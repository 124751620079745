import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { getRedirectURLParameterByName, devShopifyAccessToken, liveShopifyAccessToken } from '../common/constants'
import {addStore,setURLRedirection} from '../actions/actionMethods'
const prefix = process.env.REACT_APP_API_PREFIX
class ShopifyToTrackhiveToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true
    }
  }

  removeLocalStorage = () => {
    localStorage.removeItem("trackhiveToken");
    localStorage.removeItem("ProfileName");
    localStorage.removeItem("Reply_To");
    localStorage.removeItem("Company_Name");
  }
  async componentDidMount() {
    let shopifyAccessToken
    if (prefix === '/development') {
      shopifyAccessToken = devShopifyAccessToken
    } else {
      shopifyAccessToken = liveShopifyAccessToken
    }
    let postObject = {
      code: getRedirectURLParameterByName('code'),
      shop_name: getRedirectURLParameterByName('shop'),
      url: 'https://' + getRedirectURLParameterByName('shop')
    }

    localStorage.setItem('ShopifyStoreName', getRedirectURLParameterByName('shop'))
        
    axios.post(shopifyAccessToken, postObject).then(response => {
      if (response.data.data) {
        localStorage.setItem("ShopifyStoreData", JSON.stringify(response.data.data))
        let userDetails = JSON.parse(localStorage.getItem("trackhiveToken"))

        
        
        if (response.data.data.isRegister === true) { // isRegister true
          if (userDetails !== undefined && userDetails !== null) {
            this.callStoresAPI(userDetails, response.data.data)
          }
          else {
            this.props.history.push('/login?utm_source=shopify')
          }

        }
        else if (response.data.data.isRegister === false) { // isRegister false

          if (userDetails !== undefined && userDetails !== null) {

            this.callStoresAPI(userDetails, response.data.data)
          }
          else {
            this.props.history.push('/signup?utm_source=shopify')
          }
        }

        else if (response.data.data.storeExist === true) { // isStoreExist
          if (userDetails !== undefined && userDetails !== null) {
            this.props.history.push('/integrated_list')
          }
          else {
            this.removeLocalStorage()
            
            this.props.history.push('/login?utm_source=storeExist')
          }
        }
      }
      else {
        
        if(response.data.meta.code === 200 && response.data.meta.message[0] === 'Store integration warning'){
            this.props.history.push("/integrated_list")
        }
      }
    }).catch(error => {
    })
  }

  callStoresAPI = (userDetails, shopifyData) => {

   
    let prepareData = {}

    prepareData.user_id = userDetails._id
    prepareData.channel_id = shopifyData.shop.channel_id
    prepareData.store_name = shopifyData.shop.name
    prepareData.url = shopifyData.shop.url
    prepareData.active = 1
    prepareData.key1 = shopifyData.shop.access_token
    
    addStore(prepareData).then(res => {
      if(res.data !== undefined && res.data !== null){
        localStorage.removeItem('ShopifyStoreData')
        this.props.history.push('/integrated_list')
      }
    })
  }
  render() {
    return (
      <div id="data_container" className="main_view setup_view">
        {this.state.loader ? <div>Redirecting to Trackhive...</div> : ''}
      </div>
    );
  }
}
export default (withRouter(connect(null, {setURLRedirection})(ShopifyToTrackhiveToken)))