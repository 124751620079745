import React from 'react'
import moment from 'moment-timezone';
import { reactLocalStorage } from 'reactjs-localstorage';
import { changeDateFunction, setChartColor } from '../common/Functions'
import ShipmentSummaryColumnChart from './shipmentSummaryColumnChart'
import { shipmentSummaryChart } from '../actions/actionMethods'
import { summaryIcon } from '../../common/imageList'
import { Grid } from '@material-ui/core'
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import DashboardCourierAutocomplete from "../common/DashboardCourierAutocomplete";
import DashboardPopover from "../common/DashboardPopover";

class ShipmentSummaryColumn extends React.Component {
    constructor(props) {
        super(props);
        this.selectedCourier = null
        this.state = {
            storeName: [],
            seriesData: [],
            toDate: '',
            fromDate: '',
            chartLoader: true,
            timeSpan: 'Order Date',
            arrLength: 0,
            durationType: '',
            preparedChartData: [],
            fromUTCDateValue: '',
            toUTCDateValue: ''
        }
    }

    componentDidMount() {
        let dateObj = changeDateFunction('30Days', false)
        this.setState({ fromDate: dateObj.fromDate, toDate: dateObj.toDate, fromUTCDateValue: '', toUTCDateValue: '' })
		this.shipmentSummaryChartDetail(dateObj.fromDate, dateObj.toDate)
    }
    getTimeArray = (fromDate, toDate, diff, type) => {
        let returnArray = []
        let fromYear = moment(fromDate).add(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY')
        let toYear = moment(toDate).add(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY')
        if (type === 'month') {
            for (let i = 1; i <= 12; i++) {
                if (i < 10) {
                    returnArray.push(fromYear + '-0' + i)
                } else {
                    returnArray.push(fromYear + '-' + i)
                }
            }
        } else if (type === 'year') {
            for (let i = fromYear; i <= toYear; i++) {
                returnArray.push(i.toString())
            }
        } else {
            let currentDate = moment(fromDate).add(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes')
            let stopDate = moment(toDate).add(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes')
            while (currentDate <= stopDate) {
                returnArray.push(moment(currentDate).format('YYYY-MM-DD'))
                currentDate = moment(currentDate).add(1, 'days')
            }
        }
        return returnArray
    }

    setCustomDateRange = (fromUTCDate, toUTCDate) => {
        this.shipmentSummaryChartDetail(fromUTCDate, toUTCDate)
        this.setState({
            fromUTCDateValue: fromUTCDate,
            toUTCDateValue: toUTCDate,
			fromDate: fromUTCDate,
			toDate: toUTCDate
        })
    }
    setDateType = (dateType, fromDate, toDate) => {
		this.setState({dateType: dateType, fromDate: fromDate, toDate:toDate, fromUTCDateValue: '', toUTCDateValue: ''})
	}
    prepareChartData = (chartData, arrLength, durationType) => {
        let storeData = []
        let seriesData = []
        let storeIds = []
        let timeSpan = ''


        Object.keys(chartData).forEach(function (key, index) {
            if (durationType === 'year') {
                storeData.push(moment(key).format('YYYY'))
                timeSpan = 'Shipment Year'
            } else if (durationType === 'month') {
                storeData.push(moment(key).format('MMM'))
                timeSpan = 'Shipment Month'
            } else {
                if (arrLength < 8) {
                    storeData.push(moment(key).format('dddd'))
                    timeSpan = 'Shipment Day'
                } else {
                    storeData.push(moment(key).format('DD MMM'))
                    timeSpan = 'Shipment Date'
                }
            }
            for (let i = 0; i < chartData[key].length; i++) {
                let storeObject = {}
                let dataArray = Array.from({ length: arrLength }).map(x => 0)
                let statusIndex = storeIds.findIndex(obj => obj === chartData[key][i].status)
                if (statusIndex === -1) {
                    storeObject.name = chartData[key][i].status
                    dataArray[index] = chartData[key][i].count || 0
                    storeObject.data = dataArray
                    storeObject.color = setChartColor(chartData[key][i].status)
                    seriesData.push(storeObject)
                    storeIds.push(chartData[key][i].status)
                } else {
                    seriesData[statusIndex].data[index] = chartData[key][i].count || 0
                }
            }

        })
        this.setState({
            preparedChartData: seriesData,
            storeName: storeData,
            chartLoader: false,
            timeSpan: timeSpan
        })
    }
    shipmentSummaryChartDetail = (fromDate, toDate) => {
        let data = {}
        data.from_date = fromDate
        data.to_date = toDate
        data.utc_offset = moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset()
        let dayDiff = moment(toDate).diff(moment(fromDate), 'days')
        let toDateYear = moment(toDate).add(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY')
        let fromDateYear = moment(fromDate).add(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY')
        let durationType = ''
        if (dayDiff < 31) {
            data.date_type = 10
            data.time_array = this.getTimeArray(fromDate, toDate, dayDiff, 'days')
            durationType = 'days'
        } else if (dayDiff < 366 && (toDateYear === fromDateYear)) {
            data.date_type = 7
            data.time_array = this.getTimeArray(fromDate, toDate, dayDiff, 'month')
            durationType = 'month'
        } else {
            data.date_type = 4
            data.time_array = this.getTimeArray(fromDate, toDate, dayDiff, 'year')
            durationType = 'year'
        }
        let arrLength = data.time_array.length || 30

        if (this.selectedCourier && this.selectedCourier !== null && this.selectedCourier.value !== 'all') {
            data.courier = this.selectedCourier.value
        }
        this.setState({ chartLoader: true })
        shipmentSummaryChart(data).then(res => {
            if (res.status >= 200 && res.status <= 299) {

                this.prepareChartData(res.data, arrLength, durationType)
                this.setState({ chartData: res.data, arrLength: arrLength, durationType: durationType, chartLoader: false })
            } else {
                this.setState({ chartLoader: false })
            }
        }).catch(error => {
            console.log(error)
        })
    }

    prepareCourierData = (courierData) => {
        let courierListArr = []
        for (let index = 0; index < courierData.length; index++) {
            const element = courierData[index];
            courierListArr.push({
                label: element.title,
                value: element.slug
            })
        }

        return courierListArr
    }
    handleChangeCourier = (selectedOption) => {
        this.selectedCourier = selectedOption
        let isCustomRangeDate = ((this.state.fromUTCDateValue && this.state.fromUTCDateValue !== '') && (this.state.toUTCDateValue && this.state.toUTCDateValue !== ''))
        if (isCustomRangeDate) {
            this.shipmentSummaryChartDetail(this.state.fromUTCDateValue, this.state.toUTCDateValue)
        }
        else {
            this.shipmentSummaryChartDetail(this.state.fromDate, this.state.toDate)

        }
    }

    render() {
        const { classes } = this.props
        return (
            <Card className="cardNormal" style={{ cursor: 'default', float: 'none', width: '99.75%' }}>
                <CardHeader id="shipmentSummary" className="shipmentStatus"
                    avatar={
                        <Avatar>
                            <img alt="" src={summaryIcon} />
                        </Avatar>
                    }
                    action={
                        <Grid className="MuiCardHeader-action" style={{ display: "inline-flex", float: 'right' }}>
                            {
                                this.props.addedCourierList && this.props.addedCourierList.length > 0 ?
                                    <div>

                                        <DashboardCourierAutocomplete
                                            addedCourierList={this.props.addedCourierList}
                                            handleChangeCourier={this.handleChangeCourier}
                                        />
                                    </div> : ''
                            }
                            <DashboardPopover
                                type='ShipmentColum'
                                getData={this.shipmentSummaryChartDetail}
                                setCustomDateRange={this.setCustomDateRange}
                                setDateType={this.setDateType}
                            />
                        </Grid>
                    }
                    title={<Typography className="headingText">Shipments Summary</Typography>}
                />
                <CardContent style={{ paddingBottom: '9px', paddingLeft: '0px', paddingRight: '0px', height: '417px' }} >
                    {this.state.chartLoader ? <div style={{ width: '400px', margin: '120px auto', opacity: '0.5' }}><CircularProgress size={42} className={classes.progress} /></div> :
                        this.state.preparedChartData.length === 0 ?
                            <div style={{ width: '400px', margin: '60px auto', height: '260px', opacity: '0.5', position: 'relative' }}>
                                <div style={{ position: 'absolute', top: '50%', left: "30%", fontSize: '1.5rem' }}>No data to display </div>
                            </div> :
                            <ShipmentSummaryColumnChart
                                storeData={this.state.storeName}
                                seriesData={this.state.preparedChartData}
                                timeSpan={this.state.timeSpan} />

                    }
                </CardContent>
            </Card>
        )
    }
}
export default ShipmentSummaryColumn
