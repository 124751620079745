import React from 'react'
import axios from 'axios';
import '../assets/css/settings.scss'
import { connect } from 'react-redux'
import '../assets/css/profileStyle.scss'
import UploadImage from './uploadImage'
import { withRouter, Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { getInstance } from '../../config/token'
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Styles from '../assets/css/Styles'
import { withStyles } from '@material-ui/core/styles';
import FlashMessage from '../common/FlashMessage'
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Organization as organizationLogo } from '../../common/imageList'
import { defaultOrganizationPic, circleLoaderImage } from '../../common/imageList'
import { imageSizeInvalid, profileImageInvalid, imageUploadError } from '../common/messages'
import { LinearProgressBar, StyledTextField, StyledAutoComplete,  StyledCard, StyledTypography, BreadCrumbs, Image} from '../common/materialComponents'
import { getCountryList, getOrganizationDetails, editOrganizationDetails, setSettingsFlashMessage, toggleSettingsFlashMessage } from '../actions/actionMethods'
import {phoneRegex} from '../../static/constants'
import {ORGANIZATION_SUCCESSFULLY_SAVED_MESSAGE} from '../common/constants'
const divstyle = {
    width: "117px",
    height: "24px",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.15px",
    color: "#212121",
    display: "inline-flex", position: "relative", bottom: "14px"
}

const prefix = ''
class Organization extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess = true
        this.state = {
            selectedCountry: { value: 'IN', label: 'India' },
            organizationName: '',
            contactNumber: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: '',
            website: '',
            countryList: [],
            isOrganizationNameError: false,
            isContactNumberError: false,
            isAddressLine1Error: false,
            isCityError: false,
            isStateError: false,
            showLoader: false,
            circleLoader: false,
            organizationLogo: null,
            isActive: true
        }
    }
    startUploading = () => {
        this.setState({ circleLoader: true })
    }
    handleDrop = (images) => {
        this.setState({
            circleLoader: false,
            organizationLogo: images
        })
        let putObject = {
            logo: images
        }
        let config = getInstance('PUT', prefix + '/user/organization/logo', putObject)
        axios(config).then(response => {
            if (response && response.status >= 200 && response.status <= 299) {
                this.setState({
                    organizationLogo: response.data.data.logo
                })
                this.getOrganizationDetails()
            }
        })
        return images
    }
    onFileTypeError = file => {
        this.setState({ circleLoader: false, imageInvalid: profileImageInvalid })
        setTimeout(() => {
            this.setState({ imageInvalid: false });
        }, 3000);
    }
    onFileSizeError = file => {
        this.setState({ circleLoader: false, imageSizeInvalid: imageSizeInvalid })
        setTimeout(() => {
            this.setState({ imageSizeInvalid: false });
        }, 3000);
    }
    onFileUploadError = error => {
        this.setState({ circleLoader: false, imageInvalid: imageUploadError })
        setTimeout(() => {
            this.setState({ imageInvalid: false });
        }, 3000);
    }
    componentWillMount() {
        this.getCountryList()
        this.getOrganizationDetails()
    }
    getCountryList = () => {
        getCountryList().then(res => {
            if (res && res.data) {
                let countryListArr = []
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    countryListArr.push({
                        label: element.name,
                        value: element.iso
                    })
                }
                this.setState({
                    countryList: countryListArr
                })
            }
        })
    }
    getOrganizationDetails = () => {
        getOrganizationDetails().then(res => {
            if (res && res.meta && res.meta.code >= 200 && res.meta.code <= 299) {
                let company_name = res.data.organization_details.company_name
                let contact_no = res.data.organization_details.contact_no
                let address1 = res.data.organization_details.address1
                let address2 = res.data.organization_details.address2
                let city = res.data.organization_details.city
                let state = res.data.organization_details.state
                let country_name = res.data.organization_details.country_name
                let zipcode = res.data.organization_details.zipcode
                let website_url = res.data.organization_details.website_url
                let logo = res.data.organization_details.logo
                this.setState({
                    organizationName: company_name,
                    addressLine1: address1 !== undefined && address1 !== null ? address1 : '',
                    contactNumber: contact_no !== undefined && contact_no !== null ? contact_no : '',
                    addressLine2: address2 !== undefined && address2 !== null ? address2 : '',
                    city: city !== undefined && city !== null ? city : '',
                    state: state !== undefined && state !== null ? state : '',
                    selectedCountry: country_name !== undefined && country_name !== null ? { value: country_name, label: country_name } : '',
                    zipCode: zipcode !== undefined && zipcode !== null ? zipcode : '',
                    website: website_url !== undefined && website_url !== null ? website_url : '',
                    organizationLogo: logo,
                    circleLoader: false

                })
            }
        })
    }
    handleCountrySelect = (selectedCountry) => {
        this.setState({
            selectedCountry: selectedCountry
        })
    }
    handleChange = (key, value) => {
        if (key === 'organizationName') {
            this.setState({
                organizationName: value,
                isOrganizationNameError: false
            })
        }
        else if (key === 'contactNumber') {
            if (phoneRegex.test(value)) {
                this.setState({
                    contactNumber: value,
                    isContactNumberError: false
                })
            }
        }
        else if (key === 'addressLine1') {
            this.setState({
                addressLine1: value,
                isAddressLine1Error: false
            })
        }
        else if (key === 'addressLine2') {
            this.setState({
                addressLine2: value,
            })
        }
        else if (key === 'city') {
            this.setState({
                city: value,
                isCityError: false
            })
        }
        else if (key === 'state') {
            this.setState({
                state: value,
                isStateError: false
            })
        }
        else if (key === 'zipCode') {
            this.setState({
                zipCode: value
            })
        }
        else if (key === 'website') {
            this.setState({
                website: value
            })
        }
    }
    checkOrganizationName = () => {
        if (this.state.organizationName === '') {
            this.setState({
                isOrganizationNameError: true
            })
        }
        else {
            this.setState({
                isOrganizationNameError: false
            })
        }
    }
    checkContactNumber = () => {
        if (this.state.contactNumber === '') {
            this.setState({
                isContactNumberError: true
            })
        }
        else {
            this.setState({
                isContactNumberError: false
            })
        }
    }
    checkAddressLine1 = () => {
        if (this.state.addressLine1 === '') {
            this.setState({
                isAddressLine1Error: true
            })
        }
        else {
            this.setState({
                isAddressLine1Error: false
            })
        }
    }
    checkCity = () => {
        if (this.state.city === '') {
            this.setState({
                isCityError: true
            })
        }
        else {
            this.setState({
                isCityError: false
            })
        }
    }
    checkState = () => {
        if (this.state.state === '') {
            this.setState({
                isStateError: true
            })
        }
        else {
            this.setState({
                isStateError: false
            })
        }
    }
    handleSubmit = () => {
        let organizationNameFlag = true
        let contactNumberFlag = true
        let addressLine1Flag = true
        let cityFlag = true
        let stateFlag = true
        if (this.state.organizationName === '') {
            organizationNameFlag = false
        }
        if (this.state.contactNumber === '') {
            contactNumberFlag = false
        }
        if (this.state.addressLine1 === '') {
            addressLine1Flag = false
        }
        if (this.state.city === '') {
            cityFlag = false
        }
        if (this.state.state === '') {
            stateFlag = false
        }
        let allowFlag = (organizationNameFlag === false || contactNumberFlag === false || addressLine1Flag === false || cityFlag === false || stateFlag === false) ? false : true
        if (allowFlag) {
            this.setState({
                showLoader: true,
                isActive: false
            })
            let requestParams = {}
            requestParams.company_name = this.state.organizationName
            requestParams.contact_no = this.state.contactNumber
            requestParams.address1 = this.state.addressLine1
            requestParams.city = this.state.city
            requestParams.state = this.state.state
            requestParams.country_name = this.state.selectedCountry.label
            requestParams.logo = this.state.organizationLogo
            if (this.state.addressLine2 !== '') {
                requestParams.address2 = this.state.addressLine2
            }
            if (this.state.zipCode !== '') {
                requestParams.zipcode = this.state.zipCode
            }
            if (this.state.website !== '') {
                requestParams.website_url = this.state.website
            }
            editOrganizationDetails(requestParams).then(res => {
                this.setState({
                    showLoader: false,
                    isActive: true
                })
                if (res && res.meta && res.meta.code >= 200 && res.meta.code <= 299) {
                    this.isSuccess = true
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(ORGANIZATION_SUCCESSFULLY_SAVED_MESSAGE)
                    this.getOrganizationDetails()
                }
                else {
                    this.isSuccess = false
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(res.data.meta.message[0])
                }
            })
        }
        else {
            this.checkOrganizationName()
            this.checkContactNumber()
            this.checkAddressLine1()
            this.checkCity()
            this.checkState()
        }
    }
    toggleFlashMessage = (data) => {
        this.props.toggleSettingsFlashMessage(data)
        this.props.setSettingsFlashMessage('')
    }
    removeLogo = () => {
        this.setState({
            circleLoader: true,
            countryList: []
        })
        let putObject = {
            logo: null
        }

        let config = getInstance('PUT', prefix + '/user/organization/logo', putObject)
        axios(config).then(response => {
            if (response && response.status >= 200 && response.status <= 299) {
                this.getCountryList()
                this.getOrganizationDetails()
            }
        })
    }

    handleClick = (event) => {
        event.preventDefault();
    }

    setValue = (e, setValue) => {
        this.setState({ selectedCountry: setValue })
    }


    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
            {
                this.state.countryList && this.state.countryList.length > 0 ?
                <div className="settingsModule">
                    <StyledCard>
                        <BreadCrumbs aria-label="breadcrumb">
                            <Link className={classes.link} to="/settings">
                                Settings
                            </Link>
                           <StyledTypography className={classes.text}>Organization</StyledTypography>     
                        </BreadCrumbs>
                    </StyledCard>                  
                   <Scrollbars autoHide style={{ height: window.innerHeight - 51, clear: "both", width: "100%" }}>
                      <Container maxWidth="xl" className={classes.container}>
                            <div style={{ margin: "32px 0px 70px 0px" }}>
                                <Image alt="organization_logo" className={classes.avatar} src={organizationLogo} />
                                <div style={divstyle}>Organization</div>
                            </div>
                            <div className="dataContainer">
                                <div id="settingDataScrollerWrapper">
                                    <div className="settingsContainer">
                                        <div className="settingsRightSidePanel">
                                                    <div className="profileSettingsContentContainer">
                                                        <form>
                                                            <div className="formGroup">
                                                                <div className="imageUploadMainDiv">
                                                                    {
                                                                        this.state.circleLoader ?
                                                                            <Image alt="organization_IC" className={classes.avatarOrganization} src={circleLoaderImage} /> :
                                                                            <Image alt="organization_IC" className={classes.avatarOrganization} src={this.state.organizationLogo !== undefined && this.state.organizationLogo !== null ? this.state.organizationLogo : defaultOrganizationPic} />
                                                                    }
                                                                    <div className="uploadImgBtnDiv">
                                                                        <div className="uploadLogoContainer">
                                                                            <div className="uploadLogoBtn">Upload Logo</div>
                                                                            <UploadImage
                                                                                onDrop={this.handleDrop}
                                                                                pdf="false"
                                                                                startUploading={this.startUploading}
                                                                                onFileTypeError={this.onFileTypeError}
                                                                                onFileUploadError={this.onFileUploadError}
                                                                                onFileSizeError={this.onFileSizeError} />
                                                                            {
                                                                                this.state.imageSizeInvalid !== '' || this.state.imageInvalid !== '' ?
                                                                                    <div className="upload-image-name">{this.state.imageInvalid !== '' && <span className="error-fonts">{this.state.imageInvalid}</span>}{this.state.imageSizeInvalid !== '' && <span className="error-fonts">{this.state.imageSizeInvalid}</span>}</div> :
                                                                                    ''
                                                                            }
                                                                        </div>
                                                                        <div className="removeUploadedLogo" onClick={this.removeLogo}>Remove Logo</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <StyledTextField
                                                                    error={this.state.isOrganizationNameError ? true : false}
                                                                    id="organization-name"
                                                                    label="Organization Name"
                                                                    variant="outlined"
                                                                    className={classes.textField}
                                                                    value={this.state.organizationName}
                                                                    onChange={(e) => this.handleChange('organizationName', e.target.value)}
                                                                />
                                                                <StyledTextField
                                                                    error={this.state.isContactNumberError ? true : false}
                                                                    id="contact-number"
                                                                    label="Contact Number"
                                                                    variant="outlined"
                                                                    className={classes.textField}
                                                                    name="contactNumber"
                                                                    value={this.state.contactNumber}
                                                                    onBlur={this.checkContactNumber}
                                                                    onChange={(e) => this.handleChange('contactNumber', e.target.value)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <StyledTextField
                                                                    error={this.state.isAddressLine1Error ? true : false}
                                                                    id="address-line-1"
                                                                    label="Address Line 1"
                                                                    variant="outlined"
                                                                    className={classes.textField}
                                                                    name="addressLine1"
                                                                    value={this.state.addressLine1}
                                                                    onBlur={this.checkAddressLine1}
                                                                    onChange={(e) => this.handleChange('addressLine1', e.target.value)}
                                                                />
                                                                <StyledTextField
                                                                    id="address-line-2"
                                                                    label="Address Line 2"
                                                                    variant="outlined"
                                                                    className={classes.textField}
                                                                    name="addressLine2"
                                                                    value={this.state.addressLine2}
                                                                    onChange={(e) => this.handleChange('addressLine2', e.target.value)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <StyledTextField
                                                                    error={this.state.isCityError ? true : false}
                                                                    id="city"
                                                                    label="City"
                                                                    variant="outlined"
                                                                    className={classes.textField}
                                                                    name="city"
                                                                    value={this.state.city}
                                                                    onBlur={this.checkCity}
                                                                    onChange={(e) => this.handleChange('city', e.target.value)}
                                                                />
                                                                <StyledTextField
                                                                    error={this.state.isStateError ? true : false}
                                                                    id="state"
                                                                    label="State"
                                                                    variant="outlined"
                                                                    className={classes.textField}
                                                                    name="state"
                                                                    value={this.state.state}
                                                                    onBlur={this.checkState}
                                                                    onChange={(e) => this.handleChange('state', e.target.value)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <StyledAutoComplete
                                                                    id="country-combo-box"
                                                                    openOnFocus = {true}
                                                                    options={this.state.countryList}
                                                                    getOptionLabel={(option) => option.label}
                                                                    value={this.state.selectedCountry}
                                                                    onChange={(event, newValue) => {
                                                                        this.setValue(event, newValue);
                                                                    }}
                                                                    className={classes.autoComplete}
                                                                    renderInput={(params) => <StyledTextField {...params} label="Country" variant="outlined" />}
                                                                />
                                                                <StyledTextField
                                                                    id="zipcode"
                                                                    label="Zip Code"
                                                                    variant="outlined"
                                                                    className={classes.textField}
                                                                    name="zipCode"
                                                                    value={this.state.zipCode}
                                                                    onChange={(e) => this.handleChange('zipCode', e.target.value)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <StyledTextField
                                                                    id="website"
                                                                    label="Website"
                                                                    variant="outlined"
                                                                    className={classes.textField}
                                                                    name="website"
                                                                    value={this.state.website}
                                                                    onChange={(e) => this.handleChange('website', e.target.value)}
                                                                />
                                                            </div>

                                                        <div>
                                                        {
                                                            <div className={classes.saveBtnRoot}>
                                                            <div className={classes.wrapper} >
                                                              <Button
                                                                variant="contained"
                                                                className={classes.OrganizationSaveBtn}
                                                                disabled={this.state.showLoader}
                                                                onClick={this.handleSubmit}
                                                              >
                                                               SAVE
                                                              </Button>
                                                              {this.state.showLoader && <CircularProgress size={22} className={classes.OrganizationBtnProgress} />}
                                                            </div>
                                                          </div>
                                                     }    
                                                     </div>
                                                </form>
                                             </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* data container close  */}
                        </Container>
                     </Scrollbars>
                    {this.props.showSettingFlashMessageFlag === 1 && !this.state.showLoader?
                        <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)}/>  
                        : ''}
                </div>
                  : <div>
                       <LinearProgressBar />
                    </div>
                 }
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, { getCountryList, getOrganizationDetails, editOrganizationDetails, setSettingsFlashMessage, toggleSettingsFlashMessage })(withStyles(Styles)(Organization)));