import React from 'react'
//import Dropzone from 'react-dropzone'
import ReactS3Uploader from 'react-s3-uploader';
import axios from 'axios';
import {getInstance} from '../../config/token'
const prefix = ''
const user_id = 4
const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"]
const allowedTemplateTypes = ["image/pdf", "image/jpg","image/jpeg", "image/png"]
const allowedFtpFileTypes = ["application/json", "application/xml", "text/xml"]
export default class UploadImage extends React.Component {
  state = {
    loader: true
  }
  onUploadStart = (info, next) => {
    
    if(this.props.xmlJson){
      this.props.startUploading(info.name)
    }else{
      this.props.startUploading()
    }
    if (this.props.pdf) {
      let invalid = true
      let size = false
      for (var i = 0; i < allowedTemplateTypes.length; i++) {
        
        if (info.type === allowedTemplateTypes[i]) {
          invalid = false
        }
      }
      if(info.size > 1000000){
        size = true
      }
      if (invalid === true) {
        this
          .props
          .onFileTypeError(info.name)
      } else if(size === true){
        this.props.onFileSizeError(info.size)
      }
      else {
        next(info);
      }
    }else if(this.props.xmlJson){
      let invalid = true
      for (let fileTypeCnt = 0; fileTypeCnt < allowedFtpFileTypes.length; fileTypeCnt++) {
       
        if (info.type === allowedFtpFileTypes[fileTypeCnt]) {
          invalid = false
        }
      }
      if (invalid === true) {
        this
          .props
          .onFileTypeError(info.name)
      } else {
        next(info);
      }
    }
    else {
      let invalid = true
      for (let fileTypeCnt = 0; fileTypeCnt < allowedImageTypes.length; fileTypeCnt++) {
        if (info.type === allowedImageTypes[fileTypeCnt]) {
          invalid = false
        }
      }
      if (invalid === true) {
        this
          .props
          .onFileTypeError(info.name)
      } else {
        next(info);
      }
    }
  }
  onUploadFinish = info => {
    
    let filesData = info.signedUrl.substr(0, info.signedUrl.indexOf("?"))
    this.props.onDrop(filesData)
  }
  onUploadError = info => {
    this
      .props
      .onFileUploadError()
  }
  componentDidMount = () => {
  }
  getImageSignedUrl = (fileName, fileType) => {
    const config = getInstance('GET', `${prefix}/user/awsservice/presignedurl/image?file_name=` + fileName + '&file_type=' + fileType)
    return axios(config).then(res => res.data)
  }
  getImgSignedUrl = (file, callback) => {
    
    let filename = file.name
    filename = Math.floor((new Date()).getTime() / 1000) + '_' + filename.replace(/[^\w\d_\-.]+/ig, '')
    this.getImageSignedUrl(filename, file.type).then(res => {
      let signedUrl = {signedUrl : res.data.signedRequest}
        callback(signedUrl);
      })
      .catch(error => {
      });
  }
  render() {
    return (<ReactS3Uploader
      getSignedUrl={this.getImgSignedUrl}
      accept="image/*"
      s3path="temp/"
      preprocess={this.onUploadStart}
      onProgress={this.onUploadProgress}
      onError={this.onUploadError}
      onFinish={this.onUploadFinish}
      uploadRequestHeaders={{}}
      contentDisposition="auto"
      scrubFilename={(filename) => Math.floor((new Date()).getTime() / 1000) + '_' + user_id + '_' + filename.replace(/[^\w\d_\-.]+/ig, '')}
      inputRef={cmp => this.uploadInput = cmp}
      autoUpload={true}
      multiple={true}
      className={this.props.fileRequired
      ? 'error-Border-Message'
      : ''}
      id="uploadedFile"/>)
  }
}
