import * as types from '../common/constants'
import { NOTIFY, NOTIFY_COUNT } from '../../../src/container/actions/actions'

const initialState = {
    timezoneData: [],
    showSettingFlashMessage : '',
    SettingFlashMessage:'',
    NOTIFY: false,
    notify_snapshot: '',
    notify_count_snapshot : 0
}

export default (state = initialState, action = null) => {
    switch (action.type) {
        case types.TIMEZONELIST:
            return {
                ...state,
                timezoneData: action.data.data
            }

            case types.SETTINGS_SHOW_FLASH_MESSAGE:
        return{
            ...state,
            showSettingFlashMessage:action.data
        }

        case types.SETTINGS_FLASH_MESSAGE:
        return{
            ...state,
            SettingFlashMessage:action.data
        }

        case types.SET_URL_REDIRECTION:
        return{
            ...state,
            urlRedirection:action.data
        }

        case types.SET_HTML_VALUE:
            return{
                ...state,
                trackingPageHTMLValue:action.data
            }
        case NOTIFY:
            return {
                ...state,
                NOTIFY: true,
                notify_snapshot: action.snapshot
            }
        case NOTIFY_COUNT:
            return {
                ...state,
                NOTIFY_COUNT: true,
                notify_count_snapshot: action.snapshot
            }

        default:
            return state;
    }

}