import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { setAutomationFilters, getFiltersList, setAutomationActionFilter, setAutomationTrigger, getAutomationRuleList, setAutomationLoader } from '../../actions/actionMethods'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../static/css/automate'
import TextField from '@material-ui/core/Input';
import { getLabel } from '../../common/Functions'
import * as ShipmentAction from '../../constants/automationAction'
import AddNewAutomation from './addAutomationDialog'

import {addTrackingIcon, filterIcon } from '../../../common/imageList'
import {searchRegex} from '../../../static/constants'

class Filters extends React.Component {
    displayTypeText = 'displayType'
    displayTriggerText = 'displayTrigger'
    displayActionText = 'displayAction'
    constructor(props) {
        super(props)
        if (props.automationGlobalFilters) {
            let params = {}
            let storeObj = props.automationGlobalFilters
            if (storeObj.actionsArray) {
                let action = storeObj.actionsArray.map((element, index) => {
                    return (element)
                })
                params['actionArr'] = action.join()
            }
            if (storeObj.typeArr) {
                let type = storeObj.typeArr.map((element, index) => {
                    return (element)
                })
                params['typeArr'] = type.join()
            }
            if (storeObj.triggerArr) {
                let trigger = storeObj.triggerArr.map((element, index) => {
                    return (element)
                })
                params['triggerArr'] = trigger.join()
            }
            props.history.replace(`?${queryString.stringify(params)}`)
        }
        this.state = {
            displayType: 0,
            displayTrigger: 0,
            displayAction: 0,
            triggerList: [],
            actionList: [],
            selectedTypeArr: this.props.automationGlobalFilters.typeListArr || [],
            selectedTriggerArr: this.props.automationGlobalFilters.triggerListArr || [],
            selectedActionArr: this.props.automationGlobalFilters.actionListArr || [],
            showType: false,
            showTrigger: false,
            showAction: false,
            typeList: [
                { "label": "Inbuilt Trigger", "value": 'inbuilt' },
                { "label": "Custom Trigger", "key": 'custom' },
            ],
            filterFlag: true,
            checked: [0],
            openDialog : false
        }
    }
    componentWillMount() {
        getFiltersList().then(response => {
            let customTriggerList = [
                { "label": "Created", "value": 'CREATED' },
                { "label": "Updated", "value": 'UPDATED' },
            ]
            if (response && response.data) {
                let actionListArr = []
                if (response.data.actions && response.data.actions.length > 0) {
                    for (let index = 0; index < response.data.actions.length; index++) {
                        const element = response.data.actions[index];
                        if(element !== "REPEAT_ACTION_REMOVE"){
                            actionListArr.push({
                                label: getLabel(ShipmentAction.ShipmentActions,element),
                                value: element
                            })
                        }
                    }
                    this.setState({
                        actionList: actionListArr
                    })
                }
                this.setState({
                    triggerList: customTriggerList
                })
                this.props.setAutomationTrigger(this.state.triggerList)
                this.props.setAutomationActionFilter(this.state.actionList)
            }
        })
        if(this.props.match.url === '/automations/add'){
            this.props.history.push(`/automations/add`)
            this.setState({
                openDialog : true
            })
        }
    }
    componentWillReceiveProps(props) {
    }
    handleOnClick = (key, flag) => {
        let obj = {
            [key]: 1, [flag]: false,
            filterFlag: false
        }
        this.setState(obj)
        if (key === this.displayTypeText) {
            this.setState({
                displayTrigger: 0,
                displayAction: 0,
            })
        }
        else if (key === this.displayTriggerText) {
            this.setState({
                displayType: 0,
                displayAction: 0,
            })
        }
        else if (key === this.displayActionText) {
            this.setState({
                displayType: 0,
                displayTrigger: 0,
            })
        }
    }
    handleCloseClick = (key) => {
        if (key) {
            let obj = { [key]: 0 }
            this.setState(obj);
        }
        if (key == this.displayTypeText) {
            let tempArray = this.props.automationGlobalFilters && this.props.automationGlobalFilters.typeList && this.props.automationGlobalFilters.typeList.length ? this.props.automationGlobalFilters.typeList : []
            this.setState({ selectedTypeArr: tempArray })
        }
        if (key == this.displayTriggerText) {
            let tempArray = this.props.automationGlobalFilters && this.props.automationGlobalFilters.triggerListArr && this.props.automationGlobalFilters.triggerListArr.length ? this.props.automationGlobalFilters.triggerListArr : []
            this.setState({ selectedTriggerArr: tempArray })
        }
        if (key == this.displayActionText) {
            let tempArray = this.props.automationGlobalFilters && this.props.automationGlobalFilters.actionListArr && this.props.automationGlobalFilters.actionListArr.length ? this.props.automationGlobalFilters.actionListArr : []
            this.setState({ selectedActionArr: tempArray })
        }
        this.setState({
            triggerList: this.props.automationTriggerArrayList,
            actionList: this.props.automationActionArrayList,
        })
    }
    handleChangeAction = (event, element) => {
        let selectActionVal = [...this.state.selectedActionArr]
        let actionIndex = selectActionVal.findIndex(x => x.value == element.value)
        if (event.target.checked === true) {
            selectActionVal.push({
                label: element.label,
                value: element.value
            })
        }
        else {
            selectActionVal.splice(actionIndex, 1)
        }
        this.setState({
            selectedActionArr: selectActionVal
        })
        if (selectActionVal.length > 0) {
            this.setState({
                showAction: true
            })
        }
        else {
            if (this.props.automationGlobalFilters && this.props.automationGlobalFilters.actionListArr && this.props.automationGlobalFilters.actionListArr.length > 0) {
                this.setState({
                    showAction: true
                })
            }
            else {
                this.setState({
                    showAction: false
                })
            }
        }
    }

    handleChangeTrigger = (event, element) => {
        let selectTriggerVal = [...this.state.selectedTriggerArr]
        let triggerIndex = selectTriggerVal.findIndex(x => x.value == element.value)
        if (event.target.checked === true) {
            selectTriggerVal.push({
                label: element.label,
                value: element.value
            })
        }
        else {
            selectTriggerVal.splice(triggerIndex, 1)
        }
        this.setState({
            selectedTriggerArr: selectTriggerVal
        })
        if (selectTriggerVal.length > 0) {
            this.setState({
                showTrigger: true
            })
        }
        else {
            if (this.props.automationGlobalFilters && this.props.automationGlobalFilters.triggerListArr && this.props.automationGlobalFilters.triggerListArr.length > 0) {
                this.setState({
                    showTrigger: true
                })
            }
            else {
                this.setState({
                    showTrigger: false
                })
            }
        }
    }

    handleChangeType = (event, element) => {
        let selectTypeVal = [...this.state.selectedTypeArr]
        let typeIndex = selectTypeVal.findIndex(x => x.value == element.value)
        if (event.target.checked === true) {
            selectTypeVal.push({
                label: element.label,
                value: element.value
            })
        }
        else {
            selectTypeVal.splice(typeIndex, 1)
        }
        this.setState({
            selectedTypeArr: selectTypeVal
        })
        if (selectTypeVal.length > 0) {
            this.setState({
                showType: true
            })
        }
        else {
            if (this.props.automationGlobalFilters && this.props.automationGlobalFilters.typeListArr && this.props.automationGlobalFilters.typeListArr.length > 0) {
                this.setState({
                    showType: true
                })
            }
            else {
                this.setState({
                    showType: false
                })
            }
        }
    }

   
    removeTypeFilter = (typeFilter) => {
        let selectedTypeArrVal = [...this.state.selectedTypeArr]
        if (typeFilter) {
            let arr = selectedTypeArrVal.filter(x => x.label !== typeFilter)
            this.setState({
                selectedTypeArr: arr
            }, () => {
                this.handleDone(this.displayTypeText)
            })
        }
    }
    removeTriggerFilter = (triggerFilter) => {
        let selectedTriggerArrVal = [...this.state.selectedTriggerArr]
        if (triggerFilter) {
            let arr = selectedTriggerArrVal.filter(x => x.value !== triggerFilter)
            this.setState({
                selectedTriggerArr: arr
            }, () => {
                this.handleDone(this.displayTriggerText)
            })
        }
    }
    removeActionFilter = (actionFilter) => {
        let selectedActionArrVal = [...this.state.selectedActionArr]
        if (actionFilter) {
            let arr = selectedActionArrVal.filter(x => x.value !== actionFilter)
            this.setState({
                selectedActionArr: arr
            }, () => {
                this.handleDone(this.displayActionText)
            })
        }
    }
    searchAction = (event) => {
        var searchtypeList = this.props.automationActionArrayList.length > 0 ? this.props.automationActionArrayList : this.state.actionList
        let value = event.target.value.replace(searchRegex, "")
        searchtypeList = searchtypeList.filter(function (store) {
            return store.label.toLowerCase().search(value.toLowerCase()) !== -1;
        })
        this.setState({ actionList: searchtypeList })
    }
    searchTrigger = (event) => {
        var searchtypeList = this.props.automationTriggerArrayList.length > 0 ? this.props.automationTriggerArrayList : this.state.triggerList
        let value = event.target.value.replace(searchRegex, "")
        searchtypeList = searchtypeList.filter(function (store) {
            return store.label.toLowerCase().search(value.toLowerCase()) !== -1;
        })
        this.setState({ triggerList: searchtypeList })
    }

    handleDone = (key) => {
        if (key) {
            let obj = { [key]: 0 }
            this.setState(obj)
        }
        let storeObj = {}
        let typeArrVal = []
        let triggerArrVal = []
        let actionArrVal = []
    
        //set Shipment Status If Any
    
        if (this.state.selectedTriggerArr && this.state.selectedTriggerArr.length > 0) {
            for (let i = 0; i < this.state.selectedTriggerArr.length; i++) {
                let eachCourier = this.state.selectedTriggerArr[i]
                triggerArrVal.push({
                    value: eachCourier.value,
                    label: eachCourier.label
                })
            }
            this.setState({
                displayTrigger: 0,
            })
            storeObj.triggerListArr = triggerArrVal
        }
        if (this.state.selectedActionArr && this.state.selectedActionArr.length > 0) {
            for (let i = 0; i < this.state.selectedActionArr.length; i++) {
                let eachCourier = this.state.selectedActionArr[i]
                actionArrVal.push({
                    value: eachCourier.value,
                    label: eachCourier.label
                })
            }
            this.setState({
                displayAction: 0,
            })
            storeObj.actionListArr = actionArrVal
        }
        if (this.state.selectedTypeArr && this.state.selectedTypeArr.length > 0) {
            for (let i = 0; i < this.state.selectedTypeArr.length; i++) {
                let eachCourier = this.state.selectedTypeArr[i]
                typeArrVal.push({
                    value: eachCourier.value,
                    label: eachCourier.label
                })
            }
            this.setState({
                displayType: 0,
            })
            storeObj.typeListArr = typeArrVal
        }
        let requestParams = {}
        if (triggerArrVal.length > 0) {
            let triggerArrListVal = []
            for (let i = 0; i < triggerArrVal.length; i++) {
                let eachCourier = triggerArrVal[i]
                triggerArrListVal.push({
                    label: eachCourier.label,
                    value: eachCourier.value
                })
            }
            requestParams.trigger = triggerArrListVal
        }
        if (actionArrVal.length > 0) {
            let actionListArrVal = []
            for (let i = 0; i < actionArrVal.length; i++) {
                let eachAction = actionArrVal[i]
                actionListArrVal.push({
                    label: eachAction.label,
                    value: eachAction.value
                })
            }
            requestParams.action = actionListArrVal
        }
        if (typeArrVal.length > 0) {
            let typeArrListVal = []
            for (let i = 0; i < typeArrVal.length; i++) {
                let eachCourier = typeArrVal[i]
                typeArrListVal.push(eachCourier.label)
            }
            requestParams.event_type = typeArrListVal
        }
        if (this.props.automationGlobalFilters && Object.keys(this.props.automationGlobalFilters).length > 0 && this.props.automationGlobalFilters.query !== undefined && this.props.automationGlobalFilters.query !== '') {
            requestParams.query = this.props.automationGlobalFilters.query
            storeObj.query = this.props.automationGlobalFilters.query
        }
        this.setState({
            triggerList: this.props.automationTriggerArrayList,
            actionList: this.props.automationActionArrayList,
        })
        this.props.setAutomationTrigger(this.props.automationTriggerArrayList)
        this.props.setAutomationActionFilter(this.props.automationActionArrayList)
        this.props.setAutomationLoader()
        this.props.getAutomationRuleList(requestParams)
        this.props.setAutomationFilters(storeObj)
        let urlParams = {}
        if (storeObj.typeListArr) {
            let type = storeObj.typeListArr.map((element, index) => {
                return (element.label)
            })
            urlParams['typeListArr'] = type.join()
        } 
        if (storeObj.triggerListArr) {
            let triggerList = storeObj.triggerListArr.map((element, index) => {
                return (element.label)
            })
            let triggerListId = storeObj.triggerListArr.map((element, index) => {
                return (element.value)
            })
            urlParams['triggerListArr'] = triggerList.join()
            urlParams['triggerListId'] = triggerListId.join()
        }
        if (storeObj.actionListArr) {
            let actionList = storeObj.actionListArr.map((element, index) => {
                return (element.label)
            })
            let actionListId = storeObj.actionListArr.map((element, index) => {
                return (element.value)
            })
            urlParams['actionListArr'] = actionList.join()
            urlParams['actionListId'] = actionListId.join()
        }
        if (this.props.automationGlobalFilters.query !== undefined && this.props.automationGlobalFilters.query !== '' && this.props.automationGlobalFilters.query !== null) {
            urlParams['query'] = this.props.automationGlobalFilters.query;
        }
        this.props.history.replace(`?${queryString.stringify(urlParams, { strict: false })}`)
    }
    handleFilterClick = () => {
        this.props.history.push("/tracking")
    }
    openDialog = () => {
        this.props.history.push(`/automations/add`)
        this.setState({
            openDialog : true
        })
    }
    closeDialog = () => {
        this.setState({
            openDialog : false
        })
    }
    render() {
        let classes = this.props.classes
        return (
            <div className="filterBlock">
                <div className="addTrackingIcon">
                    <img style = {{verticalAlign: 'middle'}} alt="" src={addTrackingIcon} onClick={(event) => this.openDialog()} />
                </div>
                <div className="filterIcon">
                    <img src={filterIcon} alt=""/>
                </div>
                <div className="filterWrapper">
                    <div className="FilterBtnDD">
                        <div className="filterButton" onClick={() => this.handleOnClick(this.displayTriggerText, 'showTrigger')}>
                            <div className={this.props.automationGlobalFilters.triggerListArr && this.props.automationGlobalFilters.triggerListArr.length ? 'filterText activeColor' : 'filterText'}>Trigger</div>
                            <div className="filterArrow"></div>
                        </div>
                        {
                            this.state.displayTrigger === 1 ?
                                <div className="filterDropdown">
                                    <div className="filterText heading">
                                        <span className="verticalAlignSpan">Trigger</span>
                                    </div>
                                    <div>
                                        <TextField
                                            className={classes.dropdownSearch}
                                            id="standard-search"
                                            placeholder="Search"
                                            disableUnderline={true}
                                            inputProps={{
                                                style: {
                                                    padding: '6px 0 6px 25px',
                                                    fontSize: '14px',
                                                    height: '25px',
                                                }
                                            }}
                                            type="text"
                                            onChange={this.searchTrigger} />
                                    </div>
                                    <hr className="hrMarginBlock" />
                                    <div className="commonDropdownMenu scrollbar-custom">
                                        {
                                            this.state.triggerList && this.state.triggerList.length > 0 ?
                                                <List>
                                                    {this.state.triggerList.map((courier) => {
                                                        const labelId = `checkbox-list-label-${courier.value}`;
                                                        return (
                                                            <ListItem className={classes.MuiListItemSecondaryAction} key={courier.label} role={undefined} dense button>
                                                                <ListItemIcon className={classes.MuiListItemIcon}>
                                                                    <Checkbox
                                                                        edge="start"
                                                                        checked={this.state.selectedTriggerArr.find(x => x.value === courier.value) ? true : false}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                        onChange={(event) => this.handleChangeTrigger(event, courier)}
                                                                        color="primary"
                                                                        size='small'
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText className={classes.filterDropdownText} id={labelId} disableTypography={true} primary={courier.label} />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                                : <div className="noFilterFound">No Trigger Found</div>
                                        }
                                    </div>

                                    <hr className="hrMarginBlock" />

                                    <div className="filterText footer">
                                        {this.state.showTrigger ?
                                            <div>
                                                <a href={null} onClick={() => this.handleDone(this.displayTriggerText)}>APPLY</a>
                                                <a href={null} onClick={() => this.handleCloseClick(this.displayTriggerText)}>CANCEL</a>
                                            </div>
                                            : <a href={null} onClick={() => this.handleCloseClick(this.displayTriggerText)}>CANCEL</a>}
                                    </div>
                                </div> : ''
                        }
                    </div>
                    <div className="FilterBtnDD">
                        <div className="filterButton" onClick={() => this.handleOnClick(this.displayActionText, 'showAction')}>
                            <div className={this.props.automationGlobalFilters.actionListArr && this.props.automationGlobalFilters.actionListArr.length ? 'filterText activeColor' : 'filterText'}>Action</div>
                            <div className="filterArrow"></div>
                        </div>
                        {
                            this.state.displayAction === 1 ?
                                <div className="filterDropdown">
                                    <div className="filterText heading">
                                        <span className="verticalAlignSpan">Action</span>
                                    </div>
                                    <div>
                                        <TextField
                                            className={classes.dropdownSearch}
                                            id="standard-search"
                                            placeholder="Search"
                                            disableUnderline={true}
                                            inputProps={{
                                                style: {
                                                    padding: '6px 0 6px 25px',
                                                    fontSize: '14px',
                                                    height: '25px',
                                                }
                                            }}
                                            type="text"
                                            onChange={this.searchAction} />
                                    </div>
                                    <hr className="hrMarginBlock" />
                                    <div className="commonDropdownMenu scrollbar-custom">
                                        {
                                            this.state.actionList && this.state.actionList.length > 0 ?
                                                <List>
                                                    {this.state.actionList.map((courier) => {
                                                        const labelId = `checkbox-list-label-${courier.value}`;
                                                        return (
                                                            <ListItem className={classes.MuiListItemSecondaryAction} key={courier.label} role={undefined} dense button>
                                                                <ListItemIcon className={classes.MuiListItemIcon}>
                                                                    <Checkbox
                                                                        edge="start"
                                                                        checked={this.state.selectedActionArr.find(x => x.value === courier.value) ? true : false}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                        onChange={(event) => this.handleChangeAction(event, courier)}
                                                                        color="primary"
                                                                        size='small'
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText className={classes.filterDropdownText} id={labelId} disableTypography={true} primary={courier.label} />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                                : <div className="noFilterFound">No Action Found</div>
                                        }
                                    </div>

                                    <hr className="hrMarginBlock" />

                                    <div className="filterText footer">
                                        {this.state.showAction ?
                                            <div>
                                                <a href={null} onClick={() => this.handleDone(this.displayActionText)}>APPLY</a>
                                                <a href={null} onClick={() => this.handleCloseClick(this.displayActionText)}>CANCEL</a>
                                            </div>
                                            : <a href={null} onClick={() => this.handleCloseClick(this.displayActionText)}>CANCEL</a>}
                                    </div>
                                </div> : ''
                        }
                    </div>
                   
                    {/* All Shipment Status Filters... */}
                </div>
                <div className="selectedFiltersListDiv">
                    {
                        this.props.automationGlobalFilters !== undefined && Object.keys(this.props.automationGlobalFilters).length > 0 ?
                            this.props.automationGlobalFilters.typeListArr && this.props.automationGlobalFilters.typeListArr.length > 0 ?
                                this.props.automationGlobalFilters.typeListArr.map((element, index) => {
                                    return (
                                        <div className="Tags" key={index}>
                                            <span className="tagText">{element.label}</span>
                                            <span className="closeTag" onClick={() => this.removeTypeFilter(element.label)}></span>
                                        </div>
                                    )
                                })
                                : '' : ''
                    }
                     {
                        this.props.automationGlobalFilters !== undefined && Object.keys(this.props.automationGlobalFilters).length > 0 ?
                            this.props.automationGlobalFilters.triggerListArr && this.props.automationGlobalFilters.triggerListArr.length > 0 ?
                                this.props.automationGlobalFilters.triggerListArr.map((element, index) => {
                                    return (
                                        <div className="Tags" key={index}>
                                            <span className="tagText">{element.label}</span>
                                            <span className="closeTag" onClick={() => this.removeTriggerFilter(element.value)}></span>
                                        </div>
                                    )
                                })
                                : '' : ''
                    }
                     {
                        this.props.automationGlobalFilters !== undefined && Object.keys(this.props.automationGlobalFilters).length > 0 ?
                            this.props.automationGlobalFilters.actionListArr && this.props.automationGlobalFilters.actionListArr.length > 0 ?
                                this.props.automationGlobalFilters.actionListArr.map((element, index) => {
                                    return (
                                        <div className="Tags" key={index}>
                                            <span className="tagText">{element.label}</span>
                                            <span className="closeTag" onClick={() => this.removeActionFilter(element.value)}></span>
                                        </div>
                                    )
                                })
                                : '' : ''
                    }
                </div>
                <AddNewAutomation 
                    openDialog={this.state.openDialog}
                    classes = {this.props.classes}
                    closeDialog = {this.closeDialog}
                />
            </div>

        )
    }
}
const mapStateToProps = state => ({
    automationGlobalFilters: state.automations.list.automationGlobalFilters,
    automationTriggerArrayList : state.automations.list.automationTriggerArrayList,
    automationActionArrayList : state.automations.list.automationActionArrayList,
    customTriggerList : state.automations.list.customTriggerList
})
export default withStyles(styles)(
    withRouter(connect(mapStateToProps, { getFiltersList, setAutomationActionFilter, setAutomationTrigger, getAutomationRuleList, setAutomationFilters, setAutomationLoader})(Filters))
)