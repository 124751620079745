import React from 'react'
import Highcharts from 'highcharts'
import '../../../src/Setup/assets/css/dashboard.css'
import ReactHighcharts from 'react-highcharts'
require('highcharts/modules/exporting')(ReactHighcharts.Highcharts);
require('highcharts/modules/no-data-to-display')(ReactHighcharts.Highcharts);

class ShipmentSummaryColumnChart extends React.Component {
  state = {
		categories: [],
		xAxis: '',
		yAxis: '',
		series: []
	}
	componentDidUpdate(prevProps) {			
		if(this.props.seriesData !== this.state.series && this.props.storeData !== this.state.categories) {
			this.setState({
				series: this.props.seriesData,
				categories: this.props.storeData,
				xAxis: this.props.timeSpan,
				yAxis: 'Shipments' 
			})
		}
	}
	
	render() {
		let options = {
			chart: {
				type: 'column',
				zoomType: 'xy',
				resetZoomButton: {
					position: {
						x: 0,
						y: 20
					}
				},
				panning: true,
				panKey: 'shift'
			},
			credits: {
				enabled: false
			},
			legend: {
				squareSymbol: false,
				symbolHeight: 12,
				symbolWidth: 12,
				symbolRadius: 5,
				shadow: false,
				itemMarginBottom: 5,
				itemStyle: {
					fontFamily: 'Roboto',
					fontSize: '14px',
					fontWeight: 500,
					fontStretch: 'normal',
					fontStyle: 'normal',
					lineHeight: 'normal',
					color: '#212121'
				}
			},
			tooltip: {
				
				formatter: function () {
					return '<b>' + this.x + '</b><br/><span style="color:' + this.point.color +'">●</span> ' + this.series.name + ': <b>' + Math.round(this.y) + '</b>'
				}
			},
			plotOptions: {
				column: {
					stacking: 'normal',
				},
			},
			exporting: {
				buttons: {
					contextButton: {
						enabled: true,
						symbolFill: '#B5C9DF',
						symbol: 'url(https://trackhive-images.s3-ap-southeast-1.amazonaws.com/dashboardIcon/chartIcon.svg)'
					}
				}
			},
			xAxis: {
				categories: this.state.categories,
				title: {
					text: this.state.xAxis,
					style: {
						fontFamily: 'Roboto',
						fontSize: '12px',
						fontWeight: 500,
						fontStretch: 'normal',
						fontStyle: 'normal',
						lineHeight: 'normal'	
					 }  
				},
				labels: {
					style: {
						fontFamily: 'Roboto',
						fontSize: '11px',
						fontWeight: 500,
						fontStretch: 'normal',
						fontStyle: 'normal',
						lineHeight: 'normal'
					}
				}
			},
			yAxis: {
				labels: {
					style: {
						fontFamily: 'Roboto',
						fontSize: '11px',
						fontWeight: 500,
						fontStretch: 'normal',
						fontStyle: 'normal',
						lineHeight: 'normal'
					}
				},
				stackLabels: {
					enabled: true,
					style: {
						fontFamily: 'Roboto',
						fontSize: '11px',
						fontWeight: 500,
						fontStretch: 'normal',
						fontStyle: 'normal',
						lineHeight: 'normal',
						color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
					},
					formatter: function () {
						return Math.round(this.total)
					}
				},
				title: {
					text: this.state.yAxis,
					style: {
						fontFamily: 'Roboto',
						fontSize: '12px',
						fontWeight: 500,
						fontStretch: 'normal',
						fontStyle: 'normal',
						lineHeight: 'normal'	
					 }
				}
			},
			series: this.state.series,
			title: {
				text: ''
			},

		}

		return (
			<ReactHighcharts config={options} />
		)
	}
}
export default ShipmentSummaryColumnChart