import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import '../../static/css/automation.scss'
import Card from './EnhancedTable'
import { getAutomationRuleList, setAutomationFilters, setCourierList, getCourierList, setAutomationLoader } from '../../actions/actionMethods'
import queryString from 'query-string'
var isEmpty = require('lodash.isempty')


class Automation extends React.Component {
    constructor(props) {
        super(props)
        this.flashMessage = ''
        this.isSuccess = true
        this.state = {
            globalFilters: {}
        }
    }

    componentWillMount() {
        this.props.setAutomationFilters({})
        if (this.props.location.search !== '') {
            let filters = {}
            let urlFilters = queryString.parse(this.props.location.search)
            if (urlFilters.actionListArr) {
                let actionListArr = urlFilters.actionListArr.split(',')
                let actionListId = urlFilters.actionListId.split(',')
                let newActionListArr = []
                for (let i = 0; i < actionListArr.length; i++) {
                    let eachActionLabel = actionListArr[i]
                    let eachActionValue = actionListId[i]
                    newActionListArr.push({
                        label: eachActionLabel,
                        value: eachActionValue
                    })
                }
                filters['actionListArr'] = newActionListArr
            }
            if (urlFilters.triggerListArr) {
                let newTriggerList = urlFilters.triggerListArr.split(',')
                let newTriggerid = urlFilters.triggerListId.split(',')
                let newTriggerArrList = []
                for (let i = 0; i < newTriggerList.length; i++) {
                    let eachTrackingLabel = newTriggerList[i]
                    let eachTrackingValue = newTriggerid[i]
                    newTriggerArrList.push({
                        label: eachTrackingLabel,
                        value: eachTrackingValue
                    })
                }
                filters['triggerListArr'] = newTriggerArrList
            }
            if (urlFilters.typeListArr) {
                let typeListArr = urlFilters.typeListArr.split(',')
                let newTypeListArr = []
                for (let i = 0; i < typeListArr.length; i++) {
                    let eachType = typeListArr[i]
                    newTypeListArr.push({
                        label: eachType,
                        value: eachType
                    })
                }
                filters['typeListArr'] = newTypeListArr
            }
            this.props.setAutomationFilters(filters)
            this.setState({
                globalFilters: filters
            }, () => {
                this.applySearchAndFilter()
            })
        } else {
            this.props.setAutomationLoader()
            this.props.getAutomationRuleList()
        }
    }
    applySearchAndFilter = () => {
        let urlParams = {}
        let filters = ''
        let filterFlag = !isEmpty(this.state.globalFilters)
        if (filterFlag) {
            let storeObj = this.state.globalFilters

            if (storeObj.actionListArr) {
                let actionList = storeObj.actionListArr.map((element, index) => {
                    return (element.label)
                })
                let actionListId = storeObj.actionListArr.map((element, index) => {
                    return (element.value)
                })
                urlParams['actionListArr'] = actionList.join()
                urlParams['actionListId'] = actionListId.join()
            }
            if (storeObj.triggerListArr) {

                let triggerList = storeObj.triggerListArr.map((element, index) => {
                    return (element.label)
                })
                let triggerListId = storeObj.triggerListArr.map((element, index) => {
                    return (element.value)
                })
                urlParams['triggerListArr'] = triggerList.join()
                urlParams['triggerListId'] = triggerListId.join()
            }
            if (storeObj.typeListArr) {
                let typeList = storeObj.typeListArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['typeListArr'] = typeList.join()
            }


            let globalFiltersValue = {}
            let requestParams = {}
            globalFiltersValue = this.state.globalFilters
            let actionListArrVal = []
            let triggerListArrVal = []
            let typeListArrVal = []

            if (globalFiltersValue.actionListArr && globalFiltersValue.actionListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.actionListArr.length; i++) {
                    actionListArrVal.push({
                        label: globalFiltersValue.actionListArr[i].label,
                        value: globalFiltersValue.actionListArr[i].value
                    })
                }
                requestParams.action = actionListArrVal
            }
            if (globalFiltersValue.triggerListArr && globalFiltersValue.triggerListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.triggerListArr.length; i++) {
                    triggerListArrVal.push({
                        label: globalFiltersValue.triggerListArr[i].label,
                        value: globalFiltersValue.triggerListArr[i].value
                    })
                }
                requestParams.trigger = triggerListArrVal
            }
            if (globalFiltersValue.typeListArr && globalFiltersValue.typeListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.typeListArr.length; i++) {
                    typeListArrVal.push(globalFiltersValue.typeListArr[i].value)
                }
                requestParams.event_type = typeListArrVal
            }
            this.props.setAutomationLoader()
            this.props.getAutomationRuleList(requestParams)
        }


        filters = `?${queryString.stringify(urlParams, { strict: false })}`
        this.props.history.push(`/automations` + filters)
    }

    componentDidMount() {
        this.getCourierList()
    }
    getCourierList = () => {
        this.props.getCourierList().then((response) => {
            if (response && response.data && response.data.meta && response.data.meta.code >= 200 && response.data.meta.code <= 299) {
                let setData = []
                for (let i = 0; i < response.data.data.length; i++) {
                    let eachCourierList = response.data.data[i]
                    setData.push({
                        value: eachCourierList.slug,
                        name: eachCourierList.title
                    })
                }
                this.props.setCourierList(setData)
            }
        })
    }

    toggleFlashMessage = (data, message = '', isSuccess = true) => {
        this.isSuccess = isSuccess
        if (message) {
            this.flashMessage = message
        } else {
            this.flashMessage = ''
        }
        this.setState({ showFlashMessage: data })
    }

    render() {
        return (
            <div className="automationModule">
                <div className="commonTopHeader">
                    <h1><span className="automationIcon"></span>Automation</h1>
                    <div className="headerSearchDiv">
                        {/* <SearchTracking /> */}
                    </div>
                </div>
                <div className="dataContainer">
                    <Card toggleFlashMessage={this.toggleFlashMessage} />
                </div>
            </div>
        )
    }
}

export default (withRouter(connect(null, { getAutomationRuleList, setAutomationFilters, setCourierList, getCourierList, setAutomationLoader })(Automation)))