import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { SaveIcon, CloseIcon, cancelIconSettings } from '../../../common/imageList'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
var isEmpty = require('lodash.isempty')

const styles = () => ({
    drawerContainer: {
        maxWidth: '662px',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px',
        position : 'absolute',
        borderLeft: 'none',
        '& .MuiList-root': {
          listStyle: 'none !important',
          margin: '-2px !important',
          paddingTop: '9px'
        }
      },
      drawerHeader: {
        width: '657px',
        height: '48px',
        background: "#5e6367",
        position: 'sticky',
        top : 0
      },
      drawerTitle: {
        color: '#ffffff',
        padding: "10px 0px 8px 32px",
        fontSize: '20px',
        letterSpacing: '0.15px'
      },
      draweCancelButton: {
        marginLeft: 'auto',
        position: 'relative',
        // paddingRight: '29px'
        marginRight: '14px'
      },
      drawerTextFieldContainer: {
        padding: "30px 24px 0 0px",
        display: 'inline-block',
      },
      listIcon : {
        minWidth : '36px',
        '& .PrivateSwitchBase-root-596' :{
          paddingLeft : '0px',
          paddingTop : '5px',
          paddingBottom : '5px'
        }
    },
    drawerRadioLable : {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.16px',
        color: '#5e6367',
    },
    drawerFooter: {
        position: 'absolute',
        bottom: '0px',
        width: '657px',
        backgroundColor: '#ffffff',
        zIndex: '100',
        borderTop : '1px solid #d9d9ec',
        height : '81px'
      },
      saveBtn: {
        background: '#1a73e8',
        marginTop: "22px",
        marginLeft :'32px',
        width: '88px',
        height: '36px',
        // margin: '32px 0px 32px 32px',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px',
        color: '#ffffff',
        "&:hover": {
          background: '#1a73e8'
        }
      },
      deleteButtonAction: {
        background: "#ffffff",
        color: "#1a73e8",
        float: 'right',
        margin: "22px 32px 0px 0px",
        width: '100px',
        height: '36px',
        border: '1px solid #1a73e8',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px'
      },
      listItemRoot : {
          paddingTop : "0px",
          paddingBottom : '0px'
      },
      radioRoot : {
        padding : '9px 9px 9px 0px'
      }
});
class TriggerDrawer extends React.Component {
    state = {
        checkedOption: this.props.triggerOption[0],
        requestData: {},
        showLoader: false
    }
   
    onOptionChangeHandler = (option) => {
            this.setState({
                checkedOption : option
            })
    }

    toggleDrawer = () => {
        this.props.closeDrawer()
    }

    triggerSave = () =>{
        this.props.triggerSave(this.state.checkedOption)
        this.props.closeDrawer()
        
    }
    render() {
      const { classes } = this.props
        return (
            <React.Fragment>
                <Drawer
                    classes={{
                        paper: classes.drawerContainer,
                    }}
                    variant="temporary"
                    anchor="right"
                    open={this.props.openDrawer}
                    onClose={this.toggleDrawer}
                    BackdropProps={{ invisible: true }}
                    transitionDuration={500}
                >
                    <Grid container className={classes.drawerHeader}>
                        <Typography className={classes.drawerTitle}>Shipment Triggers</Typography>
                        <IconButton
                            color="inherit"
                            onClick={(event) => this.toggleDrawer()}
                            className={classes.draweCancelButton}
                            edge="start"
                        >
                            <img alt="" src={cancelIconSettings} />
                        </IconButton>
                    </Grid>
                    <Grid container className={classes.drawerTextFieldContainer}>
                        <div className="exprtWrapper">
                            <div className="ModalText">
                                <p className="removeBlockPadding">
                                   Triggers
                                </p>
                            </div>
                                <div className="ModalList">
                                    <List>
                                        {this.props.triggerOption.map((option) => {
                                            const labelId = `checkbox-list-label-${option.value}`;

                                            return (
                                                <ListItem  classes={{
                                                    root: classes.listItemRoot
                                                }}
                                                disableGutters={true} key={option.value} role={undefined} dense button>
                                                    <ListItemIcon className={classes.listIcon}>
                                                        <Radio
                                                            classes={{
                                                                root : classes.radioRoot
                                                            }}
                                                            checked={option.value === this.state.checkedOption.value }
                                                            onChange={(event) => this.onOptionChangeHandler(option)}
                                                            name="radio-button-demo"
                                                            color='primary'
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText className={classes.drawerRadioLable} disableTypography={true} id={labelId} primary={option.label} />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </div>
                        </div>
                    </Grid>
                    <footer className={classes.drawerFooter} justify="left">
                        <Button
                            onClick={this.triggerSave}
                            startIcon={<SaveIcon />}
                            // disabled={}
                            className={classes.saveBtn}>
                            Save
                        </Button>
                        {/* {(this.state.showLoader) && <CircularProgress size={22} className={classes.buttonProgress} />} */}
                        <Button startIcon={<CloseIcon />} onClick={(event) => this.toggleDrawer()} className={classes.deleteButtonAction}>
                            CANCEL
                              </Button>
                    </footer>
                </Drawer>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(TriggerDrawer);