import { combineReducers } from "redux"


import tracking from './index'
import loader from "./loadingReducer"

const rootReducer = combineReducers({
 list:tracking,
 loader
});

export default rootReducer;
