import React from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { SaveIcon, CloseIcon, cancelIconSettings } from '../../../common/imageList'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { ACTION } from '../../constants/defaultValues'
import { setAutomationAction,removeAutomationActionByKey} from '../../actions/actionMethods'
import { ACTION_TYPE, ACTION_REQUEST_PARAMS } from '../../constants/actionTypes'
import { ACTION_TYPE_ERROR, ACTION_VALUE_ERROR, EMAIL_ID_REQUIRED } from '../../constants/errorMessages'
import LoadComponent from '../../common/LoadComponent'
import { getLabel } from '../../common/Functions'
import ActionListCard from './ActionListCard'
import { Scrollbars } from 'react-custom-scrollbars';
import FlashMessage from '../../../common/alertSnackBar'
import { emailRegex } from '../../../static/constants'

const styles = () => ({
    drawerContainer: {
        maxWidth: '519px',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px',
        position: 'absolute',
        borderLeft: 'none',
        '& .MuiList-root': {
            listStyle: 'none !important',
            margin: '0 !important',
            paddingTop: '9px'
        }
    },
    drawerHeader: {
        width: '514px',
        height: '48px',
        background: "#5e6367",
        position: 'sticky',
        top: 0
    },
    drawerTitle: {
        color: '#ffffff',
        padding: "10px 0px 8px 32px",
        fontSize: '20px',
        letterSpacing: '0.15px'
    },
    draweCancelButton: {
        marginLeft: 'auto',
        position: 'relative',
        // paddingRight: '29px'
        marginRight: '14px'
    },
    listIcon: {
        minWidth: '36px',
        '& .PrivateSwitchBase-root-596': {
            paddingLeft: '0px',
            paddingTop: '5px',
            paddingBottom: '5px'
        }
    },
    drawerRadioLable: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.16px',
        color: '#5e6367',
    },
    drawerFooter: {
        position: 'absolute',
        bottom: '0px',
        width: '514px',
        backgroundColor: '#ffffff',
        zIndex: '100',
        borderTop: '1px solid #d9d9ec',
        height: '81px'
    },
    saveBtn: {
        background: '#1a73e8',
        marginTop: "22px",
        marginLeft: '32px',
        width: '88px',
        height: '36px',
        // margin: '32px 0px 32px 32px',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px',
        color: '#ffffff',
        "&:hover": {
            background: '#1a73e8'
        }
    },
    deleteButtonAction: {
        background: "#ffffff",
        float: 'right',
        margin: "22px 32px 0px 0px",
        width: '100px',
        height: '36px',
        border: '1px solid #1a73e8',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px',
        color: '#1a73e8',
    },
    snackBar : {
        position : 'absolute'
    }
});
const initValues = {
    action: { label: 'Select', value: '', component: '' }
}
const scheduleOption = [{ label: 'Immediately', value: '' }, { label: 'Schedule', value: 'schedule_at' }]
const scheduleTime = [{ label: 'Hours', value: 'hours' }, { label: 'Days', value: 'days' }]

class ActionDrawer extends React.Component {

    constructor(props) {
        super(props);
        this.errorMsg = { type: 'ERROR', 'message': ACTION_TYPE_ERROR }
        this.actionDataObj = null
        this.actionTypeObj = null
        // if (this.props.parameterId === null)
            // this.props.removeAutomationActionByKey({ 'index': this.props.index, 'value': null })
        this.actionRequestParams = this.props.actionParams[this.props.index] !== undefined ? this.props.actionParams[this.props.index] : {}
        //this.actionObjectKey = getActionObjectKey(this.props.trigger, this.props.triggerCondition)
        this.isCustomFlag = this.props.trigger === 'SHIPMENT' ? 'SHIPMENT' : 'CUSTOM'
        // this.actionList = ACTION[this.isCustomFlag].filter(action => {
        //     return action.trigger.includes(props.triggerCondition)
        // })
        this.actionList = ACTION['SHIPMENT']
        this.state = {
            optionAction: this.actionList && this.actionList.length > 0 ? this.actionList : initValues.action,
            selectedOptionAction: this.props.actionParams[this.props.index] !== undefined ? this.getSelectedActionObject() : initValues.action,
            selectedScheduleOption: scheduleOption[0],
            selectedTimeOption: scheduleTime[0],
            scheduleHour: '',
            actionTypeError: false,
            editFlag: false,
            requestData: {},
            showLoader: false,
            isValidEmail : true
        }
    }

    getSelectedActionObject = () => {
        let actionList = ACTION['SHIPMENT'].filter(action => {
            return action.trigger.includes('CREATED')
        })
        return getLabel(actionList, this.props.actionType[this.props.index], true)
    }

    toggleDrawer = () => {
        this.props.closeDrawer()
    }

    componentDidMount() {
        if (this.props.parameterId && (Object.keys(this.props.automationDetail).length > 0) && this.props.setDataFlag) {
            let automationData = this.props.automationDetail
            for(let index = 1; index <= automationData.action.length; index++){
                let actionObj = automationData.action[parseInt(index, 10) - 1]
                if (actionObj && this.props.actionType[parseInt(index, 10)] !== null) {
                    this.actionRequestParams = actionObj['request_params']
                    if (actionObj['request_params']['schedule_time'] !== undefined) {
                        let option = scheduleTime.filter(data => data.value === actionObj['request_params']['duration_type'])
                        this.setState({ selectedScheduleOption: scheduleOption[1], scheduleHour: actionObj['request_params']['schedule_time'], selectedTimeOption: option[0] })
                    }
                    this.handleChangeAction(getLabel(this.actionList, actionObj.type, true), index)
                    this.setactionValueForUpdate(actionObj.type, index)
                }
            }
            
        }
    }

    setactionValueForUpdate = (type, index) => {
        let requestParams = {}
        let actionRequest = {}
       if(type === 'ASSIGN_SHIPMENT' && this.actionRequestParams.user_id && this.actionRequestParams.user_email) {
        requestParams.user_id = this.actionRequestParams.user_id
        requestParams.user_email = this.actionRequestParams.user_email
       } else if(type === 'REPEAT_ACTION_REMOVE' && this.actionRequestParams.automation_id && this.actionRequestParams.automation_name){
        requestParams.automation_name = this.actionRequestParams.automation_name
        requestParams.automation_id = this.actionRequestParams.automation_id
       } else if(type === 'EMAIL' && this.actionRequestParams.template_id && this.actionRequestParams.template_name){
        requestParams.template_id = this.actionRequestParams.template_id
        requestParams.template_name = this.actionRequestParams.template_name
           if(this.actionRequestParams.receiver_type){
               let person = { label: (String(this.actionRequestParams.receiver_type).charAt(0).toUpperCase() + String(this.actionRequestParams.receiver_type).slice(1)), value: this.actionRequestParams.receiver_type }
               if (person.value === 'custom') {
                    if(this.actionRequestParams.custom_email !== ''){
                        requestParams.receiver_type = person.value
                        var emailArr = (this.actionRequestParams.custom_email.includes(',') ? this.actionRequestParams.custom_email.replace(/\s/g, "").split(/,|;/) : this.actionRequestParams.custom_email)
                        let emailIdValue = ''
                        let emailIdArrValue = ''
                        for (var i = 0; i < emailArr.length; i++) {
                            if (emailRegex.test(emailArr[i])) {
                                emailIdValue = this.actionRequestParams.custom_email
                            }
                        }

                        emailIdValue = (this.actionRequestParams.custom_email.includes(',') ? emailIdValue.replace(/\s/g, '') : this.actionRequestParams.custom_email)
                        emailIdArrValue = ((typeof this.actionRequestParams.custom_email) == 'string' ? emailIdValue.split(',') : emailIdValue)
                        requestParams.custom_email = emailIdArrValue
                    }
               } else {
                requestParams.receiver_type = person.value
               }
           }
            if(this.actionRequestParams.action_type){
                requestParams.action_type = this.actionRequestParams.action_type.value
                if (this.actionRequestParams.action_type.value == 'Immediately And Repeat') {
                    let duratioType ={ label: (String(this.actionRequestParams.duration_type).charAt(0).toUpperCase() + String(this.actionRequestParams.duration_type).slice(1).toLowerCase()) + "s", value: this.actionRequestParams.duration_type }
                    if (duratioType.value != '' && this.actionRequestParams.hours != '') {
    
                        if (duratioType.value == 'DAY') {
                            requestParams.hours = (this.actionRequestParams.hours * 24)
                        } else {
                            requestParams.hours = this.actionRequestParams.hours
                        }
                        requestParams.duration_type = duratioType.value
                    }
                }
            }
        } else if(type === 'TAG'){
            let selectTagData = []
            if (this.actionRequestParams) {
                for (let j = 0, len = this.actionRequestParams.length; j < len; j++) {
                    let tag = this.actionRequestParams[j]
                    let obj = {}
    
                    obj.value = tag.value
                    obj.name = tag.name
                    obj.color = tag.color
    
                    selectTagData.push(obj)
                }
                requestParams = selectTagData
            }
        }
        actionRequest['request_params'] = requestParams
        actionRequest['is_request_valid'] = true
        let actionObj = { index: index, value: actionRequest, type: ACTION_REQUEST_PARAMS }

        this.props.setAutomationAction(actionObj)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.openDrawer !== this.props.openDrawer && this.props.openDrawer === false) {
            this.handleCancel()
        }
        if (prevProps.isActionEdit !== this.props.isActionEdit && this.props.isActionEdit === true) {
            let selectedOptionAction = this.props.actionParams[this.props.index] !== undefined ? this.getSelectedActionObject() : initValues.action
            this.setState({ selectedOptionAction })
            this.actionRequestParams = this.props.actionParams[this.props.index] !== null && this.props.actionParams[this.props.index] !== undefined && this.props.actionParams[this.props.index] !== '' ? this.props.actionParams[this.props.index]['request_params'] : {}
        }
    }
    setEmailValidationMessage = (flag) => {
        this.setState({
            isValidEmail : flag
        })
    }
    handleChangeAction = (selectedOptionAction, index = null) => {
        let actionIndex = index === null ? this.props.index : index
        // if (selectedOptionAction && this.state.selectedOptionAction.value !== selectedOptionAction.value) {
        //     this.setState({ selectedOptionAction })
        // }
        if (selectedOptionAction && this.state.selectedOptionAction.value !== selectedOptionAction.value) {
            if (!this.isActionTypePresent(selectedOptionAction.value)) {
                // this.setState({ selectedOptionAction });
                this.setActionTypeError(false)
                this.props.removeAutomationActionByKey({ 'index': actionIndex, 'value': null })
                if (selectedOptionAction.value !== '') {
                    this.actionTypeObj = { 'index':actionIndex, 'value': selectedOptionAction.value, 'type': ACTION_TYPE }
                    if(this.props.parameterId !== null && index !== null){
                        this.props.setAutomationAction(this.actionTypeObj)
                    } else{
                        this.setState({ selectedOptionAction });
                    }
                    // this.props.setAutomationAction(actionTypeObj)
                }
            } else {
                this.actionTypeObj = null
                this.setState({ selectedOptionAction: initValues.action });
                this.setActionTypeError(true)
                this.errorMsg = { type: 'ERROR', 'message': ACTION_TYPE_ERROR }
            }
        }
    }

    isActionTypePresent = (conditionType = null) => {
        if (Object.keys(this.props.actionType).length > 0) {
            let type = {}
            type = Object.keys(this.props.actionType).filter(index => this.props.actionType[index] === conditionType)
            let returnValue = null
            if (Object.keys(type).length > 0) {
                returnValue = true
            } else {
                returnValue =  false
            }
            return returnValue
        } else {
            return false
        }
    }

    setActionTypeError = (isError = false) => {
        if (this.state.actionTypeError !== isError) {
            this.setState({ actionTypeError: isError })
        }
    }

    handleCancel = () => {
        if(!this.props.isActionEdit) {
            this.setState({ selectedOptionAction: initValues.action })
        } else {
            this.toggleDrawer()
        }
    }

    saveAction = () => {
        if(this.actionDataObj.value && this.actionDataObj.value.is_request_valid === true && this.state.isValidEmail) {
            this.props.saveAction()
            this.toggleDrawer()
            this.props.setAutomationAction(this.actionDataObj)
            this.props.setAutomationAction(this.actionTypeObj)
        } else {
            this.setActionTypeError(true)
            if(!this.state.isValidEmail) {
                this.errorMsg ={ type: 'ERROR', 'message': EMAIL_ID_REQUIRED }
            } else {
                this.errorMsg ={ type: 'ERROR', 'message': ACTION_VALUE_ERROR }
            }
        }
    }

    setActionDataToSave = (actionObj) => {
        this.actionDataObj = actionObj
    }

    toggleFlashMessage = () => {
        this.setActionTypeError(false)
    }

    render() {
        const { classes, ...other } = this.props
        return (
            <React.Fragment>
                <Drawer
                    classes={{
                        paper: classes.drawerContainer,
                    }}
                    variant="temporary"
                    anchor="right"
                    open={this.props.openDrawer}
                    onClose={this.toggleDrawer}
                    BackdropProps={{ invisible: true }}
                    transitionDuration={500}
                >
                    <Grid container className={classes.drawerHeader}>
                        <Typography className={classes.drawerTitle}>Choose an Action</Typography>
                        <IconButton
                            color="inherit"
                            onClick={this.toggleDrawer}
                            className={classes.draweCancelButton}
                            edge="start"
                        >
                            <img alt="" src={cancelIconSettings} />
                        </IconButton>
                    </Grid>
                    <Scrollbars autoHide style={{ height: "100%" }}>
                        <Grid container className={classes.drawerTextFieldContainer}>
                            {(this.state.selectedOptionAction.value) ?
                                <LoadComponent
                                    editFlag={this.state.editFlag}
                                    scheduleOption={this.state.selectedTimeOption.value}
                                    scheduleHour={this.state.scheduleHour}
                                    actionRequestParams={this.actionRequestParams}
                                    index={this.props.index}
                                    type="action"
                                    page={this.state.selectedOptionAction.component}
                                    headerTitle={this.state.selectedOptionAction.label}
                                    setActionDataToSave={this.setActionDataToSave}
                                    setEmailValidationMessage = {this.setEmailValidationMessage}
                                    {...other}
                                />
                                :
                                <ActionListCard
                                    actionList={this.state.optionAction}
                                    handleChangeAction={this.handleChangeAction}
                                />
                            }

                        </Grid>
                    </Scrollbars>
                    {(this.state.selectedOptionAction.value) ?
                        <footer className={classes.drawerFooter}>
                            <Button
                                onClick={this.saveAction}
                                startIcon={<SaveIcon />}
                                // disabled={}
                                className={classes.saveBtn}>
                                Save
                            </Button>
                            {/* {(this.state.showLoader) && <CircularProgress size={22} className={classes.buttonProgress} />} */}
                            <Button startIcon={<CloseIcon />} onClick={this.handleCancel} className={classes.deleteButtonAction}>
                                CANCEL
                            </Button>
                        </footer>
                        : null
                    }
                    {(this.state.actionTypeError) ?
                        <FlashMessage classes={classes.snackBar}open={this.state.actionTypeError} messageDetails={this.errorMsg} toggleFlashMessage={this.toggleFlashMessage} />
                        : null
                    }
                </Drawer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    actionType: state.automations.list.actionType,
    automationDetail: state.automations.list.automationDetail,
    actionParams: state.automations.list.actionRequestParams
})

export default connect(mapStateToProps, { setAutomationAction, removeAutomationActionByKey})(withStyles(styles)(ActionDrawer))