import React from 'react'
import { withStyles } from '@material-ui/core'
import ReactCountryFlag from "react-country-flag";
import { topCountries } from '../actions/actionMethods'
import { changeDateFunction } from '../common/Functions'
import { worldIcon } from '../../common/imageList'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import DashboardPopover from "../common/DashboardPopover";
import DashboardCourierAutocomplete from "../common/DashboardCourierAutocomplete";
import Styles from '../static/Styles'
class TopCountries extends React.Component {
	constructor(props) {
		super(props)
		this.selectedCourier = null
		this.state = {
			toDate: '',
			fromDate: '',
			countryData: [],
			countryLoadingFlag: true,
			fromUTCDateValue: '',
			toUTCDateValue: ''
		}
	}
 
	componentWillMount() {
		let dateObj = changeDateFunction('30Days', false)
		this.setState({ fromDate: dateObj.fromDate, toDate: dateObj.toDate })
		this.getTopCountries(dateObj.fromDate, dateObj.toDate)
	}
	getTopCountries = (fromDate, toDate) => {
		let data = {}
		data.from_date = fromDate
		data.to_date = toDate
		if (this.selectedCourier && this.selectedCourier !== null && this.selectedCourier.value !== 'all') {
			data.courier = this.selectedCourier.value
		}
		this.setState({ countryLoadingFlag: true })
		topCountries(data).then(res => {
			if (res.status >= 200 && res.status <= 299) {
				this.setState({
					countryData: res.data.data,
					countryLoadingFlag: false
				})
			} else {
				this.setState({ countryLoadingFlag: false })
			}
		}).catch(error => {
			console.log(error)
			this.setState({ countryLoadingFlag: false })
		})
	}
 
	setCustomDateRange = (fromUTCDate, toUTCDate) => {
		this.getTopCountries(fromUTCDate, toUTCDate)
		this.setState({
			fromUTCDateValue: fromUTCDate,
			toUTCDateValue: toUTCDate,
			fromDate: fromUTCDate,
			toDate: toUTCDate
		})
	}
	setDateType = (dateType, fromDate, toDate) => {
		this.setState({dateType: dateType, fromDate: fromDate, toDate:toDate, fromUTCDateValue: '', toUTCDateValue: ''})
	}
	handleChangeCourier = (selectedOption) => {
		this.selectedCourier = selectedOption
		let isCustomRangeDate = ((this.state.fromUTCDateValue && this.state.fromUTCDateValue !== '') && (this.state.toUTCDateValue && this.state.toUTCDateValue !== ''))

		if (isCustomRangeDate) {
			this.getTopCountries(this.state.fromUTCDateValue, this.state.toUTCDateValue)
		}
		else {
			this.getTopCountries(this.state.fromDate, this.state.toDate)
		}
	}

	render() {
		const { classes } = this.props
		let topCountryData = []
		for (let i = 0; i < this.state.countryData.length; i++) {
			let country = this.state.countryData[i]
			topCountryData.push(
				<div className="topCourierInlineDiv" key={i}>
					<div className="courierNameLogo">
						<div className="courierLogo">
							<ReactCountryFlag
								styleProps={{
									width: '30px',
									height: '30px'
								}}
								code={country.country_iso}
								svg
							/>
						</div>
						<div className="courierName">
							<span>{country.country_name}</span>
						</div>
					</div>
					<div className="noOfCourier">{country.count}</div>
				</div>
			)
		}
		return (
			<Card id="shipments" className="cardNormal" style={{ cursor: 'default', float: 'none', width: '99.75%', height: 'auto', position: 'relative', bottom: '16px' }}>
				<CardHeader className="shipmentStatus"
					avatar={
						<Avatar>
							<img alt="" src={worldIcon} />
						</Avatar>
					}
					action={
						<Grid className="MuiCardHeader-action" style={{ display: "inline-flex", float: 'right' }}>
							{
								this.props.addedCourierList && this.props.addedCourierList.length > 0 ?
									<div >
										<DashboardCourierAutocomplete
											addedCourierList={this.props.addedCourierList}
											handleChangeCourier={this.handleChangeCourier}
										/>
									</div> : ''
							}
							<DashboardPopover 
								type='TopCountries'
								getData={this.getTopCountries}
								setCustomDateRange={this.setCustomDateRange}
								setDateType={this.setDateType}
							/>
						</Grid>
					}
					title={<Typography className="headingText">Top 10 Countries</Typography>}
				/>
				<CardContent className="topCourierListMainDiv" style={{ padding: '0px' }}>
					{this.state.countryLoadingFlag ? <div style={{ width: '400px', margin: '60px auto', opacity: '0.5' }}> <CircularProgress size={42} className={classes.progress} /> </div>
						: this.state.countryData.length > 0 ?
							topCountryData : <div className='noData' style={{ lineHeight: '0rem' }} >No Data to Display.</div>}
				</CardContent>
			</Card>
		)
	}
}
export default withStyles(Styles)(TopCountries)