import React from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import axios from 'axios'
import jwt from 'jsonwebtoken'
import { TrackingBtnIcon } from '../../../common/imageList'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {httpRegex} from '../../../static/constants'
const options = [
    { label: 'Tracking Number', value: 'tracking_number' },
    { label: 'Order Number',  value: 'order_number' }
];
const trackingSecretKey = process.env.REACT_APP_JWT_SECRET_KEY
const prefixURL = process.env.REACT_APP_API_URL

const styles = () => ({
    textFieldLabel: {
      height: '19px',
      width : '98%',
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.16px',
      color: '#666666',
      transform: 'translate(14px, 16px) scale(1)'
    },
    textField: {
        width: '98%',
        height: '50px',
        backgroundColor: '#fff',
        "& label.Mui-focused": {
            color: '#1a73e8'
        },
        "& .MuiOutlinedInput-root": {
            height: "50px",
            "&.Mui-focused fieldset": {
            borderColor: '#1a73e8'
            }
        }
    },
    button: {
      background: '#1a73e8',
      minWidth: '132px',
      position: 'relative',
      height: '50px',
      fontFamily: 'Roboto',
      fontSize: '18px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.14px',
      color: '#ffffff',
      float: 'right',
      textTransform: 'none',
      "&:hover": {
        background: '#1a73e8'
      }
    },
    listItemRoot : {
        width : '30%'
    },
    listIcon : {
   
        // paddingLeft : '0px',
        minWidth : '36px',
        '& .PrivateSwitchBase-root-596' :{
          paddingLeft : '0px',
          paddingTop : '5px',
          paddingBottom : '5px'
        }
      },
      radioLable : {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.16px',
        color: '#5e6367',
    },
    radioRoot: {
        padding: '9px 9px 9px 0px'
    },
    radioDiv : {
        display : 'flex'
    }
  });

class TrackingPageWithSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            htmlValue: '',
            trackingsData: [],
            loader: true,
            noTrackingMessage: '',
            trackingID: '',
            currentStatus: '',
            isDomainExistMessage: '',
            domainLogo: '',
            domainContact: '',
            domainWebsite: '',
            inputValue: '',
            isInputError: false,
            checkedOption: options[0],
        };
    }
    componentDidMount() {

        setTimeout(() => {
            this.autoSubmit()
        }, 500);
    }
    autoSubmit = () => {
        this.recaptcha.execute()

    }
    UNSAFE_componentWillMount

    onResolved = () => {
        let result = window.location.href.replace(httpRegex, '$1')
        let requestBody = {}
        requestBody.domain_name = result
        let signedToken = jwt.sign({ payload: requestBody }, trackingSecretKey, { expiresIn: 60 })
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${signedToken}`
        }
        axios.post(`${prefixURL}/tracking_page_search?rc_token=${this.recaptcha.getResponse()}`, requestBody, { headers: headers }).then(response => {
            this.setState({
                domainLogo: response.data.data.logo,
                domainWebsite: response.data.data.website_url,
                domainContact: response.data.data.contact_no,
                loader: false
            })
        }).catch(error => {
            if (error.response && error.response.data) {
                if (error.response && !(error.response.status >= 200 && error.response.status <= 299)) {
                    this.setState({
                        isDomainExistMessage: error.response.data.meta.message[0],
                        loader: false
                    })
                }
            }
        })
        //this.props.history.push(`/trackingPage/${trackId}?courier=${courier}`)
    }
    handleInputValue = (val) => {
        this.setState({
            inputValue: val,
            isInputError: false
        })
    }
    handleTrackingAction = () => {
        if (this.state.inputValue === '') {
            this.setState({
                isInputError: true
            })
        }
        else {
            if(this.state.checkedOption.value === 'tracking_number') {
                window.open(`/trackingPage/${this.state.inputValue}?searchBy=tracking_number`, '_blank');
                // window.open(`https://${this.state.url}/trackingPage/${this.state.inputValue}?searchBy=tracking_number`, '_blank');
            } else {
                window.open(`/trackingPage/${this.state.inputValue}?searchBy=order_number`, '_blank');
                // window.open(`https://${this.state.url}/trackingPage/${this.state.inputValue}?searchBy=order_number`, '_blank');
            }
        }
    }
    onOptionChangeHandler = (option) => {
        this.setState({ checkedOption: option, isInputError : false })
    }
    showDomainData = (stateValues, classes) => {
        let printData = []
        if (stateValues) {
            printData.push(
                <div className="trackingPageWithSearchMainDiv">
                    <div className="wrapperDiv">
                        <div className="headerDiv">
                            <div class="templateHeaderWrapper">
                                <div class="logoDiv">
                                    {
                                        stateValues.domainWebsite ?
                                            <a href={stateValues.domainWebsite} target="_blank" rel="noopener noreferrer">
                                                <img src={stateValues.domainLogo} alt="domain_logo"/>
                                            </a> :
                                            <img src={stateValues.domainLogo} alt="domain_logo"/>
                                    }
                                </div>
                                {
                                    stateValues.domainContact ?
                                        <div class="supportNumberMainDiv">
                                            <div class="supportLabel">Support</div>
                                            <div class="supportNumber">{stateValues.domainContact}</div>
                                        </div> : ''
                                }
                            </div>
                        </div>
                        <div className="searchMainBodyDiv">
                            <div className="searchInnerDiv">
                                <label>
                                    Enter a tracking number or an order number, and get tracking results.
                                </label>
                                <div className="textBoxAndButtonMainDiv">
                                    <div className="textBoxAndButtonInnerDiv">
                                        <div className="inputDiv">
                                            {/* <input type="text" value={this.state.inputValue} placeholder="Tracking Number" onChange={(e) => this.handleInputValue(e.target.value)} /> */}
                                            <TextField
                                                InputLabelProps={{
                                                    className: classes.textFieldLabel
                                                }}
                                                error={this.state.isInputError ? true : false}
                                                id="tracking_number"
                                                label={this.state.checkedOption.label}
                                                variant="outlined"
                                                className={classes.textField}
                                                value={this.state.inputValue}
                                                onChange={(e) => this.handleInputValue(e.target.value)}
                                            ></TextField>
                                            {
                                                this.state.isInputError ?
                                                    <label className="error-fonts">Please enter {this.state.checkedOption.label.toLowerCase()}</label> : ''}
                                        </div>
                                        <div className="buttonDiv">
                                            {/* <button onClick={this.handleTrackingAction}>
                                                <img src={TrackingBtnIcon} />
                                                <span>Track</span>
                                            </button> */}
                                            <Button
                                                onClick={this.handleTrackingAction}
                                                startIcon={<img src={TrackingBtnIcon} alt="trackingBtn_IC"/>}
                                                className={classes.button}>
                                                Track
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={classes.radioDiv}>
                                    {options.map((option) => {
                                         const labelId = `checkbox-list-label-${option.label}`;
                                        return (
                                            <ListItem  className={classes.listItemRoot} disableGutters={true} key={option.label} role={undefined} dense button>
                                            <ListItemIcon className={classes.listIcon}>
                                                <Radio
                                                    classes={{
                                                        root: classes.radioRoot
                                                    }}
                                                    checked={option === this.state.checkedOption}
                                                    onChange={(event) => this.onOptionChangeHandler(option)}
                                                    name="radio-button-demo"
                                                    color='primary'
                                                />
                                            </ListItemIcon>
                                            <ListItemText className={classes.radioLable} disableTypography={true} id={labelId} primary={option.label} />
                                        </ListItem>
                                        )
                                    })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return printData
    }
    render() {
        const {classes} = this.props
        let displayData = ''
        if (this.state.domainContact !== '' || this.state.domainLogo !== '' || this.state.domainWebsite !== '') {
            displayData = this.showDomainData(this.state, classes)
        }
        else if (this.state.isDomainExistMessage !== '') {
            displayData = <div className="noTrackingDataStyleDiv">{this.state.isDomainExistMessage}</div>
        }
        else {
            displayData = <div className="trackingLoaderDiv">
                <img src="https://trackhive-images.s3-ap-southeast-1.amazonaws.com/TrackingDataLoader.svg" alt="loader"/>
            </div>
        }
        return (
            <React.Fragment>
                <div>
                    <Recaptcha
                        ref={ref => this.recaptcha = ref}
                        sitekey="6LeKRLgUAAAAAHqADRF2PFRefsFTYCo61c_PY5mj"
                        onResolved={this.onResolved}
                    />
                </div>
                {displayData}
            </React.Fragment>
        )
    }
}
// export default TrackingPageWithSearch;
export default withStyles(styles)(TrackingPageWithSearch)
