import React from 'react';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import {StyledTextField, StyledTypography} from '../common/materialComponents'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress';
import {SaveIcon, CloseIcon, cancelIconSettings} from '../../common/imageList'
class ChangePasswordDrawer extends React.Component{

    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.props.closeDrawer()
    };

    render(){
        const {classes} = this.props
        return(
               <React.Fragment>
                 <Drawer
                    variant = "persistent"
                    classes={{
                       paper: classes.drawerPaper
                    }}
                    anchor="right"
                    open={this.props.openDrawer}
                    onClose={this.toggleDrawer(false)}
                    BackdropProps={{ invisible: true }}
                    transitionDuration={500}
                 >
                    <Grid container className={classes.drawerHeader}>
                        <StyledTypography className={classes.drawerTitle}>Change Password</StyledTypography>
                        <IconButton
                            color="inherit"
                            onClick={this.toggleDrawer(false)}
                            className={classes.draweCancelButton}
                            edge="start"
                        >
                            <img alt="" src={cancelIconSettings} />
                        </IconButton>
                    </Grid>

                    <Grid container className={classes.drawerContent}>
                        <StyledTypography className={classes.drawerContentText}>You will be logged out of all active sessions and will have to login again with your new password.</StyledTypography>
                    </Grid>    

                    <Grid container  className={classes.drawerTextFieldContainer}>
                       <StyledTextField
                            error={this.props.isCurrentPasswordError ? true : false}
                            id="current_password"
                            helperText={this.props.isCurrentPasswordError ? "Current password is required." : ""}
                            label="Current Password"
                            variant="outlined"
                            type="password"
                            onBlur = {this.props.checkCurrentPassword}
                            className={classes.drawerFields}
                            value={this.props.currentPassword}
                            onChange={(e,action) => this.props.handleChangePassword(e.target.value,'currentPassword')}
                       ></StyledTextField>
                    </Grid> 
                    <Grid container  className={classes.drawerTextFieldContainer}>
                       <StyledTextField
                            error={this.props.isNewPasswordError || this.props.alphaNumericPasswordflag === false ? true : false}
                            id="new_password" 
                            helperText={this.props.newPasswordLengthError ? "Password length should be greater than 6." : this.props.alphaNumericPasswordflag === false ? "Please enter alphabets and numbers." : ""}
                            label="New Password"
                            variant="outlined"
                            onBlur = {this.props.checkNewPassword}
                            type="password"
                            className={classes.drawerFields}
                            value={this.props.newPassword}
                            onChange={(e,action) => this.props.handleChangePassword(e.target.value,"newPassword")}
                       ></StyledTextField>
                    </Grid> 
                    <Grid container  className={classes.drawerTextFieldContainer}>
                       <StyledTextField
                            error={this.props.isConfirmPasswordError ||this.props.passwordNotMatched ? true : false}
                            id="confirm_password"
                            helperText={this.props.isConfirmPasswordError ? "Confirm password is required." : this.props.passwordNotMatched ? "The new password and confirmation password do not match.": ""}
                            label="Confirm Password"
                            variant="outlined"
                            type="password"
                            onBlur = {this.props.checkConfirmPassword}
                            className={classes.drawerFields}
                            value={this.props.confirmPassword}
                            onChange={(e,action) => this.props.handleChangePassword(e.target.value,"confirmPassword")}
                       ></StyledTextField>
                    </Grid> 

                    <footer justify="left" style={{position: 'fixed', bottom: '0'}}>
                                <Button 
                                    onClick={this.props.changePassword} 
                                    startIcon={<SaveIcon/>} 
                                    disabled={this.props.loading}
                                    className={(this.props.loading) ? classes.drawerSaveBtnDisable :classes.drawerSaveBtn}>
                                    SAVE
                                </Button>
                                {(this.props.loading) && <CircularProgress size={22} className={classes.drawerbuttonProgress} />}
                                 {
                              <Button startIcon={<CloseIcon/>} onClick={this.toggleDrawer(false)} className={classes.drawerDeleteButton}>
                                CANCEL
                              </Button>
                        }
                    </footer>
                </Drawer>
                </React.Fragment>
        )
    }   
}
export default (ChangePasswordDrawer)