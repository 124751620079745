import React from 'react'
import IntegrationCard from './integrationCard'
import ManageIntegrationCard from './manageIntegrationCard'
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import FlashMessage from '../../common/FlashMessage'
import { Channel } from './integrationData'
import Styles from '../../assets/css/Styles'

const REACT_S3_PATH = process.env.REACT_APP_S3_URL

class IntegrationListGrid extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess = false
        this.successErrMsg = ''
        this.state = {
            showFlashMessage: false
        }
    }

    toggleFlashMessage = (data) => {
        this.setState({ showFlashMessage: false})
        this.isSuccess = false
        this.successErrMsg = ''
    }

    handleSuccessErrorFlashMsg = (isSuccess = false, successErrMsg = '') => {
        this.isSuccess = isSuccess
        this.successErrMsg = successErrMsg
        this.setState({ showFlashMessage: true})
    }

    render() {
        const { classes } = this.props
        const menuList = this.props.menu
        return (
            <React.Fragment>
                <div className={classes.content}>
                    <Grid container>
                        {menuList.map((item, index) => {
                            let path = null
                            if(!this.props.isList) {
                                Channel.forEach(function (channel) {
                                    if (channel.channelName === item.display_name) {
                                        path = channel.path
                                    }
                                })
                            }
                            return (
                                <Grid key={index + "-grid"} className={classes.cardGridItem}>
                                    {this.props.isList ?
                                        <ManageIntegrationCard
                                            key={index}
                                            icon={REACT_S3_PATH + "channelImages/112+x+113+logo/" + item.channel_id + ".svg"}
                                            title={item.store_name}
                                            active={item.active}
                                            id={item._id}
                                            channelId={item.channel_id}
                                            isSync = {item.sync}
                                            flashMessage={this.handleSuccessErrorFlashMsg}
                                        />
                                        : <IntegrationCard
                                            key={index}
                                            title={item.display_name}
                                            icon={item.logo}
                                            channelPath={path}
                                        />
                                    }

                                </Grid>
                            )
                        })}
                        {this.props.isList ? 
                            <Grid key={"add-integration-grid"} className={classes.cardGridItem}>
                                <ManageIntegrationCard
                                    key="add-integration"
                                    tabChange={this.props.tabChange}
                                    icon={REACT_S3_PATH + 'addIntegration.svg'}
                                />
                            </Grid>
                        : null }
                    </Grid>
                </div>
                {this.state.showFlashMessage ?
                    <FlashMessage isSuccess={this.isSuccess} message={this.successErrMsg} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                    : ''}
            </React.Fragment>
        )
    }
}

export default withStyles(Styles)(IntegrationListGrid)