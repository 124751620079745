export const users_scrollbar = { position: 'unset', overflow: 'hidden' }
export const scrollbar_left_nav = { width: "100%", height: "100%" }
export const scrollbar = { width: "100%" }
export const splitRegex = /\s*,\s*/
export const phoneRegex=/^[0-9+() -]*$/
export const searchPattern=/([^&=]+)=?([^&]*)/g
export const httpRegex = /https?:\/\/([^.]+).*/
export const ccEmailRegex = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+)$/;
export const searchRegex = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g
export const urlRegEx = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
export const nameRegEx = /[*|\":<>[\]{}`\\()';@&$%0-9#!^~,./?_+-=]/;
export function getRedirectURLParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const rootCss = {
  minWidth: 450,
  paddingTop: "54px",
  paddingBottom: "170px",
  borderRadius: "8px"
}

export const saveBtnCss = {
  color: 'white',
  background: '#1a73e8',
  fontSize: '14px',
  letterSpacing: '0.14px',
  "&:hover": {
      background: '#1a73e8'
  },
  width: "88px",
}

export const btnProgressCss = {
  color: "#1a73e8",
  position: "relative",
  left: "-60px"
}

export const btnLinkCss = {
  paddingLeft: "0px",
  color: "#1a73e8"
}

export const cardContentCss =  {
  padding: "0px 26px",
  paddingTop: "4px",
  "&:last-child": {
      paddingBottom: "0px"
  }
}

export const btnBarCss = {
  padding: "0px 0px 0px 41px",
  width: "409px"
}

export const textFieldCss = {
  width: '368px',
  height: "56px",
  margin: "32px 41px",
}

export const textFieldFocusCss = {
  "& label.Mui-focused": {
      color: '#1a73e8'
  },
  "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
          borderColor: '#1a73e8'
      }
  }
}