import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { scrollbar } from '../../../../../static/constants';
import RightTriangle from '../../../../common/commonRightArrowComponent'
class BigCommerceIntegration extends React.Component {

    render() {
        return (
            <div className="settingsModule">
                <div className="commonTopHeader">
                    <h1><span className="integrationIcon"></span>Integrations</h1>
                </div>

                <div className="dataContainer">
                    <div id="settingDataScrollerWrapper">
                        <div className="settingsContainer">
                            <div className="settingsLeftSidePanel">
                                <div className="settingsLeftSidePanelWrapper">
                                    <ul>
                                        {/* <Scrollbars autoHide style={scrollbar_left_nav} ref="scrollbars"> */}
                                        <li className={this.props.history.location.pathname.includes('/integrated_list') ? 'active' : ''}>
                                            <Link to="/integrated_list">Manage Integrations</Link>
                                        </li>
                                        <li className={this.props.history.location.pathname.includes('/integrations') ? 'active' : ''}>
                                            <Link to="/integrations">All</Link>
                                        </li>
                                        {/* </Scrollbars> */}

                                    </ul>
                                </div>
                            </div>
                            {/* Settings Left Side Panel Close */}

                            <div className="settingsRightSidePanel">
                                <div className="commonSettingsBox integrationStyleMainDiv" >
                                    <Scrollbars autoHide style={scrollbar}>
                                        <div className="commonSettingsBoxTitle">
                                            <div className="titleText commonLightGrayText">
                                            
                                            <Link to="/integrated_list">Integrations</Link>
                                                
                                            </div>
                                            <RightTriangle />
                                            <div className="titleText noPadding">
                                                <span>Adding BigCommerce</span>
                                            </div>
                                        </div>
                                        <div className="shopifyIntegrationStepsMainDiv">
                                            <div className="instructionText">
                                                <div>
                                                    Click on the Button to Integrate Your BigCommerce Store.    
                                                </div>
                                                <a href="https://www.bigcommerce.com" target="_blank">Click to Integrate</a>
                                               
                                            </div>

                                        </div>
                                   
                                    </Scrollbars>
                                </div>
                            </div>
                            {/* Settings Right Side Panel Close */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null, {})(BigCommerceIntegration));



