const url = process.env.REACT_APP_API_URL
const REACT_S3_URL = process.env.REACT_APP_S3_URL

export const TIMEZONELIST = 'TIMEZONELIST'
export const SETTINGS_SHOW_FLASH_MESSAGE = 'SETTINGS_SHOW_FLASH_MESSAGE'
export const SETTINGS_FLASH_MESSAGE = 'SETTINGS_FLASH_MESSAGE'
export const SET_URL_REDIRECTION='SET_URL_REDIRECTION'
export const SET_HTML_VALUE = 'SET_HTML_VALUE'
export const UNHANDLED_EXCEPTION = 'Something went wrong !! Please contact support@trackhive.co'

//Smd Template Success Msg
export const CREATE_SMS_TEMPLATE = 'Custom template created.'
export const UPDATE_SMS_TEMPLATE = 'Custom template updated.'

//Email Template Success & Error Msg
export const DELETE_TEMPLATE_SUCCESS = 'Template successfully removed.'
export const DELETE_TEMPLATE_ERROR = 'Template is not removed.'
export const CREATE_TEMPLATE_SUCCESS = 'Custom template created.'
export const CREATE_TEMPLATE_ERROR = 'Please provide required field.'
export const EDIT_TEMPLATE_SUCCESS = 'Custom template updated.'
export const EDIT_TEMPLATE_ERROR = 'Please provide required field.'
export const TEST_MAIL_SUCCESS = 'Test mail has been sent to '
export const SubjectReplacementList = ["[Organization Name]", "[Tracking Number]", "[Order ID]", "[Customer Name]"]

//Email accordions fields
export const ORGANIZATION_EXPANSION_LIST = ['[Name]', '[Logo]', '[Address 1]', '[Address 2]', '[City]', '[State]', '[Country]', '[Zipcode]', '[Contact Number]', '[Website Url]', '[Organization Address]']
export const TRACKING_EXPANSION_LIST = ['[Tracking Number]', '[Courier Name]', '[Tracking Status]', '[Tracking URL]', '[Order ID]', '[Customer Name]', '[Ship Type]', '[Expected Delivery]', '[Ship From City]', '[Ship From State]', '[Ship From Country]', '[Ship From Zipcode]', '[Ship To City]', '[Ship To State]', '[Ship To Country]', '[Ship To Zipcode]'] 

//Sms accordions fields
export const smsAccordionsMergers = [{ 'Organization': ['Name', 'Logo', 'Address 1', 'Address 2', 'City', 'State', 'Country', 'Zipcode', 'Contact Number', 'Website Url', 'Organization Address'] },
{ 'Tracking': ['Tracking Number', 'Courier Name', 'Tracking Status', 'Order ID', 'Customer Name', 'Ship Type', 'Expected Delivery', 'Ship From City', 'Ship From State', 'Ship From Country', 'Ship From Zipcode', 'Ship To City', 'Ship To State', 'Ship To Country', 'Ship To Zipcode'] }]

//Email Domain Success & Error Msg
export const EMAIL_ADDED_SUCCESSFULLY = "Email has been added."
export const EMAILID_DELETED_SUCCESSFULLY = "Email Id has been deleted."
export const DOMAIN_VERIFIED_SUCCESSFULLY = "Domian verfied successfully."
export const DOMAIN_VERIFICATION_ERROR = "Please add your 'CNAME' records at your domain's DNS settings."
export const ADD_CNAME_TITLE = "Create 3 CNAME records with below values at your domain's DNS settings to authorise Trackhive to send emails on your behalf."
export const PROVIDE_EMAIL_ERROR = "Please enter your email address"



export function getRedirectURLParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const devShopifyToOhURI = url + '/shopify_to_trackhive_token'
export const liveShopifyToOhURI = url + '/shopify_to_trackhive_token'

//Shopify to OH URL:
export const devShopifyVerifyHmac = url + '/shopify_verify_hmac'
export const liveShopifyVerifyHmac = url + '/shopify_verify_hmac'

export const devShopifyAccessToken =url + '/shopify_to_trackhive_token'
export const liveShopifyAccessToken =url + '/shopify_to_trackhive_token'

//Store Success and Error Messsages
export const STORE_SAVED_SUCCESSFULLY = "Store Saved."
export const STORE_ACTIVATED_MESSAGE = "Store Activated."
export const STORE_DEACTIVATED_MESSAGE = "Store Deactivated."

//TrackButton code
export const IFRAME_CODE = `<iframe allowtransparency="allowtransparency" src="trackhiveUrl/trackingButton?domain=domainName" width="100%" height="200" style="border: none;"></iframe>`
export const CODE_COPIED_SUCCESS_MESSAGE = "Code Copied."
// Daily Digest Email
export const DAILY_DIGEST_ACTIVATED = "Daily Digest Email Alert Activated."
export const DAILY_DIGEST_DEACTIVATED = "Daily Digest Email Alert Deactivated."

//Sub Users modules constant message
export const RESEND_INVITE_MESSAGE = "User Invited."
export const USER_ADDED_SUCCESSFULLY_MESSAGE = "User Added Successfully."
export const USER_UPDATED_SUCCESSFULLY_MESSAGE = "User Updated Successfully."

//Roles module constant message
export const WARNING_MESSAGE = "Something Went Wrong."
export const ROLE_ADDED_SUCCESSFULLY_MESSAGE = "Role Added Successfully."
export const ROLE_UPDATED_SUCCESSFULLY_MESSAGE = "Role Updated Successfully."

// Firebase constant

export const _BASENAME = 'Trackhive'
export const scrollbar_notification_list = { width: "100%", height: "calc(100% - 59px)" }
export const firebase_basepath = process.env.REACT_APP_FIREBASE_BASEPATH === ''? '/' : '/'+process.env.REACT_APP_FIREBASE_BASEPATH+'/'

export const NOTIFY = 'NOTIFY'

//Hubspot Constant

export const hubSpotURL = 'https://api.hsforms.com/submissions/v3/integration/submit/6955560/efc2f401-3b0a-487e-ab02-de5197a02bdd'

//Role access
export const ROLE_ACCESS = {
    "Dashboard":[
        {
            label:'Dashboard',
            switchName:'DashboardSwitch',
            roleValue:'dashboard',
            moduleIcon:REACT_S3_URL + "dashboardIcon.svg"
        }
    ],    
    "Shipments":[
        {
            label:'Shipments',
            switchName:'ShipmentSwitch',
            roleValue:'tracking',
            moduleIcon:REACT_S3_URL + "trackingIcon.svg",
            submodules:[
                {switchName:'AssignShipmentSwitch',roleValue:'assignTracking'}
            ]
        },
        {
            label:'Assign Shipments',
            switchName:'AssignShipmentSwitch',
            roleValue:'assignTracking',
            moduleIcon:REACT_S3_URL + "trackingIcon.svg"
        }
    ],
    "Automations":[
        {
            label:'Automations',
            switchName:'AutomationSwitch',
            roleValue:'automations',
            moduleIcon:REACT_S3_URL + "automationIcon.svg"
        }
    ],         
    "Settings":[
        {
            label:'Settings',
            switchName:'SettingSwitch',
            roleValue:'settings',
            moduleIcon:REACT_S3_URL + "settings.svg"
        }
    ]   
}

//tags modules constant message
export const TAG_NAME_UPDATED_SUCCESSFULLY_MESSSAGE = "Tag Name Updated."
export const TAG_COLOR_UPDATED_SUCCESSFULLY_MESSAGE = "Tag Color Updated."
export const TAG_DELETED_SUCCESSFULLY_MESSAGE = "Tag deleted."

//Tracking Page constants
export const CUSTOM_TRACKING_ORDER_OPTIONS = ['[Order ID]']
export const CUSTOM_TRACKING_ORGANIZATION_OPTIONS = ['[Logo]', '[Contact Number]', '[Website]']
export const CUSTOM_TRACKING_OPTIONS = ['[Tracking Number]', '[Courier Name]', '[Courier Phone]', '[Courier Logo]', '[Expected Delivery]', '[Tracking Timeline]', '[Customer Name]']
export const TEMPLATED_CREATED_SUCCESSFULLY_MESSAGE = 'Template Created Successfully.'
export const TEMPLATE_UPDATED_SUCCESSFULLY_MESSAGE = "Template Updated Successfully."
export const TEMPLATE_DELETED_SUCCESSFULLY_MESSAGE = "Template Deleted SuccessFully."
export const DEFAULT_TEMPLATED_UPDATED_MESSAGE = "Default Template Updated Successfully."

// Profile module constant messages
export const DATE_FORMAT_OPTIONS = [
    {
        value: 'DD-MM-YYYY',
        label: 'DD-MM-YYYY'
    }, {
        value: 'MM-DD-YYYY',
        label: 'MM-DD-YYYY'
    }, {
        value: 'YYYY-MM-DD',
        label: 'YYYY-MM-DD'
    }
]
export const PASSWORD_UPDATE_MESSAGE = "Password Updated Successfully."
export const PROFILE_SUCCESSFULLY_SAVED_MESSAGE = "Profile Settings Saved."


//Organization module constant messages
export const ORGANIZATION_SUCCESSFULLY_SAVED_MESSAGE = "Organization Settings Saved."

//Webhook module constant messages
export const WEBHOOK_ADDED_SUCCESSFULLY_MESSAGE = "Webhook Added Successfully."
export const WEBHOOK_UPDATED_MESSAGE = "Webhook Updated Successfully."
export const WEBHOOK_ACTIVATED_MESSAGE = "Webhook Activated Successfully."
export const WEBHOOK_DELETED_MESSAGE = "Webhook Deleted Successfully."
export const ACTIVATE_WEBHOOK_TO_EDIT = "Please Activate Webhook To Edit."

//API keys module constant messages
export const TOKEN_ADDED_SUCCESSFULLY_MESSSAGE = "Developer Token Added Successfully."
export const TOKEN_REFRESHED_SUCCESSFULLY_MESSAGE = "Developer Token Refreshed Successfully."
export const TOKEN_DELETED_SUCCESSFULLY_MESSAGE = "Developer Token Deleted Successfully."
export const TOKEN_COPIED_SUCCESSFULLY = "Token copied."

//Custom domain module constant messages
export const SUBDOMAIN_SUCCESSFULLY_SAVED = "Subdomain settings saved."