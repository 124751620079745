import React from 'react'
import axios from 'axios';
import { connect } from 'react-redux'
import {setPasswordLinkMessage} from './actionMethods/actionMethods'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlashMessage from '../../src/TrackingList/common/flashMessage'
import MediaContent from '../common/userMediaCard'
import Typography from '@material-ui/core/Typography';
import { emailRegex } from '../static/constants'
import Styles from './Styles'

const url = process.env.REACT_APP_API_URL
const forgotPassword = '/user/forgot_password?emailid='
class ForgotPassword extends React.Component {

    state = {
        nameBorder: '',
        passwordBorder: '',
        showLoader: false,
        apiError: '',
        openSnackBar: true,
        email: '',
        isEmailError: false,
    }

    handleForgotPassword = () => {
        let val = document.getElementById("email").value

        if (!emailRegex.test(val)) {
            this.setState({isEmailError: true})
        }
        else {
            this.setState({ showLoader: true })
            axios.get(url + forgotPassword + val).then(response => {
                // this.props.setPasswordLinkMessage()
                if (JSON.parse(localStorage.getItem('UserName'))) {
                    this.props.history.push('/users')
                } else {
                    this.props.history.push('/login')
                }
            }).catch(error => {
                this.setState({ showLoader: false, apiError: error.response.data.meta.message[0], openSnackBar: true })
            })
        }

    }

    handleBackToLogin = () => {
        this.props.history.push('/login')
    }

    closeFlashMessage = () => {
        this.setState({ openSnackBar: false, apiError: '' })
    }

    handleChange = (event) => {
        let val = event.target.value
        this.setState({email:val})

        if (!emailRegex.test(val)) {
            this.setState({isEmailError: true})
        } else {
            this.setState({isEmailError: false})
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="loginAndRegistrationCommonDiv">
                <Card className={classes.root} variant="outlined">
                    <MediaContent content="Forgot Password" />
                    <CardContent className={classes.forgotPasswordContent}>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Enter registered email address and we will send you an email to reset your password.
                        </Typography>
                    </CardContent>
                    <TextField
                        error={this.state.isEmailError ? true : false}
                        id="email"
                        label="Email"
                        variant="outlined"
                        className={`${classes.forgotPasswordEmailtextField} ${classes.onFocus}`}
                        value={this.state.email}
                        onChange={this.handleChange}
                        helperText={this.state.isEmailError && this.state.email !== '' ? 'Email must be valid' : ''}
                    />
                    <CardActions className={classes.nextButtonRoot}>
                        <div className="leftBtnDiv">
                            <Button
                                onClick={this.handleBackToLogin}
                                disabled={this.state.showLoader}
                                className={classes.forgotBtn}
                                color="primary"
                            >
                                Back to Login
                            </Button>
                        </div>
                        <div className="rightBtnDiv">
                            <Button
                                variant="contained"
                                className={classes.saveBtn}
                                disabled={this.state.showLoader}
                                onClick={this.handleForgotPassword}
                            >
                                Submit
                            </Button>
                        </div>
                        {this.state.showLoader && <CircularProgress size={22} className={classes.buttonProgress} />}
                    </CardActions>
                </Card>
                {this.state.apiError && this.state.apiError !== '' ?
                    <FlashMessage open={this.state.openSnackBar} closeFlash={this.closeFlashMessage} classes={classes.addTrackingSnackbar} messageDetails={{ type: 'ERROR', message: this.state.apiError }} />
                    : ''}
            </div>

        )
    }
}
export default (connect(null, { setPasswordLinkMessage })(withStyles(Styles)(ForgotPassword)))