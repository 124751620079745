import {
    Profile,
    Organization,
    EmailTemplates,
    EmailDomains,
    Webhooks,
    Users,
    Tags,
    ApiKeys,
    CustomDomain,
    EmailAlerts
} from '../../common/imageList'

export const settingMenu = [{
        title: "Profile",
        subTitle: "Your Account.",
        icon: Profile,
        path: "/settings_profile",
        role: "profile"
    },
    {
        title: "Organization",
        subTitle: "Organization Settings.",
        icon: Organization,
        path: "/organization",
        role: 'organization'
    },
    {
        title: "Webhooks",
        subTitle: "You can subscribe to events for your shipments by creating webhook.",
        icon: Webhooks,
        path: "/webhook",
        role: 'webook'
    },
    {
        title: "Users & Roles",
        subTitle: "Create sub users.",
        icon: Users,
        path: "/sub_users",
        role: 'subuser'
    },
    {
        title: "API Keys",
        subTitle: "Create API keys to connect your system with Trackhive.",
        icon: ApiKeys,
        path: "/developer_apps",
        role: 'apiKey'
    },
    {
        title: "Email Domains",
        subTitle: "Generate domain and send email using that domain.",
        icon: EmailDomains,
        path: "/settings_domain",
        role: 'emailDomain'
    },
    {
        title: "Email Templates",
        subTitle: "Create email templates.",
        icon: EmailTemplates,
        path: "/settings_email",
        role: 'emailTemplate'
    },
    {
        title: "Custom Domain",
        subTitle: "Create custom domains.",
        icon: CustomDomain,
        path: "/custom_domain",
        role: 'customDomain'
    },
    {
        title: "Email Alerts",
        subTitle: "Turn on/off email alerts.",
        icon: EmailAlerts,
        path: "/email_alerts",
        role: 'emailAlert'
    },
    {
        title: "Shipment Tags",
        subTitle: "Create tags.",
        icon: Tags,
        path: "/tags",
        role: 'tags'
    }
]