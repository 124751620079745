import axios from 'axios';
import * as types from '../common/constants';
import { getInstance } from '../../config/token';
import Cookies from 'js-cookie'
import * as constants from '../../Authentication/reducers/constants'

const prefix = ''

export function returnData(type, data, status) {
    if (status >= 200 && status <= 299) {
        return {
            type: type,
            data: data
        }
    } else {
        console.log('error');
    }
}

function handleException(error) {
    if (error.response && error.response.status) {
        return {
            status: error.response.status,
            data: error.response.data
        }
    } else {
        return {
            status: 500
        }
    }
}

export function getTimeZoneList() {
    let config = getInstance('GET', `${prefix}/timezone/list`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            console.log(error)
        })
}

export function addStore(requestBody) {
    let config = getInstance('POST', `${prefix}/stores`, requestBody);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getStoreDetail(id) {
    let config = getInstance('GET', `${prefix}/stores/${id}`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            console.log(error)
        })
}

export function editStore(id, requestBody) {
    let config = getInstance('PUT', `${prefix}/stores/${id}`, requestBody);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function setURLRedirection(data) {
    return dispatch => {
        dispatch({
            type: types.SET_URL_REDIRECTION,
            data: data
        })
    }
}

export function getUserDetails() {
    let config = getInstance('GET', `${prefix}/user/profile`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function editProfile(requestParams) {
    let config = getInstance('PUT', `${prefix}/user/edit_profile`, requestParams);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            console.log(error)
        })
}

export function getWebhookURLList() {
    let config = getInstance('GET', `${prefix}/webhook/subscription`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            console.log(error)
        })
}

export function createWebhook(requestParams) {
    let config = getInstance('POST', `${prefix}/webhook/subscription`, requestParams);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function editWebhook(requestParams) {
    let config = getInstance('PUT', `${prefix}/webhook/subscription`, requestParams);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function deleteWebHook(webhookID) {
    let config = getInstance('DELETE', `${prefix}/webhook/subscription/${webhookID}`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function toggleSettingsFlashMessage(data) {
    return dispatch => {
        dispatch({
            type: types.SETTINGS_SHOW_FLASH_MESSAGE,
            data: data
        })
    }
}

export function setSettingsFlashMessage(data) {
    return dispatch => {
        dispatch({
            type: types.SETTINGS_FLASH_MESSAGE,
            data: data
        })
    }
}

export function getDeveloperTokenList() {
    let config = getInstance('GET', `${prefix}/user/get_tokens`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function addDeveloperToken(requestParams) {
    let config = getInstance('POST', `${prefix}/user/add_token`, requestParams);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function refreshDeveloperToken(requestParams) {
    let config = getInstance('PUT', `${prefix}/user/refresh_token`, requestParams);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function deleteToken(requestParams) {
    let config = getInstance('DELETE', `${prefix}/user/delete_token`, requestParams);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

/* Email Template ActionMethods Starts */
export function createCustomEmailTemplate(requestParams) {
    let config = getInstance('POST', `${prefix}/email_template`, requestParams)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getEmailTemplateList(requestParams) {
    let config = getInstance('GET', `${prefix}/email_template/list`, requestParams)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getEmailTemplate(templateId) {
    let config = getInstance('GET', `${prefix}/email_template/${templateId}`)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function updateEmailTemplate(templateId, values) {
    let config = getInstance('PUT', `${prefix}/email_template/${templateId}`, values)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function deleteTemplate(templateId) {
    let config = getInstance('DELETE', `${prefix}/email_template/${templateId}`)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function testEmailTemplate(templateId, requestParams) {
    let config = getInstance('POST', `${prefix}/email_template/${templateId}`, requestParams)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

/* Email Template ActionMethods Ends */
/* SMS Template ActionMethods Starts */
export function createCustomSMSTemplate(requestParams) {
    let config = getInstance('POST', `${prefix}/sms_template`, requestParams)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getAllSMSTemplate() {
    let config = getInstance('GET', `${prefix}/sms_template`)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getSMSTemplate(templateId) {
    let config = getInstance('GET', `${prefix}/sms_template/${templateId}`)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function updateSMSTemplate(templateId, values) {
    let config = getInstance('PUT', `${prefix}/sms_template/${templateId}`, values)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function deleteSMSTemplate(templateId) {
    let config = getInstance('DELETE', `${prefix}/sms_template/${templateId}`)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

/* SMS Template ActionMethods Ends */
/* Email Domain ActionMethods Starts */
export function addDomain(requestParams) {
    let config = getInstance('POST', `${prefix}/domain`, requestParams);
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function verifyDomain(domianId) {
    let config = getInstance('POST', `${prefix}/domain/validate/${domianId}`);
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function deleteEmailId(requestParams) {
    let config = getInstance('PUT', `${prefix}/domain/`, requestParams);
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getDomainList(data) {
    let config = getInstance('GET', `${prefix}/domain${data}`)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

/* Email Domain ActionMethods Ends */
export function getCountryList() {
    let config = getInstance('GET', `${prefix}/country/list`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getOrganizationDetails() {
    let config = getInstance('GET', `${prefix}/user/organization`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function editOrganizationDetails(requestParams) {
    let config = getInstance('PUT', `${prefix}/user/organization`, requestParams);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function setHTMLValue(data) {
    return dispatch => {
        dispatch({
            type: types.SET_HTML_VALUE,
            data: data
        })
    }
}

export function getPublicPageDetails(requestBody) {
    let config = getInstance('POST', `${prefix}/tracking_page`, requestBody)
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getTrackingPageList() {
    let config = getInstance('GET', `${prefix}/tracking_template/list`)
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function createTrackingTemplate(requestBody) {
    let config = getInstance('POST', `${prefix}/tracking_template`, requestBody)
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function editTrackingTemplate(id, requestBody) {
    let config = getInstance('PUT', `${prefix}/tracking_template/${id}`, requestBody)
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getSingleTemplateDetails(id, requestBody) {
    let config = getInstance('GET', `${prefix}/tracking_template/${id}`, requestBody)
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function makeDefaultTemplate(templateID) {
    let config = getInstance('PUT', `${prefix}/tracking_template/default/${templateID}`)
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function removeTemplateAPI(templateID) {
    let config = getInstance('DELETE', `${prefix}/tracking_template/${templateID}`)
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function editSubDomain(requestParams) {
    let config = getInstance('PUT', `${prefix}/user/sub_domain`, requestParams);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getSubDomain(requestParams) {
    let config = getInstance('GET', `${prefix}/user/sub_domain`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function checkSubDomain(requestParams) {
    let config = getInstance('POST', `${prefix}/user/sub_domain`, requestParams);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function isDomainExist(requestBody) {
    let config = getInstance('POST', `${prefix}/tracking_page_search`, requestBody);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function activeOrDeactiveDailyDigestEmail(userId, requestParams) {
    let config = getInstance('PUT', `${prefix}/user/alerts/${userId}`, requestParams);
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

/* Sub User Action Methods STARTS */
export function createSubUser(requestParams) {
    let config = getInstance('POST', `${prefix}/sub_user/invite`, requestParams)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getSubUsersList(pageId, limit) {
    let config = getInstance('GET', `${prefix}/sub_user/list?pageId=${pageId}&limit=${limit}`)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getSubUser(id) {
    let config = getInstance('GET', `${prefix}/sub_user/${id}`)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function updateSubUser(id, values) {
    let config = getInstance('PUT', `${prefix}/sub_user/${id}`, values)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

/* Sub User Action Method ENDS */
/* Tag Action Method STARTS */
export function searchTags(values) {
    let config = getInstance('POST', `${prefix}/tags/search`, values)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function addShipmentsTag(requestParams) {
    let config = getInstance('POST', `${prefix}/tag`, requestParams)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function getTagList() {
    let config = getInstance('GET', `${prefix}/tags`)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function editTag(id, requestBody) {
    let config = getInstance('PUT', `${prefix}/tag/${id}`, requestBody)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function deleteTag(id) {
    let config = getInstance('DELETE', `${prefix}/tag/${id}`)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

/* Tag Action Method ENDS */


export function hubSpotForm() {
    try {
        if (process.env.REACT_APP_BUILD === "production") {
            
            /* Start : For HubSpot Entry */
            
            const tokenDetails = JSON.parse(localStorage.getItem('trackhiveToken'))
            const hubSpotFormUrl = types.hubSpotURL

            // let lifecyclestage = 'salesqualifiedlead';
            let user_type = 'main'
            // let hs_lead_status = "CONNECTED"
            
            if (tokenDetails.role !== 'user') {
                user_type = 'subuser'
            }
            let hubspot_str_post_json = {
                "fields": [

                    {
                        "name": "email",
                        "value": tokenDetails.email
                    },
                    // {
                    //     "name": "lifecyclestage",
                    //     "value": lifecyclestage
                    // },
                    // {
                    //     "name": "hs_lead_status",
                    //     "value": hs_lead_status
                    // },
                    {
                        "name": "firstname",
                        "value": tokenDetails.name
                    },
                    {
                        "name": "user_type",
                        "value": user_type
                    }
                ],
                "context": {
                    "hutk": Cookies.get('hubspotutk'), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                }
            }
            const config_headers = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            axios.post(hubSpotFormUrl, hubspot_str_post_json, config_headers)
                .then(function (response) {
                })
                .catch(function (error) {
                    
                });
            /* Start : For HubSpot Entry */
        }
    } catch (Error) {
        
    }
}

/* ROLES ACTION METHODS STARTS */


export function getRolesList() {
    let config = getInstance('GET', `${prefix}/role/list`)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function createRole(requestParams) {
    let config = getInstance('POST', `${prefix}/role`, requestParams)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function updateRole(id, values) {
    let config = getInstance('PUT', `${prefix}/role/${id}`, values)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}
export function getRoleById(id) {
    let config = getInstance('GET', `${prefix}/role/${id}`)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function updateRoleData(data) {
    return dispatch => {
    dispatch({
        type: constants.ROLES_DATA,
        data: data
    })
    }
}
/* ROLES ACTION METHODS STARTS */
