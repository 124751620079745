import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom';
import { userLogin, clearError, storeUserLogin, storeUserLoginWithStoreExist } from './auth'
import {getRedirectURLParameterByName} from '../static/constants'
import { defaultUserPic, ExpandIcon } from '../common/imageList'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlashMessage from '../../src/TrackingList/common/flashMessage'
import MediaContent from '../common/userMediaCard'
import Styles from './Styles'

const mapStateToProps = state => ({
    isLoggedIn: state.authenticate.isLoggedIn,
    errorMessage: state.authenticate.loginError,
    urlRedirection:state.authenticate.urlRedirection
})

class UserLogin extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showLoader: false,
            password: '',
            ispasswordError: false,
            openSnackBar: true
        }
    }

    UNSAFE_componentWillMount() {
        this.props.clearError()
    }

    handleLogin = () => {

        this.props.clearError()
        let userObject = JSON.parse(localStorage.getItem('UserName'))
        let emailId = userObject.email
        let password = this.state.password

        if (password !== '') {
            this.setState({
                showLoader: true
            })

            this.setState({ openSnackBar: true })
            let val = getRedirectURLParameterByName('utm_source')
            
            if((val !== '' && val === 'bigcommerce') || (val !== '' && val === 'shopify')){
                this.props.storeUserLogin(emailId, password, status => {
                    if (status) {
                        this.setState({
                            showLoader: false
                        })
                        this.props.history.push('/integrated_list');
                    }
                    else {
                        this.setState({
                            showLoader: false
                        })
                    }
                })
            }
            else if(val !== '' && val === 'storeExist'){
                this.props.storeUserLoginWithStoreExist(emailId, password, status => {
                    if (status) {
                        this.setState({
                            showLoader: false
                        })
                        this.props.history.push('/integrated_list');
                    }
                    else {
                        this.setState({
                            showLoader: false
                        })
                    }
                })
            }
            else{
                this.props.userLogin(emailId, password, status => {
                    if (status) {
                        this.setState({
                            showLoader: false
                        })

                        if(this.props.urlRedirection === true){
                            this.props.history.push('/integrated_list');
                        }
                        else{
                            this.props.history.push('/');
                        }
                    }
                    else {
                        this.setState({
                            showLoader: false
                        })
                    }
                })
            }
        } else {
            this.checkPasswordRequired()
        }
    }

    handleClick = () => {
        this.props.history.push('/users')
    }

    displayUserDetails = () => {
        const { classes } = this.props;
        let userDetails = JSON.parse(localStorage.getItem('UserName'));
        return (
            <CardContent style={{ textAlign: "center" }} className={classes.userLoginContent}>
                <Chip
                    classes={{ label: classes.chipLabel, root: classes.deleteIcon }}
                    avatar={<Avatar alt={userDetails.name} src={defaultUserPic} />}
                    label={userDetails.email}
                    variant="outlined"
                    clickable
                    onClick={this.handleClick}
                    onDelete={this.handleClick}
                    deleteIcon={<ExpandIcon />}
                />
            </CardContent>
        )
    }

    handleChange = (event) => {
        this.setState({
            password: event.target.value,
            ispasswordError: false
        })
    }

    checkPasswordRequired = () => {
        if (this.state.password === '') {
            this.setState({
                ispasswordError: true
            })
        }
        else {
            this.setState({
                ispasswordError: false
            })
        }
    }

    handleForgotPassword = () => {
        this.props.history.push('/forgotPassword')
    }

    closeFlashMessage = () => {
        this.setState({ openSnackBar: false })
        this.props.clearError()
    }

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            this.handleLogin();
        }
    }

    render() {
        let userDetails = JSON.parse(localStorage.getItem('UserName'));
        const { classes } = this.props;
        const { from } = this.props.location.state || {
            from: {
                pathname: '/'
            }
        }
        if (this.props.isLoggedIn) {
            return <Redirect to={from} search={this.props.location.search} />
        }

        return (
            <div className="loginAndRegistrationCommonDiv">
                <Card className={classes.root} variant="outlined">
                    <MediaContent content={`Hi, ${userDetails.name}`} />
                    {this.displayUserDetails()}
                    <TextField
                        error={this.state.ispasswordError ? true : false}
                        id="password"
                        label="Enter Your Password"
                        variant="outlined"
                        type="password"
                        className={`${classes.loginTextField} ${classes.onFocus}`}
                        value={this.state.password}
                        onChange={this.handleChange}
                        onKeyDown={this.onEnterPress}
                        autoFocus={true}
                    />
                    <CardActions className={classes.nextButtonRoot}>
                        <div className="leftBtnDiv">
                            <Button
                                onClick={this.handleForgotPassword}
                                disabled={this.state.showLoader}
                                className={classes.forgotBtn}
                                color="primary"
                            >
                                Forgot password ?
                            </Button>
                        </div>
                        <div className="rightBtnDiv">
                            <Button
                                variant="contained"
                                className={classes.saveBtn}
                                disabled={this.state.showLoader}
                                onClick={this.handleLogin}
                            >
                                Next
                            </Button>
                        </div>
                        {this.state.showLoader && <CircularProgress size={22} className={classes.buttonProgress} />}
                    </CardActions>
                </Card>
                {this.props.errorMessage && this.props.errorMessage !== '' ?
                    <FlashMessage open={this.state.openSnackBar} closeFlash={this.closeFlashMessage} classes={classes.addTrackingSnackbar} messageDetails={{ type: 'ERROR', message: this.props.errorMessage }} />
                    : ''}
            </div>
        )
    }
}
export default withRouter(connect(mapStateToProps, { userLogin, clearError, storeUserLogin, storeUserLoginWithStoreExist })(withStyles(Styles)(UserLogin)));
