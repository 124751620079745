import * as constants from './constants'

const getRoles = localStorage.getItem('role_access')
let rolesArr = []

if(getRoles !== undefined && getRoles !== "" && getRoles !== null){
    rolesArr = JSON.parse(getRoles).role
}

const initialState = {
    isLoggedIn: !!JSON.parse(localStorage.getItem('trackhiveToken')),
    loginError: '',
    setpasswordEmailLinkMessage:'',
    roles: rolesArr.length > 0 ? getRoles : [] 
}

export default (state = initialState, action = null) => {

    switch (action.type) {
        case constants.LOGIN:
            return {
                ...state,
                isLoggedIn: true
            }

        case constants.LOGOUT:
            return {
                ...state,
                isLoggedIn: false
            }

        case constants.CLEAR_ERROR:
            return {
                ...state,
                loginError: '',
                verifyEmailError: ''
            }

        case constants.USER_LOGIN_ERROR:
            return {
                ...state,
                loginError: action.payload
            }
        case constants.SET_PASSWORD_EMAIL_LINK_MESSAGE:
            return {
                ...state,
                setpasswordEmailLinkMessage:'We have sent Password Reset link to your email.'
            }

            case constants.RESET_PASSWORD_LINK_MESSAGE:
            return {
                ...state,
                setpasswordEmailLinkMessage:''
            }
        case constants.VERIFY_EMAIL_ERROR:
            return {
                ...state,
                verifyEmailError: action.payload
            }
        case constants.ROLES_DATA:
            return{
                ...state,
                roles: action.data
            }
        case constants.CLEAR_ROLES_DATA:
            return{
                ...state,
                roles: []
        }
        default:
            return state;
    }
}