import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from './alert'
export default class FlashMessage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }

    componentDidUpdate(prevProps){
        if((prevProps.isSuccess !== this.state.open) && (prevProps.message === "Code Copied.")){
            this.setState({open:true})
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps){
            if(nextProps.isSuccess){
                this.setState({open: true})
            }
        }
    }

    componentDidMount() {
        this.setState({open: true})
        setTimeout(() => {
            this.props.toggleFlashMessage(0)
        }, 3000)
    }

    handleClose = () => {
        this.setState({open: false})
    }

    render() {
        return (
                <Snackbar open={this.state.open} autoHideDuration={4000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity= {this.props.isSuccess ? "success" : "error"}>
                        {this.props.message}
                    </Alert>
                </Snackbar>
        )
    }
}