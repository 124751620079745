import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {StyledTextField, StyledTypography} from '../../common/materialComponents';
import {SaveIcon, CloseIcon, cancelIconSettings} from '../../../common/imageList';
class DomainDrawer extends React.Component{

    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.props.closeDrawer()
    };

    render(){
        const {classes} = this.props
        return(
               <React.Fragment>
                 <Drawer
                    variant = "temporary"
                    anchor="right"
                    open={this.props.openDrawer}
                    onClose={this.toggleDrawer(false)}
                    BackdropProps={{ invisible: true }}
                    transitionDuration={500}
                 >
                    <Grid container className={classes.drawerHeader}>
                        <StyledTypography className={classes.drawerTitle}>Email Domains</StyledTypography>
                        <IconButton
                            color="inherit"
                            onClick={this.toggleDrawer(false)}
                            className={classes.draweCancelButton}
                            edge="start"
                        >
                            <img alt="" src={cancelIconSettings} />
                        </IconButton>
                    </Grid>

                    <Grid container className={classes.drawerContent}>
                        <StyledTypography className={classes.drawerContentText}>Add Email.</StyledTypography>
                    </Grid>    

                    <Grid container className={classes.drawerTextFieldContainer}>
                       <StyledTextField
                            error={(this.props.emailError ||this.props.emailFieldError || !this.props.isValidEmail) ? true : false}
                            id="email"
                            helperText={(this.props.emailError || this.props.emailFieldError ) ? "Email-Id is required" : !this.props.isValidEmail ? "Invalid Email" : ""}
                            label="Email"
                            variant="outlined"
                            className={classes.drawerFields}
                            value={this.props.newEmail}
                            onChange={(e) => this.props.handleEmailId(e.target.value)}
                       ></StyledTextField>
                    </Grid> 

                    <footer justify="left" style={{position: 'fixed', bottom: '0'}}>
                                <Button 
                                    onClick={this.props.addDomain} 
                                    startIcon={<SaveIcon/>} 
                                    disabled={this.props.loading}
                                    className={(this.props.loading) ? classes.drawerSaveBtnDisable : classes.drawerSaveBtn}>
                                    SAVE
                                </Button>
                                {(this.props.loading) && <CircularProgress size={22} className={classes.drawerbuttonProgress} />}

                              <Button startIcon={<CloseIcon/>} onClick={this.toggleDrawer(false)} className={classes.drawerDeleteButton}>
                                CANCEL
                              </Button>
                    </footer>
                </Drawer>
                </React.Fragment>
        )
    }   
}
export default (DomainDrawer)