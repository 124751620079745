import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton'
import { Scrollbars } from 'react-custom-scrollbars'
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { addShipmentsTag } from '../Setup/actions/actionMethods'
import { trackingNumberErrorMessage, courierErrorMessage, emailErrorMessage, URLErrorMessage, postalCodeErrorMessage, trackingNumberLenghtErrorMessage } from './common/errorMessage'
import { setDestinationCountryList, setOriginCountryList, setCourierArrayValueList, setStatusArrayValueList, createShipment, getTrackingList, clearTrackingList, getCarrierList, setTrackingFilters, setCourierList, isShipmentAdd, getFiltersList, setTagList, setFilterTagList, getCountryList } from './actions/actionMethods'
import FlashMessage from './common/flashMessage'
import { Autocomplete } from '@material-ui/lab';
import { TrackingTextField } from '../Setup/common/materialComponents'
import { SaveIcon, CloseIcon, cancelIconSettings } from '../common/imageList'
import AddTags from '../../src/TrackingList/common/AddTags'
import {emailRegex,urlRegEx,nameRegEx} from '../static/constants'
import DateFnsUtils from '@date-io/moment';
import {  MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {DEFAULT_TRACKING_LIMIT} from './common/constants'
const initValues = {
    footerMessage: { type: 'NOTICE', message: '' },
    tagMessage : {}
}
class TrackingDrawer extends React.Component {
    trackingNumberText = 'trackingNumber'
    customerNameText = 'customerName'
    customerEmailText = 'customerEmail'
    otherEmailText = 'otherEmail'
    customerPhoneNoText = 'customerPhoneNo'
    orderIDText = 'orderID'
    orderURLText = 'orderURL'
    postalCodeText = 'postalCode'
    arrayOfEmailIDValues = []
    sendContactEmailId = ''
    getCommaSeparateValue = ''
    originCountryText = 'originCountryText'
    constructor(props) {
        super(props)
        this.state = {
            selectedOption: '',
            selectedUser: '',
            isAdvancedSwitch: true,
            isTrackingNumberRequired: false,
            isCourierRequired: false,
            trackigNumberValue: '',
            customerName: '',
            customerEmail: '',
            otherEmail: '',
            customerPhoneNo: '',
            orderID: '',
            orderURL: '',
            tags: [],
            isValidEmail: true,
            isValidOtherEmail: true,
            isValidURL: true,
            emailIdValue: '',
            carrierListLoader: true,
            carrierList: [],
            showLoader: false,
            showAddTrackingSuccessMessage: false,
            postalCodeValue: '',
            postalCodeRequired: false,
            shipDateValid : true,
            postalCodeFlag: false,
            shipDateFlag : false,
            selectedDate : new Date(),
            subUserList: [],
            subUserLoader: true,
            showTagSuccessMessageFlag: false,
            openSnackBar: false,
            originCountryFlag: false,
            originCountryValue: '',
            trackingNumberLengthError: false,
            isSubUser: localStorage.getItem('user_type') !== "subuser" ? false : true,
            countryList: []
        }
    }

    onSubUserChange = (event, selectedOption) => {
        if (selectedOption) {
            this.setState({ selectedUser: selectedOption })
        }
    }


    onCourierChange = (event, selectedOption) => {
        if (selectedOption && selectedOption.requireFields && selectedOption.requireFields.length > 0) {
            let fields = selectedOption.requireFields
            fields.forEach(field => {
                if (field === 'postal_code') {
                    this.setState({
                        postalCodeFlag: true
                    })
                } else {
                    this.setState({
                        postalCodeFlag: false
                    })
                }
                if(field === 'tracking_ship_date') {
                    this.setState({
                        shipDateFlag: true
                    })
                } else {
                    this.setState({
                        shipDateFlag : false
                    })
                }
            })
        } else {
            this.setState({
                postalCodeFlag: false,
                shipDateFlag : false
            })
        }
        if(selectedOption && selectedOption.optionalFields && selectedOption.optionalFields.length > 0){
            let optionalFields = selectedOption.optionalFields
            optionalFields.forEach(field => {
                if(field === "origin_country"){
                    this.setState({originCountryFlag: true})
                    this.getCountryList()
                }
            })
        }else{
            this.setState({originCountryFlag: false})
        }
        this.setState({ selectedOption });
        if (selectedOption !== 'Select') {
            this.setState({
                isCourierRequired: false
            })
        }
    }

    onCountryChange = (event, selectedOption) => {
        if (selectedOption) {
            this.setState({ selectedCountry: selectedOption })
        }
    }

    getCountryList = () => {
        getCountryList().then(res => {
            if (res && res.data) {
                let countryListArr = []
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    countryListArr.push({
                        label: element.name,
                        value: element.iso
                    })
                }
                this.setState({
                    countryList: countryListArr
                })
            }
        })
    }

    componentWillMount() {
        getCarrierList().then(response => {
            if (response && response.data) {
                this.props.setCourierList(response.data)

                this.setState({
                    carrierList: response.data,
                    carrierListLoader: false
                })
            }
        })
    }
    renderSelectOptions = (selectedCourier) => {
        return (<span className="dropdown_main_options"><span className="dropdown_secondary_options">{selectedCourier.label}</span></span>)
    }
    toggleAdvancedSwitch = () => {
        this.setState(prevState => {
            return {
                isAdvancedSwitch: !prevState.isAdvancedSwitch,
                isValidEmail: true,
                isValidOtherEmail: true,
                isValidURL: true
            };
        })
    }
    checkValidation = () => {
        if (this.state.trackigNumberValue === '') {
            this.setState({
                isTrackingNumberRequired: true
            })
        }
        else {
            this.setState({
                isTrackingNumberRequired: false
            })
        }
        if(this.state.trackigNumberValue.length < 4 || this.state.trackigNumberValue.length > 100){
            this.setState({
                trackingNumberLengthError: true
            })
        }else{
            this.setState({
                trackingNumberLengthError: false
            })
        }
        if (this.state.selectedOption === null || this.state.selectedOption === '') {
            this.setState({
                isCourierRequired: true
            })
        }
        else {
            this.setState({
                isCourierRequired: false
            })
        }
        if (this.state.postalCodeValue === '') {
            this.setState({
                postalCodeRequired: true
            })
        }
        else {
            this.setState({
                postalCodeRequired: false
            })
        }
    }

    clearAllData = () => {
        setTimeout(() => {
            this.setState({
                selectedOption: '',
                selectedUser: '',
                isAdvancedSwitch: true,
                isTrackingNumberRequired: false,
                isCourierRequired: false,
                trackigNumberValue: '',
                customerName: '',
                customerEmail: '',
                otherEmail: '',
                customerPhoneNo: '',
                orderID: '',
                orderURL: '',
                isValidEmail: true,
                isValidOtherEmail: true,
                isValidURL: true,
                emailIdValue: '',
                postalCodeValue: '',
                postalCodeFlag: false,
                shipDateFlag : false,
                shipDateValid : true,
                selectedDate : new Date(),
                tags: [],
                originCountryFlag: false,
                originCountryValue: '',
                trackingNumberLengthError: false
            })
        }, 1000)
    }

    handleAddClick = () => {
        this.checkValidation()
        let isValidEmailFlag = true
        let isValidOtherEmailFlag = true
        let isValidURLFlag = true
        let emailIdArrValue = ''
        let otherEmailIdArrValue = ''
        let phoneNoArrValue = ''
        if (this.state.isAdvancedSwitch === true) {
            if (this.state.customerEmail !== '') {
                var emailArr = this.state.customerEmail.replace(/\s/g, "").split(/,|;/);
                let emailIdValue = ''

                for (let i = 0; i < emailArr.length; i++) {

                    if (emailRegex.test(emailArr[i])) {

                        isValidEmailFlag = true
                        this.setState({
                            isValidEmail: true,

                        });
                        emailIdValue = this.state.customerEmail
                    } else {

                        isValidEmailFlag = false
                        this.setState({
                            isValidEmail: false
                        });
                    }
                }
                emailIdValue = emailIdValue.replace(/\s/g, '')
                emailIdArrValue = emailIdValue.split(',')
            }
            else {
                this.setState({
                    isValidEmail: true
                })
            }
            /** 
             * javascript comment 
             * @Author: Akshay Tukadiya
             * @Date: 2020-05-02 16:13:00 
             * @Desc: For other email
             */
            if (this.state.otherEmail !== '') {
                var otherEmailArr = this.state.otherEmail.replace(/\s/g, "").split(/,|;/);
                let emailIdValue = ''

                for (let i = 0; i < otherEmailArr.length; i++) {

                    if (emailRegex.test(otherEmailArr[i])) {

                        isValidOtherEmailFlag = true
                        this.setState({
                            isValidOtherEmail: true,

                        });
                        emailIdValue = this.state.otherEmail
                    } else {

                        isValidOtherEmailFlag = false
                        this.setState({
                            isValidOtherEmail: false
                        });
                    }
                }
                emailIdValue = emailIdValue.replace(/\s/g, '')
                otherEmailIdArrValue = emailIdValue.split(',')
            }
            else {
                this.setState({
                    isValidOtherEmail: true
                })
            }
            /*END*/
            if (this.state.orderURL !== '') {
                if (urlRegEx.test(this.state.orderURL) === false) {
                    isValidURLFlag = false
                    this.setState({
                        isValidURL: false
                    })
                }
                else {
                    this.setState({
                        isValidURL: true
                    })
                }
            }
            else {
                this.setState({
                    isValidURL: true
                })
            }
            if (this.state.customerPhoneNo !== '') {
                var phoneNo = this.state.customerPhoneNo.replace(/\s/g, "").split(/,|;/);
                let phoneNoValue = ''


                for (var j = 0; j < phoneNo.length; j++) {
                    phoneNoValue = this.state.customerPhoneNo
                }

                phoneNoValue = phoneNoValue.replace(/\s/g, '')
                phoneNoArrValue = phoneNoValue.split(',')

            }
        }
        let addFlag
        if (this.state.trackigNumberValue === '' || this.state.selectedOption === '' || this.state.selectedOption === null || this.state.trackigNumberValue.length < 4 || this.state.trackigNumberValue.length > 100) {
            addFlag = false
        }
        else {
            addFlag = true
        }
        if (addFlag && isValidEmailFlag && isValidURLFlag && isValidOtherEmailFlag && this.state.shipDateValid) {

            this.setState({
                showLoader: true
            })
            let requestParams = {}
            requestParams.slug = this.state.selectedOption.value
            requestParams.tracking_number = this.state.trackigNumberValue
            if (this.state.postalCodeValue) {
                requestParams.postal_code = this.state.postalCodeValue
            }
            if (this.state.shipDateFlag == true && this.state.selectedDate) {
                requestParams.tracking_ship_date = this.state.selectedDate
            }
            requestParams.source = "Web"
            if (this.state.customerName !== '') {
                requestParams.customer_name = this.state.customerName
            }
            if (this.state.customerEmail !== '') {
                requestParams.customer_emails = emailIdArrValue
            }
            if (this.state.otherEmail !== '') {
                requestParams.other_emails = otherEmailIdArrValue
            }
            if (this.state.customerPhoneNo !== '') {
                requestParams.customer_phone_numbers = phoneNoArrValue
            }
            if (this.state.orderID !== '') {
                requestParams.order_id = this.state.orderID
            }
            if (this.state.orderURL !== '') {
                requestParams.order_url = this.state.orderURL
            }
            if (this.state.selectedUser !== '' || this.state.selectedUser !== null) {
                requestParams.assigned_id = this.state.selectedUser.value
            }
            if (this.state.tags && this.state.tags.length > 0) {
                let tagData = [...this.state.tags]
                let prepareTag = []
                tagData.forEach(e => {
                    let data = {
                        "tag_id": e._id,
                        "tag_name": e.tag_name,
                        "tag_color": e.tag_color
                    }
                    prepareTag.push(data)
                })
                if (prepareTag.length > 0) {
                    requestParams.tag = prepareTag
                }
            }
            if(this.state.selectedCountry){
                requestParams.origin_country = this.state.selectedCountry.value
            }
            //     window.Appcues.track("Create Shipment", requestParams);

            this.props.createShipment(requestParams).then(res => {
                this.setState({
                    showLoader: false,
                    showAddTrackingSuccessMessage: true

                })

                if (res && res.data) {
                    if (res.data.meta && res.data.meta.code === 200) {
                        this.props.changeTabToAll()
                        this.props.clearTrackingList()
                        this.props.isShipmentAdd(true)
                        this.props.handleCancel()
                        this.clearAllData()
                        this.props.setPageId()
                        this.props.closeDrawer()
                        this.props.startPaginationLoader()
                        let storeObj = {}
                        let limit = localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : DEFAULT_TRACKING_LIMIT
                        let reqParams = { "pageId": 1, "limit": limit, status: [res.data.data.current_status] }
                        this.props.getTrackingList(reqParams)
                        storeObj.trackingStatusArr = [{
                            label: res.data.data.current_status,
                            value: res.data.data.current_status
                        }]
                        this.props.setTrackingFilters(storeObj)
                        this.props.isShipmentAdd(false)
                        getCarrierList().then(response => {
                            if (response && response.data) {
                                this.props.setCourierList(response.data)
                            }
                        })

                        getFiltersList().then(response => {
                            if (response && response.data) {
                                let statusListArr = []
                                let courierListArr = []
                                let originListArr = []
                                let destinationListArr = []
                                if (response.data.status && response.data.status.length > 0) {
                                    for (let index = 0; index < response.data.status.length; index++) {
                                        const element = response.data.status[index];
                                        statusListArr.push({
                                            label: element,
                                            value: element
                                        })
                                    }

                                }
                                if (response.data.courier && response.data.courier.length > 0) {
                                    for (let index = 0; index < response.data.courier.length; index++) {
                                        const element = response.data.courier[index];
                                        courierListArr.push({
                                            label: element,
                                            value: element
                                        })
                                    }

                                }
                                if (response.data.origin && response.data.origin.length > 0) {
                                    for (let index = 0; index < response.data.origin.length; index++) {
                                        const element = response.data.origin[index];
                                        if (element && Object.keys(element).length > 0) {
                                            if (element.label !== null && element.value !== null) {
                                                originListArr.push({
                                                    label: element.label,
                                                    value: element.value
                                                })
                                            }
                                        }
                                    }

                                }
                                if (response.data.destination && response.data.destination.length > 0) {
                                    for (let index = 0; index < response.data.destination.length; index++) {
                                        const element = response.data.destination[index];
                                        if (element && Object.keys(element).length > 0) {
                                            if (element.label !== null && element.value !== null) {
                                                destinationListArr.push({
                                                    label: element.label,
                                                    value: element.value
                                                })
                                            }
                                        }
                                    }

                                }
                                // this.props.sendStatusListValue(statusListArr)
                                this.props.setStatusArrayValueList(statusListArr)
                                this.props.setCourierArrayValueList(courierListArr)
                                this.props.setOriginCountryList(originListArr)
                                this.props.setDestinationCountryList(destinationListArr)
                            }
                        })
                        this.props.history.push(`/tracking?trackingStatusArr=${res.data.data.current_status}`)
                        // this.props.history.push(`/tracking?trackingStatusArr=`+res.data.data.current_status)
                    } else {
                        this.setState({ openSnackBar: true })
                    }
                } else {
                    this.setState({ openSnackBar: true })
                }
            })
        }
    }
    handleOnChange = (key, value) => {
        if (key === this.trackingNumberText) {
            this.setState({
                trackigNumberValue: value,
                isTrackingNumberRequired: false
            })
        }
        else if (key === this.customerNameText) {
            if (!nameRegEx.test(value)) {
                this.setState({
                    customerName: value,
                })
            }

        }
        else if (key === this.customerEmailText) {
            if (emailRegex.test(value) === true) {
                this.setState({
                    isValidEmail: true
                })
            }
            this.setState({
                customerEmail: String(value).toLowerCase()
            })
        }
        else if (key === this.otherEmailText) {
            if (emailRegex.test(value) === true) {
                this.setState({
                    isValidOtherEmail: true
                })
            }
            this.setState({
                otherEmail: String(value).toLowerCase()
            })
        }
        else if (key === this.customerPhoneNoText) {
            this.setState({
                customerPhoneNo: value
            })
        }
        else if (key === this.orderIDText) {
            this.setState({
                orderID: value
            })
        }
        else if (key === this.orderURLText) {
            this.setState({
                orderURL: value
            })
        }
        else if (key === this.postalCodeText) {
            this.setState({
                postalCodeValue: value,
                postalCodeRequired: false
            })
        }else if(key === this.originCountryText){
            this.setState({
                originCountryValue: value
            })
        }
    }

    addTag = (data) => {
        let tagList = [...this.state.tags]
        let tag = []
        if (data) {
            addShipmentsTag(data).then(res => {
                if (res.status === 200) {
                    tagList.push({
                        "_id": res.data.data._id,
                        "tag_name": res.data.data.tag_name,
                        "tag_color": res.data.data.tag_color
                    })
                    tag.push({
                        "_id": res.data.data._id,
                        "tag_name": res.data.data.tag_name,
                        "tag_color": res.data.data.tag_color
                    })
                    this.props.setTagList(tag)
                    let filterTagList = [...this.props.filterTagsList]
                    if (filterTagList.length > 0) {
                        let newTag = {
                            "value": res.data.data._id,
                            "label": res.data.data.tag_name,
                        }
                        filterTagList.push(newTag)
                        this.props.setFilterTagList(filterTagList)
                    }
                    initValues.tagMessage = { type: 'SUCCESS', message: 'Tag Added Successfully!' }
                    this.setState({
                        tags: tagList,
                        showTagSuccessMessageFlag: true
                    })
                } else {
                    initValues.tagMessage = { type: 'ERROR', message: res.data.meta.message[0] }
                    this.setState({
                        showTagSuccessMessageFlag: true
                    })
                }
            }).catch(error => { })
        }
    }

    linkTagSingle = (data) => {
        let tags = [...this.state.tags]
        if (tags.length > 0) {
            let found = tags.some(tag => tag._id === data._id);
            if (!found) {
                tags.push(data)
            }
        } else if (tags.length === 0) {
            tags.push(data)
        }
        this.setState({
            tags: tags
        })
    }

    deleteTag = (id) => {
        let tags = [...this.state.tags]
        let objIndex = tags.findIndex((obj => obj._id === id))
        if (objIndex > -1) {
            tags.splice(objIndex, 1)
        }
        this.setState({
            tags: tags
        })
    }
    toggleDrawer = (open) => (event) => {

        this.props.closeDrawer()

    };
    closhFlashMessage = () => {
        this.setState({ openSnackBar: false, showAddTrackingSuccessMessage : false, showTagSuccessMessageFlag : false })
    }
    handleDateChange = (date) => {
        let valid = false
        if(date && date._isValid){
            valid = true
        }
        this.setState({
            selectedDate: date,
            shipDateValid: valid
        })
      };
    render() {
        const { classes } = this.props
        let footerMessage = initValues.footerMessage
        if (this.props.createShipmentError && this.props.createShipmentError !== '') {
            footerMessage = { type: 'ERROR', message: this.props.createShipmentError }
        }
        let tagMessage = initValues.tagMessage
        let courierOptions = []
        let carrierListArr = this.state.carrierList
        for (let i = 0; i < carrierListArr.length; i++) {
            let eachCarrierData = carrierListArr[i]

            courierOptions.push({
                value: eachCarrierData.slug,
                label: eachCarrierData.title,
                requireFields: eachCarrierData.required_fields ? eachCarrierData.required_fields : [],
                optionalFields: eachCarrierData.optional_fields ? eachCarrierData.optional_fields: []
            })
        }
        let SubUserOptions = this.props.subUserList
        const {isSubUser} = this.state
        return (
            <React.Fragment>
                <Drawer
                    classes={{
                        paper: classes.addTrackingDrawerPaper,
                    }}
                    variant="temporary"
                    anchor="right"
                    open={this.props.openDrawer}
                    onClose={this.toggleDrawer(false)}
                    BackdropProps={{ invisible: true }}
                    transitionDuration={500}
                >
                    <Grid container className={classes.drawerHeader}>
                        <Typography className={classes.drawerTitle}>Add Shipment</Typography>
                        <IconButton
                            color="inherit"
                            onClick={this.toggleDrawer(false)}
                            className={classes.draweCancelButton}
                            edge="start"
                        >
                            <img alt="" src={cancelIconSettings} />
                        </IconButton>
                    </Grid>
                    <Scrollbars style={{ height: '100%', clear: 'both' }} className="detailScrollbarHeight" autoHide={true}>
                        <Grid container className={classes.drawerTrackingTextFieldContainer}>
                            <div >
                                <div className="formAttributeTop">
                                    <TextField
                                        InputLabelProps={{
                                            className: classes.addTrackingTextField
                                        }}
                                        error={this.state.isTrackingNumberRequired || this.state.trackingNumberLengthError ? true : false}
                                        id="tracking_number"
                                        helperText={this.state.isTrackingNumberRequired ? trackingNumberErrorMessage : this.state.trackingNumberLengthError ? trackingNumberLenghtErrorMessage : ""}
                                        label="Tracking Number"
                                        variant="outlined"
                                        className={classes.drawerTrackingField}
                                        value={this.state.trackigNumberValue}
                                        onChange={(e) => this.handleOnChange(this.trackingNumberText, e.target.value)}
                                    ></TextField>
                                </div>
                                <div className="formAttributeTop">
                                    <Autocomplete
                                        openOnFocus={true}
                                        id="courier"
                                        value={this.state.selectedOption}
                                        onChange={this.onCourierChange}
                                        options={courierOptions}
                                        getOptionLabel={option => option.label ? option.label : ""}
                                        renderInput={params => <TrackingTextField  {...params} error={this.state.isCourierRequired ? true : false} helperText={this.state.isCourierRequired ? courierErrorMessage : ""} label="Courier"  style={{ width: '350px !important' }} variant="outlined" />}
                                    />
                                </div>
                            </div>
                            {
                                this.state.originCountryFlag ?
                                    <div>
                                        {/* <div className="formAttributeTop">
                                            <TextField
                                                InputLabelProps={{
                                                    className: classes.addTrackingTextField
                                                }}
                                                id="origin_country"
                                                label="Origin Country"
                                                variant="outlined"
                                                className={classes.drawerTrackingField}
                                                value={this.state.originCountryValue}
                                                onChange={(e) => this.handleOnChange(this.originCountryText, e.target.value)}
                                            ></TextField>
                                        </div> */}

                                        <div className="formAttributeTop">
                                    <Autocomplete
                                        openOnFocus={true}
                                        id="origin_country"
                                        value={this.state.selectedCountry}
                                        onChange={this.onCountryChange}
                                        options={this.state.countryList}
                                        getOptionLabel={option => option.label ? option.label : ""}
                                        renderInput={params => <TrackingTextField  {...params} label="Origin Country"  style={{ width: '350px !important' }} variant="outlined" />}
                                    />
                                </div>
                                    </div> : ""
                            }
                            {this.state.postalCodeFlag ?
                                <div >
                                    <div className="formAttributeTop" >
                                        <TextField
                                            InputLabelProps={{
                                                className: classes.addTrackingTextField
                                            }}
                                            error={this.state.postalCodeRequired ? true : false}
                                            id="postal_code"
                                            helperText={this.state.postalCodeRequired ? postalCodeErrorMessage : ""}
                                            label="Postal Code"
                                            variant="outlined"
                                            className={classes.drawerTrackingField}
                                            value={this.state.postalCodeValue}
                                            onChange={(e) => this.handleOnChange(this.postalCodeText, e.target.value)}
                                        ></TextField>
                                    </div>
                                </div>
                                : ""}
                            {this.state.shipDateFlag ?
                                <div >
                                    <div className="formAttributeTop" style={{width: '45%'}}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                classes={{
                                                    root: classes.keyboardDatePickeRoot,
                                                }}
                                                disableToolbar
                                                variant="inline"
                                                format="MM-DD-YYYY"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="Select Ship To Date"
                                                value={this.state.selectedDate}
                                                onChange={this.handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}

                                            //eror shipDateValid
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                : ""}
                        </Grid>

                        <Grid container className={classes.drawerContent}>
                            <Typography className={classes.drawerContentText}>Other Details</Typography>
                        </Grid>

                        <Grid container className={classes.drawerTextFieldContainer}>
                            <div >
                                <div className="formAttribute">
                                    <TextField
                                        InputLabelProps={{
                                            className: classes.addTrackingTextField
                                        }}
                                        error={false}
                                        id="customer_name"
                                        // helperText={"Tracking Number Required"}
                                        label="Customer Name"
                                        variant="outlined"
                                        // FormHelperTextProps={this.state.postalCodeRequired}
                                        className={classes.drawerTrackingField}
                                        value={this.state.customerName}
                                        onChange={(e) => this.handleOnChange(this.customerNameText, e.target.value)}
                                    ></TextField>
                                </div>
                                <div className="formAttribute">
                                    <TextField
                                        InputLabelProps={{
                                            className: classes.addTrackingTextField
                                        }}
                                        error={false}
                                        id="customer_email"
                                        // helperText={"Tracking Number Required"}
                                        label="Customer Email"
                                        variant="outlined"
                                        // FormHelperTextProps={this.state.postalCodeRequired}
                                        className={classes.drawerTrackingField}
                                        value={this.state.customerEmail}
                                        onChange={(e) => this.handleOnChange(this.customerEmailText, e.target.value)}
                                    ></TextField>
                                    {this.state.isValidEmail === false ? <label className="error-fonts">{emailErrorMessage}</label> : ''}
                                </div>
                            </div>
                            <div >
                                <div className="formAttribute">
                                    <TextField
                                        InputLabelProps={{
                                            className: classes.addTrackingTextField
                                        }}
                                        id="phone_no"
                                        // helperText={"Tracking Number Required"}
                                        label="Customer Phone No"
                                        variant="outlined"
                                        // FormHelperTextProps={this.state.postalCodeRequired}
                                        className={classes.drawerTrackingField}
                                        value={this.state.customerPhoneNo}
                                        onChange={(e) => this.handleOnChange(this.customerPhoneNoText, e.target.value)}
                                    ></TextField>
                                </div>
                                <div className="formAttribute">
                                    <TextField
                                        InputLabelProps={{
                                            className: classes.addTrackingTextField
                                        }}
                                        error={this.state.isValidOtherEmail ? false : true}
                                        id="other_email"
                                        // helperText={"Tracking Number Required"}
                                        label="Other Email"
                                        variant="outlined"
                                        // FormHelperTextProps={this.state.postalCodeRequired}
                                        className={classes.drawerTrackingField}
                                        value={this.state.otherEmail}
                                        onChange={(e) => this.handleOnChange(this.otherEmailText, e.target.value)}
                                    ></TextField>
                                    {this.state.isValidOtherEmail === false ? <label className="error-fonts">{emailErrorMessage}</label> : ''}
                                </div>
                            </div>
                            <div >
                                <div className="formAttribute">
                                    <TextField
                                        InputLabelProps={{
                                            className: classes.addTrackingTextField
                                        }}
                                        error={false}
                                        id="order_id"
                                        // helperText={"Tracking Number Required"}
                                        label="Order ID"
                                        variant="outlined"
                                        // FormHelperTextProps={this.state.postalCodeRequired}
                                        className={classes.drawerTrackingField}
                                        value={this.state.orderID}
                                        onChange={(e) => this.handleOnChange(this.orderIDText, e.target.value)}
                                    ></TextField>
                                </div>
                                <div className="formAttribute">
                                    <TextField
                                        InputLabelProps={{
                                            className: classes.addTrackingTextField
                                        }}
                                        error={this.state.isValidURL ? false : true}
                                        id="order_url"
                                        // helperText={"Tracking Number Required"}
                                        label="Order URL"
                                        variant="outlined"
                                        // FormHelperTextProps={this.state.postalCodeRequired}
                                        className={classes.drawerTrackingField}
                                        value={this.state.orderURL}
                                        onChange={(e) => this.handleOnChange(this.orderURLText, e.target.value)}
                                    ></TextField>
                                    {this.state.isValidURL === false ? <label className="error-fonts">{URLErrorMessage}</label> : ''}
                                </div>
                            </div>
                            <div className="trackingtagDive">
                                <div className="formAttributeBottom">
                                    <AddTags
                                        addTag = {this.addTag}
                                        tags = {this.state.tags}
                                        linkTagSingle={this.linkTagSingle}
                                        deleteTag={this.deleteTag}
                                        name = "add"
                                    />
                                </div>
                                {
                                    !isSubUser ?
                                        <div className="formAttributeBottom">
                                            <Autocomplete
                                                // style={{padding: '0px !important'}}
                                                id="subuser"
                                                openOnFocus={true}
                                                value={this.state.selectedUser}
                                                onChange={this.onSubUserChange}
                                                options={SubUserOptions}
                                                getOptionLabel={option => option.label ? option.label : ""}
                                                renderInput={params => <TrackingTextField {...params} label="Assign Sub User" variant="outlined" />}
                                            />
                                        </div> : ""
                                }
                            </div>
                        </Grid>
                    </Scrollbars>
                    <footer className={classes.trackingDrawerFooter} container="true" justify="left">
                        <Button
                            onClick={this.handleAddClick}
                            startIcon={<SaveIcon />}
                            disabled={this.state.showLoader}
                            className={(this.state.showLoader) ? classes.saveBtnDisable : classes.saveBtn}>
                            SAVE
                                </Button>
                        {(this.state.showLoader) && <CircularProgress size={22} className={classes.buttonProgress} />}
                        <Button startIcon={<CloseIcon />} onClick={this.toggleDrawer(false)} className={classes.deleteButton}>
                            CANCEL
                              </Button>
                        {
                            this.state.showAddTrackingSuccessMessage && this.state.openSnackBar ? <FlashMessage open={this.state.openSnackBar} closeFlash={this.closhFlashMessage} classes={classes.drawerSnackbar} open={true} messageDetails={footerMessage} /> : ''
                        }
                        {
                            this.state.showTagSuccessMessageFlag ? <FlashMessage open={this.state.openSnackBar} closeFlash={this.closhFlashMessage} classes={classes.drawerSnackbar} open={true} messageDetails={tagMessage} /> : ''
                        }
                    </footer>
                </Drawer>

            </React.Fragment>
        )
    }
}
// export default (TrackingDrawer)
const mapStateToProps = state => ({
    isLoading: state.tracking.loader.isLoading,
    createShipmentError: state.tracking.list.createShipmentError,
    filterTagsList: state.tracking.list.filterTags,
    subUserList: state.tracking.list.addedSubUserList
})
export default withRouter(connect(mapStateToProps, { setDestinationCountryList, setOriginCountryList, setCourierArrayValueList, setStatusArrayValueList, createShipment, getTrackingList, clearTrackingList, getCarrierList, setTrackingFilters, setCourierList, isShipmentAdd, getFiltersList, setTagList, setFilterTagList })(TrackingDrawer))