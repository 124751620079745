import React from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getTrackingDetails, clearTrackingDetails, setDataForTrackingDetail, updateShipmentStatus, deleteMultipleShipment, assignUnassignSubUserToShipment, clearTrackingList, setUpdatedTrackingList } from './actions/actionMethods'
import { getUserDetails } from '../Setup/actions/actionMethods'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ReactCountryFlag from "react-country-flag";
import moment from 'moment-timezone'
import { showCourierLogo } from '../common/commonFunction'
import Filters from './filters'
import LinearProgress from '@material-ui/core/LinearProgress';
import TrackingStatusTabs from './TrackingStatusTabs'
import Button from '@material-ui/core/Button';
import { clearIcon, noTrackingIcon } from '../common/imageList'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import styles from './static/css/tracking'
import TextField from '@material-ui/core/TextField';
import FlashMessage from '../TrackingList/common/flashMessage'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import Drawer from './detail/editTracking'
import ExportDrawer from './export'
import {STATUS_ARRAY} from './common/constants'
import {searchRegex} from '../static/constants'
import { Autocomplete } from '@material-ui/lab';
import { ListTextField } from '../Setup/common/materialComponents'
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'courier', label: 'Courier', disablePadding: false },
  { id: 'service', label: 'Service', disablePadding: false },
  { id: 'tracking_number', label: 'Tracking No.', disablePadding: false },
  { id: 'origin', label: 'Origin', disablePadding: false },
  { id: 'destination', label: 'Destination', disablePadding: false },
  { id: 'last_status', label: 'Last Check Point', disablePadding: false },
  { id: 'time', label: 'Last Check Time', disablePadding: false },
    localStorage.getItem('user_type') !== "subuser" ? 
  { id: 'assigned_to', label: 'Assigned To', disablePadding: false }: "",
  { id: 'date', label: 'Expected Date', disablePadding: false },
  { id: 'transit_day', label: 'Days', disablePadding: false },
  { id: 'status', label: 'Status', disablePadding: false },
  { id: 'tag', label: 'Tags', disablePadding: false },
];

const statusArray = [
  { value: 'OutForDelivery', label: 'Out for Delivery' },
  { value: 'InTransit', label: 'In Transit' },
  { value: 'Delivered', label: 'Delivered' },
  { value: 'Exception', label: 'Exception' },
  { value: 'InfoReceived', label: 'Info Received' },
  { value: 'FailedAttempt', label: 'Failed Attempt' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Expired', label: 'Expired' },
]

class EnhancedTableHead extends React.Component {

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
    let trackingColumn = localStorage.getItem('trackingColumn') ? JSON.parse(localStorage.getItem('trackingColumn')) : []
    return (
      <TableHead>
        <TableRow className={classes.headTableRow}>
          <TableCell className={classes.headTableCell} padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              // indeterminateIcon={<img src={indeterminateCheckBox} />}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all shipment' }}
              color="primary"
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == headCell.id))) ? 'table-cell' : 'none') }}
              className={classes.headTableCell}
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
              {/* {headCell.id == 'date' ?
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={this.createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
                : headCell.label} */}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

class EnhancedTableToolbar extends React.Component {
  displayStatusText = 'displayStatus'
  displaySubUserText = 'displaySubUser'
  state = {
    statusValue: '',
    assignUserValue: '',
    subUserList: this.props.subUserList,
    openDialog: false,
    displayStatus: 0,
    displaySubUser: 0,
    showStatus: 0,
    showSubUser: 0,
    exportDrawer: false,
    successMessage: false,
    errorMessage: false,
    openSnackBar: false
  }
  handleOnClick = (key) => {
    let obj = {
      [key]: 1
      // filterFlag: false
    }
    this.setState(obj)
    if (key === this.displayStatusText) {
      this.setState({
        displaySubUser: 0
      })
    }
    else if (key === this.displaySubUserText) {
      this.setState({
        displayStatus: 0,
      })
    }
  }

  handleCloseClick = (key) => {
    if (key) {
      let obj = { [key]: 0 }
      this.setState(obj);
    }
    this.setState({ subUserList: this.props.subUserList })
  }
  onChangeStatus = (value) => {
    this.setState({ statusValue: value, showStatus: 1 })
  }
  updateStatus = () => {
    this.setState({ statusValue: '', displayStatus: 0 })
    let requestParams = {
      ids: this.props.selectedIDs,
      "current_status": this.state.statusValue
    }
    updateShipmentStatus(requestParams).then(res => {
      if (res && res.data && res.data.meta && res.data.meta.code === 200) {
        this.setState({ successMessage: 'Shipment Status Updated Successfully', openSnackBar: true })
        this.props.reloadPageAfterAction()
      } else {
        this.setState({ errorMessage: 'Error While Updating Shipment Status', openSnackBar: true })
      }
    }).catch(error => {
      this.setState({ errorMessage: 'Error While Updating Shipment Status', openSnackBar: true })
    })

  }
  onChangeUser = (value) => {
    this.setState({ assignUserValue: value, showSubUser: 1 })
  }
  assignSubUser = () => {
    this.setState({ assignUserValue: '', displaySubUser: 0 })
    let requestParams = {
      ids: this.props.selectedIDs,
      "assigned_id": this.state.assignUserValue
    }
    assignUnassignSubUserToShipment(requestParams).then(res => {
      if (res && res.data && res.data.meta && res.data.meta.code === 200) {
        this.setState({ successMessage: 'Shipment successfully assign to sub user!', openSnackBar: true })
        this.props.reloadPageAfterAction()
      } else {
        this.setState({ errorMessage: 'Error while assign shipment to sub user', openSnackBar: true })
      }
    }).catch(err => {
      this.setState({ errorMessage: 'Error while assign shipment to sub user', openSnackBar: true })
    })
  }
  handleClickOpen = () => {
    this.setState({ openDialog: true, displayStatus: 0, displaySubUser: 0 })
  }
  handleExport = () => {
    this.setState({ displayStatus: 0, displaySubUser: 0, exportDrawer: true })
    // this.props.reloadPageAfterAction()
  }
  drawerClose = () => {
    this.setState({ exportDrawer: false })
    // this.props.reloadPageAfterAction()
  }
  handleUnassign = () => {
    this.setState({ displayStatus: 0, displaySubUser: 0 })
    let requestParams = {
      ids: this.props.selectedIDs
    }
    assignUnassignSubUserToShipment(requestParams).then(res => {
      if (res && res.data && res.data.meta && res.data.meta.code === 200) {
        this.setState({ successMessage: 'Shipments unassign successfully !', openSnackBar: true })
        this.props.reloadPageAfterAction()
      } else {
        this.setState({ errorMessage: 'Error While unassign Shipment', openSnackBar: true })
      }
    }).catch(err => {
      this.setState({ errorMessage: 'Error While unassign Shipment', openSnackBar: true })
    })
  }
  handleCloseDialog = () => {
    this.setState({ openDialog: false })
  }
  deleteBulkShipment = () => {
    this.setState({ openDialog: false })
    let requestParams = {
      ids: this.props.selectedIDs
    }
    deleteMultipleShipment(requestParams).then(res => {
      if (res && res.data && res.data.meta && res.data.meta.code === 200) {
        this.setState({ successMessage: 'Selected Shipments deleted successfully !', openSnackBar: true })
        this.props.reloadPageAfterAction()
      } else {
        this.setState({ errorMessage: 'Error While Deleting Shipment', openSnackBar: true })
      }
    }).catch(err => {
      this.setState({ errorMessage: 'Error While Deleting Shipment', openSnackBar: true })
    })

  }

  closeFlashMessage = () => {
    this.setState({ openSnackBar: false })
  }
  closeDialog = () => {
    this.props.reesetSelect()
  }
  closTopbar = () => {
    this.setState({ displayStatus: 0, displaySubUser: 0 })
    this.props.reesetSelect()
  }
  searchSubuser = (event) => {
    var searchSubUserInList = this.props.subUserList.length > 0 ? this.props.subUserList : this.state.subUserList
    let value = event.target.value.replace(searchRegex, "")
    searchSubUserInList = searchSubUserInList.filter(function (user) {
      return user.label.toLowerCase().search(value.toLowerCase()) !== -1
    })
    this.setState({ subUserList: searchSubUserInList })
  }
  render() {
    const { numSelected, classes, statusValue } = this.props;
    let status = this.state.statusValue
    let subUserStatus = this.state.assignUserValue
    let isSubUser = localStorage.getItem('user_type') !== "subuser" ? true : false
    return (
      <Toolbar
        className={clsx(classes.toolbarRoot, {
          [classes.toolbarHighlight]: numSelected > 0,
        })}
        variant="regular"
        disableGutters={true}
      >
        {numSelected > 0 ? (
          <div className={classes.bulkFilterWrap}>
            <div className={classes.bulkFilterCount}>{numSelected} selected </div>
            <div className={classes.bulkFilterLine}><span className={classes.bulkfilterActionSpan}></span></div>
            <div className={classes.bulkFilterActionWrap}> <Button onClick={(event) => this.handleClickOpen()} size="small" className={classes.bulkFilterAction}> DELETE</Button> </div>
            <div className={classes.bulkFilterActionWrap}> <Button onClick={(event) => this.handleExport()} size="small" className={classes.bulkFilterAction}> Export</Button> </div>
            {
              isSubUser ?
                <div className={classes.bulkFilterActionWrap}> <Button onClick={(event) => this.handleUnassign()} size="small" className={classes.bulkFilterAction}> UNASSIGN SHIPMENT</Button> </div>
                : ""
            }
            <div className={classes.bulkFilterActionWrap}>
              <div className={classes.FilterBtnDD}>
                <div className={classes.filterButton} onClick={() => this.handleOnClick(this.displayStatusText)}>
                  <div>UPDATE STATUS</div>
                  <div className={classes.filterArrow}></div>
                </div>
                {
                  this.state.displayStatus === 1 ?
                    <div className={classes.filterDropdown}>
                      <div className={classes.filterTextHheading}>
                        <span className={classes.verticalAlignSpan}>UPDATE STATUS</span>
                      </div>
                      <div className={`${classes.commonDropdownMenu}`}>
                        {
                          <List>
                            {statusArray.map((element) => {
                              const labelId = `checkbox-list-label-${element.value}`;
                              return (
                                <ListItem className={classes.MuiListItemSecondaryAction} key={element.label} role={undefined} dense button onClick={(event) => this.onChangeStatus(element.value)}>
                                  <ListItemIcon className={classes.MuiListItemWithoutIcon}>
                                    <Radio
                                      checked={status == element.value ? true : false}
                                      onChange={(event) => this.onChangeStatus(element.value)}
                                      name="radio-button-demo"
                                      color='primary'
                                      size='small'
                                    />
                                  </ListItemIcon>
                                  <ListItemText id={labelId} className={classes.filterDropdownText} disableTypography={true} primary={element.label} />
                                </ListItem>
                              );
                            })}
                          </List>
                        }
                      </div>
                      <hr className={classes.hrMarginBlock} />
                      <div className={classes.topbarDropdownfooter}>
                        {this.state.showStatus ?
                          <div>
                            <a className={classes.topbarDropdownFooterText} onClick={() => this.updateStatus()}>APPLY</a>
                            <a className={classes.topbarDropdownFooterText} onClick={() => this.handleCloseClick(this.displayStatusText)}>CANCEL</a>
                          </div>
                          : <a className={classes.topbarDropdownFooterText} onClick={() => this.handleCloseClick(this.displayStatusText)}>CANCEL</a>}
                      </div>
                    </div> : ''
                }
              </div>
            </div>
            {
              isSubUser ?
                <div className={classes.bulkFilterActionWrap}>
                  <div className={classes.FilterBtnDD}>
                    <div className={classes.filterButton} onClick={() => this.handleOnClick(this.displaySubUserText)}>
                      <div>ASSIGN TO</div>
                      <div className={classes.filterArrow}></div>
                    </div>
                    {
                      this.state.displaySubUser === 1 ?
                        <div className={classes.filterDropdown}>
                          <div className={classes.filterTextHheading}>
                            <span className={classes.verticalAlignSpan}>ASSIGN TO</span>
                          </div>
                          <div>
                            <TextField
                              className={classes.dropdownSearch}
                              id="standard-search"
                              placeholder="Search"
                              // disableUnderline={true}
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  padding: '15px 0 0px 16px',
                                  fontSize: '14px',
                                  height: '25px'
                                }
                              }}

                              type="text"
                              onChange={this.searchSubuser} />
                          </div>
                          <hr className={classes.hrMarginBlock} />
                          <div className={classes.commonDropdownMenu} >
                            {
                              this.state.subUserList && this.state.subUserList.length > 0 ?
                                <List>
                                  {this.state.subUserList.map((element) => {
                                    const labelId = `checkbox-list-label-${element.value}`;
                                    return (
                                      <ListItem className={classes.MuiListItemSecondaryAction} key={element.label} role={undefined} dense button onClick={(event) => this.onChangeUser(element.value)}>
                                        <ListItemIcon className={classes.MuiListItemWithoutIcon}>
                                          <Radio
                                            checked={subUserStatus == element.value ? true : false}
                                            onChange={(event) => this.onChangeUser(element.value)}
                                            name="radio-button-demo"
                                            color='primary'
                                            size='small'
                                          />
                                        </ListItemIcon>
                                        <ListItemText className={classes.filterDropdownText} disableTypography={true} id={labelId} primary={element.label} />
                                      </ListItem>
                                    );
                                  })}
                                </List>
                                : <div className={classes.noFilterFound}>No Sub User Found</div>
                            }

                          </div>
                          <hr className={classes.hrMarginBlock} />
                          <div className={classes.topbarDropdownfooter}>
                            {this.state.showSubUser ?
                              <div>
                                <a className={classes.topbarDropdownFooterText} onClick={() => this.assignSubUser()}>APPLY</a>
                                <a className={classes.topbarDropdownFooterText} onClick={() => this.handleCloseClick(this.displaySubUserText)}>CANCEL</a>
                              </div>
                              : <a className={classes.topbarDropdownFooterText} onClick={() => this.handleCloseClick(this.displaySubUserText)}>CANCEL</a>}
                          </div>
                        </div> : ''
                    }
                  </div>
                </div> : ""
            }
            <div>
              <Dialog
                open={this.state.openDialog}
                onClose={this.closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Are you sure to delete selected shipments?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">This action can not be undo.</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className={classes.dialogCancelBtn} onClick={this.handleCloseDialog}>
                    Cancel
            </Button>
                  <Button className={classes.dialogActionBtn} onClick={this.deleteBulkShipment} autoFocus>
                    Yes,  Delete
            </Button>
                </DialogActions>
              </Dialog>
              <ExportDrawer
                openDrawer={this.state.exportDrawer}
                closeDrawer={this.drawerClose}
                classes={classes}
                setPageId={this.props.setPageId}
                setExportMessage={this.props.setExportMessage}
                hasShipments={true}
                // filters={}
                onExport={this.props.handleExportShipments}
                isSelected={true}
                selectedIDs={this.props.selectedIDs}
              />
            </div>
          </div>
        ) : (
            <Filters
              sendStatusListValue={statusValue}
              searchTerm={''}
              setPageId={this.props.setPageId}
              carrierList={this.props.carrierList}
              handleCancel={this.props.handleCancel}
              changeTabToAll={this.props.changeTabToAll}
              drawerClose={this.props.drawerClose}
              removeColumn={this.props.removeColumn}
              handlePopUpDisplay={this.props.handlePopUpDisplay}
              setExportMessage={this.props.setExportMessage}
              handleExportShipments={this.props.handleExportShipments}
            />
          )}

        {this.state.successMessage && this.state.successMessage !== '' ?
          <FlashMessage open={this.state.openSnackBar} closeFlash={this.closeFlashMessage} messageDetails={{ type: 'SUCCESS', message: this.state.successMessage }} />
          : ''
        }
        {}
        {this.state.errorMessage && this.state.errorMessage !== '' ?
          <FlashMessage open={this.state.openSnackBar} closeFlash={this.closeFlashMessage} messageDetails={{ type: 'ERROR', message: this.state.errorMessage }} />
          : ''
        }
        {/* : ""} */}
        {numSelected > 0 ? (
          <Tooltip title="close">
            <IconButton onClick={(event) => this.closTopbar()} className={classes.bulkActionIcon} aria-label="close">
              {<img src={clearIcon} alt="clear_IC" />}
            </IconButton>
          </Tooltip>
        ) : (
            ''
          )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};
class EnhancedTable extends React.Component {
  displayStatusText = 'displayStatus'
  displaySubUserText = 'displaySubUser'
  constructor() {
    super();
    this.trackingMessage = { type: 'SUCCESS', 'message': '' }
    this.isSuccess = true
    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      page: 0,
      rowsPerPage: 20,
      subUserList: [],
      isShiftDown: false,
      isCtrlDown: false,
      lastSelectedItem: null,
      displaySubUser: 0,
      displayStatus: 0,
      detailTrackinngDrawer: false,
      isTrackingDelete: false,
      trackingDetail: {},
      customDomainURL: '',
      subDomainName: '',
      trackingColumn: '',
      selectedSubUser: { value: 'Assign To', label: 'default' },
      successMessage: false,
      errorMessage: false,
      openSnackBar : false
    }
    this.listEl = null;

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleSelectStart = this.handleSelectStart.bind(this);
    // this.setWrapperRef = this.setWrapperRef.bind(this);
    // this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener("keyup", this.handleKeyUp, false);
    document.addEventListener("keydown", this.handleKeyDown, false);
    if (this.props.setTrackingListFlag === 1 && this.props.trackingList !== undefined && this.props.trackingList !== null && this.props.trackingList.length > 0) {
      this.listEl.addEventListener("selectstart", this.handleSelectStart, false);
    }
    // document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillMount() {
    if (this.props.match.params.id && this.props.trackingList.length > 0) {
      let trackingDetail = this.props.trackingList.filter(x => x._id == this.props.match.params.id)[0]
      this.setState({ detailTrackinngDrawer: true, trackingDetail: trackingDetail })
      this.props.setDataForTrackingDetail(trackingDetail)
      this.props.clearTrackingDetails()
      this.props.history.push(`/tracking/${this.props.match.params.id}`)
    }
    let subUserArr = []
    if (this.props.subUserList.length > 0) {
      this.props.subUserList.forEach(subUser => {
        if (subUser !== undefined) {
          if (subUser.value && subUser.label) {
            subUserArr.push(subUser)
          }
        }
      })
      this.setState({ subUserList: subUserArr })
    }
    this.setState({
      customDomainURL: localStorage.getItem('custom_domain_url') && localStorage.getItem('custom_domain_url') !== '' ? localStorage.getItem('custom_domain_url') : '',
      subDomainName: localStorage.getItem('sub_domain') && localStorage.getItem('sub_domain') !== '' ? localStorage.getItem('sub_domain') : ''
    }, () => {
      if (this.state.subDomainName == '') {
        getUserDetails().then(userDetailsResponse => {
          this.setState({
            customDomainURL: userDetailsResponse.data.custom_domain_url,
            subDomainName: userDetailsResponse.data.sub_domain
          })
        })
      }
    })
    document.removeEventListener("keyup", this.handleKeyUp);
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  // setWrapperRef(node) {
  //   this.wrapperRef = node;
  // }
  // handleClickOutside(event) {
  //   if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
  //     this.setState({displaySubUser : 0})
  //   }
  // }
  handleOnClick = (key, id) => {
    let obj = {
      [key]: id
      // filterFlag: false
    }
    this.setState(obj)
    if (key === this.displayStatusText) {
      this.setState({
        displaySubUser: 0
      })
    }
    else if (key === this.displaySubUserText) {
      this.setState({
        displayStatus: 0,
      })
    }
  }
  handleSelectStart(e) {
    if (this.state.isShiftDown || this.state.isCtrlDown) {
      e.preventDefault();
    }
  }

  handleKeyUp(e) {
    if (e.key === "Shift" && this.state.isShiftDown) {
      this.setState({ isShiftDown: false });
    }
    if (e.key === "Control" && this.state.isCtrlDown) {
      this.setState({ isCtrlDown: false });
    }
  }

  handleKeyDown(e) {
    if (e.key === "Shift" && !this.state.isShiftDown) {
      this.setState({ isShiftDown: true });
    }
    if (e.key === "Control" && !this.state.isCtrlDown) {
      this.setState({ isCtrlDown: true });
    }
  }

  handleSelectItem(e, id) {
    const value = id;
    const nextValue = this.getNextValue(value);
    this.setState({ selected: nextValue, lastSelectedItem: value });
    this.drawerClose()
  }

  getNextValue(value) {
    const { isShiftDown, selected, isCtrlDown } = this.state;
    const hasBeenSelected = !selected.includes(value);

    if (isShiftDown || isCtrlDown) {
      const newSelectedItems = this.getNewSelectedItems(value);
      const selections = [...new Set([...selected, ...newSelectedItems])];

      if (!hasBeenSelected) {
        return selections.filter(item => !newSelectedItems.includes(item));
      }

      return selections;
    }

    return selected.includes(value)
      ? selected.filter(item => item !== value)
      : [...selected, value];
  }

  getNewSelectedItems(value) {
    const { lastSelectedItem } = this.state;
    const currentSelectedIndex = this.props.trackingList.findIndex(item => item._id === value);
    const lastSelectedIndex = this.props.trackingList.findIndex(
      item => item._id === lastSelectedItem
    );

    return this.props.trackingList
      .slice(
        Math.min(lastSelectedIndex, currentSelectedIndex),
        Math.max(lastSelectedIndex, currentSelectedIndex) + 1
      )
      .map(item => item._id);
  }
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleSelectAllClick = (event) => {
    this.drawerClose()
    if (this.state.selected.length === 0 && event.target.checked) {
      const newSelecteds = this.props.trackingList.map((n) => n._id);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };
  handleClick = (event, name) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };
  resetPageForFilters = () => {
    this.setState({ page: 0 });
    this.setState({ selected: [] });
  }
  removeColumn = () => {
    this.setState({ trackingColumn: '' });
  }
  reesetSelect = () => {
    this.setState({ selected: [] });
  }
  reloadPageAfterAction = async () => {
    this.setState({ selected: [] });
    let requestParams = {}
    requestParams.pageId = 1
    requestParams.limit = 20
    this.props.clearTrackingList()
    this.props.startPaginationLoader()
    await this.props.prepereFilter()
    this.props.applySearchAndFilter()
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.pagination(newPage + 1, this.state.rowsPerPage)
    this.setState({ selected: [] });
  };
  handleChangeRowsPerPage = (event) => {
    localStorage.setItem('rowsPerPage', parseInt(event.target.value, 10))
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
    this.pagination(1, event.target.value)
    if (this.state.selected && this.state.selected.length > parseInt(event.target.value, 10)) {
      this.setState({ selected: [] })
    }
  };
  pagination = (page, rows) => {
    this.props.startPaginationLoader()
    let hasMore = (this.state.page * this.state.rowsPerPage) < this.props.noOfTotalTrackingRecords
    if (hasMore) {
      let requestParams = { "pageId": page, "limit": rows }
      if (this.props.trackingGlobalFilters && Object.keys(this.props.trackingGlobalFilters).length > 0) {
        let globalFiltersValue = this.props.trackingGlobalFilters
        let trackingStatusArrVal = []
        let couriersListArrVal = []
        let originListArrVal = []
        let destinationListArrVal = []
        let subUserListArrVal = []
        let tagListArrVal = []
        if (globalFiltersValue.trackingStatusArr && globalFiltersValue.trackingStatusArr.length > 0) {
          for (let i = 0; i < globalFiltersValue.trackingStatusArr.length; i++) {
            trackingStatusArrVal.push(globalFiltersValue.trackingStatusArr[i].value)
          }

          if (trackingStatusArrVal[0] === 'All') {
            requestParams.status = ['Pending', 'InfoReceived', 'InTransit', 'OutForDelivery', 'Delivered', 'Exception', 'FailedAttempt', 'Expired']
          }
          else {
            requestParams.status = trackingStatusArrVal
          }
        }
        if (globalFiltersValue.courierListArr && globalFiltersValue.courierListArr.length > 0) {
          for (let i = 0; i < globalFiltersValue.courierListArr.length; i++) {
            couriersListArrVal.push(globalFiltersValue.courierListArr[i].value)
          }
          requestParams.courier = couriersListArrVal
        }
        if (globalFiltersValue.originListArr && globalFiltersValue.originListArr.length > 0) {
          for (let i = 0; i < globalFiltersValue.originListArr.length; i++) {
            originListArrVal.push({
              label: globalFiltersValue.originListArr[i].label,
              value: globalFiltersValue.originListArr[i].value
            })
          }
          requestParams.origin = originListArrVal
        }
        if (globalFiltersValue.destinationListArr && globalFiltersValue.destinationListArr.length > 0) {
          for (let i = 0; i < globalFiltersValue.destinationListArr.length; i++) {
            destinationListArrVal.push({
              label: globalFiltersValue.destinationListArr[i].label,
              value: globalFiltersValue.destinationListArr[i].value
            })
          }
          requestParams.destination = destinationListArrVal
        }
        if (globalFiltersValue.transitDays !== '') {
          requestParams.transitDays = globalFiltersValue.transitDays
        }
        if (globalFiltersValue.date !== '') {
          requestParams.date = globalFiltersValue.date
        }
        if (globalFiltersValue.fromDate !== '' && globalFiltersValue.toDate !== '') {
          requestParams.from_date = globalFiltersValue.fromDate
          requestParams.to_date = globalFiltersValue.toDate
        }
        if (globalFiltersValue.subUserListArr && globalFiltersValue.subUserListArr.length > 0) {
          for (let i = 0; i < globalFiltersValue.subUserListArr.length; i++) {
            subUserListArrVal.push({
              label: globalFiltersValue.subUserListArr[i].label,
              value: globalFiltersValue.subUserListArr[i].value
            })
          }
          requestParams.assigned_id = subUserListArrVal
        }
        if (globalFiltersValue.tagListArr && globalFiltersValue.tagListArr.length > 0) {
          for (let i = 0; i < globalFiltersValue.tagListArr.length; i++) {
            tagListArrVal.push({
              label: globalFiltersValue.tagListArr[i].label,
              value: globalFiltersValue.tagListArr[i].value
            })
          }
          requestParams.tags = tagListArrVal
        }
      }
      this.props.getTrackingList(requestParams)
    }
  }
  handleDetailClick = (id, trackingDetail) => {
    if (id) {
      this.setState({ detailTrackinngDrawer: true, trackingDetail: trackingDetail })
      this.props.setDataForTrackingDetail(trackingDetail)
      this.props.clearTrackingDetails()
      // this.props.getTrackingDetails(id)
      this.props.history.push(`/tracking/${id}`)

    }
  }
  drawerOpen = () => {
    this.setState({ detailTrackinngDrawer: true })
  }
  drawerClose = () => {
    this.setState({ detailTrackinngDrawer: false })
    this.props.handleCancel()
    setTimeout(() => {
      this.props.setDataForTrackingDetail('')
    }, 500)
  }
  onChangeSubUser = (event, values) => {
    this.setState({
      selectedSubUser: values
    })
  }
  isSelected = (name) => this.state.selected.indexOf(name) !== -1;
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.trackingList.length - page * rowsPerPage);
  onStatusChange = (event, selectedStatus, trackingId) => {
    this.props.startPaginationLoader()
    let requestParams = {
      ids: [trackingId],
      "current_status": selectedStatus.value
    }
    updateShipmentStatus(requestParams).then(res => {
      if (res && res.data && res.data.meta && res.data.meta.code === 200) {
        let shipmentData = this.props.trackingList
        if (shipmentData && shipmentData.length > 0) {
          let index = shipmentData.findIndex(tracking => tracking._id === trackingId)
          if (index > -1) {
            shipmentData[index].current_status = selectedStatus.value
            shipmentData[index].trackings.tag = selectedStatus.value
            shipmentData.splice(index, 1, shipmentData[index])
          }
          this.props.setUpdatedTrackingList(shipmentData, shipmentData.length)
        }
        this.trackingMessage = { type: 'SUCCESS', message: 'Shipment Status Updated Successfully' }
        this.setState({ openSnackBar: true })
      } else {
        this.trackingMessage = { type: 'ERROR', message: 'Error While Updating Shipment Status' }
        this.setState({ openSnackBar: true })
      }
    }).catch(error => {
      this.props.startPaginationLoader(1)
      this.trackingMessage = { type: 'ERROR', message: 'Error While Updating Shipment Status' }
      this.setState({ openSnackBar: true })
    })
  }
  onSubUserChange = (event, selectedSubUser, trackingId) => {
    this.props.startPaginationLoader()
    let requestParams = {
      ids: [trackingId]
    }
    if(selectedSubUser.value !== 'unassigned'){
      requestParams.assigned_id = selectedSubUser.value
    }
    assignUnassignSubUserToShipment(requestParams).then(res => {
      this.props.startPaginationLoader(1)
      if (res && res.data && res.data.meta && res.data.meta.code === 200) {
        let message = "Shipment successfully assign to sub user!"
        let shipmentData = this.props.trackingList
        if (shipmentData && shipmentData.length > 0) {
          let index = shipmentData.findIndex(tracking => tracking._id === trackingId)
          if (index > -1) {
            if(selectedSubUser.value === 'unassigned'){
              shipmentData[index].assigned_id = null
              message = "Shipments unassign successfully !"
            }
            else{
              shipmentData[index].assigned_id = selectedSubUser.value
            }
            shipmentData.splice(index, 1, shipmentData[index])
          }
          this.props.setUpdatedTrackingList(shipmentData, shipmentData.length)
        }
        this.trackingMessage = { type: 'SUCCESS', message: message }
        this.setState({ openSnackBar: true })
      } else {
        this.trackingMessage = { type: 'ERROR', message: 'Error while assign shipment to sub user' }
        this.setState({ openSnackBar: true })
      }
    }).catch(err => {
      this.props.startPaginationLoader(1)
      this.trackingMessage = { type: 'ERROR', message: 'Error while assign shipment to sub user' }
      this.setState({ openSnackBar: true })
    })
  }
  closeFlashMessage = () => {
    this.setState({ openSnackBar: false })
  }
  render() {
    const { classes } = this.props;
    let trackinSubUserList = this.props.subUserList
    let assignUnassignArray = []
    if(trackinSubUserList.length){
      assignUnassignArray = assignUnassignArray.concat([{'value' : "unassigned", label : "Unassign Shipment"}], trackinSubUserList)
    }
    let trackingTagList = this.props.tagList
    let detailTrackinngDrawer = this.props.detailDrawer ? this.props.detailDrawer : this.state.detailTrackinngDrawer
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    let nullValue = null
    let dashValue = '_'
    let undefinedText = undefined
    const flagstyleProperty = { width: '24px', height: '17px' }
    let dateFormat = (localStorage.getItem("date_format")) ? localStorage.getItem("date_format") : null
    let trackingColumn = localStorage.getItem('trackingColumn') ? JSON.parse(localStorage.getItem('trackingColumn')) : []
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (

      <div className={classes.root}>
        <Paper className={classes.paper}>
          {
            this.props.setTrackingListPaginationFlag === 0 ? <div className={classes.progressbar}>
              <LinearProgress color="primary" />
            </div>
              : ''
          }
          <TrackingStatusTabs drawerClose={this.drawerClose} addNewTrackingTabValue={this.props.addNewTrackingTabValue} trackingStatusArrayList={this.props.trackingStatusListValue} setPageId={this.resetPageForFilters} />
          <EnhancedTableToolbar
            handleExportShipments={this.props.handleExportShipments}
            setExportMessage={this.props.setExportMessage}
            handlePopUpDisplay={this.props.handlePopUpDisplay}
            removeColumn={this.removeColumn}
            drawerClose={this.drawerClose}
            StatusListValue={this.props.sendStatusListValue}
            carrierList={this.props.carrierList}
            handleCancel={this.props.handleCancel}
            changeTabToAll={this.props.changeTabToAll}
            subUserList={this.state.subUserList}
            reloadPageAfterAction={this.reloadPageAfterAction}
            reesetSelect={this.reesetSelect}
            setPageId={this.resetPageForFilters}
            classes={classes} numSelected={selected.length}
            statusValue={this.props.sendStatusListValue}
            pageId={this.handleChangePage}
            selectedIDs={this.state.selected}
          />
          <TableContainer className={classes.container}>
            <Table
              stickyHeader={true}
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={this.props.trackingList.length}
              />
              {
                this.props.setTrackingListFlag === 1 && this.props.trackingList !== undefined && this.props.trackingList !== null && this.props.trackingList.length > 0 ?
                  <TableBody ref={node => (this.listEl = node)}>
                    {stableSort(this.props.trackingList, getComparator(order, orderBy))
                      .slice(0, rowsPerPage)
                      .map((trackingList, index) => {
                        const isItemSelected = this.isSelected(trackingList._id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        let shipmentAssignTo = ""
                        let tagsAssignedToShipment = []
                        let tagButtonalue = 0
                        let statusArr =  STATUS_ARRAY.filter(x => x.value === trackingList.current_status)[0]
                        // let currentStatus = trackingList.current_status
                        // if(statusArr && statusArr[0] && statusArr[0].label){
                        //   currentStatus = statusArr[0].label
                        // }
                        if (trackinSubUserList && trackingList.assigned_id !== '' && trackingList.assigned_id !== undefined) {
                          let assignTrackingIndex = trackinSubUserList.findIndex(ele => ele.value === trackingList.assigned_id)
                          if (assignTrackingIndex > -1) {
                            // shipmentAssignTo = trackinSubUserList[assignTrackingIndex].label
                            shipmentAssignTo = trackinSubUserList[assignTrackingIndex]
                          }
                        }

                        if (trackingList.tags && trackingList.tags.length > 0) {
                          let tagArray = []
                          for (let i = 0; i < trackingList.tags.length; i++) {
                            tagArray.push(trackingList.tags[i])
                            if (i == 1)
                              break;
                          }
                          tagButtonalue = trackingList.tags.length - 2
                          let preparedTags = trackingTagList.filter(propsTag => tagArray.some(resTag => propsTag._id === resTag.tag_id));
                          if (preparedTags && preparedTags.length > 0) {
                            tagsAssignedToShipment = [...preparedTags]
                          }
                        }
                        return (
                          <TableRow
                            className={classes.bodyTableRow}
                            classes={{
                              selected: classes.selectedTableRow,
                            }}
                            hover
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={trackingList._id}
                            selected={isItemSelected}
                          >
                            <TableCell className={classes.bodyTableCell} >
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                                color="primary"
                                onClick={(event) => this.handleSelectItem(event, trackingList._id)}
                                role="checkbox"

                              />
                            </TableCell>

                            <TableCell style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == 'courier')) != undefined) ? 'table-cell' : 'none') }} onClick={(id) => { this.handleDetailClick(trackingList._id, trackingList) }} className={classes.bodyTableCell} align="left">
                              {<div className="labelInfo"><img style={{ width: '24.5px', height: '26.7px' }} src={showCourierLogo(trackingList.slug)} alt={trackingList.slug}></img>  </div>}
                            </TableCell>
                            <TableCell style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == 'service')) != undefined) ? 'table-cell' : 'none') }} onClick={(id) => { this.handleDetailClick(trackingList._id, trackingList) }} className={classes.bodyTableCell} align="left">{trackingList.trackings && trackingList.trackings.shipment_type ? trackingList.trackings.shipment_type : dashValue}</TableCell>
                            <TableCell style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == 'tracking_number')) != undefined) ? 'table-cell' : 'none') }} onClick={(id) => { this.handleDetailClick(trackingList._id, trackingList) }} className={classes.bodyTableCell} align="left">{trackingList.tracking_number}</TableCell>
                            <TableCell style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == 'origin')) != undefined) ? 'table-cell' : 'none') }} onClick={(id) => { this.handleDetailClick(trackingList._id, trackingList) }} className={classes.bodyTableCell} align="left">{trackingList.trackings && trackingList.trackings.address && trackingList.trackings.address.ship_from && trackingList.trackings.address.ship_from.country_iso != undefinedText && trackingList.trackings.address.ship_from.country_iso != nullValue ? <div><ReactCountryFlag styleProps={flagstyleProperty} code={trackingList.trackings.address.ship_from.country_iso} svg /> <span>{trackingList.trackings.address.ship_from.state ? trackingList.trackings.address.ship_from.state : "" }</span></div> : dashValue}</TableCell>
                            <TableCell style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == 'destination')) != undefined) ? 'table-cell' : 'none') }} onClick={(id) => { this.handleDetailClick(trackingList._id, trackingList) }} className={classes.bodyTableCell} align="left">{trackingList.trackings && trackingList.trackings.address && trackingList.trackings.address.ship_to && trackingList.trackings.address.ship_to.country_iso != undefinedText && trackingList.trackings.address.ship_to.country_iso != nullValue ? <div><ReactCountryFlag styleProps={flagstyleProperty} code={trackingList.trackings.address.ship_to.country_iso} svg /> <span>{trackingList.trackings.address.ship_to.state ? trackingList.trackings.address.ship_to.state : "" }</span> </div>: dashValue}</TableCell>
                            <TableCell style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == 'last_status')) != undefined) ? 'table-cell' : 'none') }} onClick={(id) => { this.handleDetailClick(trackingList._id, trackingList) }} className={classes.bodyTableCell} align="left">{trackingList.trackings && trackingList.trackings.checkpoints && trackingList.trackings.checkpoints.length ? trackingList.trackings.checkpoints.slice(-1)[0].message : dashValue}</TableCell>
                            <TableCell style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == 'time')) != undefined) ? 'table-cell' : 'none') }} onClick={(id) => { this.handleDetailClick(trackingList._id, trackingList) }} className={classes.bodyTableCell} align="left">{trackingList.trackings && trackingList.trackings.checkpoints && trackingList.trackings.checkpoints.length ? moment(trackingList.trackings.checkpoints.slice(-1)[0].checkpoint_time).format(dateFormat + " HH:mm:ss") : dashValue}</TableCell>
                            {
                              localStorage.getItem('user_type') !== "subuser" ?
                                <TableCell style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == 'assigned_to')) != undefined) ? 'table-cell' : 'none') }} className={classes.bodyTableCell} align="left">
                                  <Autocomplete
                                    openOnFocus={true}
                                    id="status"
                                    // defaultValue={this.state.selectedStatus === '' ? statusArr : null}
                                    classes={{
                                      paper: classes.autoCompleteListRoot,
                                      clearIndicatorDirty : classes.clearIndicatorDirty,
                                      popupIndicator : classes.popupIndicator
                                    }}
                                    value={shipmentAssignTo}
                                    onChange={(event, newValue) => {
                                      this.onSubUserChange(event, newValue, trackingList._id);
                                    }}
                                    options={assignUnassignArray}
                                    getOptionLabel={option => option.label ? option.label : ""}
                                    renderInput={params => <ListTextField  {...params}  placeholder = "Assign To"style={{ width: '350px !important' }} variant="outlined" />}
                                  />
                                </TableCell>
                                : ""
                            }
                            <TableCell style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == 'date')) != undefined) ? 'table-cell' : 'none') }} onClick={(id) => { this.handleDetailClick(trackingList._id, trackingList) }} className={classes.bodyTableCell} align="left">{trackingList.trackings && trackingList.trackings.expected_delivery && trackingList.trackings.expected_delivery != nullValue ? moment(trackingList.trackings.expected_delivery).format(dateFormat + " HH:mm:ss") : dashValue}</TableCell>
                            <TableCell style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == 'transit_day')) != undefined) ? 'table-cell' : 'none') }} onClick={(id) => { this.handleDetailClick(trackingList._id, trackingList) }} className={classes.bodyTableCell} align="left">{trackingList.trackings && trackingList.trackings.delivery_time ? trackingList.trackings.delivery_time : dashValue}</TableCell>
                            <TableCell style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == 'status')) != undefined) ? 'table-cell' : 'none') }} className={classes.bodyTableCell} align="left">
                              <Autocomplete
                                openOnFocus={true}
                                id="status"
                                // defaultValue={this.state.selectedStatus === '' ? statusArr : null}
                                classes={{
                                  paper: classes.autoCompleteListRoot,
                                  clearIndicatorDirty : classes.clearIndicatorDirty,
                                  popupIndicator : classes.popupIndicator
                                }}
                                value={statusArr}
                                onChange={(event, newValue) => {
                                  this.onStatusChange(event, newValue, trackingList._id);
                                }}
                                options={statusArray}
                                getOptionLabel={option => option.label ? option.label : ""}
                                renderInput={params => <ListTextField  {...params}  style={{ width: '350px !important' }} variant="outlined" />}
                              />
                            </TableCell>

                            <TableCell style={{ display: ((trackingColumn && trackingColumn.find((column) => (column.key == 'tag')) != undefined) ? 'table-cell' : 'none') }} onClick={(id) => { this.handleDetailClick(trackingList._id, trackingList) }} className={classes.bodyTableCell} align="left">
                              {
                                <div>
                                  {tagsAssignedToShipment.map(item => {
                                    return (
                                      <div key={item._id} className="trackingListTag" style={{ backgroundColor: item.tag_color }}>
                                        <span className="text">{item.tag_name}</span>
                                      </div>
                                    )
                                  })}
                                  {tagButtonalue > 0 ? <IconButton onClick={(event) => { this.handleDetailClick(trackingList._id, trackingList) }} size="small" className={classes.tagButton}> +{tagButtonalue}</IconButton> : ""}

                                </div>
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  : this.props.setTrackingListPaginationFlag === 1 && !this.props.trackingList.length > 0 ?
                    <React.Fragment>
                      <div className="noTrackingDiv">
                        <div className="noTrackingInnerDiv">
                          <img src={noTrackingIcon} alt="No Tracking" />
                          <div className="noTrackingMsgTitle">No Tracking List</div>
                        </div>
                      </div>
                    </React.Fragment>
                    :
                    null

              }
            </Table>
          </TableContainer>
          <TablePagination className={classes.pagination}
            classes={{
              select: classes.paginationTextAlign,
            }}
            rowsPerPageOptions={[20, 30, 40, 50]}
            component="div"
            count={this.props.noOfTotalTrackingRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        {/* {this.state.detailTrackinngDrawer ?  */}
        <Drawer
          openDrawer={detailTrackinngDrawer}
          closeDrawer={this.drawerClose}
          classes={classes}
          setPageId={this.resetPageForFilters}
          trackingDetailFromTrackingList={this.state.trackingDetail}
          getListAfterDelete={this.pagination}
          loader={true}
          customDomainURL={this.state.customDomainURL}
          subDomainName={this.state.subDomainName}
        />
        {this.state.openSnackBar ?
          <FlashMessage open={this.state.openSnackBar} closeFlash={this.closeFlashMessage} messageDetails={{ type: this.trackingMessage.type, message: this.trackingMessage.message }} />
          : ''
        }
        {/* : ""} */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  trackingList: state.tracking.list.trackingList,
  subUserList: state.tracking.list.addedSubUserList,
  tagList: state.tracking.list.tags,
  detailDrawer: state.tracking.list.detailDrawer
})
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, { getTrackingDetails, clearTrackingDetails, getUserDetails, setDataForTrackingDetail, deleteMultipleShipment, updateShipmentStatus, assignUnassignSubUserToShipment, clearTrackingList, setUpdatedTrackingList })(EnhancedTable))
)