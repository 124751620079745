import React from 'react';
import '../../src/static/css/animate.css';
import { connect } from 'react-redux';
import {notify,updateLastTimeStamp} from './actions/actions';

const mapStateToProps = state => ({
  NOTIFY: state.settings.list.NOTIFY,
  notify_snapshot: state.settings.list.notify_snapshot,
})
class NotificationBlock extends React.Component{                                                                                                                                                                                                                                                                                                                                      

  constructor(props) {
    super(props);
    this.state = {
      notifyBlock:'[]',
      notifyBlockLoader:true,
      displayClass:'animated fadeIn',
      displayBlockID:'notification-block'
    };
  }

  componentWillReceiveProps(newProps) {
    
    if(newProps.notify_snapshot !== this.props.notify_snapshot){
      this.setState({displayClass:'animated fadeIn',displayBlockID:'notification-block'})
        var detail = newProps.notify_snapshot;
        var snapshot_array = [];
        snapshot_array.push(detail)
        this.setState({notifyBlock:snapshot_array,notifyBlockLoader:false});
        setTimeout(()=>{
          this.setState({displayClass:'animated fadeOut'})
          this.setState({displayBlockID:'notification-block-fadeout'})
        },6000);
    }
  }

  componentDidMount(){
    this.props.notify();
  }

  render() {

    return <div>
            {this.state.notifyBlockLoader ? '' :

              this.state.notifyBlock.map((snapshot,key) => {
                var list = snapshot;
                var notitext="";
                var notilink ="";
                this.props.updateLastTimeStamp(list.timestamp)
                let notiImage=list.icon;
                notitext=<div dangerouslySetInnerHTML={{__html:`${list.message}`}} />;
                  notilink = "#";
                return (
                       <div id={this.state.displayBlockID} className={this.state.displayClass}>{this.props.unread_notify()}
                            <div href={notilink} className="notify_block_container">
                              <span className="notify_block_img">
                                <img alt="" src={notiImage} />
                              </span>
                              <span className="notify_block_txt">
                                  {notitext}
                              </span>
                            </div>
                          </div>

                       )
                })
           }
          </div>

  }
}
export default connect(mapStateToProps, {notify,updateLastTimeStamp})(NotificationBlock);