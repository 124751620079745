import React from 'react';
import MagentoDetailsForm from '../stores/magento/MagentoDetailsForm';
import AuspostDetailForm from '../stores/australiaPost/AusPostDetailsForm';
import BlueDartDetailsForm from '../stores/bluedart/BlueDartDetailsForm';
import WooCommerceDetailsForm from '../stores/woocommerce/WooCommerceDetailsForm';
import CdlDetailsForm from '../stores/cdl/CdlDetailsForm'
import { getStoreDetail, editStore, addStore } from '../../../actions/actionMethods';
import {urlRegEx} from '../../../../static/constants'
import {STORE_SAVED_SUCCESSFULLY} from '../../../common/constants'
import NinjaVanDetailsForm from '../stores/ninjaVan/NinjaVanDetailsForm'
import GwWorldDetailForm from '../stores/gwWorld/GwWorldDetailsForm';
import NinetyNineMinutosDetailsForm from '../stores/99minutos/99minutosDetailsForm';
export default class IntegrateStore extends React.Component{
    constructor(props) {
        super(props)
        this.isSuccess = false
        this.state = {
            carrierName: '',
            storeURL: '',
            accessToken: '',
            apiKey: '',
            secretKey: '',
            storeID: '',
            carrierNameError: false,
            storeURLError: false,
            apiKeyError: false,
            secretKeyError: false,
            accessTokenError: false,
            accountNumberError: false,
            accountNumber: '',
            licenseKey: '',
            loginId: '',
            loginIdError: false,
            licenseKeyError: false,
            storeIDError: false,
            isValidURL: true,
            active: false,
            userName: '',
            userNameError: false,
            password: '',
            passwordError: false,
            webhook_url : ''
        }
    }

    async componentDidMount() {
        this.getData()
    }

    getData = async () => {
        if (this.props.storeId !== undefined) {
            let id = this.props.storeId
            getStoreDetail(id).then(res => {
                let data = res.data[0]
                this.props.isLoading(false)
                this.props.activeStore(data.active)
                if(this.props.type === "Magento" || this.props.type === "WooCommerce"){
                    this.setState({
                        carrierName: data.store_name,
                        storeURL: data.url,
                        apiKey: data.key1,
                        secretKey: data.key2, active: data.active, storeLoader: false,
                    })
                }
                if(this.props.type === "AusPost"){
                    this.setState({
                        carrierName: data.store_name,
                        accountNumber: data.key3,
                        apiKey: data.key2,
                        secretKey: data.key1,
                        active: data.active,
                        storeLoader: false,
                    })
                }
                if(this.props.type === "BlueDart"){
                    this.setState({ 
                        carrierName : data.store_name, 
                        loginId: data.key1, 
                        licenseKey:data.key2, 
                        active: data.active,
                        showSaveLoader: false
                     })
                }
                if (this.props.type === "Cdl") {
                    this.setState({
                        carrierName: data.store_name,
                        userName: data.key2,
                        password: data.key3,
                        active: data.active,
                        storeLoader: false
                    })
                }
                if (this.props.type === "NinjaVan") {
                    this.setState({
                        carrierName: data.store_name,
                        apiKey: data.key1,
                        secretKey: data.key2,
                        active: data.active,
                        storeLoader: false,
                    })
                    let wehookUrl = this.ganeratWebhookUrl(data.user_id)
                    this.setState({
                        webhook_url : wehookUrl
                    })
                }
                if(this.props.type === "GwWorld"){
                    this.setState({
                        carrierName: data.store_name,
                        apiKey: data.key2,
                        secretKey: data.key3,
                        active: data.active,
                        storeLoader: false,
                    })
                }
                if(this.props.type === "99minutos"){
                    this.setState({
                        carrierName: data.store_name,
                        apiKey: data.key1,
                        active: data.active,
                        storeLoader: false,
                    })
                }
            })
        }
    }

    ganeratWebhookUrl = (req) => {
        let encodeUri = encodeURIComponent(req);
        let base64Encode = Buffer.from(encodeUri).toString('base64')
        let encodeData = base64Encode.replace('+', '/').replace('-', '_')
        let url = process.env.REACT_APP_API_URL + "/ninjavan_webhook/consume/" + encodeData
        return url
     }

    handleCarrierName = (name) => {
        this.setState({
            carrierName: name,
            carrierNameError: false
        })
    }
    handleAccountNumber = (number) => {
        this.setState({
            accountNumber: number,
            accountNumberError: false
        })
    }

    handleApiKey = (apiKey) => {
        this.setState({
            apiKey: apiKey,
            apiKeyError: false
        })
    }
    handlestoreURL = (URL) => {
        this.setState({
            storeURL: URL,
            storeURLError: false
        })
    }
    handleSecretKey = (secretKey) => {
        this.setState({
            secretKey: secretKey,
            secretKeyError: false
        })
    }
    handleLoginId = (id) => {
        this.setState({
            loginId: id,
            loginIdError: false
        })
    }

    handleLicenseKey = (key) => {
        this.setState({
            licenseKey: key,
            licenseKeyError: false
        })
    }

    checkStoreURL = () => {
        if (this.state.storeURL !== '') {
            if (urlRegEx.test(this.state.storeURL) === false) {
                this.setState({
                    isValidURL: false
                })
            }
            else {
                this.setState({
                    isValidURL: true
                })
            }
        }
    }

    handleUserName = (username) => {
        this.setState({
            userName: username,
            userNameError: false
        })
    }

    handlePassword = (password) => {
        this.setState({
            password: password,
            passwordError: false
        })
    }

    addEditStore = (requestData) => {
        let addEditStoreSuccessMsg = {}
        addEditStoreSuccessMsg.showSaveLoader = true
        this.setState({ showSaveLoader: true })
        if (this.props.storeId !== undefined) {
            let id = this.props.storeId
            editStore(id, requestData).then(res => {
                this.setState({ showSaveLoader: false })
                if (res.data !== undefined && res.data !== null) {
                    if (res.data.meta && res.data.meta.code === 400) {
                        this.isSuccess = false
                        addEditStoreSuccessMsg.isSuccess = false
                        addEditStoreSuccessMsg.error = true
                        addEditStoreSuccessMsg.success = false
                        addEditStoreSuccessMsg.successErrMsg = res.data.meta.message[0]
                        addEditStoreSuccessMsg.showSaveLoader = false
                        this.props.updateSuccessErrMsg(addEditStoreSuccessMsg)
                        this.setState({ error: true, successErrMsg: res.data.meta.message[0], showSaveLoader: false })
                    }
                    else {
                        this.isSuccess = true
                        addEditStoreSuccessMsg.isSuccess = true
                        addEditStoreSuccessMsg.error = false
                        addEditStoreSuccessMsg.success = true
                        addEditStoreSuccessMsg.successErrMsg = STORE_SAVED_SUCCESSFULLY
                        addEditStoreSuccessMsg.showSaveLoader = false
                        this.props.updateSuccessErrMsg(addEditStoreSuccessMsg)
                        this.setState({ error: false, success: true, successErrMsg: STORE_SAVED_SUCCESSFULLY })
                    }
                }
            })
        } else {
            addStore(requestData).then(res => {
                this.setState({ showSaveLoader: false })
                if (res.data !== undefined && res.data !== null) {
                    if (res.data.meta && res.data.meta.code === 400) {
                        addEditStoreSuccessMsg.isSuccess = false
                        addEditStoreSuccessMsg.success = false
                        addEditStoreSuccessMsg.error = true
                        addEditStoreSuccessMsg.successErrMsg = res.data.meta.message[0]
                        addEditStoreSuccessMsg.showSaveLoader = false
                        this.props.updateSuccessErrMsg(addEditStoreSuccessMsg)
                        this.setState({ error: true, successErrMsg: res.data.meta.message[0] })
                    }
                    else {
                        this.props.backToIntegrationList()
                    }
                }
            })
        }
    }
    
    handleSubmit = () => {
        let flag = true
        if(this.props.type === "Magento" || this.props.type === "WooCommerce"){
            if (this.state.storeURL === '') {
                flag = false
                this.setState({ storeURLError: true })
            }
        }

        if(this.props.type === "AusPost"){
            if (this.state.accountNumber === '') {
                flag = false
                this.setState({
                    accountNumberError: true
                })
            }
        }

        if(this.props.type === "BlueDart"){
            if (this.state.loginId === '') {
                flag = false
                this.setState({ loginIdError: true })
            }
            if (this.state.licenseKey === '') {
                flag = false
                this.setState({ licenseKeyError: true })
            }
        }
        if(this.props.type === "AusPost" || this.props.type === "WooCommerce" || this.props.type === "GwWorld"){
            if (this.state.secretKey === '') {
                flag = false
                this.setState({ secretKeyError: true })
            }
            if (this.state.apiKey === '') {
                flag = false
                this.setState({
                    apiKeyError: true
                })
            }
        }
        if (this.props.type === "Cdl") {
            if (this.state.userName === '') {
                flag = false
                this.setState({ userNameError: true })
            }
            if (this.state.password === '') {
                flag = false
                this.setState({
                    passwordError: true
                })
            }
        }

        if (this.props.type === "NinjaVan") {
            if (this.state.secretKey === '') {
                flag = false
                this.setState({ secretKeyError: true })
            }
            if (this.state.apiKey === '') {
                flag = false
                this.setState({
                    apiKeyError: true
                })
            }
        }
        if(this.props.type === "99minutos"){
            if (this.state.apiKey === '') {
                flag = false
                this.setState({
                    apiKeyError: true
                })
            }
        }

        if (this.state.carrierName === '') {
            flag = false
            this.setState({ carrierNameError: true })
        }

        let requestData = {}
        requestData.store_name = this.state.carrierName
        requestData.channel_id = this.props.channelId
        requestData.active = 1
        
        if(this.props.type === "WooCommerce" || this.props.type === "Magento"){
            if(flag && this.state.isValidURL){
                requestData.url = this.state.storeURL
                requestData.key1 = this.state.apiKey
                requestData.key2 = this.state.secretKey
                this.addEditStore(requestData)
            }
        }
        if(this.props.type === "AusPost"){
            if(flag){
                requestData.key1 = this.state.secretKey
                requestData.key2 = this.state.apiKey
                requestData.key3 = this.state.accountNumber
                this.addEditStore(requestData)
            }
        }
        if(this.props.type === "BlueDart"){
            if(flag){
                requestData.key1 = this.state.loginId
                requestData.key2 = this.state.licenseKey
                this.addEditStore(requestData)
           }
        }
        if (this.props.type === "Cdl") {
            if (flag) {
                requestData.key2 = this.state.userName
                requestData.key3 = this.state.password
                this.addEditStore(requestData)
            }
        }
        if (this.props.type === "NinjaVan") {
            if (flag) {
                requestData.key1 = this.state.apiKey
                requestData.key2 = this.state.secretKey
                this.addEditStore(requestData)
            }
        }
        if(this.props.type === "GwWorld"){
            if(flag){
                requestData.key2 = this.state.apiKey
                requestData.key3 = this.state.secretKey
                this.addEditStore(requestData)
            }
        }
        if(this.props.type === "99minutos"){
            if(flag){
                requestData.key1 = this.state.apiKey
                this.addEditStore(requestData)
            }
        }
    }

    render(){
        const {classes} = this.props
        let showForm = []
        if(this.props.type === "Magento"){
            showForm.push(
                <MagentoDetailsForm 
                    classes={classes} 
                    carrierName = {this.state.carrierName}
                    carrierNameError = {this.state.carrierNameError}
                    handleCarrierName = {this.handleCarrierName}
                    storeURLError = {this.state.storeURLError}
                    checkStoreURL = {this.checkStoreURL}
                    handlestoreURL = {this.handlestoreURL}
                    apiKeyError = {this.state.apiKeyError}
                    secretKeyError = {this.state.secretKeyError}
                    isValidURL = {this.state.isValidURL}
                    apiKey = {this.state.apiKey}
                    handleSubmit = {this.handleSubmit}
                    storeURL = {this.state.storeURL}
                    handleApiKey = {this.handleApiKey}
                    secretKey = {this.state.secretKey}
                    handleSecretKey = {this.handleSecretKey}
                    showSaveLoader = {this.state.showSaveLoader}
                />
            )
        }
        if(this.props.type === "WooCommerce"){
            showForm.push(
                <WooCommerceDetailsForm 
                        classes={classes} 
                        carrierName = {this.state.carrierName}
                        carrierNameError = {this.state.carrierNameError}
                        handleCarrierName = {this.handleCarrierName}
                        storeURL = {this.state.storeURL}
                        storeURLError = {this.state.storeURLError}
                        checkStoreURL = {this.checkStoreURL}
                        handlestoreURL = {this.handlestoreURL}
                        isValidURL = {this.state.isValidURL}
                        apiKey = {this.state.apiKey}
                        apiKeyError = {this.state.apiKeyError}
                        handleApiKey = {this.handleApiKey}
                        secretKey = {this.state.secretKey}
                        secretKeyError = {this.state.secretKeyError}
                        handleSecretKey = {this.handleSecretKey}
                        handleSubmit = {this.handleSubmit}
                        showSaveLoader = {this.state.showSaveLoader}
                />
            )
        }
        if(this.props.type === "AusPost"){
            showForm.push(
                <AuspostDetailForm 
                    classes={classes} 
                    carrierName = {this.state.carrierName}
                    secretKey = {this.state.secretKey}
                    carrierNameError = {this.state.carrierNameError}
                    handleApiKey = {this.handleApiKey}
                    apiKey = {this.state.apiKey}
                    showSaveLoader = {this.state.showSaveLoader}
                    accountNumber = {this.state.accountNumber}
                    handleCarrierName = {this.handleCarrierName}
                    handleSecretKey = {this.handleSecretKey}
                    handleSubmit = {this.handleSubmit}
                    apiKeyError = {this.state.apiKeyError}
                    handleAccountNumber = {this.handleAccountNumber}
                    accountNumberError = {this.state.accountNumberError}
                    secretKeyError = {this.state.secretKeyError}
                />
            )
        }

        if(this.props.type === "BlueDart"){
            showForm.push(
                <BlueDartDetailsForm 
                    classes={classes} 
                    loginId = {this.state.loginId}
                    carrierName = {this.state.carrierName}
                    handleCarrierName = {this.handleCarrierName}
                    handleSubmit = {this.handleSubmit}
                    loginIdError = {this.state.loginIdError}
                    handleLoginId = {this.handleLoginId}
                    carrierNameError = {this.state.carrierNameError}
                    licenseKey = {this.state.licenseKey}
                    licenseKeyError = {this.state.licenseKeyError}
                    handleLicenseKey = {this.handleLicenseKey}
                    showSaveLoader = {this.state.showSaveLoader}
                />
            )
        }
        if (this.props.type === "Cdl") {
            showForm.push(
                <CdlDetailsForm
                    classes={classes}
                    userName={this.state.userName}
                    handleSubmit={this.handleSubmit}
                    userNameError={this.state.userNameError}
                    handleUserName={this.handleUserName}
                    password={this.state.password}
                    passwordError={this.state.passwordError}
                    handlePassword={this.handlePassword}
                    carrierName={this.state.carrierName}
                    carrierNameError={this.state.carrierNameError}
                    handleCarrierName={this.handleCarrierName}
                    showSaveLoader={this.state.showSaveLoader}
                />
            )
        }

        if (this.props.type === "NinjaVan") {
            showForm.push(
                <NinjaVanDetailsForm
                classes={classes} 
                carrierName = {this.state.carrierName}
                secretKey = {this.state.secretKey}
                carrierNameError = {this.state.carrierNameError}
                handleApiKey = {this.handleApiKey}
                apiKey = {this.state.apiKey}
                showSaveLoader = {this.state.showSaveLoader}
                handleCarrierName = {this.handleCarrierName}
                handleSecretKey = {this.handleSecretKey}
                handleSubmit = {this.handleSubmit}
                apiKeyError = {this.state.apiKeyError}
                secretKeyError = {this.state.secretKeyError}
                storeId={this.props.storeId}
                webhook_url = {this.state.webhook_url}
                />
            )
        }

        if(this.props.type === "GwWorld"){
            showForm.push(
                <GwWorldDetailForm 
                    classes={classes} 
                    carrierName = {this.state.carrierName}
                    secretKey = {this.state.secretKey}
                    carrierNameError = {this.state.carrierNameError}
                    handleApiKey = {this.handleApiKey}
                    apiKey = {this.state.apiKey}
                    showSaveLoader = {this.state.showSaveLoader}
                    handleCarrierName = {this.handleCarrierName}
                    handleSecretKey = {this.handleSecretKey}
                    handleSubmit = {this.handleSubmit}
                    apiKeyError = {this.state.apiKeyError}
                    secretKeyError = {this.state.secretKeyError}
                />
            )
        }
        if(this.props.type === "99minutos"){
            showForm.push(
                <NinetyNineMinutosDetailsForm 
                    classes={classes} 
                    carrierName = {this.state.carrierName}
                    carrierNameError = {this.state.carrierNameError}
                    handleApiKey = {this.handleApiKey}
                    apiKey = {this.state.apiKey}
                    showSaveLoader = {this.state.showSaveLoader}
                    handleCarrierName = {this.handleCarrierName}
                    handleSubmit = {this.handleSubmit}
                    apiKeyError = {this.state.apiKeyError}
                />
            )
        }

        return(
            <React.Fragment>
                {showForm}
            </React.Fragment>
        )
    }
}