import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import Autosuggest from "react-autosuggest"
import CircularProgress from '@material-ui/core/CircularProgress';
import { searchTracking, getTrackingSearchList, clearTrackingList,getTrackingListWithNoSearch,clearTrackingDetails,getTrackingDetails,setSubUserList,setCourierArrayValueList, setOriginCountryList, setDestinationCountryList, getCarrierList, setCourierList, setDataForSearchDetail } from '../actions/actionMethods'
import {DEFAULT_TRACKING_LIMIT} from '../common/constants'
const REACT_S3_PATH = process.env.REACT_APP_S3_URL
const cancel = 'clearIcon.svg'
var isEmpty = require('lodash.isempty')
const renderSuggestion = suggestion => (
    <div className="automationSearchResutlRaw ignore-react-onclickoutside">
        <div>
            <div className="trackingNumber">
                <span>{(suggestion.tracking_number) ? suggestion.tracking_number : '—'} </span>
            </div>
            <div className="courierName">
                <span>{suggestion.slug}</span>
            </div>
        </div>
    </div>
)
class SearchTracking extends React.Component {
    constructor(props) {
        super(props)
        this.isItemClicked = false
        let searchTerm = this.props.setSearchQueryString
        this.lastRequestId = null
        this.isInitSuggestions = searchTerm && searchTerm.length > 0
        if ((!searchTerm)) {
            searchTerm = this.props.searchQueryString
        }
        if (!searchTerm) {
            searchTerm = ""
            this.isInitSuggestions = true
        }
        this.state = {
            value: searchTerm,
            suggestions: [],
            isLoading: false,
            isNoTracking: false,
            isShowNoData: true,
            isEnterPressed: false,
            isCloseOnReset: false
        }
    }
    storeInputReference = autosuggest => {
        if (autosuggest !== null) {
            this.input = autosuggest.input
        }
    }
    onSuggestionsFetchRequested = ({ value }) => {
        if (this.isItemClicked) {
            this.isItemClicked = false
            return
        }
        if (!this.isInitSuggestions) {
            this.isInitSuggestions = true
            return
        }
        if (value.length > 0) {
            this.setState({
                isLoading: true
            })
        }
        this.loadSuggestions(value)
    }
    loadSuggestions(newValue) {
        const inputLength = newValue.length
        if (this.lastRequestId !== null) {
            clearTimeout(this.lastRequestId)
        }
        this.lastRequestId = setTimeout(() => {
            if (inputLength > 2) {
                var bodyValue = { query: newValue }
                this.setState({
                    isLoading: true
                })
                searchTracking(bodyValue).then(res => {
                    this.setState({
                        isLoading: false
                    })
                    if (res) {
                        if (this.state.isEnterPressed) {
                            return
                        }
                        if (res.data.length > 0) {
                            this.setState({
                                suggestions: res.data
                            })
                        }
                        else {
                            this.setState({
                                isNoTracking: true,
                                suggestions: [],
                                isShowNoData: true
                            })
                        }
                    }
                })
            } else {
                this.setState({
                    isLoading: false,
                    isShowNoData: true,
                    suggestions: []
                })
                return []
            }
        }, 300)
    }
    renderSuggestionsContainer({ containerProps, children, query }) {
        if (this.state.isLoading) {
            //Here we will render the content loader
            return (
                <div className="react-autosuggest__suggestions-container--no noAutomationData">
                    <CircularProgress size={22} />

                </div>
            )
        }
        if (this.state.suggestions.length === 0) {
            let placeholder = ""
            if (this.state.isShowNoData && query.length > 2) {
                placeholder = "No Tracking found."
            }
            if (this.state.isShowNoData && query.length <= 2 && query.length > 0) {
                placeholder = "Please enter minimum 3 characters."
            }
            if (placeholder.length > 0) {
                return (
                    <div className="react-autosuggest__suggestions-container--no noAutomationData">
                        {placeholder}
                        <div className="clearFix" />
                    </div>
                )
            }
        } else {
            return <div {...containerProps}>{children}</div>
        }
    }
    handleSelection = (event, suggestion) => {
        this.isItemClicked = true
        if (suggestion.suggestion._id) {
            this.props.clearTrackingDetails()
            this.props.setDataForSearchDetail(suggestion.suggestion)
            this.props.history.push(`/tracking/${suggestion.suggestion._id}`)
            // this.props.popupDisplay('detail')
        }
    }
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
            isShowNoData: false
        })
    }
    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue.trim(),
            isEnterPressed: false
        })
    }
    performSearchAndFilter = (query) => {



        let urlParams = {}
        let filterFlag = !isEmpty(this.props.trackingGlobalFilters)
        if (filterFlag) {
            let storeObj = this.props.trackingGlobalFilters

            if (storeObj.trackingStatusArr) {
                let trackingStatus = storeObj.trackingStatusArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['trackingStatusArr'] = trackingStatus.join()
            }

            if (storeObj.allTrackingStatusArr) {
                let trackingStatus = storeObj.allTrackingStatusArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['allTrackingStatusArr'] = trackingStatus.join()
            }


            if (storeObj.courierListArr) {
                let couriersList = storeObj.courierListArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['courierListArr'] = couriersList.join()
            }
            if (storeObj.originListArr) {
                let originList = storeObj.originListArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['originListArr'] = originList.join()
            }
            if (storeObj.destinationListArr) {
                let destinationList = storeObj.destinationListArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['destinationListArr'] = destinationList.join()
            }
            if (this.props.trackingGlobalFilters.subUserListArr && this.props.trackingGlobalFilters.subUserListArr.length > 0) {
                let subUser = this.props.trackingGlobalFilters.subUserListArr
                let subUserList = subUser.map((element, index) => {
                    return (element.label)
                })
                let subUserListId = subUser.map((element, index) => {
                    return (element.value)
                })
                urlParams['subUserListArr'] = subUserList.join()
                urlParams['subUserListId'] = subUserListId.join()
            }
            if (this.props.trackingGlobalFilters.tagListArr && this.props.trackingGlobalFilters.tagListArr.length > 0) {
                let tags = this.props.trackingGlobalFilters.tagListArr
                let tagList = tags.map((element, index) => {
                    return (element.label)
                })
                let tagListId = tags.map((element, index) => {
                    return (element.value)
                })

                urlParams['tagListArr'] = tagList.join()
                urlParams['tagListId'] = tagListId.join()
            }
            if (this.props.trackingGlobalFilters.transitDays !== '') {
                urlParams['transitDays'] = this.props.trackingGlobalFilters.transitDays
            }
            if (this.props.trackingGlobalFilters.date !== '') {
                urlParams['date'] = this.props.trackingGlobalFilters.date
            }
            if (this.props.trackingGlobalFilters.fromDate !== '' && this.props.trackingGlobalFilters.toDate !== '') {
                urlParams['fromDate'] = this.props.trackingGlobalFilters.fromDate
                urlParams['toDate'] = this.props.trackingGlobalFilters.toDate
            }
            if (query) {
                urlParams['query'] = query
            }
            let globalFiltersValue = {}
            let requestParams = {}
            globalFiltersValue = this.props.trackingGlobalFilters
            requestParams.pageId = 1
            requestParams.limit = localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : DEFAULT_TRACKING_LIMIT
            let trackingStatusArrVal = []
            let couriersListArrVal = []
            let originListArrVal = []
            let destinationListArrVal = []
            let subUserListArrVal = []
            let tagListArrval = []
            if (globalFiltersValue.trackingStatusArr && globalFiltersValue.trackingStatusArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.trackingStatusArr.length; i++) {
                    trackingStatusArrVal.push(globalFiltersValue.trackingStatusArr[i].value)
                }

                if(trackingStatusArrVal[0] !== 'All'){
                    requestParams.status = trackingStatusArrVal
                }
            }

            if (globalFiltersValue.allTrackingStatusArr && globalFiltersValue.allTrackingStatusArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.allTrackingStatusArr.length; i++) {
                    trackingStatusArrVal.push(globalFiltersValue.allTrackingStatusArr[i].value)
                }

                requestParams.status = trackingStatusArrVal
            }



            if (globalFiltersValue.courierListArr && globalFiltersValue.courierListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.courierListArr.length; i++) {
                    couriersListArrVal.push(globalFiltersValue.courierListArr[i].value)
                }
                requestParams.courier = couriersListArrVal
            }
            if (globalFiltersValue.originListArr && globalFiltersValue.originListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.originListArr.length; i++) {
                    originListArrVal.push({
                        label: globalFiltersValue.originListArr[i].label,
                        value: globalFiltersValue.originListArr[i].value
                    })
                }
                requestParams.origin = originListArrVal
            }
            if (globalFiltersValue.destinationListArr && globalFiltersValue.destinationListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.destinationListArr.length; i++) {
                    destinationListArrVal.push({
                        label: globalFiltersValue.destinationListArr[i].label,
                        value: globalFiltersValue.destinationListArr[i].value
                    })
                }
                requestParams.destination = destinationListArrVal
            }
            if (globalFiltersValue.subUserListArr && globalFiltersValue.subUserListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.subUserListArr.length; i++) {
                    subUserListArrVal.push({
                        label: globalFiltersValue.subUserListArr[i].label,
                        value: globalFiltersValue.subUserListArr[i].value
                    })
                }
                requestParams.assigned_id = subUserListArrVal
            }
            if (globalFiltersValue.tagListArr && globalFiltersValue.tagListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.tagListArr.length; i++) {
                    tagListArrval.push({
                        label: globalFiltersValue.tagListArr[i].label,
                        value: globalFiltersValue.tagListArr[i].value
                    })
                }
                requestParams.tags = tagListArrval
            }
            if (globalFiltersValue.transitDays !== '') {
                requestParams.transitDays = globalFiltersValue.transitDays
            }
            if (globalFiltersValue.date !== '') {
                requestParams.date = globalFiltersValue.date
            }
            if (globalFiltersValue.fromDate !== '' && globalFiltersValue.toDate !== '') {
                requestParams.fromDate = globalFiltersValue.fromDate
                requestParams.toDate = globalFiltersValue.toDate
            }
            if(query !== null && query !== ''){
                requestParams.query = query.trim()
            }
        
            this.props.getTrackingSearchList(requestParams)
        }
    }
    onKeyPress = event => {

        if ((event.keyCode === 27 || event.keyCode === 8 || event.keyCode === 46) && this.props.searchQueryString !== null) {
            if (this.state.value.length === 0) {

                this.props.resetTrackingSearch()
                this.setState({
                    value: "",
                    suggestions: []
                })
                if (Object.keys(this.props.trackingGlobalFilters).length > 0) {
                    let query = null
                    this.performSearchAndFilter(query)
                }
                else {
                    let requestParams = {}
                    this.props.getTrackingSearchList(requestParams).then(res => {
                    })
                }
            }
        } else if (event.keyCode === 13 && this.state.value.length > 2) {

            this.props.setPaginationLoader()
            this.props.startPaginationLoader()
            this.props.clearTrackingList()
            getCarrierList().then(response => {
                if (response && response.data) {
                    this.props.setCourierList(response.data)
                }
            })

            this.props.setCourierArrayValueList(this.props.trackingCourierArrayList)
            this.props.setOriginCountryList(this.props.originCountryArrayList)
            this.props.setDestinationCountryList(this.props.destinationCountryArrayList)
            this.props.setSubUserList(this.props.subUserArrayList)

            if (Object.keys(this.props.trackingGlobalFilters).length > 0) {

                this.performSearchAndFilter(this.state.value)
            }
            else {

                let bodyValue = { query: this.state.value }
                this.props.getTrackingSearchList(bodyValue).then(res => {
                })
            }
            this.setState({
                suggestions: [],
                isShowNoData: false,
                isEnterPressed: true,
                isCloseOnReset: true
            })
            this.props.onSearchQueryString(this.state.value)
        }
    }
    clearSearch = () => {
        this.props.startPaginationLoader()
        if(this.state.isEnterPressed){
            this.props.clearTrackingList()
            getCarrierList().then(response => {
                if (response && response.data) {
                    this.props.setCourierList(response.data)
                }
            })
            this.props.setCourierArrayValueList(this.props.trackingCourierArrayList)
            this.props.setOriginCountryList(this.props.originCountryArrayList)
            this.props.setDestinationCountryList(this.props.destinationCountryArrayList)
            this.props.setSubUserList(this.props.subUserArrayList)
        }
        this.setState({
            value: "",
            suggestions: []
        })
        this.isInitSuggestions = false
        this.input.focus()
        if (Object.keys(this.props.trackingGlobalFilters).length > 0) {

            let query = null
            this.performSearchAndFilter(query)
        }
        else {

            this.props.getTrackingListWithNoSearch().then(res => {
            })
        }
        if (this.state.isCloseOnReset) {
            this.props.resetTrackingSearch()
        }
    }
    focusInputOnSuggestionClick = suggestion => { }
    getSearchTerm = () => {
        return this.state.value
    }
    onSuggestionHighlighted = suggestion => { }
    render() {
        const { value } = this.state
        const inputProps = {
            placeholder: "Search",
            value,
            className: "trackingSearchInput",
            onChange: this.onChange,
            autoFocus: true,
            onKeyUp: this.onKeyPress
        }
        return (
            <div className="searchTrackingMainDiv">
                <div id="SearchWrapper">
                    <Autosuggest
                        ref={this.storeInputReference}
                        suggestions={
                            this.state.suggestions.length > 0 ? this.state.suggestions : []
                        }
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={this.getSearchTerm}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        // alwaysRenderSuggestions={true}
                        onSuggestionHighlighted={suggestion =>
                            this.onSuggestionHighlighted(suggestion)
                        }
                        renderSuggestionsContainer={(containerProps, children, query) =>
                            this.renderSuggestionsContainer(containerProps, children, query)
                        }
                        onSuggestionSelected={(
                            event,
                            suggestion,
                            suggestionValue,
                            suggestionIndex,
                            sectionIndex,
                            method
                        ) => this.handleSelection(event, suggestion)}
                    />
                    {
                        <div className="imageSearchClearContainer" style={{ display: this.state.value ? "block" : "none" }}>
                            <img
                                className="imageSearchClear"
                                src={REACT_S3_PATH + cancel}
                                alt="Clear"
                                onClick={() => this.clearSearch()}
                            />
                            {/* <span style={{height : '32px', borderLeft : '10px solid #ffffff'}}></span> */}
                        </div>
                    }

                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    trackingGlobalFilters: state.tracking.list.trackingFilters,
    trackingCourierArrayList: state.tracking.list.trackingCourierArrayList,
    originCountryArrayList: state.tracking.list.originCountryArrayList,
    destinationCountryArrayList: state.tracking.list.destinationCountryArrayList,
    subUserArrayList: state.tracking.list.addedSubUserList
})
export default (withRouter(connect(mapStateToProps, { searchTracking, getTrackingSearchList, clearTrackingList,getTrackingListWithNoSearch,clearTrackingDetails,getTrackingDetails,setCourierArrayValueList, setOriginCountryList, setDestinationCountryList,setCourierList,setSubUserList, setDataForSearchDetail })(SearchTracking)))
