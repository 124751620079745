export const statusColor = [
    {
        status:'InfoReceived',
        color:'#3690F7'
    },
    {
        status:'FailedAttempt',
        color:'#E95656'
    },
    {
        status:'Exception',
        color:'#F97E2B'
    },
    {
        status:'Delivered',
        color:'#47CF6E'
    },
    {
        status:'Pending',
        color:'#FCCC1B'
    },
    {
        status:'InTransit',
        color:'#2CCCCB'
    },
    {
        status:'OutForDelivery',
        color:'#F551B1'
    },  
    {
        status:'Expired',
        color:'#707479'
    } 
]