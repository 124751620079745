import React from 'react'
import Value from 'lodash.values'
import '../assets/css/tags.scss';
import { connect } from 'react-redux'
import findIndex from 'lodash.findindex'
import { CirclePicker, HuePicker } from 'react-color';
import { withRouter,Link } from 'react-router-dom';
import Table from "@material-ui/core/Table";
import Button from '@material-ui/core/Button';
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from '@material-ui/core/TableRow'
import TableCell from "@material-ui/core/TableCell";
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline'
import Styles from '../assets/css/Styles'
import FlashMessage from '../common/FlashMessage';
import { withStyles } from '@material-ui/core/styles';
import  Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TableContainer from "@material-ui/core/TableContainer";
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {Tags as TagIcon,RemoveIcon,editOutlineIcon,checkedOutLineIcon} from '../../common/imageList';
import {LinearProgressBar,StyledCard, StyledTypography, BreadCrumbs, Image} from '../common/materialComponents'
import { getTagList, editTag, deleteTag, setSettingsFlashMessage, toggleSettingsFlashMessage } from '../actions/actionMethods'
import { TAG_COLOR_UPDATED_SUCCESSFULLY_MESSAGE, TAG_DELETED_SUCCESSFULLY_MESSAGE, TAG_NAME_UPDATED_SUCCESSFULLY_MESSSAGE } from '../common/constants'
class Tags extends React.Component {
    constructor(props) {
      super(props)
      this.isSuccess = true
      this.state = {
        tagListObj: [],
        loading: false,
        deletedUser: false,
        success: false,
        loader: true,
        EditDone: [],
        removeTag: false,
        background: '#123456',
        openAlert: false
      }
    }

    componentDidMount(){
         this.getTagList()
    }

    getTagList = () => {
        getTagList().then(res => {
            this.setState({              
                tagListObj : res.data.data,              
                loader: false
            }, () => {
                const EditDone = this.state.tagListObj.map(tag => {
                    return ({ id: tag._id, state: false })
                  })            
                  this.setState({ EditDone: EditDone })
            })          
        }).catch(err => {
            console.log(err)
        })
    }


    handleEditDone = (id) => {
        var getValue = Value(this.state.EditDone[id])
        return getValue[1]
    }

      
  handleChangeComplete = (id) => (color) => {
    this.setState({background: color.hex})
    document.getElementById('tagcolour' + id).style.background = color.hex
  };

  putTagColour = id => {
    let rgb = document.getElementById('tagcolour' + id).style.background
    let hco = rgb.substr(4, rgb.indexOf(')') - 4).split(',')
    let hexColor = '#' + this.rgbToHex(hco[0]) + this.rgbToHex(hco[1].trim()) + this.rgbToHex(hco[2].trim());
    let putObject = {
      tag_color: hexColor,
    }

    editTag(id,putObject).then(res => {
      if(res && res.data && res.data.meta && res.data.meta.code >= 200 && res.data.meta.code <= 299){
        this.getTagList()
        this.isSuccess = true
        this.props.toggleSettingsFlashMessage(1)
        this.props.setSettingsFlashMessage(TAG_COLOR_UPDATED_SUCCESSFULLY_MESSAGE)
        this.setState({loader: false})
      }else{
        this.isSuccess = false
        this.props.toggleSettingsFlashMessage(1)
        this.props.setSettingsFlashMessage( res.data.meta.message[0])
        this.setState({loader: false})
      }
    }).catch(err => {
      this.setState({loader: false})
    })
    document.getElementById('edittag' + id).click()
  }

  rgbToHex = (rgb) => {
    var hex = Number(rgb).toString(16);
    if (hex.length < 2) {
      hex = "0" + hex;
    }
    return hex;
  };

  editTag = id => {
    const EditDone = this.state.EditDone.map(Border => {
      if (id === Border.id) {
        return {
          ...Border,
          state: !Border.state
        }
      }
      return Border
    })

    this.setState({ EditDone })

    if (document.getElementById("tagname" + id)) {
      let rgb = document.getElementById('tagcolour' + id).style.background
      let hco = rgb.substr(4, rgb.indexOf(')') - 4).split(',')
      let hexColor = '#' + this.rgbToHex(hco[0]) + this.rgbToHex(hco[1].trim()) + this.rgbToHex(hco[2].trim());

      let putObject = {
        tag_color: hexColor,
        tag_name: document.getElementById("tagname" + id).value,       
      }

    editTag(id,putObject).then(res => {
      if(res && res.data && res.data.meta && res.data.meta.code >= 200 && res.data.meta.code <= 299){
        this.getTagList()
        this.isSuccess = true
        this.props.toggleSettingsFlashMessage(1)
        this.props.setSettingsFlashMessage(TAG_NAME_UPDATED_SUCCESSFULLY_MESSSAGE)
        this.setState({loader: false})
      }else{
        this.isSuccess = false
        this.props.toggleSettingsFlashMessage(1)
        this.props.setSettingsFlashMessage(res.data.meta.message[0])
        this.setState({loader: false})
      }
    }).catch(err => {
      this.setState({loader: false})
    })
    }
  }

  toggleFlashMessage = (data) => {
    this.props.toggleSettingsFlashMessage(data)
    this.props.setSettingsFlashMessage('')
  }
  
  changeTagName = id => {
    document.getElementsByName("tagname" + id)[0].addEventListener('input', doThing);
    function doThing() {
      document.getElementById("tagname" + id).value = this.value;
    }
  }

  deleteTag = (id) => {
    localStorage.setItem('tag_id', id)
    this.setState({ removeTag: true})
  }

  removeTag = () => {
    this.setState({loading: true})
    deleteTag(localStorage.getItem("tag_id")).then(res => {
      if(res && res.data && res.data.meta && res.data.meta.code >= 200 && res.data.meta.code <= 299){
        this.setState({ removeTag: false, loading: false })
        localStorage.removeItem('tag_id')     
        this.getTagList()
        this.isSuccess = true
        this.props.toggleSettingsFlashMessage(1)
        this.props.setSettingsFlashMessage(TAG_DELETED_SUCCESSFULLY_MESSAGE)
        this.setState({loader: false})
      }
    }).catch(err => {
      localStorage.removeItem('tag_id')
      this.setState({loader: false,loading: false})
    })
  }

  tagsList = (list) => {
    const {classes} = this.props
      return(
        <React.Fragment>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} stickyHeader aria-label="sticky table">
              <TableHead>
                    <TableRow>
                       <TableCell align="left" className={`${classes.headCell} ${classes.firstHeadAndBodyCell}`} style={{paddingRight:'162px'}}>Tags</TableCell>
                       <TableCell align="left" className={classes.headCell}>Edit</TableCell>
                       <TableCell align="left" className={`${classes.headCell} ${classes.lastHeadAndBodyCell}`}>Delete</TableCell>
                    </TableRow>
              </TableHead>
              <TableBody>
              {
                list && list.length > 0 ? 
                  list.map((tag,index) => {
                    return(
                      <React.Fragment key={index}>
                          <TableRow>
                              <TableCell align="left" className={`${classes.bodyCell} ${classes.firstHeadAndBodyCell}`} style={{paddingRight:'162px'}}>
                                <React.Fragment>
                                <UncontrolledButtonDropdown  className="floatLeft">
                                  <DropdownToggle
                                      className='tag-style'
                                      id={`tagcolour${tag._id}`}
                                      style={{
                                      background: tag.tag_color
                                      }}>
                                      <span ></span>
                                  </DropdownToggle>
                                  <DropdownMenu id={`tagCircle${tag._id}`} className="tag-position">
                                      <CirclePicker
                                      circleSize={20}
                                      colors={["#FFF4BF", "#D2FCBD", "#DEF1FF", "#F2E6FF", "#FFD9DF", "#FFDE4C", "#88F054", "#64BBFA", "#BD80FF", "#FA7087"]}
                                      className="circle_picker"
                                      color={this.state.background}
                                      onChange={this.handleChangeComplete(tag._id)} 
                                      />
                                      <div className="BotBorder" style={{ marginTop: 12 }}></div>
                                      <HuePicker
                                      id={`tagHue${tag._id}`}
                                      className="heupicker"
                                      color={this.state.background}
                                      onChange={this.handleChangeComplete(tag._id)} 
                                      />
                                      <button
                                      className="dd-add-tag"
                                      type="submit"
                                      onClick={() => this.putTagColour(tag._id)}
                                      >Done</button>
                                      <DropdownItem
                                      style={{
                                          display: 'none'
                                      }}>
                                      <button type="hidden" id={`edittag${tag._id}`}>Add</button>
                                      </DropdownItem >
                                  </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                </React.Fragment>
                                <React.Fragment>
                                {this.handleEditDone(findIndex(this.state.EditDone, { 'id': tag._id }))
                                ? 
                                  <input
                                      className={this.state.lengthTagError ? 'error-Border-Message' : ''}
                                      style={{ lineHeight: 1.35 }}
                                      type="text"
                                      autoFocus
                                      value={tag.tag_name}
                                      onKeyDown={() => this.changeTagName(tag._id)}
                                      id={`tagname${tag._id}`}
                                      name={`tagname${tag._id}`} />
                                : <Typography className={classes.tagName}>{tag.tag_name}</Typography>}
                                </React.Fragment>
                              </TableCell>
                              <TableCell align="left" className={classes.bodyCell}>
                                  <div style={{cursor: 'pointer'}} onClick={() => this.editTag(tag._id)}>
                                  {this.handleEditDone(findIndex(this.state.EditDone, { 'id': tag._id }))
                                ? <img src={checkedOutLineIcon} alt="checked_IC" />
                                : <img src={editOutlineIcon} alt="edit_IC"/>}
                                  </div>
                              </TableCell>
                            <TableCell className={`${classes.bodyCell} ${classes.lastHeadAndBodyCell}`} style={{paddingLeft:'10px'}}>
                            <div style={{cursor: 'pointer'}} onClick={() => this.deleteTag(tag._id)}>
                                  <RemoveIcon/>
                            </div>  
                            </TableCell>
                          </TableRow>
                      </React.Fragment>
                    )
                  }) : <div className="noData" style={{ position: "absolute", padding: "2rem", left: '45%' }}>No Tags added. </div>
              }
              </TableBody>
          </Table>
        </TableContainer>
        </React.Fragment>
      )
  }

  cancelTagPopUP = () => {
    this.setState({ removeTag: false })
  }

    render() {
      const { classes } = this.props
      if(this.state.loader){
        return(
          <div>
            <LinearProgressBar/>
          </div>
        )
      }else{
        return (
          <React.Fragment>
            <CssBaseline/>
          <div className="settingsModule">
              <StyledCard>
                  <BreadCrumbs aria-label="breadcrumb">
                      <Link className={classes.link} to="/settings">
                          Settings
                      </Link>
                      <StyledTypography className={classes.text}>Shipments Tags</StyledTypography>     
                  </BreadCrumbs>
              </StyledCard>
              <div className="dataContainer">
                       <div className="containerHeader">
                            <Image alt="emailDomain_IC" className={classes.avatar} src={TagIcon} />
                              <div className="containerTitle" style={{width: '167px'}}>Shipments Tags</div>
                        </div>
                  <div id="settingDataScrollerWrapper">
                      <div className="settingsContainer">
                          <div className="settingsRightSidePanel">
                              <div className="commonSettingsBox tagStyleMainDiv">
                                      <div className="setting-box-content-con">
                                          {this.tagsList(this.state.tagListObj)}   
                                      </div>     
                              </div>
                              {this.props.showSettingFlashMessageFlag === 1 ?
                                 <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                          : ''}
                          </div>
                      </div>
                  </div>
                  { /* footer */ }
              </div>
              {/* data container close  */}           
              
            <Dialog
                open={this.state.removeTag}
                onClose={this.cancelTagPopUP}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
              <DialogTitle id="alert-dialog-title">{"Delete Shipment Tag"}</DialogTitle>
                  <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure that you want to delete this tag ?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button className={classes.dialogCancelBtn}  onClick={this.cancelTagPopUP}>
                      Cancel
                  </Button>
                  <Button  className={classes.dialogActionBtn}  onClick={this.removeTag} autoFocus>
                      Yes, Delete
                  </Button>
                  {(this.state.loading) && <CircularProgress size={22} className={classes.deleteDialogProgress} />}
              </DialogActions>
            </Dialog>
          </div>
        </React.Fragment>
       )
      }
    }
}
const mapStateToProps = state => ({
  showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
  settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, { toggleSettingsFlashMessage, setSettingsFlashMessage })(withStyles(Styles)(Tags)));