import React from 'react'
import '../assets/css/settings.scss'
import '../assets/css/profileStyle.scss'
import {DAILY_DIGEST_ACTIVATED, DAILY_DIGEST_DEACTIVATED, UNHANDLED_EXCEPTION} from '../common/constants'
import { getUserDetails, activeOrDeactiveDailyDigestEmail, toggleSettingsFlashMessage, setSettingsFlashMessage} from '../actions/actionMethods'
import {EmailAlerts as emailAlertIcon} from '../../common/imageList'
import { withRouter, Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { scrollbar } from '../../static/constants'
import Grid from '@material-ui/core/Grid'
import Styles from '../assets/css/Styles'
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch'
import { CssBaseline } from '@material-ui/core';
import FlashMessage from '../common/FlashMessage'
import { connect } from 'react-redux'
import {LinearProgressBar, StyledCard, StyledTypography, BreadCrumbs, Image} from '../common/materialComponents'
class EmailAlerts extends React.Component {
    constructor(props){
        super(props)
        this.isSuccess = true
        this.state = {
            openAlert: false,
            daily_digest_email_active : false,
            user_id : null
        }
    }

    toggleSwitch = (event,userId = '', toggleState = '') => {
        let newToggleState = toggleState
        if (toggleState == 0)
            newToggleState = 1
        else
            newToggleState = 0
        let requestParams = { daily_digest_email_active: newToggleState }
        activeOrDeactiveDailyDigestEmail(userId, requestParams).then(res => {
            if (res.status && !(res.status >= 200 && res.status <= 299)) {
                let message = ''
                if (res.data.errors) {
                    message = res.data.errors[0]
                } else {
                    message = UNHANDLED_EXCEPTION
                }
                this.isSuccess = false
                this.props.toggleSettingsFlashMessage(1)
                this.props.setSettingsFlashMessage(message)
            } else {
                    this.setState({
                        daily_digest_email_active : newToggleState,
                    })
                    this.isSuccess = true
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(this.state.daily_digest_email_active ? ' '+DAILY_DIGEST_ACTIVATED: ' ' +DAILY_DIGEST_DEACTIVATED)
                    localStorage.setItem('daily_digest_email_active', newToggleState)
            }
        })
    }

    componentWillMount() {
        let daily_digest_email = localStorage.getItem('daily_digest_email_active')
        let user_id = JSON.parse(localStorage.getItem('trackhiveToken'))._id
        if(user_id != 'undefined' && (daily_digest_email == 0 || daily_digest_email == 1)){
            this.setState({
                daily_digest_email_active : daily_digest_email,
                user_id : user_id,
            })
        } else {
            getUserDetails().then(res => {
                if(res.data){
                    this.setState({
                        daily_digest_email_active : res.data.daily_digest_email_active,
                        user_id : res.data._id,
                    })
                }
            })
        }
    }

    toggleFlashMessage = (data) => {
        this.props.toggleSettingsFlashMessage(data)
        this.props.setSettingsFlashMessage('')
    }

    render() {
        const {classes} = this.props
        return (
            <React.Fragment>
            <CssBaseline/>
            {
                 this.state.user_id ? 
                 <div className="settingsModule">
                 <StyledCard>
                    <BreadCrumbs aria-label="breadcrumb">
                        <Link className={classes.link} to="/settings">
                            Settings
                        </Link>
                        <StyledTypography className={classes.text}>Email Alerts</StyledTypography>     
                    </BreadCrumbs>
               </StyledCard>
                <div className="dataContainer">
                            <div className="containerHeader">
                                 <Image alt="emailAlerts_IC" className={classes.avatar} src={emailAlertIcon} />
                                 <div className="containerTitle">Email Alerts</div>
                            </div>
                    <div id="settingDataScrollerWrapper">
                        <div className="settingsContainer">
                            <div className="settingsRightSidePanel">
                                <div className="commonSettingsBox">
                                    <Scrollbars autoHide style={scrollbar}>
                                        <Grid className={classes.grid}>
                                            <StyledTypography className={classes.digestText}>
                                                    Daily Digest Email Alert
                                            </StyledTypography>
                                            <Switch
                                                size="medium"
                                                className={this.state.daily_digest_email_active == 1 ? classes.digestSwitchOn : classes.digestSwitchOff}
                                                checked={(this.state.daily_digest_email_active == 1) ? true : false}
                                                onChange={(e,userId,dailyDigest) => this.toggleSwitch(e,this.state.user_id,this.state.daily_digest_email_active)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        </Grid>
                                    </Scrollbars>
                                      {this.props.showSettingFlashMessageFlag === 1 ?
                                         <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                                    : ''}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* dataContainer close */}
            </div> : 
               <div>
                    <LinearProgressBar/>
               </div>
            }
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, { toggleSettingsFlashMessage, setSettingsFlashMessage })(withStyles(Styles)(EmailAlerts)));