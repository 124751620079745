import { combineReducers } from "redux"
import loader from "./loadingReducer"
import automationReducer from "./automationReducer"

const rootReducer = combineReducers({
    loader,
    list:automationReducer
});

export default rootReducer;
