import { firebase } from '@firebase/app';
import '@firebase/database';
import '@firebase/auth';
import { getUserStatus } from '../../../src/index';
import { firebase_basepath } from '../../../src/Setup/common/constants';

const prefix = process.env.REACT_APP_API_PREFIX

export const NOTIFY = 'NOTIFY';
export const NOTIFY_COUNT = 'NOTIFY_COUNT';
export function notify() {
  return dispatch => {
    getUserStatus()
    .then(function (uid) {
      var userId = uid;
      const _TENANT_ID = JSON.parse(localStorage.getItem("trackhiveToken")) ? JSON.parse(localStorage.getItem("trackhiveToken"))._id : '';
      firebase.database().ref(firebase_basepath + _TENANT_ID + '/' + userId + '/lastTimestamp/').limitToFirst(1).once('value', function (snapshot) {
        let startAtTimestamp = 0;
        if (snapshot.exists()) {
          startAtTimestamp = snapshot.val().timestamp;
        }

        if (userId) {
          firebase.database().ref(firebase_basepath + _TENANT_ID + '/' + userId + '/notifications/').orderByChild("timestamp").startAt(startAtTimestamp + 1).on('child_added', function (snapshot) {
            
            //this.getNotification();
            
            dispatch({
              type: NOTIFY,
              snapshot: snapshot.val()
            })

          });

          firebase.database().ref(firebase_basepath + _TENANT_ID + '/' + userId + '/notifications/').orderByChild("timestamp").startAt(startAtTimestamp).on('child_changed', function (snapshot) {
            //console.log(snapshot);
            //this.getNotification();
            dispatch({
              type: NOTIFY,
              snapshot: snapshot.val()
            })

          });
        }

      });
    });
  }
}
export function updateLastTimeStamp(timestamp_val) {
  
  return dispatch => {
    getUserStatus()
    .then(function (uid) {
      var userId = uid;
      const _TENANT_ID = JSON.parse(localStorage.getItem("trackhiveToken")) ? JSON.parse(localStorage.getItem("trackhiveToken"))._id : '';
      firebase.database().ref(firebase_basepath + _TENANT_ID + '/' + userId + '/lastTimestamp/').update({
        timestamp: timestamp_val
      });
    });
  }
}

export function unreadMessageCount() {
  return dispatch => {
    getUserStatus()
    .then(function (uid) {
      var userId = uid;
      const _TENANT_ID = JSON.parse(localStorage.getItem("trackhiveToken")) ? JSON.parse(localStorage.getItem("trackhiveToken"))._id : '';
      firebase.database().ref(firebase_basepath + _TENANT_ID + '/' + userId + '/unreadMessageCount/').on('value', function (snapshot) {
        dispatch({
          type: NOTIFY_COUNT,
          snapshot: snapshot.val()
        })
      });
    });
  }
}


export function updateCount(timestamp_val) {
  
  return dispatch => {
    getUserStatus()
    .then(function (uid) {
      var userId = uid;
      const _TENANT_ID = JSON.parse(localStorage.getItem("trackhiveToken")) ? JSON.parse(localStorage.getItem("trackhiveToken"))._id : '';
      var updates = {};
      updates[firebase_basepath + _TENANT_ID + '/' + userId + '/last_notify/timestamp'] = firebase.database.ServerValue.TIMESTAMP;
      updates[firebase_basepath + _TENANT_ID + '/' + userId + '/unreadMessageCount'] = 0;
      firebase.database().ref().update(updates);
    });
  }
}