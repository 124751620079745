import React, { PureComponent } from 'react';
import FileRow from './fileRow';
import ReactS3Uploader from 'react-s3-uploader';
import axios from 'axios';
import {getInstance} from '../../src/config/token'
import {trackingUpload} from '../common/imageList'
const FILE_EXTENSION_REGEX = /(?:\.([^.]+))?$/;
const prefix = ''
const ERROR_INVALID_EXT = 'invalid-ext';
const ERROR_INVALID_FILE = 'invalid-file';
const ERROR_UPLOAD_FAILED = 'upload-failed';

const VALID_EXTENTIONS = ['xls', 'xlsx'];
const VALID_MIME_TYPES = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

class FileDropZone extends PureComponent {
    uploaderReference = null;
    filename = null;
    state = {
        file: null,
        error: null,
        progress: null,
        isUploading: false
    }

    // static propTypes = {
    //     onStarted: PropTypes.func,
    //     onError: PropTypes.func,
    //     onSuccess: PropTypes.func,
    //     onReset: PropTypes.func
    // }
    getImageSignedUrl = (fileName, fileType) => {
        const config = getInstance('GET', `${prefix}/user/awsservice/presignedurl/image?file_name=` + fileName + '&file_type=' + fileType)
        return axios(config).then(res => res.data)
      }

    getSignedUrl = (file, callback) => {      
        let filename = file.name
        filename = Math.floor((new Date()).getTime() / 1000) + '_' + filename.replace(/[^\w\d_\-.]+/ig, '')
        this.getImageSignedUrl(filename, file.type).then(res => {
        let signedUrl = {signedUrl : res.data.signedRequest}
            callback(signedUrl);
        })
        .catch(error => {
        });
    }

    onUploadStart = (file, next) => {
        if (file) {
            const ext = FILE_EXTENSION_REGEX.exec(file.name)[1];
            if (VALID_EXTENTIONS.includes(ext) || VALID_MIME_TYPES.includes(file.type)) {
                this.filename = Math.floor((new Date()).getTime() / 1000) + '_' + file.name.replace(/[^\w\d_\-.]+/ig, '')
                this.setState({ file: file, isUploading: true, error: null, progress: 0 });
                this.props.onStarted();
                next(file);
            } else {
                this.filename = null;
                this.props.onError(ERROR_INVALID_EXT);
                this.setState({ file: null, isUploading: false, error: ERROR_INVALID_EXT, progress: 0 });
            }
        } else {
            this.filename = null;
            this.props.onError(ERROR_INVALID_FILE)
            this.setState({ file: null, isUploading: false, error: ERROR_INVALID_FILE, progress: 0 });
        }
    }

    onUploadFinish = (info) => {
        this.setState({ isUploading: false, progress: 100 });
        this.props.onSuccess(`temp/${this.filename}`);
    }

    onUploadError = (info) => {
        this.setState({ file: null, isUploading: false, error: ERROR_UPLOAD_FAILED, progress: 0 });
        this.props.onError(ERROR_UPLOAD_FAILED);
    }

    onUploadProgress = (percentage) => {
        this.setState({ progress: percentage });
    }

    removeFileHandler = () => {
        this.abortUpload();
        this.setState({ file: null, isUploading: false, error: null, progress: null });
        this.props.onReset();
    }

    saveUploaderReference = (uploader) => {
        if (uploader) {
            this.uploaderReference = uploader;
        }
    }

    getErrorMessageFromType = (type) => {
        let errorMessage;
        switch (type) {
            case ERROR_INVALID_EXT:
                errorMessage = 'Invalid file format. Only .xls files are supported.'
                break;
            case ERROR_UPLOAD_FAILED:
                errorMessage = 'Failed to upload file, please try again.'
                break;
            case ERROR_INVALID_FILE:
            default:
                errorMessage = 'Invalid file. This file may be corrupted, please selected another file.'
                break;
        }
        return errorMessage;
    }

    abortUpload = () => {
        if (this.uploaderReference != null) {
            this.uploaderReference.abort();
        }
    }

    componentWillUnmount() {
        this.abortUpload();
    }

    render() {
        const { file, isUploading, error, progress } = this.state;
        const content = file
            ? <div className="DragDropWrapper"><FileRow
                onRemoveFile={this.removeFileHandler}
                isUploading={isUploading}
                progress={progress}
                file={file} />
                </div>
            : <div className="DragDropWrapper">
                <div className="box">
                    <div className="box-content-inner">
                        <img src={trackingUpload} alt="" />
                        <div className="label-dnd">Drag &amp; Drop</div>
                        <div className="label-browse">or Browse</div>
                    </div>

                    <ReactS3Uploader
                        ref={this.saveUploaderReference}
                        getSignedUrl={this.getSignedUrl}
                        s3path="temp/"
                        preprocess={this.onUploadStart}
                        onProgress={this.onUploadProgress}
                        onError={this.onUploadError}
                        onFinish={this.onUploadFinish}
                        uploadRequestHeaders={{}}
                        contentDisposition="auto"
                        scrubFilename={(filename) => this.filename}
                        autoUpload={true}
                        multiple={false}>

                    </ReactS3Uploader>
                </div>
                {(error) && (
                    <div className='import-error'>{this.getErrorMessageFromType(error)}</div>
                )}
            </div>
        return (
            content
        )
    }
}

export { FileDropZone, ERROR_INVALID_EXT, ERROR_INVALID_FILE, ERROR_UPLOAD_FAILED };