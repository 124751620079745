import React from 'react';
import Footer from '../../../../common/footer';
import { Scrollbars } from 'react-custom-scrollbars';
import { storeURL, Permalink, Legacy, RestAPI, Permissions,CarrierTrackhive } from '../../../../../common/imageList';
const wocommercePluginLink = process.env.REACT_APP_WOOCOMMERCE_PLUGIN_URL

export default class WooCommerceInstructions extends React.Component{
    moveToWooCommForm = () => {
        this.props.moveToWooCommForm(true)
    }
    backToIntegration = () => {
        this.props.backToIntegration()
    }

    render(){
        return(
            <React.Fragment>
                 <Scrollbars autoHide style={{height:  "calc(100vh - 266px)", width: "100%"}}>
                 <div className="integrationStepsMainDiv">
                            <ul className="instructions-list">
                                <li>
                                    <span className="list-seq">1</span>
                                    <span className="instructions-text">Login to your Wordpress/WooCommerce account.</span>
                                </li>
                                <li>
                                    <span className="list-seq">2</span>
                                    <span className="instructions-text"><a href={wocommercePluginLink} download target="_blank" rel="noopener noreferrer">Download Trackhive plugin</a>, then install and activate it at wordpress.</span>
                                </li>

                                <li>
                                    <span className="list-seq">3</span>
                                    <span className="instructions-text">
                                        Enter tracking number & select carrier, then click on Update.
                                        
                                    </span>
                                    <span className="instructions-ss"><img alt="" src={CarrierTrackhive} /></span>
                                </li>

                                <li>
                                    <span className="list-seq">4</span>
                                    <span className="instructions-text">
                                        Enter your Store URL. To get your Store URL, go to System Status under
                                        WooCommerce menu in your WordPress account. Copy the Home URL mentioned first
                                        under WordPress environment label.</span>
                                    <span className="instructions-ss"><img alt="" src={storeURL} /></span>
                                </li>

                                
                                <li>
                                    <span className="list-seq">5</span>
                                    <span className="instructions-text">Please make sure permalinks are not set to DEFAULT in your Wordpress account.</span>
                                    <span className="instructions-ss"><img alt="" src={Permalink} /></span>
                                </li>
                                <li>
                                    <span className="list-seq">6</span>
                                    <span className="instructions-text">Go to WooCommerce settings. Under “Advanced” tab, select the Legacy API and ensure that legacy REST API is enabled.</span>
                                    <span className="instructions-ss"><img alt="" src={Legacy} /></span>
                                </li>
                                <li>
                                    <span className="list-seq">7</span>
                                    <span className="instructions-text">Select the REST API and click on Add key.</span>
                                    <span className="instructions-ss"><img alt="" src={RestAPI} /></span>
                                </li>
                                <li>
                                    <span className="list-seq">8</span>
                                    <span className="instructions-text">Scroll to the bottom of the edit user page
                                        and copy “Consumer Key” and “Consumer Secret Key”.</span>
                                    <span className="instructions-ss"><img alt="" src={Permissions} /></span>
                                </li>
                                <li>
                                    <span className="list-seq">9</span>
                                    <span className="instructions-text">Set the permissions to Read/Write and click on “Update User”.</span>
                                </li>
                                <li>
                                    <span className="list-seq">10</span>
                                    <span className="instructions-text">Now, head back to your Trackhive account.</span>
                                </li>
                                <li>
                                    <span className="list-seq">11</span>
                                    <span className="instructions-text">Enter all the details you see on the next page.</span>
                                </li>
                                <li>
                                    <span className="list-seq">12</span>
                                    <span className="instructions-text">Lastly, click on “Done”.</span>
                                </li>

                                <li>
                                    <span className="list-seq" style={{width:'auto'}}>Note: </span>
                                    <span className="instructions-text">The Tracking will only be generated after installing <strong>Trackhive plugin to WooCommerce </strong>   & <strong>integrating WooCommerce store to Trackhive.</strong> </span>
                                </li>
                            </ul>
                            <button
                                id="store-next-btn"
                                type="button"
                                style={{
                                    float: 'right',
                                    display: 'none'
                                }}
                                onClick={this.moveToWooCommForm}
                            >
                                Next
                            </button>
                            <button
                                id="store-cancel-btn"
                                type="button"
                                style={{
                                    float: 'right',
                                    display: 'none'
                                }}
                                onClick={this.backToIntegration}
                            >
                                Cancel
                            </button>
                        </div>
                 </Scrollbars>
            <div className="commonFooter">
                <Footer storeAction="true" />
            </div>
            </React.Fragment>
        )
   }
}