import React from 'react';
import {withRouter} from 'react-router-dom';

import axios from 'axios';
import {addStore} from '../actions/actionMethods'
import {searchPattern} from '../../static/constants'

class BigCommerceToTrackhive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader:true
    }
  }

  removeLocalStorage = () => {
    localStorage.removeItem("trackhiveToken");
    localStorage.removeItem("ProfileName");
    localStorage.removeItem("Reply_To");
    localStorage.removeItem("Company_Name");
  }

  async componentDidMount() {
    
      var urlParams;
      (window.onpopstate = function () { // To get all query params in an Obj form
          var match,
              pl     = /\+/g,  // Regex for replacing addition symbol with a space    
              decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
              query  = window.location.search.substring(1);
  
          urlParams = {};
          while (match = searchPattern.exec(query))
             urlParams[decode(match[1])] = decode(match[2]);
      })();
     
      let postObject = {
          "code" : urlParams.code,
          "scope" : urlParams.scope,
          "context" : urlParams.context
      }
      let redirect_uri = process.env.REACT_APP_API_URL + '/bigcommerce_to_trackhive_token'     
      axios.post(redirect_uri,postObject).then(response => {          
          if (response.data.data) {
            localStorage.setItem("BigCommerceStoreKey", JSON.stringify(response.data.data))
            let userDetails = JSON.parse(localStorage.getItem("trackhiveToken"))    
            
            if (response.data.data.isRegister === true) { // isRegister true
              if (userDetails !== undefined && userDetails !== null) {
                this.callStoresAPI(userDetails, response.data.data)
              }
              else {
                this.props.history.push('/login?utm_source=bigcommerce')
              }    
            }
            else if (response.data.data.isRegister === false) { // isRegister false
    
              if (userDetails !== undefined && userDetails !== null) {
    
                this.callStoresAPI(userDetails, response.data.data)
              }
              else {
                this.props.history.push('/signup?utm_source=bigcommerce')
              }
            }
    
            else if (response.data.data.storeExist === true) { // isStoreExist
              if (userDetails !== undefined && userDetails !== null) {
                this.props.history.push('/integrated_list')
              }
              else {
                this.removeLocalStorage()                
                this.props.history.push('/login?utm_source=storeExist')
              }
            }
          }
          else {
            
            if(response.data.meta.code === 200 && response.data.meta.message[0] === 'Store integration warning'){
                this.props.history.push("/integrated_list")
            }
            else{
              this.setState({loader:false})
            }
          }

      }).catch(err => {
          
      })   
  }

  callStoresAPI = (userDetails, bigcommerceData) => {
    let prepareData = {}

    prepareData.user_id = userDetails._id
    prepareData.request_id = bigcommerceData.key
    prepareData.channel_id = '2'  
    prepareData.active = 1  
    
    addStore(prepareData).then(res => {
      if(res.data !== undefined && res.data !== null){
        localStorage.removeItem('BigCommerceStoreData')
        this.props.history.push('/integrated_list')
      }
    })
  }

  render() {   
    return (
      <div id="data_container" className="main_view setup_view">
      {this.state.loader ? <div>Redirecting to BigCommerce...</div> : <div>Unauthorized Store...</div>}
      </div>

    );
  }
}
export default withRouter(BigCommerceToTrackhive);
