import * as Condition from './conditionTypes'
import * as Action from './automationAction'

export const TRIGGER = [{ label: 'Select Condition', value: '' }, { label: 'When a Shipment ', value: 'SHIPMENT' }]
export const TRIGGER_CUSTOM = [{ label: 'Select Trigger', value: '' }]

export const TRIGGER_CONDITION = {
    SHIPMENT: [{ label: 'is created', value: 'CREATED' }, { label: 'is updated', value: 'UPDATED' }],
    CUSTOM: [{label:'is custom', value: 'CUSTOM'}]
}
export const TRIGGER_CONDITION_SHIPMENT = [{ label: 'Shipment is created', value: 'CREATED' }, { label: 'Shipment status is updated', value: 'UPDATED' }]
// export const CONTROLLER_CONDITION = [{ label: 'Inbuilt Trigger', value: 'TRIGGER' }, { label: 'Custom Trigger', value: 'TRIGGER_CUSTOM' }]
export const CONTROLLER_CONDITION = [{ label: 'Inbuilt Trigger', value: 'TRIGGER' }]

export const CONDITION_OPERATOR = [{ label: 'all', value: 'AND' }, { label: 'any', value: 'OR' }]

export const CONDITION_TYPE = {
    
    SHIPMENT: Condition.ShipmentConditions,
    CUSTOM : Condition.ShipmentConditions,
    
}

export const ACTION = {
    SHIPMENT: Action.ShipmentActions,
    CUSTOM : Action.ShipmentActions,
}

const IS_OPERATOR = { label: 'is', value: 'IN' }
const NOT_IS_OPERATOR = { label: 'is not', value: 'NOT IN' }
const LIKE_OPERATOR = { label: 'contains', value: 'LIKE' }
const NOT_LIKE_IS_OPERATOR = { label: 'contains not', value: 'NOT LIKE' }

export const CONDITION_TYPE_OPERATOR = {
    STATUS: [IS_OPERATOR, NOT_IS_OPERATOR],
    COURIER: [IS_OPERATOR, NOT_IS_OPERATOR],
    ORIGIN:[IS_OPERATOR, NOT_IS_OPERATOR, LIKE_OPERATOR, NOT_LIKE_IS_OPERATOR],
    DESTINATION:[IS_OPERATOR, NOT_IS_OPERATOR, LIKE_OPERATOR, NOT_LIKE_IS_OPERATOR],
    CUSTOMER_NAME:[IS_OPERATOR, NOT_IS_OPERATOR],
    CUSTOMER_EMAIL_ID:[IS_OPERATOR, NOT_IS_OPERATOR],
    DELIVERY_TIME:[IS_OPERATOR],
    ORIGIN_STATE: [IS_OPERATOR, NOT_IS_OPERATOR, LIKE_OPERATOR, NOT_LIKE_IS_OPERATOR],
    DESTINATION_STATE: [IS_OPERATOR, NOT_IS_OPERATOR, LIKE_OPERATOR, NOT_LIKE_IS_OPERATOR],
    ORIGIN_CITY: [IS_OPERATOR, NOT_IS_OPERATOR, LIKE_OPERATOR, NOT_LIKE_IS_OPERATOR],
    DESTINATION_CITY: [IS_OPERATOR, NOT_IS_OPERATOR, LIKE_OPERATOR, NOT_LIKE_IS_OPERATOR],
    SHIPPING_SERVICES : [IS_OPERATOR, NOT_IS_OPERATOR, LIKE_OPERATOR, NOT_LIKE_IS_OPERATOR]
}