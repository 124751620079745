import React from 'react';
import '../../assets/css/emailSettings.scss'
import { withRouter } from 'react-router-dom';
import { getAllSMSTemplate, deleteSMSTemplate } from '../../actions/actionMethods';
import { Scrollbars } from 'react-custom-scrollbars';
import { scrollbar } from '../../../static/constants';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import {DELETE_TEMPLATE_ERROR, DELETE_TEMPLATE_SUCCESS} from '../../common/constants'
import {addIcon, infiniteLoader} from '../../../common/imageList'

class SMSSetting extends React.Component{
  constructor(props){
    super(props)
    this.state = {
        templateList: [],
        deletePopupFlag: false,
        deleteTemplateId: '',
        success: false,
        showLoader: false,
        loading: false,
        apiError:false,
        loaderFlag: true
    }
  }   
        
  componentDidMount(){
    this.getSMSTemplates()
  }

  getSMSTemplates = () => {
    getAllSMSTemplate().then(res => {
        if(res.status >= 200 && res.status <= 500){
            let templateList = []
            templateList = res.data
            let customTemplates = []
            if(templateList !== undefined){
                for(let i = 0; i<templateList.data.length; i++){
                    customTemplates.push(templateList.data[i]) 
                }
            }
            this.setState({
                templateList: customTemplates,
                loaderFlag: false
            })
        }
    }).catch(function(error){
        console.log(error)
    })
  }

  createCustomTemplate = () => {
    this.props.history.push('/custom_sms_template')
  }

  handleActionPerform = (id,action) =>{
    if(action === 'copy'){
        this.props.history.push(`/copy_sms_template/${id}`)
    } else if (action === 'edit'){
        
        this.props.history.push(`/edit_sms_template/${id}`)
    }
  }


  templatesListing = () => {
    let template = []
    let templateArray = []         

    template = this.state.templateList
    if(template !== undefined){
        for(let i=0 ; i<template.length; i++){
            templateArray.push(
                <React.Fragment key={`email_template_${template[i]._id}`}>
                    <div className="TemplateContainer">
                       {/*<div className="star">
                            <img src={Star} alt="star" className="star" />
                       </div>*/}
                        <div className="template_name">{template[i].template_name}</div>
                        <div className="action_button">
                            <div className="button"  onClick={(id, action) => this.handleActionPerform(template[i]._id, 'copy')} >Copy</div>
                            <div className="button"  onClick={(id, action) => this.handleActionPerform(template[i]._id, 'edit')}>Edit</div>
                            <div className="button" onClick = {(id) => this.setState({deletePopupFlag:true,deleteTemplateId:template[i]._id})}>Delete</div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
    return(templateArray)
  }

  removeTemplate = () => {            
    this.setState({loading: true})
    let templateId = this.state.deleteTemplateId

    deleteSMSTemplate(templateId).then(res => {               
        if(res && res.status >= 200 && res.status <= 299){
            this.getSMSTemplates()
            this.setState({ loading: false, success: DELETE_TEMPLATE_SUCCESS, apiError: false, deleteTemplateId: '', deleteTemplateType: '', deletePopupFlag: false })                    
        }else{
            this.setState({ loading: false, success: false, apiError: DELETE_TEMPLATE_ERROR, deleteTemplateType: '', deleteTemplateId: '', deletePopupFlag: false })
        }
    }).catch(error => {
        console.log(error)
        this.setState({loading: false,success:false})
    })
  }

  cancelDeletePopUP = () => {
    this.setState({
        deletePopupFlag: false,
        deleteTemplateId: '',
        deleteTemplateType: ''
    })
  }

  showLoader = () => {
    return (
        <div style={{ background: 'white', margin: '0.5rem 0' }}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   >
        <ContentLoader height={400} width={700} speed={2} primaryColor={'#f1f1f1'} secondaryColor={'#e2e2e2'}>
            <rect x="30" y="24" rx="5.5" ry="5.5" width="200" height="6" />
            <text x='510' y='30' style={{fontSize: '0.65rem'}}>Delete</text>
            <text x='575' y='30' style={{fontSize: '0.65rem'}}>Edit</text>
            <text x='630' y='30' style={{fontSize: '0.65rem'}}>Copy</text>

            <rect x="30" y="49" rx="5.5" ry="5.5" width="200" height="6" />
            <text x='510' y='55' style={{fontSize: '0.65rem'}}>Delete</text>
            <text x='575' y='55' style={{fontSize: '0.65rem'}}>Edit</text>
            <text x='630' y='55' style={{fontSize: '0.65rem'}}>Copy</text>

            <rect x="30" y="74" rx="5.5" ry="5.5" width="200" height="6" />
            <text x='510' y='80' style={{fontSize: '0.65rem'}}>Delete</text>
            <text x='575' y='80' style={{fontSize: '0.65rem'}}>Edit</text>
            <text x='630' y='80' style={{fontSize: '0.65rem'}}>Copy</text>

            <rect x="30" y="99" rx="5.5" ry="5.5" width="200" height="6" />
            <text x='510' y='105' style={{fontSize: '0.65rem'}}>Delete</text>
            <text x='575' y='105' style={{fontSize: '0.65rem'}}>Edit</text>
            <text x='630' y='105' style={{fontSize: '0.65rem'}}>Copy</text>
        </ContentLoader>      
        </div>
    )
}

render(){
    let templates = []
    templates = this.templatesListing()
            
    return(
        <div className="settingsModule">
        <div className="commonTopHeader">
            <h1><span className="settingsIcon"></span>Settings</h1>
        </div>
        <div className="dataContainer">
            <div id="settingDataScrollerWrapper">
                <div className="settingsContainer">
                    <div className="settingsLeftSidePanel">
                    </div>
                    <div className="settingsRightSidePanel">
                        <div className="commonSettingsBox emailStyleMainDiv">
                            <Scrollbars autoHide style={scrollbar}>
                                <div className="commonSettingsBoxTitle">
                                    <div className="titleText">
                                        <span>SMS Templates</span>
                                    </div>
                                    <div className="titleTextRightSideDiv">
                                        <img src={addIcon} alt="Add Icon" onClick={this.createCustomTemplate} />
                                    </div>
                                </div>
                                <div>
                                    {this.state.loaderFlag ? this.showLoader(): templates.length > 0 ? templates : <div className="noData">No SMS templates available.</div> }
                                </div>
                            </Scrollbars>
                        </div>
        
                {this.state.apiError != ''
                ? <div className="activity error">
                    <span>{(this.state.apiError)}</span>
                </div>
                : ''}
                    {this.state.success
                    ? <div className="activity success">
                    <span>{this.state.success}</span>
                    </div>
                    : ''}
                    </div>
                </div>
            </div>
        </div>
        {/* data container close  */}
        <div
                style={{
                    display: this.state.deletePopupFlag
                        ? 'block'
                        : 'none'
                }}>
                <div className="ModalPopupWrapper">
                    <div className="ModalContainer">
                        <div className="ModalHeading">Delete Template</div>
                        <div className="ModalText">
                            <p>Are you sure that you want to delete a template?</p>
                        </div>
                    </div>
                    <div className="ActionButtons">
                        {
                            this.state.loading ?
                            <img src={infiniteLoader} style = {{width: "318px"}} alt="loader" /> 
                            : <React.Fragment>
                                <a 
                                    href
                                    className="SecondryButton borderright_1"
                                    onClick={this.cancelDeletePopUP}>Cancel</a>
                                <a
                                    href
                                    className="PrimaryButton redText"
                                    onClick={this.removeTemplate}>Yes, Delete</a>
                                </React.Fragment>  
                        }

                    </div>
                </div>
                <div className="commonOverlayDiv"></div>
            </div>
    </div>
    )
  }   
}
export default withRouter(connect(null, {})(SMSSetting));