
const REACT_APP_S3_URL = process.env.REACT_APP_S3_URL
const styles = {
  /* TRACKING FILTER CSS START */
  MuiListItemIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'inline-flex',
    'min-width': '30px !important',
    'flex-shrink': 0,
    paddingLeft: '10px'
  },
  MuiListItemWithoutIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'inline-flex',
    'min-width': '0px !important',
    'flex-shrink': 0
  },
  MuiListItemSecondaryAction: {
    paddingRight: '5px !important',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '3px'
  },
  filterDropdownText: {
    fontSize: '14px',
    fontFamily: 'Roboto',
    color: '#111111',
    paddingTop: '2px'
  },
  dropdownSearch: {
    border: 0,
    margin: 0,
    display: 'inline-flex',
    padding: '0px',
    position: 'relative',
    minWidth: 0,
    flexDirection: 'column',
    verticalAlign: 'middle',
    width: '100%',
    background: 'url(https://trackhive-images.s3-ap-southeast-1.amazonaws.com/search.svg) no-repeat 18.6em 0.1rem',
    fontSize: '12px',
    backgroundColor: '#ffffff !important',
    height: '38px'
  },
  /* TRACKING FILTER CSS END */

  /* TRACKING LIST CSS START*/
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    boxShadow: 'none'
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headTableCell: {
    height: '16px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#212121',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
   // borderTop: '1px solid rgba(224, 224, 224, 1)',
    verticalAlign: 'middle',
    padding: '6px 15px 6px 0px',
    backgroundColor: '#ffffff',
    "&:first-child": {
      width: '1%'
    },
    "&:nth-child(2)": {
      width: '4%',
    },
    "&:nth-child(3)": {
      width: '6%'
    },
    "&:nth-child(4)": {
      width: '11%'
    },
    "&:nth-child(5)": {
      width: '5%'
    },
    "&:nth-child(6)": {
      width: '5%'
    },
    "&:nth-child(7)": {
      width: '12%'
    },
    "&:nth-child(8)": {
      width: '10%'
    },
    "&:nth-child(9)": {
      width: '12%'
    },
    "&:nth-child(10)": {
      width: '10%'
    },
    "&:nth-child(11)": {
      width: '3%'
    },
    "&:nth-child(12)": {
      width: '12%'
    },
    "&:nth-child(13)": {
      width: '9%'
    }
  },
  headTableRow: {
    height: '56px'
  },
  bodyTableRow: {
    height: '52px',
    '&:hover': {
      backgroundColor: '#F6F5F5 !important',
    },
  },
  selectedTableRow: {
    backgroundColor: '#e9faff !important'
  },
  bodyTableCell: {
    height: '16px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#212121',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    verticalAlign: 'middle',
    padding: '4px 15px 4px 0px',
    // backgroundColor: '#ffffff',
    cursor : 'pointer',
    maxWidth: '0px',
    overflow: 'hidden',
    textOverflow : 'ellipsis',
    "&:first-child": {
      width: '1%'
    },
    "&:nth-child(2)": {
      width: '4%',
    },
    "&:nth-child(3)": {
      width: '6%'
    },
    "&:nth-child(4)": {
      width: '11%'
    },
    "&:nth-child(5)": {
      width: '5%'
    },
    "&:nth-child(6)": {
      width: '5%'
    },
    "&:nth-child(7)": {
      width: '12%'
    },
    "&:nth-child(8)": {
      width: '10%'
    },
    "&:nth-child(9)": {
      width: '12%'
    },
    "&:nth-child(10)": {
      width: '10%'
    },
    "&:nth-child(11)": {
      width: '3%'
    },
    "&:nth-child(12)": {
      width: '12%'
    },
    "&:nth-child(13)": {
      width: '9%'
    }
  },
  tagButton: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color : '#ffffff',
    '& .MuiIconButton-label' : {
      backgroundColor :'#727272',
      borderRadius : '50%',
      padding : '3px',
      color : '#ffffff'
    }
  },
  container: {
    height: 'calc(100vh - 205px)',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      utline: '1px solid slategrey'
    }
  },
  progressbar: {
    width: '100%',
    zIndex: 2,
    position: 'absolute',
  },
  pagination: {
    bottom: 0,
    position: 'fixed',
    zIndex: 100,
    backgroundColor: '#ffffff',
    width: 'calc(100% - 73px)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  paginationTextAlign : {
    paddingTop : '10px'
  },

  /*TRACKING LIST CSS END*/

  /*TOOLBAR CSS START */

  toolbarRoot: {
    minHeight: '54px !important',
    padding: '1px 0px 1px 0px !important',
    display: 'block',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  toolbarHighlight:
  // theme.palette.type === 'light'
  //   ? 
  {
    color: '#ffffff',
    backgroundColor: '#757575',
    display: 'flex'
  },
  // : {
  //   color: theme.palette.text.primary,
  //   backgroundColor: theme.palette.secondary.dark,
  //   display: 'flex'
  // },
  bulkFilterWrap: {
    flex: '1 1 100%',
    paddingLeft: '15px',
    height: '35px'
  },
  bulkFilterCount: {
    display: 'inline-block',
    paddingRight: '20px',
    paddingTop: '8px',
    height: '16px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#ffffff',
  },
  bulkFilterLine: {
    display: 'inline-block',
    position: 'absolute',
  },
  bulkfilterActionSpan: {
    height: '32px',
    position: 'absolute',
    borderLeft: '1px solid #ffffff'
  },
  bulkFilterActionWrap: {
    display: 'inline-block',
    paddingLeft: '12px'
  },
  bulkFilterActionWrapLast: {
    display: 'inline-block',
    paddingLeft: '3px'
  },
  bulkFilterAction: {
    display: 'inline-block',
    paddingTop: '8px',
    height: '16px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#ffffff',
    cursor: 'pointer',
    paddingBottom: '26px',
    paddingLeft: '5px',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#868686',
      paddingRight: '5px'
    }
  },
  bulkFilterActionDropdown: {
    height: '16px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#ffffff',
    "& .MuiSelect-select:focus": {
      borderRadius: 0,
      backgroundColor: '#868686'
    },
  },
  selectIcon: {
    "& .MuiSelect-icon": {
      color: '#ffffff'
    },
  },
  hrMarginBlock: {
    marginBlockEnd: '0px',
    marginBlockStart: '0px',
    display: 'block',
    height: '1px',
    border: 0,
    borderTop: '1px solid #ccc',
    padding: '0px',
  },
  topbarSelectAutocomplete: {
    "& .MuiFormControl-marginNormal": {
      marginTop: '0px',
      marginBottom: '0px',
      display: 'block',
      paddingRight: '0px',
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: '#ffffff',
      }
    },
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
      paddingRight: '0px'
    },
    "& .MuiInputBase-input": {
      height: '16px',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.14px',
      color: '#111111',
    },
    "& .MuiSvgIcon-root": {
      // color: '#ffffff'
    },
    "& .MuiInput-underline:before": {
      borderBottom: 'none'
    },
    "& .MuiInput-underline:after": {
      borderBottom: 'none'
    },
    "& .MuiInput-underline.Mui-focused:after": {
      transform: 'unset'
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: 'none'
    },
    "& .MuiAutocomplete-root:hover .MuiAutocomplete-clearIndicatorDirty, .MuiAutocomplete-root.Mui-focused .MuiAutocomplete-clearIndicatorDirty": {
      display: 'none'
    },
    "& .MuiAutocomplete-clearIndicator": {
      visibility: 'hidden',
      display: 'none'
    },
    "& .MuiAutocomplete-endAdornment": {
      position: 'relative'
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingRight: '0px !important'
    },
  },
  topbarStatusInputWidth: {
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      width: 0,
      minWidth: '100px',
      padding: '5px 4px 4px 4px',
      textAlign: 'center'
    }
  },
  topbarAssignToInputWidth: {
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      width: 0,
      minWidth: '85px',
      padding: '5px 4px 4px 4px',
      textAlign: 'center'
    }
  },
  toolbarDropdownoption: {
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  toolbarDropdownList: {
    height: '250px',
    // width : '450px',
    '&::-webkit-scrollbar': {
      width: '0.5em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    },
  },
  toolbarDropdownListRadius: {
    borderRadius: 0,
    boxShadow: '0rem 0.06rem 0.125rem 0.01rem',
    width: '200px',
  },
  bulkActionIcon: {
    marginRight: '30px',
    padding: '4px'
  },

  /*TOOLBAR CUSTOM DROPDOWN CSS START */
  filterWrapper: {
    // position: 'relative',
    display: 'inline-block',
    // paddingBottom: '10px',
    paddingLeft: '12px',
  },
  FilterBtnDD: {
    float: 'left',
    position: 'relative',
  },
  filterButton: {
    float: 'left',
    cursor: 'pointer',
    display: 'flex',
    height: '16px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#ffffff',
    paddingTop: '3px',
    paddingLeft: '5px',
    '&:first-child': {
      // paddingLeft: 1.5rem,
    },
  },
  filterArrow: {
    background: 'url(' + REACT_APP_S3_URL + 'bulkActionDropdownIcon.svg)' + 'no-repeat 0.3rem -0.1rem',
    width: '24px',
    height: '24px',
    display: 'block',
    marginTop: '-3px',
    objectFit: 'contain',
  },
  filterTextHheading: {
    fontSize: '14px',
    color: '#ffffff',
    fontWeight: 500,
    backgroundColor: '#1a73e8',
    textAlign: 'left',
    verticalAlign: 'middle',
    fontStyle: 'Roboto',
    fontFamily: 'Roboto',
    height: '38px',
    paddingLeft: '15px',
    paddingTop: '10px'
  },
  filterDropdown: {
    background: '#ffffff',
    boxShadow: '0rem 0.06rem 0.125rem 0.01rem #D4BDBD',
    position: 'absolute',
    top: '-0.5rem',
    left: '-10px',
    zIndex: 20,
    width: '250px',
    borderRadius: '0rem',

  },
  commonDropdownMenu: {
    maxHeight: '280px',
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar:horizontal': {
      height: '5px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#EBECED',
      borderRadius: '16px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#cacaca'
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#cacaca'
    },
    '& .MuiList-root': {
      listStyle: 'none !important',
      margin: '0 !important',
      paddingTop: '9px'
    }
  },
  verticalAlignSpan: {
    // verticalAlign: '-webkit-baseline-middle'
  },
  topbarDropdownfooter: {
    // marginBottom: '0.5rem',
  },
  topbarDropdownFooterText: {
    fontSize: '14px',
    float: 'right',
    cursor: 'pointer',
    paddingRight: '1.5rem',
    color: '#1a73e8',
    paddingTop: '9px',
    // paddingBottom: '0.5rem',
    fontWeight: 500,
    height: '38px'
  },
  noFilterFound: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    fontSize: '14px',
    textAlign: 'center',
    color: '#111111',
    //padding: '0 0 0.5rem 0'
  },
  dialogActionBtn: {
    color: "red"
  },
  dialogCancelBtn: {
    color: "#1a73e8"
  },
  /* TOOLBAR CUSTOM CSS END */

  /* TABLE DROPDOWN CSS START */
  trackingTableFilterBtnDD: {
    float: 'left',
    position: 'relative',
  },
  tableDropdown : {
    position :'fixed',
    zIndex : 999
  },
  trackingTableFilterButton: {
    float: 'left',
    cursor: 'pointer',
    display: '-ms-flexbox',
    height: '16px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#212121',
    // paddingTop: '3px',
    // paddingLeft : '5px',
    '&:first-child': {
      // paddingLeft: 1.5rem,
    },
    // '&:hover': {
    //     background: '$white',
    // },
  },
  trackingTableFilterArrow: {
    background: 'url(' + REACT_APP_S3_URL + 'filterDownIcon.svg)' + 'no-repeat 0.3rem -0.1rem',
    width: '24px',
    height: '24px',
    display: 'block',
    marginTop: '-3px',
    objectFit: 'contain',
  },
  trackingTableFilterTextHheading: {
    fontSize: '14px',
    color: '#ffffff',
    fontWeight: 500,
    backgroundColor: '#1a73e8',
    textAlign: 'left',
    verticalAlign: 'middle',
    fontStyle: 'Roboto',
    fontFamily: 'Roboto',
    height: '38px',
    paddingLeft: '15px',
    paddingTop: '10px'
  },
  trackingTableFilterDropdown: {
    background: '#ffffff',
    boxShadow: '0rem 0.06rem 0.125rem 0.01rem #D4BDBD',
    position: 'absolute',
    top: '1.5rem',
    left: '-159px',
    zIndex: 20,
    width: '250px',
    borderRadius: '0rem',

  },
  trackingTableCommonDropdownMenu: {
    maxHeight: '280px',
    overflowX: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar:horizontal': {
      height: '5px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#EBECED',
      borderRadius: '16px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#cacaca'
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#cacaca'
    },
    '& .MuiList-root': {
      listStyle: 'none !important',
      margin: '0 !important',
      paddingTop: '9px'
    }
  },
  trackingTableVerticalAlignSpan: {
    // verticalAlign: '-webkit-baseline-middle'
  },
  trackingTableTopbarDropdownfooter: {
    // marginBottom: '0.5rem',
  },
  trackingTableTopbarDropdownFooterText: {
    fontSize: '14px',
    float: 'right',
    cursor: 'pointer',
    paddingRight: '1.5rem',
    color: '#1a73e8',
    paddingTop: '9px',
    // paddingBottom: '0.5rem',
    fontWeight: 500,
    height: '38px'
  },
  trackingTableNoFilterFound: {
    fontSize: '20px',
    textAlign: 'center',
    color: '#111111',
    //padding: '0 0 0.5rem 0'
    fontFamily: 'Robotto'
  },
  /*TABLE DROPDOWN CSS END */

  /*ADD TRACKING DRAWER CSS START */
  drawerHeader: {
    width: '657px',
    // maxWidth: '825px',
    height: '48px',
    background: "#5e6367",
    // position: 'fixed',
    zIndex: 100
  },
  drawerTitle: {
    color: '#ffffff',
    padding: "10px 0px 8px 32px",
    fontSize: '20px',
    letterSpacing: '0.15px'
  },
  draweCancelButton: {
    marginLeft: 'auto',
    position: 'relative',
    // paddingRight: '29px'
    marginRight: '14px'
  },
  drawerContent: {
    // height: '86px',
    padding: "0px 32px 34px 32px",
  },
  drawerContentText: {
    height: '23px',
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    color: '#212121',
  },
  drawerTrackingTextFieldContainer: {
    // height: '86px',
    padding: "38px 24px 0 0px",
    display: 'inline-block'
  },
  drawerTextFieldContainer: {
    // height: '56px',
    padding: "0px 24px 65px 0px",
    display: 'inline-block'
  },
  trackingDrawerFooter: {
    position: 'absolute',
    bottom: '0px',
    width: '657px',
    backgroundColor: '#ffffff',
    zIndex: '100',
    borderTop : '1px solid #d9d9ec',
    height : '81px'
  },
  drawerTrackingField: {
    width: '282px',
    height: '58px',
    "& label.Mui-focused": {
      color: '#1a73e8'
    },
    "& .MuiOutlinedInput-root": {
      height: "58px",
      "&.Mui-focused fieldset": {
        borderColor: '#1a73e8'
      }
    }
  },
  footerMain: {
    width: '657px',
    height: '100px',
    position: 'fixed',
    left: '0',
    bottom: '0',
    background: 'black'
  },
  deleteButton: {
    // marginLeft: '25rem',
    background: "#ffffff",
    color: "#1a73e8",
    float: 'right',
    margin: "22px 29px 0px 0px",
    width: '100px',
    height: '36px',
    border: '1px solid #1a73e8',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px'
  },
  deleteButtonAction: {
    // marginLeft: '25rem',
    background: "#ffffff",
    color: "#1a73e8",
    float: 'right',
    margin: "22px 32px 0px 0px",
    width: '100px',
    height: '36px',
    border: '1px solid #1a73e8',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px'
  },
  deleteButtonDisable: {
    // marginLeft: '25rem',
    background: "#d3d3d3",
    color: "#1a73e8",
    float: 'right',
    margin: "22px 29px 0px 0px",
    width: '100px',
    height: '36px',
    border: '1px solid #1a73e8',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px'
  },
  error: {
    "&.MuiFormHelperText-root.MuiFormHelperText-contained.MuiFormHelperText-filled": {
      color: 'red'
    },
  },
  saveBtnRoot: {
    display: "flex",
    alignItems: "center"
  },
  wrapper: {
    position: "relative"
  },
  saveBtn: {
    background: '#1a73e8',
    marginTop: "22px",
    marginLeft :'32px',
    width: '88px',
    height: '36px',
    // margin: '32px 0px 32px 32px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#ffffff',
    "&:hover": {
      background: '#1a73e8'
    }
  },
  saveBtnDisable: {
    background: '#d3d3d3',
    marginTop: "22px",
    marginLeft :'32px',
    width: '88px',
    height: '36px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#ffffff',
    "&:hover": {
      background: '#d3d3d3'
    }
  },
  buttonProgress: {
    color: "primary",
    position: "absolute",
    top: "40%",
    left: "50%",
    marginLeft: '-16rem',
    marginTop: -5,
  },
  deleteTrackingProgress: {
    color: "primary",
    position: "absolute",
    top: "46%",
    left: "45%",
    marginLeft: '-16rem',
    marginTop: -5,
  },
  circularProgress: {
    color: "primary",
    position: "absolute",
    top: "50%",
    left: "86.5%",
    marginLeft: '-16rem',
    marginTop: -5,
  },
  addTrackingDrawer: {
    display: 'inline-block'
  },
  addTracingAutocoplete: {
    width: '350px',
    height: '58px'
  },
  addTrackingTextField: {
    height: '19px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.16px',
    color: '#666666',
    transform: 'translate(14px, 20px) scale(1)'
  },
  addTrackingDrawerPaper: {
    maxWidth : '657px',
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar:horizontal': {
      height: '5px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#EBECED',
      borderRadius: '16px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#cacaca'
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#cacaca'
    },
    '& .MuiList-root': {
      listStyle: 'none !important',
      margin: '0 !important',
      paddingTop: '9px'
    }
  },
  drawerSnackbar: {
    position : 'absolute',
    bottom : '59px'
  },
  /*ADD TRACKING DRAWER CSS END */

  /*DETAIL TRACKING DRAWER CSS START */
  detailTrackingDrawerPaper: {
    maxWidth: '662px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px',
    // zIndex: 100,
    position : 'absolute',
    borderLeft: 'none',
    // '&::-webkit-scrollbar': {
    //   width: '5px'
    // },
    // '&::-webkit-scrollbar:horizontal': {
    //   height: '5px'
    // },
    // '&::-webkit-scrollbar-track': {
    //   borderRadius: '10px'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#EBECED',
    //   borderRadius: '16px'
    // },
    // '&::-webkit-scrollbar-thumb:hover': {
    //   backgroundColor: '#cacaca'
    // },
    // '&::-webkit-scrollbar-thumb:active': {
    //   backgroundColor: '#cacaca'
    // },
    '& .MuiList-root': {
      listStyle: 'none !important',
      margin: '0 !important',
      paddingTop: '9px'
    }
  },
  detailDrawerHeader: {
    width: '657px',
    // ma`xWidth: '825px',
    height: '48px',
    background: "#5e6367",
    // position: 'fixed',
    // zIndex: 100,
    position: 'sticky',
    top : 0
  },
  DetaildrawerTrackingTextFieldContainer: {
    // height: '86px',
    padding: "30px 24px 0 0px",
    display: 'inline-block',
    // marginTop : '34px'
  },
  detailDrawerContent: {
    // height: '86px',
    padding: "38px 32px 25px 32px",
  },
  detailDrawerTitleIcon : {
    paddingLeft : '41px',
    position: 'relative',
  },
  detailDrawerTitle: {
    color: '#ffffff',
    padding: "15px 0px 8px 18px",
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
  },
  expandPanelRoot: {
    marginLeft : '34px !important',
    width: '593px',
    maxWidth: '593px',
    borderRadius: '4px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.25)',
    backgroundColor: '#ffffff',
    border: 'none',
    
    '& .MuiExpansionPanel-rounded:last-child':{
      borderBottomLeftRadius : '0px',
    borderBottomRightRadius : '0px',
    },
    '&::before' : {
      opacity : '0px',
      height : '0px',
    },
  },
  expandPanelDetail : {
    display : 'block',
    paddingTop : '0px',
    paddingLeft: '0px',
    paddingBottom: '0px'
  },
  expandPanelSummery : {
    // display : 'block',
    paddingLeft: '0px',
    marginTop : '19px',
    // paddingBottom : '19px',
    '& .MuiExpansionPanelSummary-content' :{
      display : 'block',
      paddingTop : '3px',
      margin : '16px 0px 0px 0px'
    },
    '& .MuiExpansionPanelSummary-content.Mui-expanded': {
      margin : '16px 0px 0px 0px'
    }
  },
  expandPanelLessButton: {
    cursor : 'pointer',
    display : 'inline-block',
    paddingLeft: '7px',
    paddingBottom : '5px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#1a73e8',
  },
  expandPanelCopyButton: {
    cursor : 'pointer',
    display : 'inline-block',
    paddingLeft: '42px',
    paddingBottom : '5px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#1a73e8',
  },
  /*DETAIL TRACKING DRAWER CSS END */
  /*TOOLBAR CSS END */
  exportDrawerRadioLable : {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.16px',
      color: '#5e6367',
  },
  exportListIcon : {
   
      // paddingLeft : '0px',
      minWidth : '36px',
      '& .PrivateSwitchBase-root-596' :{
        paddingLeft : '0px',
        paddingTop : '5px',
        paddingBottom : '5px'
      }
    },
  detailDrawerTitleTrackingNumber: {
    padding: "15px 0px 8px 18px",
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#ffffff',
    overflow: 'hidden',
    maxWidth:'250px',
    textOverflow: 'ellipsis',
    display:"inline"
  },
  detailDrawerTitleCourierName: {
    padding: "15px 0px 8px 3px",
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    color: '#ffffff',
    maxWidth: '250px',
    whiteSpace:'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    letterSpacing: '0.16px'
  },
  autoCompleteListRoot : {
    width : '200px'
  },
  clearIndicatorDirty : {
    display : 'none'
  },
  popupIndicator : {
    marginRight : "-10px"
  },
  keyboardDatePickeRoot : {
    width : "100%"
  }
}
export default styles