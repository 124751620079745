import React from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getAverageDaysToDeliver } from '../actions/actionMethods'
import { changeDateFunction } from '../common/Functions'
import { getCourierLabel, showCourierLogo } from '../../common/commonFunction'
import { averageDaysIcon } from '../../common/imageList'
import { getCarrierList, setCourierList } from '../../TrackingList/actions/actionMethods'
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Styles from '../static/Styles'
import DashboardCourierAutocomplete from "../common/DashboardCourierAutocomplete";
import DashboardPopover from "../common/DashboardPopover";

class AverageDaysToDeliver extends React.Component {
	constructor(props) {
		super(props)
		this.selectedCourier = null
		this.state = {
			toDate: '',
			fromDate: '',
			averageDaysToDeliverData: [],
			courierLoadingFlag: true,
			fromUTCDateValue: '',
			toUTCDateValue: ''
		}
	}
	componentWillMount() {
		getCarrierList().then(response => {
			if (response && response.data) {
				this.props.setCourierList(response.data)
			}
		})
		let dateObj = changeDateFunction('30Days', false)
		this.getAverageDays(dateObj.fromDate, dateObj.toDate)
		this.setState({ fromDate: dateObj.fromDate, toDate: dateObj.toDate })
	}
	getAverageDays = (fromDate, toDate) => {
		let data = {}
		data.from_date = fromDate
		data.to_date = toDate
		this.setState({ courierLoadingFlag: true })
		if (this.selectedCourier && this.selectedCourier !== null && this.selectedCourier.value !== 'all') {
			data.courier = this.selectedCourier.value
		}
		getAverageDaysToDeliver(data).then(res => {
			if (res.status >= 200 && res.status <= 299) {
				this.setState({
					averageDaysToDeliverData: res.data.data,
					courierLoadingFlag: false
				})
			} else {
				this.setState({ courierLoadingFlag: false })
			}
		}).catch(error => {
			console.log(error)
			this.setState({ courierLoadingFlag: false })
		})
	}

	setCustomDateRange = (fromUTCDate, toUTCDate) => {
		this.getAverageDays(fromUTCDate, toUTCDate)
		this.setState({
			fromUTCDateValue: fromUTCDate,
			toUTCDateValue: toUTCDate,
			fromDate: fromUTCDate,
			toDate: toUTCDate
		})
	}
	setDateType = (dateType, fromDate, toDate) => {
		this.setState({dateType: dateType, fromDate: fromDate, toDate:toDate, fromUTCDateValue: '', toUTCDateValue: ''})
	}
	handleChangeCourier = (selectedOption) => {
		this.selectedCourier = selectedOption
			let isCustomRangeDate = ((this.state.fromUTCDateValue && this.state.fromUTCDateValue !== '') && (this.state.toUTCDateValue && this.state.toUTCDateValue !== ''))
			if (isCustomRangeDate) {
				this.getAverageDays(this.state.fromUTCDateValue, this.state.toUTCDateValue)
			}
			else {
				this.getAverageDays(this.state.fromDate, this.state.toDate)
			}
	}

	render() {
		const { classes } = this.props
		let averageDaysToDeliverDataValue = []
		for (let i = 0; i < this.state.averageDaysToDeliverData.length; i++) {
			let element = this.state.averageDaysToDeliverData[i]
			if (i <= 9) {
				averageDaysToDeliverDataValue.push(
					<div className="topCourierInlineDiv" key={i}>
						<div className="courierNameLogo">
							<div className="courierLogo">
								<img src={showCourierLogo(element.courier_name)} alt={element.courier_name}></img>
							</div>
							<div className="courierName">
								<span>{getCourierLabel(element.courier_name, this.props.addedCourierList)}</span>
							</div>
						</div>
						<div className="noOfCourier">{element.avg_days_to_delivery_shipment}</div>
					</div>
				)
			}
		}
		return (
			<Card d="shipments" className="cardNormal" style={{ cursor: 'default', float: 'none', width: '99.5%', height: "auto", position: 'relative', bottom: '8px' }}>
				<CardHeader id="shipmentSummary" className="shipmentStatus"
					avatar={
						<Avatar>
							<img alt="" src={averageDaysIcon} />
						</Avatar>
					}
					action={
						<Grid className="MuiCardHeader-action" style={{ display: "inline-flex", float: 'right' }}>
							{
								this.props.addedCourierListValue && this.props.addedCourierListValue.length > 0 ?
									<div>
										<DashboardCourierAutocomplete
											addedCourierList={this.props.addedCourierListValue}
											handleChangeCourier={this.handleChangeCourier}
										/>
									</div> : ''
							}
							<DashboardPopover 
								type='AverageDaysToDeliver'
								getData={this.getAverageDays}
								setCustomDateRange={this.setCustomDateRange}
								setDateType={this.setDateType}
							/>
						</Grid>
					}
					title={<Typography className="headingText">Average Days To Deliver</Typography>}
				/>
				<CardContent className="topCourierListMainDiv" style={{ padding: '0px' }}>
					{this.state.courierLoadingFlag ? <div style={{ width: '400px', margin: '60px auto', opacity: '0.5' }}> <CircularProgress size={42} className={classes.progress} /> </div>
						: this.state.averageDaysToDeliverData.length > 0 ?
							<React.Fragment>
								{averageDaysToDeliverDataValue}
							</React.Fragment>
							: <div className='noData' style={{ lineHeight: "0rem" }}>No Data to Display.</div>}
				</CardContent>
			</Card>
		)
	}
}
const mapStateToProps = state => ({
	addedCourierList: state.tracking.list.addedTrackingCourierList
})
export default withRouter(connect(mapStateToProps, { getCarrierList, setCourierList })(withStyles(Styles, { withTheme: true })(AverageDaysToDeliver)))