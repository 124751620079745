import React from 'react';
import '../../assets/css/subuser.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table'
import Drawer from './UserAndRoleDrawer'
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import {emailRegex} from '../../../static/constants';
import FlashMessage from '../../common/FlashMessage';
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from '@material-ui/core/TablePagination'
import CircularProgress from '@material-ui/core/CircularProgress';
import {verifyIcon,notVerifiedIcon} from '../../../common/imageList';
import { RESEND_INVITE_MESSAGE, USER_ADDED_SUCCESSFULLY_MESSAGE, USER_UPDATED_SUCCESSFULLY_MESSAGE } from '../../common/constants';
import { getSubUsersList, createSubUser, getRolesList,getRoleById,updateSubUser, setSettingsFlashMessage, toggleSettingsFlashMessage } from '../../actions/actionMethods';
class SubUser extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess = true
        this.isLoading = false
        this.userCount = ''
        this.hasMore = ''
        this.state = {
            usersListObj: [],
            user_count: 0,
            page: 0,
            rowsPerPage: 15,
            roles: [],
            loader: true,
            userName: '',
            emailId: '',
            userNameError: false,
            emailIdError: false,
            selectedRole: '',
            selectedRoleError: '',
            showDrawerLoader: false,
            editSubUserId: '',
            onChange: false,
            userData: {},
            isEmailValid: true
        }
    }

    componentDidMount(){
         this.getUserList()
         this.getRoles()
    }
 
    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
        this.pagination(newPage + 1, this.state.rowsPerPage)
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        });
        this.pagination(1, event.target.value)
    };


    pagination = (page, rows) => {
        let hasMore = (this.state.page * this.state.rowsPerPage) < this.state.user_count
        this.props.paginationLoading(true)
        if (hasMore) {
            getSubUsersList(page, rows).then(res => {
                this.setState({
                    usersListObj: res.data.data
                })
                this.props.paginationLoading(false)
                let count = this.userCount - 1
                this.userCount = count
                if (this.userCount > 0) {
                    this.isLoading = false
                }
            }).catch(err => {
                this.props.paginationLoading(false)
                console.log(err)
            })
        }
    }

    resendInvite = async (userData) => {
        let resendInvitationParams = {
            resend: true,
            name: userData.name,
            email: userData.email
        }
        await getRoleById(userData.role_id).then(res => {
            if (res && res.data && res.data.meta && res.data.meta.code === 200) {
                resendInvitationParams.role_id = res.data.data._id
                resendInvitationParams.role_name = res.data.data.name
            }
        })
        createSubUser(resendInvitationParams).then(res => {
            this.isSuccess = true
            this.props.toggleSettingsFlashMessage(1)
            this.props.setSettingsFlashMessage(RESEND_INVITE_MESSAGE)
        }).catch(err => {
            console.log(err)
        })
    }

    getUserList = () => {
        let pageId = this.state.page + 1
        getSubUsersList(pageId, this.state.rowsPerPage).then(res => {
            this.setState({
                user_count: res.data.meta.total_records,
                usersListObj: res.data.data,
                showLink: res.data.meta.total_records > 0 ? true : false,
                loader: false,
            })
            this.props.isLoading(false)
            let userCount = Math.ceil((res.data.meta.total_records - this.state.rowsPerPage) / this.state.rowsPerPage)
            if (userCount > 0) {
                this.hasMore = true
            }
            this.userCount = userCount
        }).catch(err => {
            console.log(err)
        })
    }

    getRoles = () => {
        getRolesList().then(res => {
            let rolesArr = []
            if (res && res.data && res.data.meta && res.data.meta.code === 200) {
                const LIST_OF_ROLES = res.data.data
                LIST_OF_ROLES.map(eachRole => {
                    let eachOption = {}
                    eachOption.value = eachRole._id
                    eachOption.label = eachRole.name
                    rolesArr.push(eachOption)
                })
                this.setState({
                    roles: rolesArr
                })
            }
        }).catch(err => console.log(err))
    }

    checkValidation = () => {
        let errorCount = 0
        if (this.state.userName === '' || this.state.userName.trim().length === 0) {
            this.setState({
                userNameError: true,
                showDrawerLoader: false
            })
            errorCount++
        } else {
            this.setState({
                userNameError: false
            })
        }
        if (this.state.emailId === '' || this.state.emailId.trim().length === 0) {
            this.setState({
                emailIdError: true,
                showDrawerLoader: false
            })
            errorCount++
        } else {
            this.setState({
                emailIdError: false
            })
        }
        if (this.state.selectedRole === '') {
            this.setState({
                selectedRoleError: true,
                showDrawerLoader: false
            })
            errorCount++
        } else {
            this.setState({
                selectedRoleError: false
            })
        }
        if(!this.state.isEmailValid){
            errorCount++
            this.setState({showDrawerLoader: false})
        }
        if (errorCount > 0) {
            return false
        }
        return true
    }

    closeDrawer = () => {
        this.props.closeDrawer()
        this.clearFieldsValue()
    }

    clearFieldsValue = () => {
        this.setState({
            userName: '',
            emailId: '',
            userNameError: false,
            emailIdError: false,
            selectedRole: '',
            selectedRoleError: false,
            userData: {}
        })
    }

    saveSubUser = async () => {
        let subUserData = {}
        this.setState({
            showDrawerLoader: true
        })
        let validation = this.checkValidation()
        if (validation) {
            subUserData.name = this.state.userName
            subUserData.email = this.state.emailId
            subUserData.source = "Web"
            subUserData.role_name = this.state.selectedRole.label
            await getRoleById(this.state.selectedRole.value).then(res => {
                if (res && res.data && res.data.meta && res.data.meta.code === 200) {
                    subUserData.role_id = res.data.data._id
                    subUserData.department = res.data.data.department
                }
            })
            createSubUser(subUserData).then(res => {
                if (res && res.status >= 200 && res.status <= 299) {
                    this.isSuccess = true
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(USER_ADDED_SUCCESSFULLY_MESSAGE)
                    this.setState({
                        showDrawerLoader: false,
                    })
                    this.getUserList()
                    this.closeDrawer()
                } else {
                    this.isSuccess = false
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(res.data.meta.message)
                    this.setState({
                        showDrawerLoader: false,
                    })
                }
            }).catch(error => {
                this.isSuccess = false
                this.props.toggleSettingsFlashMessage(1)
                this.props.setSettingsFlashMessage(error)
                this.setState({
                    showLoader: false,
                    showDrawerLoader: false
                })
            })
        }
    }

    handleSelectedRole = (event, selectedRole) => {
        this.setState({
            selectedRole
        })
    }

    handleName = (value) => {
        this.setState({
            userName: value,
            userNameError: false
        })
    }

    handleEmail = (value) => {
         this.setState({
            emailId: value,
            emailIdError: false
        })
        if (!emailRegex.test(value)) {
            this.setState({isEmailValid: false})
        } else {
            this.setState({isEmailValid: true})
        }
    }

    editDrawer = () => {
        this.props.editDrawer()
    }

    handleEditSubUser = (userData) => {
        let selectedRole;
        let options = this.state.roles
        let index = options.findIndex(roleObj => roleObj.value === userData.role_id)
        if (index > -1) {
            selectedRole = options[index]
        }
        this.setState({
            onChange: true,
            userName: userData.name,
            emailId: userData.email,
            selectedRole: selectedRole,
            editSubUserId: userData._id,
            userData: userData
        }, () => {
            setTimeout(() => {
                this.setState({
                    onChange: false
                })
            }, 800)
        })
        this.editDrawer()
    }

    updateSubUser = async () => {
        let subUserData = {}
        this.setState({
            showDrawerLoader: true
        })
        let validation = this.checkValidation()
        let userId = this.state.editSubUserId
        if (validation) {
            subUserData.name = this.state.userName
            subUserData.email = this.state.emailId
            subUserData.source = "Web"
            subUserData.role_name = this.state.selectedRole.label
            await getRoleById(this.state.selectedRole.value).then(res => {
                if (res && res.data && res.data.meta && res.data.meta.code === 200) {
                    subUserData.role_id = res.data.data._id
                    if(res.data.data.department){
                        subUserData.department = res.data.data.department 
                    }
                }
            })
            updateSubUser(userId, subUserData).then(res => {
                if (res && res.status >= 200 && res.status <= 299) {
                    this.isSuccess = true
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(USER_UPDATED_SUCCESSFULLY_MESSAGE)
                    this.setState({
                        showDrawerLoader: false,
                        editSubUserId: '',
                    })
                    this.getUserList()
                    this.closeDrawer()
                } else {
                    this.isSuccess = false
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(res.data.meta.message)
                    this.setState({
                        showDrawerLoader: false,
                    })
                }
            }).catch(error => {
                this.isSuccess = false
                this.props.toggleSettingsFlashMessage(1)
                this.props.setSettingsFlashMessage(error)
                this.setState({
                    showDrawerLoaderr: false,
                    showDrawerLoader: false
                })
            })
        }
    }

 
    showSubUserData = (subUserList) => {
        const {classes} = this.props
        const { page, rowsPerPage, user_count } = this.state
            return(
                <Paper className={classes.paper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table className={classes.table} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={`${classes.headCell} ${classes.firstHeadAndBodyCell}`}>Name</TableCell>
                                    <TableCell align="left" className={classes.headCell}>Email</TableCell>
                                    <TableCell align="left" className={classes.headCell}>Role</TableCell>
                                    <TableCell align="left" className={classes.headCell}>Department</TableCell>
                                    <TableCell align="left" className={`${classes.headCell} ${classes.lastHeadAndBodyCell}`}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    subUserList.length > 0 ? 
                                    subUserList.map((subUser, index) => {
                                        return (
                                                <TableRow key={index}>
                                                    <TableCell align="left" className={`${classes.bodyCell} ${classes.firstHeadAndBodyCell}`}>
                                                        <div style={{ color: "#1a73e8", fontSize: "14px", textDecoration: 'none', cursor: 'pointer' }} onClick={(roleData) => this.handleEditSubUser(subUser)} >
                                                            {subUser.name}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.bodyCell}>
                                                        <div style={{ color: "#1a73e8", fontSize: "14px", textDecoration: 'none', cursor: 'pointer' }} onClick={(roleData) => this.handleEditSubUser(subUser)} >
                                                            {subUser.email}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.bodyCell}>
                                                        {subUser.role_name ? subUser.role_name : ""}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.bodyCell}>
                                                        {subUser.department ? subUser.department : ''}
                                                    </TableCell>
                                                    <TableCell align="left" className={`${classes.headCell} ${classes.lastHeadAndBodyCell}`}>
                                                        <img src={subUser.invite === 1 ? verifyIcon : notVerifiedIcon} alt={subUser.invite === 1 ? 'Accepted' : 'Pending'} className="emailImage" />
                                                        <span style={{ margin: '5px 0px 0px 5px', verticalAlign: 'top', lineHeight: '25px' }} >{subUser.invite === 1 ? 'Accepted' : 'Pending'}</span>
                                                    </TableCell>
                                                </TableRow>
                                        )
                                    })
                                    : <div className="noData" style={{ position: "absolute", padding: "2rem", left: '45%' }}>
                                        No Sub Users.
                                    </div>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination className={classes.pagination}
                        classes={{
                            select: classes.paginationTextAlign,
                        }}
                        rowsPerPageOptions={[15, 25, 35]}
                        component="div"
                        count={user_count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>
            )
    }

    toggleFlashMessage = (data) => {
        this.props.toggleSettingsFlashMessage(data)
        this.props.setSettingsFlashMessage('')
    }

    render() {
        const { classes } = this.props
        return (
            <div id="settingDataScrollerWrapper">
                <div className="settingsContainer">
                    <div className="settingsRightSidePanel">
                        <div className="commonSettingsBox subUserStyleMainDiv">
                            {/* <Scrollbars autoHide style={scrollbar}> */}
                                <div className="setting-box-content-con">
                                    {this.state.loader ?
                                        <CircularProgress size={62} className={classes.tabProgress} />
                                        : this.showSubUserData(this.state.usersListObj)}
                                </div>
                            {/* </Scrollbars> */}
                        </div>
                        <Drawer
                            openDrawer={this.props.open}
                            closeDrawer={this.closeDrawer}
                            classes={classes}
                            type={this.props.type}
                            userName={this.state.userName}
                            emailId={this.state.emailId}
                            userNameError={this.state.userNameError}
                            emailIdError={this.state.emailIdError}
                            selectedRoleError={this.state.selectedRoleError}
                            handleName={this.handleName}
                            handleEmail={this.handleEmail}
                            handleSelectedRole={this.handleSelectedRole}
                            selectedRole={this.state.selectedRole}
                            saveSubUser={this.saveSubUser}
                            roles={this.state.roles}
                            isEditRole={this.props.isEditRole}
                            updateSubUser={this.updateSubUser}
                            isLoading={this.state.showDrawerLoader}
                            onChange={this.state.onChange}
                            userData = {this.state.userData}            
                            resendInvite={this.resendInvite}
                            isEmailValid = {this.state.isEmailValid}
                        />
                        {this.props.showSettingFlashMessageFlag === 1 ?
                                <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                        : ''}
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, {setSettingsFlashMessage,toggleSettingsFlashMessage })(SubUser))