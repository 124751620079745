import React from 'react';
import './static/css/app.scss';
import { connect } from 'react-redux'
import Header from './container/Header'
import { withRouter } from 'react-router-dom'
import MainRoutes from './container/MainRoutes';
import PageNotFound from './container/PageNotFound'
import * as constant from './TrackingList/common/constants'
import { userLogout, getFirebaseToken, clearRoles } from './Authentication/auth'
import { updateRoleData, getRoleById } from './Setup/actions/actionMethods'
import TrackhivePublicPage from './Setup/components/customTrackingPage/TrackingPublicPage'
import TrackingPageWithSearch from './Setup/components/customTrackingPage/TrackingPageWithSearch'

const mapStateToProps = state => {
  return {
    isLoggedIn: state.authenticate.isLoggedIn,
    roles: state.authenticate.roles
  }
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showFlag : false
    }
  }

 async UNSAFE_componentWillMount() {
   let userType = localStorage.getItem('user_type') !== undefined && localStorage.getItem('user_type') !== '' ? (localStorage.getItem('user_type')) : ''
   let roleId = localStorage.getItem('role_id') !== undefined && localStorage.getItem('role_id') !== "" ? localStorage.getItem('role_id') : ""
   if (userType === "subuser") {
     this.setState({
       showFlag: false
     })
     if (roleId) {
       getRoleById(roleId).then(res => {
         if (res && res.data && res.data.meta && res.data.meta.code === 200) {
           this.props.updateRoleData(res.data.data.role)
           localStorage.setItem('role_access', res.data.data.role ? JSON.stringify({"role":res.data.data.role}) : '')
           this.setState({
             showFlag: true
           })
         }
       })
     }
   } else {
     this.setState({
       showFlag: true
     })
   }
 }

  logout = () => {
    this.props.userLogout(path => {
      this.props.clearRoles()
      this.props.history.push(path)
    })
  }
  componentDidMount() {
    /** 
     * javascript comment 
     * @Author: Akshay Tukadiya
     * @Date: 2020-01-21 10:53:00 
     * @Desc: Get Firebase token if user is already loggedin.
     */
    if (this.props.isLoggedIn && !localStorage.getItem('firebaseToken')) {
      getFirebaseToken(JSON.parse(localStorage.getItem("trackhiveToken")) ? JSON.parse(localStorage.getItem("trackhiveToken"))._id : '')
    }
    if (!localStorage.getItem('trackingColumn') || localStorage.getItem('trackingColumn') == undefined || localStorage.getItem('trackingColumn') == null || localStorage.getItem('trackingColumn') == '') {
      localStorage.setItem('trackingColumn', JSON.stringify(constant.COLUMN_ARRAY))
    }
  }
 
  render() {

    if (this.state.showFlag) {
      let rightPanelStyle = {}
      if (this.props.isLoggedIn && !this.props.history.location.pathname.includes('/custom_tracking_page/preview')) {
        rightPanelStyle = {
          float: "left",
          // marginLeft: "73px",
          width: "calc(100% - 73px)",
          height: "100vh",
          marginTop: '48px'
        }
      } else {
        rightPanelStyle = {
          float: "none",
          marginLeft: "0",
          width: "100%",
          height: "100vh"

        }
      }
      if (this.props.history.location.pathname.includes('trackingPage') || this.props.history.location.pathname.includes('trackingButton')) {
        rightPanelStyle = {
          float: "none",
          marginLeft: "0",
          width: "100%",
          height: "100vh",
          background : 'none'
        }
      }
      let urlValue = window.location.href
      let isAllowedFlag = true
      if (urlValue.includes('localhost')) {
        isAllowedFlag = false
      }
      let isURLExist = true
      if (urlValue.includes('trackinghive') || urlValue.includes('ngrok') || urlValue.includes('trackhive-dev.s3-website.us-east-2.amazonaws.com') || urlValue.includes('trackhive-staging.s3-website.us-east-2.amazonaws.com')) {
        isURLExist = false
      }
      if ((isURLExist) && isAllowedFlag) {
        if (!urlValue.includes('trackingPage')) {
          return (
            <div className="applicationMainWrapper">
              <PageNotFound />
            </div>
          )
        }
        else {
          if (this.props.history.location.pathname === '/trackingPage' || this.props.history.location.pathname === '/trackingPage/' || this.props.history.location.pathname === '/trackingButton' ) {
              return (
                <div className="applicationMainWrapper">
                  <section id="applicationRightPanel" style={rightPanelStyle}>
                    <TrackingPageWithSearch />
                  </section>
                </div>
              )
          }
          else {
              return (
                <div className="applicationMainWrapper">
                  <section id="applicationRightPanel" style={rightPanelStyle}>
                    <TrackhivePublicPage />
                  </section>
                </div>
              )
          }
        }
      }
      else {
        return (
          <div className="applicationMainWrapper">
            {
              this.props.history.location.pathname.includes('/shopifyApp/install') ||
                this.props.history.location.pathname.includes('/shopify_to_trackhive_token') ||
                this.props.history.location.pathname.includes('/custom_tracking_page/preview') ||
                this.props.history.location.pathname.includes('/trackingPage') ||
                this.props.history.location.pathname.includes('/trackingButton')
                ?
                null :
                this.props.isLoggedIn ? <Header logout={this.logout} /> : null
            }
            <section id="applicationRightPanel" style={rightPanelStyle}>
              <MainRoutes />
            </section>
          </div>
        );
      }
    }
    else{
      return("")
    }
  }
}

export default withRouter(connect(mapStateToProps, {userLogout,updateRoleData, clearRoles})(App));