import React from 'react'
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { withStyles } from '@material-ui/core/styles';
import Link from 'react-router-dom/Link';
import Styles from '../../assets/css/Styles';

function integrationCard(props) {
    const { classes } = props
    return (
        <Link to={props.channelPath} style={{ textDecoration: "none" }}>
            <Card className={classes.IntegrationCard}>
                <CardActionArea className={classes.IntegrationCardAction}>
                    <CardMedia
                        component="img"
                        className={classes.IntegrationMedia}
                        image={props.icon}
                        title={props.title}
                    />
                    <CardContent className={classes.integrationContent}>
                        <Typography gutterBottom className={classes.integrationTitle} variant="body1" component="p">
                            {props.title}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>
    )
}

export default withStyles(Styles)(integrationCard)