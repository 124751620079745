import React from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import axios from 'axios'
import jwt from 'jsonwebtoken'
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment-timezone'
import {getTrackingStatusName} from '../../../common/commonFunction'
import queryString from 'query-string'
import {httpRegex} from '../../../static/constants'
const trackingSecretKey = process.env.REACT_APP_JWT_SECRET_KEY
const prefixURL = process.env.REACT_APP_API_URL

class TrackingPublicPage extends React.Component {
        constructor(props) {

            super(props);
            this.state = {
                htmlValue: '',
                trackingsData: [],
                loader: true,
                noTrackingMessage: '',
                trackingID: '',
                currentStatus: '',
                searchBy: 'tracking_number'
            };
        }

        // componentDidMount() {
        //     setTimeout(() => {
        //         this.autoSubmit()
        //     }, 500);
        // }

        autoSubmit = () => {

            let str = window.location.href
            let splitValued = str.split('/')
            if ((splitValued[4] !== undefined && splitValued[4] !== '')) {
                let searchNumber = splitValued[4].split('?')
                if (searchNumber[1] && searchNumber[1] !== '') {
                    let urlParams = queryString.parse(searchNumber[1])
                    if (urlParams.searchBy && urlParams.searchBy === 'order_number') {
                        this.setState({
                            searchBy: 'order_number'
                        })
                    }
                }
                this.setState({
                    trackingID: searchNumber[0]
                }, () => {
                    this.recaptcha.execute()
                })
            }
        }

        onResolved = () => {
            let result = window.location.href.replace(httpRegex, '$1')
            let requestBody = {}
            requestBody.domain_name = result
            if (this.state.searchBy === 'order_number') {
                requestBody.order_number = this.state.trackingID
            } else {
                requestBody.tracking_number = this.state.trackingID
            }
            let signedToken = jwt.sign({
                payload: requestBody
            }, trackingSecretKey, {
                expiresIn: 60
            })

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${signedToken}`
            }
            axios.post(`${prefixURL}/tracking_page?rc_token=${this.recaptcha.getResponse()}`, requestBody, {
                headers: headers
            }).then(response => {
                this.setState({
                    loader: false
                })
                if (response.data) {
                    this.setState({

                        htmlValue: response.data.data.body,
                        trackingsData: response.data.data.checkpoints,
                        currentStatus: response.data.data.current_status
                    })
                }
            }).catch(error => {
                if (error.response && error.response.data) {
                    if (error.response && !(error.response.status >= 200 && error.response.status <= 299)) {
                        this.setState({
                            loader: false,
                            noTrackingMessage: error.response.data.meta.message[0]
                        })
                    }
                }
            })
        }

        prepareTimeLineData = (trackingCheckpoints) => {
            let preparedArray = []
            if (trackingCheckpoints && trackingCheckpoints.length > 0) {
                for (let checkpointIndex = 0; checkpointIndex < trackingCheckpoints.length; checkpointIndex++) {
                    let accessEachTrackingCheckpoint = trackingCheckpoints[checkpointIndex]
                    let eachCheckpointObject = {}
                    let dateTime = accessEachTrackingCheckpoint.checkpoint_time === null ? ['', ''] : moment(accessEachTrackingCheckpoint.checkpoint_time).format('YYYY-MM-DD' + ' HH:mm:ss').split(' ')
                    let dataToDisplay = accessEachTrackingCheckpoint.message
                    eachCheckpointObject.tag = accessEachTrackingCheckpoint.tag
                    eachCheckpointObject.location = accessEachTrackingCheckpoint.location
                    eachCheckpointObject.date = dateTime
                    eachCheckpointObject.data = dataToDisplay
                    preparedArray.push(eachCheckpointObject)
                }
                return preparedArray
            }
        }

        setBackgroundColor = (currentStatusValue) => {

            let backgroundColor = ''

            switch (currentStatusValue) {
                case 'Delivered':
                    backgroundColor = '#54BB83'
                    break;
                case 'InfoReceived':
                    backgroundColor = '#3690f7'
                    break;
                case 'Expired':
                    backgroundColor = '#606e7d'
                    break;
                case 'OutForDelivery':
                    backgroundColor = '#f551b0'
                    break;
                case 'InTransit':
                    backgroundColor = '#65aee0'
                    break;
                case 'Exception':
                    backgroundColor = '#f97e2b'
                    break;
                case 'Pending':
                    backgroundColor = '#cccccc'
                    break;
                case 'FailedAttempt':
                    backgroundColor = '#e95655'
                    break;

                default:
                    break;
            }
            return backgroundColor
        }

    render() {
        let trackingsTimeLineData = []
        let replacedValue = ''
        let dataToDisplay = []
        if (this.state.trackingsData && this.state.trackingsData.length > 0) {
            trackingsTimeLineData = this.prepareTimeLineData(this.state.trackingsData)
            trackingsTimeLineData.map((element, indexValue) => {
                let statusType = ''
                let paddingStyle
                let location = ''
                let lastIndex = trackingsTimeLineData.length - 1
                let backGroundPositionStyle
                if (indexValue === lastIndex) {
                    backGroundPositionStyle = '160px 0px'
                }
                else {
                    backGroundPositionStyle = '-125px'
                }
                if (indexValue === 0)
                    paddingStyle = { paddingTop: 0, backgroundPosition: backGroundPositionStyle }
                else if (indexValue === trackingsTimeLineData.length - 2)
                    paddingStyle = { paddingBottom: '32px', backgroundPosition: backGroundPositionStyle }
                else if (indexValue === trackingsTimeLineData.length - 1)
                    paddingStyle = { paddingTop: 0, paddingBottom: '2.5rem', backgroundPosition: backGroundPositionStyle }
                else
                    paddingStyle = {}
                if (indexValue === lastIndex) {
                    // paddingStyle += {backgroundPosition : '160px 0px'}
                }
                if (element.tag === 'InTransit') {
                    statusType = 'InTransit'
                }
                else if (element.tag === 'Delivered') {
                    statusType = 'Delivered'
                }
                else if (element.tag === 'Pending') {
                    statusType = 'Pending'
                }
                else if (element.tag === 'OutForDelivery') {
                    statusType = 'OutForDelivery'
                }
                else if (element.tag === 'InfoReceived') {
                    statusType = 'InfoReceived'
                }
                else if (element.tag === 'FailedAttempt') {
                    statusType = 'FailedAttempt'
                }
                else if (element.tag === 'Expired') {
                    statusType = 'Expired'
                }
                else if (element.tag === 'Exception') {
                    statusType = 'Exception'
                }

                if(element.location !== null && element.location !== ''){
                    location = element.location
                }
                else{
                    location = ' — '
                }
                dataToDisplay.push(
                    '<tr><td class="trackingTimelineDateTimeValue" style=' + paddingStyle + '><p>' + element.date[0] + '</p><p class="time">' + element.date[1] + '</p></td><td class="trackingTimelineStatusIcon" style= ' + paddingStyle + '><div class=' + statusType + '></div></td><td class="trackingTimelineInfo" style= ' + paddingStyle + '><div class="itemLocation"> ' + location + '</div><div class="trackingMessage"> ' + element.data + '</div></td></tr>'
                )
            })
        }
        let convertedString = '<div class="currentStatusMainDiv" style="background:' + this.setBackgroundColor(this.state.currentStatus) + '"><div class="statusText"> CURRENT STATUS </div> <div class="statusValue">' + getTrackingStatusName(this.state.currentStatus) + ' </div></div><div class="trackingHistoryTimelineInnerDiv"><table><tbody>' + dataToDisplay.reverse().join('') + ' </tbody> </table></div>'
        replacedValue = this.state.htmlValue.replace('[Tracking Timeline]', convertedString)
        
        let returnValue = ''
        if (this.state.loader) {
            returnValue =
                <div className="trackingLoaderDiv">
                    <img src="https://trackhive-images.s3-ap-southeast-1.amazonaws.com/TrackingDataLoader.svg" alt="data_loader"/>
                </div>

        }
        else {
            if ((this.state.htmlValue && this.state.htmlValue !== '') || (this.state.trackingsData && this.state.trackingsData.length > 0)) {
                returnValue = <React.Fragment>
                    <div className="trackingPublicPageMainContainerDiv">
                        <div className="trackingPublicPageBodyMainDiv">
                            {ReactHtmlParser(replacedValue)}
                        </div>

                    </div>
                </React.Fragment>
            }
            else {
                returnValue = <div className="noTrackingDataStyleDiv">{this.state.noTrackingMessage}</div>
            }
        }
        return (
            <div>
                <Recaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey="6LeKRLgUAAAAAHqADRF2PFRefsFTYCo61c_PY5mj"
                    onResolved={this.onResolved}
                    onLoaded={this.autoSubmit}
                />
                {returnValue}
            </div>
        )
    }
}
export default TrackingPublicPage;
