import React from 'react'
import { saveIcon, cancelIcon, BackIcon, DoneIcon } from '../../../src/common/imageList'
import Button from '@material-ui/core/Button'
import { CloseIcon } from '../../common/imageList'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    cancelButton: {
        marginLeft: '2rem',
        background: "#ffffff",
        color: "#1a73e8",
        float: 'right',
        width: '100px',
        height: '36px',
        border: '1px solid #1a73e8',
    },
    nextBtn: {
        color: 'white',
        background: '#1a73e8',
        fontSize: '14px',
        letterSpacing: '0.14px',
        width: '88px',
        height: '36px',
        "&:hover": {
            background: '#1a73e8'
        }
    }
});


const Footer = ({ profileVisible, isOrganizationVisible, storeAction, storeActionVisible, actionVisible }) => {
    const classes = useStyles();
    if (profileVisible) {
        return (
            <footer>
                <div className="centerItems">
                    <a className="bottomPrimaryButton" onClick={e => {
                        e.preventDefault()
                        document.getElementById('settingSubmitBtn').click()
                    }}
                        href="">
                        <span>
                            <img src={saveIcon} alt="Save" />
                            <span className="buttonText">Save</span>
                        </span>
                    </a>
                </div>
            </footer>
        )
    }

    else if (isOrganizationVisible) {
        return (
            <footer>
                <div className="centerItems">
                    <a className="bottomPrimaryButton" onClick={e => {
                        e.preventDefault()
                        document.getElementById('organizeSubmitBtn').click()
                    }}
                        href="">
                        <span>
                            <img src={saveIcon} alt="Save" />
                            <span className="buttonText">Save</span>
                        </span>
                    </a>
                </div>
            </footer>
        )
    }
    else if (storeAction) {
        return (
            <footer>
                <div className="centerItems">
                    <Button
                        onClick={e => {
                            e.preventDefault()
                            document.getElementById('store-next-btn').click()
                        }}
                        variant="contained"
                        className={classes.nextBtn}
                    >
                        NEXT
                    </Button>
                    <Button startIcon={<CloseIcon />} onClick={e => {
                        e.preventDefault()
                        document.getElementById('store-cancel-btn').click()
                        }}
                        variant="contained"
                        className={classes.cancelButton}
                    >
                        CANCEL
                    </Button>
                </div>
            </footer>
        )
    }
    else if (storeActionVisible) {
        return (
            <footer>
                <div className="centerItems">
                    <a className="bottomPrimaryButton" onClick={e => {
                        e.preventDefault()
                        document.getElementById('store-done-btn').click()
                    }}
                        href="">
                        <span>
                            <img src={DoneIcon} alt="Save" />
                            <span className="buttonText">Done</span>
                        </span>
                    </a>
                    <a className="bottomPrimaryButton" onClick={e => {
                        e.preventDefault()
                        document.getElementById('store-back-btn').click()
                    }}
                        href="">
                        <span>
                            <img src={BackIcon} alt="Save" />
                            <span className="buttonText">Back</span>
                        </span>
                    </a>
                    <a className="bottomPrimaryButton" onClick={e => {
                        e.preventDefault()
                        document.getElementById('store-cancel-btn').click()
                    }}
                        href="">
                        <span>
                            <img src={cancelIcon} alt="Cancel" />
                            <span className="buttonText">Cancel</span>
                        </span>
                    </a>
                </div>
            </footer>
        )
    }
    else if (actionVisible) {
        return (
            <footer>
                <div className="centerItems">
                    <a className="bottomPrimaryButton" onClick={e => {
                        e.preventDefault()
                        document.getElementById('store-save-btn').click()
                    }}
                        href="">
                        <span>
                            <img src={saveIcon} alt="Save" />
                            <span className="buttonText">Save</span>
                        </span>
                    </a>
                    <a className="bottomPrimaryButton" onClick={e => {
                        e.preventDefault()
                        document.getElementById('store-cancel-btn').click()
                    }}
                        href="">
                        <span>
                            <img src={cancelIcon} alt="Cancel" />
                            <span className="buttonText">Cancel</span>
                        </span>
                    </a>
                </div>
            </footer>
        )
    }
}

export default (Footer)