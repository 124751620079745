import React from 'react'
import ErrorBoundary from './ErrorBoundary'
import loadable from '@loadable/component'
import omit from 'lodash/omit'
class LoadComponent extends React.Component {

    shouldComponentUpdate(nextProps) {
        return (this.props.page !== nextProps.page || this.props.scheduleHour !== nextProps.scheduleHour || this.props.scheduleOption !== nextProps.scheduleOption);
    }

    render() {
        let AsyncComponent = null
        if (this.props.type === 'action') {
            AsyncComponent = loadable(() => import(`../components/actionType/${this.props.page}`))
        } else {

            AsyncComponent = loadable(() => import(`../components/conditionType/${this.props.page}`))
        }
        const newProps = omit(this.props, 'classes');
        return (
            <ErrorBoundary>
                <AsyncComponent {...newProps} />
            </ErrorBoundary>
        )
    }
}

export default LoadComponent