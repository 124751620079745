import React from 'react'
import '@firebase/database';
import '@firebase/auth';
import clsx from 'clsx';
import '../static/css/app.scss';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import { Grid } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import NotificationBell from './notificationBell';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem'
import NotificationBlock from './notificationBlock';
import { Link, withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Styles from '../../src/static/css/HeaderStyle';
import ListItemLink from '@material-ui/core/ListItem';
import CssBaseline from '@material-ui/core/CssBaseline'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { menuIcon, profileIcon } from '../common/imageList'
import { menuItems, menuItems1, menuItems2, itemsmenu } from './menuItems';
import { Scrollbars } from 'react-custom-scrollbars';

const mapStateToProps = state => {
    return {
        notify_snapshot: state.settings.list.notify_snapshot,
        isLoggedIn: state.authenticate.isLoggedIn,
        roles: state.authenticate.roles
    }
}

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            unread_notify: false,
            open: false,
            anchorEl: null,
            menuItems: []
        }
    }

    componentWillMount(){
	/*try {           
	    	window.inspectlet_identity();      
	  } catch (e) {
		console.log(e)
	 }*/
        let userType = localStorage.getItem('user_type') !== undefined && localStorage.getItem('user_type') !== '' ? (localStorage.getItem('user_type')) : ''      
        let roles = this.props.roles && this.props.roles.length > 0 ? this.props.roles : []      
        let menuIcons = []
        
        if(roles.length === 0 && userType !== 'subuser'){
            menuIcons = menuItems          
        }
        else if(roles.length > 0){          
          menuItems.map(item => {             
             if(roles.includes(item.roleValue)){                
                return menuIcons.push(item)
             }   
            })                       
        }
        this.setState({menuItems:menuIcons})        
    }

    isPathSelected = (path) => {
        const historyPath = this.props.history.location.pathname;
        return historyPath
            && ((historyPath === path) || (historyPath.length > 1) && (path.length) > 1 && historyPath.startsWith(path)
            );
    }

    handleDrawerOpen = () => {
        this.setState({ open: true })
    };

    handleDrawerClose = () => {
        this.setState({ open: false })
    };
    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    getUserName = () => {
        return localStorage.getItem('ProfileName');
    }
    getEmail = () => {
        let userObject = JSON.parse(localStorage.getItem('trackhiveToken'))
        return userObject.email
    }
    unread_notify = () => {
        this.setState({ unread_notify: true })
    }
    hide_unread_notify = () => {
        this.setState({ unread_notify: false })
    }

    getImage = () => {
        let src = ""
        if (JSON.parse(localStorage.getItem('trackhiveToken'))) {
            if (JSON.parse(localStorage.getItem('trackhiveToken')).organization_details && Object.keys(JSON.parse(localStorage.getItem('trackhiveToken')).organization_details).length > 0) {
                let detail = JSON.parse(localStorage.getItem('trackhiveToken')).organization_details
                if (detail.logo && detail.logo !== null && detail.logo !== '') {
                    src = detail.logo
                    return src
                } else {
                    return src
                }
            } else {
                return src
            }
        } else {
            return src
        }
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null })
    };

    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ open: open })
    };

    render() {
        const { classes } = this.props
        const isMenuOpen = Boolean(this.state.anchorEl)
        let content = null
        let active = false;
        let classname
        let state
        let path
        const menuId = 'primary-search-account-menu';
        let paths = this.isPathSelected
        let processtitle = itemsmenu && itemsmenu.length > 0 ? itemsmenu.filter(function (item) {
            let titlePath = item.paths.replace(/'/g, '"');
            titlePath = JSON.parse(titlePath);
            if (titlePath.some(paths)) {
                return item.title
            }
        }) : [];
        let title = processtitle && processtitle.length > 0 ? processtitle[0].title : ''
        const renderMenu = (
            <Menu
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={menuId}
                keepMounted
                style={{ position: 'absolute', marginTop: 30 }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={e => {
                    e.preventDefault()
                    this.handleMenuClose()
                    this.props.history.push('/settings_profile')
                }}>View Profile</MenuItem>
                <MenuItem onClick={e => {
                    e.preventDefault()
                    this.handleMenuClose()
                    this.props.logout()
                }}>Logout</MenuItem>
            </Menu>
        );
        return (
            <div
                className={classes.root} >
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: this.state.open,
                    })}
                >
                    <Toolbar variant="dense">
                        <div className="menuButtonHeader">
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.toggleDrawer(true)}
                                edge="start"
                                className={clsx(classes.menuButton, {
                                    [classes.hide]: this.state.open
                                })}
                            >
                                <img alt="" src={menuIcon} />
                            </IconButton>
                        </div>
                        <Typography className={classes.titleText} noWrap>
                            {title}
                        </Typography>
                        <div className={classes.grow} />
                        <div>
                            <NotificationBell unread_notify={this.state.unread_notify} hide_unread_notify={this.hide_unread_notify} />
                            <div style={{ 'display': 'block' }}>
                                <NotificationBlock unread_notify={this.unread_notify} />
                            </div>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                style={{ marginLeft: 138 }}
                                onClick={this.handleProfileMenuOpen}
                                color="inherit"
                            >
                                <img alt="" src={profileIcon} />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMenu}
                <div style={this.state.open ? { width: '73px' } : null}>
                    <Drawer
                        variant={this.state.open ? "temporary" : "permanent"}
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: this.state.open,
                            [classes.drawerClose]: !this.state.open,

                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: this.state.open,
                                [classes.drawerClose]: !this.state.open,
                                [classes.override]: !this.state.open
                            }),
                        }}
                        open={this.state.open}
                        onClose={this.toggleDrawer(false)}
                        BackdropProps={{ invisible: true }}
                    >
                        <Grid container className={this.state.open ? null : classes.hide}>
                            <Avatar
                                src={this.getImage()}
                                className={classes.bigAvatar}
                            />
                        </Grid>
                        <Typography className={this.state.open ? classes.labelText : classes.hide} noWrap>
                            {this.getUserName()}
                        </Typography>
                        <Typography className={this.state.open ? classes.emailText : classes.hide} noWrap>
                            {this.getEmail()}
                        </Typography>
                        <Divider className={!this.state.open ? classes.hide : ''} />
                        <Scrollbars 
                        renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                        renderView={props => (
                            <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
                        )}
                        style={{ height: window.innerHeight - 48, clear: "both" }} 
                        autoHide={true} >
                        <List className={this.state.open ? classes.listopen : classes.topListclose}>
                            {
                                this.state.menuItems && this.state.menuItems.length > 0 ?
                                    this.state.menuItems.map(item => {
                                        switch (Number(item.type)) {
                                            case 1:
                                                path = item.paths.replace(/'/g, '"')
                                                path = JSON.parse(path);
                                                state = this.state.open
                                                classname = state ? classes.linkText : classes.linkTextClose;
                                                active = path.some(this.isPathSelected);
                                                let activeStyle
                                                if (active === true) {
                                                    activeStyle = { backgroundColor: '#e3eefc' }
                                                } else {
                                                    activeStyle = {}
                                                }
                                                content = path && path.length > 0
                                                    ? <Link className={this.state.open ? classes.link : classes.closelink} style={activeStyle} to={path[0]} onClick={this.toggleDrawer(false)}>
                                                        <ListItemIcon>
                                                            <Icon className={this.state.open ? classes.imageIcon : classes.imageIconClose}>
                                                                <img alt="" src={active ? item.activeIcon : item.icon} />
                                                            </Icon>
                                                        </ListItemIcon>
                                                        <div className="textWrapper">
                                                            <ListItemText
                                                                disableTypography
                                                                className={classname}
                                                                primary={item.title}
                                                                style={active ? { color: '#1a73e8' } : { color: '#727272' }}
                                                            ></ListItemText>
                                                        </div>
                                                    </Link>
                                                    : <a >
                                                        <img src={item.icon} alt="" title={item.title} />
                                                    </a>;
                                                break;
                                            case 2:
                                                content = null;
                                                break;
                                            default:
                                                content = null;
                                                break;
                                        }
                                        return (
                                            <div key={item.title+item.type} className="headerWrapper">
                                                <ListItem className={`${this.state.open ? classes.listitem : classes.closelistitem}`}>
                                                    {content}
                                                </ListItem>
                                            </div>
                                        )
                                    })
                                    : ''
                            }
                        </List>
                       
                        <Divider className={!this.state.open ? classes.hide : ''} />
                        <List className={this.state.open ? classes.listopen : classes.listclose} >
                            {
                                menuItems1 && menuItems1.length > 0 ?
                                    menuItems1.map(item => {
                                        switch (Number(item.type)){
                                            case 2:
                                                content = null;
                                                break;
                                            case 3:
                                                path = item.paths.replace(/'/g, '"')
                                                path = JSON.parse(path);
                                                state = this.state.open
                                                classname = state ? classes.fixlinkText : classes.linkTextCloseSidebar;
                                                let buttonClassName = state ? classes['button-link-drawer-open'] : classes['button-link-drawer-close'];
                                                active = path.some(this.isPathSelected);
                                                let activeStyle
                                                if (active === true) {
                                                    activeStyle = { backgroundColor: '#e3eefc' }
                                                } else {
                                                    activeStyle = {}
                                                }
                                                content = path && path.length > 0
                                                    ? <div className="headerWrapper"><ListItemLink button className={buttonClassName} style={activeStyle} component="a" href={path[0]} target="_blank" onClick={this.toggleDrawer(false)}>
                                                        <ListItemIcon>
                                                            <Icon className={this.state.open ? classes.imageIcon : classes.imageIconCloseSideBar}>
                                                                <img alt="" src={active ? item.activeIcon : item.icon} />
                                                            </Icon>
                                                        </ListItemIcon>
                                                        <div className="textWrapper">
                                                            <ListItemText
                                                                disableTypography
                                                                className={classname}
                                                                primary={item.title}
                                                                style={active ? { color: '#1a73e8' } : { color: '#727272' }}
                                                            ></ListItemText>
                                                        </div>
                                                    </ListItemLink>
                                                    </div>
                                                    : ''
                                                break
                                            default:
                                                content = null;
                                                break;
                                        }
                                        return (
                                            <div key={item.title+item.type} className="headerWrapper">
                                                <ListItem className={`${this.state.open ? classes.listitem : classes.closelistitemSideBar}`}>
                                                    {content}
                                                </ListItem>
                                            </div>
                                        )
                                    })
                                    : ''
                            }
                        </List>
                        
                        <Divider className={!this.state.open ? classes.hide : ''} />
                        <List className={this.state.open ? classes.listopen : classes.listclose}>
                            {
                                menuItems2 && menuItems2.length > 0 ?
                                    menuItems2.map(item => {
                                        switch (Number(item.type)) {
                                            case 1:
                                                path = item.paths.replace(/'/g, '"')
                                                path = JSON.parse(path);
                                                state = this.state.open
                                                classname = state ? classes.linkText : classes.linkTextClose;
                                                active = path.some(this.isPathSelected)
                                                let activeStyle
                                                if (active === true) {
                                                    activeStyle = { backgroundColor: '#e3eefc' }
                                                } else {
                                                    activeStyle = {}
                                                }
                                                content = path && path.length > 0
                                                    ?
                                                    <Link className={this.state.open ? classes.link : classes.closelink} style={activeStyle} to={path[0]} onClick={this.toggleDrawer(false)}>
                                                        <ListItemIcon>
                                                            <Icon className={this.state.open ? classes.imageIcon : classes.imageIconClose}>
                                                                <img alt="" src={active ? item.activeIcon : item.icon} />
                                                            </Icon>
                                                        </ListItemIcon>
                                                        <div className="textWrapper">
                                                            <ListItemText
                                                                disableTypography
                                                                className={classname}
                                                                primary={item.title}
                                                                style={active ? { color: '#1a73e8' } : { color: '#727272' }}
                                                            ></ListItemText>
                                                        </div>
                                                    </Link>
                                                    : <a >
                                                        <img src={item.icon} alt="" title={item.title} />
                                                    </a>;
                                                break;
								            case 2:
                                                content = null;
                                                break;
                                            default:
                                                content = null;
                                                break;
                                        }
                                        return (
                                            <div key={item.title+item.type} className="headerWrapper">
                                                <ListItem className={`${this.state.open ? classes.listitem : classes.closelistitem} `}>
                                                    {content}
                                                </ListItem>
                                            </div>
                                        )
                                    })
                                    : ''
                            }
                        </List>
                        </Scrollbars>
                    </Drawer>
                </div>
            </div >
        );
    }
}

export default withRouter(connect(mapStateToProps, {})(withStyles(Styles, { withTheme: true })(Header)))
