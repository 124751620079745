import React from 'react'
import '../assets/css/webhook.scss'
import Drawer from './webhookDrawer'
import { connect } from 'react-redux'
import Table from "@material-ui/core/Table";
import Styles from '../assets/css/Styles'
import { CssBaseline } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FlashMessage from '../common/FlashMessage';
import { withStyles } from '@material-ui/core/styles';
import TableContainer from "@material-ui/core/TableContainer";
import {addIcon, Webhooks as webhookIcon} from '../../common/imageList'
import { LinearProgressBar, StyledCard, StyledTypography, BreadCrumbs, Image } from '../common/materialComponents';
import { getWebhookURLList, createWebhook, editWebhook, toggleSettingsFlashMessage, setSettingsFlashMessage, deleteWebHook } from '../actions/actionMethods';
import {WEBHOOK_ADDED_SUCCESSFULLY_MESSAGE, WEBHOOK_UPDATED_MESSAGE, WEBHOOK_ACTIVATED_MESSAGE, WEBHOOK_DELETED_MESSAGE, ACTIVATE_WEBHOOK_TO_EDIT} from '../common/constants';

class Webhook extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess = true
        this.state = {
            showWebhookURLFlag: false,
            showLoader: false,
            webhookURLValue: '',
            webhookURLList: [],
            showDataLoader: true,
            isURLErrorFlag: false,
            user_details: (JSON.parse(localStorage.getItem('trackhiveToken'))),
            invalidUrl: '',
            isEditFlag: false,
            webhookURLInputValue: '',
            webhookDatavalue: '',
            isDeleteFlag: false,
            deleteErrorMessage: '',
            onChange: false
        }
    }

    openwebhookURLModal = () => {
        this.setState({
            showWebhookURLFlag: true,
            isURLErrorFlag: false,
            webhookURLValue: '',
            invalidUrl: '',
            isEditFlag: false
        })
    }

    closewebhookURLModal = () => {
        this.setState({
            showWebhookURLFlag: false,
            isDeleteFlag: false,
            isEditFlag: false,
            isURLErrorFlag: false,
            deleteErrorMessage: ""
        })
    }

    addWebHookURL = () => {
        if (this.state.webhookURLValue === '') {
            this.setState({
                isURLErrorFlag: true
            })
        } else {
            let requestParams = {}
            requestParams.endpoint_url = this.state.webhookURLValue
            requestParams.notify_if_inactive = false
            requestParams.email_alerts = [this.state.user_details.email]
            this.setState({
                showLoader: true
            })
            createWebhook(requestParams).then(response => {
                this.setState({
                    showLoader: false
                })
                if (response) {
                    if (response.meta !== undefined) {
                        if (response.meta.code >= 200 && response.meta.code <= 299) {
                            this.setState({
                                invalidUrl: ''
                            })
                            this.isSuccess = true
                            this.props.toggleSettingsFlashMessage(1)
                            this.props.setSettingsFlashMessage(WEBHOOK_ADDED_SUCCESSFULLY_MESSAGE)

                            this.closewebhookURLModal()
                            this.getWebhookDataList()
                        }
                    } else if (response.status === 400) {
                        this.setState({
                            isURLErrorFlag: true,
                            invalidUrl: response.data.meta.message[0]
                        })
                    }
                }
            })
        }
    }

    editWebhookURL = (webhookData) => {
        if (this.state.webhookURLValue === '') {
            this.setState({
                isURLErrorFlag: true,
                invalidUrl: ''
            })
        } else {
            let requestParams = {}
            requestParams.endpoint_url = this.state.webhookURLValue
            requestParams.notify_if_inactive = true
            requestParams.email_alerts = [this.state.user_details.email]
            requestParams.active = webhookData.active
            requestParams.id = webhookData.id
            this.setState({
                showLoader: true,
                isURLErrorFlag: false
            })
            editWebhook(requestParams).then(response => {
                this.setState({
                    showLoader: false
                })
                if (response) {
                    if (response.meta !== undefined) {
                        if (response.meta.code >= 200 && response.meta.code <= 299) {
                            this.setState({
                                invalidUrl: ''
                            })
                            this.isSuccess = true
                            this.closewebhookURLModal()
                            this.getWebhookDataList()
                            this.props.toggleSettingsFlashMessage(1)
                            this.props.setSettingsFlashMessage(WEBHOOK_UPDATED_MESSAGE)

                        }
                    } else if (response.status === 400) {
                        this.setState({
                            isURLErrorFlag: true,
                            invalidUrl: response.data.meta.message[0]
                        })
                    }
                }
            })
        }
    }

    activateWebhook = (webhookData) => {
        let requestParams = {}
        requestParams.endpoint_url = webhookData.endpoint_url
        requestParams.notify_if_inactive = true
        requestParams.email_alerts = [this.state.user_details.email]
        requestParams.active = true
        requestParams.id = webhookData.id

        editWebhook(requestParams).then(response => {
            if (response) {
                if (response.meta !== undefined) {
                    if (response.meta.code >= 200 && response.meta.code <= 299) {
                        this.isSuccess = true
                        this.getWebhookDataList()
                        this.props.toggleSettingsFlashMessage(1)
                        this.props.setSettingsFlashMessage(WEBHOOK_ACTIVATED_MESSAGE)

                    }
                } else if (response.status === 400) {
                    this.setState({
                        invalidUrl: response.data.meta.message[0]
                    })
                }
            }
        })
    }

    deleteWebhookURL = (webhookData) => {
        this.setState({
            showLoader: true
        })
        deleteWebHook(webhookData.id).then(response => {
            this.setState({
                showLoader: false
            })
            if (response) {
                if (response.meta !== undefined) {
                    if (response.meta.code >= 200 && response.meta.code <= 299) {
                        this.setState({
                            invalidUrl: ''
                        })
                        this.isSuccess = true
                        this.closewebhookURLModal()
                        this.getWebhookDataList()
                        this.props.toggleSettingsFlashMessage(1)
                        this.props.setSettingsFlashMessage(WEBHOOK_DELETED_MESSAGE)

                    } 
                } else if (response.status === 404) {
                    this.setState({
                        deleteErrorMessage: response.data.meta.message[0]
                    })
                }
            }
        })
    }

    handleChange = (value) => {
        this.setState({
            webhookURLValue: value
        })
    }

    componentWillMount() {
        this.getWebhookDataList()
    }

    getWebhookDataList = () => {
        getWebhookURLList().then(res => {
            if (res && res.data) {
                this.setState({
                    webhookURLList: res.data.data.reverse(),
                    showDataLoader: false
                })
            }
        })
    }

    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.props.closeDrawer()
        this.setState({
            open: open
        })
    };

    handleWebhookEdit = (webhookData, event) => {
        if (webhookData.active === true) {
            this.setState({
                showWebhookURLFlag: true,
                onChange: true,
                isEditFlag: true,
                webhookDatavalue: webhookData,
                webhookURLValue: webhookData.endpoint_url,
                invalidUrl: ''
            })
            setTimeout(() => {
                this.setState({onChange: false})
            },800)
        } else {
            this.props.toggleSettingsFlashMessage(1)
            this.props.setSettingsFlashMessage(ACTIVATE_WEBHOOK_TO_EDIT)
            this.isSuccess = false
        }
    }

    handleDelete = (webhookData) => {
        this.setState({
            isDeleteFlag: true,
            webhookDatavalue: webhookData,
            showWebhookURLFlag: true,
            deleteErrorMessage: ''
        })
    }

    showWebhookData = (data) => {
      const {classes} = this.props
        return(
        <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                <TableRow>
                    <TableCell align="left" className={`${classes.headCell} ${classes.firstHeadAndBodyCell}`}>Webhook URL</TableCell>
                    <TableCell align="left" className={`${classes.headCell} ${classes.lastHeadAndBodyCell}`}>Status</TableCell>
                </TableRow>
                </TableHead>
                <TableBody >
                    {data && data.length > 0 ?
                        data.map((webhook,index) => (
                            <TableRow key={index}>
                                <TableCell align="left" className={`${classes.bodyCell} ${classes.firstHeadAndBodyCell}`}>
                                <div style={{color: "#1a73e8", fontSize: "14px", textDecoration:'none',cursor: 'pointer'}} onClick={(id) => this.handleWebhookEdit(webhook)}>
                                    {webhook.endpoint_url}
                                </div>
                                </TableCell>
                                <TableCell align="left" className={`${classes.bodyCell} ${classes.lastHeadAndBodyCell}`}>{webhook.active === true ? <span className="greenText">Active</span> : <span className="warningColorText" style={{ cursor: 'pointer', textDecoration:'underline' }} onClick={(id) => this.activateWebhook(webhook)}>Activate</span>}</TableCell>
                            </TableRow>
                        )) : <div className="noData" style={{ position: "absolute", padding: "2rem", left: '45%' }}>No webhooks yet.</div>
                    }   
                </TableBody>
            </Table>
        </TableContainer>     
        )
    }

    toggleFlashMessage = (data) => {
        this.props.toggleSettingsFlashMessage(data)
        this.props.setSettingsFlashMessage('')
    }
    
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
            <CssBaseline/>
            {
             this.state.showDataLoader ?
                <div>
                    <LinearProgressBar/>
                </div>
                : 
               <div className="settingsModule">
                   <StyledCard>
                        <BreadCrumbs aria-label="breadcrumb">
                            <Link className={classes.link} to="/settings">
                                Settings
                            </Link>
                           <StyledTypography className={classes.text}>Webhook</StyledTypography>     
                        </BreadCrumbs>
                    </StyledCard>
               <div className="dataContainer">
                    <div className="containerHeader">
                            <Image alt="webhook_IC" className={classes.avatar} src={webhookIcon} />
                            <div className="containerTitle">Webhook</div>
                            <div style={{float: "right", textAlign:"right", paddingRight: '26px', cursor: 'pointer'}}>
                                <img src={addIcon} alt="Add Icon" onClick={this.openwebhookURLModal} />
                            </div>
                     </div>
                     <div id="settingDataScrollerWrapper">
                         <div className="settingsContainer">
                             <div className="settingsRightSidePanel">
                                 <div className="commonSettingsBox webhookStyleMainDiv">
                                         <div className="webhookMainContainerDiv">
                                             <div className="webhookContentMainDiv">
                                                 <div className="webhookContentInnerDiv" style={{ border: 'none' }}>
                                                        {this.showWebhookData(this.state.webhookURLList)}
                                                 </div>
                                             </div>
                                         </div>
                                 </div>
                             </div>
                         </div>
                     </div>                    
                 
                    <Drawer 
                        openDrawer={this.state.showWebhookURLFlag} 
                        closeDrawer={this.closewebhookURLModal}
                        classes={classes}
                        handleChange={this.handleChange}
                        invalidUrl = {this.state.invalidUrl}
                        webhookURLValue={this.state.webhookURLValue}
                        webhookDatavalue={this.state.webhookDatavalue}
                        isURLErrorFlag = {this.state.isURLErrorFlag}
                        addWebhookURL={this.addWebHookURL} 
                        isEditFlag = {this.state.isEditFlag}
                        editWebhookURL = {this.editWebhookURL}
                        deleteErrorMessage = {this.state.deleteErrorMessage}
                        deleteWebhookURL = {this.deleteWebhookURL}
                        loading = {this.state.showLoader}
                        handleDelete = {this.handleDelete}
                        isDeleteFlag = {this.state.isDeleteFlag}
                        onChange = {this.state.onChange}
                    />
                     
                    {this.props.showSettingFlashMessageFlag === 1 ?
                        <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                        : ''}
                </div>
                {/* data container close  */}
            </div>
            }
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, { getWebhookURLList, createWebhook, editWebhook, toggleSettingsFlashMessage, setSettingsFlashMessage, deleteWebHook })(withStyles(Styles)(Webhook)));