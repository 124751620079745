const Styles = theme => ({
    /*Common BreadCrumbs Styles Start */
    text: {
        fontSize: '16px',
        fontWeight: '700'
    },
    link: {
        color: '#666666',
        textDecoration: "none"
    },
    /*Common BreadCrumbs Styles Ends */

    /* Organization Styles Starts */
    container: {
        paddingLeft: "32px",
        height: "100vh",
        float: "left",
        width: "100%",
        paddingRight: "5px",
        fontFamily: "Roboto"
    },
    avatar: {
        marginRight: "16px",
        display: "inline-flex"
    },
    avatarOrganization: {
        width: "80px",
        height: "80px",
        float: "left",
        display: "inline-flex",
        marginRight: "16px"
    },
    OrganizationSaveBtn: {
        color: 'white',
        background: '#1a73e8',
        marginTop: "42px",
        fontSize: '14px',
        letterSpacing: '0.14px',
        "&:hover": {
            background: '#1a73e8'
        }
    },
    OrganizationBtnProgress: {
        color: "primary",
        position: "absolute",
        top: "50%",
        left: "50%",
        marginLeft: -12,
        marginTop: 12,
    },
    /* Organization Styles Ends */

    /* Sub User Tab Styles Starts */
    mainRoot: {
        flexGrow: 1,
    },
    appBar: {
        "&.MuiAppBar-root": {
            backgroundColor: 'white',
            color: '#1a73e8',
            boxShadow: 'none'
        }
    },
    indicatorColor: {
        backgroundColor: "#1a73e8 !important"
    },
    tabpanel: {
        "&.MuiBox-root": {
            padding: 0
        }
    },
    tabProgress: {
        color: "#1a73e8",
        position: "absolute",
        top: "40%",
        left: "50%",
        right: '50%',
    },
    headCell: {
        paddingTop: '16px',
        paddingBottom: '16px',
        paddingRight: '34px',
        paddingLeft: '0px',
        width: '5px'
    },
    bodyCell: {
        paddingTop: '16px',
        paddingBottom: '16px',
        paddingRight: '34px',
        paddingLeft: '0px',
        width: '5px'
    },
    pagination: {
        bottom: 0,
        position: 'fixed',
        zIndex: 100,
        backgroundColor: '#ffffff',
        width: 'calc(100% - 73px)',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    paginationTextAlign: {
        paddingTop: '10px'
    },
    subUserContentText: {
        letterSpacing: '0.2px',
        fontWeight: 500,
        fontSize: 20
    },
    /* Sub User Tab Styles Ends */

    /* Custom Email Templates Styles Starts */
    emailTextField: {
        margin: "24px 32px 8px 0px",
    },
    templateNameField: {
        width: '712px',
        margin: "36px 32px 8px 0px",
    },
    templateSubjectField: {
        width: '554px',
        margin: "24px 0px 8px 0px",
        "& .MuiOutlinedInput-root": {
            borderRadius: '4px 0px 0px 4px',
        }
    },
    emailAutoComplete: {
        margin: '24px 32px 8px 0px'
    },
    cancelEmailBtn: {
        background: "#ffffff",
        marginLeft: '2rem',
        color: "#1a73e8",
        width: '110px',
        height: '36px',
        border: '1px solid #1a73e8',
    },
    saveEmailBtn: {
        color: 'white',
        background: '#1a73e8',
        fontSize: '14px',
        letterSpacing: '0.14px',
        width: '110px',
        height: '36px',
        "&:hover": {
            background: '#1a73e8'
        }
    },
    testEmailBtn: {
        marginLeft: '2rem',
        color: 'white',
        background: '#1a73e8',
        fontSize: '14px',
        letterSpacing: '0.14px',
        width: '110px',
        height: '36px',
        "&:hover": {
            background: '#1a73e8'
        }
    },
    emailCircularProgress: {
        color: "#1a73e8",
    },
    buttonProgressTest: {
        color: "primary",
        position: "absolute",
        left: "43.5%",
        marginLeft: '-16rem',
        marginTop: 6,
    },
    item: {
        "&:hover": {
            background: '#BFE4FF'
        }
    },
    expansionItem: {
        width: '100%',
        marginBottom: '5px',
        fontSize: '14px',
        cursor: 'pointer',
        outline: 'none',
        borderRadius: '.25rem',
        border: '.0625rem solid #bfc2c7',
        display: 'inline-block',
        padding: '.5rem .35rem',
        boxSizing: 'border-box',
        textDecoration: 'none',
        textAlign: 'center',
        "&:hover": {
            background: '#BFE4FF'
        }
    },
    popoverStyle: {
        "& .MuiPopover-paper": {
            padding: 5
        }
    },
    popoverBtnStyle: {
        color: 'white',
        background: '#1a73e8',
        marginTop: "24px",
        padding: '.7rem',
        width: '23%',
        borderRadius: '0px 4px 4px 0px',
        fontWeight: 500,
        cursor: 'pointer',
        fontSize: '14px',
        letterSpacing: '0.14px',
        height: '56px',
        textTransform: 'none',
        "&:hover": {
            background: '#1a73e8'
        }
    },
    expansionPanelRoot: {
        width: '340px'
    },
    expansionPanel: {
        marginBottom: '32px !important',
        borderRadius: '4px',
        boxShadow: 'none',
        border: '1px solid #b3b3b3',
        '&.MuiExpansionPanel-root:before': {
            display: 'none',
        },
    },
    expansionPanelSummary: {
        minHeight: '54px'
    },
    exapansionMainTitle: {
        fontSize: '20px',
        fontWeight: 500,
        letterSpacing: '0.15px',
        marginBottom: 9,
        color: '#212121'
    },
    heading: {
        fontSize: '15px',
        color: '#757575',
        lineHeight: '22px'
    },
    /* Custom Email Templates Styles Ends */

    /* Sub Domain Styles Start */
    subDomainSaveBtn: {
        color: 'white',
        background: '#1a73e8',
        fontSize: '14px',
        letterSpacing: '0.14px',
        width: '88px',
        height: '36px',
        "&:hover": {
            background: '#1a73e8'
        }
    },
    subDomainSaveBtnDisable: {
        color: 'white',
        background: '#d3d3d3',
        fontSize: '14px',
        letterSpacing: '0.14px',
        width: '88px',
        height: '36px',
        "&:hover": {
            background: '#d3d3d3'
        }
    },
    subDomainButtonProgress: {
        color: "#1a73e8",
        position: "absolute",
        top: "22%",
        left: "4.5%",
        marginTop: -12,
    },
    subDomainsaveBtn: {
        color: 'white',
        background: '#1a73e8',
        fontSize: '14px',
        letterSpacing: '0.14px',
        width: '88px',
        height: '36px',
        "&:hover": {
            background: '#1a73e8'
        }
    },
    /* Sub Domain Styles End */

    /* Email Alerts Styles Starts */
    digestText: {
        fontSize: '14px',
        letterSpacing: '0.14px',
        paddingTop: '7px',
        fontWeight: 400
    },
    digestSwitchOn: {
        marginLeft: '3rem',
        "& .Mui-checked ": {
            color: '#1a73e8'
        },
        "& .MuiSwitch-track": {
            background: '#1a73e8 !important'
        }
    },
    digestSwitchOff: {
        marginLeft: '3rem'
    },
    grid: {
        borderTop: '1px solid #b3b3b3',
        borderBottom: '1px solid #b3b3b3',
        padding: '8px 10px 8px 31px',
        display: 'flex'
    },
    /* Email Alters Styles Ends */

    /*Tags Styles Starts */
    iconColor: {
        "& .MuiSvgIcon-root": {
            color: '#1a73e8'
        }
    },
    tagName: {
        fontSize: '14px'
    },
    /*Tags Styles Ends */

    /* Common Componets Styles Starts */
    textField: {
        margin: "32px 32px 8px 0px",
    },
    autoComplete: {
        display: 'inline-flex',
        margin: '32px 32px 8px 0px',
    },
    saveBtnRoot: {
        display: "flex",
        alignItems: "center"
    },
    wrapper: {
        position: "relative"
    },
    saveBtn: {
        color: 'white',
        background: '#1a73e8',
        marginLeft: '32px',
        fontSize: '14px',
        letterSpacing: '0.14px',
        "&:hover": {
            background: '#1a73e8'
        }
    },
    buttonProgress: {
        color: "#1a73e8",
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: 6
    },
    table: {
        width: '100%',
        whiteSpace: 'nowrap'
    },
    tableContainer: {
        height: 'calc(100vh - 311px)',
        width: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
            utline: '1px solid slategrey'
        },

        borderTop: '1px solid #e0e0e0'
    },
    deleteDialogProgress:{
            color: "#1a73e8",
            position: "absolute",
            top: "46%",
            left: "45%",
            marginLeft: '-16rem',
            marginTop: -5,
    },
    /* Common Componets Styles Ends */

    /* Common Drawer Styles Starts */
    drawerHeader: {
        width: '657px',
        height: '48px',
        background: "#5e6367",
    },
    drawerTitle: {
        color: '#ffffff',
        padding: "10px 0px 8px 32px",
        fontSize: '20px',
        letterSpacing: '0.15px'
    },
    draweCancelButton: {
        marginLeft: 'auto',
        position: 'relative',
    },
    drawerContentSpace: {
        marginBottom: '15px'
    },
    drawerContent: {
        height: '86px',
        width: '650px',
        padding: "30px 32px 0px 32px"
    },
    drawerContentText: {
        color: "#666666",
        fontSize: '15px',
    },
    drawerTextAreaContainer: {
        height: '85px',
        padding: "0px 34px 0px 32px"
    },
    drawerTextFieldContainer: {
        padding: "0px 34px 0px 32px",
        marginBottom: '32px'
    },
    drawerFields: {
        width: '588px'
    },
    drawerAutoComplete: {
        width: '588px',
    },
    roleMouleTitle: {
        "&.MuiTypography-body1": {
            fontSize: 20,
            fontWeight: 500,
            width: 146,
            height: 23
        }
    },
    drawerSwitchMainContainer: {
        padding: "0px 34px 0px 33px",
    },
    drawerSwitchContainer: {
        marginTop: 38,
        position: 'absolute'
    },
    drawerRolesSwitchOn: {
        float: 'right',
        "& .Mui-checked ": {
            color: '#1a73e8'
        },
        "& .MuiSwitch-track": {
            background: '#1a73e8 !important'
        },
    },
    drawerRolesSwitchOff: {
        float: 'right',
        "& .Mui-checked ": {
            color: '#ffffff'
        },
        "& .MuiSwitch-track": {
            background: '#979797 !important'
        },
    },
    drawerPaper: {
        border: 'none',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px',
    },
    footerMain: {
        width: '657px',
        height: '100px',
        position: 'fixed',
        left: '0',
        bottom: '0',
        background: 'black'
    },
    drawerDeleteButton: {
        marginLeft: '25rem',
        background: "#ffffff",
        color: "#1a73e8",
        float: 'right',
        margin: "42px 32px 32px 32px",
        width: '100px',
        height: '36px',
        border: '1px solid #1a73e8',
    },
    drawerCopyButton: {
        background: "#ffffff",
        color: "#1a73e8",
        marginTop: '23px',
        marginLeft: '458px',
        width: '100px',
        height: '36px',
        border: '1px solid #1a73e8',
    },
    error: {
        "&.MuiFormHelperText-root.MuiFormHelperText-contained.MuiFormHelperText-filled": {
            color: 'red'
        },
    },
    drawerSaveBtn: {
        color: 'white',
        background: '#1a73e8',
        marginTop: "42px",
        fontSize: '14px',
        letterSpacing: '0.14px',
        width: '88px',
        height: '36px',
        margin: '32px 0px 32px 32px',
        "&:hover": {
            background: '#1a73e8'
        }
    },
    drawerRefreshBtn: {
        color: 'white',
        background: '#1a73e8',
        marginTop: "42px",
        fontSize: '14px',
        letterSpacing: '0.14px',
        width: '100px',
        height: '36px',
        margin: '32px 0px 32px 32px',
        "&:hover": {
            background: '#1a73e8'
        }
    },
    drawerRefreshBtnDisable: {
        color: 'white',
        background: '#d3d3d3',
        marginTop: "42px",
        fontSize: '14px',
        letterSpacing: '0.14px',
        width: '100px',
        height: '36px',
        margin: '32px 0px 32px 32px',
        "&:hover": {
            background: '#d3d3d3'
        }
    },
    drawerSaveBtnDisable: {
        color: 'white',
        background: '#d3d3d3',
        marginTop: "42px",
        fontSize: '14px',
        letterSpacing: '0.14px',
        width: '88px',
        height: '36px',
        margin: '32px 0px 32px 32px',
        "&:hover": {
            background: '#d3d3d3'
        }
    },
    drawerbuttonProgress: {
        color: "primary",
        position: "absolute",
        top: "50%",
        left: "50%",
        marginLeft: '-16rem',
        marginTop: -5,
    },
    drawerProgress: {
        color: "#1a73e8",
        position: "absolute",
        top: "50%",
        left: "50%",
        right: '50%',
    },
    /* Common Drawer Styles Ends */

    /* Common Dialog Styles Starts */
    verifyDialogTable: {
        minWidth: '500px'
    },
    dialogActionBtn: {
        color: "red"
    },
    dialogCancelBtn: {
        color: "#1a73e8"
    },
    dialogVerifyAction: {
        color: "#1a73e8"
    },
    dialogVerifyCancel: {
        color: 'red'
    },
    /* Common Dialog Styles Ends */

    /*Common Table Styles Starts */
    firstHeadAndBodyCell: {
        paddingLeft: '16px'
    },
    lastHeadAndBodyCell: {
        paddingRight: '0px',
        width: '0px'
    },
    /* Common Table Styles Ends */

    /* Integration Styles Starts */
    integrateBtn: {
        color: 'white',
        background: '#1a73e8',
        fontSize: '14px',
        letterSpacing: '0.14px',
        height: '36px',
        "&:hover": {
            background: '#1a73e8'
        },
        marginTop: "24px"
    },
    /* Integration Styles Ends */

    /* Tracking Page List Styles Starts */
    root: {
        minWidth: 275,
        height: "48px",
        padding: "13px 0px 12px 9px",
        borderRadius: "unset",
        position: "relative",
        width: "100%",
        fontFamily: "Roboto"
    },
    title: {
        fontSize: 14,
    },
    onFocus: {
        "& label.Mui-focused": {
            color: '#1a73e8'
        },
        "& .MuiOutlinedInput-root": {
            // height: "50px",
            "&.Mui-focused fieldset": {
                borderColor: '#1a73e8'
            }
        }
    },
    gridItem: {
        width: '100%'
    },
    pageListSaveBtn: {
        color: 'white',
        display: 'flow-root',
        background: '#1a73e8',
        fontSize: '14px',
        letterSpacing: '0.14px',
        width: '110px',
        height: '36px',
        "& .MuiButton-label": {
            display: 'inline-flex',
            "& .MuiButton-startIcon": {
                marginTop: -3
            }
        },
        "&:hover": {
            background: '#1a73e8'
        }
    },
    previewBtn: {
        color: 'white',
        display: 'flow-root',
        background: '#1a73e8',
        fontSize: '14px',
        letterSpacing: '0.14px',
        width: '110px',
        height: '36px',
        marginLeft: '2rem',
        "& .MuiButton-label": {
            display: 'inline-flex',
            "& .MuiButton-startIcon": {
                marginTop: -3
            }
        },
        "&:hover": {
            background: '#1a73e8'
        }
    },
    cancelButton: {
        background: "#ffffff",
        color: "#1a73e8",
        width: '110px',
        fontSize: '14px',
        letterSpacing: '0.14px',
        height: '36px',
        border: '1px solid #1a73e8',
        marginLeft: '2rem',
        "& .MuiButton-label": {
            display: 'inline-flex',
            "& .MuiButton-startIcon": {
                marginTop: -3
            }
        },
    },
    progress: {
        color: "#1a73e8"
    },
    /* Tracking Page List Styles Ends */

    /* Tracking Button Styles Starts */
    trackButtonexpansionPanelRoot: {
        minWidth: '55%',
        width: '82.26%',
        marginLeft: '3rem',
        "& .Mui-expanded": {
            border: 'none',
            marginBottom: '34px'
        }
    },
    trackingButtonPageIcon: {
        marginRight: 10
    },
    trackingButtonPageTitle: {
        fontSize: '20px',
        fontWeight: 500,
        letterSpacing: '0.15px',
        color: '#212121',
        marginTop: 5
    },
    trackingButtonExpansionSequence: {
        color: "#eb5757",
        fontSize: '18px'
    },
    trackingButtonExpansionBtn: {
        fontWeight: 500,
        fontSize: 14,
        color: "#1a73e8"
    },
    trackingButtonexpansionHeading: {
        fontSize: '18px',
        marginLeft: '12px'
    },
    trackingButtonExpansionPanel: {
        marginBottom: '34px',
        borderRadius: '4px',
        boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.12), 0px 1px 8px 0px rgba(0,0,0,0.14)',
        '&.MuiExpansionPanel-root:before': {
            display: 'none',
        },
    },
    trackingButtonExpansionSummary: {
        height: 84
    },
    /* Tracking Button Styles Ends */
    /* Integration Styles Starts */
    paperRoot: {
        borderRadius: "0"
    },
    tabTextColor: {
        color: "#1a73e8 !important"
    },
    IntegrationCard: {
        width: 180,
        height: 160
    },
    IntegrationMedia: {
        objectFit: 'none',
        height: 90,
        width: 90,
        margin: "16px auto",
        marginBottom: "24px"
    },
    integrationTitle: {
        textAlign: "center",
        fontWeight: "500"
    },
    integrationContent: {
        padding: "0px",
        marginBottom: "16px",
        "&:last-child": {
            paddingBottom: "0px"
        }
    },
    IntegrationCardAction: {
        "&:hover": {
            background: "#e3eefc"
        }
    },
    /* Integration Sytles End */

    /* Settings Main-menu Styles starts */
    settingCard: {
        width: 350,
        height: 131
    },
    settingCardheader: {
        paddingBottom: '0px',
        alignItems: 'stretch'
    },
    settingCardSubTitle: {
        minHeight: '45px'
    },
    settingCardActions: {
        width: '110.9px',
        paddingLeft: '14px',
        paddingTop: '9px'
    },
    /* Settings Main-menu Styles Ends */

    /* Setting Main-menu and Integration Grid List Styles starts */
    content: {
        flexGrow: 1,
        padding: '15px 20px 20px 16px',
    },
    cardGridItem: {
        padding: '17px'
    },
    /* Setting Main-menu and Integration Grid List Styles starts */

    /* Track Button Styles Starts */
    trackButtonMainTitle: {
        fontSize: 20,
        fontWeight: 500,
        padding: '32px 37px 32px 40px'
    },
    trackButtonCardContainer: {
        flexGrow: 1,
        padding: '0px 20px 20px 16px',
    },
    trackButtonGridItem: {
        padding: '0px 7px 15px 25px'
    },
    trackButtoncard: {
        width: 180,
        height: 160
    },
    trackButtonCardTitle: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 500
    },
    customStoreMedia: {
        objectFit: 'none',
        marginTop: 16,
        "& .MuiCardMedia-root": {
            width: 80
        }
    },
    otherStoreMedia: {
        objectFit: 'none',
        marginTop: 16,
        "& .MuiCardMedia-root": {
            width: 80
        }
    },
    customStoreActionArea: {
        "&.MuiButtonBase-root": {
            height: 'inherit'
        }
    }
    /* Track Button Styles Ends */

});
export default Styles