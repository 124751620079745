import axios from 'axios';
import * as types from '../common/constants';
import { getInstance } from '../../config/token';
import {DEFAULT_TRACKING_LIMIT} from '../common/constants'
const prefix = ''
export function returnData(type, data, status) {
    if (status >= 200 && status <= 299) {
        return {
            type: type,
            data: data
        }
    } else {
        console.log('error');
    }
}
function handleException(error) {
    if (error.response && error.response.status) {
        return {
            status: error.response.status,
            data: error.response.data
        }
    } else {
        return {
            status: 500
        }
    }
}

export function isEmptyObject(obj) {
    for (var prop in obj) {
        return false;
    }
    return true;
}

export const exportShipments = (requestParams) => {
    const config = getInstance('POST', `${prefix}/export/trackings`, requestParams);
    return axios(config).then(response => response);
}

export function createShipment(requestParams) {
    let config = getInstance('POST', `${prefix}/trackings`, requestParams);
    return dispatch => {
        dispatch(startLoading())
        return axios(config)
            .then(res => {
                dispatch(endLoading())
                return dispatch(returnData(types.CREATE_SHIPMENT, res.data, res.status))
            })
            .catch(function (error) {
                dispatch(endLoading())
                if (error.response) {

                    if (!(error.response.status >= 200 && error.response.status <= 299)) {
                        dispatch({
                            type: types.CREATE_SHIPMENT_ERROR,
                            data: error.response.data.meta.message[0]
                        })
                    }
                }
                // return {
                //     status: error.response.status,
                //     data: error.response.data
                // }
            });
    }
}
function startLoading() {
    return {
        type: types.START_LOADING
    }
}
function endLoading() {
    return {
        type: types.END_LOADING
    }
}
export function getTrackingList(requestParams) {
    let pageId = requestParams.pageId
    let limit = requestParams.limit
    let filters = {}
    let searchQueryValue = ''
    if (requestParams.courier !== undefined) {
        filters.courier = requestParams.courier
    }
    if (requestParams.status !== undefined && requestParams.status.length === 1) {
        filters.status = requestParams.status
    }
    if (requestParams.origin !== undefined) {
        filters.origin = requestParams.origin
    }
    if (requestParams.destination !== undefined) {
        filters.destination = requestParams.destination
    }
    if (requestParams.transitDays !== undefined && requestParams.transitDays !== '') {
        let days = requestParams.transitDays
        filters.transitdays = days === '1 Day' ? 1 : days === '2 Days' ? 2 : days === '3 Days' ? 3 : days === '4 Days' ? 4 : days === '5 Days' ? 5 : 6
    }
    if(requestParams.from_date !==undefined &&  requestParams.from_date !== ''){
        filters.from_date = requestParams.from_date
    }
    if(requestParams.to_date !==undefined &&  requestParams.to_date !== ''){
        filters.to_date = requestParams.to_date
    }
    if(requestParams.assigned_id !== undefined && requestParams.assigned_id !== '' && requestParams.assigned_id.length > 0){
        let assignIdArr = []
        let subUserIds = requestParams.assigned_id
        if(subUserIds[0].value == "false" || subUserIds[0].value == false){
            filters.is_shipment_assigned = false
        }else{
            subUserIds.forEach(id => {
                    assignIdArr.push(id.value)
            });
            filters.assigned_id = assignIdArr
            filters.is_shipment_assigned = true
        }
    }
    if(requestParams.tags !== undefined && requestParams.tags && requestParams.tags.length > 0){
        let tagNameArr = []
        let tags = requestParams.tags
        tags.forEach(tag => {
            tagNameArr.push(tag.value)
        })
        filters.tagsIds = tagNameArr
    }
    //filter for sub user 
    let trackingRole = localStorage.getItem('role_access') != undefined && localStorage.getItem('role_access') != '' ? JSON.parse(localStorage.getItem('role_access')).role : []
    if(localStorage.getItem('user_type') === 'subuser' && trackingRole && trackingRole.includes('assignTracking') ){
        let userToken = (JSON.parse(localStorage.getItem('trackhiveToken')))
        filters.is_shipment_assigned = true
        filters.assigned_id = [userToken._id]
    }
    if (requestParams.query && requestParams.query !== '') {
        searchQueryValue = escape(requestParams.query)
    }
    let config = ''
    if (filters && Object.keys(filters).length > 0) {
        config = getInstance('GET', `${prefix}/trackings?pageId=${pageId}&limit=${limit}&filters=${JSON.stringify(filters)}&searchQuery=${JSON.stringify(searchQueryValue)}`);
    }
    else {
        if(searchQueryValue !== ''){
            if(localStorage.getItem('user_type') == 'subuser'){
                config = getInstance('GET', `${prefix}/trackings?pageId=${pageId}&limit=${limit}&filters=${JSON.stringify(filters)}&searchQuery=${JSON.stringify(searchQueryValue)}`);
            }else{
                config = getInstance('GET', `${prefix}/trackings?pageId=${pageId}&limit=${limit}&searchQuery=${JSON.stringify(searchQueryValue)}`);
            }
        }
        else{
                config = getInstance('GET', `${prefix}/trackings?pageId=${pageId}&limit=${limit}`);
        }
    }
    return dispatch => {
        return axios(config)
            .then(res => {
                return dispatch(returnData(types.GET_TRACKING_LIST, res.data, res.status))
            })
            .catch(function (error) {
                return handleException(error)
            });
    }
}
export function searchTracking(requestParams) {
    let pageId = 1
    let limit = localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : DEFAULT_TRACKING_LIMIT
    let searchQueryValue = ''

    searchQueryValue = escape(requestParams.query)
    let config = getInstance('GET', `${prefix}/trackings?pageId=${pageId}&limit=${limit}&searchQuery=${JSON.stringify(searchQueryValue)}`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export  function setUpdatedTrackingList(updatedData,totalRecords){
    let data = {}
    data.data = updatedData
    data.meta = {}
    data.meta.total_records = totalRecords
    return dispatch => {
        dispatch({
            type: types.GET_TRACKING_LIST,
            data: data
        })
    }
}

export function clearShipmentStatusFilters() {
    return dispatch => {
        dispatch({
            type: types.CLEAR_SHIPMENT_STATUS_FILTERS,
            data: []
        })
    }
}

export function clearAllShipmentStatusFilters() {
    return dispatch => {
        dispatch({
            type: types.CLEAR_ALL_SHIPMENT_STATUS_FILTERS,
            data: []
        })
    }
}


export  function setTrackingDetails(data){

    return dispatch => {
        dispatch({
            type: types.TRACKING_DETAILS,
            data: data
        })
    }
}




export function getTrackingSearchList(requestParams) {

    let pageId = 1
    let limit = localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : DEFAULT_TRACKING_LIMIT
    let filters = {}
    let searchQueryValue = ''
    if(requestParams.query !== undefined){
        searchQueryValue = escape(requestParams.query)
    }
    if (requestParams.courier !== undefined) {
        filters.courier = requestParams.courier
    }
    if (requestParams.status !== undefined) {
        filters.status = requestParams.status
    }
    if (requestParams.origin !== undefined) {
        filters.origin = requestParams.origin
    }
    if (requestParams.destination !== undefined) {
        filters.destination = requestParams.destination
    }
    if (requestParams.transitDays !== undefined && requestParams.transitDays !== '') {
        let days = requestParams.transitDays
        filters.transitdays = days === '1 Day' ? 1 : days === '2 Days' ? 2 : days === '3 Days' ? 3 : days === '4 Days' ? 4 : days === '5 Days' ? 5 : 6
    }

    if(requestParams.assigned_id !== undefined && requestParams.assigned_id !== '' && requestParams.assigned_id.length > 0){
        let assignIdArr = []
        let subUserIds = requestParams.assigned_id
        if(subUserIds[0].value == "false" || subUserIds[0].value == false){
            filters.is_shipment_assigned = false
        }else{
            subUserIds.forEach(id => {
                    assignIdArr.push(id.value)
            });
            filters.assigned_id = assignIdArr
            filters.is_shipment_assigned = true
        }
    }

    if(requestParams.tags !== undefined && requestParams.tags && requestParams.tags.length > 0){
        let tagNameArr = []
        let tags = requestParams.tags
        tags.forEach(tag => {
            tagNameArr.push(tag.value)
        })
        filters.tagsIds = tagNameArr
    }

    let trackingRole = localStorage.getItem('role_access') != undefined && localStorage.getItem('role_access') != '' ? JSON.parse(localStorage.getItem('role_access')).role : []
    if(localStorage.getItem('user_type') === 'subuser' && trackingRole && trackingRole.includes('assignTracking')){
        filters.is_shipment_assigned = true
        let userToken = (JSON.parse(localStorage.getItem('trackhiveToken')))
        filters.assigned_id = [userToken._id]
    }
    if (requestParams.fromDate !== undefined && requestParams.toDate !== undefined) {
        filters.from_date = requestParams.fromDate
        filters.to_date = requestParams.toDate
    }

    let config = getInstance('GET', `${prefix}/trackings?pageId=${pageId}&limit=${limit}&filters=${JSON.stringify(filters)}&searchQuery=${JSON.stringify(searchQueryValue)}`);
    return dispatch => {
        return axios(config)
            .then(res => {
                return dispatch(returnData(types.GET_TRACKING_LIST, res.data, res.status))
            })
            .catch(function (error) {
                return handleException(error)
            });
    }
}
export function getTrackingListWithNoSearch () {

    let pageId = 1
    let limit = localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : DEFAULT_TRACKING_LIMIT
    let config = ''
    config = getInstance('GET', `${prefix}/trackings?pageId=${pageId}&limit=${limit}`);
    return dispatch => {
        return axios(config)
            .then(res => {
                return dispatch(returnData(types.GET_TRACKING_LIST, res.data, res.status))
            })
            .catch(function (error) {
                return handleException(error)
            });
    }
}
export function getTrackingDetails(trackingId) {
    let config = getInstance('GET', `${prefix}/trackings/${trackingId}`);
    return dispatch => {
        return axios(config)
            .then(res => {
                if (res && res.data) {
                    if (res.data.meta && res.data.meta.code === 200) {
                        return dispatch(returnData(types.TRACKING_DETAILS, res.data.data, res.status))
                    }
                }
            })
            .catch(function (error) {
                return handleException(error)
                // return {
                //     status: error.response.status,
                //     data: error.response.data
                // }
            });
    }
}
export function deleteTracking(trackingId) {
    let config = getInstance('DELETE', `${prefix}/trackings/${trackingId}`);
    return dispatch => {
        return axios(config)
            .then(res => {
                if (res && res.data) {
                    return dispatch(returnData(types.DELETE_TRACKING, res.data, res.status))
                }
            })
            .catch(function (error) {

                return {}
                // return {
                //     status: error.response.status,
                //     data: error.response.data
                // }
            });
    }
}
export function clearShipmentError() {
    return dispatch => {
        dispatch({
            type: types.CLEAR_SHIPMENT_ERROR,
            data: ''
        })
    }
}


export function clearTrackingDetails() {

    return dispatch => {
        dispatch({
            type: types.CLEAR_TRACKING_DETAILS,
            data: ''
        })
    }
}

export function setDataForTrackingDetail(trackingDetail) {
    return dispatch => {
        dispatch({
            type: types.SET_TRACKING_DETAIL,
            data: trackingDetail
        })
    }
}

export function setDataForSearchDetail(trackingDetail) {
    return dispatch => {
        dispatch({
            type: types.SET_SEARCH_TRACKING_DETAIL,
            data: trackingDetail
        })
    }
}

export function displayEditTrackingErrorMessage(data) {
    return dispatch => {
        dispatch({
            type: types.EDIT_TRACKING_ERROR,
            data: data
        })
    }
}
export function clearTrackingList() {
    return dispatch => {
        dispatch({
            type: types.CLEAR_TRACKING_LIST,
        })
    }
}

export function startPaginationLoader(flag = 0) {
    return dispatch => {
        dispatch({
            type: types.START_TRAKING_LIST_LOADER,
            data: flag
        })
    }
}

export function endPaginationLoader() {
    return dispatch => {
        dispatch({
            type: types.END_TRAKING_LIST_LOADER,
        })
    }
}

export function clearTagList() {
    return dispatch => {
        dispatch({
            type: types.CLEAR_TAG_LIST,
            data:[]
        })
    }
}


export function toggleFlashMessage(data) {
    return dispatch => {
        dispatch({
            type: types.TRACKING_SHOW_FLASH_MESSAGE,
            data: data
        })
    }
}
export function setTrackingFlashMessage(data) {
    return dispatch => {
        dispatch({
            type: types.TRACKING_FLASH_MESSAGE,
            data: data
        })
    }
}
export function setTrackingFilters(data) {
    return dispatch => {
        dispatch({
            type: types.TRACKING_LIST_GLOBAL_FILTERS,
            data: data
        })
    }
}


export function setCourierList(data) {
    return dispatch => {
        dispatch({
            type: types.SET_ADDED_COURIER_LIST,
            data: data
        })
    }
}
export function setSubUserList(data){
    return dispatch => {
        dispatch({
            type: types.SET_ADDED_SUBUSER_LIST_FILTER,
            data: data
        })
    }
}

export function setStatusArrayValueList(data) {
    return dispatch => {
        dispatch({
            type: types.SET_TRACKING_STATUS_ARRAY_VALUE_LIST,
            data: data
        })
    }
}
export function setCourierArrayValueList(data) {
    return dispatch => {
        dispatch({
            type: types.SET_COURIER_LIST_ARRAY_VALUE_LIST,
            data: data
        })
    }
}
export function setOriginCountryList(data) {
    return dispatch => {
        dispatch({
            type: types.SET_ORIGIN_COUNTRY_ARRAY_VALUE_LIST,
            data: data
        })
    }
}
export function setDestinationCountryList(data) {
    return dispatch => {
        dispatch({
            type: types.SET_DESTINATION_COUNTRY_ARRAY_VALUE_LIST,
            data: data
        })
    }
}
export function setTransitionDays(data) {
    return dispatch => {
        dispatch({
            type: types.SET_TRANSITION_DAYS,
            data: data
        })
    }
}

export function isShipmentAdd(data) {
    return dispatch => {
        dispatch({
            type: types.IS_SHIPMENT_ADD,
            data: data
        })
    }
}
export function getCarrierList() {
    let config = getInstance('GET', `${prefix}/couriers/list`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}
export function getFiltersList() {
    let config = getInstance('GET', `${prefix}/trackings/filters`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}

export function editTracking(requestParams, trackingID) {

    let config = getInstance('PUT', `${prefix}/trackings/${trackingID}`, requestParams);
    return axios(config).then(res => res).catch(error => {
        return handleException(error)
    })
}

export function setTagList(data){
    return dispatch => {
        dispatch({
            type: types.SET_ADDED_TAGS,
            data: data
        })
    }
}

export function setFilterTagList(data){
    return dispatch => {
        dispatch({
            type: types.FILTER_TAG_LIST,
            data: data
        })
    }
}

export function addTagToShipment(requestParams){
    let config = getInstance('PUT', `${prefix}/tracking/tag/`, requestParams);
    return axios(config).then(res => res).catch(error => {
        return handleException(error)
    })
}

export function setSelectedShipmentStatus(tab) {

    return dispatch => {
        dispatch({
            type: types.GET_SELECTED_SHIPMENT_TAB,
            data: tab
        })
    }
}

export function deleteMultipleShipment(requestParams) {
    let config = getInstance('POST', `${prefix}/tracking/bulk/delete`, requestParams);
    return axios(config).then(res => res).catch(error => {
        return handleException(error)
    })
}

export function updateShipmentStatus(requestParams){
    let config = getInstance('POST', `${prefix}/tracking/bulk/update/status`, requestParams);
    return axios(config).then(res => res).catch(error => {
        return handleException(error)
    })
}


export function assignUnassignSubUserToShipment(requestParams){
    let config = getInstance('POST', `${prefix}/tracking/bulk/assign_or_unassign_user`, requestParams);
    return axios(config).then(res => res).catch(error => {
        return handleException(error)
    })
}

export function getCountryList() {
    let config = getInstance('GET', `${prefix}/country/list`);
    return axios(config).then(res =>
        res.data)
        .catch(function (error) {
            return handleException(error)
        })
}