import React from 'react'
import { withRouter } from 'react-router-dom'
import { Line } from 'rc-progress';
import {FileDropZone} from './fileDropZone'
import axios from 'axios'
import {getInstance} from '../../src/config/token'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { ImportButtonIcon, CloseIcon, cancelIconSettings } from '../common/imageList'
import IconButton from '@material-ui/core/IconButton'
class ImportTrackings extends React.Component {
    constructor(props) {
        super(props)
    this.state = {
        fileUrl: null,
        isActionEnabled: false,
        isImportInvoked: false,
        importStatus: null,
        showLoader : false
    }
}

    fileUploadStartedHandler = (file) => {
        this.setState({ fileUrl: null});
    }

    fileUploadSuccessHandler = (url) => {
        this.setState({ fileUrl: url, isActionEnabled: true});  
    }

    fileUploadErrorHandler = (error) => {
        this.setState({ fileUrl: null, isActionEnabled: false });
    }

    resetHandler = () => {
        this.setState({ fileUrl: null, isActionEnabled: false });
    }

    importHandler = (event) => {
        
        event.stopPropagation();
        const { isActionEnabled, fileUrl } = this.state;     
        if (!fileUrl || !isActionEnabled) return;
        this.setState({ isActionEnabled: false, isImportInvoked: true, showLoader : true });
        let request = { url: fileUrl };
        const config = getInstance('POST', `/import/tracking`,request)
        axios(config).then(res => {           
           setTimeout(() => {
                this.setState({ isActionEnabled: false, isImportInvoked: false, showLoader : false });
               this.props.closeDrawer()
               this.props.setExportMessage()
            },2000)                      
        }).catch(err => {
            console.log(err)
        })      
    }

    componentWillUnmount() {
        this.resetHandler();
    }
    toggleDrawer = (open) => (event) => {
        this.props.closeDrawer()
        this.resetHandler();
    };
    render() {  
        const { classes } = this.props
        const {isActionEnabled, importStatus, isImportInvoked } = this.state;
        const progress = (importStatus ? importStatus : 0);
        const isImportEnabled = isActionEnabled;
       
        return (
            <React.Fragment>
                <Drawer
                        classes={{
                            paper: classes.detailTrackingDrawerPaper,
                        }}
                        variant="temporary"
                        anchor="right"
                        open={this.props.openDrawer}
                        onClose={this.toggleDrawer()}
                        BackdropProps={{ invisible: true }}
                        transitionDuration={500}
                    >
                        <Grid container className={classes.drawerHeader}>
                        <Typography className={classes.drawerTitle}>Import Shipment</Typography>
                        <IconButton
                            color="inherit"
                            onClick={this.toggleDrawer(false)}
                            className={classes.draweCancelButton}
                            edge="start"
                        >
                            <img alt="" src={cancelIconSettings} />
                        </IconButton>
                    </Grid>
                        <Grid container className={classes.DetaildrawerTrackingTextFieldContainer}>
                            <div className="detailDrawerContent">
                                <Typography className={classes.drawerContentText}>
                                To Import <a href="https://trackhive-images.s3-ap-southeast-1.amazonaws.com/template/Shipments_import.xls"><u style={{"cursor": "pointer"}}> Download a template</u></a>
                                </Typography>
                            </div>
              <div className="importWrapper">
                            <div className="ModalContainer">
                        {/* <div className="ModalHeading">{importStatus ? 'Importing Shipments' : 'Import Shipments'}</div> */}
                        {(importStatus || isImportInvoked)
                        ? (
                            <div className="importSave" >
                                <div className="ModalText">
                                    <p className="text"><a>This may take few minutes.</a></p>
                                </div>
                                <Line
                                    percent={progress}
                                    trailColor="#EBECED"
                                    strokeWidth="3"
                                    trailWidth="3"
                                    strokeColor="#31A5F7"
                                    className="ProcessBar" />
                            </div>
                        )
                        : (
                            <div>
                                <FileDropZone
                                    onError={this.fileUploadErrorHandler}
                                    onSuccess={this.fileUploadSuccessHandler}
                                    onStarted={this.fileUploadStartedHandler}
                                    onReset={this.resetHandler} />                               
                                {/* <div className="checkbox-update-existing"></div>  */}
                            </div>
                            )
                        }
                    </div>    
                    </div>
                        </Grid>
                        <footer className={classes.trackingDrawerFooter} container="true">
                        <Button
                            onClick={this.importHandler}
                            startIcon={<ImportButtonIcon />}
                            disabled={!isImportEnabled}
                            className={(!isImportEnabled ) ? classes.saveBtnDisable : classes.saveBtn}>
                            IMPORT
                        </Button>
                        {(this.state.showLoader) && <CircularProgress size={22} className={classes.buttonProgress} />}
                        <Button startIcon={<CloseIcon />} onClick={this.toggleDrawer(false)} className={classes.deleteButtonAction}>
                            CANCEL
                              </Button>
                        </footer>
                    </Drawer>
            </React.Fragment>
        )
    
    }
}

export default withRouter(ImportTrackings)
