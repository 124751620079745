import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import {withStyles} from '@material-ui/core/styles';
import TextField  from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinearProgress from '@material-ui/core/LinearProgress';
export const StyledTextField = withStyles({
    root: {
        width: '340px',
        "& label.Mui-focused": {
            color: '#1a73e8'
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: '#1a73e8'
            }
        }
    }
})(TextField);

export const LinearProgressBar = withStyles({
    root: {
        width: '100%',
        position: 'absolute',
        zIndex : 100
    }
})(LinearProgress)

export const TrackingTextField = withStyles({
    root: {
        width: '282px',
        "& label.Mui-focused": {
            color: '#1a73e8'
        },
        "& .MuiOutlinedInput-root": {
            height: "58px",
            "&.Mui-focused fieldset": {
                borderColor: '#1a73e8'
            }
        },
        "& .MuiInputLabel-outlined": {
            height: '19px',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.16px',
            color: '#666666',
        }
    }
})(TextField);

export const ListTextField = withStyles({
    root: {
        width: '100%',
        "& label.Mui-focused": {
            color: '#1a73e8'
        },
        "& .MuiOutlinedInput-root": {
            height: "30px",
            "&.Mui-focused fieldset": {
                borderColor: '#1a73e8'
            }
        },
        "& .MuiInputLabel-outlined": {
            height: '19px',
            fontFamily: 'Roboto',
            fontSize: '15px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.16px',
            color: '#666666',
        },
        "& .MuiAutocomplete-inputRoot":{
            "& .MuiAutocomplete-input" : {
                fontSize : '14px',
                padding : '1px 4px 2px 4px',
                height: '0.6rem',
                minWidth : "30px",
                maxWidth : "100px"
            },
            paddingRight : "10px !important"
        }
    }
})(TextField);

export const StyledAutoComplete = withStyles({
  root: {
    width: '340px',
    "& .MuiAutocomplete-inputRoot":{
        flexWrap: 'nowrap'
    }
  }  
})(Autocomplete);

export const StyledCard = withStyles({
    root:{
        minWidth: 275,
        height: "48px",
        padding: "13px 0px 12px 9px",
        borderRadius: "unset",
        position: "relative",
        width: "100%",
        fontFamily: "Roboto"
    }
})(Card)

export const StyledTypography = withStyles({

})(Typography)

export const BreadCrumbs = withStyles({

})(Breadcrumbs)

export const Image = withStyles({

})(Avatar)
