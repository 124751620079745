
import React from 'react';
import '../static/css/app.scss'
import { firebase } from '@firebase/app';
import '@firebase/database';
import '@firebase/auth';
import { firebase_basepath } from '../../src/Setup/common/constants';
import { scrollbar_notification_list } from '../../src/Setup/common/constants'
import { connect } from 'react-redux';
import { unreadMessageCount, updateCount } from './actions/actions';
import moment from 'moment-timezone';
import { getUserStatus } from '../index';
import { notify_export } from '../images'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { notificationIcon, closeIcon } from '../common/imageList'
import Scrollbars from 'react-custom-scrollbars'
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress';
import { getInstance } from '../config/token'
import axios from 'axios'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
const prefix = ''
const style = theme => ({
  container: {
    width: 396,
    height: 468,
    position: 'absolute',
    top: 48,
    right: 64,
    objectFit: "contain",
    borderRadius: "1px",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.25)",
    backgroundColor: "#f1f3f4",
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16
  },
  icon: {
    marginLeft: 90,
    display: 'block',
    float: 'left',
    //top: '2px',
    position: 'absolute'
  },
  image: {
    width: 16,
    height: 16,
    position: 'relative',
    right: '7px'
  },
  header: {
    padding: 0,
    height: 25,
    width: 332,
    //paddingLeft:0
  },
  gridItem: {
    marginBottom: 16
  },
  card: {
    padding: 16,
    width: 364,
    height: 120,
    backgroundColor: '#ffffff',
    borderRadius: 1,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
    objectFit: "contain",
  },
  textContent: {
    paddingLeft: 0,
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: '#757575',
    height: 50,
    position: 'relative',
    bottom: 4
  },
  title: {
    textAlign: "left",
    width: 111,
    paddingTop: 1,
    paddingBottom: 1,
    height: 18,
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.15,
    color: '#212121'
  },
  closeButton: {
    //marginLeft: 'auto',
    //position: 'relative',
    bottom: 16,
    //float: 'right'
    float: 'left',
    right: -218
  },
  topHeader: {
    width: '380px',
    height: '18px',
    marginTop: 19,
    marginBottom: 23,
    //marginLeft: 16,
  },
  subHeader: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#757575',
    position: 'relative',
    marginTop: 9,
    marginRight: 8
  },
  link: {
    padding: '0px',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.14,
    color: '#1a73e8',
    textDecoration: 'none',
    marginRight: '16px'
  },
  card__actions: {
    padding: 0,
    //paddingLeft: 0,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textDecoration: 'none'
  },
  headerTitle: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#212121',
    position: 'relative',
    bottom: 3,
    right: 20
  },
  lineRemover: {
    "& a": {
      color: '#1a73e8',
      textDecoration: "none"
    }
  },
  buttonProgress: {
    color: "primary",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -70,
    marginLeft: -30
  },
  noResultText: {
    color: 'black',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    textAlign: 'center',
    position: 'relative',
    top: '40%'
  }
});


//const _TENANT_ID=JSON.parse(localStorage.getItem("trackhiveToken")) ? JSON.parse(localStorage.getItem("trackhiveToken")).user_details.tenant_id : '';
const mapStateToProps = state => ({
  NOTIFY_COUNT: state.settings.list.NOTIFY_COUNT,
  notify_count_snapshot: state.settings.list.notify_count_snapshot
})
class NotificationBell extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      notificationList: false,
      listBlock: [],
      listBlockLoader: true,
      today_content: '',
      last_week_content: '',
      no_result: false,
      notification_counts: false,
      open: false
    };
    this.notificationList = this.notificationList.bind(this);
    this.firebaseAuth = this.firebaseAuth.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.props.unreadMessageCount();
    if (newProps.notify_count_snapshot && newProps.notify_count_snapshot > 0) {
      this.setState({ notification_counts: true })
    }
  }

  handleDelete = (key) => {
    let config = getInstance('DELETE', `${prefix}/firebase/notification/${key}`)
    axios(config).then(response => {
      if (response && response.status >= 200 && response.status <= 299) {
        this.notificationListClick(true)
      }
    }).catch(err => {
      console.log(err)
    })
  }
  notificationListClick = (state) => {
    this.setState({ notificationList: state })
    //var userId = firebase.auth().currentUser.uid;
    this.props.hide_unread_notify();
    //updates['/'+_TENANT_ID+'/'+userId+'/last_notify/timestamp'] = firebase.database.ServerValue.TIMESTAMP;
    //updates['/'+_TENANT_ID+'/'+userId+'/unreadMessageCount'] = 0;
    //firebase.database().ref().update(updates);

    if (this.props.unread_notify && this.props.notify_count_snapshot > 0) {
      this.hideCount()
    }
    if (this.state.notification_counts && this.props.notify_count_snapshot > 0) {
      this.hideCount()
    }
    this.notificationInfo();
  }

  hideCount = () => {
    this.setState({ notification_counts: false })
    this.props.updateCount()
  }

  toggle = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.notificationListClick(false)
    this.setState({ open: open })
  };

  notificationInfo = () => {
    var _TENANT_ID = JSON.parse(localStorage.getItem("trackhiveToken")) ? JSON.parse(localStorage.getItem("trackhiveToken"))._id : '';
    this.firebaseAuth(_TENANT_ID);
  }

  firebaseAuth = (_TENANT_ID) => {
   
    var snapshots = '';
    var self = this;
    getUserStatus()
      .then(function (uid) {
        if (uid) {
          var userId = uid;
          var currentTimestamp = moment().unix().toString();
          var startAtTimestamp = currentTimestamp;
          var ref_path = firebase_basepath + _TENANT_ID + '/' + userId + '/lastTimestamp/';
          firebase.database().ref(ref_path).limitToFirst(1).once('value', function (snapshot) {
            if (snapshot.exists()) {
              startAtTimestamp = snapshot.val().timestamp;
            }
          });
          firebase.database().ref(firebase_basepath + _TENANT_ID + '/' + userId + '/notifications/').orderByChild('timestamp').endAt(startAtTimestamp + 1).limitToLast(20).once('value').then(function (snapshot) {
            snapshots = snapshot.val();
            return snapshots;
          }).then(function (notificationsnapshots) {
            return self.notificationList(notificationsnapshots);
          }).catch(function (error) {
            self.setState({ listBlockLoader: false, no_result: `You're all caught up.` });
          });

        } else {
          // No user is signed in.
        }

      });
  }

  notificationList = (snapshots) => {
    var snapshots_array = [];
    Object.entries(snapshots).map(([key, list]) => {
      let key1 = key;
      let list1 = list;
      snapshots_array.push({ "key": key1, "list": list1 });
      return snapshots_array
    });
    //this.setState({listBlock:snapshots_array.reverse(),listBlockLoader:false});
    this.setState({ listBlock: snapshots_array.reverse(), listBlockLoader: false, no_result: false });

  }

  listClick = (key) => (e) => {
    var notificatoin_id = key;
    var userId = firebase.auth().currentUser.uid;
    var updates = {};
    var _TENANT_ID = JSON.parse(localStorage.getItem("trackhiveToken")) ? JSON.parse(localStorage.getItem("trackhiveToken")).user_details.tenant_id : '';
    this.firebaseAuth(_TENANT_ID);
    updates[firebase_basepath + _TENANT_ID + '/' + userId + '/notifications/' + notificatoin_id + '/updatable'] = false;
    firebase.database().ref().update(updates);
    this.notificationListClick();
  }
 
  componentDidMount() {
    this.props.unreadMessageCount();
    if(this.props.notify_count_snapshot && this.props.notify_count_snapshot > 0){
      this.setState({notification_counts: true})
    }
  }
  dateConverter(param) {
    // var modifiedDate = param.toString() var dateStr = modifiedDate.replace(" ",
    // "T") let modifiedTime = dateStr+"Z"

    var date = new Date(param)
    let newDate = moment(date)
      .tz(localStorage.getItem('TIMEZONE'))
      .format(localStorage.getItem('date_format') + ', HH:mm:ss')
    newDate.toString()
    return newDate
  }
  render() {
    const { classes } = this.props
    return <React.Fragment>
      <IconButton
        color="inherit"
        onClick={() => { this.notificationListClick(true) }}
        className={classes.icon}
        edge="start"
      >
        <Badge badgeContent={this.state.notification_counts && this.props.notify_count_snapshot !== 0 ? this.props.notify_count_snapshot : 0}
          color="secondary"></Badge>
        <img alt="" src={notificationIcon} />
      </IconButton>
      {
        this.state.notificationList ?
        <ClickAwayListener onClickAway={this.toggle(true)}>
          <Grid container className={classes.container}>
            {this.state.notificationList ? <Grid container className={classes.topHeader}>
              <Typography className={classes.title}>NOTIFICATIONS</Typography>
              <IconButton
                onClick={this.toggle(true)}
                className={classes.closeButton}
              >
                <img alt="" src={closeIcon}></img>
              </IconButton>
            </Grid> : null}

            <Scrollbars autoHide style={scrollbar_notification_list}>
              {
                this.state.notificationList ?
                  this.state.listBlockLoader ? <CircularProgress size={50} className={classes.buttonProgress} />
                    : this.state.no_result ? <div className={classes.noResultText}> {this.state.no_result} </div> : this.state.listBlock.map((snapshot, key) => {
                      var list = snapshot.list;
                      let notificationKey = snapshot.key;
                      var timestamp_format = this.dateConverter(list.timestamp);
                      var first = list.message.substring(0, list.message.indexOf(".", 1) + 1)
                      let title = first.search("export") > 1 ? 'EXPORT' : 'IMPORT'
                      let titleText = list.type ? list.type : title
                      var second = list.message.substring(list.message.indexOf("<a"), list.message.indexOf("a>") + 2)
                      return (< Grid className={classes.gridItem}>
                        <Card className={classes.card}>
                          <CardHeader className={classes.header}
                            avatar={
                              <Icon className={classes.image}>
                                <img alt="" src={notify_export} />
                              </Icon>
                            }
                            action={
                              <div className={classes.subHeader}>{timestamp_format}</div>
                            }
                            title={<Typography className={classes.headerTitle}>{titleText}</Typography>}
                          />
                          <CardContent className={classes.textContent}>
                            {first}
                          </CardContent >
                          <div className={classes.card__actions}>
                            <Button className={classes.link}>
                              <div className={classes.lineRemover} dangerouslySetInnerHTML={{ __html: second }}>
                              </div>
                            </Button>
                            <Button className={classes.link}
                              onClick={(notificationId) => this.handleDelete(notificationKey)}
                            >
                              DISMISS
                          </Button>
                          </div>
                        </Card>
                      </Grid>)
                    })
                  : ''
              }</Scrollbars></Grid></ClickAwayListener> : null
      }
    </React.Fragment >
  }
}

export default connect(mapStateToProps, { unreadMessageCount, updateCount })(withStyles(style, { withTheme: true })(NotificationBell));
