import React from 'react';
import { trackhiveLogo } from '../common/imageList'
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    accountTitle: {
        color: "#4f4f4f",
        textAlign: "center"
    },
    media: {
        height: 34,
        width: 154,
        margin: "0 auto",
    },
    accountContent: {
        paddingBottom: "0px"
    }
});

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
        <CardMedia
            className={classes.media}
            image={trackhiveLogo}
            title="Trackhive"
        />
        <CardContent className={classes.accountContent}>
            <Typography gutterBottom className={classes.accountTitle} variant="h5">
               {props.content}
            </Typography>
        </CardContent>
    </React.Fragment>
  );
}
