import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import AccordionAceEditor from './AceEditor';
import 'brace/mode/html';
import 'brace/theme/xcode';
import 'brace/ext/language_tools';
import '../../assets/css/emailSettings.scss';
import FlashMessage from '../../common/FlashMessage';
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { ExpandIcon } from '../../../common/imageList';
import {CUSTOM_TRACKING_ORDER_OPTIONS, CUSTOM_TRACKING_ORGANIZATION_OPTIONS, CUSTOM_TRACKING_OPTIONS, TEMPLATE_UPDATED_SUCCESSFULLY_MESSAGE} from '../../common/constants'
import {editTrackingTemplate,getSingleTemplateDetails,setSettingsFlashMessage,toggleSettingsFlashMessage} from '../../actions/actionMethods'
class EditTrackingPage extends React.Component {
    editor1 = {};
    currentEditor = 'editor1'
    constructor(props) {
        super(props);
        this.isSuccess = true
        this.state = {
            templateName: '',
            templateNameError: false,
            aceValue: '',
            showLoader: false,
            customFields: false,
            selectedTemplateSize: '',
            selectedPrintOption: { label: '8.27 x 11.69 in (A4)', value: 'a4' },
            selectedGroupbyOption: 'location',
            selectedPageOption: 'landscape',
            isConsolidated: false,
            isIncludedExtraItem: true,
            isLoading: false,
            showPreview: false,
            expanded: "panel1"
        };
    }

    UNSAFE_componentWillMount(){
        if(this.props.match.params.id && this.props.match.params.id !== '') {
            getSingleTemplateDetails(this.props.match.params.id).then( res =>{
                if(res && res.meta && res.meta.code === 200){
                    this.setState({
                        templateName:res.data.template_name,
                        aceValue:res.data.body
                    }, () =>{
                        this.props.setHTMLValue(this.state.aceValue)
                    })
                }
            })
        }
    }

    UNSAFE_componentWillReceiveProps(){
        if(this.props.editID && this.props.editID !== '') {
            getSingleTemplateDetails(this.props.editID).then( res =>{
                if(res && res.meta && res.meta.code === 200){
                    this.setState({
                        templateName:res.data.template_name,
                        aceValue:res.data.body
                    }, () =>{
                        this.props.setHTMLValue(this.state.aceValue)
                    })
                }
            })
        }
    }

    handleChangeTemplateName = (value) => {
        this.setState({
            templateName: value,
            templateNameError: false
        })
    }
    
    handleInsertValue = (value) => {
        if (this.currentEditor !== '') {
            let editor = this[this.currentEditor]
            let cursorPosition = editor.getCursorPosition();
            editor.session.insert(cursorPosition, value);
        }
    }
    onEditorChange = (newValue, aceState) => {
        this.setState({ [aceState]: newValue })
        if (newValue !== '') {
            this.props.setHTMLValue(newValue)
        }
    }
    onEditorLoad = (value, editor) => {
        this[editor] = value
        this[editor].setFontSize(13)
    }
    
    checkValidation = () => {
        let flag = true 
        if (this.state.templateName === '') {
            this.setState({
                templateNameError: true
                
            })
            flag = false
        }
        else {
            this.setState({
                templateNameError: false
            })
        }
        if(flag){
            this.props.showFooterLoader(true)
            let requestBody = {}
            requestBody.template_name = this.state.templateName
            requestBody.body = this.state.aceValue
            let templateID = this.props.editID
            editTrackingTemplate(templateID,requestBody).then(res => {
                this.props.showFooterLoader(false)
               
                if(res){
                    if(res.status === 400){
                        this.isSuccess = false
                        this.props.toggleSettingsFlashMessage(1)
                        this.props.setSettingsFlashMessage(res.data.meta.message[0])
                    }
                    else{
                        this.isSuccess = true
                        this.props.toggleSettingsFlashMessage(1)
                        this.props.setSettingsFlashMessage(TEMPLATE_UPDATED_SUCCESSFULLY_MESSAGE)
                    }
                }
                
            })
        }
    }
    editTemplateTracking = () => {
        this.checkValidation()
    }
    handleShowPreview = () => {
        window.open('/custom_tracking_page/preview', '_blank');
    }
    toggleFlashMessage = (data) => {
        this.props.toggleSettingsFlashMessage(data)
        this.props.setSettingsFlashMessage('')
    }
    handleClose = () => {
        this.setState({
            showPreview: false
        })
    }
    handleChange = panel => (event, isExpanded) => {
        this.setState({expanded: isExpanded ? panel :false})
    }

    render() {
        const {classes} = this.props
        let customTrackingMenu = []
        let customTrackingOrderMenu = []
        let customTrackingOrganizationMenu = []
        customTrackingMenu =  CUSTOM_TRACKING_OPTIONS.map((option, index) => {
          return <MenuItem className={classes.expansionItem} key={index} id="trackingMenuAction" onClick={(value => this.handleInsertValue(option))}>
                   {option}    
                 </MenuItem>
        })
        customTrackingOrderMenu = CUSTOM_TRACKING_ORDER_OPTIONS.map((option, index) => {
          return <MenuItem className={classes.expansionItem} key={index} id="orderMenuAction" onClick={(value => this.handleInsertValue(option))}>
                    {option}    
                 </MenuItem>
        })
        customTrackingOrganizationMenu = CUSTOM_TRACKING_ORGANIZATION_OPTIONS.map((option, index) => {
            return <MenuItem className={classes.expansionItem} key={index} id="organizationMenuAction" onClick={(value => this.handleInsertValue(option))}>
                     {option}    
                   </MenuItem>
        })
        return (
            <React.Fragment >
                    <Scrollbars autoHide style={{height: window.innerHeight + 60}}>
                    <div className="customTrackingPageMainWrapper">
                        <div className="customTrackingPageInnerLeftSideDiv">
                             <TextField
                                error={this.state.templateNameError ? true : false}
                                id="page-name"
                                autoFocus={true}
                                label="Page Name"
                                variant="outlined"
                                className={classes.onFocus}
                                value={this.state.templateName}
                                onChange={(event) => this.handleChangeTemplateName(event.target.value)}
                             />
                            <div className="commonReactAceEditorMainDiv">
                                <AccordionAceEditor
                                    onEditorLoad={this.onEditorLoad}
                                    onEditorChange={this.onEditorChange}
                                    stateValue={this.state}
                                />
                            </div>
                        </div>
                        <div className="customTrackingPageInnerRightSideDiv">
                            <div className="email_insert_value" style={{ width: '90%' }}>
                            <Typography className={classes.exapansionMainTitle}>Insert Values</Typography>
                               <div className={classes.expansionPanelRoot}>
                                  <ExpansionPanel className={classes.expansionPanel} expanded={this.state.expanded === "panel1"}   onChange={this.handleChange("panel1")}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className={classes.expansionPanelSummary}
                                    >
                                      <Typography className={classes.heading}>Order</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid className={classes.gridItem}>
                                         {customTrackingOrderMenu}
                                      </Grid>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                  <ExpansionPanel className={classes.expansionPanel} expanded={this.state.expanded === "panel2"}   onChange={this.handleChange("panel2")}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className={classes.expansionPanelSummary}
                                    >
                                      <Typography className={classes.heading}>Organization</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <Grid className={classes.gridItem}>
                                         {customTrackingOrganizationMenu}
                                      </Grid>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                  <ExpansionPanel className={classes.expansionPanel} expanded={this.state.expanded === "panel3"}   onChange={this.handleChange("panel3")}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className={classes.expansionPanelSummary}
                                    >
                                      <Typography className={classes.heading}>Tracking</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <Grid>
                                         {customTrackingMenu}
                                      </Grid>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <input type="hidden" name="editTemplateTracking" id="editTemplateTracking" onClick={this.editTemplateTracking} />
                    {this.props.showSettingFlashMessageFlag === 1 ?
                                 <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                          : ''}
                </Scrollbars>
                </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, { toggleSettingsFlashMessage, setSettingsFlashMessage })(EditTrackingPage));