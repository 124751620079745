import React from 'react';
import { connect } from 'react-redux';
class Tag extends React.Component{
  
  removeTag = (tagId) => {
    this.props.deleteTag(tagId)
  }
  render(){
    if(this.props.tags.length > 0){
      var tagData = [];
      for(let i=0;i<this.props.tags.length;i++){
        let tag = this.props.tags[i];
        tagData.push(
          <div className="tag" style={{"backgroundColor":tag.tag_color, marginTop: "0.3rem" }} key={i}>
                <span className="text">{tag.tag_name}</span>
                <span className="closeTag" onClick={() => this.removeTag(tag._id)}>
                </span>
          </div>
        );
      }
      return (
        <div>
        <div className="tagsText">
        Tags
      </div>
        <div id="scroll" style={{margin: ".50rem 0 0 .0rem"}}>
          {tagData}
        </div>
        </div>
      );
    } else {
      return ''
    }
  }
}
export default connect(null)(Tag);
