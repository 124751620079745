const REACT_S3_URL = process.env.REACT_APP_S3_URL

export const menuItems = [
    {
        "title": "Overview",
        "icon": REACT_S3_URL + "menuIcons/dashboard.svg",
        "type": 1,
        "paths": "['/', '/inbox']",
        "path": '/',
        "roleValue": "dashboard",
        "activeIcon": REACT_S3_URL + "menuIcons/activedashboard.svg"
    },
    {
        "title": "Track",
        "icon": REACT_S3_URL + "menuIcons/location.svg",
        "type": 1,
        "paths": "['/tracking']",
        "path": "'/tracking'",
        "roleValue": "tracking",
        "activeIcon": REACT_S3_URL + "menuIcons/activeTrackIcon.svg",
    },
    {
        "title": "Automate",
        "icon": REACT_S3_URL + "menuIcons/automation.svg",
        "type": 1,
        "paths": "['/automations']",
        "path": "'/automations'",
        "roleValue": "automations",
        "activeIcon": REACT_S3_URL + "menuIcons/activeautomation.svg"
    },
    {
        "title": "Integrate",
        "icon": REACT_S3_URL + "menuIcons/integration.svg",
        "type": 1,
        "paths": "['/integrated_list']",
        "path": '/integrated_list',
        "roleValue": "settings",
        "activeIcon": REACT_S3_URL + "menuIcons/activeIntegrationIcon.svg",
    },
    {
        "title": "Page",
        "icon": REACT_S3_URL + "menuIcons/tracking_page+.svg",
        "type": 1,
        "paths": "['/custom_tracking_page']",
        "path": '/custom_tracking_page',
        "roleValue": "settings",
        "activeIcon": REACT_S3_URL + "menuIcons/activepageIcon.svg",
    },
    {
        "title": "Button",
        "icon": REACT_S3_URL + "menuIcons/touch.svg",
        "type": 1,
        "paths": "['/track_button']",
        "path": '/track_button',
        "roleValue": "settings",
        "activeIcon": REACT_S3_URL + "menuIcons/activetouchIcon.svg",
    }
]


export const menuItems1 = [

    {
        "title": "Support",
        "icon": REACT_S3_URL + "menuIcons/contant_us.svg",
        "type": 3,
        "paths": "['https://www.trackhive.co/contact-us/']",
        "path": 'https://www.trackhive.co/contact-us/',
        "roleValue": "settings",
        "activeIcon": REACT_S3_URL + "menuIcons/contant_us.svg",
    },
    {
        "title": "API",
        "icon": REACT_S3_URL + "menuIcons/api_documentation.svg",
        "type": 3,
        "paths": "['https://trackhive.docs.apiary.io/#']",
        "path": 'https://trackhive.docs.apiary.io/#',
        "roleValue": "settings",
        "activeIcon": REACT_S3_URL + "menuIcons/api_documentation.svg",
    }
]

export const menuItems2 = [
    {
        "title": "Settings",
        "icon": REACT_S3_URL + "menuIcons/settings.svg",
        "type": 1,
        "paths": "['/settings']",
        "path": '/settings',
        "roleValue": "settings",
        "activeIcon": REACT_S3_URL + "menuIcons/activesettings.svg",
    }
]

export const itemsmenu = [{
    "title": "Overview",
    "paths": "['/', '/inbox']",
    "path": '/'
},
{
    "title": "Track",
    "paths": "['/tracking']",
    "path": "'/tracking'"
}
    ,
{
    "title": "Automation",
    "paths": "['/automations']",
    "path": "'/automations'"
},
{
    "title": "Integration",
    "paths": "['/integrated_list', '/integrations', '/add_shopify', '/add_auspost', '/edit_auspost', '/add_wooCommerce', '/edit_wooCommerce', '/add_magento', '/edit_magento', '/add_bluedart', '/edit_bluedart','/add_cdl','/edit_cdl','/add_ninja_van','/edit_ninja_van', 'add_gw_world', 'edit_gw_world', 'add_99minutos', 'edit_99minutos']",
    "path": '/integrated_list'
},
{
    "title": "Track Page",
    "paths": "['/custom_tracking_page']",
    "path": '/custom_tracking_page'
},
{
    "title": "Track Button",
    "paths": "['/track_button']",
    "path": '/track_button'
},
{
    "title": "Support",
    "paths": "['https://www.trackhive.co/contact-us/']",
    "path": 'https://www.trackhive.co/contact-us/'
},
{
    "title": "API",
    "paths": "['https://trackhive.docs.apiary.io/#']",
    "path": 'https://trackhive.docs.apiary.io/#'
},
{
    "title": "Settings",
    "paths": "['/settings','/organization','/webhook','/custom_email_template','/edit_email_template','/email_alerts','/custom_domain','/tags','/sub_users', '/developer_apps']",
    "path": '/settings'
}]