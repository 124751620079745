const drawerWidth = 256;
const HeaderStyle = theme => ({
    grow: {
        flexGrow: 1,
    },
    root: {
        display: 'flex'
    },
    'button-link-drawer-open': {
        width: 240,
        height: 40,
        paddingLeft: '8px !important',
        borderRadius: 4,
    },
    'button-link-drawer-close': {
        width: 72,
        height: 72,
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
    },
    imageIcon: {
        width: 24,
        height: 24,
        position: 'relative',
        bottom: 1,
        objectFit: 'contain'
    },
    imageIconClose: {
        width: 24,
        height: 24,
        marginTop: 8,
        marginLeft: 24,
        objectFit: 'contain'
    },
    imageIconCloseSideBar: {
        width: 24,
        height: 24,
        marginTop: 10,
        marginLeft: 24,
        bottom: 15,
        position: 'relative',
        objectFit: 'contain'
    },
    appBar: {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "#1a73e8",
        height: 48,
        boxShadow: 'none',
        zIndex: 10
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `100%`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    menuButton: {
        marginRight: 22
    },
    hide: {
        display: 'none',
        height: 0
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: "#ffffff",
    },
    list: {
        marginTop: 4,
        marginBottom: 4
    },
    drawerOpen: {
        height: 2042,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: "8px 0 8px 0 rgba(0, 0, 0, 0.1)"
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 73,
        backgroundColor: '#ffffff',
        marginTop: 48,
        overflow : 'hidden'
       
    },
    override: {
        height: '100%'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    linkActive: {
        borderRadius: 4,
        backgroundColor: '#e3eefc',
    },
    linkText: {
        height: '16px',
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px',
        color: '#727272',
        display: "inline-block",
        position: 'relative',
        bottom: '28px',
        marginLeft: '56px'
    },
    fixlinkText: {
        height: '16px',
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px',
        color: '#727272',
        display: "inline-block",
        position: 'relative',
        bottom: '1px'
    },
    linkTextClose: {
        height: '16px',
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        display: "inline-block",
        letterSpacing: '0.14px',
        color: '#727272',
        textAlign: 'center',
        width: 72
    },
    link: {
        height: 40,
        width: 240,
        borderRadius: 4,
        'text-decoration': 'none',
        paddingTop: 8,
        paddingLeft: 8,
        paddingBottom: 8
    },
    closelink: {
        width: 72,
        height: 72,
        'text-decoration': 'none'
    },
    linkTextCloseSidebar: {
        height: '16px',
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px',
        color: '#727272',
        textAlign: 'center',
        width: 72,
        marginLeft: '-3.4rem',
        position: 'relative',
        bottom: '-15px'
    },
    listopen: {
        paddingTop: '4px !important',
        paddingBottom: '4px !important',
    },
    listclose: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
    },
    topListclose: {
        paddingTop: '8px !important',
        paddingBottom: '0px !important',
    },
    listitem: {
        width: 256,
        height: 48,
        paddingLeft: '8px !important',
        paddingRight: '8px !important'
    },
    closelistitem: {
        width: 72,
        height: 72,
        paddingLeft: '0px !important',
        paddingRight: '0px !important'

    },
    closelistitemSideBar: {
        width: 72,
        height: 72,
        paddingLeft: '0px !important',
        paddingRight: '0px !important'
    },
    bigAvatar: {
        width: 40,
        height: 40,
        'object-fit': 'contain',
        marginTop: 16,
        marginLeft: 16
    },
    labelText: {
        height: '23px',
        fontFamily: 'Roboto',
        fontSize: 20,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px',
        color: '#212121',
        marginTop: 20,
        marginLeft: 16,
    },
    emailText: {
        height: '16px',
        fontFamily: 'Roboto',
        fontSize: 14,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px',
        color: '#666666',
        marginTop: 5,
        marginLeft: 16,
        marginBottom: 15,
    },
    titleText: {
        fontFamily: 'Roboto',
        fontSize: 20,
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.2px',
        color: '#ffffff'
    }
})
export default HeaderStyle