import React from 'react';
import Button from '@material-ui/core/Button';
import '../../../../assets/css/profileStyle.scss'
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledTextField } from '../../../../common/materialComponents';

class MagentoDetailsForm extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                <div className="settingsRightSidePanel">
                    <div className="commonSettingsBox profileRightSideMainDiv">
                            <div className="customFormContainer" >
                                <div className="input_wrap">
                                    <div className="form-attribute">
                                        <StyledTextField
                                            error={this.props.carrierNameError ? true : false}
                                            id="CarrierName"
                                            label="Store Name"
                                            variant="outlined"
                                            name="CarrierName"
                                            value={this.props.carrierName}
                                            onChange={e => this.props.handleCarrierName(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-attribute">
                                        <StyledTextField
                                            error={(this.props.storeURLError || !this.props.isValidURL) ? true : false}
                                            id="StoreURL"
                                            label="Store URL"
                                            variant="outlined"
                                            name="StoreURL"
                                            value={this.props.storeURL}
                                            onBlur={this.checkStoreURL}
                                            onChange={e => this.props.handlestoreURL(e.target.value)}
                                            helperText={!this.props.isValidURL && this.props.storeURLError !== '' ? 'Invalid Url' : ''}
                                        />
                                    </div>
                                </div>
                                <div className="input_wrap">
                                    <div className="form-attribute">
                                        <StyledTextField
                                            error={this.props.apiKeyError ? true : false}
                                            id="ApiKey"
                                            label="Access Token"
                                            variant="outlined"
                                            name="ApiKey"
                                            value={this.props.apiKey} onChange={e => this.props.handleApiKey(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-attribute">
                                        <StyledTextField
                                            error={this.props.secretKeyError ? true : false}
                                            id="SecretKey"
                                            label="Store ID"
                                            variant="outlined"
                                            name="SecretKey"
                                            value={this.props.secretKey}
                                            onChange={e => this.props.handleSecretKey(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className={classes.saveBtnRoot}>
                                    <div className={classes.wrapper} >
                                        <Button
                                            variant="contained"
                                            className={classes.saveBtn}
                                            disabled={this.props.showSaveLoader}
                                            onClick={this.props.handleSubmit}
                                        >
                                            SAVE
                                        </Button>
                                        {this.props.showSaveLoader && <CircularProgress size={22} className={classes.buttonProgress} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </React.Fragment>
        )
    }
}
export default MagentoDetailsForm