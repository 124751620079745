import React from 'react';
import { withRouter } from 'react-router-dom';
import IntegrateStore from '../IntegrateStore';
import Styles from '../../../../assets/css/Styles';
import { withStyles } from '@material-ui/core/styles';
import BlueDartInstructions from './BlueDartInstructions';
import { BLUEDART } from '../../../../../common/imageList';
import BreadCrumbsCard from '../../BreadCrumbsCard';
import IntegrationLogo from '../../IntegrationLogo';
import FlashMessage from '../../../../common/FlashMessage';
import { LinearProgressBar } from '../../../../common/materialComponents';

class BlueDart extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess = false
        this.state = {
            error: false,
            showSaveLoader: false,
            updateToggleMessage: false,
            success: false,
            successErrMsg: '',
            blueDartStoreActive: false,
            blueDartPostStoreLoader: this.props.match.params.id ? true : false,
            blueDartForm: this.props.match.params.id === undefined ? false : true,
        }
    }

    blueDartStoreLoader = (isLoading) => {
        this.setState({blueDartPostStoreLoader: isLoading})
    }
    
    toggleFlashMessage = (data) => {
        this.setState({
            error: false,
            success: false,
            successErrMsg: ''
        })
    }

    updateToggleMessage = (data) => {
        this.isSuccess = data.isSuccess 
        this.setState({error: data.error, success: data.success, successErrMsg: data.successErrMsg, showSaveLoader: data.showSaveLoader ? data.showSaveLoader : false})
    }
    
    isBlueDartActive = (isActive) => {
        this.setState({blueDartStoreActive: isActive})
    }

  
    moveToBlueDartForm = (data) => {
        this.setState({blueDartForm: data})
    }

    backToIntegration = () => {
        this.props.history.push('/integrations')
    }

    backToIntegrationList = () => {
        this.props.history.push('/integrated_list')
    }

    render() {
        const { classes } = this.props
        return(
            <React.Fragment>
                <div style = {{display:this.state.blueDartPostStoreLoader ? 'block' : 'none'}}>
                    <LinearProgressBar/>
                </div>
                <div style = {{display:!this.state.blueDartPostStoreLoader ? 'block' : 'none'}}>
                <div className="settingsModule">
                         <BreadCrumbsCard classes={classes} storeName="Blue Dart" 
                                storeId = {this.props.match.params.id} activeStore={this.isBlueDartActive} 
                                active={this.state.blueDartStoreActive} updateToggleMessage={this.updateToggleMessage} />
                        <div className="dataContainer">
                            <IntegrationLogo classes={classes} icon = {BLUEDART} title = "Blue Dart"/>
                            <div id="settingDataScrollerWrapper">
                                <div className="settingsContainer">
                                    <div className="settingsRightSidePanel">
                                        {
                                             this.state.blueDartForm ?
                                             <IntegrateStore 
                                                 isLoading={this.blueDartStoreLoader} updateSuccessErrMsg={this.updateToggleMessage}
                                                 activeStore={this.isBlueDartActive} classes={classes} type = "BlueDart" 
                                                 storeId = {this.props.match.params.id} channelId= "6" backToIntegrationList={this.backToIntegrationList}/> 
                                             : <BlueDartInstructions classes={classes} moveToBlueDartForm={this.moveToBlueDartForm} backToIntegration={this.backToIntegration}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.error || this.state.success) && !this.state.showSaveLoader ?
                            <FlashMessage isSuccess={this.isSuccess} message={this.state.successErrMsg} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                        : ''}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(Styles)(BlueDart));
