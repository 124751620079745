import React from 'react'
import { Redirect } from 'react-router-dom'; import { connect } from 'react-redux'
import { clearError, verifyUserEmail } from './auth'
import { resetPasswordLinkMessage } from './actionMethods/actionMethods'
import {getRedirectURLParameterByName} from '../static/constants'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlashMessage from '../../src/TrackingList/common/flashMessage'
import MediaContent from '../common/userMediaCard'
import Typography from '@material-ui/core/Typography';
import { emailRegex } from '../static/constants'
import Styles from './Styles'

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoader: false,
            openSnackBar: true,
            email: '',
            isEmailError: false,
        }
    }
    handleLogin = (values) => {
        let emailId = this.state.email
        let urlParams = window.location.href.split('/login')

        if (!emailRegex.test(emailId)) {
            this.setState({isEmailError: true})
        }
        else {
            this.setState({
                showLoader: true
            })
            this.setState({ openSnackBar: true })
            this.props.verifyUserEmail(emailId, status => {
                if (status) {
                    this.setState({
                        showLoader: false
                    })
                    this.props.history.push('/userLogin'+urlParams[1]);
                }
                else {
                    this.setState({
                        showLoader: false
                    })
                }
            })
        }
    }
    componentDidMount() {
        this.props.clearError()
    }

    SignUpMethod = () => {
        let val = getRedirectURLParameterByName('utm_source')
        let signUpUrl = ''
        switch (val) {          
            case 'shopify':
                signUpUrl = '/signup?utm_source=shopify'
                break;
            case 'bigcommerce':
                signUpUrl = '/signup?utm_source=bigcommerce'
                break;
            default:
                signUpUrl = '/signup'
                break;  
              
          }
          this.props.history.push(signUpUrl)
    }

    closeFlashMessage = () => {
        this.setState({ openSnackBar: false })
        this.props.clearError()
    }

    handleChange = (event) => {
        let val = event.target.value
        this.setState({email:val})

        if (!emailRegex.test(val)) {
            this.setState({isEmailError: true})
        } else {
            this.setState({isEmailError: false})
        }
    }

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            this.handleLogin();
        }
    }

    render() {
        const { classes } = this.props;
        const { from } = this.props.location.state || {
            from: {
                pathname: '/'
            }
        }
        const { onboarding } = this.props.location.state || {
            onboarding: {
                pathname: '/onboarding'
            }
        }
        if (this.props.isLoggedIn) {
            var onboarding_val = JSON
                .parse(localStorage.getItem('trackhiveToken'))
                .onboarding
                ? JSON
                    .parse(localStorage.getItem('trackhiveToken'))
                    .onboarding
                    .status
                : 0;
            if (from && from.pathname === "/admin/oauth/request_grant")
                return <Redirect to={from.pathname} />
            else if (onboarding_val)
                return <Redirect to={onboarding} />
            else
                return <Redirect to={from} />
        }
        return (
            <div className="loginAndRegistrationCommonDiv">
                <Card className={classes.root} variant="outlined">
                    <MediaContent content="Sign in" />
                    <CardContent className={classes.loginContent}>
                        <Typography variant="body2" color="textSecondary" component="p">
                            to continue to Trackhive
                        </Typography>
                    </CardContent>
                    <TextField
                        error={this.state.isEmailError ? true : false}
                        id="email"
                        label="Email"
                        variant="outlined"
                        className={`${classes.loginTextField} ${classes.onFocus}`}
                        value={this.state.email}
                        onChange={this.handleChange}
                        helperText={this.state.isEmailError && this.state.email !== '' ? 'Email must be valid' : ''}
                        onKeyDown={this.onEnterPress}
                        autoFocus={true}
                    />
                    <CardActions className={classes.nextButtonRoot}>
                        <div className="leftBtnDiv">
                            <Button
                                onClick={this.SignUpMethod}
                                disabled={this.state.showLoader}
                                className={classes.createAcntBtn}
                                color="primary"
                            >
                            Create account
                        </Button>
                        </div>
                        <div className="rightBtnDiv">
                            <Button
                                variant="contained"
                                className={classes.saveBtn}
                                disabled={this.state.showLoader}
                                onClick={this.handleLogin}
                            >
                                Next
                            </Button>
                        </div>
                        {this.state.showLoader && <CircularProgress size={22} className={classes.buttonProgress} />}
                    </CardActions>
                </Card>
                {this.props.errorMessage && this.props.errorMessage !== '' ?
                    <FlashMessage open={this.state.openSnackBar} closeFlash={this.closeFlashMessage} classes={classes.addTrackingSnackbar} messageDetails={{ type: 'ERROR', message: this.props.errorMessage }} />
                    : ''
                }
                {
                    this.props.setpasswordEmailLinkMessage !== undefined && this.props.setpasswordEmailLinkMessage !== '' ?
                    <FlashMessage open={this.state.openSnackBar} closeFlash={this.closeFlashMessage} classes={classes.addTrackingSnackbar} messageDetails={{ type: 'SUCCESS', message: this.props.setpasswordEmailLinkMessage }} />
                    : ''
                }
            </div>
        )
    }
}
const mapStateToProps = state => ({ 
    roles: state.authenticate.roles,
    isLoggedIn: state.authenticate.isLoggedIn, 
    errorMessage: state.authenticate.verifyEmailError, 
    setpasswordEmailLinkMessage: state.authenticate.setpasswordEmailLinkMessage,
    urlRedirection:state.authenticate.urlRedirection 
})
export default (connect(mapStateToProps, { verifyUserEmail, clearError, resetPasswordLinkMessage })(withStyles(Styles)(Login)))