import React from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { getAutomationRuleList, updateAutomationRule, updateAutomationRuleListState, setAutomationDetail, setAutomationLoader, deleteAutomationRule } from '../../actions/actionMethods'
import { TRIGGER, TRIGGER_CONDITION, CONDITION_TYPE_OPERATOR, ACTION } from '../../constants/defaultValues'
import { ACTIVATE_AUTOMATION_RULE, DEACTIVATE_AUTOMATION_RULE, DELETE_AUTOMATION_RULE } from '../../constants/successMessages'
import { UNHANDLED_EXCEPTION } from '../../constants/errorMessages'
import { getLabel } from '../../common/Functions'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import moment from 'moment-timezone'
import Filters from './filter'
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch'
import styles from '../../static/css/automate'
import EditAutomation from './addAutomationDialog'
import FlashMessage from '../../../common/alertSnackBar'
import IconButton from '@material-ui/core/IconButton'
import { IconDelete } from '../../../common/imageList'
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
const headCells = [
  { id: 'switch', label: '', disablePadding: false },
  { id: 'name', label: 'Automation Name', disablePadding: false },
  { id: 'type', label: 'Type', disablePadding: false },
  { id: 'trigger', label: 'Trigger', disablePadding: false },
  { id: 'action', label: 'Actions', disablePadding: false },
  { id: 'created', label: 'Created Date', disablePadding: false },
  { id: 'modified', label: 'Modified Date', disablePadding: false },
  
];

class EnhancedTableHead extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow className={classes.headTableRow}>
          {headCells.map((headCell) => (
            <TableCell
              className={classes.headTableCell}
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'default'}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

class EnhancedTableToolbar extends React.Component {
  state = {
  }

  render() {
    const { classes } = this.props;
    return (
      <Toolbar className={classes.toolbarRoot}

        variant="regular"
        disableGutters={true}
      >
        <Filters
          searchTerm={''}
          classes={this.props.classes}
        />
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};
class EnhancedTable extends React.Component {
  dateFormat = (localStorage.getItem("date_format")) ? localStorage.getItem("date_format") : null
  constructor() {
    super();
    this.deleteId = null
    this.conditionOperatorTypeList = CONDITION_TYPE_OPERATOR
    this.state = {
      page: 0,
      rowsPerPage: 20,
      trackingDetail: {},
      isAutomationEnabled: false,
      noRecord: false,
      customEvent: [],
      loaderFlag: true,
      openDialog: false,
      showMessage: false,
      flashMessage: { type: 'NOTICE', message: '' },
      openDeleteDialog: false
    }
  }
  toggleSwitch = (automationId = '', toggleState = '') => {
    this.props.setAutomationLoader()
    let newToggleState = toggleState
    if (toggleState === 0)
      newToggleState = 1
    else
      newToggleState = 0
    let requestParams = { active: newToggleState }
    this.props.updateAutomationRule(automationId, requestParams).then(res => {
      this.props.setAutomationLoader(1)
      let message = ''
      // let isSuccess = ''
      if (res.status && !(res.status >= 200 && res.status <= 299)) {
        if (res.data.errors) {
          message = { type: 'ERROR', message: res.data.errors[0] }
        } else {
          message = { type: 'ERROR', message: UNHANDLED_EXCEPTION }
        }
        // isSuccess = false
      } else {
        this.props.updateAutomationRuleListState(automationId, requestParams)
        if (newToggleState)
          message = { type: 'SUCCESS', message: ACTIVATE_AUTOMATION_RULE }
        else
          message = { type: 'SUCCESS', message: DEACTIVATE_AUTOMATION_RULE }
        // isSuccess = true
      }
      this.setState({
        flashMessage: message,
        showMessage: true
      })
    })
  }
  toggleFlashMessage = () => {
    this.setState({
      showMessage: false
    })
  }
  // componentWillMount = () => {
  //   if(this.props.match.params.id && this.props.automationList.length > 0){
  //       let automationData = this.props.automationList.filter(x => x.id == this.props.match.params.id)[0]
  //       this.props.setAutomationDetail(automationData)
  //       this.setState({
  //         openDialog: true,
  //       })
  //     }
  // }
  componentDidUpdate(prevProps) {
    if (this.props.automationList !== prevProps.automationList || !this.state.noRecord) {
      if (this.props.automationList.length === 0) {
        this.setState({
          noRecord: true
        })
      }
    }
    if(this.props.match.params.id && this.state.openDialog !== true && this.props.automationList.length > 0){
        let automationData = this.props.automationList.filter(x => x.id == this.props.match.params.id)[0]
        this.props.setAutomationDetail(automationData)
        this.setState({
          openDialog: true,
        })
      } else if(this.props.match.params.id === undefined && this.state.openDialog === true) {
        this.setState({
          openDialog: false,
        })
      }

  }
  resetPageForFilters = () => {
    this.setState({ page: 0 });
    this.setState({ selected: [] });
  }

  reloadPageAfterAction = () => {
    this.setState({ selected: [] });
    let requestParams = {}
    requestParams.pageId = 1
    requestParams.limit = 20
    this.props.getTrackingList(requestParams)
    this.props.startPaginationLoader()
    this.props.getTrackingList(requestParams)
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.pagination(newPage + 1, this.state.rowsPerPage)
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
    this.pagination(1, event.target.value)
  };
  pagination = (page, rows) => {
    this.props.startPaginationLoader()
    let hasMore = (this.state.page * this.state.rowsPerPage) < this.props.noOfTotalTrackingRecords
    if (hasMore) {
      let requestParams = { "pageId": page, "limit": rows }
      this.props.getTrackingList(requestParams)
    }
  }
  handleDetailClick = (id, trackingDetail) => {
    if (id) {
      this.setState({ detailTrackinngDrawer: true, trackingDetail: trackingDetail })
      this.props.setDataForTrackingDetail(trackingDetail)
      this.props.clearTrackingDetails()
      // this.props.getTrackingDetails(id)
      this.props.history.push(`/tracking/${id}`)

    }
  }
  drawerOpen = () => {
    this.setState({ detailTrackinngDrawer: true })
  }
  drawerClose = () => {
    this.props.setDataForTrackingDetail('')
    this.setState({ detailTrackinngDrawer: false })
  }
  onChangeSubUser = (event, values) => {
    this.setState({
      selectedSubUser: values
    })
  }
  handleDeleteAutomationRule = () => {
    this.props.setAutomationLoader()
    if(this.deleteId) {
      let deletAutomationId = this.deleteId
      this.deleteId = null
      this.props.deleteAutomationRule(deletAutomationId).then(res => {
        this.props.history.push(`/automations`)
        this.props.setAutomationLoader(1)
        let message = ''
        if (res.status && !(res.status >= 200 && res.status <= 299)) {
          if (res.data.errors) {
            message = { type: 'ERROR', message: res.data.errors[0] }
          } else {
            message = { type: 'ERROR', message: UNHANDLED_EXCEPTION }
          }
        } else {
          message = { type: 'SUCCESS', message: DELETE_AUTOMATION_RULE }
        }
        this.setState({
          flashMessage: message,
          showMessage: true,
          openDeleteDialog: false
        })
      })
    } else {
      this.setState({
        openDeleteDialog: false
      })
    }
  }
  openDialog = (id, automationData) => {
    this.props.history.push(`/automations/edit/${id}`)
    this.props.setAutomationDetail(automationData)
    this.setState({
      openDialog: true,
    })
  }
  closeDialog = () => {
    this.setState({
      openDialog: false
    })
  }
  openDeleteDialog = (id) => {
    this.deleteId = id 
    this.setState({
      openDeleteDialog: true
    })
  }
  closeDeleteDialog = () => {
    this.deleteId = null
    this.setState({
      openDeleteDialog: false
    })
  }
  isSelected = (name) => this.state.selected.indexOf(name) !== -1;
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.trackingList.length - page * rowsPerPage);
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {
            this.props.automatioLoader === 1 ?
              null
              :
              <div className={classes.progressbar}>
                <LinearProgress color="primary" />
              </div>
          }
          {/* <TrackingStatusTabs drawerClose={this.drawerClose} addNewTrackingTabValue={this.props.addNewTrackingTabValue} trackingStatusArrayList={this.props.trackingStatusListValue} setPageId={this.resetPageForFilters} /> */}
          <EnhancedTableToolbar
            reloadPageAfterAction={this.reloadPageAfterAction}
            setPageId={this.resetPageForFilters}
            classes={classes}
            pageId={this.handleChangePage}
          />
          <TableContainer className={classes.container}>
            <Table
              stickyHeader={true}
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
              // numSelected={selected.length}
              // order={order}
              // orderBy={orderBy}
              // onSelectAllClick={this.handleSelectAllClick}
              // onRequestSort={this.handleRequestSort}
              // rowCount={this.props.trackingList.length}
              />
              {
                this.props.automatioLoader === 1 && this.props.automationList && this.props.automationList.length > 0 ?
              
              <TableBody ref={node => (this.listEl = node)}>
                {this.props.automationList.map((automationData, index) => {
                  // const isItemSelected = this.isSelected(trackingList._id);
                  return (
                    <TableRow
                      className={classes.bodyTableRow}
                      classes={{
                        selected: classes.selectedTableRow,
                      }}
                      hover
                      tabIndex={-1}
                      key={automationData.id}
                    >
                      <TableCell className={classes.bodyTableCell} >
                        <Switch
                          size="medium"
                          className={automationData.active == 1 ? classes.digestSwitchOn : classes.digestSwitchOff}
                          checked={(automationData.active == 1) ? true : false}
                          onChange={(e) => this.toggleSwitch(automationData.id, automationData.active)}
                          name="checkedB"
                          color="primary"
                        />
                        {
                          automationData.active !== 1 ?
                            <IconButton
                              color="inherit"
                              onClick={() => this.openDeleteDialog(automationData.id)}
                              className={classes.deleteButon}
                              edge="start"
                            >
                              <img src={IconDelete} className={classes.removeIcon} />
                            </IconButton>
                            : null
                        }
                      </TableCell>
                      <TableCell onClick={() => { this.openDialog(automationData.id, automationData) }} className={classes.bodyTableCell} align="left">{automationData.name}</TableCell>
                      <TableCell onClick={() => { this.openDialog(automationData.id, automationData) }} className={classes.bodyTableCell} align="left">{automationData.event_type == 'CUSTOM' ? 'Custom Event' : 'Inbuilt Event'}</TableCell>
                      <TableCell onClick={() => { this.openDialog(automationData.id, automationData) }} className={classes.bodyTableCell} align="left">{automationData.event_type === 'CUSTOM' ? 'Custom event: ' + automationData.custom_event_name : getLabel(TRIGGER, automationData.module_name) + ' ' + getLabel(TRIGGER_CONDITION[automationData.module_name], automationData.event_type)}</TableCell>
                      <TableCell onClick={() => { this.openDialog(automationData.id, automationData) }} className={classes.bodyTableCell} align="left">
                        {automationData.action.map((actionValue, key) => {
                          let actionList = ACTION[automationData.module_name].filter(action => {
                            return action.trigger.includes(automationData.event_type)
                          })
                          if (key == automationData.action.length - 1)
                            return (getLabel(actionList, actionValue.type))
                          else
                            return (getLabel(actionList, actionValue.type) + ', ')
                        }
                        )}
                      </TableCell>
                      <TableCell onClick={() => { this.openDialog(automationData.id, automationData) }} className={classes.bodyTableCell} align="left">{moment(automationData.created).tz(localStorage.getItem('TIMEZONE')).format(this.dateFormat + " hh:mm:ss A")}</TableCell>
                      <TableCell onClick={() => { this.openDialog(automationData.id, automationData) }} className={classes.bodyTableCell} align="left">{moment(automationData.modified).tz(localStorage.getItem('TIMEZONE')).format(this.dateFormat + " hh:mm:ss A")}</TableCell>
                    </TableRow>
                  );
                })}

              </TableBody>
                :
                this.props.automatioLoader === 1 && !this.props.automationList.length > 0 ? 
                <div className="noAutomationDiv">
                <div className="noAutomationInnerDiv">
                   {/* <img src={NoAutomation} alt="No Tracking" /> */}
                   <div className="noTrackingMsgTitle">No Automation Found</div>
                </div>
                </div>
                :null
              }
            </Table>
          </TableContainer>
          {/* <TablePagination className={classes.pagination}
            rowsPerPageOptions={[20, 30, 40, 50]}
            component="div"
            count={this.props.noOfTotalTrackingRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          /> */}
          {(this.state.showMessage) ?
            <FlashMessage open={this.state.showMessage} messageDetails={this.state.flashMessage} toggleFlashMessage={this.toggleFlashMessage} />
            : null
          }
        </Paper>
        {this.state.openDialog ?
          <EditAutomation
            openDialog={this.state.openDialog}
            classes={this.props.classes}
            closeDialog={this.closeDialog}
          />
          :
          null}
        <Dialog
          open={this.state.openDeleteDialog}
          onClose={this.closeDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Automation Rule"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete this automation rule ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.dialogCancelBtn} onClick={this.closeDeleteDialog}>
              Cancel
    </Button>
            <Button className={classes.dialogActionBtn} onClick={this.handleDeleteAutomationRule} autoFocus>
              Yes,  Delete
    </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

// EnhancedTable.propTypes = {
//   classes: PropTypes.object.isRequired,
// };
const mapStateToProps = state => ({
  automationList: state.automations.list.automationList,
  automatioLoader: state.automations.list.automatioLoader
})
export default withStyles(styles)(
  (withRouter(connect(mapStateToProps, { updateAutomationRule, updateAutomationRuleListState, getAutomationRuleList, setAutomationDetail, setAutomationLoader, deleteAutomationRule })(EnhancedTable)))
)