import React from 'react'
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    card: {
        width: 450,
        height: 56,
        "&:hover": {
            background: "#e3eefc"
        },
        cursor: "pointer",
        margin: "32px 32px 0px 32px"
    },
    media: {
        height: 80,
        width: 90,
        margin: "16px auto",
        marginBottom: "24px"
    },
    integrationTitle: {
        textAlign: "center",
        fontWeight: "500"
    },
    integrationContent: {
        padding: "0px",
        marginBottom: "16px",
        "&:last-child": {
            paddingBottom: "0px"
        }
    },
    headerAvatar: {
        width: "24px",
        height: "24px",
    },
    sendEmailImage: {
        width: "24px",
        height: "24px",
        "& > img": {
            width: "20px",
            height: "16px",
        }
    },
    title: {
        fontWeight: "normal",
        color: "#666666",
        fontSize: "16px",
        lineHeight: "normal",
        letterSpacing: "0.16px",
    },
});

function actioListCard(props) {
    const { classes } = props

    return (
        <React.Fragment>
            {props.actionList.map((option) => {
                return (
                    <Card key={option.value} className={classes.card} variant="outlined" onClick={(event) => {props.handleChangeAction(option)}}>
                        <CardHeader
                            classes={{
                                title: classes.title,
                                avatar: classes.headerAvatar
                            }}
                            avatar={
                                <Avatar variant="square" aria-label="action" className={option.value === 'EMAIL' ? classes.sendEmailImage : classes.headerAvatar} src={option.icon} />
                            }
                            title={option.label}
                        />
                    </Card>
                )}
            )}
        </React.Fragment>
    )
}

export default withStyles(styles)(actioListCard)