import "regenerator-runtime/runtime";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import authenticate from "./Authentication/reducers/authenticate";
import tracking from "./TrackingList/reducer/tracking";
import settings from "./Setup/reducers/setup"
import automations from './Automation/reducer/automations'


const appReducer = combineReducers({
    authenticate,
    tracking,
    settings,
    automations,
});

const reducer = (state, action) => {
    return appReducer(state, action);
};

const isProduction = process.env.NODE_ENV === "production";
const hasReduxExtension =
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function";

const getMiddleware = () => {
    if (isProduction || (!isProduction && hasReduxExtension)) {
        return applyMiddleware(thunk);
    } else {
        // Enable additional logging in non-production environments.
        return applyMiddleware(thunk, createLogger());
    }
};

const composeEnhancers =
    !isProduction && hasReduxExtension
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;
const createStoreWithMiddleware = composeEnhancers(getMiddleware());

const store = createStoreWithMiddleware(createStore)(reducer, {});

export default store;
