import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import FlashMessage from '../../common/FlashMessage';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTrackingPageList, makeDefaultTemplate, removeTemplateAPI, setSettingsFlashMessage, toggleSettingsFlashMessage } from '../../actions/actionMethods'
import { defaultStar, activeStar, ApiCopyIcon, RemoveIcon, previewIcon } from '../../../common/imageList'
import {TEMPLATE_DELETED_SUCCESSFULLY_MESSAGE, DEFAULT_TEMPLATED_UPDATED_MESSAGE} from '../../common/constants'
class TrackingPageList extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess = true
        this.state = {
            trackingPageList: [],
            showDeletePopup: false,
            deleteTemplateId: '',
            loading: false
        }
    }
    UNSAFE_componentWillMount() {
        this.getTrackingPageListValues()
    }
    getTrackingPageListValues = () => {
        getTrackingPageList().then(res => {
            if (res && res.data && res.data.length > 0) {
                this.setState({
                    trackingPageList: res.data
                }, this.props.loader(false))
            }
        })
    }
    handleDelete = (trackingPageID) => {
        this.setState({
            deleteTemplateId: trackingPageID,
            showDeletePopup: true
        })
    }
    handleDefaultTemplate = (index, templateID) => {
        this.props.showFooterLoader(true)

        let eachTemplateData = [...this.state.trackingPageList]
        makeDefaultTemplate(templateID).then(response => {
            this.props.showFooterLoader(false)
            if (response && response.meta && response.meta.code === 200) {
                for (let i = 0; i < eachTemplateData.length; i++) {
                    if (index === i) {
                        eachTemplateData[i].is_default_template = 1
                    }
                    else {
                        eachTemplateData[i].is_default_template = 0
                    }
                }

                if(response.status === 400){
                    this.isSuccess = false
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(response.data.meta.message[0])
                    this.setState({
                        loading: false,
                        showDeletePopup: false,
                        deleteTemplateId: '',
                    })
                }
                else{
                    this.isSuccess = true
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(DEFAULT_TEMPLATED_UPDATED_MESSAGE)
                    this.setState({
                        loading: false,
                        showDeletePopup: false,
                        deleteTemplateId: '',
                    })
                }

                this.setState({
                    trackingPageList: eachTemplateData
                })
            }
        })
    }
    prepareTrackingPageListData = (trackingPageData) => {
        const {classes} = this.props
        if(trackingPageData !== undefined){
           return(
              <TableContainer className={classes.tableContainer}>
                <Table className={classes.table}  stickyHeader aria-label="sticky table" >
                   <TableHead>
                       <TableRow>
                          <TableCell align="left" className={`${classes.headCell} ${classes.firstHeadAndBodyCell}`}>Template Name</TableCell>
                          <TableCell align="left" className={classes.headCell}>Delete</TableCell>
                          <TableCell align="left" className={classes.headCell}>Preview</TableCell>
                          <TableCell align="left" className={`${classes.headCell} ${classes.lastHeadAndBodyCell}`}>Copy</TableCell>
                        </TableRow>
                   </TableHead>
                   <TableBody>
                      {
                        trackingPageData.map((template,index) => {
                         return(
                           <>
                             <TableRow key={index}>
                                <TableCell align="left" className={`${classes.bodyCell} ${classes.firstHeadAndBodyCell}`}>
                                   <div style={{display: 'inline-flex', cursor: 'pointer'}} className="starDiv" onClick={template.is_default_template ? null : (i) => this.handleDefaultTemplate(index, template._id)}>
                                        <img alt="star_IC" width="35" height="35"  src={template.is_default_template ? activeStar : defaultStar} />
                                  </div>
                                  <div style={{color: "#2f80ed", fontSize: "14px", textDecoration:'none', cursor: 'pointer', display: 'inline-flex', verticalAlign: 'top', lineHeight: '37px', paddingLeft: "5px"}}   
                                       onClick={() => this.props.handleEdit(template)}>
                                       {template.template_name}
                                  </div>
                                </TableCell>
                                <TableCell align="left" className={classes.bodyCell} style={{paddingLeft:'10px'}}>
                                   <div style={{cursor: 'pointer' }} onClick={() => this.handleDelete(template._id)} >
                                        { !template.is_default_template ? <RemoveIcon/> : ""}
                                    </div>
                                </TableCell>
                                <TableCell align="left" className={classes.bodyCell} style={{paddingLeft:'10px'}}>
                                   <div style={{cursor: 'pointer' }} onClick={() => this.handleTemplatePreview(template)} >
                                        <img src={previewIcon} alt="preview_IC"/>
                                    </div>
                                </TableCell>
                                <TableCell className={`${classes.bodyCell} ${classes.lastHeadAndBodyCell}`} style={{paddingLeft:'8px'}}>
                                   <div style={{cursor: 'pointer' }} onClick={() => this.props.handleCopy(template)} >
                                        <ApiCopyIcon/>
                                    </div>
                                </TableCell>
                             </TableRow>
                           </>
                          )
                          })
                      }
                   </TableBody>
                </Table>
              </TableContainer>
           )
        }   
    }

    cancelDeletePopUP = () => {
        this.setState({
            showDeletePopup: false,
            deleteTemplateId: '',
        })
    }
    toggleFlashMessage = (data) => {
        this.props.toggleSettingsFlashMessage(data)
        this.props.setSettingsFlashMessage('')
    }
    handleTemplatePreview = (eachTemplateData) => {
        //  var win = window.open(`/custom_tracking_page/preview/${eachTemplateData._id}`, '_blank');
        var win = window.open(`/custom_tracking_page/preview`, '_blank');
        setTimeout(() => {
            // win.postMessage(eachTemplateData.body, `https://testing.trackinghive.com/custom_tracking_page/preview`)
            win.postMessage(eachTemplateData.body, "*")
        }, 1200);
    }

    removeTemplate = () => {
        this.setState({
            loading: true
        })
        removeTemplateAPI(this.state.deleteTemplateId).then(res => {

            if (res.status === 400) {
                this.isSuccess = false
                this.props.toggleSettingsFlashMessage(1)
                this.props.setSettingsFlashMessage(res.data.meta.message[0])
                this.setState({
                    loading: false,
                    showDeletePopup: false,
                    deleteTemplateId: '',
                })
            } else {
                this.isSuccess = true
                this.props.toggleSettingsFlashMessage(1)
                this.props.setSettingsFlashMessage(TEMPLATE_DELETED_SUCCESSFULLY_MESSAGE)
                this.setState({
                    loading: false,
                    showDeletePopup: false,
                    deleteTemplateId: '',
                })
            }
            this.getTrackingPageListValues()
        })
    }

    render() {
        const {classes} = this.props
        let showTrackingPageListData = []
        if (this.state.trackingPageList && this.state.trackingPageList.length > 0) {
            showTrackingPageListData = this.prepareTrackingPageListData(this.state.trackingPageList)
            return (
                <React.Fragment>
                 <CssBaseline/>
                    <div className="trackingPageListMainDiv">
                        {showTrackingPageListData}
                    </div>
                    <Dialog
                        open={this.state.showDeletePopup}
                        onClose={this.cancelDeletePopUP}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">{"Delete Tracking Page"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure that you want to delete this tracking page?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.dialogCancelBtn}  onClick={this.cancelDeletePopUP}>
                            Cancel
                        </Button>
                        <Button  className={classes.dialogActionBtn}  onClick={this.removeTemplate} autoFocus>
                            Yes, Delete
                        </Button>
                        {(this.state.loading) && <CircularProgress size={22} className={classes.deleteDialogProgress} />}
                    </DialogActions>
                   </Dialog>
                   {this.props.showSettingFlashMessageFlag === 1 ?
                                 <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                          : ''}
                </React.Fragment>
            )
        }else{
            return(
                <div>
                
                </div>
            )
        }
        
    }
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, { toggleSettingsFlashMessage, setSettingsFlashMessage })(TrackingPageList));