import React from 'react'
import {Link} from 'react-router-dom'
import Styles from '../assets/css/Styles'
import { withStyles, Card, CardActions, CardHeader, Avatar} from '@material-ui/core';

function settingCard(props){
const { classes } = props
const title = <div className="cardTitle">{props.title}</div>
const subTitle = <div className="cardSubTitle">{props.subTitle}</div>
const image = <Avatar aria-label="recipe"  src={props.icon} ></Avatar>
return(
  <React.Fragment>
  { 
    (props.settingFlag)
    ?
    <Card className={classes.settingCard}>
    <CardHeader className={classes.settingCardheader}
       classes={{subheader: classes.settingCardSubTitle}}
       title={title}
       subheader={subTitle}
       avatar={image}
    >
   </CardHeader>
   <CardActions className={classes.settingCardActions}>
      <Link className="cardAction" to = {props.path}>EDIT</Link>
   </CardActions>
</Card>        
    : (props.title === "Profile") ?
    <Card className={classes.card}>
    <CardHeader className={classes.header}
       classes={{subheader: classes.subTitle}}
       title={title}
       subheader={subTitle}
       avatar={image}
    >
   </CardHeader>
   <CardActions className={classes.settingCardActions}>
      <Link className="cardAction" to = {props.path}>EDIT</Link>
   </CardActions>
</Card>  : ""
   }
  </React.Fragment>
)
}

export default withStyles(Styles)(settingCard)