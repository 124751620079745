import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { scrollbar } from '../../../static/constants'
import '../../assets/css/settings.scss'
import '../../assets/css/profileStyle.scss'
import {IFRAME_CODE, CODE_COPIED_SUCCESS_MESSAGE} from '../../common/constants'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import FlashMessage from '../../common/FlashMessage'
import { setSettingsFlashMessage,toggleSettingsFlashMessage } from '../../actions/actionMethods'
import {addNewPageToShopify,shopifyContentButton,shopifyPreviewTrackPage,ExpandIcon,verifyIcon,SHOPIFY} from '../../../common/imageList'
import { Avatar } from '@material-ui/core';
import Styles from '../../assets/css/Styles'
const prefix = process.env.REACT_APP_API_PREFIX

class TrackButtonCode extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess=true
        this.state = {
            domainName : localStorage.getItem('custom_domain_url') === ''  ? localStorage.getItem('sub_domain') + '.trackhive.co' : localStorage.getItem('custom_domain_url'),
            redirect_url : '',
            expanded: "panel1",
            panelStatus1: false,
            panelStatus2: false,
            panelStatus3: false,
            panelStatus4: false,
            panelStatus5: false
        }
               
    }
    componentDidMount(){
        let trackhive_url = ''
        if(prefix === '/development'){
            trackhive_url = 'https://testing.trackinghive.com'
        }
        else{
            trackhive_url = 'https://my.trackinghive.com'
        }
        let redirect_url = IFRAME_CODE.replace('domainName',this.state.domainName)
        redirect_url = redirect_url.replace('trackhiveUrl',trackhive_url)
        this.setState({
            redirect_url : redirect_url
        })        
    }
    
    toggleFlashMessage = (data) => {
        this.props.toggleSettingsFlashMessage(data)
        this.props.setSettingsFlashMessage('')
    }
    selectCode = (e) => {
        this.textArea.select();        
        document.execCommand('copy');
        this.props.toggleSettingsFlashMessage(1)
        this.props.setSettingsFlashMessage(CODE_COPIED_SUCCESS_MESSAGE)   
    }  

    handleChange = panel => (event, isExpanded) => {
       this.setState({expanded: isExpanded ? panel :false})        
    }

    handleDone = panel => (event, isExpanded) => {
        if(this.state.expanded === "panel1"){
            this.setState({panelStatus1: true})
        }
        if(this.state.expanded === "panel2"){
            this.setState({panelStatus2: true})
        }
        if(this.state.expanded === "panel3"){
            this.setState({panelStatus3: true})
        }
        if(this.state.expanded === "panel4"){
            this.setState({panelStatus4: true})
        }
        if(this.state.expanded === "panel5"){
            this.setState({panelStatus5: true})
        }
        this.setState({expanded: isExpanded ? panel :false})        
    }
       
    render() {
        const {classes} = this.props
        return (
            <div className="settingsModule">
                    <Card className={classes.root}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link className={classes.link} to="/track_button">
                                Button
                            </Link>
                            <Typography className={classes.text}>Shopify</Typography>     
                        </Breadcrumbs>
                    </Card>
                   <div className="dataContainer" style={{background: "#f2f4f5"}}>
                             <div style={{display: "inline-flex",padding: "2% 0% 0% 2%"}}>
                                  <Avatar src={SHOPIFY} alt="shopify_IC" className={classes.trackingButtonPageIcon} ></Avatar>
                                  <Typography className={classes.trackingButtonPageTitle}>Shopify</Typography>
                             </div>
                    <div id="settingDataScrollerWrapper">
                        <div className="settingsContainer">
                            <div className="settingsRightSidePanel">
                                <div className="commonSettingsBox integrationStyleMainDiv" style={{background: "#f2f4f5"}}>
                                    <Scrollbars autoHide style={scrollbar}> 
                                    <div className="wooCommerceIntegrationDiv" style={{padding: "2% 8% 8% 14%"}}>
                                    <div className={classes.trackButtonexpansionPanelRoot}>
                                    <ExpansionPanel className={classes.trackingButtonExpansionPanel} expanded={this.state.expanded === "panel1"}   onChange={this.handleChange("panel1")}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className={classes.trackingButtonExpansionSummary}
                                    >
                                        {
                                            this.state.panelStatus1 ? <img src={verifyIcon} alt="done_IC"/> 
                                            : <Typography className={classes.trackingButtonExpansionSequence}>1</Typography>
                                        }
                                      <Typography className={classes.trackingButtonexpansionHeading}>Add a new page</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <Grid>
                                         <img alt="" src={addNewPageToShopify} className="image"/>
                                      </Grid>
                                    </ExpansionPanelDetails>
                                    <Divider />
                                    <ExpansionPanelActions>
                                        <Button size="small" className={classes.trackingButtonExpansionBtn} onClick={this.handleDone(false)}>
                                            Done
                                        </Button>
                                    </ExpansionPanelActions>
                                  </ExpansionPanel>

                                  <ExpansionPanel className={classes.expansionPanel} expanded={this.state.expanded === "panel2"}   onChange={this.handleChange("panel2")}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className={classes.trackingButtonExpansionSummary}
                                    >
                                       {
                                            this.state.panelStatus2 ? <img src={verifyIcon} alt="done_IC"/> 
                                            : <Typography className={classes.trackingButtonExpansionSequence}>2</Typography>
                                        }
                                      <Typography className={classes.trackingButtonexpansionHeading}>Change to HTML editor by clicking {'< >'} button at content</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <Grid>
                                         <img alt="" src={shopifyContentButton} className="image"/>
                                      </Grid>
                                    </ExpansionPanelDetails>
                                    <Divider />
                                    <ExpansionPanelActions>
                                        <Button size="small" className={classes.trackingButtonExpansionBtn} onClick={this.handleDone(false)}>
                                            Done
                                        </Button>
                                    </ExpansionPanelActions>
                                  </ExpansionPanel>

                                  <ExpansionPanel className={classes.expansionPanel} expanded={this.state.expanded === "panel3"}   onChange={this.handleChange("panel3")}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className={classes.trackingButtonExpansionSummary}
                                    >
                                       {
                                            this.state.panelStatus3 ? <img src={verifyIcon} alt="done_IC"/> 
                                            : <Typography className={classes.trackingButtonExpansionSequence}>3</Typography>
                                       }
                                      <Typography className={classes.trackingButtonexpansionHeading}>Copy and paste the code below into your page</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                            <textarea rows="8" cols="140" style={{width:'100%'}} ref={(textarea) => this.textArea = textarea} type="text"  value={this.state.redirect_url} onFocus={e => this.selectCode(e)} readOnly={true} />
                                    </ExpansionPanelDetails>
                                    <Divider />
                                    <ExpansionPanelActions>
                                        <Button size="small" className={classes.trackingButtonExpansionBtn} onClick={this.handleDone(false)}>
                                            Done
                                        </Button>
                                    </ExpansionPanelActions>
                                  </ExpansionPanel>

                                  <ExpansionPanel className={classes.expansionPanel} expanded={this.state.expanded === "panel4"}   onChange={this.handleChange("panel4")}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className={classes.trackingButtonExpansionSummary}
                                    >
                                       {
                                            this.state.panelStatus4 ? <img src={verifyIcon} alt="done_IC"/> 
                                            : <Typography className={classes.trackingButtonExpansionSequence}>4</Typography>
                                        }
                                      <Typography className={classes.trackingButtonexpansionHeading}>Name your URL accordingly, e.g.  .../track-your-order</Typography>
                                    </ExpansionPanelSummary>
                                    <Divider />
                                    <ExpansionPanelActions>
                                        <Button size="small" className={classes.trackingButtonExpansionBtn} onClick={this.handleDone(false)}>
                                            Done
                                        </Button>
                                    </ExpansionPanelActions>
                                  </ExpansionPanel>
                                   
                                  <ExpansionPanel className={classes.expansionPanel} expanded={this.state.expanded === "panel5"}   onChange={this.handleChange("panel5")}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className={classes.trackingButtonExpansionSummary}
                                    >
                                       {
                                            this.state.panelStatus5 ? <img src={verifyIcon} alt="done_IC"/> 
                                            : <Typography className={classes.trackingButtonExpansionSequence}>5</Typography>
                                        }
                                      <Typography className={classes.trackingButtonexpansionHeading}>Preview your track order page</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <Grid>
                                         <img alt="" src={shopifyPreviewTrackPage} className="image"/>
                                      </Grid>
                                    </ExpansionPanelDetails>
                                    <Divider />
                                    <ExpansionPanelActions>
                                        <Button size="small" className={classes.trackingButtonExpansionBtn} onClick={this.handleDone(false)}>
                                            Done
                                        </Button>
                                    </ExpansionPanelActions>
                                  </ExpansionPanel>
                                </div>
                                </div>
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                    </div>  
                    {this.props.showSettingFlashMessageFlag === 1 ?
                        <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                        : ''}                                   
                </div>
                {/* dataContainer close */}
            </div>
            // Settings Module Close
        )
    }
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, {setSettingsFlashMessage,toggleSettingsFlashMessage})(withStyles(Styles)(TrackButtonCode)));