import React from 'react'
import Tag from './tag'
import Tags from './Tags'

class AddTags extends React.Component{
    
  constructor(props) {
    super(props);
    this.state = {
      showTags: 1
    }
  }

  handleShowTags = () => {
    this.setState({showTags: 1})
  }

  handleLinkTags = (data) => {
    if(data._id) {
      this.props.linkTagSingle(data)
    }
  }

  handleAddTag = (tag, tagColor) => {
    if(tagColor === '' || tagColor === "#FFFFFF")
      this.props.addTag({tag_name: tag})
    else
      this.props.addTag({tag_name: tag, tag_color: tagColor})
  }

  tagScroll = (event) => {
    event.preventDefault()
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    var left, cntWd, sldWd, tb, element;
    cntWd = document.getElementById('tagScroll').clientWidth;
    tb = document.getElementById('scroll');
    element = document.getElementById('tagScroll');
    var rect = element.getBoundingClientRect();
    sldWd = tb.offsetWidth;
    left = rect.left
    if (sldWd > cntWd) {
      tb.style.left = (((cntWd - sldWd)*((event.pageX - left - 2) / cntWd).toFixed(3)).toFixed(1) / 16) +"rem";
    } else {
      tb.style.left = "0rem";
    }
  }

  tagTouchScroll = (event) => {
    event.preventDefault()
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    var left, cntWd, sldWd, tb, element;
		cntWd = document.getElementById('tagScroll').clientWidth;
    tb = document.getElementById('scroll');
    element = document.getElementById('tagScroll');
    var rect = element.getBoundingClientRect();
    sldWd = tb.offsetWidth;
    left = rect.left
    if (sldWd > cntWd) {
      tb.style.left = (((cntWd - sldWd)*((event.touches[0].pageX - left - 4) / cntWd).toFixed(3)).toFixed(1) / 16) +"rem";
    } else {
      tb.style.left = "0rem";
    }
  }

  render(){
    return(
      <div className="form-input" style={{width: "100%"}}>
        {this.state.showTags === 1 ?
          <div className="floatLeft">
            <Tags
              tags={this.props.tags}
              name={this.props.name}
              linkTag={(data) => this.handleLinkTags(data) }
              addTag={(tag, tagColor) => this.handleAddTag(tag, tagColor)}
            />
          </div> : ''
        }
        {(this.props.tags.length > 0) ?
          <div id='tagScroll' className="tagDiv"  onMouseMove={(e) => this.tagScroll(e) } onTouchMove={(e) => this.tagTouchScroll(e)}>
            <Tag
              tags={this.props.tags}
              deleteTag={(id) => this.props.deleteTag(id)}
            />
          </div> : ''}
          {this.state.showTags === 0 ?
            <a href onClick={() => this.handleShowTags()} className="CreateTagIc">&nbsp;</a>
          :  <span className="hideAddTag" onClick={() => this.setState({'showTags':0})}></span> }
        </div>
    );
  }
}

export default AddTags