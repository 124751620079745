import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { startPaginationLoader, clearTrackingList, setTrackingFilters, getCarrierList, setStatusArrayValueList, setCourierArrayValueList, setOriginCountryList, setDestinationCountryList, setTransitionDays,  getFiltersList, getTrackingList, setCourierList, clearShipmentStatusFilters } from './actions/actionMethods'
import { getCourierLabel } from '../common/commonFunction'
import { changeDateFunction } from '../Dashboard/common/Functions'
import { getTrackingStatusName } from '../common/commonFunction'
import moment from 'moment'
import { reactLocalStorage } from 'reactjs-localstorage'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import styles from './static/css/tracking'
import TextField from '@material-ui/core/Input';
import Radio from '@material-ui/core/Radio';
import Drawer from './trackingDrawer'
import * as constant from './common/constants'
import ImportDrawer from './importTracking'
import ExportDrawer from './export'
import { expandIcon, columnIcon, importTrackingIcon, exportTrackingIcon, addTrackingIcon, filterIcon, collapseIcon } from '../common/imageList'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateUtils  from "@date-io/moment"
import DateRangePicker from "../common/DateRangePicker";
import {searchRegex} from '../static/constants'
import {DEFAULT_TRACKING_LIMIT} from './common/constants'
class Filters extends React.Component {
    displayStatusText = 'displayStatus'
    displayCourierText = 'displayCourier'
    displayOriginText = 'displayOrigin'
    displayDestinationText = 'displayDestination'
    displayTransitDaysText = 'displayTransitDays'
    displayColumnText = 'displayColumn'
    displayExpectedDateText = 'displayExpectedDate'
    displayDateText = 'displayDate'
    displaySubUserText = 'displaySubUser'
    displayTagsText = 'displayTags'
    displayTrackingDrawerText = 'addTrackinngDrawer'
    displayImportText = 'importDrawer'
    displayExportText = 'exportDrawer'
    dateArray = [
        { "label": "Today", "key": "Today", 'alias': "Today" },
        { "label": "Yesterday", "key": "Yesterday", 'alias': "Yesterday" },
        { "label": "Last 7 Days", "key": "7Days", 'alias': "7 Days" },
        { "label": "Last 14 Days", "key": "14Days", 'alias': "15 Days" },
        { "label": "Last 30 Days", "key": "30Days", 'alias': "30 Days" },
        { "label": "Custom", "key": "Custom", 'alias': "Custom" }
    ]
    constructor(props) {
        super(props)
        if (props.trackingGlobalFilters) {
            let params = {}
            let storeObj = props.trackingGlobalFilters
            if (storeObj.trackingStatusArr) {
                let trackingStatus = storeObj.trackingStatusArr.map((element, index) => {
                    return (element.value)
                })
                params['trackingStatusArr'] = trackingStatus.join()
            }
            if (storeObj.allTrackingStatusArr) {
                let trackingStatus = storeObj.allTrackingStatusArr.map((element, index) => {
                    return (element.label)
                })
                params['allTrackingStatusArr'] = trackingStatus.join()
            }
            if (storeObj.courierListArr) {
                let couriersList = storeObj.courierListArr.map((element, index) => {
                    return (element.label)
                })
                params['courierListArr'] = couriersList.join()
            }
            if (storeObj.originListArr) {
                let originList = storeObj.originListArr.map((element, index) => {
                    return (element.label)
                })
                params['originListArr'] = originList.join()
            }
            if (storeObj.destinationListArr) {
                let destinationList = storeObj.destinationListArr.map((element, index) => {
                    return (element.label)
                })
                params['destinationListArr'] = destinationList.join()
            }
            if (this.props.trackingGlobalFilters.transitDays !== '') {
                params['transitDays'] = this.props.trackingGlobalFilters.transitDays
            }
            if (this.props.trackingGlobalFilters.date !== '') {
                params['date'] = this.props.trackingGlobalFilters.date
            }
            if (this.props.trackingGlobalFilters.fromDate !== '') {
                params['fromDate'] = this.props.trackingGlobalFilters.fromDate
            }

            if (this.props.trackingGlobalFilters.toDate !== '') {
                params['toDate'] = this.props.trackingGlobalFilters.toDate
            }

            if (storeObj.subUserListArr) {
                let subUserList = storeObj.subUserListArr.map((element, index) => {
                    return (element.label)
                })
                let subUserListId = storeObj.subUserListArr.map((element, index) => {
                    return (element.value)
                })
                params['subUserListArr'] = subUserList.join()
                params['subUserListId'] = subUserListId.join()
            }
            if (storeObj.tagListArr) {
                let tagList = storeObj.tagListArr.map((element, index) => {
                    return (element.label)
                })
                let tagsId = storeObj.tagListArr.map((element, index) => {
                    return (element.value)
                })
                params['tagListArr'] = tagList.join()
                params['tagListId'] = tagsId.join()
            }
            // props.history.replace(`?${queryString.stringify(params)}`)
        }
        this.state = {
            displayStatus: 0,
            displayCourier: 0,
            displayOrigin: 0,
            displayDestination: 0,
            displayColumn : 0,
            displayTransitDays: 0,
            displayDate: 0,
            displayExpectedDate: 0,
            displaySubUser: 0,
            displayTags: 0,
            statusList: [],
            couriersList: [],
            subUserList: [],
            tagList: [],
            originCoutryList: [],
            desinationCountryList: [],
            selectedStatusArr: this.props.trackingGlobalFilters.trackingStatusArr || this.props.trackingGlobalFilters.allTrackingStatusArr || [],
            selectedCourierArr: this.props.trackingGlobalFilters.courierListArr || [],
            selectedOriginArr: this.props.trackingGlobalFilters.originListArr || [],
            selectedDestinationArr: this.props.trackingGlobalFilters.destinationListArr || [],
            selectedSubUserArr: this.props.trackingGlobalFilters.subUserListArr || [],
            selectedTagsArr: this.props.trackingGlobalFilters.tagListArr || [],
            selectedColumnArr : [],
            selectDate: this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.date ? this.getDate() : "",
            showStatus: false,
            showCourier: false,
            showOrigin: false,
            showDestination: false,
            showTransitDays: false,
            showDate: false,
            showSubUsers: false,
            showColumn : false,
            showTags: false,
            showExpectedDate: false,
            isUnassignChecked: false,
            unassignedChecked: [],
            transitDaysArray: [
                { "label": "1 Day", "key": 1 },
                { "label": "2 Days", "key": 2 },
                { "label": "3 Days", "key": 3 },
                { "label": "4 Days", "key": 4 },
                { "label": "5 Days", "key": 5 },
                { "label": "More than 5 Days", "key": 6 }
            ],
            transitDays: this.props.trackingGlobalFilters.transitDays || '',
            filterFlag: true,
            fromDate: this.props.trackingGlobalFilters.fromDate || '',
            toDate: this.props.trackingGlobalFilters.toDate || '',
            dateType: this.props.trackingGlobalFilters.dateType || '',
            showCustom: false,
            checked: [0],
            addTrackinngDrawer: false,
            importDrawer : false,
            exportDrawer : false,
            exportFilter : {},
            isNotSubUser: localStorage.getItem('user_type') !== 'subuser' ? true : false,
            isWindowExpanded: false,
            isSubUser:localStorage.getItem('user_type') === "subuser" ? true : false
        }
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }
    getDate = () => {
        let res = []
        let date = this.dateArray.filter(x => x.label === this.props.trackingGlobalFilters.date)
        if(date && date.length && date[0] && date[0].key){
            res = date[0].key
        }
        return res
    }
    componentDidMount() {
        document.addEventListener("fullscreenchange", this.handleKeyDown, false);
        document.addEventListener("mozfullscreenchange", this.handleKeyDown, false);
        document.addEventListener("webkitfullscreenchange", this.handleKeyDown, false);
        document.addEventListener("msfullscreenchange", this.handleKeyDown, false);
    }
    async componentWillMount() {
        document.removeEventListener("fullscreenchange", this.handleKeyDown);
        document.removeEventListener("mozfullscreenchange", this.handleKeyDown);
        document.removeEventListener("webkitfullscreenchange", this.handleKeyDown);
        document.removeEventListener("msfullscreenchange", this.handleKeyDown);
        await getCarrierList().then(response => {
            if (response && response.data) {
                this.props.setCourierList(response.data)
            }
        })
        if(this.state.isNotSubUser){
            this.setState({
                subUserList: this.props.subUserList,
                tagList: this.props.tagList
            })
        }else{
            this.setState({
                tagList: this.props.tagList
            })
        }
        if(!localStorage.getItem('trackingColumn') || localStorage.getItem('trackingColumn') == undefined || localStorage.getItem('trackingColumn') == null || localStorage.getItem('trackingColumn') == ''){
            localStorage.setItem('trackingColumn', JSON.stringify(constant.COLUMN_ARRAY))
            this.setState({
                selectedColumnArr : JSON.parse(localStorage.getItem('trackingColumn'))
            })
        } else {
            this.setState({
                selectedColumnArr : JSON.parse(localStorage.getItem('trackingColumn'))
            })
        }
        getFiltersList().then(response => {
            if (response && response.data) {
                let statusListArr = []
                let courierListArr = []
                let originListArr = []
                let destinationListArr = []
                if (response.data.status && response.data.status.length > 0) {
                    for (let index = 0; index < response.data.status.length; index++) {
                        const element = response.data.status[index];
                        statusListArr.push({
                            label: element,
                            value: element
                        })
                    }
                    this.setState({
                        statusList: statusListArr
                    })
                }
                if (response.data.courier && response.data.courier.length > 0) {
                    for (let index = 0; index < response.data.courier.length; index++) {
                        const element = response.data.courier[index];
                        courierListArr.push({
                            label: getCourierLabel(element, this.props.addedCourierList),
                            value: element
                        })
                    }
                    this.setState({
                        couriersList: courierListArr
                    })
                }
                if (response.data.origin && response.data.origin.length > 0) {
                    for (let index = 0; index < response.data.origin.length; index++) {
                        const element = response.data.origin[index];
                        if (element && Object.keys(element).length > 0) {
                            if (element.label !== null && element.value !== null) {
                                originListArr.push({
                                    label: element.label,
                                    value: element.value
                                })
                            }
                        }
                    }
                    this.setState({
                        originCoutryList: originListArr
                    })
                }
                if (response.data.destination && response.data.destination.length > 0) {
                    for (let index = 0; index < response.data.destination.length; index++) {
                        const element = response.data.destination[index];
                        if (element && Object.keys(element).length > 0) {
                            if (element.label !== null && element.value !== null) {
                                destinationListArr.push({
                                    label: element.label,
                                    value: element.value
                                })
                            }
                        }
                    }
                    this.setState({
                        desinationCountryList: destinationListArr,
                    })
                }

                this.props.sendStatusListValue(this.state.statusList)
                this.props.setStatusArrayValueList(this.state.statusList)
                this.props.setCourierArrayValueList(this.state.couriersList)
                this.props.setOriginCountryList(this.state.originCoutryList)
                this.props.setDestinationCountryList(this.state.desinationCountryList)
            }
        })
        if(this.props.location.pathname === '/tracking/add'){
            this.setState({addTrackinngDrawer : true})
        }
    }
    componentWillReceiveProps(props) {
        if (props.trackingStatusArrayList && props.trackingStatusArrayList.length > 0) {
            this.setState({
                statusList: props.trackingStatusArrayList
            })
        }
        if (this.props.isShipmentAdded) {
            this.setState({
                selectedCourierArr: [],
                selectedOriginArr: [],
                selectedDestinationArr: [],
                transitDays: '',
                dateType: ''
            })
        }

        if (props && props.selectedShipmentTab !== 'All') {
            this.setState({
                selectedStatusArr: []
            })
        }

        if(props.subUserList){
            this.setState({subUserList: props.subUserList})
        }

        if (props.filterTagsList && props.filterTagsList.length > 0) {
            this.setState({ tagList: props.filterTagsList })
        }

    }
    handleOnClick = (key, flag) => {
        this.props.drawerClose()
        let obj = {
            [key]: 1, [flag]: false,
            filterFlag: false
        }
        this.setState(obj)
        if (key === this.displayStatusText) {
            this.setState({
                displayCourier: 0,
                displayDestination: 0,
                displayOrigin: 0,
                displayTransitDays: 0,
                displayDate: 0,
                displaySubUser: 0,
                displayExpectedDate: 0,
                displayTags: 0,
                displayColumn : 0
            })
        }
        else if (key === this.displayCourierText) {
            this.setState({
                displayStatus: 0,
                displayDestination: 0,
                displayOrigin: 0,
                displayTransitDays: 0,
                displayDate: 0,
                displaySubUser: 0,
                displayExpectedDate: 0,
                displayTags: 0,
                displayColumn : 0
            })
        }
        else if (key === this.displayOriginText) {
            this.setState({
                displayStatus: 0,
                displayCourier: 0,
                displayDestination: 0,
                displayTransitDays: 0,
                displayDate: 0,
                displaySubUser: 0,
                displayExpectedDate: 0,
                displayTags: 0,
                displayColumn : 0
            })
        }
        else if (key === this.displayDestinationText) {
            this.setState({
                displayStatus: 0,
                displayCourier: 0,
                displayOrigin: 0,
                displayTransitDays: 0,
                displayDate: 0,
                displaySubUser: 0,
                displayExpectedDate: 0,
                displayTags: 0,
                displayColumn : 0
            })
        }
        else if (key === this.displayTransitDaysText) {
            this.setState({
                displayStatus: 0,
                displayCourier: 0,
                displayDestination: 0,
                displayOrigin: 0,
                displayDate: 0,
                displaySubUser: 0,
                displayExpectedDate: 0,
                displayTags: 0,
                displayColumn : 0
            })
        }
        else if(key === this.displayColumnText) {
            this.setState({
                displayStatus: 0,
                displayCourier: 0,
                displayDestination: 0,
                displayOrigin: 0,
                displayDate: 0,
                displaySubUser: 0,
                displayExpectedDate: 0,
                displayTags: 0,
                displayTransitDays : 0
            })
        }
        else if (key === this.displayDateText) {
            this.setState({
                displayStatus: 0,
                displayCourier: 0,
                displayDestination: 0,
                displayOrigin: 0,
                displayTransitDays: 0,
                displaySubUser: 0,
                displayExpectedDate: 0,
                displayTags: 0,
                displayColumn : 0
            })
        }
        else if (key === this.displayExpectedDateText) {
            this.setState({
                displayStatus: 0,
                displayCourier: 0,
                displayDestination: 0,
                displayOrigin: 0,
                displayTransitDays: 0,
                displaySubUser: 0,
                displayDate: 0,
                displayTags: 0,
                displayColumn : 0
            })
        } else if (key === this.displaySubUserText) {
            this.setState({
                displayStatus: 0,
                displayCourier: 0,
                displayDestination: 0,
                displayOrigin: 0,
                displayTransitDays: 0,
                displayExpectedDate: 0,
                displayDate: 0,
                displayTags: 0,
                displayColumn : 0
            })
        } else if (key === this.displayTagsText) {
            this.setState({
                displayStatus: 0,
                displayCourier: 0,
                displayDestination: 0,
                displayOrigin: 0,
                displayTransitDays: 0,
                displayExpectedDate: 0,
                displayDate: 0,
                displaySubUser: 0,
                displayColumn : 0
            })
        }
    }
    handleCloseClick = (key) => {
        if (key) {
            let obj = { [key]: 0 }
            this.setState(obj);
        }
        if (key == this.displayCourierText) {
            let tempArray = this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.courierListArr && this.props.trackingGlobalFilters.courierListArr.length ? this.props.trackingGlobalFilters.courierListArr : []
            this.setState({ selectedCourierArr: tempArray })
        }
        if (key == this.displayOriginText) {
            let tempArray = this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.originListArr && this.props.trackingGlobalFilters.originListArr.length ? this.props.trackingGlobalFilters.originListArr : []
            this.setState({ selectedOriginArr: tempArray })
        }
        if (key == this.displayDestinationText) {
            let tempArray = this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.destinationListArr && this.props.trackingGlobalFilters.destinationListArr.length ? this.props.trackingGlobalFilters.destinationListArr : []
            this.setState({ selectedDestinationArr: tempArray })
        }
        if (key == this.displayTransitDaysText) {
            let tempArray = this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.transitDays && this.props.trackingGlobalFilters.transitDays.length ? this.props.trackingGlobalFilters.transitDays : ''
            this.setState({ transitDays: tempArray })
        }
        if (key == this.displayDateText) {
            let tempArray = this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.date && this.props.trackingGlobalFilters.date.length ? this.props.trackingGlobalFilters.date : ''
            this.setState({ selectDate: tempArray, dateType: tempArray })
        }
        if (key == this.displaySubUserText) {
            let tempArray = this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.subUserListArr && this.props.trackingGlobalFilters.subUserListArr.length ? this.props.trackingGlobalFilters.subUserListArr : []
            this.setState({ selectedSubUserArr: tempArray })
        }
        if (key == this.displayTagsText) {
            let tempArray = this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.tagListArr && this.props.trackingGlobalFilters.tagListArr.length ? this.props.trackingGlobalFilters.tagListArr : []
            this.setState({ selectedTagsArr: tempArray })
        }
        this.setState({
            couriersList: this.props.trackingCourierArrayList,
            originCoutryList: this.props.originCountryArrayList,
            desinationCountryList: this.props.destinationCountryArrayList,
            subUserList: this.props.subUserList,
            tagList: this.props.filterTagsList
        })
    }
    handleChangeStatus = (event, element) => {
        let selectedStatusArrVal = [...this.state.selectedStatusArr]
        let statusIndex = selectedStatusArrVal.findIndex(x => x.value == element.value)
        if (event.target.checked === true) {
            selectedStatusArrVal.push({
                label: element.label,
                value: element.value
            })
        }
        else {
            selectedStatusArrVal.splice(statusIndex, 1)
        }
        this.setState({
            selectedStatusArr: selectedStatusArrVal
        })
        if (selectedStatusArrVal.length > 0) {
            this.setState({
                showStatus: true
            })
        }
        else {
            if (this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.allTrackingStatusArr && this.props.trackingGlobalFilters.allTrackingStatusArr.length > 0) {
                this.setState({
                    showStatus: true
                })
            }
            else {
                this.setState({
                    showStatus: false
                })
            }
        }
    }
    handleChangeCourier = (event, element) => {
        let selectedCourierValue = [...this.state.selectedCourierArr]
        let statusIndex = selectedCourierValue.findIndex(x => x.value == element.value)
        if (event.target.checked === true) {
            selectedCourierValue.push({
                label: element.label,
                value: element.value
            })
        }
        else {
            selectedCourierValue.splice(statusIndex, 1)
        }
        this.setState({
            selectedCourierArr: selectedCourierValue
        })
        if (selectedCourierValue.length > 0) {
            this.setState({
                showCourier: true
            })
        }
        else {
            if (this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.courierListArr && this.props.trackingGlobalFilters.courierListArr.length > 0) {
                this.setState({
                    showCourier: true
                })
            }
            else {
                this.setState({
                    showCourier: false
                })
            }
        }
    }
    handleChangeOrigin = (event, element) => {
        let selectedOriginVal = [...this.state.selectedOriginArr]
        let statusIndex = selectedOriginVal.findIndex(x => x.value == element.value)
        if (event.target.checked === true) {
            selectedOriginVal.push({
                label: element.label,
                value: element.value
            })
        }
        else {
            selectedOriginVal.splice(statusIndex, 1)
        }
        this.setState({
            selectedOriginArr: selectedOriginVal
        })
        if (selectedOriginVal.length > 0) {
            this.setState({
                showOrigin: true
            })
        }
        else {
            if (this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.originListArr && this.props.trackingGlobalFilters.originListArr.length > 0) {
                this.setState({
                    showOrigin: true
                })
            }
            else {
                this.setState({
                    showOrigin: false
                })
            }
        }
    }

    handleUnassignChecked = (event, element) => {
        let checked = event.target.checked
        if (checked) {
            this.setState({ selectedSubUserArr: [], isUnassignChecked: true, showSubUsers: true })
        } else {
            this.setState({
                isUnassignChecked: false,
            })
            if (this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.subUserListArr && this.props.trackingGlobalFilters.subUserListArr.length > 0) {
                this.setState({
                    showSubUsers: true
                })
            }
            else {
                this.setState({
                    showSubUsers: false
                })
            }
        }
    }

    handleSubUserChange = (event, element, index) => {
        let arr = [...this.state.selectedSubUserArr]
        let checked = event.target.checked
        let statusIndex = arr.findIndex(x => x.value == element.value)
        if (checked) {
            arr.push({
                label: element.label,
                value: element.value,
                isChecked: true
            })
            this.setState({
                isUnassignChecked: false
            })

        } else {
            arr.splice(statusIndex, 1)
        }
        this.setState({ selectedSubUserArr: arr })
        if (arr.length > 0) {
            this.setState({
                showSubUsers: true
            })
        }
        else {
            if (this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.subUserListArr && this.props.trackingGlobalFilters.subUserListArr.length > 0) {
                this.setState({
                    showSubUsers: true
                })
            }
            else {
                this.setState({
                    showSubUsers: false
                })
            }
        }
    }

    handleTags = (event, element) => {
        let selectedTagsVal = [...this.state.selectedTagsArr]

        let statusIndex = selectedTagsVal.findIndex(x => x.value == element.value)
        if (event.target.checked === true) {
            selectedTagsVal.push({
                label: element.label,
                value: element.value
            })
        }
        else {
            selectedTagsVal.splice(statusIndex, 1)
        }
        this.setState({
            selectedTagsArr: selectedTagsVal
        })
        if (selectedTagsVal.length > 0) {
            this.setState({
                showTags: true
            })
        }
        else {
            if (this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.tagListArr && this.props.trackingGlobalFilters.tagListArr.length > 0) {
                this.setState({
                    showTags: true
                })
            }
            else {
                this.setState({
                    showTags: false
                })
            }
        }
    }

    handleChangeDestination = (event, element) => {
        let selectDestinationVal = [...this.state.selectedDestinationArr]
        let statusIndex = selectDestinationVal.findIndex(x => x.value == element.value)
        if (event.target.checked === true) {
            selectDestinationVal.push({
                label: element.label,
                value: element.value
            })
        }
        else {
            selectDestinationVal.splice(statusIndex, 1)
        }
        this.setState({
            selectedDestinationArr: selectDestinationVal
        })
        if (selectDestinationVal.length > 0) {
            this.setState({
                showDestination: true
            })
        }
        else {
            if (this.props.trackingGlobalFilters && this.props.trackingGlobalFilters.destinationListArr && this.props.trackingGlobalFilters.destinationListArr.length > 0) {
                this.setState({
                    showDestination: true
                })
            }
            else {
                this.setState({
                    showDestination: false
                })
            }
        }
    }
    handleChangeTransitDays = (days) => {
        this.setState({
            transitDays: days,
            showTransitDays: true
        })
    }
    removeStatusFilter = (statusFilter) => {
        let selectedStatusArrVal = [...this.state.selectedStatusArr]
        if (statusFilter) {
            let arr = selectedStatusArrVal.filter(x => x.value !== statusFilter)
            this.setState({
                selectedStatusArr: arr
            }, () => {
                this.handleDone(this.displayStatusText)
            })
        }
    }
    removeCourierFilter = (courierFilter) => {
        this.props.history.push("/tracking")
        let selectedCourierArrVal = [...this.state.selectedCourierArr]
        if (courierFilter) {
            let arr = selectedCourierArrVal.filter(x => x.value !== courierFilter)
            this.setState({
                selectedCourierArr: arr
            }, () => {
                this.handleDone("")
            })
        }
    }
    removeOriginFilter = (originFilter) => {
        this.props.history.push("/tracking")
        let selectedOriginArrVal = [...this.state.selectedOriginArr]
        if (originFilter) {
            let arr = selectedOriginArrVal.filter(x => x.value !== originFilter)
            this.setState({
                selectedOriginArr: arr
            }, () => {
                this.handleDone("")
            })
        }
    }

    removeSubUser = (subUserValue) => {
        this.props.history.push("/tracking")
        let selectedSubUserList = [...this.state.selectedSubUserArr]

        if (subUserValue === false) {
            this.setState({ isUnassignChecked: false, selectedSubUserArr: [] }, () => {
                this.handleDone("")
            })
        }

        this.props.history.push("/tracking")
        if (subUserValue) {
            let arr = selectedSubUserList.filter(x => x.value !== subUserValue)
            this.setState({
                selectedSubUserArr: arr
            }, () => {
                this.handleDone("")
            })
        }
    }

    removeAppliedTag = (tagFilter) => {
        this.props.history.push("/tracking")
        let selectedTagArrval = [...this.state.selectedTagsArr]

        if (tagFilter) {
            let arr = selectedTagArrval.filter(x => x.value !== tagFilter)
            this.setState({
                selectedTagsArr: arr
            }, () => {
                this.handleDone("")
            })
        }
    }

    removeDestinationFilter = (destinationFilter) => {
        this.props.history.push("/tracking")
        let selectDestinationVal = [...this.state.selectedDestinationArr]
        if (destinationFilter) {
            let arr = selectDestinationVal.filter(x => x.value !== destinationFilter)
            this.setState({
                selectedDestinationArr: arr
            }, () => {
                this.handleDone("")
            })
        }
    }
    searchStatus = (event) => {
        var searchStatusList = this.props.trackingStatusArrayList.length > 0 ? this.props.trackingStatusArrayList : this.state.statusList
        let value = event.target.value.replace(searchRegex, "")
        searchStatusList = searchStatusList.filter(function (store) {
            return store.label.toLowerCase().search(value.toLowerCase()) !== -1;
        })
        this.setState({ statusList: searchStatusList })
    }
    searchCourier = (event) => {
        var searchCourierList = this.props.trackingCourierArrayList.length > 0 ? this.props.trackingCourierArrayList : this.state.couriersList
        let value = event.target.value.replace(searchRegex, "")
        searchCourierList = searchCourierList.filter(function (store) {
            return store.label.toLowerCase().search(value.toLowerCase()) !== -1;
        })
        this.setState({ couriersList: searchCourierList })
    }
    searchSubuser = (event) => {
        var searchSubUserInList = this.props.subUserList.length > 0 ? this.props.subUserList : this.state.subUserList
        let value = event.target.value.replace(searchRegex, "")
        searchSubUserInList = searchSubUserInList.filter(function (store) {
            return store.label.toLowerCase().search(value.toLowerCase()) !== -1
        })
        this.setState({ subUserList: searchSubUserInList })
    }
    searchOrigin = (event) => {
        var searchOriginList = this.props.originCountryArrayList.length > 0 ? this.props.originCountryArrayList : this.state.originCoutryList
        let value = event.target.value.replace(searchRegex, "")
        searchOriginList = searchOriginList.filter(function (store) {
            return store.label.toLowerCase().search(value.toLowerCase()) !== -1;
        })
        this.setState({ originCoutryList: searchOriginList })
    }
    searchDestination = (event) => {
        var searchDestinationList = this.props.destinationCountryArrayList.length > 0 ? this.props.destinationCountryArrayList : this.state.desinationCountryList
        let value = event.target.value.replace(searchRegex, "")
        searchDestinationList = searchDestinationList.filter(function (store) {
            return store.label.toLowerCase().search(value.toLowerCase()) !== -1;
        })
        this.setState({ desinationCountryList: searchDestinationList })
    }
    searchTag = (event) => {
        var tagInList = this.props.tagList.length > 0 ? this.props.filterTagsList : this.state.tagList
        let value = event.target.value.replace(searchRegex, "")
        tagInList = tagInList.filter(function (store) {
            return store.label.toLowerCase().search(value.toLowerCase()) !== -1
        })
        this.setState({ tagList: tagInList })
    }

    handleDone = (key) => {
        this.props.setPageId()
        if (key) {
            let obj = { [key]: 0 }
            this.setState(obj)
        }
        let storeObj = {}
        let trackingStatusArrValue = []
        let courierListArrValue = []
        let originListArrValue = []
        let destinationListArrValue = []

        //set Shipment Status If Any

        if (key === this.displayStatusText) {

            this.props.clearShipmentStatusFilters()
            if (this.state.selectedStatusArr && this.state.selectedStatusArr.length > 0) {

                for (let i = 0; i < this.state.selectedStatusArr.length; i++) {
                    let eachStatus = this.state.selectedStatusArr[i]

                    if (eachStatus.value !== 'All') {
                        trackingStatusArrValue.push({
                            value: eachStatus.value,
                            label: eachStatus.label
                        })
                    }
                }

            }

            storeObj.allTrackingStatusArr = trackingStatusArrValue
        }

        else {
            if (this.props.trackingGlobalFilters && Object.keys(this.props.trackingGlobalFilters).length > 0 && this.props.trackingGlobalFilters.trackingStatusArr && this.props.trackingGlobalFilters.trackingStatusArr.length > 0) {
                for (let i = 0; i < this.props.trackingGlobalFilters.trackingStatusArr.length; i++) {
                    let eachStatus = this.props.trackingGlobalFilters.trackingStatusArr[i]
                    trackingStatusArrValue.push({
                        value: eachStatus.value,
                        label: eachStatus.label
                    })
                }
            }

            storeObj.trackingStatusArr = trackingStatusArrValue
        }

        this.setState({
            displayStatus: 0,
        })


        if (this.state.selectedCourierArr && this.state.selectedCourierArr.length > 0) {
            for (let i = 0; i < this.state.selectedCourierArr.length; i++) {
                let eachCourier = this.state.selectedCourierArr[i]
                courierListArrValue.push({
                    value: eachCourier.value,
                    label: eachCourier.label
                })
            }
            this.setState({
                displayCourier: 0,
            })
            storeObj.courierListArr = courierListArrValue
        }
        if (this.state.selectedOriginArr && this.state.selectedOriginArr.length > 0) {
            for (let i = 0; i < this.state.selectedOriginArr.length; i++) {
                let eachCourier = this.state.selectedOriginArr[i]
                originListArrValue.push({
                    value: eachCourier.value,
                    label: eachCourier.label
                })
            }
            this.setState({
                displayOrigin: 0,
            })
            storeObj.originListArr = originListArrValue
        }
        if (this.state.selectedDestinationArr && this.state.selectedDestinationArr.length > 0) {
            for (let i = 0; i < this.state.selectedDestinationArr.length; i++) {
                let eachCourier = this.state.selectedDestinationArr[i]
                destinationListArrValue.push({
                    value: eachCourier.value,
                    label: eachCourier.label
                })
            }
            this.setState({
                displayDestination: 0,
            })
            storeObj.destinationListArr = destinationListArrValue
        }

        let preparedSubUserData = []
        if (this.state.selectedSubUserArr && this.state.selectedSubUserArr.length > 0) {
            for (let i = 0; i < this.state.selectedSubUserArr.length; i++) {
                let eachUser = this.state.selectedSubUserArr[i]
                preparedSubUserData.push({
                    value: eachUser.value,
                    label: eachUser.label
                })
            }
            this.setState({
                displaySubUser: 0,
            })
            storeObj.subUserListArr = preparedSubUserData
        }

        if (this.state.isUnassignChecked) {
            preparedSubUserData.push({
                value: false,
                label: "Unassigned Shipments"
            })
            storeObj.subUserListArr = preparedSubUserData
        }

        let prepareTagData = []
        if (this.state.tagList && this.state.selectedTagsArr.length > 0) {
            for (let i = 0; i < this.state.selectedTagsArr.length; i++) {
                let eachTag = this.state.selectedTagsArr[i]
                prepareTagData.push({
                    value: eachTag.value,
                    label: eachTag.label
                })
            }
            storeObj.tagListArr = prepareTagData
        }
        this.setState({ displayTransitDays: false, displayDate: false })
        storeObj.transitDays = this.state.transitDays
        storeObj.date = this.state.dateType
        storeObj.fromDate = this.state.fromDate
        storeObj.toDate = this.state.toDate
        let requestParams = {}
        if (trackingStatusArrValue.length > 0) {
            let trackingStatusListVal = []
            for (let i = 0; i < trackingStatusArrValue.length; i++) {
                let eachStatus = trackingStatusArrValue[i]
                trackingStatusListVal.push(eachStatus.value)
            }
            if (trackingStatusListVal[0] === 'All') {
                // requestParams.status = "All"
            }
            else {
                requestParams.status = trackingStatusListVal
            }
        }
        if (courierListArrValue.length > 0) {
            let courierArrListVal = []
            for (let i = 0; i < courierListArrValue.length; i++) {
                let eachCourier = courierListArrValue[i]
                courierArrListVal.push(eachCourier.value)
            }
            requestParams.courier = courierArrListVal
        }
        if (originListArrValue.length > 0) {
            let originListArrVal = []
            for (let i = 0; i < originListArrValue.length; i++) {
                let eachOrigin = originListArrValue[i]
                originListArrVal.push({
                    label: eachOrigin.label,
                    value: eachOrigin.value
                })
            }
            requestParams.origin = originListArrVal
        }
        if (destinationListArrValue.length > 0) {
            let destinationListArrVal = []
            for (let i = 0; i < destinationListArrValue.length; i++) {
                let eachDestination = destinationListArrValue[i]
                destinationListArrVal.push({
                    label: eachDestination.label,
                    value: eachDestination.value
                })
            }
            requestParams.destination = destinationListArrVal
        }
        if (storeObj.transitDays !== '') {
            requestParams.transitDays = storeObj.transitDays
        }
        if (storeObj.date !== '') {
            requestParams.date = storeObj.date
        }
        if (storeObj.fromDate !== '') {
            requestParams.from_date = storeObj.fromDate
        }
        if (storeObj.toDate !== '') {
            requestParams.to_date = storeObj.toDate
        }
        if (storeObj.subUserListArr !== '') {
            requestParams.assigned_id = storeObj.subUserListArr
        }
        if (storeObj.tagListArr !== '') {
            requestParams.tags = storeObj.tagListArr
        }

        if (this.props.trackingGlobalFilters && Object.keys(this.props.trackingGlobalFilters).length > 0 && this.props.trackingGlobalFilters.query !== undefined && this.props.trackingGlobalFilters.query !== '') {
            requestParams.query = this.props.trackingGlobalFilters.query
            storeObj.query = this.props.trackingGlobalFilters.query
        }
        this.setState({
            couriersList: this.props.trackingCourierArrayList,
            originCoutryList: this.props.originCountryArrayList,
            desinationCountryList: this.props.destinationCountryArrayList,
            subUserList: this.props.subUserList

        })
        this.props.clearTrackingList()
        this.props.startPaginationLoader()
        this.props.setCourierArrayValueList(this.props.trackingCourierArrayList)
        this.props.setOriginCountryList(this.props.originCountryArrayList)
        this.props.setDestinationCountryList(this.props.destinationCountryArrayList)
        getCarrierList().then(response => {
            if (response && response.data) {
                this.props.setCourierList(response.data)
            }
        })



        requestParams.pageId = 1
        requestParams.limit = localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : DEFAULT_TRACKING_LIMIT
        this.props.getTrackingList(requestParams)
        this.props.setTrackingFilters(storeObj)
        let urlParams = {}
        if (storeObj.trackingStatusArr) {
            let trackingStatus = storeObj.trackingStatusArr.map((element, index) => {
                return (element.value)
            })
            urlParams['trackingStatusArr'] = trackingStatus.join()
        } else if (storeObj.allTrackingStatusArr) {
            let trackingStatus = storeObj.allTrackingStatusArr.map((element, index) => {
                return (element.value)
            })
            urlParams['allTrackingStatusArr'] = trackingStatus.join() || "All"
        } else {
            urlParams['trackingStatusArr'] = "All"
        }

        if (storeObj.courierListArr) {
            let couriersList = storeObj.courierListArr.map((element, index) => {
                return (element.value)
            })
            urlParams['courierListArr'] = couriersList.join()
        }
        if (storeObj.originListArr) {
            let originList = storeObj.originListArr.map((element, index) => {
                return (element.label)
            })
            urlParams['originListArr'] = originList.join()
        }
        if (storeObj.destinationListArr) {
            let destinationList = storeObj.destinationListArr.map((element, index) => {
                return (element.label)
            })
            urlParams['destinationListArr'] = destinationList.join()
        }
        if (this.state.transitDays !== '') {
            urlParams['transitDays'] = this.state.transitDays
        }
        if (this.state.dateType !== '') {
            urlParams['date'] = this.state.dateType
        }
        if (this.state.fromDate !== '') {
            urlParams['fromDate'] = this.state.fromDate
        }
        if (this.state.toDate !== '') {
            urlParams['toDate'] = this.state.toDate
        }
        if (this.props.trackingGlobalFilters.query !== undefined && this.props.trackingGlobalFilters.query !== '' && this.props.trackingGlobalFilters.query !== null) {
            urlParams['query'] = this.props.trackingGlobalFilters.query;
        }
        if (storeObj.subUserListArr && storeObj.subUserListArr.length > 0) {
            let subUserList = storeObj.subUserListArr.map((element, index) => {
                return (element.label)
            })
            let subUserListId = storeObj.subUserListArr.map((element, index) => {
                return (element.value)
            })
            urlParams['subUserListArr'] = subUserList.join()
            urlParams['subUserListId'] = subUserListId.join()
        }
        if (storeObj.tagListArr && storeObj.tagListArr.length > 0) {
            let tagList = storeObj.tagListArr.map((element, index) => {
                return (element.label)
            })
            let tagListId = storeObj.tagListArr.map((element, index) => {
                return (element.value)
            })
            urlParams['tagListArr'] = tagList.join()
            urlParams['tagListId'] = tagListId.join()
        }
        this.props.history.replace(`?${queryString.stringify(urlParams, { strict: false })}`)
    }

    handleDoneColumn = (key) => {
        if (key) {
            let obj = { [key]: 0 }
            this.setState(obj)
        }
        localStorage.setItem('trackingColumn', JSON.stringify(this.state.selectedColumnArr))
        this.props.removeColumn()
    }
    handleCloseColumnClick = (key) => {
        if (key) {
            let obj = { [key]: 0 }
            this.setState(obj);
        }
    }
    handleColumn = (event, element) => {
        let arr = [...this.state.selectedColumnArr]
        let checked = event.target.checked
        let columnIndex = arr.findIndex(x => x.key == element.key)
        if (checked) {
            arr.push({
                label: element.label,
                key: element.key,
            })
        } else {
            arr.splice(columnIndex, 1)
        }
        this.setState({ selectedColumnArr: arr })
        if (arr.length > 0) {
            this.setState({
                showColumn: true
            })
        }
    }

    handleDaysRange = (days) => {
        this.props.history.push("/tracking")
        this.setState({
            transitDays: days,
            displayTransitDays: 0,
        }, () => {
            this.handleDone("")
        })
    }
    removeTransitionDays = (days) => {
        this.props.history.push("/tracking")
        this.setState({
            transitDays: ''
        }, () => {
            this.handleDone("")
        })
    }
    handleDate = (key) => {
        // this.props.history.push("/tracking")
        let dateObj = changeDateFunction(key, false)
        this.setState({
            fromDate: dateObj.fromDate,
            toDate: dateObj.toDate,
            dateType: dateObj.dateType,
            // displayDate: 0,
            showDate: true,
            selectDate: key
        })
    }
    handleCustom = (key) => {
        this.setState({ showCustom: true, displayDate: 0, selectDate: key })
    }
    removeDate = (days) => {
        this.props.history.push("/tracking")
        this.setState({
            fromDate: '',
            toDate: '',
            dateType: '',
            selectDate: ''
        }, () => {
            this.handleDone("")
        })
    }
    handleFilterClick = () => {
        this.props.history.push("/tracking")
    }
    setCustomDateRange = (start, end) => {
        let startDate = moment(start).format('YYYY-MM-DD 00:00:00')
        let fromUTCDate = moment(startDate).subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:00')
        let endDate = moment(end).format('YYYY-MM-DD 23:59:59')
        let toUTCDate = moment(endDate).subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:59')
        this.setState({
            showCustom: false,
            toDate: toUTCDate,
            fromDate: fromUTCDate,
            dateType: moment(start).format('DD MMM, YYYY') + ' to ' + moment(end).format('DD MMM, YYYY')
        }, () => {
            this.handleDone("")
        })
    }
    handleToggle = (value) => () => {
        const currentIndex = this.state.checked.indexOf(value);
        const newChecked = [...this.state.checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({ checked: newChecked })
    };
    drawerOpen = (key) => {
        this.props.drawerClose()
        if (key) {
            if(key === 'addTrackinngDrawer'){
                this.props.history.push(`/tracking/add`)
            }
            let obj = { [key]: true }
            this.setState(obj)
        }
        if(key == 'exportDrawer'){
            let filters = JSON.parse(JSON.stringify(this.props.trackingGlobalFilters));
            if (filters.toDate && filters.fromDate) {
                var to_date = filters.toDate.toString()
                var from_date = filters.fromDate.toString()
                var toDate = to_date.replace(",", " ")
                var fromDate = from_date.replace(",", " ")
                filters.toDate = toDate
                filters.fromDate = fromDate
            }
            this.setState({exportFilter : filters})
        }
    }
    drawerClose = () => {
        this.props.handleCancel()
        this.setState({ addTrackinngDrawer: false, exportDrawer :false, importDrawer : false })
    }
    goFull = () => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            this.setState({ isWindowExpanded : true})
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            this.setState({ isWindowExpanded : false})
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    handleKeyDown(e) {
        if (document.webkitIsFullScreen === false || document.mozFullScreen === false || document.msFullscreenElement === null) {
            this.setState({ isWindowExpanded : false})
        }
      }

    toggleDatePicker = (toggleValue = false) => {
        this.setState({showCustom : toggleValue})
    }
    onCancelClick = () => {
        this.handleCloseClick(this.displayDateText)
        this.toggleDatePicker(false)
    }
    render() {
        let DateStatus = this.state.selectDate
        let transitDaysStatus = this.state.transitDays
        let classes = this.props.classes
        let isAllStatusSelectedTab = this.props.selectedShipmentTab === 'All'
        let columnArray = this.state.isSubUser ? constant.COLUMN_ARRAY.filter((col) => col.key !== "assigned_to") : constant.COLUMN_ARRAY
        return (
            <div className="filterBlock">
                <div className="addTrackingIcon">
                    <img alt="" src={addTrackingIcon} onClick={() => this.drawerOpen(this.displayTrackingDrawerText)} />
                </div>
                <div className="filterIcon">
                    <img alt="" src={filterIcon} />
                </div>
                <div className="filterWrapper">

                    <div className="FilterBtnDD">
                        <div className="filterButton" onClick={() => this.handleOnClick(this.displayCourierText, 'showCourier')}>
                            <div className={this.props.trackingGlobalFilters.courierListArr && this.props.trackingGlobalFilters.courierListArr.length ? 'filterText activeColor' : 'filterText'}>Courier</div>
                            <div className="filterArrow"></div>
                        </div>
                        {
                            this.state.displayCourier === 1 ?
                                <div className="filterDropdown">
                                    <div className="filterText heading">
                                        <span className="verticalAlignSpan">Courier</span>
                                    </div>
                                    <div>
                                        <TextField
                                            className={classes.dropdownSearch}
                                            id="standard-search"
                                            placeholder="Search"
                                            disableUnderline={true}
                                            inputProps={{
                                                style: {
                                                    padding: '6px 0 6px 25px',
                                                    fontSize: '14px',
                                                    height: '25px',
                                                }
                                            }}
                                            type="text"
                                            onChange={this.searchCourier} />
                                    </div>
                                    <hr className="hrMarginBlock" />
                                    <div className="commonDropdownMenu scrollbar-custom">
                                        {
                                            this.state.couriersList && this.state.couriersList.length > 0 ?
                                                <List>
                                                    {this.state.couriersList.map((courier) => {
                                                        const labelId = `checkbox-list-label-${courier.value}`;
                                                        return (
                                                            <ListItem className={classes.MuiListItemSecondaryAction} key={courier.label} role={undefined} dense button onClick={this.handleToggle(courier.value)}>
                                                                <ListItemIcon className={classes.MuiListItemIcon}>
                                                                    <Checkbox
                                                                        edge="start"
                                                                        checked={this.state.selectedCourierArr.find(x => x.value === courier.value) ? true : false}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                        onChange={(event) => this.handleChangeCourier(event, courier)}
                                                                        color="primary"
                                                                        size='small'
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText className={classes.filterDropdownText} id={labelId} disableTypography={true} primary={courier.label} />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                                : <div className="noFilterFound">No Courier Found</div>
                                        }
                                    </div>

                                    <hr className="hrMarginBlock" />

                                    <div className="filterText footer">
                                        {this.state.showCourier ?
                                            <React.Fragment>
                                                <a href={null} onClick={() => this.handleDone(this.displayCourierText)}>APPLY</a>
                                                <a href={null} onClick={() => this.handleCloseClick(this.displayCourierText)}>CANCEL</a>
                                            </React.Fragment>
                                            : <a href={null} onClick={() => this.handleCloseClick(this.displayCourierText)}>CANCEL</a>}
                                    </div>
                                </div> : ''
                        }
                    </div>
                    <div className="FilterBtnDD">
                        <div className="filterButton" onClick={() => this.handleOnClick(this.displayOriginText, 'showOrigin')}>
                            <div className={this.props.trackingGlobalFilters.originListArr && this.props.trackingGlobalFilters.originListArr.length ? 'filterText activeColor' : 'filterText'}>Origin</div>
                            <div className="filterArrow"></div>
                        </div>
                        {
                            this.state.displayOrigin === 1 ?
                                <div className="filterDropdown">
                                    <div className="filterText heading">
                                        <span className="verticalAlignSpan">Origin</span>
                                    </div>
                                    <div>
                                        <TextField
                                            className={classes.dropdownSearch}
                                            id="standard-search"
                                            placeholder="Search"
                                            disableUnderline={true}
                                            inputProps={{
                                                style: {
                                                    padding: '6px 0 6px 25px',
                                                    fontSize: '14px',
                                                    height: '25px'
                                                }
                                            }}

                                            type="text"
                                            onChange={this.searchOrigin} />
                                    </div>
                                    <hr className="hrMarginBlock" />
                                    <div className="commonDropdownMenu scrollbar-custom">
                                        {
                                            this.state.originCoutryList && this.state.originCoutryList.length > 0 ?
                                                <List>
                                                    {this.state.originCoutryList.map((element) => {
                                                        const labelId = `checkbox-list-label-${element.value}`;
                                                        return (
                                                            <ListItem className={classes.MuiListItemSecondaryAction} key={element.label} role={undefined} dense button onClick={this.handleToggle(element.value)}>
                                                                <ListItemIcon className={classes.MuiListItemIcon}>
                                                                    <Checkbox
                                                                        edge="start"
                                                                        checked={this.state.selectedOriginArr.find(x => x.value === element.value) ? true : false}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                        onChange={(event) => this.handleChangeOrigin(event, element)}
                                                                        color="primary"
                                                                        size='small'
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText className={classes.filterDropdownText} disableTypography={true} id={labelId} primary={element.label} />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                                : <div className="noFilterFound">No Origin Country Found</div>
                                        }
                                    </div>
                                    <hr className="hrMarginBlock" />
                                    <div className="filterText footer">
                                        {this.state.showOrigin ?
                                            <React.Fragment>
                                                <a href={null} onClick={() => this.handleDone(this.displayOriginText)}>APPLY</a>
                                                <a href={null} onClick={() => this.handleCloseClick(this.displayOriginText)}>CANCEL</a>
                                            </React.Fragment>
                                            : <a href={null} onClick={() => this.handleCloseClick(this.displayOriginText)}>CANCEL</a>}
                                    </div>
                                </div> : ''
                        }
                    </div>

                    <div className="FilterBtnDD">
                        <div className="filterButton" onClick={() => this.handleOnClick(this.displayDestinationText, 'showDestination')}>
                            <div className={this.props.trackingGlobalFilters.destinationListArr && this.props.trackingGlobalFilters.destinationListArr.length ? 'filterText activeColor' : 'filterText'}>Destination</div>
                            <div className="filterArrow"></div>
                        </div>
                        {
                            this.state.displayDestination === 1 ?
                                <div className="filterDropdown">
                                    <div className="filterText heading">
                                        <span className="verticalAlignSpan">Destination</span>
                                    </div>
                                    <div>
                                        <TextField
                                            className={classes.dropdownSearch}
                                            id="standard-search"
                                            placeholder="Search"
                                            disableUnderline={true}
                                            inputProps={{
                                                style: {
                                                    padding: '6px 0 6px 25px',
                                                    fontSize: '14px',
                                                    height: '25px'
                                                }
                                            }}

                                            type="text"
                                            onChange={this.searchDestination} />
                                    </div>
                                    <hr className="hrMarginBlock" />
                                    <div className="commonDropdownMenu scrollbar-custom">
                                        {
                                            this.state.desinationCountryList && this.state.desinationCountryList.length > 0 ?
                                                <List>
                                                    {this.state.desinationCountryList.map((element) => {
                                                        const labelId = `checkbox-list-label-${element.value}`;
                                                        return (
                                                            <ListItem className={classes.MuiListItemSecondaryAction} key={element.label} role={undefined} dense button onClick={this.handleToggle(element.value)}>
                                                                <ListItemIcon className={classes.MuiListItemIcon}>
                                                                    <Checkbox
                                                                        edge="start"
                                                                        checked={this.state.selectedDestinationArr.find(x => x.value === element.value) ? true : false}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                        onChange={(event) => this.handleChangeDestination(event, element)}
                                                                        color="primary"
                                                                        size="small"
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText id={labelId} className={classes.filterDropdownText} disableTypography={true} primary={element.label} />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                                : <div className="noFilterFound">No Destination Country Found</div>
                                        }
                                    </div>
                                    <hr className="hrMarginBlock" />
                                    <div className="filterText footer">
                                        {this.state.showDestination ?
                                            <React.Fragment>
                                                <a href={null} onClick={() => this.handleDone(this.displayDestinationText)}>APPLY</a>
                                                <a href={null} onClick={() => this.handleCloseClick(this.displayDestinationText)}>CANCEL</a>
                                            </React.Fragment>
                                            : <a href={null} onClick={() => this.handleCloseClick(this.displayDestinationText)}>CANCEL</a>}
                                    </div>
                                </div> : ''
                        }
                    </div>
                    <div className="FilterBtnDD">
                        <div className="filterButton" onClick={() => this.handleOnClick(this.displayTransitDaysText, 'showTransitDays')}>
                            <div className={this.props.trackingGlobalFilters.transitDays && this.props.trackingGlobalFilters.transitDays.length ? 'filterText activeColor' : 'filterText'}>Transit Days</div>
                            <div className="filterArrow"></div>
                        </div>
                        {
                            this.state.displayTransitDays === 1 ?
                                <div className="filterDropdown">
                                    <div className="filterText heading">
                                        <span className="verticalAlignSpan">Transit Days</span>
                                    </div>
                                    <div className="commonDropdownMenu scrollbar-custom">
                                        {
                                            <List>
                                                {this.state.transitDaysArray.map((element) => {
                                                    const labelId = `checkbox-list-label-${element.key}`;
                                                    return (
                                                        <ListItem className={classes.MuiListItemSecondaryAction} key={element.label} role={undefined} dense button onClick={(event) => this.handleChangeTransitDays(element.label)}>
                                                            <ListItemIcon className={classes.MuiListItemWithoutIcon}>
                                                                <Radio
                                                                    checked={transitDaysStatus == element.label ? true : false}
                                                                    onChange={(event) => this.handleChangeTransitDays(element.label)}
                                                                    name="radio-button-demo"
                                                                    color='primary'
                                                                    size='small'
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={labelId} className={classes.filterDropdownText} disableTypography={true} primary={element.label} />
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        }
                                    </div>
                                    <hr className="hrMarginBlock" />
                                    <div className="filterText footer">
                                        {this.state.showTransitDays ?
                                            <React.Fragment>
                                                <a href={null} onClick={() => this.handleDone()}>APPLY</a>
                                                <a href={null} onClick={() => this.handleCloseClick(this.displayTransitDaysText)}>CANCEL</a>
                                            </React.Fragment>
                                            : <a href={null} onClick={() => this.handleCloseClick(this.displayTransitDaysText)}>CANCEL</a>}
                                    </div>
                                </div> : ''
                        }
                    </div>
                    <div className="FilterBtnDD">
                        <div className="filterButton" onClick={() => this.handleOnClick(this.displayDateText, 'showDate')}>
                            <div className={this.props.trackingGlobalFilters.date && this.props.trackingGlobalFilters.date.length ? 'filterText activeColor' : 'filterText'}>Date</div>
                            <div className="filterArrow"></div>
                        </div>
                        {
                            this.state.displayDate === 1 ?
                                <div className="filterDropdown">
                                    <div className="filterText heading">
                                        <span className="verticalAlignSpan">Date</span>
                                    </div>
                                    <div className="commonDropdownMenu scrollbar-custom">
                                        {
                                            <List>
                                                {this.dateArray.map((element) => {
                                                    const labelId = `checkbox-list-label-${element.key}`;
                                                    return (
                                                        <ListItem className={classes.MuiListItemSecondaryAction} key={element.label} role={undefined} dense button onClick={(event) => element.label == 'Custom' ? this.handleCustom(element.key) : this.handleDate(element.key)}>
                                                            <ListItemIcon className={classes.MuiListItemWithoutIcon}>
                                                                <Radio
                                                                    checked={DateStatus == element.key ? true : false}
                                                                    onChange={(event) => element.label == 'Custom' ? this.handleCustom(element.key) : this.handleDate(element.key)}
                                                                    // value="a"
                                                                    name="radio-button-demo"
                                                                    inputProps={{ 'aria-label': 'A' }}
                                                                    color='primary'
                                                                    size='small'
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText className={classes.filterDropdownText} disableTypography={true} id={labelId} primary={element.label} />
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        }
                                    </div>
                                    <hr className="hrMarginBlock" />
                                    <div className="filterText footer">
                                        {this.state.showDate ?
                                            <React.Fragment>
                                                <a href={null} onClick={() => this.handleDone()}>APPLY</a>
                                                <a href={null} onClick={() => this.handleCloseClick(this.displayDateText)}>CANCEL</a>
                                            </React.Fragment>
                                            : <a href={null} onClick={() => this.handleCloseClick(this.displayDateText)}>CANCEL</a>}
                                    </div>
                                </div> : ''
                        }
                        {this.state.showCustom === true ?
                            <MuiPickersUtilsProvider utils={DateUtils}>
                                <div className="App">
                                    <DateRangePicker
                                        open={this.state.showCustom}
                                        onOpen={() => {}}
                                        onDismiss={this.onCancelClick}
                                        onClose={() => {
                                            this.toggleDatePicker(false);
                                        }}
                                        value={[]}
                                        onChange={this.setCustomDateRange}
                                        TextFieldComponent={() => null}
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                            : ''
                        }
                    </div>
                    {
                        this.props.subUserList && this.props.subUserList.length > 0 && this.state.isNotSubUser ?
                            <div className="FilterBtnDD">
                                <div className="filterButton" onClick={() => this.handleOnClick(this.displaySubUserText, 'showSubUsers')}>
                                    <div className={this.props.trackingGlobalFilters.subUserListArr && this.props.trackingGlobalFilters.subUserListArr.length ? 'filterText activeColor' : 'filterText'}>Sub Users</div>
                                    <div className="filterArrow"></div>
                                </div>
                                {
                                    this.state.displaySubUser === 1 ?
                                        <div className="filterDropdown">
                                            <div className="filterText heading">
                                                <span className="verticalAlignSpan">Sub Users</span>
                                            </div>
                                            <div>
                                                <TextField
                                                    className={classes.dropdownSearch}
                                                    id="standard-search"
                                                    placeholder="Search"
                                                    disableUnderline={true}
                                                    inputProps={{
                                                        style: {
                                                            padding: '6px 0 6px 25px',
                                                            fontSize: '14px',
                                                            height: '25px'
                                                        }
                                                    }}

                                                    type="text"
                                                    onChange={this.searchSubuser} />
                                            </div>
                                            <hr className="hrMarginBlock" />
                                            <div className="commonDropdownMenuStatic scrollbar-custom" style={{ paddingTop: '0px' }} >
                                                {
                                                    this.state.subUserList && this.state.subUserList.length > 0 ?
                                                        <List>
                                                            <ListItem className={classes.MuiListItemSecondaryAction} key={'Unassigned Shipments'} role={undefined} dense button>
                                                                <ListItemIcon className={classes.MuiListItemIcon}>
                                                                    <Checkbox
                                                                        edge="start"
                                                                        checked={this.state.isUnassignChecked}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{ 'aria-labelledby': 'false' }}
                                                                        onChange={(event) => this.handleUnassignChecked(event)}
                                                                        color="primary"
                                                                        size="small"
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText className={classes.filterDropdownText} disableTypography={true} id={'false'} primary={'Unassigned Shipments'} />
                                                            </ListItem>
                                                            <hr className="hrMarginBlock" />
                                                            {this.state.subUserList.map((element) => {
                                                                const labelId = `checkbox-list-label-${element.value}`;
                                                                return (
                                                                    <ListItem className={classes.MuiListItemSecondaryAction} key={element.label} role={undefined} dense button onClick={this.handleToggle(element.value)}>
                                                                        <ListItemIcon className={classes.MuiListItemIcon}>
                                                                            <Checkbox
                                                                                edge="start"
                                                                                checked={this.state.selectedSubUserArr.find(x => x.value === element.value) ? true : false}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                                                onChange={(event) => this.handleSubUserChange(event, element)}
                                                                                color="primary"
                                                                                size="small"
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText className={classes.filterDropdownText} disableTypography={true} id={labelId} primary={element.label} />
                                                                    </ListItem>
                                                                );
                                                            })}
                                                        </List>
                                                        : <div className="noFilterFound">No Sub User Found</div>
                                                }
                                            </div>
                                            <hr className="hrMarginBlock" />
                                            <div className="filterText footer">
                                                {this.state.showSubUsers ?
                                                    <React.Fragment>
                                                        <a href={null} onClick={() => this.handleDone(this.displaySubUserText)}>APPLY</a>
                                                        <a href={null} onClick={() => this.handleCloseClick(this.displaySubUserText)}>CANCEL</a>
                                                    </React.Fragment>
                                                    : <a href={null} onClick={() => this.handleCloseClick(this.displaySubUserText)}>CANCEL</a>}
                                            </div>
                                        </div> : ''
                                }
                            </div>
                            : ""
                    }
                    <div className="FilterBtnDD">
                        <div className="filterButton" onClick={() => this.handleOnClick(this.displayTagsText, 'showTags')}>
                            <div className={this.props.trackingGlobalFilters.tagListArr && this.props.trackingGlobalFilters.tagListArr.length ? 'filterText activeColor' : 'filterText'}>Tags</div>
                            <div className="filterArrow"></div>
                        </div>
                        {
                            this.state.displayTags === 1 ?
                                <div className="filterDropdown">
                                    <div className="filterText heading">
                                        <span className="verticalAlignSpan">Tags</span>
                                    </div>
                                    <div>
                                        <TextField
                                            className={classes.dropdownSearch}
                                            id="standard-search"
                                            placeholder="Search"
                                            disableUnderline={true}
                                            inputProps={{
                                                style: {
                                                    padding: '6px 0 6px 25px',
                                                    fontSize: '14px',
                                                    height: '25px'
                                                }
                                            }}

                                            type="text"
                                            onChange={this.searchTag} />
                                    </div>
                                    <hr className="hrMarginBlock" />
                                    <div className="commonDropdownMenu scrollbar-custom">
                                        {
                                            this.state.tagList && this.state.tagList.length > 0 ?
                                                <List>
                                                    {this.state.tagList.map((element) => {
                                                        const labelId = `checkbox-list-label-${element.value}`;
                                                        return (
                                                            <ListItem className={classes.MuiListItemSecondaryAction} key={element.label} role={undefined} dense button onClick={this.handleToggle(element.value)}>
                                                                <ListItemIcon className={classes.MuiListItemIcon}>
                                                                    <Checkbox
                                                                        edge="start"
                                                                        checked={this.state.selectedTagsArr.find(x => x.value === element.value) ? true : false}
                                                                        tabIndex={-1}
                                                                        disableRipple
                                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                                        onChange={(event) => this.handleTags(event, element)}
                                                                        color="primary"
                                                                        size="small"
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText className={classes.filterDropdownText} disableTypography={true} id={labelId} primary={element.label} />
                                                            </ListItem>
                                                        );
                                                    })}
                                                </List>
                                                : <div className="noFilterFound">No Tags Found</div>
                                        }
                                    </div>
                                    <hr className="hrMarginBlock" />
                                    <div className="filterText footer">
                                        {this.state.showTags ?
                                            <React.Fragment>
                                                <a href={null} onClick={() => this.handleDone(this.displayTagsText)}>APPLY</a>
                                                <a href={null} onClick={() => this.handleCloseClick(this.displayTagsText)}>CANCEL</a>
                                            </React.Fragment>
                                            : <a href={null} onClick={() => this.handleCloseClick(this.displayTagsText)}>CANCEL</a>}
                                    </div>

                                </div> : ''
                        }
                    </div>


                    {/* All Shipment Status Filters... */}



                    <div className="FilterBtnDD" style={isAllStatusSelectedTab && false ? { display: 'block' } : { display: 'none' }}>
                        <div className="filterButton" onClick={() => this.handleOnClick(this.displayStatusText, 'showStatus')}>
                            <div className="filterText">Status</div>
                            <div className="filterArrow"></div>
                        </div>
                        {
                            this.state.displayStatus === 1 ?
                                <div className="filterDropdown">
                                    <div className="filterText heading">
                                        <span>Status</span>
                                        {
                                            this.state.showStatus ?
                                                <a href={null} onClick={() => this.handleDone(this.displayStatusText)}>Done</a> :
                                                <a href={null} onClick={() => this.handleCloseClick(this.displayStatusText)}>Close</a>
                                        }
                                    </div>
                                    <div className="filterSearchDiv">
                                        <input type="text" className="searchFilter" onChange={this.searchStatus} />
                                    </div>
                                    <div className="commonDropdownMenu scrollbar-custom">
                                        <ul>
                                            {
                                                this.state.statusList.map((element, indexValue) => {
                                                    return (
                                                        <li key={indexValue}>
                                                            <label>
                                                                <input type="checkbox"
                                                                    name={element.label}
                                                                    value={element.value}
                                                                    checked={this.state.selectedStatusArr.find(x => x.value === element.value) ? true : false}
                                                                    onChange={(event) => this.handleChangeStatus(event, element)}
                                                                />
                                                                <span className="spanText">{getTrackingStatusName(element.label)}</span>
                                                            </label>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div> : ''
                        }
                    </div>



                    <div className="FilterBtnDD" style={{ display: 'none' }}>
                        <div className="filterButton" onClick={() => this.handleOnClick(this.displayExpectedDateText, 'showExpectedDate')}>
                            <div className="filterText">Expected Date</div>
                            <div className="filterArrow"></div>
                        </div>
                        {
                            this.state.displayExpectedDate === 1 ?
                                <div className="filterDropdown">
                                    <div className="filterText heading">
                                        <span>Expected Date</span>
                                        <a href={null} onClick={() => this.handleCloseClick(this.displayExpectedDateText)}>Close</a>
                                    </div>
                                    <div className="commonDropdownMenu scrollbar-custom transitDaysRange " style={{ 'marginBottom': '0.4rem' }}>
                                        <ul>
                                            <li key={"1"} style={DateStatus === 'Today' ? { backgroundColor: '#DEF1FF' } : { backgroundColor: '#FFFFFF' }} onClick={() => this.handleDate('Today')}><span>Today</span></li>
                                            <li key={"2"} style={DateStatus === 'Yesterday' ? { backgroundColor: '#DEF1FF' } : { backgroundColor: '#FFFFFF' }} onClick={() => this.handleDate('Yesterday')}><span>Yesterday</span></li>
                                            <li key={"3"} style={DateStatus === 'Last 7 Days' ? { backgroundColor: '#DEF1FF' } : { backgroundColor: '#FFFFFF' }} onClick={() => this.handleDate('Last 7 Days')}><span>Last 7 Days</span></li>
                                            <li key={"4"} style={DateStatus === 'Last 15 Days' ? { backgroundColor: '#DEF1FF' } : { backgroundColor: '#FFFFFF' }} onClick={() => this.handleDate('Last 14 Days')}><span>Last 14 Days</span></li>
                                            <li key={"5"} style={DateStatus === 'Last 30 Days' ? { backgroundColor: '#DEF1FF' } : { backgroundColor: '#FFFFFF' }} onClick={() => this.handleDate('Last 30 Days')}><span></span>Last 30 Days</li>
                                        </ul>
                                    </div>
                                </div> : ''
                        }
                    </div>
                </div>
                {/* <div className="clearFix"></div> */}
                <div className="actionIconWrapper">
                <div className="actionIconImg">
                        <img  onClick={this.goFull} src={(this.state.isWindowExpanded) ? collapseIcon : expandIcon} />
                        <span  onClick={this.goFull} className="actionIconText">{(this.state.isWindowExpanded) ? "Collapse" : "Expand"}</span>
                    </div>
                    <div className="actionIconImg">
                        <img onClick={() => this.handleOnClick(this.displayColumnText, 'showColumn')} src={columnIcon} />
                        <span onClick={() => this.handleOnClick(this.displayColumnText, 'showColumn')} className="actionIconText">Column</span>
                        <div className="filterWrapperRight">
                            <div className="FilterBtnDD">
                                {
                                    this.state.displayColumn === 1 ?
                                        <div className="filterDropdown">
                                            <div className="filterText heading">
                                                <span className="verticalAlignSpan">Column</span>
                                            </div>
                                            <div className="commonDropdownMenu scrollbar-custom">
                                                {
                                                    <List>
                                                        {columnArray.map((element) => {
                                                            const labelId = `checkbox-list-label-${element.key}`;
                                                            return (
                                                                <ListItem className={classes.MuiListItemSecondaryAction} key={element.label} role={undefined} dense button onClick={(event) => this.handleChangeTransitDays(element.label)}>
                                                                    <ListItemIcon className={classes.MuiListItemIcon}>
                                                                        <Checkbox
                                                                            edge="start"
                                                                            checked={this.state.selectedColumnArr.find(x => x.key === element.key) ? true : false}
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                                            onChange={(event) => this.handleColumn(event, element)}
                                                                            color="primary"
                                                                            size="small"
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText id={labelId} className={classes.filterDropdownText} disableTypography={true} primary={element.label} />
                                                                </ListItem>
                                                            );
                                                        })}
                                                    </List>
                                                }
                                            </div>
                                            <hr className="hrMarginBlock" />
                                            <div className="filterText footer">
                                                {this.state.showColumn ?
                                                    <div>
                                                        <a href={null} onClick={() => this.handleDoneColumn(this.displayColumnText)}>APPLY</a>
                                                        <a href={null} onClick={() => this.handleCloseColumnClick(this.displayColumnText)}>CANCEL</a>
                                                    </div>
                                                    : <a href={null} onClick={() => this.handleCloseColumnClick(this.displayColumnText)}>CANCEL</a>}
                                            </div>
                                        </div> : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="actionIconImg">
                        <img onClick={(event) => this.drawerOpen(this.displayImportText)} src={importTrackingIcon} />
                        <span onClick={(event) => this.drawerOpen(this.displayImportText)} className="actionIconText">Import</span>
                    </div>
                    <div className="actionIconImg">
                        <img onClick={(event) => this.drawerOpen(this.displayExportText)} src={exportTrackingIcon} />
                        <span onClick={(event) => this.drawerOpen(this.displayExportText)} className="actionIconText">Export</span>
                    </div>
                </div>
                <div className="selectedFiltersListDiv">

                    {
                        isAllStatusSelectedTab && this.props.trackingGlobalFilters !== undefined && Object.keys(this.props.trackingGlobalFilters).length > 0 ?
                            this.props.trackingGlobalFilters.allTrackingStatusArr && this.props.trackingGlobalFilters.allTrackingStatusArr.length > 0 ?
                                this.props.trackingGlobalFilters.allTrackingStatusArr.map((element, index) => {


                                    return (
                                        element.value !== 'All' ?
                                            <div className="Tags" key={index}>
                                                <span className="tagText">{element.label}</span>
                                                <span className="closeTag" onClick={() => this.removeStatusFilter(element.value)}></span>
                                            </div> : ''
                                    )
                                })
                                : '' : ''
                    }


                    {
                        this.props.trackingGlobalFilters !== undefined && Object.keys(this.props.trackingGlobalFilters).length > 0 ?
                            this.props.trackingGlobalFilters.courierListArr && this.props.trackingGlobalFilters.courierListArr.length > 0 ?
                                this.props.trackingGlobalFilters.courierListArr.map((element, index) => {
                                    return (
                                        <div className="Tags" key={index}>
                                            <span className="tagText">{getCourierLabel(element.value, this.props.addedCourierList)}</span>
                                            <span className="closeTag" onClick={() => this.removeCourierFilter(element.value)}></span>
                                        </div>
                                    )
                                })
                                : '' : ''
                    }
                    {
                        this.props.trackingGlobalFilters !== undefined && Object.keys(this.props.trackingGlobalFilters).length > 0 ?
                            this.props.trackingGlobalFilters.originListArr && this.props.trackingGlobalFilters.originListArr.length > 0 ?
                                this.props.trackingGlobalFilters.originListArr.map((element, index) => {
                                    return (
                                        <div className="Tags" key={index}>
                                            <span className="tagText">{element.label}</span>
                                            <span className="closeTag" onClick={() => this.removeOriginFilter(element.value)}></span>
                                        </div>
                                    )
                                })
                                : '' : ''
                    }
                    {
                        this.props.trackingGlobalFilters !== undefined && Object.keys(this.props.trackingGlobalFilters).length > 0 ?
                            this.props.trackingGlobalFilters.destinationListArr && this.props.trackingGlobalFilters.destinationListArr.length > 0 ?
                                this.props.trackingGlobalFilters.destinationListArr.map((element, index) => {
                                    return (
                                        <div className="Tags" key={index}>
                                            <span className="tagText">{element.label}</span>
                                            <span className="closeTag" onClick={() => this.removeDestinationFilter(element.value)}></span>
                                        </div>
                                    )
                                })
                                : '' : ''
                    }
                    {
                        this.props.trackingGlobalFilters !== undefined && Object.keys(this.props.trackingGlobalFilters).length > 0 ?
                            this.props.trackingGlobalFilters.transitDays && this.props.trackingGlobalFilters.transitDays !== '' ?
                                <div className="Tags TagGrey">
                                    <span>{this.props.trackingGlobalFilters.transitDays}</span>
                                    <span className="closeTag" onClick={() => this.removeTransitionDays(this.props.trackingGlobalFilters.transitDays)}></span>
                                </div>
                                : '' : ''
                    }
                    {
                        this.props.trackingGlobalFilters !== undefined && Object.keys(this.props.trackingGlobalFilters).length > 0 ?
                            this.props.trackingGlobalFilters.date ?
                                <div className="Tags TagGrey">
                                    <span>{this.props.trackingGlobalFilters.date}</span>
                                    <span className="closeTag" onClick={() => this.removeDate(this.props.trackingGlobalFilters.fromDate)}></span>
                                </div>
                                : '' : ''
                    }

                    {
                        this.props.trackingGlobalFilters !== undefined && Object.keys(this.props.trackingGlobalFilters).length > 0 ?
                            this.props.trackingGlobalFilters.subUserListArr && this.props.trackingGlobalFilters.subUserListArr.length > 0 ?
                                this.props.trackingGlobalFilters.subUserListArr.map((element, index) => {
                                    return (
                                        <div className="Tags" key={index}>
                                            <span className="tagText">{element.label}</span>
                                            <span className="closeTag" onClick={() => this.removeSubUser(element.value)}></span>
                                        </div>
                                    )
                                })
                                : '' : ''
                    }

                    {
                        this.props.trackingGlobalFilters !== undefined && Object.keys(this.props.trackingGlobalFilters).length > 0 ?
                            this.props.trackingGlobalFilters.tagListArr && this.props.trackingGlobalFilters.tagListArr.length > 0 ?
                                this.props.trackingGlobalFilters.tagListArr.map((element, index) => {
                                    return (
                                        <div className="Tags" key={index}>
                                            <span className="tagText">{element.label}</span>
                                            <span className="closeTag" onClick={() => this.removeAppliedTag(element.value)}></span>
                                        </div>
                                    )
                                })
                                : '' : ''
                    }

                </div>
                <Drawer
                    openDrawer={this.state.addTrackinngDrawer}
                    closeDrawer={this.drawerClose}
                    classes={classes}
                    // toggleAddNewTracking={this.props.toggleAddNewTracking}
                    // sendStatusListValue={this.props.setStatusListVal}
                    // carrierList={this.props.carrierList}
                    handleCancel={this.props.handleCancel}
                    changeTabToAll={this.props.changeTabToAll}
                    startPaginationLoader={this.props.startPaginationLoader}
                    setPageId={this.props.setPageId}
                />
                <ImportDrawer
                    openDrawer={this.state.importDrawer}
                    closeDrawer={this.drawerClose}
                    classes={classes}
                    setPageId={this.props.setPageId}
                    setExportMessage = {this.props.setExportMessage}
                />
                <ExportDrawer
                    openDrawer={this.state.exportDrawer}
                    closeDrawer={this.drawerClose}
                    classes={classes}
                    setPageId={this.props.setPageId}
                    setExportMessage = {this.props.setExportMessage}
                    hasShipments={((this.props.initialState.showPending === 0) && (this.props.initialState.showInTransits === 0) && (this.props.initialState.showToHandover === 0) && (this.props.initialState.showCompleted === 0)) ? false : true}
                    filters={this.state.exportFilter}
                    onExport = {this.props.handleExportShipments}
                    isSelected = {false}
                    selectedIDs={[]}
                />

            </div>

        )
    }
}
const mapStateToProps = state => ({
    trackingGlobalFilters: state.tracking.list.trackingFilters,
    trackingStatusArrayList: state.tracking.list.trackingStatusArrayList,
    trackingCourierArrayList: state.tracking.list.trackingCourierArrayList,
    originCountryArrayList: state.tracking.list.originCountryArrayList,
    destinationCountryArrayList: state.tracking.list.destinationCountryArrayList,
    isShipmentAdded: state.tracking.list.isShipmentAdded,
    addedCourierList: state.tracking.list.addedTrackingCourierList,
    subUserList: state.tracking.list.addedSubUserList,
    tagList: state.tracking.list.tags,
    filterTagsList: state.tracking.list.filterTags,
    selectedShipmentTab: state.tracking.list.selectedShipmentTab,
    initialState: state.tracking.list,

})
export default withStyles(styles)(
    withRouter(connect(mapStateToProps, { startPaginationLoader, clearTrackingList, setTrackingFilters, setStatusArrayValueList, setCourierArrayValueList, setOriginCountryList, setDestinationCountryList, setTransitionDays,  getFiltersList, getTrackingList, setCourierList, clearShipmentStatusFilters })(Filters))
)
