import React from 'react'
import NoAccess from './NoAccess'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import HelmetComponent from './HelmetComponent'

const mapStateToProps = state => ({
    isLoggedIn: state.authenticate.isLoggedIn,
    roles: state.authenticate.roles
})

const PrivateRoute = ({ component: Component, ...rest }) => {
    const defaultTitle = 'Trackhive'  
    const users = JSON.parse(localStorage.getItem('userNamesList'))
    const subdomain_status = JSON.parse(localStorage.getItem('sub_domain_status'))
    let roles = rest.roles && rest.roles.length > 0 ? rest.roles : []
    let check_user_type = localStorage.getItem('user_type') 
    let loginRedirect = roles.length && roles.length > 0 ? roles[0] === 'settings' ? 'settings' : roles[0] : 'settings'   
    return (
        <Route onEnter={() => window.analytics()} {...rest} render={props => (
            rest.isLoggedIn ?(
               ( check_user_type === 'user' || check_user_type == null || check_user_type === '') && subdomain_status === 0 &&  props.location.pathname !== '/onboarding' ? 
                <Redirect to={{
                 pathname: '/onboarding',
                 state: { from: props.location }
                 }}/> : subdomain_status === 1 &&  props.location.pathname === '/onboarding' ?
                 <Redirect to={{
                    pathname: '/dashboard',
                    state: { from: props.location }
                    }}/>:
                    ((check_user_type === 'user' || check_user_type == null || check_user_type === '') ? 
                        <React.Fragment>
                            <HelmetComponent title={rest.title ? rest.title : defaultTitle}/>
                            <Component {...props}/>
                        </React.Fragment>
                        : 
                        roles.includes(rest.allowedRole) || rest.allowedRole === "settingMainMenu" ?
                        <React.Fragment>
                            <HelmetComponent title={rest.title ? rest.title : defaultTitle}/>
                            <Component {...props}/>
                        </React.Fragment>
            
                        :
                        props.location.pathname === '/' ?  <Redirect to={{pathname : `/${loginRedirect}`}}/>  :     
                        <NoAccess/>
                    )) :
            props.location.state === {pathname:'/accept_invite'} ? <Redirect to="/accept_invite"/> :
            <Redirect to={{
                pathname: users ? '/users' : '/login',
                state: { from: props.location }
                }}/>
        )}/>
    )
}

export default connect(mapStateToProps)(PrivateRoute)
