import React from 'react'
import SubUser from './subuser'
import Roles from './roles'
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Styles from '../../assets/css/Styles';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {addIcon,Users} from '../../../common/imageList';
import { LinearProgressBar, StyledCard, StyledTypography, BreadCrumbs, Image } from '../../common/materialComponents';

class UserAndRolesTab extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            value: 0,
            open: false,
            isEditRole: false,
            isLoading: true,
            paginationLoading : false
        }
    }

    handleChange = (event,newValue) => {
        this.setState({value: newValue,open:false})
    }

    openDrawer = () => {
        this.setState({open: true,isEditRole: false})
    }

    closeDrawer = () => {
        this.setState({open: false,isEditRole: false})
    }

    editDrawer = () => {
        this.setState({open: true, isEditRole: true})
    }

    loader = (isLoading) => {
        this.setState({isLoading})
    }
    paginationLoader = (paginationLoading) => {
        this.setState({paginationLoading})
    }

    render(){
        const {classes} = this.props
        return(
            <React.Fragment>
            <div style = {{display:(this.state.paginationLoading || this.state.isLoading) ? 'block' : 'none'}}>
                <LinearProgressBar/>
            </div> 
            <div className="settingsModule" style = {{display:!this.state.isLoading ? 'block' : 'none'}}>
                     <StyledCard>
                        <BreadCrumbs aria-label="breadcrumb">
                            <Link className={classes.link} to="/settings">
                                Settings
                            </Link>
                           <StyledTypography className={classes.text}>Sub Users</StyledTypography>     
                        </BreadCrumbs>
                    </StyledCard>
                <div className="dataContainer">
                        <div className="containerHeader">
                            <Image alt="usersNroles_IC" className={classes.avatar} src={Users} />
                            <div className="containerTitle" style={{width: '180px'}}>{`Users & Roles`}</div>
                            <div style={{float: "right", textAlign:"right", paddingRight: '26px', cursor: 'pointer'}}>
                                <img src={addIcon} alt="Add Icon" onClick={this.openDrawer} />
                            </div>
                        </div>
                    <div className={classes.mainRoot}>
                        <AppBar position="static" className={classes.appBar}>
                        <Tabs  classes={{ indicator: classes.indicatorColor }} value={this.state.value} onChange={this.handleChange} aria-label="simple tabs example">
                            <Tab label="Users" />
                            <Tab label="Roles" />s
                        </Tabs>
                        </AppBar>
                        <TabPanel className={classes.tabpanel} value={this.state.value} index={0}>
                            <SubUser 
                                type="user" 
                                open={this.state.open} 
                                classes={classes} 
                                isEditRole = {this.state.isEditRole} 
                                closeDrawer = {this.closeDrawer} 
                                editDrawer = {this.editDrawer}
                                isLoading = {this.loader}    
                                paginationLoading = {this.paginationLoader}
                            />
                        </TabPanel>
                        <TabPanel className={classes.tabpanel} value={this.state.value} index={1}>
                            <Roles 
                                type="role" 
                                open={this.state.open} 
                                classes={classes} 
                                isEditRole = {this.state.isEditRole} 
                                closeDrawer = {this.closeDrawer} 
                                editDrawer = {this.editDrawer}
                            />
                        </TabPanel>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}

export default withStyles(Styles)(UserAndRolesTab)

function TabPanel(props) {
    const { children, value, index,  ...other } = props;  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};