import { START_LOADING, END_LOADING } from '../constants/actionTypes';

export let startLoading = () => {
    return {
        type: START_LOADING
    };
};

export let endLoading = () => {
    return {
        type: END_LOADING,
    };
};