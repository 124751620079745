import React from 'react'
import '../../assets/css/emailSettings.scss'
import { Link, withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { scrollbar } from '../../../static/constants';
import SmsTemplateLoader from './sms_template_loader'
import { createCustomSMSTemplate, getSMSTemplate,updateSMSTemplate } from '../../actions/actionMethods'
import {CREATE_SMS_TEMPLATE, UPDATE_SMS_TEMPLATE,CREATE_TEMPLATE_ERROR, smsAccordionsMergers} from '../../common/constants'
import { Accordion } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import InputAccordion from './InputAccordion'

import {infiniteLoader, breadCrumbIcon,cancelIcon,saveIcon} from '../../../common/imageList'

class CustomSMSTemplate extends React.Component{
  constructor(props){
      super(props)
      this.state = {                           
          templateError: false,      
          template_name: '',      
          body: '',
          bodyError:false,    
          apiError: false,        
          showLoader: false,
          success: false,
          loaderFlag: true
      }
    }

  componentWillMount(){
      this.getData()
  }

  getData = () => {
    if(this.props.match.params.id !== undefined && this.props.match.path !== undefined){
      getSMSTemplate(this.props.match.params.id).then(res =>{
        if(res.status >= 200 && res.status <= 299){
            this.setState({                    
                template_name: res.data.data.template_name,
                body: res.data.data.body,  
                loaderFlag: false                   
            } , () => {console.log()})
        }
        }).catch(error => {
             console.log(error)
        })
      }else{
        this.setState({ loaderFlag: false })           
      }
  }

  handleTemplateName = (event) => {
    if(event.target.value === " ") {
      return;
    } else {
      this.setState({
        template_name: event.target.value,
        templateError: false
      })
    }
  }

  handleBody = (event) => {
      if(event.target.value === " ") {
        return;
      } else {
        this.setState({
          body: event.target.value,
          bodyError: false
        })
      }
  }  

  handleInsertValue = (value) => {
    let element = document.getElementById('sms_body')
    let cursorPosStart = element.selectionStart
    let newBody = this.state.body.slice(0, cursorPosStart) + value + this.state.body.slice(cursorPosStart)
    this.setState({ body: newBody }, () => { element.focus(); element.setSelectionRange(cursorPosStart + value.length, cursorPosStart + value.length) })
  }
  

  checkValidation = () => {
    let errorCount = 0

    if(this.state.template_name === '' || this.state.template_name.trim().length === 0) {
      this.setState({templateError: true})
      errorCount++
    } else {
      this.setState({templateError: false})
    }

    if(this.state.body === '' || this.state.body.trim().length === 0) {
      this.setState({bodyError: true})
      errorCount++
    } else {
      this.setState({bodyError: false})
    }    
    if(errorCount > 0) {
      return false
    }
      return true
  }

  saveSMSSetting = () => {
    let smsData = {}
    this.setState({showLoader: true})      
    let validation = this.checkValidation()

    if(validation){
      smsData.template_name = this.state.template_name           
      smsData.body = this.state.body
      if (this.props.match.path.includes('edit_sms_template')) {
        updateSMSTemplate(this.props.match.params.id,smsData).then(res => {
          if (res.status >= 200 && res.status <= 299) {
            this.setState({ showLoader: false, success: UPDATE_SMS_TEMPLATE, apiError: false })
          } else {
            this.setState({ showLoader: false, success: false, apiError: res.data.meta.message })
          }
        }).catch(err => {
            console.log(err)
            this.setState({ showLoader: false, success: false, apiError: err })
        })
        }else {
        createCustomSMSTemplate(smsData).then(res => {
          if (res && res.status >= 200 && res.status <= 299) {
            this.setState({ showLoader: false, success: CREATE_SMS_TEMPLATE, apiError: false })
            this.props.history.push(`/edit_sms_template/${res.data.data._id}`)
          } else {
            this.setState({ showLoader: false, success: false, apiError: res.data.meta.message })
          }
        }).catch(err => {
            console.log(err)
            this.setState({ showLoader: false, success: false, apiError: err})
        })
      }
    }else{
      this.setState({showLoader: false, success: false, apiError: CREATE_TEMPLATE_ERROR})
    }
  }    

  cancelSMSTemplateSetting = () => {          
    this.props.history.push("/settings_sms")
  }

render(){
  let editFlag = this.props.match.path.includes('edit') ? true : false
  let footer = ''

  return(
    <div className="settingsModule">
    <div className="commonTopHeader">
        <h1><span className="settingsIcon"></span>Settings</h1>
    </div>
    <div className="dataContainer">
        <div id="settingDataScrollerWrapper">
            <div className="settingsContainer">
                <div className="settingsLeftSidePanel">
                 
                </div>
                <div className="settingsRightSidePanel">
                    <div className="commonSettingsBox emailStyleMainDiv">
                        {this.state.loaderFlag
                          ? <SmsTemplateLoader/>
                          :  <Scrollbars autoHide style={scrollbar}>
                            <div className="commonSettingsBoxTitle">
                                <div className="titleText">
                                    <span className="lightgrey">
                                        <Link to = "/settings_sms">SMS Template</Link>
                                    </span>
                                </div>
                                <img alt="" src={breadCrumbIcon} className="arrow"/>
                                <span className="pageTitle">
                                    {editFlag ? ' Edit Template ': ' Create Template '}
                                </span>
                            </div>
                            
                            <div >
                              <div className="emailContentMainDiv">
                                <div className = "emailContentInnerDiv">
                                    <div id ="subjectOptions">
                                      <div className="form-group" >
                                          <label>Template Name</label>
                                          <input type="text" value={this.state.template_name} style={this.state.templateError ? { border: '1px solid #f73e5d' } : {width: '99%'}} onChange={(event) => this.handleTemplateName(event)} />
                                          {this.state.templateError ? <span className="error-fonts" style={{ paddingLeft: '0.25rem' }} >Template name required.</span> : ''}
                                      </div>                                    
                                    </div>
                              </div>
                              <div className='form-group'>
                                <label>Message Body</label>
                                <textarea value={this.state.body} style={this.state.bodyError ? { border: '1px solid #f73e5d' } : {}}
                                  onChange={(event) => this.handleBody(event)}                                  
                                  className='message-body' id="sms_body" maxLength={612}
                                />
                                {this.state.bodyError ? <span className="error-fonts" style={{ paddingLeft: '0.25rem' }} >Body is required.</span> : ''}
                                <span className='message-footnote' > {this.state.body.replace(/\r?\n/g, "\r\n").length}/612</span>
                              </div>
                              <div className="important_note">
                                  <span style={{ fontWeight: 700 }} >Important Note:</span>
                                  <table>
                                    <tr><th>No. of SMS</th><th>Characters</th></tr>
                                    <tr><td>1</td><td>160</td></tr>
                                  </table>
                                  <li style={{ lineHeight: '20px' }}>
                                      If you send message that are 160 characters and under in length, you will be charged for 1 message.
                                  </li>
                                  <li style={{ lineHeight: '20px' }}>
                                    If you send message that are 306 characters and under in length, you will be charged for 2 message.
                                  </li>
                                  <li style={{ lineHeight: '20px' }}>
                                    If you send message that are 459 characters and under in length, you will be charged for 3 message.
                                  </li>
                                  <li style={{ lineHeight: '20px' }}>
                                    If you send message that are 612 characters and under in length, you will be charged for 4(Max) message.
                                  </li>
                                  <li style={{ lineHeight: '20px' }}>
                                    1 space is 1 character and 1 line break is 2 characters.
                                  </li>
                                  <li style={{ lineHeight: '20px' }}>
                                    If you use variable [Tracking ID], and name that is inserted into the variable is 11 characters in length it will be count as 11 characters.
                                  </li>
                              </div>
                              <br/>
                            </div>
                            <div className="email_insert_value"> 
                              <Accordion key="emailAccordion">
                                <div className="insert_value" > Insert Values </div>
                                    {smsAccordionsMergers.map((mergerObj, conditionKey) => <InputAccordion key={conditionKey} index={conditionKey} accordianTitle={Object.keys(mergerObj)[0]} buttonValues={Object.values(mergerObj)[0]} handleInsertValue={(value) => this.handleInsertValue(value)} />)}
                              </Accordion>
                                  <div className="clearFix"></div>
                              </div>
                          </div>
                            </Scrollbars>
                        }         
                       </div>
                      {this.state.showLoader ? 
                        footer = <div className="commonFooter ignore-react-onclickoutside">
                                    <footer>
                                        <div className="centerItems">
                                            <img src={infiniteLoader} alt="loader" />
                                        </div>
                                    </footer>
                                 </div> 
                      : 
                        footer =  <div className="commonFooter">
                                    <footer>
                                        <div className="centerItems">
                                            <a href onClick={() => this.saveSMSSetting()} className="bottomPrimaryButton">
                                                <span className="bottomPrimaryButton_inner">
                                                <img src={saveIcon} alt="EmailSetting" />
                                                <span style={{ paddingTop: '0.7rem' }}>Save</span>
                                                </span>
                                            </a>                     
                                            <a href onClick={() => this.cancelSMSTemplateSetting()} className="bottomPrimaryButton">
                                                <span className="bottomPrimaryButton_inner">
                                                <img src={cancelIcon} alt="EmailSetting" />
                                                <span style={{ paddingTop: '0.7rem' }}>Cancel</span>
                                                </span>
                                            </a>
                                        </div>
                                    </footer>
                                 </div>}

                      {this.state.apiError != ''
                        ? <div className="activity error">
                          <span>{(this.state.apiError)}</span>
                        </div>
                        : ''}
                      {this.state.success
                        ? <div className="activity success">
                          <span>{this.state.success}</span>
                        </div>
                        : ''}
                      </div>
                  </div>
              </div>
                {footer}
          </div>                      
      </div>
        )
    }   
}

export default withRouter(CustomSMSTemplate);