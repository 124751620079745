import * as constant from '../TrackingList/common/constants'
export function setLocalStorageData (response){
    localStorage.setItem('TIMEZONE' , response.time_zone_data.timezone)
    localStorage.setItem('date_format' , response.date_format)
    localStorage.setItem('ProfileName', response.name)
    localStorage.setItem("Reply_To", response.email)
    localStorage.setItem('Company_Name', response.name)
    localStorage.setItem('sub_domain', (response.sub_domain) ? response.sub_domain : response.name.replace(/[^a-zA-Z0-9_-]/g, "").toLowerCase())
    localStorage.setItem('custom_domain_url', (response.custom_domain_url) ? response.custom_domain_url : '')
    localStorage.setItem('sub_domain_status', (response.sub_domain) ? 1 : 0)
    localStorage.setItem('daily_digest_email_active', (response.daily_digest_email_active) ? 1 : 0)
    localStorage.setItem('user_type',response.role && response.role === 'subuser' ? response.role : 'user')
    if(response.role === "subuser"){
        const COLUMN_ARRAY = constant.COLUMN_ARRAY.filter((col) => col.key !== "assigned_to")
        localStorage.setItem('trackingColumn',JSON.stringify(COLUMN_ARRAY))
    }else{
        localStorage.setItem('trackingColumn',JSON.stringify(constant.COLUMN_ARRAY))
    }
}