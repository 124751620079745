require('dotenv').config()
export const STATUS_LIST = 'STATUS_LIST'
export const DELIVERY_TIME_LIST = 'DELIVERY_TIME_LIST'
export const COURIER_LIST='COURIER_LIST'
export const SUPPLIER_LIST = 'SUPPLIER_LIST'
export const PRICING_TIER_LIST = 'PRICING_TIER_LIST'
export const PRODUCT_CUSTOM_FIELD = 'PRODUCT_CUSTOM_FIELD'
//Condition
export const CONDITION_VALUE = 'CONDITION_VALUE'
export const CONDITION_TYPE_COLLECTION= 'CONDITION_TYPE_COLLECTION'
export const CONDITION_OPERATOR= 'CONDITION_OPERATOR'
export const CLEAR_CONDITION_BY_KEY = 'CLEAR_CONDITION_BY_KEY'
export const CLEAR_CONDITION_VALUE = 'CLEAR_CONDITION_VALUE'
export const CLEAR_AUTOMATION_LIST = 'CLEAR_AUTOMATION_LIST'
export const SET_LOGICAL_OPERATOR = 'SET_LOGICAL_OPERATOR'
//Action
export const ACTION_TYPE = 'ACTION_TYPE'
export const CLEAR_ACTION_BY_KEY = 'CLEAR_ACTION_BY_KEY'
export const WAREHOUSE_LIST = 'WAREHOUSE_LIST'
export const ACTION_REQUEST_PARAMS = 'ACTION_REQUEST_PARAMS'
export const CLEAR_ALL_ACTION = 'CLEAR_ALL_ACTION'
//Automation
export const AUTOMATION_LIST = 'AUTOMATION_LIST'
export const DELETE_AUTOMATION_LIST = 'DELETE_AUTOMATION_LIST'
export const AUTOMATION_DETAIL = 'AUTOMATION_DETAIL'
export const ADD_AUTOMATION_LIST = 'ADD_AUTOMATION_LIST'
export const COURIER_LIST_VALUE='COURIER_LIST_VALUE'
export const CUSTOM_TRIGGER_LIST = 'CUSTOM_TRIGGER_LIST'
//Loader
export const START_LOADING = 'START_LOADING'
export const END_LOADING = 'END_LOADING'
export const API_EXCEPTION = 'EXCEPTION'
//Search Automation
export const SEARCH_AUTOMATION_LIST = 'SEARCH_AUTOMATION_LIST'
// Automation Filters
export const AUTOMATION_GLOBAL_FILTERS = 'AUTOMATION_GLOBAL_FILTERS'
// set URL Values
export const SET_URL_VALUES = 'SET_URL_VALUES'
// set Automation Values
export const SET_AUTOMATION_TRIGGER_LIST = 'SET_AUTOMATION_TRIGGER_LIST'
export const SET_AUTOMATION_CONDITION_LIST = 'SET_AUTOMATION_CONDITION_LIST'
export const SET_AUTOMATION_ACTION_LIST = 'SET_AUTOMATION_ACTION_LIST'
export const SET_AUTOMATION_CUSTOM_EVENT_LIST = 'SET_AUTOMATION_CUSTOM_EVENT_LIST'
export const AUTOMATION_LIST_GLOBAL_FILTERS = 'AUTOMATION_LIST_GLOBAL_FILTERS'
export const SET_AUTOMATION_ACTION_ARRAY_VALUE_LIST = 'SET_AUTOMATION_ACTION_ARRAY_VALUE_LIST'
export const SET_AUTOMATION_TRIGGER_ARRAY_VALUE_LIST = 'SET_AUTOMATION_TRIGGER_ARRAY_VALUE_LIST'
export const SET_AUTOMATION_LOADER = 'SET_AUTOMATION_LOADER'
