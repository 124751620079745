import React from 'react'
import axios from 'axios';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import MediaContent from '../common/userMediaCard'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter, Redirect } from 'react-router-dom';
import Styles from './Styles'
import {passwordRegex} from '../static/constants'
const url = process.env.REACT_APP_API_URL
const resetPassword = '/user/reset_password'
const mapStateToProps = state => ({
    isLoggedIn: state.authenticate.isLoggedIn
})
class ResetPassword extends React.Component {
    constructor() {
        super()
        this.state = {
            newPassword: '',
            confirmPassword: '',
            newPasswordError: false,
            confirmPasswordError: false,
            alphaNumericPasswordflag: true,
            passwordLengthError: false,
            newPasswordErrorMsg: "",
            showLoader: false,
            passwordError: ''
        }
    }
    checkNewPassword = () => {
        if (this.state.newPassword === '') {
            this.setState({
                newPasswordError: true
            })
        } else {
            if (this.state.newPassword.length < 6) {
                this.setState({
                    passwordLengthError: true,
                    newPasswordErrorMsg: "Password length should be greater than 6."
                })
            } else {
                this.setState({
                    passwordLengthError: false,
                    newPasswordErrorMsg: ""
                })
            }
        }
    }
    checkConfirmPassword = () => {
        if (this.state.confirmPassword === '') {
            this.setState({
                confirmPasswordError: true
            })
        }
    }
    handleChangePassword = (value, key) => {

        if (key === 'newPassword') {
            if (value.length < 6) {
                this.setState({
                    newPasswordError: true,
                    newPasswordErrorMsg: "Password length should be greater than 6.",
                    newPassword: value
                })
            } else {
                if (passwordRegex.test(value) === false) {
                    this.setState({
                        alphaNumericPasswordflag: false,
                        newPasswordErrorMsg: "Please enter alphabets and numbers.",
                        newPassword: value,
                        newPasswordError: false,
                        passwordLengthError: false
                    })
                } else {
                    this.setState({
                        newPassword: value,
                        alphaNumericPasswordflag: true,
                        newPasswordErrorMsg: "",
                        newPasswordError: false,
                        passwordLengthError: false
                    })
                }
            }
        } else if (key === 'confirmPassword') {
            this.setState({
                confirmPassword: value,
                confirmPasswordError: false,
                passwordError: ''
            })
        }
    }

    checkValidation = () => {
        let errorCount = 0
        if (this.state.newPassword === '') {
            this.setState({
                newPasswordError: true
            })
            errorCount++
        }
        if (this.state.confirmPassword === '') {
            this.setState({
                confirmPasswordError: true
            })
            errorCount++
        }
        if (errorCount > 0) {
            return false
        }
        return true
    }

    handleSubmit = values => {
        let isValid = this.checkValidation()
        if (isValid) {
            if (this.state.newPassword === this.state.confirmPassword) {
                this.setState({
                    showLoader: true
                })
                this.setState({
                    passwordError: ''
                })
            } else {
                this.setState({
                    passwordError: "Password and Confirm Password does not match."
                })
            }
            let postObject = {
                token: this.getRedirectURLParameterByName('token'),
                new_password: this.state.newPassword,
                confirm_password: this.state.confirmPassword
            }

            axios.post(url + resetPassword, postObject).then(response => {
                if (JSON.parse(localStorage.getItem('Username'))) {
                    this.props.history.push('/users')
                } else {
                    this.props.history.push('/login')
                }

            }).catch(error => {
                this.setState({
                    showLoader: false
                })

            })
        }
    }

    getRedirectURLParameterByName(name, locationUrl) {
        if (!locationUrl) locationUrl = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(locationUrl);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    render() {
        const { classes } = this.props;
        const { from } = this.props.location.state || {
            from: {
                pathname: '/'
            }
        }

        const { onboarding } = this.props.location.state || {
            onboarding: {
                pathname: '/'
            }
        }

        if (this.props.isLoggedIn) {
            var onboarding_val = JSON
                .parse(localStorage.getItem('trackhiveToken'))
                .onboarding
                ? JSON
                    .parse(localStorage.getItem('trackhiveToken'))
                    .onboarding
                    .status
                : 0;
            if (onboarding_val)
                return <Redirect to={onboarding} />
            else
                return <Redirect to={from} />
        }
        return (
            <div className="loginAndRegistrationCommonDiv">
                <div id="loginRegWrapper">
                    <Card className={classes.root} variant="outlined">
                        <MediaContent content="Reset Password" />
                        <TextField
                            error={this.state.newPasswordError || this.state.alphaNumericPasswordflag === false ? true : false}
                            id="new_password"
                            label="New Password"
                            variant="outlined"
                            type="password"
                            className={`${classes.forgotPasswordEmailtextField} ${classes.onFocus}`}
                            value={this.state.newPassWord}
                            onChange={(e,action) => this.handleChangePassword(e.target.value,"newPassword")}
                            helperText={this.state.newPasswordErrorMsg}
                        />
                        <TextField
                            error={this.state.confirmPasswordError ? true : false}
                            id="confrim_password"
                            label="Confirm Password"
                            variant="outlined"
                            type="password"
                            className={`${classes.forgotPasswordEmailtextField} ${classes.onFocus}`}
                            value={this.state.confirmPassword}
                            onChange={(e,action) => this.handleChangePassword(e.target.value,"confirmPassword")}
                            helperText={this.state.confirmPasswordError ? "Confirm password is required." : this.state.passwordError ? <div style={{color: 'red'}}>{this.state.passwordError}</div>  : ""}
                        />
                       <CardActions className={classes.nextButtonRoot}>
                        <div className="rightBtnDiv" style={{width: '370px'}}>
                            <Button
                                variant="contained"
                                className={classes.saveBtn}
                                disabled={this.state.showLoader}
                                onClick={this.handleSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                        {this.state.showLoader && <CircularProgress size={22} className={classes.buttonProgress} />}
                    </CardActions>
                    </Card>
                </div>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, {})(withStyles(Styles)(ResetPassword)))

