import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { startPaginationLoader,clearTrackingList, getTrackingList,clearAllShipmentStatusFilters,setCourierList, setTrackingFilters,setCourierArrayValueList, setOriginCountryList, setDestinationCountryList,setSelectedShipmentStatus,clearShipmentStatusFilters } from './actions/actionMethods'
import {DEFAULT_TRACKING_LIMIT} from './common/constants'


const trackingStatusValueList = [{
        label: 'All',
        value: 'All',
    },
    {
        label: 'PENDING',
        value: 'Pending',
    },
    {
        label: 'INFO RECEIVED',
        value: 'InfoReceived',
    },
    {
        label: 'IN TRANSIT',
        value: 'InTransit',
    },
    {
        label: 'OUT FOR DELIVERY',
        value: 'OutForDelivery',
    },
    {
        label: 'DELIVERED',
        value: 'Delivered',
    },
    {
        label: 'EXCEPTION',
        value: 'Exception',
    },
    {
        label: 'FAILED ATTEMPT',
        value: 'FailedAttempt',
    },
    {
        label: 'EXPIRED',
        value: 'Expired',
    }
]

class TrackingStatusTabs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

            tabFilterText: '',
            activeFirstTab: false
        }
    }
    UNSAFE_componentWillMount() {
        this.getSelectedTab()
    }

    getSelectedTab = () => {
        if (this.props.location.search !== '') {
            let urlFilters = queryString.parse(this.props.location.search)
            if (urlFilters.allTrackingStatusArr) {
                this.setState({
                    tabFilterText: 'All',
                })
            } else {
                let trackingStatusArr = urlFilters.trackingStatusArr.split(',')
                this.setState({
                    tabFilterText: trackingStatusArr[0],
                })
                this.props.setSelectedShipmentStatus(trackingStatusArr[0])
            }
        } else {
            this.callInitialTabFilter('All')

            this.setState({
                activeFirstTab: true
            })

        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search === '' && this.props.location.search !== '' && this.props.isAddedFlag && this.props.isShipmentAdded) {
            let val = queryString.parse(this.props.location.search)
            this.setActiveTab(val.trackingStatusArr)

        }
        if (this.props.location.search !== '' && prevProps.location.search !== '' && prevProps.location.search !== this.props.location.search) {
            this.getSelectedTab()
        }
    }

    setActiveTab = (value) => {

        let index = trackingStatusValueList.findIndex(data => data.value === value)
        let tablinks = document.getElementsByClassName("tablinks");

        for (let i = 0, len = tablinks.length; i < len; i++) {
            if (i === index) {
                tablinks[i].className += ' ButtonActive'

            } else {
                tablinks[i].className = tablinks[i].className.replace(" ButtonActive", "");

            }
        }

        let requestParams = {}
        requestParams.pageId = 1
        requestParams.limit = localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : DEFAULT_TRACKING_LIMIT
        requestParams.status = [value]

        this.props.getTrackingList(requestParams)

    }

    changeTab = (evt, element) => {
        this.props.clearShipmentStatusFilters()
        this.props.history.push('/tracking')
        this.setState({
            activeFirstTab: false
        })
        this.props.setPageId()
        this.props.clearTrackingList()
        this.props.startPaginationLoader()
        this.props.drawerClose()

        this.props.setCourierArrayValueList(this.props.trackingCourierArrayList)
        this.props.setOriginCountryList(this.props.originCountryArrayList)
        this.props.setDestinationCountryList(this.props.destinationCountryArrayList)

        let tablinks = document.getElementsByClassName("tablinks");
        for (let i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" ButtonActive", "");

        }
        evt.currentTarget.className += " ButtonActive";

        let requestParams = {}
        requestParams.pageId = 1
        requestParams.limit = localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : DEFAULT_TRACKING_LIMIT
        if (element.value !== 'All') {
            requestParams.status = [element.value]
            this.props.clearAllShipmentStatusFilters()
        }
        let storeObj = {}
        if (element.value === 'All') {
            storeObj.trackingStatusArr = [{
                value: 'All',
                label: 'ALL SHIPMENTS'
            }]
        } else {
            storeObj.trackingStatusArr = [{
                value: element.value,
                label: element.label
            }]
        }
        if (this.props.trackingGlobalFilters !== undefined && Object.keys(this.props.trackingGlobalFilters).length > 0) {
            let trackingFiltersValues = this.props.trackingGlobalFilters
            if (trackingFiltersValues.courierListArr && trackingFiltersValues.courierListArr.length > 0) {
                storeObj.courierListArr = trackingFiltersValues.courierListArr
                let courierListArrVal = []
                for (let index = 0; index < trackingFiltersValues.courierListArr.length; index++) {
                    const courier = trackingFiltersValues.courierListArr[index];
                    courierListArrVal.push(courier.value)
                }
                requestParams.courier = courierListArrVal
            }
            if (trackingFiltersValues.originListArr && trackingFiltersValues.originListArr.length > 0) {
                storeObj.originListArr = trackingFiltersValues.originListArr
                requestParams.origin = trackingFiltersValues.originListArr
            }
            if (trackingFiltersValues.destinationListArr && trackingFiltersValues.destinationListArr.length > 0) {
                storeObj.destinationListArr = trackingFiltersValues.destinationListArr
                requestParams.destination = trackingFiltersValues.destinationListArr
            }
            if (trackingFiltersValues.subUserListArr && trackingFiltersValues.subUserListArr.length > 0) {
                storeObj.subUserListArr = trackingFiltersValues.subUserListArr
                let subUserList = []
                for (let index = 0; index < trackingFiltersValues.subUserListArr.length; index++) {
                    const subUser = trackingFiltersValues.subUserListArr[index];
                    subUserList.push(subUser)
                }
                requestParams.assigned_id = subUserList
            }
            if (trackingFiltersValues.tagListArr && trackingFiltersValues.tagListArr.length > 0) {
                storeObj.tagListArr = trackingFiltersValues.tagListArr
                let tagList = []
                for (let index = 0; index < trackingFiltersValues.tagListArr.length; index++) {
                    const tag = trackingFiltersValues.tagListArr[index];
                    tagList.push(tag)
                }
                requestParams.tags = tagList
            }
            if (trackingFiltersValues.transitDays && trackingFiltersValues.transitDays !== '') {
                storeObj.transitDays = trackingFiltersValues.transitDays
                requestParams.transitDays = trackingFiltersValues.transitDays
            }
            if (trackingFiltersValues.date && trackingFiltersValues.date !== '') {
                storeObj.date = trackingFiltersValues.date
            }
            if (trackingFiltersValues.dateType && trackingFiltersValues.dateType !== '') {
                storeObj.dateType = trackingFiltersValues.dateType
            }
            if (trackingFiltersValues.fromDate && trackingFiltersValues.fromDate !== '' && trackingFiltersValues.toDate && trackingFiltersValues.toDate !== '') {
                storeObj.fromDate = trackingFiltersValues.fromDate
                requestParams.from_date = trackingFiltersValues.fromDate
                storeObj.toDate = trackingFiltersValues.toDate
                requestParams.to_date = trackingFiltersValues.toDate
            }
            if (trackingFiltersValues.query && trackingFiltersValues.query !== '') {
                storeObj.query = trackingFiltersValues.query
                requestParams.query = trackingFiltersValues.query
            }


        }

        this.props.setSelectedShipmentStatus(element.value)

        this.props.setTrackingFilters(storeObj)
        let urlParams = {}

        if (element.value === 'All') {
            urlParams['allTrackingStatusArr'] = 'All'
        } else {
            urlParams['trackingStatusArr'] = element.value
        }

        this.props.history.replace(`?${queryString.stringify(urlParams)}`)
        this.props.getTrackingList(requestParams)
    }
    showTrackingStatusTab = (trackingStatusArrayList) => {
        let trackingStatusArr = []
        let index = 0
        if (trackingStatusArrayList && trackingStatusArrayList.length > 0) {
                trackingStatusArrayList.map((element) => {
                trackingStatusArr.push(
                    <React.Fragment key={index}>
                        <button id="summaryBtn" className={"tablinks " + (this.state.tabFilterText !== '' && this.state.tabFilterText === element.value ? "ButtonActive" : (this.state.activeFirstTab && index === 0) ? "ButtonActive" : '')} onClick={(event, type) => this.changeTab(event, element)}>
                            {(element.label === 'All' ? "ALL SHIPMENTS" : element.label)}
                        </button>
                    </React.Fragment>
                )
                index++
            })
        }
        return trackingStatusArr
    }
    callInitialTabFilter = (element) => {
        let storeObj = {}
        storeObj.trackingStatusArr = [{
            label: 'All',
            value: 'All'
        }]
        this.props.setSelectedShipmentStatus('All')
        this.props.setTrackingFilters(storeObj)
    }
    render() {
        return (
            <div className="trackingTabViewMainDiv">
                <div className="tab">
                    {this.showTrackingStatusTab(trackingStatusValueList)}
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    trackingGlobalFilters: state.tracking.list.trackingFilters,
    trackingCourierArrayList: state.tracking.list.trackingCourierArrayList,
    originCountryArrayList: state.tracking.list.originCountryArrayList,
    destinationCountryArrayList: state.tracking.list.destinationCountryArrayList,
    isShipmentAdded:state.tracking.list.isShipmentAdded

})
export default withRouter(connect(mapStateToProps, { startPaginationLoader, clearTrackingList, getTrackingList, setTrackingFilters,setCourierArrayValueList, setOriginCountryList, setDestinationCountryList,setCourierList,setSelectedShipmentStatus,clearShipmentStatusFilters, clearAllShipmentStatusFilters})(TrackingStatusTabs));