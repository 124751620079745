import React from 'react'
import Popover from '@material-ui/core/Popover'
import { withStyles } from '@material-ui/core'
import { changeDateFunction } from '../common/Functions'
import moment from 'moment-timezone';
import Styles from '../static/Styles'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateUtils from "@date-io/moment"
import DateRangePicker from "../../common/DateRangePicker";
import MenuItem from '@material-ui/core/MenuItem'
import { reactLocalStorage } from 'reactjs-localstorage'

class DashboardPopover extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null,
            visibleSummary: false,
            dateType: 'Last 30 Days',
            showCustom: false,
        }
    }

    changeDate = (key, type) => {

        let dateObj = changeDateFunction(key, false)
        if (this.props.setDateType !== undefined) {
            this.props.setDateType(dateObj.dateType, dateObj.fromDate, dateObj.toDate)
        }
        this.props.getData(dateObj.fromDate, dateObj.toDate)

        this.setState({ [type]: !this.state[type], dateType: dateObj.dateType, anchorEl: null })
    }


    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            showCustom: false
        })
    }

    handleCustom = () => {
        this.setState({ showCustom: true })
    }

    setCustomDateRange = (start, end) => {
        let startDate = moment(start).format('YYYY-MM-DD 00:00:00')
        let fromUTCDate = moment(startDate).subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:00')
        let endDate = moment(end).format('YYYY-MM-DD 23:59:59')
        let toUTCDate = moment(endDate).subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:59')
        this.props.setCustomDateRange(fromUTCDate, toUTCDate)
        this.setState({
            showCustom: false,
            visibleSummary: !this.state.visibleSummary,
            dateType: moment(start).format('DD MMM, YYYY') + ' to ' + moment(end).format('DD MMM, YYYY'),
            anchorEl: null
        }, () => {
                if (this.props.setDateType !== undefined) {
                    this.props.setDateType(this.state.dateType, fromUTCDate, toUTCDate)
                }
            }
        )
    }

    toggleDatePicker = (toggleValue = false) => {
        this.setState({ showCustom: toggleValue })
    }

    onCancelClick = () => {
        this.toggleDatePicker(false)
    }

    render() {
        const { classes } = this.props
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        let classname = (this.props.type === 'TopCountries' || this.props.type === 'TopCouriers') ? classes.leftPopOverStyle : classes.rightPopOverStyle
        const customDateRange = (
            <MuiPickersUtilsProvider utils={DateUtils}>
                <div>
                    <DateRangePicker
                        open={this.state.showCustom}
                        onOpen={() => { }}
                        onDismiss={this.onCancelClick}
                        onClose={() => {
                            this.toggleDatePicker(false);
                        }}
                        value={[]}
                        onChange={this.setCustomDateRange}
                        TextFieldComponent={() => null}
                    />
                </div>
            </MuiPickersUtilsProvider>
        )
        const dateRangeList = (
            <div style={{ width: '8rem' }} >
                <MenuItem className={`${classes.dateText} `} onClick={(key, type) => this.changeDate('7Days', 'visibleSummary')} >Last 7 Days</MenuItem>
                <MenuItem className={`${classes.dateText} `} onClick={(key, type) => this.changeDate('14Days', 'visibleSummary')} >Last 14 Days</MenuItem>
                <MenuItem className={`${classes.dateText} `} onClick={(key, type) => this.changeDate('30Days', 'visibleSummary')} >Last 30 Days</MenuItem>
                <MenuItem className={`${classes.dateText} `} onClick={(key, type) => this.changeDate('ThisMonth', 'visibleSummary')} >This Month</MenuItem>
                <MenuItem className={`${classes.dateText} `} onClick={(key, type) => this.changeDate('LastMonth', 'visibleSummary')} >Last Month</MenuItem>
                <MenuItem className={`${classes.dateText} `} onClick={() => this.handleCustom()} >Custom</MenuItem>
            </div>
        );

        return (
            <React.Fragment>
                <div className="dateRange" onClick={this.handleClick}>
                    <span className="Text">{this.state.dateType}</span>
                    <span className="ArrowDD" />
                </div>
                <Popover
                    id={id}
                    open={open}
                    style={{ display: (this.state.showCustom ? 'none' : 'unset') }}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    onClose={this.handleClose}
                    className={classname}
                >
                    {this.state.showCustom ? customDateRange : dateRangeList}
                </Popover>
            </React.Fragment>
        )
    }
}

export default (withStyles(Styles, { withTheme: true })(DashboardPopover))

