import React from 'react';
import Drawer from './domainDrawer';
import { connect } from 'react-redux';
import Table from "@material-ui/core/Table";
import Button from '@material-ui/core/Button';
import '../../assets/css/emailSettings.scss';
import Dialog from '@material-ui/core/Dialog';
import TableRow from "@material-ui/core/TableRow";
import Styles from '../../assets/css/Styles';
import { withRouter, Link } from 'react-router-dom';
import {emailRegex} from '../../../static/constants';
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import FlashMessage from '../../common/FlashMessage';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TableContainer from "@material-ui/core/TableContainer";
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import {LinearProgressBar,StyledCard, StyledTypography, BreadCrumbs, Image} from '../../common/materialComponents';
import {EmailDomains, addIcon, verifyIcon, notVerifiedIcon, RemoveIcon } from '../../../../src/common/imageList';
import { addDomain, getDomainList, deleteEmailId, verifyDomain, toggleSettingsFlashMessage, setSettingsFlashMessage} from '../../actions/actionMethods';
import {EMAILID_DELETED_SUCCESSFULLY, EMAIL_ADDED_SUCCESSFULLY, DOMAIN_VERIFIED_SUCCESSFULLY, DOMAIN_VERIFICATION_ERROR, ADD_CNAME_TITLE} from '../../common/constants';

class EmailDomain extends React.Component{
  constructor(props){
    super(props)
    this.isSuccess = true
    this.state = {
        templateList: [],
        showLoader: false,
        loading: false,
        loaderFlag: true,
        showEmailDomainModal: false,
        emailIdValue: '',
        newEmailId: '',
        errorBorderEmail: false,
        EmailError: '',
        domainList: [],
        isVerifyFlag: false,
        isDeleteFlag: false,
        deleteEmailId: '',
        verifyDomainId: '',
        cname : {},
        selectedFilterOptions: null,
        emailFieldError: false,
        isValidEmail: true
    }
  }   
        
  componentDidMount(){
      this.getDomainList()
  }

  getDomainList = () => {
        this.setState({ domainList: []})
        let filterData = ''
        if(this.state.selectedFilterOptions !== null){
            filterData = `?is_verified=${this.state.selectedFilterOptions.value}`
        }
        getDomainList(filterData).then(res => {
            let responseData = []
            responseData = res.data
            let domainList = []
            if(responseData !== undefined){
                for(let i=0 ; i<responseData.length ; i++){
                    domainList.push(responseData[i])
                }
                this.setState({
                    domainList: domainList,
                    loaderFlag: false
                })
            }
            }).catch(error => {
                console.log(error)
         })
  }
  
  toggleFlashMessage = (data) => {
    this.props.toggleSettingsFlashMessage(data)
    this.props.setSettingsFlashMessage('')
  }

    domainListing = (domainList) => {
        const {classes} = this.props
        if(domainList !== undefined){
            return(
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                 <TableHead>
                    <TableRow>
                       <TableCell align="left" className={`${classes.headCell} ${classes.firstHeadAndBodyCell}`}>Email</TableCell>
                       <TableCell align="left" className={classes.headCell}>Domain</TableCell>
                       <TableCell align="left" className={classes.headCell}>Status</TableCell>
                       <TableCell align="left" className={classes.headCell}>Verify Domain</TableCell>
                       <TableCell align="left" className={`${classes.headCell} ${classes.lastHeadAndBodyCell}`}>Delete Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                  {domainList && domainList.length > 0
                    ? domainList.map((list,index) => (
                        list.email.map((emailId) => {
                            return(
                             
                                <TableRow key={index}>
                                    <TableCell align="left" className={`${classes.bodyCell} ${classes.firstHeadAndBodyCell}`}>
                                        {emailId}
                                    </TableCell>
                                    <TableCell align="left" className={classes.bodyCell}>
                                        {list.domain}
                                    </TableCell>
                                    <TableCell align="left" className={classes.bodyCell}>
                                         <img src={list.is_verified === true ? verifyIcon : notVerifiedIcon} alt={list.is_verified === true ? 'Verified' : 'UnVerified'} className="emailImage" />
                                         <span style={{ margin: '5px 0px 0px 5px', verticalAlign: 'top', lineHeight: '28px'}} >{list.is_verified === true ? 'Verified' : 'Unverified'}</span> 
                                     </TableCell>
                                    <TableCell align="left" className={classes.bodyCell}>
                                        {
                                            list.is_verified === false ?
                                            <div style={{color: "#2f80ed", fontSize: "14px", textDecoration:'none', cursor: 'pointer'}} 
                                                 onClick = {(id) => this.setState({isVerifyFlag: true,loading: false ,verifyDomainId: list.domain_id, cname: list.cname})}>
                                                     Verify
                                            </div>
                                            : ""
                                        }
                                    </TableCell>
                                    <TableCell className={`${classes.bodyCell} ${classes.lastHeadAndBodyCell}`} style={{paddingLeft:'30px'}}>
                                        <div style={{ width: "26%", cursor: 'pointer' }} onClick = {(id) => this.setState({loading: false,isDeleteFlag:true,deleteEmailId: emailId})}>
                                           <RemoveIcon/>
                                        </div>
                                  </TableCell>
                                </TableRow>
                            )
                        })
                    )) : <div className="noData" style={{ position: "absolute", padding: "2rem", left: '45%' }}>No Domains </div>
                  }
              </TableBody>
            </Table>
          </TableContainer>  
          )
        }
    }

  checkValidation = () => {
    let errorCount = 0
    if(this.state.newEmailId === '' || this.state.newEmailId.trim().length === 0) {
      this.setState({emailFieldError: true})
      errorCount++
    } else {
      this.setState({emailFieldError: false})
    }
    if(this.state.emailError === true){
        errorCount++
    }else{
        this.setState({emailError: false})
    }
    if(!this.state.isValidEmail){
        errorCount++
    }else{
        this.setState({isValidEmail: true})
    }
    if(errorCount > 0) {
      return false
    }
    return true
  }

   addDomain = () => {
       let validation = this.checkValidation()
        if(validation){
          this.setState({loading: true})
          let postObject = {
             email: this.state.newEmailId
          }
          addDomain(postObject).then(res => {
              if(res.status >= 200 && res.status <= 299){
                    this.getDomainList();
                    this.isSuccess = true
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(EMAIL_ADDED_SUCCESSFULLY)
                    this.setState({loading: false,showLoader: false, showEmailDomainModal: false})
               }else{
                    this.isSuccess = false
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(res.data.meta.message)
                    this.setState({loading: false,showLoader: false, showEmailDomainModal: false})
               }
          }).catch(error => {
                console.log(error)
                this.setState({loading : false})
          })
          }else{
                this.setState({loaderFlag: false,loading: false})
          }
  }
 
   verifyDomain = () => {
     this.setState({loading: true})
     verifyDomain(this.state.verifyDomainId).then(res => {
        if(res && res.status >= 200 && res.status <=299){
            this.getDomainList()
            this.isSuccess = true
            this.props.toggleSettingsFlashMessage(1)
            this.props.setSettingsFlashMessage(DOMAIN_VERIFIED_SUCCESSFULLY)
            this.setState({loading: false, verifyDomainId: '', isVerifyFlag: false})
        }else{
            this.isSuccess = false
            this.props.toggleSettingsFlashMessage(1)
            this.props.setSettingsFlashMessage(DOMAIN_VERIFICATION_ERROR)
            this.setState({loading: false, verifyDomainId: '', isVerifyFlag: false})
        }
     }).catch(error => {
         console.log(error)
         this.setState({loading: false})
     })
  }

   removeEmailId = () => {
     this.setState({loading: true})
     let putObject = {
         email: this.state.deleteEmailId 
     }
     deleteEmailId(putObject).then(res => {
        if(res && res.status >= 200 && res.status <=299){
            this.getDomainList()
            let message = res.data.message ? res.data.message : EMAILID_DELETED_SUCCESSFULLY
            this.isSuccess = true
            this.props.toggleSettingsFlashMessage(1)
            this.props.setSettingsFlashMessage(message)
            this.setState({loading: false, deleteEmailId: '', isDeleteFlag: false})
        }else{
            this.isSuccess = false
            this.props.toggleSettingsFlashMessage(1)
            this.props.setSettingsFlashMessage(res.data.meta.message)
            this.setState({loading: false, deleteEmailId: '', isDeleteFlag: false})
        }
     }).catch(error => {
        console.log(error)
        this.setState({loading: false})
     })
 }

  cancelDomainPopup = () => {
    this.setState({
        isDeleteFlag: false,
        isVerifyFlag: false,
        verifyDomainId: '',
        deleteEmailId: ''
    })
  }

  handleEmailId = (value) => {
    let val = value.trim()
    if (val !== '') {
        if (!emailRegex.test(val)) {
            this.setState({ newEmailId: val, isValidEmail: false })
        } else {
            this.setState({ newEmailId: val, isValidEmail: true })
        }
    }else {
        this.setState({newEmailId: val, emailError: false})
    }
  }

  openEmailDomainModal = () => {
    this.setState({
        showEmailDomainModal: true,
        newEmailId: '',
        loading: false
    })
  }
 
   closeEmailDomainModal = () => {
     this.setState({
        showEmailDomainModal: false,
        emailError: false,
        emailFieldError: false,
        isValidEmail: true
     })
   }
  
render(){
    if(this.state.loaderFlag){
        return(
        <div>
            <LinearProgressBar/>
        </div>
        )
    }else{
        const {classes} = this.props
        let mailedBy = this.state.isVerifyFlag ? this.state.cname.domain.host.split('.',1) : ''
        mailedBy = String(mailedBy)
        let dkim1SubstringArray =this.state.isVerifyFlag ?  this.state.cname.dkim1.host.split('.',2) : ''
        let dkim1HostName = dkim1SubstringArray[0] + "." + dkim1SubstringArray[1]
        let dkim2SubstringArray =this.state.isVerifyFlag ?  this.state.cname.dkim2.host.split('.',2) : ''
        let dkim2HostName = dkim2SubstringArray[0] + "." + dkim2SubstringArray[1]
    
        return(
            <React.Fragment>
            <CssBaseline/>
            <div className="settingsModule">
                    <StyledCard>
                        <BreadCrumbs aria-label="breadcrumb">
                            <Link className={classes.link} to="/settings">
                                Settings
                            </Link>
                           <StyledTypography className={classes.text}>Email Domains</StyledTypography>     
                        </BreadCrumbs>
                    </StyledCard>
            <div className="dataContainer">
                <div className="containerHeader">
                     <Image alt="emailDomain_IC" className={classes.avatar} src={EmailDomains} />
                        <div className="containerTitle" style={{width: '137px'}}>Email Domains</div>
                        <div style={{float: "right", textAlign:"right", paddingRight: '26px', cursor: 'pointer'}}>
                              <img src={addIcon} alt="Add Icon" onClick={this.openEmailDomainModal} />
                        </div>
                 </div>
                <div id="settingDataScrollerWrapper">
                    <div className="settingsContainer">
                        <div className="settingsRightSidePanel">
                            <div className="commonSettingsBox emailStyleMainDiv">
                                        <div>
                                            <div className="form-group">
                                                </div>
                                                <div className ="noPadding" style={{marginBottom:'1rem'}}>
                                                  {this.domainListing(this.state.domainList)}
                                                </div>
                                        </div>
                            </div>
                            {this.props.showSettingFlashMessageFlag === 1 ?
                                         <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                            : ''}
                        </div>
                    </div>
                </div>
            </div>
        {/* data container close  */}
            
        <Drawer 
            classes = {classes}
            openDrawer = {this.state.showEmailDomainModal}
            closeDrawer = {this.closeEmailDomainModal}
            addDomain = {this.addDomain}
            loading = {this.state.loading}
            newEmail = {this.state.newEmailId}
            emailError = {this.state.emailError}
            emailFieldError = {this.state.emailFieldError}
            handleEmailId = {this.handleEmailId}
            isValidEmail = {this.state.isValidEmail}
        />
        
        <Dialog
            open={this.state.isVerifyFlag}
            onClose={this.cancelDomainPopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Verify Email Domain"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {ADD_CNAME_TITLE}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                <TableContainer>
                    <Table className={classes.verifyDialogTable}>
                    <TableHead>
                        <TableRow>
                        <TableCell style={{paddingLeft: '32px'}}>CNAME</TableCell>
                        <TableCell align="left">VALUE</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        <TableRow>
                        <TableCell style={{paddingLeft: '32px'}}>{this.state.isVerifyFlag ? mailedBy : " "}</TableCell>
                        <TableCell align="left">{this.state.isVerifyFlag ? this.state.cname.domain.data  : " "}</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell style={{paddingLeft: '32px'}}>{this.state.isVerifyFlag ? dkim1HostName: " "}</TableCell>
                        <TableCell align="left">{this.state.isVerifyFlag ? this.state.cname.dkim1.data: " "}</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell style={{paddingLeft: '32px'}}>{this.state.isVerifyFlag ? dkim2HostName: " "}</TableCell>
                        <TableCell align="left">{this.state.isVerifyFlag ? this.state.cname.dkim2.data: " "}</TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                </TableContainer>  
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.dialogVerifyCancel}  onClick={this.cancelDomainPopup}>
                    Cancel
                </Button>
                <Button  className={classes.dialogVerifyAction}  onClick={this.verifyDomain} autoFocus>
                    Yes, Verify
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog
            open={this.state.isDeleteFlag}
            onClose={this.cancelDomainPopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Delete Email Id"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure that you want to delete this Email Id?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.dialogCancelBtn}  onClick={this.cancelDomainPopup}>
                    Cancel
                </Button>
                <Button  className={classes.dialogActionBtn}  onClick={this.removeEmailId} autoFocus>
                    Yes, Delete
                </Button>
                {(this.state.loading) && <CircularProgress size={22} className={classes.deleteDialogProgress} />}
            </DialogActions>
        </Dialog>
    </div>
    </React.Fragment>
    )
    }
  }   
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, { toggleSettingsFlashMessage, setSettingsFlashMessage })(withStyles(Styles)(EmailDomain)));