import React from 'react';
import { withRouter } from 'react-router-dom';
import IntegrateStore from '../IntegrateStore';
import Styles from '../../../../assets/css/Styles'
import BreadCrumbsCard from '../../BreadCrumbsCard';
import IntegrationLogo from '../../IntegrationLogo';
import { withStyles } from '@material-ui/core/styles';
import MangentoInstuctions from './MagentoInstructions';
import FlashMessage from '../../../../common/FlashMessage';
import { LinearProgressBar} from '../../../../common/materialComponents';
const MAGENTO_LOGO = process.env.REACT_APP_S3_URL + "courierImages/MAGENTO.svg"
class Magento extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess = false
        this.state = {
            error: false,
            updateToggleMessage: false,
            success: false,
            magentoStoreActive: false,
            showSaveLoader: false,
            magentoStoreLoader: this.props.match.params.id ? true : false,
            successErrMsg: '',
            magentoForm: this.props.match.params.id === undefined ? false : true
        }
    }

    magentoStoreLoader = (isLoading) => {
        this.setState({magentoStoreLoader: isLoading})
    }
    
    toggleFlashMessage = (data) => {
        this.setState({error: false, success: false, successErrMsg: '', showSaveLoader: false})
    }

    updateToggleMessage = (data) => {
        this.isSuccess = data.isSuccess 
        this.setState({error: data.error, success: data.success, successErrMsg: data.successErrMsg, showSaveLoader: data.showSaveLoader ? data.showSaveLoader : false})
    }
    
    isMagentoActive = (isActive) => {
        this.setState({magentoStoreActive: isActive})
    }

    moveToMagentoForm = (data) => {
        this.setState({
            magentoForm: data
        })
    }

    backToIntegrationList = () => {
        this.props.history.push('/integrated_list')
    }

    backToIntegration = () => {
        this.props.history.push('/integrations')
    }

    render() {
        const { classes } = this.props
        return(
            <React.Fragment>
                <div style = {{display:this.state.magentoStoreLoader ? 'block' : 'none'}}>
                    <LinearProgressBar/>
                </div>
                <div style = {{display:!this.state.magentoStoreLoader ? 'block' : 'none'}}>
                <div className="settingsModule">
                         <BreadCrumbsCard classes={classes} storeName="Magento 2.X" 
                                storeId = {this.props.match.params.id} activeStore={this.isMagentoActive} 
                                active={this.state.magentoStoreActive} updateToggleMessage={this.updateToggleMessage} />
                        <div className="dataContainer">
                            <IntegrationLogo 
                                classes={classes}
                                title = "Magento 2.x"
                                icon = {MAGENTO_LOGO}
                            />
                            <div id="settingDataScrollerWrapper">
                                <div className="settingsContainer">
                                    <div style={{width: '100%'}}>
                                        {
                                             this.state.magentoForm ?
                                             <IntegrateStore 
                                                 isLoading={this.magentoStoreLoader} updateSuccessErrMsg={this.updateToggleMessage}
                                                 activeStore={this.isMagentoActive} classes={classes} type = "Magento" 
                                                 storeId = {this.props.match.params.id} channelId= "5" backToIntegrationList={this.backToIntegrationList}/> 
                                             : <MangentoInstuctions classes={classes} moveToMagentoForm={this.moveToMagentoForm} backToIntegration={this.backToIntegration}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.error || this.state.success) && !this.state.showSaveLoader ?
                            <FlashMessage isSuccess={this.isSuccess} message={this.state.successErrMsg} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                        : ''}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(Styles)(Magento));
