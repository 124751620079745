import React from 'react';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { scrollbar } from '../../../static/constants';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgressBar } from '../../common/materialComponents'
import Card from '@material-ui/core/Card'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress';
import TrackingPageList from './trackingPageList'
import CreateCustomTrackingPageView from './createCustomTracking'
import PreviewPage from './trackingPagePreview'
import TrackingPageIDPreview from './trackingPageIDPreview'
import EditTrackingPage from './EditTrackingPage'
import CopyTrackingPage from './CopyTrackingPage'
import {addIcon,CustomDomain,SaveIcon,CloseIcon,PreviewOutlineIcon } from '../../../common/imageList';
import Styles from '../../assets/css/Styles';


class CustomTrackingPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            viewType: 'default',
            htmlValue: '',
            previewFlag: false,
            showFooterLoaderFlag: false,
            editID: '',
            loading: true
        }
    }
    createCustomTrackingPage = () => {
        this.setState({
            viewType: 'createTrackingPage'
        })
        this.props.history.push('/custom_tracking_page/create')
    }

    handleCancel = () => {
        this.props.history.push('/custom_tracking_page')
        this.setState({
            viewType: 'default'
        })
    }

    UNSAFE_componentWillMount() {
        this.setState({
            viewType: 'default',
            loading: true
        })
    }

    componentDidMount() {
        let nextProps = this.props

        if (nextProps.match.path !== undefined && nextProps.match.path === '/custom_tracking_page/create') {
            this.setState({
                viewType: 'createTrackingPage',
            })
        }

        if (nextProps.match.path !== undefined && nextProps.match.path === '/custom_tracking_page') {
            this.setState({
                viewType: 'default'
            })
        }

        if (nextProps.match.path !== undefined && nextProps.match.path === '/custom_tracking_page/edit/:id') {
            this.setState({
                viewType: 'edit'
            })
        }

        if (nextProps.match.path !== undefined && nextProps.match.path === '/custom_tracking_page/copy/:id') {
            this.setState({
                viewType: 'copy'
            })
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.match.path !== undefined && nextProps.match.path === '/custom_tracking_page/create') {
            this.setState({
                viewType: 'createTrackingPage',
            })
        }

        if (nextProps.match.path !== undefined && nextProps.match.path === '/custom_tracking_page') {
            this.setState({
                viewType: 'default',
                loading: true
            })
        }

        if (nextProps.match.path !== undefined && nextProps.match.path === '/custom_tracking_page/edit/:id') {
            this.setState({
                viewType: 'edit'
            })
        }

        if (nextProps.match.path !== undefined && nextProps.match.path === '/custom_tracking_page/copy/:id') {
            this.setState({
                viewType: 'copy'
            })
        }
    }

    handleShowPreview = () => {
        var win = window.open('/custom_tracking_page/preview', '_blank');
        setTimeout(() => {
            win.postMessage(this.state.htmlValue, "*")
        }, 1200);
    }

    setHTMLValue = (data) => {
        this.setState({
            htmlValue: data
        })
    }

    showFooterLoader = (flag) => {
        this.setState({
            showFooterLoaderFlag: flag
        })
    }

    handleEdit = (templateData) => {
        this.props.history.push(`/custom_tracking_page/edit/${templateData._id}`)
        this.setState({
            viewType: 'edit',
            editID: templateData._id
        })
    }

    handleCopy = (templateData) => {
        this.props.history.push(`/custom_tracking_page/copy/${templateData._id}`)
        this.setState({
            viewType: 'edit',
            editID: templateData._id
        })
    }

    updateLoader = (data) => {
        this.setState({loading: data})
    }

    render() {
        let showView = []
        let isEditOrCreate =(this.props.history.location.pathname.includes('create') || this.props.history.location.pathname.includes('edit') || this.props.history.location.pathname.includes('copy')) ? true : false
        let activePageTitle = this.props.history.location.pathname.includes('create') ? 'Create Tracking Template' : this.props.history.location.pathname.includes('edit') ?  'Edit Tracking Template' : 'Copy Tracking Template';
        const { classes } = this.props
        let footerElement = ''
        footerElement = <div className="commonFooter">
            <footer>
                <div className="centerItems">
                    {
                        this.state.viewType === 'edit' ?
                            <div>
                                <Button 
                                onClick={e => {e.preventDefault(); document.getElementById('editTemplateTracking').click();  }}
                                    startIcon={<SaveIcon/>}
                                    className={classes.pageListSaveBtn}>
                                    Save
                                </Button>
                            </div>
                            :
                            this.state.viewType === 'copy' ?
                            <div>
                               <Button 
                                  onClick={e => {e.preventDefault(); document.getElementById('copyTemplateTracking').click();  }}
                                  startIcon={<SaveIcon/>}
                                  className={classes.pageListSaveBtn}>
                                    Save
                              </Button>
                            </div> 
                            : 
                            <div>
                               <Button 
                                  onClick={e => {e.preventDefault(); document.getElementById('createTemplateTracking').click();  }}
                                  startIcon={<SaveIcon/>}
                                  className={classes.pageListSaveBtn}>
                                    Save
                              </Button>
                            </div>
                    }
                    <div>
                        {
                            !this.props.history.location.pathname.includes('create')
                            ? <Button 
                                onClick={() => this.handleShowPreview()}
                                startIcon={<PreviewOutlineIcon/>}
                                className={classes.previewBtn}>
                                    Preview
                             </Button> : ""
                        }
                    </div>
                    <div>
                        <Button 
                            onClick={() => this.handleCancel()}
                            startIcon={<CloseIcon/>}
                            className={classes.cancelButton}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </footer>
        </div>

        if (this.state.viewType === 'default' && this.props.match.path !== '/custom_tracking_page/preview' && this.props.match.path !== '/custom_tracking_page/preview/:id') {
            showView.push(
                <Scrollbars key="TrackingPageListScroll" autoHide style={scrollbar}>
                    <TrackingPageList key="TrackingPageList" classes={classes} showFooterLoader={this.showFooterLoader} handleEdit={(templateData) => this.handleEdit(templateData)} handleCopy={(templateData) => this.handleCopy(templateData)} loader={this.updateLoader}  />
                </Scrollbars>
            )
        }

        else if (this.state.viewType === 'createTrackingPage' && this.props.match.path !== '/custom_tracking_page/preview' && this.props.match.path !== '/custom_tracking_page/preview/:id') {
            showView.push(
                <Scrollbars key="CustomTrackingPageViewScroll" autoHide style={scrollbar}>
                    <CreateCustomTrackingPageView key="CreateCustomTrackingPageView" showFooterLoader={this.showFooterLoader} setHTMLValue={this.setHTMLValue} classes={classes} />
                </Scrollbars>
            )
        }

        else if (this.state.viewType === 'edit' && this.props.match.path !== '/custom_tracking_page/preview' && this.props.match.path !== '/custom_tracking_page/preview/:id') {
            showView.push(
                <Scrollbars key="EditTrackingPageScroll" autoHide style={scrollbar}>
                    <EditTrackingPage key="EditTrackingPage" classes={classes} showFooterLoader={this.showFooterLoader} setHTMLValue={this.setHTMLValue} editID={this.state.editID} />
                </Scrollbars>
            )
        }
        else if (this.state.viewType === 'copy' && this.props.match.path !== '/custom_tracking_page/preview' && this.props.match.path !== '/custom_tracking_page/preview/:id') {
            showView.push(
                <Scrollbars key="CopyTrackingPageScroll" autoHide style={scrollbar}>
                    <CopyTrackingPage key="CopyTrackingPage" classes={classes} showFooterLoader={this.showFooterLoader} setHTMLValue={this.setHTMLValue} editID={this.state.editID} />
                </Scrollbars>
            )
        }

        else if (this.props.match.path === '/custom_tracking_page/preview') {

            showView.push(
                <PreviewPage key="PreviewPage" htmlValue={this.state.htmlValue} />
            )
        }

        else if (this.props.match.path === '/custom_tracking_page/preview/:id') {
            showView.push(
                <TrackingPageIDPreview  key="TrackingPageIDPreview" />
            )
        }

        if (this.props.match.path === '/custom_tracking_page/preview' || this.props.match.path === '/custom_tracking_page/preview:id') {
            return (
                showView
            )
        }

        else {
            return (
                <React.Fragment>
                <div style = {{display:this.state.loading ? 'block' : 'none'}}>
                <LinearProgressBar/>
                </div> 
                <div className="settingsModule" style = {{display:!this.state.loading ? 'block' : 'none'}}>
                        {
                            isEditOrCreate ? 
                            <Card className={classes.root}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link className={classes.link} to="/custom_tracking_page">
                                    Tracking Page
                                </Link>
                                <Typography className={classes.text}>{activePageTitle}</Typography>     
                            </Breadcrumbs>
                          </Card> : ""
                        }
                        <div className="dataContainer">
                            {
                                this.props.history.location.pathname.includes("/custom_tracking_page") 
                                ? 
                                <div className="containerHeader" style={this.props.match.path !== "/custom_tracking_page" ? { height: '40px', marginTop: '0px', paddingTop: '32px' } : {marginTop: '0px', paddingTop: '32px'} }>
                                <Avatar alt="trackingPage_IC" className={classes.avatar} src={CustomDomain} />
                                    <div className="containerTitle" style={{width: '149px'}}>Tracking Page</div>
                                    {
                                        this.props.history.location.pathname === "/custom_tracking_page" ?
                                        <div style={{float: "right", textAlign:"right", paddingRight: '26px', cursor: 'pointer'}}>
                                            <Avatar src={addIcon} alt="Add Icon" onClick={this.createCustomTrackingPage} />
                                        </div> : ""
                                    }
                                </div> : ""
                            }
                            <div id="settingDataScrollerWrapper">
                                <div className="settingsContainer">
                                    <div className="settingsRightSidePanel">
                                        <div className="commonSettingsBox emailStyleMainDiv">
                                            {this.state.previewFlag ? <Scrollbars autoHide style={scrollbar}>
                                                <CreateCustomTrackingPageView setHTMLValue={this.setHTMLValue} />
                                            </Scrollbars> : showView}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.showFooterLoaderFlag ?
                                    <div className="commonFooter">
                                        <footer>
                                            <div className="centerItems">
                                            <CircularProgress size={32} className={classes.progress} /> 
                                            </div>
                                        </footer>
                                    </div>   
                                 : this.state.viewType === 'createTrackingPage' || this.state.viewType === 'edit' || this.state.viewType === 'copy' || this.state.previewFlag ? footerElement : ''
                            }
                        </div>
                    </div>
                    </React.Fragment>
                )
            }
        }
}
export default withStyles(Styles)(CustomTrackingPage);
