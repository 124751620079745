import axios from 'axios';
import { getInstance } from '../../config/token';
const prefix = ''

export function topCouriers(data) {   
    let config = getInstance('POST', prefix + '/dashboard/top_couriers', data)
    return axios(config).then(res => res)
}

export function topCountries(data) {   
    let config = getInstance('POST', prefix + '/dashboard/top_countries', data)
    return axios(config).then(res => res)
}
export function trackingsData(data) {   
    let config = getInstance('POST', prefix + '/dashboard/tracking_count', data)
    return axios(config).then(res => res)
}

export function shipmentSummaryChart(data) {
    let config = getInstance('POST', prefix + '/dashboard/tracking_summary', data)
    return axios(config).then(res => res )
}

export function getAverageDaysToDeliver(data) {
    let config = getInstance('POST', prefix + '/dashboard/shipments/average_days_to_delivery',data)
    return axios(config).then(res => res )
}

export function getTATCourierWise(data) {
    let config = getInstance('POST', prefix + '/dashboard/shipments/day_wise_delivery_count',data)
    return axios(config).then(res => res )
}