import React from 'react';
import {accessDenied} from './common/imageList'
class SubUserLinkExpired extends React.Component{

    render(){
        return(
            <div style={{marginTop:'140px',textAlign:'center',fontSize:'1.8rem',color:'#bfc2c7' }}>
                <img src={accessDenied} alt="IconAccess" style={{marginTop:'2rem'}}/>
                <p >The link has been expired.<br/> Check with your admin for more details.</p>
            </div>
        )
    }
}
export default SubUserLinkExpired;