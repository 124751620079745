import React from 'react';
import ContentLoader from 'react-content-loader';

class SmsTemplateLoader extends React.Component {

  render() {
    return (
      <ContentLoader height={415} width={700} speed={2} primaryColor={'#f1f1f1'} secondaryColor={'#e2e2e2'}>
        
      <rect x="20" y="15" rx="5" ry="5" width="150" height="6" />
      <rect x="205" y="15" rx="5" ry="5" width="50" height="6" />

      <text x='20' y='45' style={{ fontSize: '0.65rem' }} >Template Name</text>
      <rect x="20" y="55" rx="5" ry="5" width="450" height="15" />

      <text x='20' y='90' style={{ fontSize: '0.65rem' }} >Body</text>
      <rect x="20" y="100" rx="5" ry="5" width="450" height="80" />

      <text x='515' y='80' style={{ fontSize: '0.65rem' }} >Insert values</text>
      <rect x="515" y="90" rx="5" ry="5" width="150" height="25" />
      <rect x="515" y="130" rx="5" ry="5" width="150" height="25" />
        
      </ContentLoader>
    )
  }
}

export default SmsTemplateLoader