
import moment from 'moment-timezone'
import { reactLocalStorage } from 'reactjs-localstorage'

export const changeDateFunction = (key, compareFlag) => {
    let localDate = moment().tz(reactLocalStorage.get('TIMEZONE')).format('YYYY-MM-DD 00:00:00')
    let todayUTCDate = moment(localDate).subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:00')
    let toDate = moment(todayUTCDate).add(24, 'hours').subtract(1, 'minutes').format('YYYY-MM-DD HH:mm:59')
    let yesterdayUTCDate = moment(todayUTCDate).subtract(1, 'days').format('YYYY-MM-DD HH:mm:00')
    let yesterdayDate = moment(yesterdayUTCDate).add(24, 'hours').subtract(1, 'minutes').format('YYYY-MM-DD HH:mm:59')
    let endDate = moment().endOf('month').format('YYYY-MM-DD 23:59:59')
    let startDate = moment().startOf('month').format('YYYY-MM-DD 00:00:00')
    let returnObj = {}
        
    if (key === '7Days') {
        let days_7UTCDate = moment(todayUTCDate).subtract(6, 'days').format('YYYY-MM-DD HH:mm:00')
        
        
        if(compareFlag) {
            let compareToDate = moment(todayUTCDate).subtract(6, 'days').subtract(1, 'minutes').format('YYYY-MM-DD HH:mm:59')
            let compareFromDate = moment(days_7UTCDate).subtract(7, 'days').format('YYYY-MM-DD HH:mm:00')
            returnObj.compareToDate = compareToDate
            returnObj.compareFromDate = compareFromDate
        }

        returnObj.toDate = toDate
        returnObj.fromDate = days_7UTCDate       
        returnObj.dateType = '7 Days'

        return returnObj
    } else if (key === '14Days') {
        let day_14UTCDate = moment(todayUTCDate).subtract(13, 'days').format('YYYY-MM-DD HH:mm:00')
        
        if(compareFlag) {
            let compareToDate = moment(todayUTCDate).subtract(13, 'days').subtract(1, 'minutes').format('YYYY-MM-DD HH:mm:59')
            let compareFromDate = moment(day_14UTCDate).subtract(14, 'days').format('YYYY-MM-DD HH:mm:00')

            returnObj.compareToDate = compareToDate
            returnObj.compareFromDate = compareFromDate
        }

        returnObj.toDate = toDate
        returnObj.fromDate = day_14UTCDate       
        returnObj.dateType = '14 Days'

        return returnObj
    } else if (key === '30Days') {
        let day_30UTCDate = moment(todayUTCDate).subtract(29, 'days').format('YYYY-MM-DD HH:mm:00')
        
        if(compareFlag) {
            let compareToDate = moment(todayUTCDate).subtract(29, 'days').subtract(1, 'minutes').format('YYYY-MM-DD HH:mm:59')
            let compareFromDate = moment(day_30UTCDate).subtract(30, 'days').format('YYYY-MM-DD HH:mm:00')

            returnObj.compareToDate = compareToDate
            returnObj.compareFromDate = compareFromDate
        }
        returnObj.toDate = toDate
        returnObj.fromDate = day_30UTCDate       
        returnObj.dateType = 'Last 30 Days'

        return returnObj
    } else if (key === 'LastMonth') {
        toDate = moment(endDate).subtract(1, 'months').endOf('month').subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:59')
        let fromDate = moment(startDate).subtract(1, 'months').subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:00')
        
        if(compareFlag) {
            let compareToDate = moment(endDate).subtract(2, 'months').subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:59')
            let compareFromDate = moment(startDate).subtract(2, 'months').subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:00')

            returnObj.compareToDate = compareToDate
            returnObj.compareFromDate = compareFromDate
        }

        returnObj.toDate = toDate
        returnObj.fromDate = fromDate       
        returnObj.dateType = moment().startOf('month').subtract(1, 'months').format('DD MMM, YYYY') + ' to ' + moment().endOf('month').subtract(1, 'months').endOf('month').format('DD MMM, YYYY')

        return returnObj
    }else if (key === 'ThisMonth') {
        toDate = moment(endDate).subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:59')
        let fromDate = moment(startDate).subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:00')
        
        if(compareFlag) {
            let compareToDate = moment(endDate).subtract(1, 'months').subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:59')
            let compareFromDate = moment(startDate).subtract(1, 'months').subtract(moment().tz(reactLocalStorage.get('TIMEZONE')).utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:00')

            returnObj.compareToDate = compareToDate
            returnObj.compareFromDate = compareFromDate
        }

        returnObj.toDate = toDate
        returnObj.fromDate = fromDate       
        returnObj.dateType = moment().startOf('month').format('DD MMM, YYYY') + ' to ' + moment().endOf('month').format('DD MMM, YYYY')

        return returnObj
    } else if (key === 'Alltime') {
        returnObj.toDate = toDate
        returnObj.fromDate = '2014-01-01 00:00:00'       
        returnObj.dateType = 'All Time'

        return returnObj
    } else if (key === 'Today') {
        returnObj.toDate = toDate
        returnObj.fromDate = todayUTCDate
        returnObj.dateType = 'Today'

        if(compareFlag) {
            returnObj.compareToDate = moment(yesterdayUTCDate).add(24, 'hours').subtract(1, 'minutes').format('YYYY-MM-DD HH:mm:59')
            returnObj.compareFromDate = yesterdayUTCDate
        }
        
        return returnObj
    } else if (key === 'Yesterday') {
        returnObj.toDate = yesterdayDate
        returnObj.fromDate = yesterdayUTCDate
        returnObj.dateType = 'Yesterday'

        // if(compareFlag) {
        //     returnObj.compareToDate = moment(yesterdayUTCDate).add(24, 'hours').subtract(1, 'minutes').format('YYYY-MM-DD HH:mm:59')
        //     returnObj.compareFromDate = yesterdayUTCDate
        // }
        
        return returnObj
    }
}

export const setChartColor = (status) => {
    let color = '#f551b0'

    if(status === 'InfoReceived'){
        color = '#3690F7'
    }

    else if(status === 'FailedAttempt'){
        color = '#E95656'
    }

    else if (status === 'Exception'){
        color = '#F97E2B'
    }

    else if (status === 'Delivered'){
        color = '#47CF6E'
    }

    else if(status === 'Pending'){
        color = '#FCCC1B'
    }

    else if (status === 'InTransit'){
        color = '#2CCCCB'
    }

    else if(status === 'OutForDelivery'){
        color = '#F551B1'
    }

    else if(status === 'Expired'){
        color = '#707479'
    }

    return color
}