import React from 'react';
import Footer from '../../../../common/footer';
import { Scrollbars } from 'react-custom-scrollbars';
import { IntegrationPage, AddNewIntegration, NewIntegration, MagentoAPIImage, ActivationLink, DialogBox1, DialogBox2, MagentoTargetStore, storeIDURL } from '../../../../../common/imageList'

export default class MagentoInstructions extends React.Component{
    moveToMagentoForm = () => {
        this.props.moveToMagentoForm(true)
    }
    backToIntegration = () => {
        this.props.backToIntegration()
    }

    render(){
        return(
            <React.Fragment>
            <Scrollbars autoHide style={{height: "calc(100vh - 266px)", width: "100%"}}>
                 <div className="integrationStepsMainDiv">
                 <div className="stepsTitle">Steps For Access Token</div>
                            <ul className="instructions-list">
                                <li>
                                    <span className="list-seq">1</span>
                                    <span className="instructions-text">Log in to Admin and click System > Extensions > Integrations to display the Integrations page.</span>
                                    <span className="instructions-ss"><img alt="" src={IntegrationPage} /></span>
                                </li>
                                <li>
                                    <span className="list-seq">2</span>
                                    <span className="instructions-text">Click Add New Integration to display the New Integration page.</span>
                                    <span className="instructions-ss"><img alt="" src={AddNewIntegration} /></span>
                                </li>
                                <li>
                                    <span className="list-seq">3</span>
                                    <span className="instructions-text">Enter a unique name for the integration in the Name field. Then enter your admin password in the Your Password field. Leave all other fields blank.</span>
                                    <span className="instructions-ss"><img alt="" src={NewIntegration} /></span>
                                </li>
                                <li>
                                    <span className="list-seq">4</span>
                                    <span className="instructions-text">Click the API tab. Select the Magento resources the integration can access. You can select all resources, or select a custom list.</span>
                                    <span className="instructions-ss"><img alt="" src={MagentoAPIImage} /></span>
                                </li>
                                <li>
                                    <span className="list-seq">5</span>
                                    <span className="instructions-text">Click Save to save your changes and return to the Integrations page.</span>
                                </li>
                                <li>
                                    <span className="list-seq">6</span>
                                    <span className="instructions-text">Click the Activate link in the grid that corresponds to the newly-created integration.</span>
                                    <span className="instructions-ss"><img alt="" src={ActivationLink} /></span>
                                </li>
                                <li>
                                    <span className="list-seq">7</span>
                                    <span className="instructions-text">Click Allow . A dialog similar to the following displays:</span>
                                    <span className="instructions-ss"><img alt="" src={DialogBox2} /></span>
                                    <span className="instructions-ss"><img alt="" src={DialogBox1} /></span>
                                </li>
                            </ul>
                            <div className="stepsTitle">Steps For Finding Store ID</div>
                            <ul className="instructions-list">
                                <li>
                                    <span className="list-seq">1</span>
                                    <span className="instructions-text">From the Magento 2 admin, open Stores -> All Stores. Click on the target Store View. </span>
                                    <span className="instructions-ss"><img alt="" src={MagentoTargetStore} /></span>
                                </li>
                                <li>
                                    <span className="list-seq">2</span>
                                    <span className="instructions-text">Find the Store ID in the browser URL. </span>
                                    <span className="instructions-ss"><img alt="" src={storeIDURL} /></span>
                                </li>
                            </ul>
                            <button
                                id="store-next-btn"
                                type="buttn"
                                style={{
                                    float: 'right',
                                    display: 'none'
                                }}
                                onClick={this.moveToMagentoForm}
                            >
                                Next
                        </button>
                            <button
                                id="store-cancel-btn"
                                type="buttn"
                                style={{
                                    float: 'right',
                                    display: 'none'
                                }}
                                onClick={this.backToIntegration}
                            >
                                Cancel
                        </button>
                </div>
                 </Scrollbars>
            <div className="commonFooter">
                <Footer storeAction="true" />
            </div>
            </React.Fragment>
        )
   }
}