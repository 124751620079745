import React from 'react'
const REACT_S3_URL = process.env.REACT_APP_S3_URL

const FirstTrackingCheckpoints = props => {
    if (props.firstTrackingCheckpoints) {
        let dataToDisplay = []
        let element = props.firstTrackingCheckpoints
        let paddingStyle = {backgroundPosition: '160px 0px' }
        let statusType = 'Pending'
            if(element != ''){
            if (element.tag === 'InTransit') {
                statusType = 'InTransit'
            }

            else if (element.tag === 'Delivered') {
                statusType = 'Delivered'
            }

            else if (element.tag === 'OutForDelivery') {
                statusType = 'OutForDelivery'
            }

            else if (element.tag === 'InfoReceived') {
                statusType = 'InfoReceived'
            }

            else if (element.tag === 'FailedAttempt' || element.tag === 'AttemptFail'){
                statusType = 'FailedAttempt'
            }

            else if (element.tag === 'Expired'){
                statusType = 'Expired'
            }

            else if (element.tag === 'Exception'){
                statusType = 'Exception'
            }
            
            dataToDisplay.push(
                <tr key={0}>
                    <td id = "firstCheckpoint" className="trackingTimelineStatusIcon" style={paddingStyle}>
                        <div title={statusType}>
                            <img src={REACT_S3_URL+statusType+'.svg'} />
                        </div>
                    </td>
                   
                    <td className="trackingTimelineInfo">
                        <div className="itemLocation">
                            {element.location}
                        </div>
                        <div className="trackingMessage">
                            

                        <div dangerouslySetInnerHTML={{ __html:element.data }} />

                        </div>
                    </td>
                    <td className="trackingTimelineDateTimeValue">
                        <p>
                            {element.date[0] +' '+element.date[1]}
                        </p>
                    </td>
                </tr>
            )
        } else {
            paddingStyle = {backgroundPosition: '160px 0px !important', background : 'none' }
            dataToDisplay.push(
                <tr key={0}>
                    <td className="trackingTimelineStatusIcon" style={paddingStyle}>
                        <div title={statusType}>
                            <img src={REACT_S3_URL+statusType+'.svg'} />
                        </div>
                    </td>
                    <td className="trackingTimelineInfo">
                        <div className="itemLocation">
                        Awaiting for Updates from Courier
                        </div>
                    </td>
                </tr>
            )
        }
        return (
            <div className="trackingHistoryTimelineInnerDiv">
                <table>
                    <tbody>
                        {dataToDisplay}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default FirstTrackingCheckpoints