export const getLabel = (collection = [], collectionValue = null, sendFullCollection = false) => {


    if (collection !== undefined) {
        let collectionLabel = collection.find((collectionObj) => {
            return collectionObj.value === collectionValue
        })

        if (collectionLabel !== undefined && collectionLabel !== null) {
            if (Object.keys(collectionLabel).length > 0 && collectionLabel.label) {
                if (sendFullCollection) {
                    return collectionLabel
                } else {
                    return collectionLabel.label
                }
            }
        }
    }
    return false
}

export const getConditonValue = (conditionObj = []) => {
    let conditionValueList = []
    if (conditionObj !== undefined && conditionObj && conditionObj.length > 0) {
        if (typeof conditionObj === 'string') {
            conditionValueList.push(conditionObj)
        } else {
            conditionObj.forEach(conditionValue => {
                if (typeof conditionValue === 'object') {
                    conditionValueList.push(conditionValue.name)
                } else {
                    conditionValueList.push(conditionValue)
                }
            });
        }

        if (conditionValueList.length > 0) {
            return conditionValueList.join(', ')
        }
    } else {
        if (conditionObj !== undefined && conditionObj.warehouse !== undefined) {
            if (conditionObj.stockStatus !== undefined) {
                return ' in ' + conditionObj.warehouse.label + ' warehouse ' + conditionObj.stockType.label + ' is ' + conditionObj.stockStatus.label
            } else {
                if (conditionObj.qty !== undefined) {
                    return ' in ' + conditionObj.warehouse.label + ' warehouse ' + conditionObj.stockType.label + ' ' + conditionObj.operator.label + ' ' + conditionObj.qty
                } else {
                    return ' in ' + conditionObj.warehouse.label + ' warehouse ' + conditionObj.stockType.label + ' ' + conditionObj.operator.label + ' ' + conditionObj.min + ' to ' + conditionObj.max
                }
            }
        } else if (conditionObj !== undefined && conditionObj.timer !== undefined) {
            if (conditionObj.number !== undefined) {
                return conditionObj.number + ' in ' + conditionObj.timer + ' ' + conditionObj.timerLabel
            }
            if (conditionObj.min !== undefined && conditionObj.max !== undefined) {
                return conditionObj.min + ' to ' + conditionObj.max + ' in ' + conditionObj.timer + ' ' + conditionObj.timerLabel
            }
        } else {
            if (conditionObj && conditionObj.number !== undefined) {
                return conditionObj.number + ' ' + conditionObj.timerLabel
            }
            if (conditionObj && conditionObj.min !== undefined && conditionObj.max !== undefined) {
                return conditionObj.min + ' to ' + conditionObj.max + ' ' + conditionObj.timerLabel
            }
        }
    }
    return ''
}

export const getActionObjectKey = (trigger = null, triggerCondition = null) => {
    if (trigger && triggerCondition) {
        return trigger + '_' + triggerCondition
    } else {
        return ''
    }
}



export const getLabelInfo = (collection = '', params = '') => {
    let returnInfo = ''

    if (collection.includes('ITEM_CUSTOM_DATE')) {
        collection = 'ITEM_CUSTOM_DATE'
    }

    if (collection !== '') {
        switch (collection) {
            case 'DELIVERY_DATE':
            case 'CREATED_DATE':
            case 'ITEM_CUSTOM_DATE':
                returnInfo = 'day(s).'
                break
            case 'SO_WEIGHT':
                returnInfo = params !== undefined && params !== null && params.length > 0 ? params[0].value + '(s).' : ''
                break
            default:
                break
        }
    }

    return returnInfo
}

export const checkIsBwtweenCondition = (conditionOperatorObj, index) => {
    let returnData = false

    for (let key in conditionOperatorObj) {
        if (conditionOperatorObj[key] === 'IS BETWEEN' && index === key) {
            returnData = true
        }
    }

    return returnData
}

export const getComponentType = (type) => {
    switch (type) {
        case 'TEXT':
        case 'NUMBER':
            return 'CommaSeperatedList'
        case 'DATE':
            return 'NumberRange'
        case 'CHECKBOX':
            return 'ReferenceNumber'
        case 'DROPDOWN':
            return 'CustomFieldDropDown'
        default:
            return ''
    }
}

const IS_OPERATOR = { label: 'is', value: 'IN' }
const NOT_IS_OPERATOR = { label: 'is not', value: 'NOT IN' }
const CONTAINS_OPERATOR = { label: 'contains', value: 'LIKE' }
const CONTAINS_NOT_OPERATOR = { label: 'contains not', value: 'NOT LIKE' }
const IN_NEXT_OPERATOR = { label: 'is in Next', value: 'IN NEXT' }
const WAS_BEFORE_OPERATOR = { label: 'was Before', value: 'WAS BEFORE' }

export const getOperatorType = (type) => {
    switch (type) {
        case 'TEXT':
        case 'NUMBER':
            return [IS_OPERATOR, NOT_IS_OPERATOR, CONTAINS_OPERATOR, CONTAINS_NOT_OPERATOR]
        case 'DATE':
            return [IN_NEXT_OPERATOR, WAS_BEFORE_OPERATOR]
        case 'CHECKBOX':
            return [IS_OPERATOR]
        case 'DROPDOWN':
            return [IS_OPERATOR, NOT_IS_OPERATOR]
    }
}
