import React from 'react';
import '../../assets/css/integrations.scss';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';
import { scrollbar } from '../../../static/constants';
import { connect } from 'react-redux';
import { getInstance } from '../../../config/token';
import SearchInput, { createFilter } from 'react-search-input'
import IntegrationListGrid from './integrationListGrid'
import { withStyles } from '@material-ui/core/styles';
import { LinearProgressBar } from '../../common/materialComponents';
import CssBaseline from '@material-ui/core/CssBaseline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Styles from '../../assets/css/Styles';
const KEYS_TO_FILTERS = ['channel']
const prefix = ''
const pathName = '/integrated_list'

class Integrations extends React.Component {

    constructor(props) {
        super(props)
        this.isCurrentPathList = this.props.location.pathname.includes(pathName)
        this.state = {
            channels: [],
            allChannels: [],
            loaderflag: true,
            noData: false,
            tabValue: this.isCurrentPathList ? 0 : 1
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.location.pathname !==  this.props.location.pathname) {
            if(this.props.location.pathname.includes(pathName)) {
                this.setState({ tabValue: 0 });
                this.isCurrentPathList = true
            } else {
                this.setState({ tabValue: 1 });
                this.isCurrentPathList = false
            }
            this.getList()
        }
    }

    componentDidMount() {
        this.getList()
    }
    getList() {
        this.setState({ loaderflag: true })
        let config = null
        if (this.isCurrentPathList) {
            config = getInstance('GET', `${prefix}/stores/list`)
        } else {
            config = getInstance('GET', `${prefix}/channels/list`)
        }
        axios(config).then(res => {
            this.setState({
                channels: res.data.data,
                allChannels: res.data.data,
                loaderflag: false
            })
        }).catch(err => {
            console.log(err)
        })
    }
    handleSearch = (term) => {
        let All = this.state.allChannels
        let data
        let channelArray = []
        All.forEach(channel => {
            channelArray.push(channel)
            data = channelArray.filter(createFilter(term, KEYS_TO_FILTERS))

        })
        if (data.length === 0) {
            this.setState({ noData: true })
        }
        this.setState({
            channels: data
        })


    }
    handleTabChange = (event, newValue) => {
        if (newValue === 1) {
            this.props.history.push('/integrations')
        } else {
            this.props.history.push('/integrated_list')
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                {!this.isCurrentPathList ?
                        <SearchInput id="SearchWrap" className="SearchInput" onChange={this.handleSearch} />
                    : ""}
                <CssBaseline />
                {this.state.loaderflag ?
                    <LinearProgressBar />
                    : null}
                <Paper className={classes.paperRoot}>
                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        classes={{ indicator: classes.indicatorColor }}
                    >
                        <Tab classes={{ selected: classes.tabTextColor }} label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Manage Integrations&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />
                        <Tab classes={{ selected: classes.tabTextColor }} label="All" />
                    </Tabs>
                </Paper>
                {!this.state.loaderflag ?
                    <div style={{ height: "calc(100vh - 100px)" }}>
                        <Scrollbars autoHide style={scrollbar}>
                            {!this.isCurrentPathList ?
                                <h2 className="integrateH2Style">Select from to integrate</h2>
                                : ""}
                            <IntegrationListGrid
                                isList={this.isCurrentPathList}
                                menu={this.state.channels}
                                userType={this.state.userType}
                                tabChange={this.handleTabChange}
                                settingFlag={this.state.settingsFlag} />
                        </Scrollbars>
                    </div >
                    : null
                }
            </React.Fragment>
        )
    }
}

export default withRouter(connect(null, {})(withStyles(Styles)(Integrations)));