import React from 'react';
import Styles from '../../../../assets/css/Styles'
import { withRouter} from 'react-router-dom';
import IntegrateStore from '../IntegrateStore';
import { withStyles } from '@material-ui/core/styles';
import WooCommerceInstructions from './WooCommerceInstructions';
import BreadCrumbsCard from '../../BreadCrumbsCard';
import IntegrationLogo from '../../IntegrationLogo';
import FlashMessage from '../../../../common/FlashMessage';
import { LinearProgressBar} from '../../../../common/materialComponents';
const WOOCOMMERE_LOGO = process.env.REACT_APP_S3_URL + "courierImages/WOOCOMMERCE.svg"
class WooCommerce extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess = false
        this.state = {
            showSaveLoader: false,
            error: false,
            successErrMsg: '',
            success: false,
            updateToggleMessage: false,
            wooCommStoreActive: false,
            wooCommerceLoader: this.props.match.params.id ? true : false,
            wooCommForm: this.props.match.params.id === undefined ? false : true
        }
    }

    wooCommerceLoader = (isLoading) => {
        this.setState({wooCommerceLoader: isLoading})
    }

    moveToWooCommForm = (data) => {
        this.setState({
            wooCommForm: data
        })
    }

    toggleFlashMessage = (data) => {
        this.setState({error: false, success: false, successErrMsg: '', showSaveLoader: false})
    }

    updateToggleMessage = (data) => {
        this.isSuccess = data.isSuccess 
        this.setState({error: data.error, success: data.success, successErrMsg: data.successErrMsg, showSaveLoader: data.showSaveLoader ? data.showSaveLoader : false})
    }
    
    isWooCommActive = (isActive) => {
        this.setState({wooCommStoreActive: isActive})
    }

    backToIntegration = () => {
        this.props.history.push('/integrations')
    }

    backToIntegrationList = () => {
        this.props.history.push('/integrated_list')
    }
    
    render() {
        const { classes } = this.props
        return(
            <React.Fragment>
                <div style = {{display:this.state.wooCommerceLoader ? 'block' : 'none'}}>
                    <LinearProgressBar/>
                </div>
                <div style = {{display:!this.state.wooCommerceLoader ? 'block' : 'none'}}>
                <div className="settingsModule">
                        <BreadCrumbsCard classes={classes} storeName="WooCommerce" 
                            storeId = {this.props.match.params.id} activeStore={this.isWooCommActive} 
                            active={this.state.wooCommStoreActive} updateToggleMessage={this.updateToggleMessage} />
                        <div className="dataContainer">
                            <IntegrationLogo 
                                classes={classes}
                                title = "WooCommerce"
                                icon = {WOOCOMMERE_LOGO}
                            />
                            <div id="settingDataScrollerWrapper">
                                <div className="settingsContainer">
                                    <div style={{width: '100%'}}>
                                        {
                                            this.state.wooCommForm ?
                                             <IntegrateStore isLoading={this.wooCommerceLoader} updateSuccessErrMsg={this.updateToggleMessage} activeStore={this.isWooCommActive} classes={classes} type = "WooCommerce" storeId = {this.props.match.params.id} channelId= "4" backToIntegrationList={this.backToIntegrationList}/>                                        
                                            : <WooCommerceInstructions classes={classes} moveToWooCommForm={this.moveToWooCommForm} backToIntegration={this.backToIntegration}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.error || this.state.success) && !this.state.showSaveLoader ?
                            <FlashMessage isSuccess={this.isSuccess} message={this.state.successErrMsg} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                        : ''}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(Styles)(WooCommerce));
