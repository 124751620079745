import React from 'react';
import { withRouter } from 'react-router-dom';
import IntegrateStore from '../IntegrateStore';
import Styles from '../../../../assets/css/Styles';
import { withStyles } from '@material-ui/core/styles';
import GwWorldInstructions from './GwWorldInstructions';
import BreadCrumbsCard from '../../BreadCrumbsCard';
import IntegrationLogo from '../../IntegrationLogo';
import { GWWORLDLOGO } from '../../../../../common/imageList';
import FlashMessage from '../../../../common/FlashMessage';
import { LinearProgressBar } from '../../../../common/materialComponents';

class GwWorld extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess = false
        this.state = {
            error: false,
            showSaveLoader: false,
            updateToggleMessage: false,
            success: false,
            successErrMsg: '',
            gwWorldStoreActive: false,
            gwWorldForm: this.props.match.params.id === undefined ? false : true,
            gwWorldStoreLoader: this.props.match.params.id ? true : false
        }
    }

    gwWorldStoreLoader = (isLoading) => {
        this.setState({gwWorldStoreLoader: isLoading})
    }
    
    toggleFlashMessage = (data) => {
        this.setState({
            error: false,
            success: false,
            successErrMsg: ''
        })
    }

    updateToggleMessage = (data) => {
        this.isSuccess = data.isSuccess 
        this.setState({error: data.error, success: data.success, successErrMsg: data.successErrMsg, showSaveLoader: data.showSaveLoader ? data.showSaveLoader : false})
    }
    
    isGwWorldActive = (isActive) => {
        this.setState({gwWorldStoreActive: isActive})
    }

    moveToGwWorldForm = (data) => {
        this.setState({
            gwWorldForm: data
        })
    }

    backToIntegration = () => {
        this.props.history.push('/integrations')
    }

    backToIntegrationList = () => {
        this.props.history.push('/integrated_list')
    }

    render() {
        const { classes } = this.props
        return(
            <React.Fragment>
                <div style = {{display:this.state.gwWorldStoreLoader ? 'block' : 'none'}}>
                    <LinearProgressBar/>
                </div>
                <div style = {{display:!this.state.gwWorldStoreLoader ? 'block' : 'none'}}>
                <div className="settingsModule">
                         <BreadCrumbsCard classes={classes} storeName="Gw World" 
                                storeId = {this.props.match.params.id} activeStore={this.isGwWorldActive} 
                                active={this.state.gwWorldStoreActive} updateToggleMessage={this.updateToggleMessage} />
                        <div className="dataContainer">
                            <IntegrationLogo 
                                icon = {GWWORLDLOGO}
                                title = "Gw World"
                                classes={classes}
                            />
                            <div id="settingDataScrollerWrapper">
                                <div className="settingsContainer">
                                    <div className="settingsRightSidePanel">
                                        {
                                            this.state.gwWorldForm ?
                                            <IntegrateStore 
                                                isLoading={this.gwWorldStoreLoader} updateSuccessErrMsg={this.updateToggleMessage}
                                                activeStore={this.isGwWorldActive} classes={classes} type = "GwWorld" 
                                                storeId = {this.props.match.params.id} channelId= "9" backToIntegrationList={this.backToIntegrationList}/> 
                                            : <GwWorldInstructions classes={classes} moveToGwWorldForm={this.moveToGwWorldForm} backToIntegration={this.backToIntegration}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                         {(this.state.error || this.state.success) && !this.state.showSaveLoader ?
                            <FlashMessage isSuccess={this.isSuccess} message={this.state.successErrMsg} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                        : ''}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(Styles)(GwWorld));
