import React from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars';
import {resetPasswordLinkMessage} from './actionMethods/actionMethods'
import {defaultUserPic, cancelIcon} from '../common/imageList'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MediaContent from '../common/userMediaCard'
import { users_scrollbar} from '../static/constants'
import FlashMessage from '../../src/TrackingList/common/flashMessage'
import Styles from './Styles';

const mapStateToProps = state => ({
    isLoggedIn: state.authenticate.isLoggedIn,
    setpasswordEmailLinkMessage: state.authenticate.setpasswordEmailLinkMessage
})

class Users extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            doneRemove: false,
            openSnackBar: true
        }
    }


    componentDidMount() {
        let localStorageValues = []
        localStorageValues = JSON.parse(localStorage.getItem("userNamesList"))
        if (localStorageValues === null) {
            this.props.history.push('/login')
        }
        if (localStorageValues && localStorageValues.length === 0) {
            this.props.history.push('/login')
        }
    }

    handleDoneClick = () => {
        this.setState({
            doneRemove: false
        })
    }

    handleRemoveClick = () => {
        this.setState({
            doneRemove: true
        })
    }

    handleClick = (valueItem) => (event) => {
        if (this.state.doneRemove) {
            this.setState({
                doneRemove: true
            })
            let storageValues = JSON.parse(localStorage.getItem('userNamesList'));
            if (storageValues) {
                storageValues.splice(valueItem, 1)
                localStorage.setItem('userNamesList', JSON.stringify(storageValues))

                if (storageValues.length === 0) {
                    this
                        .props
                        .history
                        .push('/login');
                }
            }

        } else {
            (localStorage.setItem('UserName', JSON.stringify(valueItem)));
            this.props.history.push('/userLogin');
        }
    }

    displayUsersList = () => {
        let storageValues = []
        storageValues = JSON.parse(localStorage.getItem('userNamesList'));

        let divBlock = []
        if (storageValues && storageValues.length > 0) {
                const { classes } = this.props;
                for (let index = 0; index < storageValues.length; index++) {
                    const element = storageValues[index];
                    divBlock.push(
                        <React.Fragment key={element.email}>
                                <CardContent className={classes.content} onClick={this.handleClick(element)}>
                                    <CardHeader
                                        classes={{
                                            title: classes.title,
                                            subheader: classes.subHeader,
                                            root: classes.headerRoot,
                                            action: classes.removeAction
                                        }}
                                        avatar={
                                            <Avatar aria-label="Organization pic" src={defaultUserPic} />
                                        }
                                        action={
                                            <IconButton 
                                                className={this.state.doneRemove ? classes.removeIcon : classes.removeIconHide} 
                                                aria-label="settings">
                                              <img alt="" src={cancelIcon} />
                                            </IconButton>
                                        }
                                        title={element.name}
                                        subheader={element.email}
                                    />
                                </CardContent>
                            <Divider className={classes.divider} />
                        </React.Fragment>
                    )
                }
        }
        return divBlock
    }

    displayRemoveUserSection = () => {
        const { classes } = this.props;
        if(!this.state.doneRemove) {
            return (<React.Fragment>
                <CardContent style={{cursor:"pointer"}} className={classes.content} onClick={this.handleRemoveClick}>
                    <CardHeader
                        classes={{
                            title: classes.title,
                            root: classes.headerRoot
                        }}
                        avatar={
                            <Avatar aria-label="recipe" src={defaultUserPic} />
                        }
                        title="Remove an account"
                    />
                </CardContent>
                <Divider className={classes.divider} /> 
            </React.Fragment>)
        } else {
            return (
                <CardActions className={classes.deleteButtonRoot}>
                   <Button size="small" color="primary" onClick={this.handleDoneClick}>
                        Done
                    </Button>
                </CardActions>
            )
        }
    }

    closeFlashMessage = () => {
        this.setState({ openSnackBar: false })
    }

    render() {
        const { classes } = this.props;
        const {from} = this.props.location.state || {
            from: {
              pathname: '/'
            }
          }
          if (this.props.isLoggedIn) {
            return <Redirect to={from} search={this.props.location.search} />
          }

          
        return (
            <Scrollbars autoHide style={users_scrollbar}>
                    <div className="loginAndRegistrationCommonDiv">
                    <Card className={classes.rootCustomCss} variant="outlined">
                        <MediaContent content="Choose an account" />
                        {this.displayUsersList(this.state.doneRemove)}
                        <Link  style={{ textDecoration: 'none' }} to={{ state: this.props.location.state, pathname: "/login", search: this.props.location.search }} className="link">
                            <CardContent className={classes.content} >
                                <CardHeader
                                    classes={{
                                        title: classes.title,
                                        root: classes.headerRoot
                                    }}
                                    avatar={
                                        <Avatar aria-label="recipe" src={defaultUserPic} />
                                    }
                                    title="Use another account"
                                />
                            </CardContent>
                        </Link>
                        <Divider className={classes.divider} />
                        { this.displayRemoveUserSection() }
                    </Card>
                    {
                        this.props.setpasswordEmailLinkMessage !== undefined && this.props.setpasswordEmailLinkMessage !== '' ?
                        <FlashMessage open={this.state.openSnackBar} closeFlash={this.closeFlashMessage} classes={classes.addTrackingSnackbar} messageDetails={{ type: 'SUCCESS', message: this.props.setpasswordEmailLinkMessage }} />
                        : ''
                    }
                </div>
            </Scrollbars>
        )
    }
}
export default withRouter(connect(mapStateToProps,{resetPasswordLinkMessage})(withStyles(Styles)(Users)))
