import React from 'react'
import right_triangle from '../../assets/img/right_triangle.svg'
import { AccordionItem, AccordionItemTitle, AccordionItemBody } from 'react-accessible-accordion'

const separatorStart = '['
const separatorEnd = ']'

export default class InputAccordion extends React.Component {
  render() {
    return (
      <React.Fragment>
        <AccordionItem uuid={this.props.index}>
          <AccordionItemTitle>
            <div className="title__div" title={this.props.accordianTitle} >{this.props.accordianTitle}</div>
            <div className="title__triangle__div"><img alt="" src={right_triangle} className="arrow"/></div>
          </AccordionItemTitle>
          <AccordionItemBody>
            {this.props.buttonValues.map((placeHolder) => {
              let placeHolderValue = separatorStart + placeHolder + separatorEnd
              return <div key={placeHolder} className="button__div">
                          <button onClick={() => this.props.handleInsertValue(placeHolderValue)}>
                              {placeHolderValue}
                          </button>
                     </div>
            })
            }
          </AccordionItemBody>
        </AccordionItem>
      </React.Fragment>
    )
  }
}
