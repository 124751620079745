import React from 'react';
import TinyMCE from 'react-tinymce';

class EmailEditor extends React.Component {

  handleEditorChange(e) {
    this.props.handleContent(e.target.getContent())
  }

  render() {
    let contentData = this.props.content
    let editorHeight = 327
    let editorWidth = 710
    return (
      <TinyMCE
        content=""
        config={{
          selector: 'email',
          branding: false,
          width: editorWidth,
          height: editorHeight,
          id: 'email',
          resize: false,
          image_advtab: true,
          file_picker_types: 'image',
          font_formats: 'Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Times New Roman=times new roman,times;Verdana=verdana,geneva;',
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.onchange = function () {
              var file = this.files[0];

              var reader = new FileReader();
              reader.onload = function (e) {
                cb(e.target.result, { alt: '' });
              };
              reader.readAsDataURL(file);
            };

            input.click();
          },
          setup: function (editor) {
            editor.on('init', function () {
              this.setContent(contentData);
            });
          },
          plugins: [
            'advlist autolink lists link image imagetools charmap print preview anchor textcolor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table contextmenu code wordcount'
          ],
          toolbar: 'fontselect | fontsizeselect | formatselect | bold italic | bullist numlist outdent indent'
        }}
        onBlur={(e) => this.handleEditorChange(e)}
      />
    )
  }
}

export default EmailEditor