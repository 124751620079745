import React from 'react';
import { connect } from 'react-redux';
import '../../assets/css/settings.scss';
import Styles from '../../assets/css/Styles';
import '../../assets/css/profileStyle.scss';
import Button from '@material-ui/core/Button';
import { CssBaseline } from '@material-ui/core';
import {urlRegEx} from '../../../static/constants';
import { withRouter, Link } from 'react-router-dom';
import { scrollbar } from '../../../static/constants'
import { Scrollbars } from 'react-custom-scrollbars';
import { withStyles } from '@material-ui/core/styles';
import FlashMessage from '../../common/FlashMessage';
import {CustomDomain, SaveIcon} from '../../../common/imageList';
import CircularProgress from '@material-ui/core/CircularProgress';
import {SUBDOMAIN_SUCCESSFULLY_SAVED} from '../../common/constants';
import { getSubDomain, editSubDomain,setSettingsFlashMessage,toggleSettingsFlashMessage } from '../../actions/actionMethods';
import {LinearProgressBar, StyledTextField, StyledCard, StyledTypography, BreadCrumbs, Image} from '../../common/materialComponents';
class SubDomain extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess=true
        this.state = {
            trackingPage : '',
            subDomain : '',
            isSubDomainError: false,
            isTrackingPageError: false,
            showLoader: false,
            showSaveLoader: false,
            isValidURL:true,
            noLoading : false,
        }
    }
    handleSubmit = () => {
        if (this.state.subDomain !== '' && this.state.isValidURL === true) {
            this.setState({
                showSaveLoader:true
            })
            let requestParams = {}
            requestParams.sub_domain = this.state.subDomain
            requestParams.custom_domain_url = this.state.trackingPage
            editSubDomain(requestParams).then(res => {
                
                this.setState({
                    showSaveLoader:false
                })
                
                if (res && res.data) {
                    if(res.data.meta && res.data.meta.code === 400){
                        this.isSuccess = false
                        this.props.toggleSettingsFlashMessage(1)
                        this.props.setSettingsFlashMessage(res.data.meta.message[0])
                    }else {
                        localStorage.setItem('sub_domain', res.data.sub_domain)
                        localStorage.setItem('custom_domain_url', res.data.custom_domain_url)
                        this.isSuccess = true
                        this.props.toggleSettingsFlashMessage(1)
                        this.props.setSettingsFlashMessage(SUBDOMAIN_SUCCESSFULLY_SAVED)
                    }
                }
            })
        }
    }
    toggleFlashMessage = (data) => {
        this.props.toggleSettingsFlashMessage(data)
        this.props.setSettingsFlashMessage('')
    }
    
    onChangeSubDomain = (value) => {
        this.setState({
            subDomain: value.replace(/[^a-zA-Z0-9_-]/g, "").toLowerCase(),
            isSubDomainError: false
        })
    }
    onChangeTrackingPage = (value) => {
        this.setState({
            trackingPage: value,
            isTrackingPageError: false
        })
    }
    
    checkSubDomain = () => {
        if (this.state.subDomain === '') {
            this.setState({
                isSubDomainError: true
            })
        }
    }
    checkTrackingPage = () => {
        if (this.state.trackingPage != '') {
            if (urlRegEx.test(this.state.trackingPage) === false) {
                this.setState({
                    isValidURL: false
                })
            }
            else {
                this.setState({
                    isValidURL: true
                })
            }
        }
    }
    componentWillMount() {
        getSubDomain().then(res => {
            if (res.data) {
                this.setState({
                    subDomain : res.data.sub_domain.toLowerCase(),
                    trackingPage : (res.data.custom_domain_url) ? res.data.custom_domain_url : '',
                    noLoading : true,
                })
            }
        })
    }
    
    render() {
        const {classes} = this.props
        return (
            <React.Fragment>
            <CssBaseline/>
            {
              this.state.subDomain || this.state.noLoading ?
              <div className="settingsModule">
               <StyledCard>
                    <BreadCrumbs aria-label="breadcrumb">
                        <Link className={classes.link} to="/settings">
                            Settings
                        </Link>
                        <StyledTypography className={classes.text}>Custom Domain</StyledTypography>     
                    </BreadCrumbs>
               </StyledCard>
             <div className="dataContainer">
                 <div className="containerHeader">
                     <Image alt="customDomain_IC" className={classes.avatar} src={CustomDomain} />
                         <div className="containerTitle" style={{width: '149px'}}>Custom Domain</div>
                 </div>
                 <div id="settingDataScrollerWrapper">
                     <div className="settingsContainer">
                         <div className="settingsRightSidePanel">
                             <div className="commonSettingsBox profileRightSideMainDiv">
                                 <Scrollbars autoHide style={scrollbar}>
                                    
                                             <div className="profileSettingsContentContainer" style={{paddingLeft: "32px", paddingRight: '32px'}}>
                                                 <form>
                                                     <div>
                                                         <StyledTextField
                                                             error={this.state.isSubDomainError ? true : false}
                                                             id="organization-name"
                                                             label={`Subdomain - ${this.state.subDomain}.trackhive.co`}
                                                             variant="outlined"
                                                             helperText={"Note : Space and special characters are not allowed."}
                                                             className={classes.textField}
                                                             value={this.state.subDomain}
                                                             onBlur={(e) => this.checkSubDomain(e)}
                                                             onChange={(e) => this.onChangeSubDomain(e.target.value)}
                                                          />                                                            
                                                     </div>
                                                     <div style={{marginTop:'22px'}}>
                                                       <Button 
                                                          onClick={() => this.handleSubmit()}
                                                          startIcon={<SaveIcon/>} 
                                                          disabled={this.state.showSaveLoader}
                                                          className={(this.state.showSaveLoader) ? classes.subDomainSaveBtnDisable : classes.subDomainSaveBtn}
                                                         >
                                                           Save
                                                        </Button>
                                                       {(this.state.showSaveLoader) && <CircularProgress size={22} className={classes.subDomainButtonProgress} />}
                                                    </div>
                                                 </form>
                                             </div>
                                 </Scrollbars>
                             </div>
                         </div>
                     </div>
                 </div>
                 {this.props.showSettingFlashMessageFlag === 1 ?
                     <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                     : ''}
             </div>
             {/* dataContainer close */}
           </div> : 
           <div>
               <LinearProgressBar/>
           </div>
          }
         </React.Fragment>
            // Settings Module Close
        )
    }
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, { getSubDomain, editSubDomain ,setSettingsFlashMessage,toggleSettingsFlashMessage })(withStyles(Styles)(SubDomain)));