
import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import { getInstance } from '../../../config/token';
import {STORE_ACTIVATED_MESSAGE, STORE_DEACTIVATED_MESSAGE} from '../../common/constants'
import {StyledCard,BreadCrumbs,StyledTypography } from '../../common/materialComponents';

export default class BreadCrumbsCard extends React.Component{
    
    toggleSwitch = (event, toggleState) => {
        let id = this.props.storeId
        let newToggleState = ''
        if (toggleState) {
            newToggleState = 0
        }
        else {
            newToggleState = 1
        }
        let requestParams = { active: newToggleState }
        this.setState({active: newToggleState})
        this.props.activeStore(newToggleState)
        let config = getInstance('PUT', `/stores/activation/${id}`, requestParams)
        axios(config).then(res => {
            let updateSuccessData = {}
            updateSuccessData.isSuccess = true
            updateSuccessData.success = true
            updateSuccessData.error = false
            updateSuccessData.successErrMsg = (newToggleState) ? STORE_ACTIVATED_MESSAGE : STORE_DEACTIVATED_MESSAGE
            this.props.updateToggleMessage(updateSuccessData)
        }).catch(err => {
            this.setState({active: toggleState})
            this.props.activeStore(newToggleState)
           
            this.isSuccess = false
            if (err.response && err.response.status) {
                if (err.response.data && err.response.data.meta && err.response.data.meta.code === 400){
                    let updateErrorData = {}
                    updateErrorData.isSuccess = false
                    updateErrorData.error = true
                    updateErrorData.successErrMsg = err.response.data.meta.message[0]
                    updateErrorData.showSaveLoader = false
                    updateErrorData.success = false
                    this.props.updateToggleMessage(updateErrorData)
                }   
                 
            }
        })
    }
    
    render(){
        const {classes} = this.props
        return(
            <React.Fragment>
            <StyledCard className={classes.root}>
            <BreadCrumbs aria-label="breadcrumb">
                {this.props.storeId === undefined ?
                    <Link className={classes.link} to="/integrations">
                        Integration
                    </Link> :
                    <Link className={classes.link} to="/integrated_list">
                        Manage Integration
                    </Link>
                }
                <StyledTypography className={classes.text}>{this.props.storeName}</StyledTypography>
                {this.props.storeId !== undefined ? <div className="integrationSwitch">
                    <Switch
                        size="medium"
                        checked={(this.props.active === 1) ? true : false}
                        onChange={(e) => this.toggleSwitch(e, this.props.active)}
                        name="checkedB"
                        color="primary"
                    />
                </div> : null}
            </BreadCrumbs>
        </StyledCard>
        </React.Fragment>
        )
    }
}