import React from 'react';
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { getCourierLabel, showCourierLogo } from '../../common/commonFunction'
import Styles from '../static/Styles'

class AGGridTATDays extends React.Component {
    render() {
        const { classes } = this.props
        return (
            <TableContainer className={classes.container}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow className={classes.headTableRow}>
                            <TableCell className={classes.headTableCell} style={{ width: '15%', paddingLeft: '16px' }}>Courier Name</TableCell>
                            <TableCell className={classes.headTableCell} align="left" style={{ width: '5%', }}>Total</TableCell>
                            <TableCell className={classes.headTableCell} align="left" style={{ width: '5%', }}>Day 0</TableCell>
                            <TableCell className={classes.headTableCell} align="left" style={{ width: '5%', }}>Day 1</TableCell>
                            <TableCell className={classes.headTableCell} align="left" style={{ width: '5%', }}>Day 2</TableCell>
                            <TableCell className={classes.headTableCell} align="left" style={{ width: '5%', }}>Day 3</TableCell>
                            <TableCell className={classes.headTableCell} align="left" style={{ width: '5%', }}>Day 4</TableCell>
                            <TableCell className={classes.headTableCell} align="left" style={{ width: '5%', }}>Day 5</TableCell>
                            <TableCell className={classes.headTableCell} align="left" style={{ width: '5%', }}>Day 6</TableCell>
                            <TableCell className={classes.headTableCell} align="left" style={{ width: '5%', }}>Day 7</TableCell>
                            <TableCell className={classes.headTableCell} align="left" style={{ width: '5%', }}>Day 8</TableCell>
                            <TableCell className={classes.headTableCell} align="left" style={{ width: '5%', }}>Day 9</TableCell>
                            <TableCell className={classes.headTableCell} align="left" style={{ width: '5%', }}>Day 10 & More</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.rowData.map((tatData, index) => {
                            return (<TableRow
                                className={classes.bodyTableRow}
                                key={index}>
                                <TableCell style={{ width: '15%', paddingRight: '16px', paddingBottom: '9px', paddingTop: '9px' }} component="th" scope="row">
                                    <div className={classes.TATCourierNameDiv}>
                                        <img src={showCourierLogo(tatData.courier_name)} alt={tatData.courier_name}></img>
                                        <span>{getCourierLabel(tatData.courier_name, this.props.addedCourierList)}</span>
                                    </div>
                                </TableCell>
                                <TableCell align="left" style={{ width: '5%', }} className={classes.bodyTableCell}>{tatData.total}</TableCell>
                                <TableCell align="left" style={{ width: '5%', }} className={classes.bodyTableCell}>{tatData.zero_day}</TableCell>
                                <TableCell align="left" style={{ width: '5%', }} className={classes.bodyTableCell}>{tatData.one_day}</TableCell>
                                <TableCell align="left" style={{ width: '5%', }} className={classes.bodyTableCell}>{tatData.two_days}</TableCell>
                                <TableCell align="left" style={{ width: '5%', }} className={classes.bodyTableCell}>{tatData.three_days}</TableCell>
                                <TableCell align="left" style={{ width: '5%', }} className={classes.bodyTableCell}>{tatData.four_days}</TableCell>
                                <TableCell align="left" style={{ width: '5%', }} className={classes.bodyTableCell}>{tatData.five_days}</TableCell>
                                <TableCell align="left" style={{ width: '5%', }} className={classes.bodyTableCell}>{tatData.six_days}</TableCell>
                                <TableCell align="left" style={{ width: '5%', }} className={classes.bodyTableCell}>{tatData.seven_days}</TableCell>
                                <TableCell align="left" style={{ width: '5%', }} className={classes.bodyTableCell}>{tatData.eight_days}</TableCell>
                                <TableCell align="left" style={{ width: '5%', }} className={classes.bodyTableCell}>{tatData.nine_days}</TableCell>
                                <TableCell align="left" style={{ width: '5%', }} className={classes.bodyTableCell}>{tatData.more_than_nine_days}</TableCell>
                            </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}


export default withRouter(withStyles(Styles)(AGGridTATDays));