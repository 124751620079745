import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { signUp, clearError, storeSignup } from './auth'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlashMessage from '../../src/TrackingList/common/flashMessage'
import MediaContent from '../common/userMediaCard'
import Styles from './Styles'
import {getRedirectURLParameterByName, emailRegex, passwordRegex} from '../static/constants'

const maxPwdLength = 6
let passwordErrorMsg = ''

class UserSignup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isError: false,
            errorMessage: '',
            showLoader: false,
            password: '',
            ispasswordError: false,
            email: '',
            isEmailError: false,
            name: '',
            isNameError: false,
            openSnackBar: true
        }
    }

    componentDidMount() {
        this.props.clearError()
        let shopifyStoreDetails = JSON.parse(localStorage.getItem("ShopifyStoreData"))
        if (shopifyStoreDetails !== undefined && shopifyStoreDetails !== null) {
            this.setStoreData(shopifyStoreDetails)
        }

        let bigCommerceStoreDetails = JSON.parse(localStorage.getItem("BigCommerceStoreData"))
        if (bigCommerceStoreDetails !== undefined && bigCommerceStoreDetails !== null) {
            this.setStoreData(bigCommerceStoreDetails)
        }
    }
    setStoreData = (storeDetails) => {
        let data = { first_name: storeDetails.shop.name, email: storeDetails.shop.email }
        this.setState({
            data
        })
    }
    onSubmit = () => {
        let requestParams = {}
        requestParams.email = this.state.email
        requestParams.password = this.state.password
        requestParams.name = this.state.name
        requestParams.source = "Web"
        let isEmail = this.checkEmail(requestParams.email)
        let isName = this.checkName(requestParams.name)
        let isPassword = this.checkPassword(requestParams.password)

        if (isEmail && isName && isPassword) {
            this.setState({
                showLoader: true
            })
            this.setState({ openSnackBar: true })
            let val = getRedirectURLParameterByName('utm_source')
            if (val !== '' && val === 'shopify') {
                requestParams.source = "shopify"
                this.props.storeSignup(requestParams, status => {

                    if (status) {
                        this.setState({
                            showLoader: false
                        })
                        this.props.history.push('/integrated_list');
                    }
                    else {
                        this.setState({
                            showLoader: false
                        })
                    }
                })
            }
            else if (val !== '' && val === 'bigcommerce') {
                requestParams.source = "bigcommerce"
                this.props.storeSignup(requestParams, status => {

                    if (status) {
                        this.setState({
                            showLoader: false
                        })
                        this.props.history.push('/integrated_list');
                    }
                    else {
                        this.setState({
                            showLoader: false
                        })
                    }
                })
            }
            else {
                this.props.signUp(requestParams, status => {

                    if (status) {
                        this.setState({
                            showLoader: false
                        })
                        this.props.history.push('/dashboard');
                    }
                    else {
                        this.setState({
                            showLoader: false
                        })
                    }
                })
            }
        }

    }

    handlePasswordChange = (event) => {
        let val = event.target.value
        this.setState({
            password: val,
        })
        this.checkPassword(val)
    }

    checkPassword = (val) => {
        if (!passwordRegex.test(val)) {
            this.setState({ ispasswordError: true })
            passwordErrorMsg = 'Password must contain alphabets and numbers'
            return false
        } else if (val.length < maxPwdLength) {
            this.setState({ ispasswordError: true })
            passwordErrorMsg = 'Password must be minimum 6 characters long'
            return false
        } else {
            this.setState({ ispasswordError: false })
            passwordErrorMsg = ''
            return true
        }
    }

    handleEmailChange = (event) => {
        let val = event.target.value
        this.setState({ email: val })
        this.checkEmail(val)
    }

    checkEmail = (val) => {
        if (!emailRegex.test(val)) {
            this.setState({ isEmailError: true })
            return false
        } else {
            this.setState({ isEmailError: false })
            return true
        }
    }

    handleNameChange = (event) => {
        let val = event.target.value
        this.setState({ name: val })
        this.checkName(val)
    }

    checkName = (val) => {
        if (val === '') {
            this.setState({ isNameError: true })
            return false
        } else {
            this.setState({ isNameError: false })
            return true
        }
    }

    handleLogin = () => {
        let val = getRedirectURLParameterByName('utm_source')
        if((val !== '' && val === 'shopify') ||  (val !== '' && val === 'bigcommerce')) {
            this.props.history.push(`/login?utm_source=${val}`)
        } else {
            this.props.history.push('/login')
        }
    }

    closeFlashMessage = () => {
        this.setState({ openSnackBar: false })
        this.props.clearError()
    }

    onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            this.onSubmit();
        }
    }

    render() {
        const { classes } = this.props;
        const { from } = this.props.location.state || {
            from: {
                pathname: '/'
            }
        }

        const { onboarding } = this.props.location.state || {
            onboarding: {
                pathname: '/onboarding'
            }
        }

        if (this.props.isLoggedIn) {
            var onboarding_val = JSON
                .parse(localStorage.getItem('trackhiveToken'))
                .onboarding
                ? JSON
                    .parse(localStorage.getItem('trackhiveToken'))
                    .onboarding
                    .status
                : 0;

            if (from && from.pathname === "/admin/oauth/request_grant")
                return <Redirect to={from.pathname} />
            else if (onboarding_val)
                return <Redirect to={onboarding} />
            else
                return <Redirect to={from} />
        }
        return (
            <div className="loginAndRegistrationCommonDiv">
                <Card className={classes.rootCustomCss} variant="outlined">
                    <MediaContent content="Create an account" />
                    <TextField
                        error={this.state.isNameError ? true : false}
                        id="name"
                        label="Name"
                        variant="outlined"
                        className={`${classes.signUpTextField} ${classes.onFocus}`}
                        value={this.state.name}
                        style={{ marginTop: "32px" }}
                        onChange={this.handleNameChange}
                        onKeyDown={this.onEnterPress}
                    />
                    <TextField
                        error={this.state.isEmailError ? true : false}
                        id="email"
                        label="Email"
                        variant="outlined"
                        className={`${classes.signUpTextField} ${classes.onFocus}`}
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        helperText={this.state.isEmailError && this.state.email !== '' ? 'Email must be valid' : ''}
                        onKeyDown={this.onEnterPress}
                    />
                    <TextField
                        error={this.state.ispasswordError ? true : false}
                        id="password"
                        label="Enter Your Password"
                        variant="outlined"
                        type="password"
                        className={`${classes.signUpTextField} ${classes.onFocus}`}
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        helperText={this.state.ispasswordError && this.state.password !== '' ? passwordErrorMsg : ''}
                        onKeyDown={this.onEnterPress}
                    />
                    <CardActions className={classes.nextButtonRoot}>
                        <div className="leftBtnDiv">
                            <Button
                                onClick={this.handleLogin}
                                disabled={this.state.showLoader}
                                className={classes.forgotBtn}
                                color="primary"
                            >
                                Already registered? Login
                            </Button>
                        </div>
                        <div className="rightBtnDiv">
                            <Button
                                variant="contained"
                                className={classes.saveBtn}
                                disabled={this.state.showLoader}
                                onClick={this.onSubmit}
                            >
                                Sign Up
                            </Button>
                        </div>
                        {this.state.showLoader && <CircularProgress size={22} className={classes.buttonProgress} />}
                    </CardActions>
                </Card>
                {this.props.errorMessage && this.props.errorMessage !== '' ?
                    <FlashMessage open={this.state.openSnackBar} closeFlash={this.closeFlashMessage} classes={classes.addTrackingSnackbar} messageDetails={{ type: 'ERROR', message: this.props.errorMessage }} />
                    : ''}
            </div>
        )
    }
}
const mapStateToProps = state => ({ isLoggedIn: state.authenticate.isLoggedIn, errorMessage: state.authenticate.loginError })
export default withRouter(connect(mapStateToProps, { signUp, clearError, storeSignup })(withStyles(Styles)(UserSignup)));
