import React from 'react'
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute'
//Authenticate route
import SignupComponent from '../Authentication/signup'
import LoginComponent from '../Authentication/login'
import ForgotPasswordComponent from '../Authentication/forgotPassword'
import ResetPasswordComponent from '../Authentication/resetPassword'
import ShopifyToOH from '../Setup/components/shopifyToOH'
import shopifyToTrackhiveToken from '../Setup/components/shopifyToTrackhiveToken'
import BigCommerceToTrackhive from '../Setup/components/bigCommerceToTrackhive'
import LoadBigCommerceApp from '../Setup/components/loadBigCommerceApp'
//Users route
import Users from '../Authentication/users'
import UserLogin from '../Authentication/userLogin'
//Dashboard route
import DashboardComponent from '../Dashboard/components/dashboard'
//tracking shipment route
import TrackingListComponent from '../TrackingList'
import AutomationComponent from '../Automation/components/list/index'
//settings route
import Profile from '../Setup/components/profile'
import Webhook from '../Setup/components/webhook'
import DeveloperApps from '../Setup/components/developerApps'
import Organization from '../Setup/components/organization'
import EmailSettings from '../Setup/components/email/emailSettings'
import DomainSettings from '../Setup/components/email/emailDomains'
import CustomEmailTemplate from '../Setup/components/email/custom_email_template'
import SMSSettings from '../Setup/components/sms/smsSettings'
import CustomSMSTemplate from '../Setup/components/sms/custom_sms_template'
import Integrations from '../Setup/components/integrations/integrations'
import ShopifyStoreIntegration from '../Setup/components/integrations/stores/shopify/shopifyIntegration'
import BigCommerceIntegration from '../Setup/components/integrations/stores/bigcommerce/bigCommerceIntegration'
import AustraliaPost from '../Setup/components/integrations/stores/australiaPost/AusPost'
import WooCommerce from '../Setup/components/integrations/stores/woocommerce/WooCommerce'
import Magento from '../Setup/components/integrations/stores/magento/Magento'
import BlueDart from '../Setup/components/integrations/stores/bluedart/BlueDart'
import CustomTrackingPage from '../Setup/components/customTrackingPage/CustomTrackingPage'
import pageNotFound from './PageNotFound'
import TrackhivePublicPage from '../Setup/components/customTrackingPage/TrackingPublicPage'
import TrackingPageWithSearch from '../Setup/components/customTrackingPage/TrackingPageWithSearch'
import TrackButton from '../Setup/components/customTrackingPage/TrackButton'
import TrackButtonList  from '../Setup/components/trackButton/TrackingButtonCards'
import TrackingButtonShopify from '../Setup/components/trackButton/trackButtonShopify'
import SubDomain from '../Setup/components/subdomain/subdomain'
import OnBoarding from '../Setup/components/onboarding/index'
import EmailAlert from '../Setup/components/emailAlerts'
import SubUserLogin from '../../src/Authentication/subUserLogin'
import Tags from '../Setup/components/tags'
import Settings from '../Setup/components/settings'
import UserAndRolesTab from '../Setup/components/subUser/index'
import TrackingButtonCustomStore from '../Setup/components/trackButton/TrackButtonCustomStore'
import Cdl from '../Setup/components/integrations/stores/cdl/Cdl'
import NinjaVan from '../Setup/components/integrations/stores/ninjaVan/NinjaVan'
import GwWorld from '../Setup/components/integrations/stores/gwWorld/GwWorld'
import NinetyNineMinutos from '../Setup/components/integrations/stores/99minutos/99minutos'
const MainRoutes = () => (
    // Routes
    <Switch>
        <Route exact path="/login" component={LoginComponent} />
        <Route exact path="/signup" component={SignupComponent} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/userLogin" component={UserLogin} />
        <Route exact path="/forgotPassword" component={ForgotPasswordComponent} />
        <Route exact path="/accept_invite" component={SubUserLogin} />
        <Route exact path="/reset_password" component={ResetPasswordComponent} />
        <Route exact path="/shopifyApp/install" component={ShopifyToOH} />
        <Route exact path="/shopify_to_trackhive_token" component={shopifyToTrackhiveToken} />
        <Route exact path="/bigCommerceApp/install" component={BigCommerceToTrackhive} />
        <Route exact path="/bigCommerceApp/load" component={LoadBigCommerceApp} />
        <Route exact path="/trackingPage" component={TrackingPageWithSearch} />
        <Route exact path="/trackingPage/" component={TrackingPageWithSearch} />
        <Route exact path="/trackingButton" component={TrackButton} />
        <Route exact path="/trackingPage/:tracking_number" component={TrackhivePublicPage} />
        <PrivateRoute title="Settings" allowedRole="settingMainMenu" exact path="/settings" component={Settings}/>
        <PrivateRoute title="Dashboard" allowedRole ="dashboard" exact path="/dashboard" component={DashboardComponent} />
        <PrivateRoute title="Tracking" allowedRole="tracking" exact path="/tracking/add" component={TrackingListComponent} />
        <PrivateRoute title="Tracking" allowedRole="tracking" exact path="/tracking/import" component={TrackingListComponent} />
        <PrivateRoute title="Tracking" allowedRole="tracking" exact path="/tracking/export" component={TrackingListComponent} />
        <PrivateRoute title="Tracking" allowedRole="tracking" exact path="/tracking/edit/:id" component={TrackingListComponent} />
        <PrivateRoute title="Tracking" allowedRole="tracking" exact path="/tracking/:id" component={TrackingListComponent} />
        <PrivateRoute title="Tracking" allowedRole="tracking" exact path="/tracking" component={TrackingListComponent} />
        <PrivateRoute title="Webhook" allowedRole="settings" exact path="/webhook" component={Webhook} />
        <PrivateRoute title="API Keys" allowedRole="settings" exact path="/developer_apps" component={DeveloperApps} />
        <PrivateRoute title="Organization" allowedRole="settings" exact path="/organization" component={Organization} />
        <PrivateRoute title="Profile" allowedRole="settings" exact path="/settings_profile" component={Profile} />
        <PrivateRoute title="Email Templates" allowedRole="settings" exact path="/settings_email" component={EmailSettings} />
        <PrivateRoute title="Email Domains" allowedRole="settings" exact path="/settings_domain" component={DomainSettings} />
        <PrivateRoute title="Email Templates" allowedRole="settings" exact path="/custom_email_template" component={CustomEmailTemplate} />
        <PrivateRoute title="Email Templates" allowedRole="settings" exact path="/copy_email_template/:id" component={CustomEmailTemplate} />
        <PrivateRoute title="Email Templates" allowedRole="settings" exact path="/edit_email_template/:id" component={CustomEmailTemplate} />
        <PrivateRoute title="Settings" allowedRole="settings" exact path="/settings_sms" component={SMSSettings} />
        <PrivateRoute title="Settings" allowedRole="settings" exact path="/custom_sms_template" component={CustomSMSTemplate} />
        <PrivateRoute title="Settings" allowedRole="settings" exact path="/copy_sms_template/:id" component={CustomSMSTemplate} />
        <PrivateRoute title="Settings" allowedRole="settings" exact path="/edit_sms_template/:id" component={CustomSMSTemplate} />
        <PrivateRoute title="Integration" allowedRole="settings" exact path="/integrations" component={Integrations} />
        <PrivateRoute title="Shopify" allowedRole="settings" exact path="/add_shopify" component={ShopifyStoreIntegration} />
        <PrivateRoute title="Settings" allowedRole="settings" exact path="/add_bigcommerce_step1" component={BigCommerceIntegration} />
        <PrivateRoute title="WooCommerce" allowedRole="settings" exact path="/add_wooCommerce" component={WooCommerce} />
        <PrivateRoute title="WooCommerce" allowedRole="settings" exact path="/edit_wooCommerce/:id" component={WooCommerce} />
        <PrivateRoute title="Australia Post" allowedRole="settings" exact path="/add_auspost" component={AustraliaPost} />
        <PrivateRoute title="Australia Post" allowedRole="settings" exact path="/edit_auspost/:id" component={AustraliaPost} />
        <PrivateRoute title="Magento" allowedRole="settings" exact path="/add_magento" component={Magento} />
        <PrivateRoute title="Magento" allowedRole="settings" exact path="/edit_magento/:id" component={Magento} />
        <PrivateRoute title="Bluedart" allowedRole="settings" exact path="/add_bluedart" component={BlueDart} />
        <PrivateRoute title="Bluedart" allowedRole="settings" exact path="/edit_bluedart/:id" component={BlueDart} />
        <PrivateRoute title="CDL Last Mile" allowedRole="settings" exact path="/add_cdl" component={Cdl} />
        <PrivateRoute title="CDL Last Mile" allowedRole="settings" exact path="/edit_cdl/:id" component={Cdl} />
        <PrivateRoute title="Dashboard" allowedRole="dashboard" exact path='/' component={DashboardComponent} />
        <PrivateRoute title="Integration" allowedRole="settings" exact path="/integrated_list" component={Integrations} />
        <PrivateRoute title="Sub Domain" allowedRole="settings" exact path="/custom_domain" component={SubDomain} />
        <PrivateRoute title="OnBoarding" allowedRole="settings" exact path="/onboarding" component={OnBoarding} />
        <PrivateRoute title="Automation" allowedRole="automations" exact path='/automations' component={AutomationComponent} />
        <PrivateRoute title="Automation" allowedRole="automations" exact path="/automations/add" component={AutomationComponent} />
        <PrivateRoute title="Automation" allowedRole="automations" exact path="/automations/:id" component={AutomationComponent} />
        <PrivateRoute title="Automation" allowedRole="automations" exact path="/automations/edit/:id" component={AutomationComponent} />        
        <PrivateRoute title="Automation" allowedRole="automations" exact path="/automations/:id/logs" component={AutomationComponent} />
        <PrivateRoute title="Track Page" allowedRole="settings" exact path='/custom_tracking_page/create' component={CustomTrackingPage} />
        <PrivateRoute title="Track Page" allowedRole="settings" exact path='/custom_tracking_page/preview' component={CustomTrackingPage} />
        <PrivateRoute title="Track Page" allowedRole="settings" exact path='/custom_tracking_page/preview/:id' component={CustomTrackingPage} />
        <PrivateRoute title="Track Page" allowedRole="settings" exact path='/custom_tracking_page/edit/:id' component={CustomTrackingPage} />
        <PrivateRoute title="Track Page" allowedRole="settings" exact path='/custom_tracking_page/copy/:id' component={CustomTrackingPage} />
        <PrivateRoute title="Track Page" allowedRole="settings" exact path='/custom_tracking_page' component={CustomTrackingPage} />
        <PrivateRoute title="Track Button" allowedRole="settings" exact path='/track_button' component={TrackButtonList} />
        <PrivateRoute title="Track Button" allowedRole="settings" exact path='/track_button_shopify' component={TrackingButtonShopify}/>
        <PrivateRoute title="Track Button" allowedRole="settings" exact path='/track_button_custom' component={TrackingButtonCustomStore}/>
        <PrivateRoute title="Email Alerts" allowedRole="settings" exact path='/email_alerts' component={EmailAlert} />
        <PrivateRoute title="Users & Roles" allowedRole="settings" exact path='/sub_users' component={UserAndRolesTab} />
        <PrivateRoute title="Tags" allowedRole="settings" exact path="/tags" component={Tags} />     
        <PrivateRoute title="Ninja Van" allowedRole="settings" exact path="/add_ninja_van" component={NinjaVan} />
        <PrivateRoute title="Ninja Van" allowedRole="settings" exact path="/edit_ninja_van/:id" component={NinjaVan} />  
        <PrivateRoute title="Gw World" allowedRole="settings" exact path="/add_gw_world" component={GwWorld} />
        <PrivateRoute title="Gw World" allowedRole="settings" exact path="/edit_gw_world/:id" component={GwWorld} />  
        <PrivateRoute title="99minutos" allowedRole="settings" exact path="/add_99minutos" component={NinetyNineMinutos} />
        <PrivateRoute title="99minutos" allowedRole="settings" exact path="/edit_99minutos/:id" component={NinetyNineMinutos} />  
        <Route component={pageNotFound} />
    </Switch>
)
export default MainRoutes
