const Styles = theme => ({
    autocomplete: {
        "& fieldset.MuiOutlinedInput-notchedOutline": {
            borderColor: "#767676"
        },
        "& .MuiAutocomplete-inputRoot": {
            flexWrap: 'nowrap'
        }
    },
    select: {
        width: '140px',
        height: '44px',
        borderRadius: '3px',
        boxShadow: 'solid 1px #767676',
        backgroundColor: '#ffffff'
    },
    text: {
        width: '240px',
        "& label.Mui-focused": {
            color: '#1a73e8'
        },
        "& .MuiOutlinedInput-root": {
            height: "50px",
            "&.Mui-focused fieldset": {
                borderColor: '#1a73e8'
            }
        },
        "& .MuiFormLabel-root": {
            lineHeight: '0.7'
        }
    },
    rightPopOverStyle: {
        "& .MuiPopover-paper": {
            marginLeft: -15
        }
    }, dateText: {
        fontFamily: 'Roboto',
        fontSize: '15px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.15px',
        color: '#767676',
        textDecoration: 'none',
        cursor: 'pointer',
        "&:hover": {
            background: '#BFE4FF',
        }
    },
    customDate: {
        padding: '4px 2px',
        color: '#fff',
        backgroundColor: '#31a5f7',
        textAlign: 'center',
        borderRadius: '4px',
        fontWeight: 300,
        lineHeight: '1.5rem',
        cursor: 'pointer'
    },
    progress: {
        color: "primary",
        marginLeft: '50%'
    },
    leftPopOverStyle: {
		"& .MuiPopover-paper": {
			marginLeft: -130
		}
    },
    table: {
        width: '100%',
        maxHeight: '350px'
    },
    headTableCell: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px',
        color: '#212121',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        verticalAlign: 'middle',
        paddingRight: '16px',
        paddingLeft: '0px',
        paddingTop: '6px',
        paddingBottom: '6px',
        backgroundColor: '#ffffff',
    },
    bodyTableCell: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px',
        color: '#212121',
        verticalAlign: 'middle',
        //padding: '16px 16px 16px 0px',
        paddingRight: '16px',
        paddingLeft: '0px',
        paddingTop: '9px',
        paddingBottom: '9px'
    },
    TATCourierNameDiv: {
        "& img": {
            width: "30px",
            height: "30px",
            verticalAlign: "middle",
        },
        "& span": {
            verticalAlign: 'middle',
            marginLeft: '0.5rem',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 500,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.14px',
            color: '#212121'
        }
    },
    container: {
        width: 'auto',
        maxHeight: '350px',
        borderRadius: '4px',
        boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.2)',
        backgroundColor: '#ffffff',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
            utline: '1px solid slategrey'
        },
        '&::-webkit-scrollbar-track-piece:horizontal:start': {
            marginLeft: '325px'
        }
    },
    headTableRow: {
        height: '48px'
    },
    bodyTableRow: {
        height: '50px'
    },
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        boxShadow: 'none'
    }
})
export default Styles