import React from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import {searchPattern} from '../../static/constants'

class LoadBigCommerceApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader:true
    }
  }

  async componentDidMount() {
    
      var urlParams;
      (window.onpopstate = function () { // To get all query params in an Obj form
          var match,
              pl     = /\+/g,  // Regex for replacing addition symbol with a space
              decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
              query  = window.location.search.substring(1);
  
          urlParams = {};
          while (match = searchPattern.exec(query))
             urlParams[decode(match[1])] = decode(match[2]);
      })();        
      let postObject = {
          "signed_payload" : urlParams.signed_payload       
      }
      let redirect_uri = process.env.REACT_APP_API_URL + '/load_bigcommerce_store'     
      axios.post(redirect_uri,postObject).then(response => {           
          if(response.data && response.data.meta.message === "SUCCESS"){
            this.setState({loader:false})
            this.props.history.push('/dashboard')
          }       
          else{
            this.setState({loader:false})
          }       
      }).catch(err => {
          
      })   
  }

  render() {   
    return (
      <div id="data_container" className="main_view setup_view">
      {this.state.loader ? <div>Redirecting to Trackhive...</div> : <div>Unauthorized User...</div>}
      </div>

    );
  }
}
export default withRouter(LoadBigCommerceApp);
