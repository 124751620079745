import {shopifyIcon, customStoreIcon} from '../../../common/imageList'

export const trackingButtonMenu = [{
            title: "Shopify",
            path: "/track_button_shopify",
            icon: shopifyIcon
        },
        // {
        //     title: "WooCommerce",
        //     path: "/track_button_woocommerce",
        //     icon: wooCommerceIcon
        // },
        // {
        //     title: "Magento",
        //     path: "/track_button_magento",
        //     icon: magentoIcon
        // },
        {
            title: "Custom Store",
            path: "/track_button_custom",
            icon: customStoreIcon
        }
]