import { combineReducers } from "redux"


import setupList from './index'
import loading from '../../TrackingList/reducer/loadingReducer'

const setupRootReducer = combineReducers({
 list:setupList,
 loading
});

export default setupRootReducer;
