import axios from 'axios';
import * as types from '../constants/actionTypes';
import {
  startLoading,
  endLoading
} from './loadingActions';
import { getInstance } from '../../config/token';
const prefix = ''
function handleException(error) {
  if (error.response && error.response.status) {
    return {
      status: error.response.status,
      data: error.response.data
    }
  } else {
    return {
      status: 500
    }
  }
}
export function returnData(type, data, status) {
  
  if (status >= 200 && status <= 299) {
      return {
          type: type,
          data: data
      }
  } else {
      console.log('error');
  }
}
export function setAutomationCondition(data) {
  return dispatch => {
    dispatch({
      type: data.type,
      data: {
        key: data.index,
        value: data.value
      }
    })
  }
}
export function clearAllCondition() {
  return dispatch => {
    dispatch({
      type: types.CLEAR_CONDITION_VALUE,
      data: {}
    })
  }
}
export function clearAllAction() {
  return dispatch => {
    dispatch({
      type: types.CLEAR_ALL_ACTION,
      data: {}
    })
  }
}

export function setLogicalOperator(operator) {
  return dispatch => {
    dispatch({
      type: types.SET_LOGICAL_OPERATOR,
      data: operator
    })
  }
}
export function removeAutomationConditionByKey(data) {
  return dispatch => {
    dispatch({
      type: types.CLEAR_CONDITION_BY_KEY,
      data: {
        key: data.index,
        value: data.value
      }
    })
  }
}
export function setStatusList(data) {
  return dispatch => {
    dispatch({
      type: types.STATUS_LIST,
      data: data
    })
  }
}
export function setDeliveryTimeList(data) {
  return dispatch => {
    dispatch({
      type: types.DELIVERY_TIME_LIST,
      data: data
    })
  }
}
export function setCourierList(data) {
  return dispatch => {
    dispatch({
      type: types.COURIER_LIST,
      data: data
    })
  }
}
export function setAutomationAction(data) {
  return dispatch => {
    dispatch({
      type: data.type,
      data: {
        key: data.index,
        value: data.value
      }
    })
  }
}
export function removeAutomationActionByKey(data) {
  return dispatch => {
    dispatch({
      type: types.CLEAR_ACTION_BY_KEY,
      data: {
        key: data.index,
        value: data.value
      }
    })
  }
}
export function getEmailTemplateList() {
  let config = getInstance('GET', `${prefix}/email_template/list`)
  return axios(config).then(res => res).catch(err => {
    console.log(err)
  })
}

export function getSubUserList() {
  let config = getInstance('GET', `${prefix}/sub_user/list?pageId=undefined&limit=undefined`)
  return axios(config).then(res => res).catch(err => {
    console.log(err)
  })
}

export function getSMSTemplateList() {
  let config = getInstance('GET', `${prefix}/sms_template`)
  return axios(config).then(res => res).catch(err => {
    console.log(err)
  })
}
export function addAutomationRule(requestParams = null) {
 
  let config = getInstance('POST', `${prefix}/automation`, requestParams);
 
  return dispatch => {
     dispatch(startLoading())
    return axios(config)
      .then(res => {  
       
    
         dispatch(endLoading())
         return dispatch(returnData(types.ADD_AUTOMATION_LIST, res.data, res.status))
      })
      .catch(function (error) {
         dispatch(endLoading())
         
         return handleException(error)
      });
  }
}
export function getAutomationRuleList(requestParams = {}) {
  // let pageId = requestParams.pageId
    // let limit = requestParams.limit
    let filters = {}
    let searchQueryValue = ''
    if (requestParams.trigger !== undefined) {
        filters.trigger = requestParams.trigger
    }
    if (requestParams.action !== undefined) {
        filters.action = requestParams.action
    }
    if (requestParams.event_type !== undefined) {
        filters.event_type = requestParams.event_type
    }
    //filter for sub user 
    if (requestParams.query && requestParams.query !== '') {
        searchQueryValue = escape(requestParams.query)
    }
    let config = ''
    if (Object.keys(filters).length > 0) {
        config = getInstance('GET', `${prefix}/automation/list?filters=${JSON.stringify(filters)}&searchQuery=${JSON.stringify(searchQueryValue)}`);
    }
    else {
        if(searchQueryValue !== ''){
          config = getInstance('GET', `${prefix}/automation/list?searchQuery=${JSON.stringify(searchQueryValue)}`);
        }
        else{
          config = getInstance('GET', `${prefix}/automation/list`);
        }
    }
    return dispatch => {
      // dispatch(startLoading())
        return axios(config)
            .then(res => {
              dispatch(endLoading())
              return dispatch(returnData(types.AUTOMATION_LIST, res.data.data, res.status))
            })
            .catch(function (error) {
              // dispatch(endLoading())
              return handleException(error)
            });
    }
}
export function clearAutomationDetail() {
  return (dispatch) => {
    dispatch({
      type: types.AUTOMATION_DETAIL,
      data: {}
    })
  }
}
export function updateAutomationRule(automationId = null, requestParams = null) {
  let config = getInstance('PUT', `${prefix}/automation/${automationId}`, requestParams);
  return dispatch => {
    dispatch(startLoading())
    return axios(config)
      .then(res => {
        dispatch(endLoading())
        return res
      })
      .catch(function (error) {
        dispatch(endLoading())
        return handleException(error)
      });
  }
}
export function getAutomationDetail(automationId = null) {
  
  let config = getInstance('GET', `${prefix}/automation/${automationId}`);
  return dispatch => {
    dispatch(startLoading())
    return axios(config)
      .then(res => {
        
        dispatch(endLoading())
        return dispatch(returnData(types.AUTOMATION_DETAIL, res.data, res.status))
      })
      .catch(function (error) {
        dispatch(endLoading())
        return handleException(error)
      });
  }
}
export function updateAutomationRuleListState(automationId = null, updatedData = null) {
  return (dispatch, getState) => {
    let automationDetail = getState().automations.list
    let automationList = [...automationDetail.automationList]
    let objIndex = automationList.findIndex((obj => obj.id === parseInt(automationId, 10)))
    for (let key in updatedData) {
      automationList[objIndex][key] = updatedData[key]
    }
    
    dispatch({
      type: types.AUTOMATION_LIST,
      data: automationList
    })
  }
}

export function deleteAutomationRule(automationId = null) {
  let config = getInstance('DELETE', `${prefix}/automation/${automationId}`);
  return dispatch => {
    dispatch(startLoading())
    return axios(config)
      .then(res => {
        dispatch(endLoading())
        return dispatch(returnData(types.DELETE_AUTOMATION_LIST, res.data.data, res.status))
      })
      .catch(function (error) {
        dispatch(endLoading())
        return handleException(error)
      });
  }
}
export function getCourierList () {
  let config = getInstance('GET', `${prefix}/couriers/list`);
  return dispatch => {
    dispatch(startLoading())
    return axios(config)
      .then(res => {
        
        dispatch(endLoading())
        return dispatch(returnData(types.COURIER_LIST_VALUE, res.data, res.status))
      })
      .catch(function (error) {
        dispatch(endLoading())
        return handleException(error)
      });
  }
}
export function clearAutomationList() {
  return dispatch => {
    dispatch({
      type: types.CLEAR_AUTOMATION_LIST,
      data: []
    })
  }
}

export function setAutomationDetail(automationDetail) {
  return dispatch => {
    dispatch({
      type: types.AUTOMATION_DETAIL,
      data: automationDetail
    })
  }
}

export function createCustomEvent(reqBody) {
  let config = getInstance('POST', `${prefix}/automation/custom_event/create`, reqBody);
  return axios(config).then(res => res).catch(err => {
    return handleException(err)
  })
}

export function getCustomEventsListReducer() {
  let config = getInstance('GET', `${prefix}/automation/custom_event/list`);
  return dispatch => {
    dispatch(startLoading())
    return axios(config)
      .then(res => {
        dispatch(endLoading())
        return dispatch(returnData(types.CUSTOM_TRIGGER_LIST, res.data.data, res.status))
      })
      .catch(function (error) {
        dispatch(endLoading())
        return handleException(error)
      });
  }
}

export function getCustomEventsList() {
  let config = getInstance('GET', `${prefix}/automation/custom_event/list`);
  return axios(config).then(res => res.data.data).catch(err => {
    return handleException(err)
  })
}

export function editCustomEvent(reqBody, customEventID) {
  let config = getInstance('POST', `${prefix}/automation/custom_event/edit/${customEventID}`, reqBody);
  return axios(config).then(res => res).catch(err => {
    return handleException(err)
  })
}

export function deleteCustomEvent(customEventID) {
  let config = getInstance('DELETE', `${prefix}/automation/custom_event/delete/${customEventID}`);
  return axios(config).then(res => res).catch(err => {
    return handleException(err)
  })
}

export function setCustomEvents(data) {
  return dispatch => {
    dispatch({
      type: types.SET_AUTOMATION_CUSTOM_EVENT_LIST,
      data: data
    })
  }
}

/**
 * javascript comment
 * @Author: Akshay Tukadiya
 * @Date: 2020-01-24 11:14:00
 * @Desc: Function to add tag
 */
export function addTag(values) {
  let config = getInstance('POST', prefix + '/tag', values)
  return axios(config).then(res => res.data)
}

/**
 * javascript comment
 * @Author: Akshay Tukadiya
 * @Date: 2020-01-24 11:14:00
 * @Desc: Function to get all tag
 */
export function getTagList(searchVal) {
  let config = getInstance('POST', `${prefix}/tags/search`, searchVal)
  return () => {
    return axios(config)
      .then(res => { return res })
      .catch(error => console.log(error))
  }
}

export function getAutomationLog(requestBody) {

  let config = getInstance('POST', `${prefix}/automation/logs`, requestBody)
    return axios(config)
        .then(response => response)
        .catch(function (error) {
            return handleException(error)
        })
}

export function setAutomationFilters(data) {
  return dispatch => {
      dispatch({
          type: types.AUTOMATION_GLOBAL_FILTERS,
          data: data
      })
  }
}

export function getFiltersList() {
  let config = getInstance('GET', `${prefix}/automation/filters`);
  return axios(config).then(res =>
      res.data)
      .catch(function (error) {
          return handleException(error)
      })
}

export function setAutomationActionFilter(data) {
  return dispatch => {
      dispatch({
          type: types.SET_AUTOMATION_ACTION_ARRAY_VALUE_LIST,
          data: data
      })
  }
}

export function setAutomationTrigger(data) {
  return dispatch => {
      dispatch({
          type: types.SET_AUTOMATION_TRIGGER_ARRAY_VALUE_LIST,
          data: data
      })
  }
}

export function setAutomationLoader(flag = 0) {
  return dispatch => {
      dispatch({
          type: types.SET_AUTOMATION_LOADER,
          data : flag
      })
  }
}