import React from 'react';
import ReactAce from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/xcode';
import 'brace/ext/language_tools';

class AceEditor extends React.PureComponent {
    render() {
        return (
            <ReactAce
                mode="html"
                theme="xcode"
                name={"accordion"}
                onLoad={(value) => this.props.onEditorLoad(value, 'editor1' )}
                onChange={(newValue) => this.props.onEditorChange(newValue, 'aceValue')}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={this.props.stateValue['aceValue']}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                    useWorker: false
                }}
                wrapEnabled={true}
            />
        )
    }
}
export default AceEditor;