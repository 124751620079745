import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'rc-progress';

class FileRow extends PureComponent {
    static propTypes = {
        progress: PropTypes.number,
        isUploading: PropTypes.bool,
        file: PropTypes.object.isRequired,
        onRemoveFile: PropTypes.func
    }

    render() {
        const { file, isUploading, progress } = this.props;

        return (
            <div className="DocProcessWrapper">
                <div className="FileIc" />
                <div className="FileName_Process">
                    <div className="FileName" title={file.name}>{file.name}</div>
                    <div onClick={this.props.onRemoveFile}>
                        <a className="CloseIc" >&nbsp;</a>
                    </div>
                    <div className="clearfix"></div>
                    {isUploading && (
                        <Line
                            percent={progress}
                            trailColor="#EBECED"
                            strokeWidth="3"
                            trailWidth="3"
                            strokeColor="#31A5F7"
                            className="ProcessBar" />
                    )}
                    {/* <div className="ProcessBar"></div> */}
                </div>
            </div>
        )
    }
}
export default FileRow;