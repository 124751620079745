import React from 'react';
import Footer from '../../../../common/footer';

export default class AusPostInstructions extends React.Component{
    moveToAusPostForm = () => {
        this.props.moveToAusPostForm(true)
    }
    backToIntegration = () => {
        this.props.backToIntegration()
    }

    render(){
        return(
            <React.Fragment>
                <div className="integrationStepsMainDiv">
                    <ul className="instructions-list">
                        <li>
                            <span className="list-seq">1</span>
                            <span className="instructions-text">
                                Email Australia Post customer_connectivity@auspost.com.au Subject as "Shipping and Tracking API suite", tell them your account number, and contact you to discuss the Shipping and Tracking API suite.
                            </span>
                        </li>
                        <li>
                            <span className="list-seq">2</span>
                            <span className="instructions-text">
                                Australia Post will provide Australia Post Shipping API test and production credentials: Password and API Key
                            </span>

                        </li>
                        <li>
                            <span className="list-seq">3</span>
                            <span className="instructions-text">
                                Fill up all the necessary production details - Australia Post account number, API key, API key password.
                            </span>

                        </li>
                        <li>
                            <span className="list-seq">4</span>
                            <span className="instructions-text">
                                Recheck the correctness of all data you have entered in the above fields.
                            </span>

                        </li>
                        <li>
                            <span className="list-seq">5</span>
                            <span className="instructions-text">
                                Click on the <strong>Done</strong> button to successfully integrate with Australia Post.
                            </span>

                        </li>
                        <li>
                            <span className="list-seq">6</span>
                            <span className="instructions-text">
                                You can get more information and tips on their <a target="_blank" rel="noopener noreferrer" href="https://auspost.com.au/">website.</a>
                            </span>
                        </li>

                    </ul>
                    <button
                        id="store-next-btn"
                        type="button"
                        style={{
                            float: 'right',
                            display: 'none'
                        }}
                        onClick={this.moveToAusPostForm}
                    >
                        Next
                    </button>
                    <button
                        id="store-cancel-btn"
                        type="button"
                        style={{
                            float: 'right',
                            display: 'none'
                        }}
                        onClick={this.backToIntegration}
                    >
                        Cancel
                    </button>
                </div>

            <div className="commonFooter">
                <Footer storeAction="true" />
            </div>
            </React.Fragment>
        )
   }
}