const styles = {
  /* TRACKING FILTER CSS START */
  MuiListItemIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'inline-flex',
    'min-width': '30px !important',
    'flex-shrink': 0,
    paddingLeft: '10px'
  },
  MuiListItemWithoutIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'inline-flex',
    'min-width': '0px !important',
    'flex-shrink': 0
  },
  MuiListItemSecondaryAction: {
    paddingRight: '5px !important',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '3px'
  },
  filterDropdownText: {
    fontSize: '14px',
    fontFamily: 'Roboto',
    color: '#111111',
    paddingTop: '2px'
  },
  dropdownSearch: {
    border: 0,
    margin: 0,
    display: 'inline-flex',
    padding: '0px',
    position: 'relative',
    minWidth: 0,
    flexDirection: 'column',
    verticalAlign: 'middle',
    width: '100%',
    background: 'url(https://trackhive-images.s3-ap-southeast-1.amazonaws.com/search.svg) no-repeat 18.6em 0.1rem',
    fontSize: '12px',
    backgroundColor: '#ffffff !important',
    height: '38px'
  },
  /* TRACKING FILTER CSS END */

  /* TRACKING LIST CSS START*/
  digestSwitchOn: {
    // marginLeft: '3rem',
    "& .Mui-checked ": {
      color: '#1a73e8'
    },
    "& .MuiSwitch-track": {
      background: '#1a73e8 !important'
    }
  },
  digestSwitchOff: {
    // marginLeft: '3rem'
  },
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    boxShadow: 'none'
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headTableCell: {
    height: '16px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#212121',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    verticalAlign: 'middle',
    padding: '6px 15px 6px 0px',
    backgroundColor: '#ffffff',
    "&:first-child": {
       width: '120px'
    },
    "&:nth-child(2)": {
       width: '280px'
    },
    "&:nth-child(3)": {
       width: '100px'
    },
    "&:nth-child(4)": {
       width: '200px'
    },
    "&:nth-child(5)": {
       width: '100px'
    },
    "&:nth-child(6)": {
       width: '175px'
    },
    "&:nth-child(7)": {
      // width: '70px'
    },
    // "&:nth-child(8)": {
    //   width: '15%'
    // },
  },
  headTableRow: {
    height: '56px'
  },
  bodyTableRow: {
    height: '52px',
    '&:hover': {
      backgroundColor: '#F6F5F5 !important',
    },
  },
  selectedTableRow: {
    backgroundColor: '#e9faff !important'
  },
  bodyTableCell: {
    height: '16px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#212121',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    verticalAlign: 'middle',
    padding: '4px 15px 4px 0px',
    // backgroundColor: '#ffffff',
    cursor: 'pointer',
    // maxWidth: '0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    "&:first-child": {
      width: '120px'
   },
   "&:nth-child(2)": {
      width: '280px'
   },
   "&:nth-child(3)": {
      width: '100px'
   },
   "&:nth-child(4)": {
      width: '200px'
   },
   "&:nth-child(5)": {
      width: '100px'
   },
   "&:nth-child(6)": {
      width: '175px'
   },
   "&:nth-child(7)": {
     // width: '70px'
   },
  },
  tagButton: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#ffffff',
    '& .MuiIconButton-label': {
      backgroundColor: '#727272',
      borderRadius: '50%',
      padding: '3px',
      color: '#ffffff'
    }
  },
  container: {
    height: 'calc(100vh - 110px)',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      utline: '1px solid slategrey'
    }
  },
  progressbar: {
    width: '100%',
    zIndex: 2,
    position: 'absolute',
  },
  progressbarDialog: {
    width: '100%',
    zIndex: 2,
    position: 'absolute',
    top: 48
  },
  pagination: {
    bottom: 0,
    position: 'fixed',
    zIndex: 100,
    backgroundColor: '#ffffff',
    width: 'calc(100% - 73px)',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },

  /*TRACKING LIST CSS END*/

  /*TOOLBAR CSS START */

  toolbarRoot: {
    minHeight: '56px !important',
    paddingLeft: '0px !important',
    display: 'block'
  },
  toolbarHighlight:
  {
    color: '#ffffff',
    backgroundColor: '#757575',
    display: 'flex'
  },
  hrMarginBlock: {
    marginBlockEnd: '0px',
    marginBlockStart: '0px',
    display: 'block',
    height: '1px',
    border: 0,
    borderTop: '1px solid #ccc',
    padding: '0px',
  },
  /* Add Dialog Start  */
  appBar: {
    position: 'relative',
    boxShadow: 'none'
  },
  title: {
    // marginLeft: ,
    display: 'contents',
    flex: 1,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.18px',
    color: '#ffffff',
    paddingLeft: '6px'

  },
  dialogToolbarRoot: {
    minHeight: '48px',
    backgroundColor: '#1a73e8'
  },
  dialogIcon: {
    marginLeft: '-22px',
  },
  dialogAppbarButton: {
    width: 'auto',
    height: '30px',
    borderRadius: '3px',
    backgroundColor: '#ffffff',
    '& .MuiButton-label': {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.16px',
      color: '#1a73e8',
    },
    '&:hover': {
      backgroundColor: '#ffffff'
    }
  },
  shipmentbaseConatainer: {
    height: '100%',
    display: 'flex'
  },
  shipmentTriggerDiv: {
    marginLeft: '32px',
    display: 'inline-block'
  },
  shipmentBasesGrid: {
    width: '558px',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    borderRight: '2px solid #EFEFEF',
    height: '100%'
  },
  shipmentCard: {
    width: '494px',
    height: '88px',
    borderRadius: '4px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.14)',
    backgroundColor: '#e3eefc',
    marginLeft: '32px',
    marginTop: '32px',
  },
  shipmentCardHeader: {
    paddingBottom: '0px',
    alignItems: 'stretch'
  },
  shipmentCardSubTitle: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  shipmentCardSubHeader: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: '0.14px',
    color: '#5e6367',
    paddingTop : '7px'
  },
  shipmentCardActions: {
    width: '170px',
    paddingLeft: '14px',
    paddingTop: '21px',
  },
  cardAction: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.14,
    letterSpacing: '0.75px',
    color: '#1a73e8',
    textDecoration : 'none'
  },
  exportDrawerRadioLable: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.16px',
    color: '#5e6367',
  },
  shipmentBaseText: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    color: '#212121',
    paddingTop: '32px'
  },
  shipmentBaseTextPreview: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    color: '#212121',
    paddingTop: '10px'
  },
  exportListIcon: {

    // paddingLeft : '0px',
    minWidth: '36px',
    '& .PrivateSwitchBase-root-496': {
      paddingLeft: '0px',
      paddingTop: '5px',
      paddingBottom: '5px'
    }
  },
  shipmentTrigger: {
    marginTop: '45px'
  },
  triggerCard: {
    width: '352px',
    height: '136px',
    borderRadius: '4px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.14)',
    backgroundColor: '#ffffff',
    left: '39%',
    position: 'absolute'
  },
  bulkfilterActionSpan: {
    height: '32px',
    position: 'absolute',
    borderLeft: '1px solid #111111',
    left : '50%'
  },
  formControl : {
    margin: "0 auto"
  },
  automationNameInput : {
    fontSize: "20px",
    color: "#ffffff",
    '& > input::placeholder': {
      color: "#ffffff",
      opacity: 1
    }
  },
  toolBarGutter : {
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  dialogActionBtn: {
    color: "red"
  },
  dialogCancelBtn: {
    color: "#1a73e8"
  },
  deleteButon : {
    padding : '5px',
    marginLeft : '5px',
  },
  listItemRoot : {
    paddingTop : "0px",
    paddingBottom : '0px'
},
radioRoot : {
  padding : '9px 9px 9px 0px'
},
removeIcon : {
  height : '24px',
  width : '24px'
}
  /* Add Dialog End  */
}
export default styles