import React from 'react'
import SettingListGrid from './settingsListGrid'
import Scrollbars from 'react-custom-scrollbars'
import {settingMenu} from '../common/settingsMenu'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import '../assets/css/settings.scss'  

const mapStateToProps = state => {
    return {
        roles: state.authenticate.roles
    }
  }
class Settings extends React.Component{
    constructor(){
        super()
        this.state = {
            userType: '',
            settingsFlag: false
        }
    }

    componentDidMount(){
        let settingsFlag = true
        let user_type = localStorage.getItem('user_type')
        let roles = this.props.roles && this.props.roles.length > 0 ? this.props.roles : []      
        if(roles.length > 0 || user_type === 'subuser'){
           if(!roles.includes('settings')){
            settingsFlag = false
           }
        }
        this.setState({userType:user_type,settingsFlag:settingsFlag})
    }

    render(){
        return(
            <div className="settingsModule" style={{background: "#f2f4f5"}}>
                <div className="dataContainer">
                   <div id="settingDataScrollerWrapper">
                      <div className="settingsContainer">
                        <Scrollbars autoHide style={{ height: window.innerHeight - 51, clear: "both", width: "100%" }}>
                            <SettingListGrid 
                                    menu={settingMenu} 
                                    userType={this.state.userType} 
                                    settingFlag={this.state.settingsFlag} />
                        </Scrollbars>
                      </div>
                   </div>
                </div>
            </div>        )
    }
}
export default withRouter(connect(mapStateToProps, null)(Settings));