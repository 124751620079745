import React from 'react';
import Button from '@material-ui/core/Button';
import '../../../../assets/css/profileStyle.scss'
import { Scrollbars } from 'react-custom-scrollbars';
import CssBaseline from '@material-ui/core/CssBaseline';
import { scrollbar } from '../../../../../static/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledTextField } from '../../../../common/materialComponents';
class BlueDartDetailsForm extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />       
                    <div className="commonSettingsBox profileRightSideMainDiv">
                        <Scrollbars autoHide style={{ scrollbar }}>
                            <div className="customFormContainer" >
                                <div className="input_wrap">
                                    <div className="form-attribute">
                                        <StyledTextField
                                            error={this.props.carrierNameError ? true : false}
                                            id="CarrierName"
                                            label="Carrier Name"
                                            variant="outlined"
                                            name="CarrierName"
                                            value={this.props.carrierName}
                                            onChange={e => this.props.handleCarrierName(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-attribute">
                                        <StyledTextField
                                            error={this.props.loginIdError ? true : false}
                                            id="LoginId"
                                            label="Login Id"
                                            variant="outlined"
                                            name="LoginId"
                                            value={this.props.loginId}
                                            onChange={e => this.props.handleLoginId(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="input_wrap">
                                    <div className="form-attribute">
                                        <StyledTextField
                                            error={this.props.licenseKeyError ? true : false}
                                            id="LicenseKey"
                                            label="License Key"
                                            variant="outlined"
                                            name="LicenseKey"
                                            value={this.props.licenseKey}
                                            onChange={e => this.props.handleLicenseKey(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className={classes.saveBtnRoot}>
                                    <div className={classes.wrapper} >
                                        <Button
                                            variant="contained"
                                            className={classes.saveBtn}
                                            disabled={this.props.showSaveLoader}
                                            onClick={this.props.handleSubmit}
                                        >
                                            SAVE
                                        </Button>
                                        {this.props.showSaveLoader && <CircularProgress size={22} className={classes.buttonProgress} />}
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
            </React.Fragment>
        )
    }
}
export default BlueDartDetailsForm;