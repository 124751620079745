import React from 'react'
import '../../assets/css/emailSettings.scss';
import { Link, withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import EmailEditor from './email_editor'
import MenuItem from '@material-ui/core/MenuItem'
import { connect } from 'react-redux';
import HTMLDecoder from 'html-encoder-decoder'
import {emailRegex,ccEmailRegex} from '../../../static/constants'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Popover from '@material-ui/core/Popover';
import Styles from '../../assets/css/Styles';
import FlashMessage from '../../common/FlashMessage';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import {ExpandIcon,EmailTemplates, SaveIcon, CloseIcon, EmailIcon} from '../../../common/imageList'
import {StyledTextField, StyledAutoComplete, LinearProgressBar, StyledCard, StyledTypography, BreadCrumbs, Image } from '../../common/materialComponents';
import { createCustomEmailTemplate, getEmailTemplate, updateEmailTemplate, testEmailTemplate, getDomainList, setSettingsFlashMessage, toggleSettingsFlashMessage } from '../../actions/actionMethods';
import {CREATE_TEMPLATE_SUCCESS, CREATE_TEMPLATE_ERROR, EDIT_TEMPLATE_SUCCESS, TEST_MAIL_SUCCESS, EDIT_TEMPLATE_ERROR, SubjectReplacementList,ORGANIZATION_EXPANSION_LIST,TRACKING_EXPANSION_LIST} from '../../common/constants';
const { tinymce } = window;
let fromName = localStorage.getItem('Company_Name') !== undefined && localStorage.getItem('Company_Name') !== null ? localStorage.getItem('Company_Name') : ''

class CustomEmailTemplate extends React.Component{
  constructor(props){
    super(props)
    this.isSuccess = true
    this.state = {
        path: props.match.path ? props.match.path : '',
        templateName: '',
        templateError: false,
        fromName: fromName,
        replyTo: localStorage.getItem('Reply_To') !== undefined && localStorage.getItem('Reply_To') !== null ? localStorage.getItem('Reply_To') : '',
        replyToError: false,
        ccEmail: '',
        ccEmailError:false,
        subject: '',
        subjectError: false,
        body: '',
        anchorEl: null,
        content: '',
        contentError: false,
        showLoader: false,
        success: false,
        apiError: false,
        openAlert: false,
        loaderFlag: true,
        visibleSubjectReplacement: false,
        caretPosition: 0,
        fromEmailList: [],
        saveFlag:  false,
        testFlag: false,
        selectFromEmail: {value: 'noreply@trackhive.co', label: 'noreply@trackhive.co'},
        expanded: "panel1",
        isReplyToValid: true
    }
  }
  async componentDidMount(){
    this.getDomainListData()
    this.getData()
  }
  getData = () => {
    if(this.props.match.params.id !== undefined){
      let REPLY_TO_EMAIL = localStorage.getItem('REPLY_TO')
      getEmailTemplate(this.props.match.params.id).then(res =>{
        if(res.status >= 200 && res.status <= 299){
          this.setState({
              subject: HTMLDecoder.decode(HTMLDecoder.decode(res.data.data.subject)),
              caretPosition: res.data.data.subject.length,
              templateName: res.data.data.template_name,
              body: HTMLDecoder.decode(HTMLDecoder.decode(res.data.data.body)),
              content: HTMLDecoder.decode(HTMLDecoder.decode(res.data.data.body)),
              selectFromEmail: res.data.data.from_email === "" || res.data.data.from_email === undefined ? {value: 'noreply@trackhive.co', label: 'noreply@trackhive.co'} : { value: res.data.data.from_email, label: res.data.data.from_email },
              replyTo: res.data.data.reply_to !== null ? res.data.data.reply_to : REPLY_TO_EMAIL,
              fromName: res.data.data.from_name ? res.data.data.from_name : fromName,
              ccEmail: res.data.data.cc_email.toString(),
              loaderFlag: false,
              isReplyToValid: true
          } , () => {})
        }
        }).catch(error => {
            console.log(error)
        })
    }else{
      this.setState({loaderFlag: false})
    }
  }
  getDomainListData =  () => {
    getDomainList('?is_verified=true').then(res => {
      if(res && res.status >= 200 && res.status <= 299){
        let emailId = []
          res.data.forEach(e =>{
            if(e.email){
              e.email.map((eid) =>{
                emailId.push({value: eid, label: eid})
              })
            }
          })
          this.setState({
            fromEmailList:  emailId
          })
      }
    })
  }
  handleSelectFromEmail = (e, fromEmail) => {
    this.setState({
        selectFromEmail: fromEmail
    }, () => {})
  }
  handleTemplateName = (event) => {
    if(event.target.value !== '') {
      this.setState({
        templateName: event.target.value,
        templateError: false
      })
    } else {
      this.setState({
        templateName: event.target.value,
        templateError: true
      })
    }
  }
  handleFromName = (event) => {
      this.setState({
        fromName: event.target.value,
      })
  }
  handleReplyTo = (event) => {
    let val = event.target.value
    this.setState({
      replyTo: val,
      replyToError: false
    })
    if (!emailRegex.test(val)) {
      this.setState({replyTo: val, isReplyToValid: false})
    } else {
      this.setState({replyTo: val, isReplyToValid: true})
    }
  }
  handleCCEmail = (event) => {
    let val = event.target.value
    if (val !== '') {
      if (!ccEmailRegex.test(val)) {
        this.setState({ ccEmail: val, ccEmailError: true })
      } else {
        this.setState({ ccEmail: val, ccEmailError: false })
      }
    } else {
      this.setState({ccEmail: val, ccEmailError: false})
    }
  }
  subjectChange = (e) => {
    if(e.target.value !== '') {
      this.setState({
        subject: e.target.value,
        subjectError: false
      })
    } else {
      this.setState({
        subject: e.target.value,
        subjectError: true
      })
    }
  }
    
  getCaret = () => {
    let did = document.getElementById("subject")
    let startPos = did.selectionStart;
    this.setState({caretPosition: startPos})
  }
  handleContent = (html) => {
    this.setState({
      content: html 
    })
  }
  handleInsertValue = (value) => {
    if(tinymce) {
      if(tinymce.activeEditor !== null){
        tinymce.activeEditor.insertContent(value)
      }
    }    
  }
  setSubjectValue = (subjectValue) => { 
    this.setState(prevState => ({
      subject: prevState.subject + " " + subjectValue
    }))
  }
  
  handleVisibleChange = (key) => {
    this.setState({ [key]: !this.state[key] })
  }
  cancelEmailTemplateSetting = () => {
    if(tinymce) {
      if(tinymce.activeEditor !== null){
        tinymce.activeEditor.remove()
      }
    }    
    this.props.history.push("/settings_email")
  }
  checkValidation = () => {
    let errorCount = 0
    if(this.state.templateName === '' || this.state.templateName.trim().length === 0) {
      this.setState({templateError: true})
      errorCount++
    } else {
      this.setState({templateError: false})
    }
    if(this.state.replyTo === '' || this.state.replyToError) {
      this.setState({replyToError: true})
      errorCount++
    } else {
      this.setState({replyToError: false})
    }
    if(this.state.ccEmailError) {
      errorCount++
    }
    if(this.state.subject === '' || this.state.subject.trim().length === 0) {
      this.setState({subjectError: true})
      errorCount++
    } else {
      this.setState({subjectError: false})
    }
    if(this.state.content === '' || this.state.content.trim().length === 0) {
      this.setState({contentError: true})
      errorCount++
    } else {
      this.setState({contentError: false})
    }
    if(!this.state.isReplyToValid){
      errorCount++
    }else{
      this.setState({isReplyToValid: true})
    }
    if(errorCount > 0) {
      return false
    }
    return true
  }
saveEmailSetting = () => {
  let emailData = {}
  let ccEmailArray = []
  this.setState({showLoader: true,saveFlag: true})
  if(this.state.ccEmail !== undefined && this.state.ccEmail !== ''){
      ccEmailArray = this.state.ccEmail.split(',')
  }
  if(this.state.path.includes('edit_email_template')){
      let validation = this.checkValidation()
      if(validation){
        emailData.template_name = this.state.templateName
        emailData.subject = this.state.subject
        emailData.body = this.state.content.replace(/(\r\n\t|\n|\r\t)/gm,"")
        emailData.reply_to = this.state.replyTo
        emailData.from_email = this.state.selectFromEmail? this.state.selectFromEmail.value : ""
        emailData.from_name = this.state.fromName
        emailData.cc_email = ccEmailArray
        updateEmailTemplate(this.props.match.params.id,emailData).then(res => {
          if(res && res.status >= 200 && res.status <= 299){
              this.getData()
              this.isSuccess = true
              this.props.toggleSettingsFlashMessage(1)
              this.props.setSettingsFlashMessage(EDIT_TEMPLATE_SUCCESS)
              this.setState({showLoader: false, saveFlag: false})
          }else{
              this.isSuccess = false
              this.props.toggleSettingsFlashMessage(1)
              this.props.setSettingsFlashMessage(res.data.meta.message)
              this.setState({showLoader: false, saveFlag: false})
          }
        }).catch(error => {
              this.isSuccess = false
              this.props.toggleSettingsFlashMessage(1)
              this.props.setSettingsFlashMessage(error)
              this.setState({showLoader: false, saveFlag: false})
        })
      }else{
        this.isSuccess = false
        this.props.toggleSettingsFlashMessage(1)
        this.props.setSettingsFlashMessage(EDIT_TEMPLATE_ERROR)
        this.setState({showLoader:false, saveFlag: false})
      }
  }else{
    let validation = this.checkValidation()
    if(validation){
      emailData.template_name = this.state.templateName
      emailData.subject = this.state.subject
      emailData.body = this.state.content.replace(/(\r\n\t|\n|\r\t)/gm,"")
      emailData.from_email =  this.state.selectFromEmail? this.state.selectFromEmail.value: ""
      emailData.reply_to = this.state.replyTo
      emailData.from_name = this.state.fromName
      emailData.cc_email = ccEmailArray
      createCustomEmailTemplate(emailData).then(res => {
        if(res && res.status >= 200 && res.status <= 299){
          this.isSuccess = true
          this.props.toggleSettingsFlashMessage(1)
          this.props.setSettingsFlashMessage(CREATE_TEMPLATE_SUCCESS)
          this.setState({showLoader: false, saveFlag: false, path: 'edit_email_template'})
          this.props.history.push(`/edit_email_template/${res.data.data._id}`)
        }else{
          this.isSuccess = false
          this.props.toggleSettingsFlashMessage(1)
          this.props.setSettingsFlashMessage(res.data.meta.message)
          this.setState({showLoader: false, saveFlag: false})
        }
      }).catch(error => {
            this.isSuccess = false
            this.props.toggleSettingsFlashMessage(1)
            this.props.setSettingsFlashMessage(error)
            this.setState({showLoader: false,success: false, saveFlag: false})
      })
    }else{
      this.isSuccess = false
      this.props.toggleSettingsFlashMessage(1)
      this.props.setSettingsFlashMessage(CREATE_TEMPLATE_ERROR)
      this.setState({showLoader: false, success: false, saveFlag: false})
    }
  }   
}

handleCloseAlert = () => {
  this.setState({openAlert: false})
}

testMail = () => {
  this.setState({showLoader: true,testFlag:true})
  let toEmail = this.state.replyTo
  let templateId = this.props.match.params.id
  let postObject = {
    "to_email": toEmail
  }
  testEmailTemplate(templateId,postObject).then(res => {  
    if(res && res.status >= 200 && res.status <= 299){
      this.isSuccess = true
      this.props.toggleSettingsFlashMessage(1)
      this.props.setSettingsFlashMessage(TEST_MAIL_SUCCESS + toEmail)
      this.setState({showLoader: false, testFlag: false, openAlert: true , apiError: false, success: TEST_MAIL_SUCCESS + toEmail})
    }else{
      this.isSuccess = false
      this.props.toggleSettingsFlashMessage(1)
      this.props.setSettingsFlashMessage(res.data.meta.message)
      this.setState({showLoader: false, testFlag: false})
    }
  }).catch(error => {
    this.isSuccess = false
    this.props.toggleSettingsFlashMessage(1)
    this.props.setSettingsFlashMessage(error.response.data.errors)
    this.setState({showLoader: false, success: false, testFlag: false})
    
  })
}

handleClick = (event) => {
  this.setState({
      anchorEl: event.currentTarget,
  });
};

handleClose = (pam) => {
  this.setState({
    anchorEl: null
  })
}

handleChange = panel => (event, isExpanded) => {
  this.setState({expanded: isExpanded ? panel :false})
}

toggleFlashMessage = (data) => {
  this.props.toggleSettingsFlashMessage(data)
  this.props.setSettingsFlashMessage('')
}

render(){
  let editFlag = this.state.path.includes('edit_email_template') ? true : false
  const open = Boolean(this.state.anchorEl);
  const id = open ? 'simple-popover' : undefined;
  let ReplacementList = []
  const {classes} = this.props
  ReplacementList = SubjectReplacementList.map((replacement, index) => {
    return <MenuItem className={classes.item} key={index} id="subjectAction" onClick={(subjectValue => this.setSubjectValue(replacement))}>
              {replacement}    
          </MenuItem>
  })
  let organizationExapansionMenu = []
  let trackingExapansionMenu = []
  organizationExapansionMenu =  ORGANIZATION_EXPANSION_LIST.map((organizationKey, index) => {
    return <MenuItem className={classes.expansionItem} key={index} id="organizationAction" onClick={(value => this.handleInsertValue(organizationKey))}>
              {organizationKey}    
           </MenuItem>
  })
  trackingExapansionMenu = TRACKING_EXPANSION_LIST.map((trackingKey, index) => {
    return <MenuItem className={classes.expansionItem} key={index} id="trackingAction" onClick={(value => this.handleInsertValue(trackingKey))}>
            {trackingKey}    
           </MenuItem>
  })
  return(
    <React.Fragment>
   {
     this.state.loaderFlag  ? 
          <LinearProgressBar/> : 
     <div className="settingsModule">
         <StyledCard>
            <BreadCrumbs aria-label="breadcrumb">
               <Link className={classes.link} to="/settings">
                   Settings
               </Link>
               <Link className={classes.link} to="/settings_email">
                   Email Templates
               </Link>
                <StyledTypography className={classes.text}>{editFlag? ' Edit Template ': ' Create Template '}</StyledTypography>     
            </BreadCrumbs>
          </StyledCard>
     <Scrollbars style={{height: 'calc(100vh - 110px)'}} autoHide={true}>
     
     <div className="dataContainer" >
        <div className="containerHeader" style={{ marginBottom: "10px" }}>
           <Image alt="EmailTemplate_IC" className={classes.avatar} src={EmailTemplates} />
           <div className="containerTitle" style={{width: '149px'}}>Email Templates</div>
         </div>
         <div >
             <div className="settingsContainer">
                 <div className="settingsRightSidePanel">
                     <div className="emailStyleMainDiv"  style={{padding: '0px 10px 56px 36px'}}>  
                          <div className="email_main_wrapper">
                          <div className="email_wrapper">     
                               <section className="emailContentMainDiv">
                                        <StyledTextField
                                            error={this.state.templateError ? true : false}
                                            id="template-name"
                                            label="Template Name"
                                            variant="outlined"
                                            className = {classes.templateNameField}
                                            value={this.state.templateName}
                                            onChange={(event) => this.handleTemplateName(event)}
                                        />
                                       
                                       <div style={{display: 'inline-flex'}}>
                                          <StyledTextField
                                                  id="from-name"
                                                  label="From Name"
                                                  variant="outlined"
                                                  className={classes.emailTextField}
                                                  value={this.state.fromName}
                                                  onChange={(event) => this.handleFromName(event)}
                                          />
                                          <StyledAutoComplete
                                               id="from-email-combo-box"
                                               openOnFocus = {true}
                                               options={this.state.fromEmailList}
                                               getOptionLabel={(option) => option.label}
                                               value={this.state.selectFromEmail}
                                              //  onChange={this.handleSelectFromEmail}
                                               onChange={(event, newValue) => {
                                                this.handleSelectFromEmail(event, newValue);
                                            }}
                                               className={classes.emailAutoComplete}
                                               renderInput={(params) => <StyledTextField {...params} label="From Email" variant="outlined" />}
                                          />
                                       </div>
                                       
                                       <div style={{display: 'inline-flex'}} >
                                            <StyledTextField
                                                id="reply-to"
                                                label="Reply To"
                                                variant="outlined"
                                                error={this.state.replyToError || !this.state.isReplyToValid? true : false}
                                                helperText={this.props.replyToError ? "Email-Id is required" : !this.state.isReplyToValid  ? "Email-Id is not valid" : ""}
                                                className={classes.emailTextField}
                                                value={this.state.replyTo}
                                                onChange={(event) => this.handleReplyTo(event)}
                                           />
                                           <StyledTextField
                                                id="cc-email"
                                                label="CC (Comma separated)"
                                                variant="outlined"
                                                error={this.state.ccEmailError ? true : false}
                                                helperText={this.state.ccEmailError ? "Email-Id is not valid" : ""}
                                                className={classes.emailTextField}
                                                value={this.state.ccEmail}
                                                onChange={(event) => this.handleCCEmail(event)}
                                           />
                                       </div>
                                       
                                       <div style={{display: 'inline-flex'}} >
                                           <StyledTextField
                                                  error={this.state.subjectError ? true : false}
                                                  id="subject"
                                                  label="Subject"
                                                  variant="outlined"
                                                  className = {classes.templateSubjectField}
                                                  value={this.state.subject}
                                                  onChange={(event) => this.subjectChange(event)}
                                                
                                              />
                                              <Button
                                                  className={classes.popoverBtnStyle}
                                                  onClick={this.handleClick}
                                                >
                                                  Subject Replacement
                                              </Button>
                                               <Popover
                                                    id={id}
                                                    open={open}
                                                    className={classes.popoverStyle}
                                                    anchorEl={this.state.anchorEl}
                                                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                                    targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                                                    onClose={this.handleClose}
                                                >
                                                  {ReplacementList}
                                              </Popover>
                                       </div>
                                   
                                  <div style= {{marginTop: '23px'}}>
                                    {!this.state.loaderFlag ? 
                                        <EmailEditor content={this.state.body} handleContent={(content) => this.handleContent(content)} />  
                                      : ''}
                                    {this.state.contentError ? 
                                      <span className="error-fonts" style={{fontSize: '14px'}}> 
                                          Email body required.
                                      </span> : ''}
                                  </div>                              
                             </section>
                              <aside  className="email_insert_value"> 
                               <Typography className={classes.exapansionMainTitle}>Insert Values</Typography>
                               <div className={classes.expansionPanelRoot}>
                                  <ExpansionPanel className={classes.expansionPanel} expanded={this.state.expanded === "panel1"}   onChange={this.handleChange("panel1")}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className = {classes.expansionPanelSummary}
                                    >
                                      <Typography className={classes.heading}>Organization</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <Grid>
                                         {organizationExapansionMenu}
                                      </Grid>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                  <ExpansionPanel className={classes.expansionPanel} expanded={this.state.expanded === "panel2"}   onChange={this.handleChange("panel2")}>
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      className = {classes.expansionPanelSummary}
                                    >
                                      <Typography className={classes.heading}>Tracking</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <Grid>
                                         {trackingExapansionMenu}
                                      </Grid>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                </div>
                               </aside>
                               </div>
                               </div>
                       </div>

                          {this.props.showSettingFlashMessageFlag === 1 ?
                                 <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                          : ''}
                           </div>
                     </div>
               </div>

       {/* {footer} */}
   </div>
     {/* data container close*/}
     </Scrollbars>  
     {
                  this.state.showLoader ?
                  <div className="commonFooter ignore-react-onclickoutside">
                      <footer>
                          <div className="centerItems">
                            <CircularProgress size={32} className={classes.emailCircularProgress} />
                          </div>
                      </footer>
                    </div> 
                    :
                  <div className="commonFooter">
                      <footer>
                      <div className="centerItems">
                        <div>
                          <Button 
                            onClick={() => this.saveEmailSetting()}
                            startIcon={<SaveIcon/>} 
                            className={classes.saveEmailBtn}
                            >
                            Save
                          </Button>
                        </div>
                        {
                          this.props.match.params.id !== undefined ?
                            <div>                                    
                            <Button 
                                onClick={() => this.testMail()}
                                startIcon={<EmailIcon/>} 
                                className={classes.testEmailBtn}>
                                Test
                            </Button>
                          </div>: ""
                        }
                        <div >
                          <Button 
                            startIcon={<CloseIcon/>}  
                            className={classes.cancelEmailBtn}
                            onClick={() => this.cancelEmailTemplateSetting()}>
                              Cancel
                          </Button>
                        </div>
                        </div>
                      </footer> 
                    </div>
              }
      </div>
     }
  </React.Fragment>
    )
  }   
}
const mapStateToProps = state => ({
  showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
  settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, { toggleSettingsFlashMessage, setSettingsFlashMessage })(withStyles(Styles)(CustomEmailTemplate)));
