import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux';
import store from './store'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { firebase } from '@firebase/app';
import '@firebase/database';
import '@firebase/auth';

var config_json = JSON.parse(process.env.REACT_APP_FIREBASE_JSON);
 var config_firebase = {
  apiKey: config_json['apiKey'],
  authDomain: config_json['authDomain'],
  databaseURL: config_json['databaseURL'],
  messagingSenderId: config_json['messagingSenderId'],
  projectId: config_json['projectId'],
  storageBucket: config_json['storageBucket']
 }
 firebase.initializeApp(config_firebase);
export const getUserStatus = function (store) {
  return new Promise(function (resolve, reject) {
    firebase.auth().onAuthStateChanged(function (user, err) {
      if (user) {
        resolve(user.uid);
      } else {
        
        //reject(Error('It broke'));
      }
    });
  });
};

ReactDOM.render(
    (<Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>

    ), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
