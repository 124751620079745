import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { SaveIcon, CloseIcon, cancelIconSettings } from '../../../common/imageList'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { CONDITION_TYPE, CONDITION_TYPE_OPERATOR } from '../../constants/defaultValues'
import { setAutomationCondition, clearAllAction, removeAutomationConditionByKey } from '../../actions/actionMethods'
import { CONDITION_TYPE_COLLECTION, CONDITION_OPERATOR, CONDITION_VALUE } from '../../constants/actionTypes'
import { Scrollbars } from 'react-custom-scrollbars'
import { CONDITION_TYPE_ERROR, CONDITION_VALUE_REQUIRED, CONDITION_OPERATOR_REQUIRED } from '../../constants/errorMessages'
import LoadComponent from '../../common/LoadComponent'
import { getLabel } from '../../common/Functions'
import FlashMessage from '../../../common/alertSnackBar'

const initValues = {
    conditionTypeOperator: { label: 'Select', value: '' },
    condition: { label: 'Select', value: '', component: '', field: '' },
}

const styles = () => ({
    drawerContainer: {
        maxWidth: '662px',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px',
        position: 'absolute',
        borderLeft: 'none',
        '& .MuiList-root': {
            listStyle: 'none !important',
            margin: '-2px !important',
            paddingTop: '9px'
        }
    },
    drawerHeader: {
        width: '657px',
        height: '48px',
        background: "#5e6367",
        position: 'sticky',
        top: 0
    },
    drawerTitle: {
        color: '#ffffff',
        padding: "10px 0px 8px 32px",
        fontSize: '20px',
        letterSpacing: '0.15px'
    },
    draweCancelButton: {
        marginLeft: 'auto',
        position: 'relative',
        // paddingRight: '29px'
        marginRight: '14px'
    },
    drawerTextFieldContainer: {
        padding: "30px 24px 0 0px",
        display: 'inline-block',
    },
    listIcon: {
        minWidth: '36px',
        display : 'inline-block'
        // '& .PrivateSwitchBase-root-596' :{
        //   paddingLeft : '0px',
        //   paddingTop : '5px',
        //   paddingBottom : '5px'
        // }
    },
    drawerRadioLable: {
        display : 'inline-block',
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.16px',
        color: '#5e6367',
    },
    drawerFooter: {
        position: 'absolute',
        bottom: '0px',
        width: '657px',
        backgroundColor: '#ffffff',
        zIndex: '100',
        borderTop: '1px solid #d9d9ec',
        height: '81px'
    },
    saveBtn: {
        background: '#1a73e8',
        marginTop: "22px",
        marginLeft: '32px',
        width: '88px',
        height: '36px',
        // margin: '32px 0px 32px 32px',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px',
        color: '#ffffff',
        "&:hover": {
            background: '#1a73e8'
        }
    },
    deleteButtonAction: {
        background: "#ffffff",
        float: 'right',
        margin: "22px 32px 0px 0px",
        width: '100px',
        height: '36px',
        border: '1px solid #1a73e8',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.14px',
        color: '#1a73e8',
    },
    listItemRoot: {
        paddingTop: "0px",
        paddingBottom: '0px',
        display: 'block'
    },
    radioRoot: {
        padding: '9px 9px 9px 0px'
    },
    snackBar : {
        position : 'absolute'
    }
});
class ConditionDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.queryParams = []
        this.errorMsg = { type: 'ERROR', 'message': CONDITION_TYPE_ERROR }
        this.isCustomFlag = this.props.trigger === 'SHIPMENT' ? 'SHIPMENT' : 'CUSTOM'
        this.conditionTypeList = CONDITION_TYPE[this.isCustomFlag].filter(condition => {
            return condition.trigger.includes(this.props.triggerCondition)
        })

        this.conditionOperatorTypeList = CONDITION_TYPE_OPERATOR
        this.defaultConditionType = CONDITION_TYPE[this.isCustomFlag][0]
        this.conditionValueObj = {}
        this.conditionTypeObj = {}
        this.conditionOperatorObj = {}
        this.conditionTypeOperatorObj = {}
        this.state = {
            selectedOptionConditionType: this.props.conditionCollection[this.props.index] !== undefined ? this.getSelectedCondition() : initValues.condition,
            optionConditionTypeOperator: [],
            selectedOptionConditionTypeOperator: initValues.conditionTypeOperator,
            conditionTypeError: false,
            checkedOption: [],
            requestData: {},
            showLoader: false,
        }
    }

    getSelectedCondition = () => {
        let conditioValue = this.conditionTypeList.filter(condition => {
            return condition.value === this.props.conditionCollection[this.props.index].value
        })
        return conditioValue[0]
        // this.setState({
        //     selectedOptionConditionType : conditioValue
        // })
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (prevProps.openDrawer !== this.props.openDrawer && this.props.openDrawer === false) {
            this.handleCancel()
        }
        if (prevProps.isConditionEdit !== this.props.isConditionEdit && this.props.isConditionEdit === true) {
            let selectedOptionConditionType = this.props.conditionCollection[this.props.index] !== undefined ? this.getSelectedCondition() : initValues.condition
            this.setState({ selectedOptionConditionType })
            if (selectedOptionConditionType.value) {
                let operators = this.conditionOperatorTypeList[selectedOptionConditionType.value].filter(operator => {
                    return operator.value === this.props.conditionOperator[this.props.index]
                })
                this.setState({
                    optionConditionTypeOperator: this.conditionOperatorTypeList[selectedOptionConditionType.value],
                    selectedOptionConditionTypeOperator: operators[0]
                })
            }
        }
        if (this.props.conditionValueList !== prevProps.conditionValueList) {
            if (Object.keys(this.props.conditionValueList).filter(index => this.props.conditionValueList[index] !== null).length === 0) {
                this.queryParams = []
                this.props.clearAllAction()
                if (Object.keys(this.props.conditionValueList).length === 0) {
                    this.setState({
                        selectedOptionConditionType: CONDITION_TYPE[this.isCustomFlag][0],
                        optionConditionTypeOperator: [],
                        selectedOptionConditionTypeOperator: initValues.conditionTypeOperator,
                        conditionTypeError: false
                    })
                }
            }
        }
    }
    handleChangeConditionType = (selectedOptionConditionType, index = null) => {
        let conditionIndex = index === null ? this.props.index : index
        if (selectedOptionConditionType && this.state.selectedOptionConditionType.value !== selectedOptionConditionType.value) {
            let removeAutomationConditionObj = { 'index': conditionIndex, 'value': null }
            this.props.removeAutomationConditionByKey(removeAutomationConditionObj)
            if (!this.isConditionTypePresent(selectedOptionConditionType.value)) {
                // this.setState({ selectedOptionConditionType });
                this.setConditionTypeError(false)
                let conditionTypeValue = null, conditionOperatorValue = null
                if (selectedOptionConditionType.value !== '') {
                    conditionTypeValue = {}
                    conditionTypeValue['value'] = selectedOptionConditionType.value
                    // if (this.conditionOperatorTypeList[selectedOptionConditionType.value] !== undefined)
                    //     conditionOperatorValue = this.conditionOperatorTypeList[selectedOptionConditionType.value][0]['value']
                    conditionTypeValue['field'] = selectedOptionConditionType.field
                }
                this.conditionTypeObj = { 'index': conditionIndex, 'value': conditionTypeValue, 'type': CONDITION_TYPE_COLLECTION }
                // this.props.setAutomationCondition(conditionTypeObj)
                this.conditionOperatorObj = { 'index': conditionIndex, 'value': conditionOperatorValue, 'type': CONDITION_OPERATOR }
                // this.props.setAutomationCondition(conditionOperatorObj)
                if (this.props.parameterId !== null && index !== null) {
                    this.props.setAutomationCondition(this.conditionTypeObj)
                    this.props.setAutomationCondition(this.conditionOperatorObj)
                } else {
                    this.setState({ selectedOptionConditionType });
                }
                if (selectedOptionConditionType.value) {
                    this.setState({
                        optionConditionTypeOperator: this.conditionOperatorTypeList[selectedOptionConditionType.value],
                        selectedOptionConditionTypeOperator: this.conditionOperatorTypeList[selectedOptionConditionType.value]
                    })
                } else {
                    this.setState({ optionConditionTypeOperator: null, selectedOptionConditionTypeOperator: initValues.conditionTypeOperator })
                }
            } else {
                // this.setState({ selectedOptionConditionType: this.defaultConditionType });
                this.setConditionTypeError(true)
                this.errorMsg = { type: 'ERROR', 'message': CONDITION_TYPE_ERROR }
                this.conditionTypeObj = {}
                this.conditionOperatorObj = {}
            }
        }

        /*  else
             this.setState({ selectedOptionConditionType: this.defaultConditionType }); */
    }
    handleChangeConditionTypeOperator = (selectedOptionConditionTypeOperator, index = null) => {
        let conditionIndex = index === null ? this.props.index : index
        if (selectedOptionConditionTypeOperator) {
            this.setState({ selectedOptionConditionTypeOperator });
            this.conditionTypeOperatorObj = { 'index': conditionIndex, 'value': selectedOptionConditionTypeOperator.value, 'type': CONDITION_OPERATOR }
            // this.props.setAutomationCondition(conditionTypeObj)
            if (this.props.parameterId !== null && index !== null) {
                this.props.setAutomationCondition(this.conditionTypeOperatorObj)
            }
        } else {
            this.conditionTypeOperatorObj = {}
        }
    }
    isConditionTypePresent = (conditionType = null) => {
        if (Object.keys(this.props.conditionCollection).length > 0) {
            let type = {}
            let response = false
            type = Object.keys(this.props.conditionCollection).filter(index => (this.props.conditionCollection[index] !== null && this.props.conditionCollection[index]['value'] === conditionType))
            if (Object.keys(type).length > 0) {
                response = true
            }
            return response
        } else {
            return false
        }
    }

    setConditionTypeError = (isError = false) => {
        if (this.state.conditionTypeError !== isError) {
            this.setState({ conditionTypeError: isError })
        }
    }
    componentDidMount() {
        if (this.props.parameterId && (Object.keys(this.props.automationDetail).length > 0) && this.props.setDataFlag) {
            let automationData = this.props.automationDetail
            for (let index = 1; index <= automationData.condition.length; index++) {
                let conditionObj = automationData.condition[parseInt(index, 10) - 1]
                if (conditionObj && this.props.conditionCollection[parseInt(index, 10)] !== null) {
                    this.queryParams = conditionObj['query_param_value']
                    this.handleChangeConditionType(getLabel(this.conditionTypeList, conditionObj.collection, true), index)
                    this.handleChangeConditionTypeOperator(getLabel(this.conditionOperatorTypeList[conditionObj.collection], conditionObj.operator, true), index)
                    this.setConditionValueForUpdate(index)
                }
            }

        }
    }

    setConditionValueForUpdate = (conditionIndex) => {
        let selectedList = []
        for (let index = 0; index < this.queryParams.length; index++) {
            selectedList.push(this.queryParams[index])
        }
        this.setConditionValues(selectedList, conditionIndex)
    }
    setConditionValues = (listValue, index) => {
        let conditionValueObj = { 'index': index, 'value': listValue, 'type': CONDITION_VALUE }
        this.props.setAutomationCondition(conditionValueObj)
    }
    onOptionChangeHandler = (option) => {
        this.setState({
            checkedOption: option
        })
    }

    toggleDrawer = () => {
        this.props.closeDrawer()
        this.conditionTypeObj = {}
    }

    handleCancel = () => {
        this.conditionValueObj = {}
        if (!this.props.isConditionEdit) {
            this.setState({ selectedOptionConditionType: initValues.condition })
        } else {
            this.toggleDrawer()
        }
    }

    saveConditionValue = (conditionValue) => {
        this.conditionValueObj = conditionValue
    }
    conditionSave = () => {
        if (this.props.isConditionEdit) {
            let saveFlag = true
            if (this.props.conditionValueList[this.props.index] !== null) {
                if (Object.keys(this.conditionValueObj).length > 0) {
                    if (this.conditionValueObj.value !== null) {
                        this.props.setAutomationCondition(this.conditionValueObj)
                    } else {
                        saveFlag = false
                        this.setConditionTypeError(true)
                        this.errorMsg = { type: 'ERROR', 'message': CONDITION_VALUE_REQUIRED }
                        this.conditionValueObj = {}
                    }
                }
                if (saveFlag === true) {
                    this.props.conditionSave()
                    if (Object.keys(this.conditionTypeOperatorObj).length > 0)
                        this.props.setAutomationCondition(this.conditionTypeOperatorObj)
                    this.conditionTypeObj = this.conditionOperatorObj = this.conditionTypeOperatorObj = this.conditionValueObj = {}
                    this.props.closeDrawer()
                }
            } else {
                this.setConditionTypeError(true)
                this.errorMsg = { type: 'ERROR', 'message': CONDITION_VALUE_REQUIRED }
            }
        } else {
            if (Object.keys(this.conditionValueObj).length > 0 && Object.keys(this.conditionTypeObj).length > 0 && Object.keys(this.conditionOperatorObj).length > 0 && Object.keys(this.conditionTypeOperatorObj).length > 0 && this.conditionValueObj.value !== null) {
                this.props.conditionSave(this.props.conditionValueList)
                this.props.setAutomationCondition(this.conditionTypeObj)
                this.props.setAutomationCondition(this.conditionOperatorObj)
                this.props.setAutomationCondition(this.conditionTypeOperatorObj)
                this.props.setAutomationCondition(this.conditionValueObj)
                this.conditionTypeObj = this.conditionOperatorObj = this.conditionTypeOperatorObj = this.conditionValueObj = {}
                this.props.closeDrawer()
            } else {
                if (Object.keys(this.conditionValueObj).length <= 0 || this.conditionValueObj.value === null) {
                    this.setConditionTypeError(true)
                    this.errorMsg = { type: 'ERROR', 'message': CONDITION_VALUE_REQUIRED }
                } else {
                    this.setConditionTypeError(true)
                    this.errorMsg = { type: 'ERROR', 'message': CONDITION_OPERATOR_REQUIRED }
                }

            }
        }

    }
    toggleFlashMessage = () => {
        this.setConditionTypeError(false)
    }
    render() {
        const { classes } = this.props
        return (
            <React.Fragment>
                <Drawer
                    classes={{
                        paper: classes.drawerContainer,
                    }}
                    variant="temporary"
                    anchor="right"
                    open={this.props.openDrawer}
                    onClose={this.toggleDrawer}
                    BackdropProps={{ invisible: true }}
                    transitionDuration={500}
                >
                    <Grid container className={classes.drawerHeader}>
                        <Typography className={classes.drawerTitle}>Shipment Conditions</Typography>
                        <IconButton
                            color="inherit"
                            onClick={(event) => this.toggleDrawer()}
                            className={classes.draweCancelButton}
                            edge="start"
                        >
                            <img alt="" src={cancelIconSettings} />
                        </IconButton>
                    </Grid>
                    <Scrollbars style={{ height: window.innerHeight - 100, clear: 'both' }} className="detailScrollbarHeight" autoHide={true}>
                        <Grid container className={classes.drawerTextFieldContainer}>
                            <div className="exprtWrapper">
                                <div className="ModalText">
                                    <p className="removeBlockPadding">
                                        Conditions
                                </p>
                                </div>
                                {(!this.state.selectedOptionConditionType.value) ?
                                    <div className="ModalList">
                                        <List>
                                            {this.conditionTypeList.map((option) => {
                                                const labelId = `checkbox-list-label-${option.value}`;

                                                return (
                                                    <ListItem
                                                        classes={{
                                                            root: classes.listItemRoot
                                                        }}
                                                        disableGutters={true}
                                                        key={option.value}
                                                        role={undefined}
                                                        dense
                                                        button
                                                    >
                                                        <ListItemIcon

                                                            className={classes.listIcon}>
                                                            <Radio
                                                                classes={{
                                                                    root: classes.radioRoot
                                                                }}
                                                                checked={option.value === this.state.selectedOptionConditionType.value}
                                                                onChange={(event) => this.handleChangeConditionType(option)}
                                                                name="radio-button-demo"
                                                                color='primary'
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText className={classes.drawerRadioLable} disableTypography={true} id={labelId} primary={option.label} />
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </div> :
                                    <div>
                                        <div className="ModalList">
                                            <List>
                                                {this.state.optionConditionTypeOperator.map((option) => {
                                                    const labelId = `checkbox-list-label-${option.value}`;

                                                    return (
                                                        <ListItem classes={{
                                                            root: classes.listItemRoot
                                                        }}
                                                            disableGutters={true} key={option.value} role={undefined} dense button>
                                                            <ListItemIcon className={classes.listIcon}>
                                                                <Radio
                                                                    classes={{
                                                                        root: classes.radioRoot
                                                                    }}
                                                                    checked={option.value === this.state.selectedOptionConditionTypeOperator.value}
                                                                    onChange={(event) => this.handleChangeConditionTypeOperator(option)}
                                                                    name="radio-button-demo"
                                                                    color='primary'
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText className={classes.drawerRadioLable} disableTypography={true} id={labelId} primary={option.label} />
                                                            {
                                                                option.value === this.state.selectedOptionConditionTypeOperator.value ?
                                                                <LoadComponent saveConditionValue={this.saveConditionValue} queryParamValue={this.queryParams} index={this.props.index} type="condition" page={this.state.selectedOptionConditionType.component} {...this.props} />
                                                                :""
                                                            }
                                                        </ListItem>
                                                    );
                                                    
                                                })}
                                            </List>
                                        </div>
                                        {/* <LoadComponent saveConditionValue={this.saveConditionValue} queryParamValue={this.queryParams} index={this.props.index} type="condition" page={this.state.selectedOptionConditionType.component} {...this.props} /> */}
                                    </div>
                                }
                            </div>
                        </Grid>
                    </Scrollbars>
                    {this.state.selectedOptionConditionType.value ?
                        <footer className={classes.drawerFooter} justify="left">
                            <Button
                                onClick={this.conditionSave}
                                startIcon={<SaveIcon />}
                                // disabled={}
                                className={classes.saveBtn}>
                                Save
                        </Button>
                            {/* {(this.state.showLoader) && <CircularProgress size={22} className={classes.buttonProgress} />} */}
                            <Button startIcon={<CloseIcon />} onClick={(event) => this.handleCancel()} className={classes.deleteButtonAction}>
                                CANCEL
                              </Button>
                        </footer>
                        : ""}
                    {(this.state.conditionTypeError) ?
                        <FlashMessage open={this.state.conditionTypeError} classes={classes.snackBar} messageDetails={this.errorMsg} toggleFlashMessage={this.toggleFlashMessage} />
                        : null
                    }
                </Drawer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    conditionValueList: state.automations.list.conditionValueList,
    conditionCollection: state.automations.list.conditionCollection,
    automationDetail: state.automations.list.automationDetail,
    productCustomField: state.automations.list.productCustomField,
    conditionOperator: state.automations.list.conditionOperator,

})

export default withStyles(styles)(
    withRouter(connect(mapStateToProps, { setAutomationCondition, clearAllAction, removeAutomationConditionByKey })(ConditionDrawer))
)