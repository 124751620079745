import React from 'react'
import { withStyles } from '@material-ui/core'
import { trackingsData } from '../actions/actionMethods'
import { changeDateFunction } from '../common/Functions'
import AllTrackingsDataChart from './allTrackingsDataChart'
import { statusColor } from '../common/constants'
import { flightIcon } from '../../common/imageList'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Styles from '../static/Styles'
import DashboardCourierAutocomplete from "../common/DashboardCourierAutocomplete";
import DashboardPopover from "../common/DashboardPopover";
const REACT_S3_URL = process.env.REACT_APP_S3_URL

class AllTrackingsData extends React.Component {
	constructor(props) {
		super(props)
		this.selectedCourier = null
		this.state = {
			dateType: 'Last 30 Days',
			chartData: [],
			toDate: '',
			fromDate: '',
			chartLoader: true,
			trackingdata: [],
			statusColor: statusColor,
			color: [],
			displaydata: [],
			count: '',
			fromUTCDateValue: '',
			toUTCDateValue: ''
		}
	}
	componentWillMount() {
		let dateObj = changeDateFunction('30Days', false)
		this.setState({ fromDate: dateObj.fromDate, toDate: dateObj.toDate, fromUTCDateValue: '', toUTCDateValue: '' })
		this.getTrackingsData(dateObj.fromDate, dateObj.toDate)
	}
	getTrackingsData = (fromDate, toDate) => {
		let data = {}
		data.from_date = fromDate
		data.to_date = toDate
		if (this.selectedCourier && this.selectedCourier !== null && this.selectedCourier.value !== 'all') {
			data.courier = this.selectedCourier.value
		}
		this.setState({ courierLoadingFlag: true })
		trackingsData(data).then(res => {
			if (res.status >= 200 && res.status <= 299) {
				this.setState({
					trackingdata: res.data.data.status,
					count: res.data.data.total_count,
					courierLoadingFlag: false
				})
				this.setChartData()
			} else {
				this.setState({ courierLoadingFlag: false })
			}
		}).catch(error => {
			console.log(error)
			this.setState({ courierLoadingFlag: false })
		})
	}
	setCustomDateRange = (fromUTCDate, toUTCDate) => {
		this.getTrackingsData(fromUTCDate, toUTCDate)
		this.setState({
			fromUTCDateValue: fromUTCDate,
			toUTCDateValue: toUTCDate,
			fromDate: fromUTCDate,
			toDate: toUTCDate
		})
	}
	setDateType = (dateType, fromDate, toDate) => {
		this.setState({dateType: dateType, fromDate: fromDate, toDate:toDate, fromUTCDateValue: '', toUTCDateValue: ''})
	}
	handleChangeCourier = (selectedOption) => {
		this.selectedCourier = selectedOption
		let isCustomRangeDate = ((this.state.fromUTCDateValue && this.state.fromUTCDateValue !== '') && (this.state.toUTCDateValue && this.state.toUTCDateValue !== ''))
		if (isCustomRangeDate) {
			this.getTrackingsData(this.state.fromUTCDateValue, this.state.toUTCDateValue)
		}
		else {
			this.getTrackingsData(this.state.fromDate, this.state.toDate)
		}
	}
	setChartData = () => {
		let displaydata = []
		let data = []
		let trackingdata = this.state.trackingdata
		let statuscolor = this.state.statusColor
		for (let i = 0; i < trackingdata.length; i++) {
			for (let j = 0; j < statuscolor.length; j++) {
				if (trackingdata[i].status === statuscolor[j].status) {
					let temp = {}
					temp.name = trackingdata[i].status
					temp.y = trackingdata[i].count
					temp.color = statuscolor[j].color
					data.push(temp)
					let icon = ''
					let status = trackingdata[i].status
					if (status === 'InfoReceived') {
						status = 'Info Received'
						icon = 'InfoReceived.svg'
					}
					if (status === 'FailedAttempt') {
						status = 'Failed Attempt'
						icon = 'FailedAttempt.svg'
					}
					if (status === 'InTransit') {
						status = 'In Transit'
						icon = 'InTransit.svg'
					}
					if (status === 'OutForDelivery') {
						status = 'Out For Delivery'
						icon = 'OutForDelivery.svg'
					}
					if (status === 'Pending') {
						icon = 'Pending.svg'
					}
					if (status === 'Expired') {
						icon = 'Expired.svg'
					}
					if (status === 'Exception') {
						icon = 'Exception.svg'
					}
					if (status === 'Delivered') {
						icon = 'Delivered.svg'
					}
					displaydata.push({ icon: icon, key: trackingdata[i].status, status: status, color: statuscolor[j].color, percent: trackingdata[i].percentage, count: trackingdata[i].count })
				}
			}
		}
		this.setState({
			chartData: data,
			displaydata: displaydata,
		})
	}
	
	render() {
		const { classes } = this.props
		let summaryData = []
		if (this.state.displaydata.length > 0) {
			for (let i = 0, len = this.state.displaydata.length; i < len; i++) {
				let value = this.state.displaydata[i]
				summaryData.push(
					<div key={i} className='summaryData' onClick={(key) => this.props.onItemClick(value.key, this.state.dateType, this.state.fromDate, this.state.toDate, this.selectedCourier)}>
						<div style={{ float: 'left', width: '68%' }}>
							<div className='legendBullet'><img src={REACT_S3_URL + value.icon} alt="" /></div>
							<div className="statusName">
								<div className='title'> {value.status}</div>
								<div className='subtitle'> {value.count}&nbsp;Shipments</div>
							</div>
						</div>
						<div style={{ float: 'right', width: '30%', textAlign: 'right', paddingRight: '1rem' }}>
							<div className='value'> {parseFloat(value.percent).toFixed(2) + "%"} </div>
						</div>
					</div>
				)
			}
		}
		
		return (
			< Card id="shipments" className="cardNormal" style={{ cursor: 'default', float: 'none', width: '99.75%' }}>
				<CardHeader id="shipments" className="shipmentStatus"
					avatar={
						<Avatar>
							<img alt="" src={flightIcon} />
						</Avatar>
					}
					action={
						<Grid className="MuiCardHeader-action" style={{ display: "inline-flex", float: 'right' }}>
							{
								this.props.addedCourierList && this.props.addedCourierList.length > 0 ?
									<div>
										<DashboardCourierAutocomplete
											addedCourierList={this.props.addedCourierList}
											handleChangeCourier={this.handleChangeCourier}
										/>
									</div> : ''
							}
							<DashboardPopover
							    type='AllShipment'
								getData={this.getTrackingsData}
								setCustomDateRange={this.setCustomDateRange}
								setDateType={this.setDateType}
							/>
						</Grid>
					}
					title={<Typography className="headingText">All Shipments</Typography>}
				/>
				<CardContent style={{ paddingLeft: '76px', paddingRight: '76px', paddingBottom: '25px', height: '417px' }}>
					{
						this.state.courierLoadingFlag ?
							<div style={{ width: '400px', margin: '125px auto', opacity: '0.5' }}> <CircularProgress size={42} className={classes.progress} /> </div>
							:
							this.state.count === 0 ? <div className='noData' style={{ height: "10rem" }}>0 Shipments</div>
								:
								<div className='InboxSummary' >
									<div className="pieChartContainer" > <AllTrackingsDataChart data={this.state.chartData} count={this.state.count} /></div>
									<div className="pieChartSummaryContainer">
										{summaryData}
									</div>
								</div>
					}
				</CardContent>
			</Card >
		)
	}
}
export default withStyles(Styles)(AllTrackingsData)