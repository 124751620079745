import React from 'react'
const REACT_S3_URL = process.env.REACT_APP_S3_URL

const TrackingCheckpoints = props => {
    if (props.trackingCheckpoints) {

        let dataToDisplay = []

        props.trackingCheckpoints.map((element, indexValue) => {
            if(indexValue !== 0){
            let statusType = ''
            let paddingStyle
            let lastIndex = props.trackingCheckpoints.length - 1
            let backGroundPositionStyle

            if (indexValue === lastIndex) {
                backGroundPositionStyle = '160px 0px'
            }
            else {
                backGroundPositionStyle = '0px 0px'
            }
           
            paddingStyle = {backgroundPosition: backGroundPositionStyle }

            if (element.tag === 'InTransit') {
                statusType = 'InTransit'
            }

            else if (element.tag === 'Delivered') {
                statusType = 'Delivered'
            }

            else if (element.tag === 'Pending') {
                statusType = 'Pending'
            }

            else if (element.tag === 'OutForDelivery') {
                statusType = 'OutForDelivery'
            }

            else if (element.tag === 'InfoReceived') {
                statusType = 'InfoReceived'
            }

            else if (element.tag === 'FailedAttempt' || element.tag === 'AttemptFail'){
                statusType = 'FailedAttempt'
            }

            else if (element.tag === 'Expired'){
                statusType = 'Expired'
            }

            else if (element.tag === 'Exception'){
                statusType = 'Exception'
            }
            
            dataToDisplay.push(
                <tr key={indexValue}>
                    <td id = "nthCheckpoint"className="trackingTimelineStatusIcon" style={paddingStyle}>
                        <div title={statusType}>
                            <img src={REACT_S3_URL+statusType+'.svg'} />
                        </div>
                    </td>
                    <td className="trackingTimelineInfo" style={paddingStyle}>
                        <div className="itemLocation">
                            {element.location}
                        </div>
                        <div className="trackingMessage">
                            

                        <div dangerouslySetInnerHTML={{ __html:element.data }} />

                        </div>
                    </td>
                    <td className="trackingTimelineDateTimeValue" style={paddingStyle}>
                        <p>
                            {element.date[0] +' '+element.date[1]}
                        </p>
                    </td>
                </tr>
            )
        }
        })


        return (
            <div className="trackingHistoryTimelineInnerDiv">
                <table>
                    <tbody>
                        {dataToDisplay}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TrackingCheckpoints