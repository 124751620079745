import React from 'react'
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import AddedActionCard from './AddedActionCard'
import AddedConditionCard from './addedConditionCard'
import { CONDITION_OPERATOR } from '../../constants/defaultValues'

const styles = theme => ({
    card: {
        width: 352,
        "&:hover": {
            background: "#e3eefc"
        }
    },
    title: {
        fontWeight: 500,
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "20px",
        lineHeight: "normal",
        letterSpacing: "0.15px",
    },
    headerRoot: {
        padding: "15px 16px",
        alignItems: "stretch"
    },
    subHeader: {
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.43,
        letterSpacing: "0.14px",
        color: "#5e6367",
        paddingTop: "2px",

    },
    content: {
        padding: "0px",
        "&:last-child": {
            paddingBottom: "0px"
        },
    },
    mainDiv: {
        position: "absolute",
        left: "calc(50% - 176px)",
        marginTop: "45px"
    },
    containerDiv: {
        width: "100%",
        height: "100%",
        backgroundColor: "#f2f4f5"
    },
    button: {
        color: "#1a73e8",
        lineHeight: 1.14,
        letterSpacing: "0.75px",
    },
    divider: {
        margin: "0 auto",
        height: "24px !important"
    },
    dividerHorizontal: {
        margin: "0 auto",
        width: "48px !important",
        height: "2px"
    },
    small: {
        width: "26px",
        height: "26px",
        margin: "0 auto",
        cursor: "pointer"
    },
    headerAvatar: {
        marginTop: "3px"
    },
    cardAction: {
        paddingTop: "4px"
    },
    addButton: {
        width: "53px",
        height: "27px",
        borderRadius: "3px",
        border: "solid 1px #cbd6e2",
        backgroundColor: "#eaf0f6",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "0.12px",
        fontSize: "12px",
        marginBottom: "10px",
        paddingTop: "7px",
        color: "#5e6367",
    },
    orButton: {
        width: "53px",
        height: "27px",
        borderRadius: "3px",
        border: "solid 1px #cbd6e2",
        backgroundColor: "#eaf0f6",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "0.12px",
        fontSize: "12px",
        marginBottom: "10px",
        paddingTop: "7px",
        color: "#5e6367",
        marginLeft: '6px'
    },
    addedCardContainerDiv: {
        margin: "0px 20px 0px 20px"
    },
    addedCardContainerConditionDiv: {
        margin: '6px 6px 0px 6px'
    },
    conditionContainerDiv: {
        margin: '0px 6px 6px 6px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
    }
});

class AutomationWorkflowCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            displayConditionCard: false
        }
    }

    addCard = () => {
        if (!this.state.displayConditionCard) {
            this.setState({ displayConditionCard: true })
        }
    }

    addConditionCard = () => {
        let conditionObjLength = Object.keys(this.props.totalSavedCondition).filter(index => (this.props.totalSavedCondition[index] !== null)).length
        return Object.keys(this.props.totalSavedCondition).
            filter(index => (this.props.totalSavedCondition[index] !== null)).
            map((conditionKey, mapIndex) =>
                <AddedConditionCard
                    conditionObjLength={conditionObjLength} 
                    mapIndex={mapIndex} 
                    key={conditionKey} 
                    index={conditionKey} 
                    operator={this.props.operator} 
                    removeCondition={this.props.removeCondition} 
                    addedConditionCardClick={() => this.addedConditionCardClick(conditionKey)} 
                />
            )
    }

    addedConditionCardClick = (indexValue) => {
        this.props.addedConditionClick(indexValue)
    }

    addedActionCard = () => {
        return Object.keys(this.props.totalSavedActions).
            filter(index => (this.props.totalSavedActions[index] !== null)).
            map(actionKey =>
                <AddedActionCard key={actionKey} index={actionKey} removeAction={this.props.removeAction} addedActionCardClick={this.addedActionCardClick} trigger={this.props.trigger === 'SHIPMENT' ? 'SHIPMENT' : 'CUSTOM'} triggerType={this.props.triggerType} />
            )
    }

    addedActionCardClick = (indexValue) => {
        this.props.addedActionClick(indexValue)
    }

    render() {
        const { classes } = this.props
        let conditionObj = this.props.totalSavedCondition !== null && this.props.totalSavedCondition !== undefined ? Object.keys(this.props.totalSavedCondition).filter(index => (this.props.totalSavedCondition[index] !== null)) : []
        let actionObj = this.props.totalSavedActions !== null && this.props.totalSavedActions !== undefined ? Object.keys(this.props.totalSavedActions).filter(index => (this.props.totalSavedActions[index] !== null)) : []
        return (
            <React.Fragment>
                <Card className={classes.card}>
                    <CardContent className={classes.content}>
                        <CardHeader
                            classes={{
                                title: classes.title,
                                subheader: classes.subHeader,
                                root: classes.headerRoot,
                                avatar: classes.headerAvatar
                            }}
                            avatar={
                                <Avatar aria-label="Trigger" src={this.props.avatar} />
                            }
                            title={this.props.title}
                            subheader={this.props.subHeader}
                        />
                    </CardContent>
                    {(actionObj.length > 0 && this.props.cardType === 'action') || (conditionObj.length > 0 && this.props.cardType === 'condition') || this.props.triggerExist ?
                        <div>

                            {this.props.cardType === 'action' ?
                                <div className={classes.addedCardContainerDiv}>
                                    {this.addedActionCard()}
                                    <Button className={classes.addButton} onClick={(event) => this.props.onButtonClick()}>Add</Button>
                                </div>

                                : this.props.cardType === 'condition' ?
                                    <div>
                                        <div className={classes.conditionContainerDiv}>
                                            <div className={classes.addedCardContainerConditionDiv}>
                                                {this.addConditionCard()}
                                                {
                                                    conditionObj.length > 1 ?
                                                        <Button className={classes.addButton} onClick={(event) => this.props.onButtonClick(this.props.operator)}>{this.props.operator}</Button>
                                                        :
                                                        <Button className={classes.addButton} onClick={(event) => this.props.onButtonClick(CONDITION_OPERATOR[0].value)}>AND</Button>
                                                }
                                            </div>
                                        </div>
                                        {
                                            conditionObj.length > 1 ? ""
                                                :
                                                <Button className={classes.orButton} onClick={(event) => this.props.onButtonClick(CONDITION_OPERATOR[1].value)}>OR</Button>
                                        }

                                    </div>
                                    :
                                    this.props.allowToChangeTrigger === false && this.props.cardType === "trigger" ? ""
                                        :
                                        <CardActions className={classes.cardAction} disableSpacing>
                                            <Button className={classes.button} onClick={(event) => this.props.onButtonClick()}>Change Trigger</Button>
                                        </CardActions>
                            }

                        </div>
                        :
                        <CardActions className={classes.cardAction} disableSpacing>
                            <Button className={classes.button} onClick={(event) => this.props.onButtonClick(CONDITION_OPERATOR[0].value)}>{this.props.buttonText}</Button>
                        </CardActions>
                    }
                    {/* {this.props.totalSavedCondition !== null && this.props.cardType === 'condition' ?
                        <div className={classes.addedCardContainerDiv}>
                            {this.addConditionCard()}
                            <Button className={classes.addButton} onClick={(event) => this.props.onButtonClick()}>AND</Button>
                        </div>
                        :""
                    } */}
                </Card>
                <Divider orientation="vertical" flexItem className={classes.divider} />
                {/* {this.props.onAvatarClick !== undefined ? <Avatar onClick={this.props.onAvatarClick} alt="Add" src={smallAddIcon} className={classes.small} /> : null} */}
                <Divider orientation="vertical" flexItem className={classes.divider} />
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(AutomationWorkflowCard)