import {
    USPSLOGO,
    UPSLOGO,
    FEDEX,
    DELHIVERY,
    DPD,
    ECMS,
    FASTWAY,
    LASERSHIP,
    DHL,
    GLS,
    TNT,
    AUSPOSTLOGO,
    BLUEDART,
    ARAMEX,
    CANADAPOST,
    BRING,
    JAPANPOST,
    DEFAULTCOURIERLOGO,
    OMNIVA,
    CNEEXPRESS,
    GWWORLDLOGO,
    NINETYNINELOGO,
    NINJAVANLOGO,
    CDLLOGO
} from '../common/imageList'
export function getCourierLabel(slug, addedTrackingList) {
    let label = ''
    if (addedTrackingList && addedTrackingList.length > 0) {
        for (let index = 0; index < addedTrackingList.length; index++) {
            const element = addedTrackingList[index];
            if (element.slug === slug) {
                label = element.title
            }
        }
    }
    return label
}
export function getTrackingStatusName(statusName) {
    let label = ''
    if (statusName === 'Pending') {
        label = statusName
    }
    else if (statusName === 'OutForDelivery') {
        label = 'Out For Delivery'
    }
    else if (statusName === 'InfoReceived') {
        label = 'Info Received'
    }
    else if (statusName === 'Expired') {
        label = statusName
    }
    else if (statusName === 'FailedAttempt') {
        label = 'Failed Attempt'
    }
    else if (statusName === 'Delivered') {
        label = statusName
    }
    else if (statusName === 'Exception') {
        label = statusName
    }
    else if (statusName === 'InTransit') {
        label = 'In Transit'
    }
    return label
}
export function showCourierLogo(courierName) {
    let logo = ''
    if (courierName === 'usps') {
        logo = USPSLOGO
    }
    else if (courierName === 'ups') {
        logo = UPSLOGO
    }
    else if (courierName === 'fedex') {
        logo = FEDEX
    }
    else if (courierName === 'dpd' || courierName === 'dpd-de') {
        logo = DPD
    }
    else if (courierName === 'ecms-global') {
        logo = ECMS
    }
    else if (courierName === 'fastway-au') {
        logo = FASTWAY
    }
    else if (courierName === 'delhivery') {
        logo = DELHIVERY
    }
    else if (courierName === 'lasership') {
        logo = LASERSHIP
    }
    else if (courierName === 'dhl' ||
        courierName === 'dhl-pieceid' ||
        courierName === 'dhl-benelux' ||
        courierName === 'dhl-germany' ||
        courierName === 'dhl-global-mail' ||
        courierName === 'dhl-global-mail-asia'
    ) {
        logo = DHL
    }
    else if (courierName === 'gls' ||
        courierName === 'gls-croatia' ||
        courierName === 'gls-cz'
    ) {
        logo = GLS
    }
    else if (courierName === 'tnt') {
        logo = TNT
    }
    else if (courierName === 'australia-post') {
        logo = AUSPOSTLOGO
    }
    else if (courierName === 'bluedart') {
        logo = BLUEDART
    }
    else if (courierName === 'aramex') {
        logo = ARAMEX
    }
    else if (courierName === 'canada-post') {
        logo = CANADAPOST
    }
    else if (courierName === 'posten-norge') {
        logo = BRING
    }
    else if (courierName === 'japan-post') {
        logo = JAPANPOST
    }
    else if (courierName === 'omniva') {
        logo = OMNIVA
    }
    else if (courierName === 'cnexps') {
        logo = CNEEXPRESS
    }
    else if (courierName === 'gw-world') {
        logo = GWWORLDLOGO
    }
    else if (courierName === '99minutos') {
        logo = NINETYNINELOGO
    }
    else if (courierName === 'cdl') {
        logo = CDLLOGO
    }
    else if (courierName === 'ninjavan') {
        logo = NINJAVANLOGO
    }
    else {
        logo = DEFAULTCOURIERLOGO
    }
    return logo
}

