import React from 'react';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch'
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { ROLE_ACCESS } from '../../common/constants';
import IconButton from '@material-ui/core/IconButton';
import {StyledTextField,StyledAutoComplete,StyledTypography} from '../../common/materialComponents'
import CircularProgress from '@material-ui/core/CircularProgress';
import {SaveIcon, CloseIcon, cancelIconSettings} from '../../../common/imageList';

class UserAndRoleDrawer extends React.Component{

    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.props.closeDrawer()
    };

    render(){
        const {classes} = this.props
        return(
               <React.Fragment>
                 <Drawer
                    variant = "persistent"
                    classes={{
                       paper: classes.drawerPaper
                    }}
                    anchor="right"
                    open={this.props.openDrawer}
                    onClose={this.toggleDrawer(false)}
                    BackdropProps={{ invisible: true }}
                    transitionDuration={500}
                 >
                     {
                         this.props.type === "user" ?
                            <React.Fragment>
                            <Grid container className={classes.drawerHeader}>
                            <StyledTypography className={classes.drawerTitle}> User</StyledTypography>
                            <IconButton
                                color="inherit"
                                onClick={this.toggleDrawer(false)}
                                className={classes.draweCancelButton}
                                edge="start"
                            >
                                <img alt="" src={cancelIconSettings} />
                            </IconButton>
                            {this.props.onChange ? <CircularProgress size={42} className={classes.drawerProgress} /> : ""}
                        </Grid>
    
                        <Grid container className={classes.drawerContent}>
                            <StyledTypography className={classes.subUserContentText}>Adding User</StyledTypography>
                        </Grid>    
    
                        <Grid container className={classes.drawerTextFieldContainer}>
                            <StyledTextField
                                 error={this.props.userNameError ? true : false}
                                 id="user_name"
                                 helperText={this.props.userNameError ? "Name is required" : ""}
                                 label="Name"
                                 variant="outlined"
                                 className={classes.drawerFields}
                                 value={this.props.userName}
                                 onChange={(e) => this.props.handleName(e.target.value)}
                                />
                        </Grid>
                        <Grid container className={classes.drawerTextFieldContainer}>
                            <StyledTextField
                                 error={this.props.emailIdError || !this.props.isEmailValid ? true : false}
                                 id="email"
                                 helperText={this.props.emailIdError ? "Email-Id is required" : !this.props.isEmailValid  ? "Email-Id is not valid" : ""}
                                 label="Account Email (Private)"
                                 variant="outlined"
                                 disabled = {this.props.isEditRole ? true : false}
                                 className={classes.drawerFields}
                                 value={this.props.emailId}
                                 onChange={(e) => this.props.handleEmail(e.target.value)}
                                />
                        </Grid>
                        <Grid container className={classes.drawerTextFieldContainer}>
                            <StyledAutoComplete
                                    id="role"
                                    value={this.props.selectedRole}
                                    openOnFocus = "true"
                                    className={`${classes.drawerAutoComplete}`}
                                    onChange={(e,selectedRole) => this.props.handleSelectedRole(e,selectedRole)}
                                    defaultValue={{label: "", value: ""}}
                                    options={this.props.roles}
                                    getOptionLabel={option => option.label}
                                    renderInput={params => <StyledTextField className={classes.drawerFields} error={this.props.selectedRoleError ? true : false} helperText={this.props.selectedRoleError ? "Role is required" : ""}  {...params} label="Role" variant="outlined" />}
                                /> 
                        </Grid>
                        <Grid container className={classes.drawerTextFieldContainer}>
                           {this.props.userData.active === 1 && this.props.userData.invite === 0 ?
                           <div style={{ cursor: 'pointer', maxWidth: '24px', color: "#1a73e8", whiteSpace: 'nowrap' }} onClick={(data) => this.props.resendInvite(this.props.userData)}>
                               Resend Invite
                           </div> : ""}
                        </Grid>
                        </React.Fragment>
                        :
                        <React.Fragment>
                         <Grid container className={classes.drawerHeader}>
                         <StyledTypography className={classes.drawerTitle}> Role</StyledTypography>
                         <IconButton
                             color="inherit"
                             onClick={this.toggleDrawer(false)}
                             className={classes.draweCancelButton}
                             edge="start"
                         >
                             <img alt="" src={cancelIconSettings} />
                         </IconButton>
                         {this.props.onChange ? <CircularProgress size={42} className={classes.drawerProgress} /> : ""}
                     </Grid>
 
                     <Grid container  className={classes.drawerContent}>
                         <StyledTypography className={classes.subUserContentText}>Add Role</StyledTypography>
                     </Grid>    
 
                     <Grid container className={classes.drawerTextFieldContainer}>
                           <StyledTextField
                              error={this.props.roleNameError ? true : false}
                              id="role_name"
                              helperText={this.props.roleNameError ? "Department name required" : ""}
                              label="Name"
                              variant="outlined"
                              className={classes.drawerFields}
                              value={this.props.roleName}
                              onChange={(e) => this.props.handleName(e.target.value)}
                            />
                     </Grid>
                     <Grid container className={classes.drawerTextFieldContainer}>
                           <StyledTextField
                              id="department"
                              label="Department"
                              variant="outlined"
                              className={classes.drawerFields}
                              value={this.props.department}
                              onChange={(e) => this.props.handleDepartment(e.target.value)}
                           />
                     </Grid>
                     <Grid container className={classes.drawerSwitchMainContainer}>
                        <StyledTypography className={classes.roleMouleTitle}>Module Access</StyledTypography>
                        <Grid className={classes.drawerSwitchContainer}>
                        {
                            Object.keys(ROLE_ACCESS).map(module=>{
                                return(
                              <React.Fragment>
                                {ROLE_ACCESS[module].map(data=>{ 
                                   let submodules = data.submodules? data.submodules : null
                                   let isChecked = this.props.switchStateArray[data.switchName]
                                    return (
                                    <div style={{width: '588px',marginBottom: '10px', marginTop:'10px'}} id={data.switchName}>
                                        <img  alt={data.switchName+"_IC"} src={data.moduleIcon} style={{marginLeft: '-6px'}} height="32" width="34"/>
                                        <span style={{verticalAlign: 'top', lineHeight: '2.2', marginLeft: '15px'}}>{data.label}</span>
                                        {/* <div style={{display: 'inline-flex',float: 'right', position: 'absolute'}}> */}
                                            <Switch 
                                                size="medium"
                                                className={isChecked ? classes.drawerRolesSwitchOn : classes.drawerRolesSwitchOff}
                                                name={data.switchName}
                                                checked={isChecked ? true : false} 
                                                onClick={(e,switchName,roleValue,label,subModule)=>{this.props.handleChangeRoles(e,data.switchName,data.roleValue, data.label, submodules)}}
                                            />
                                        {/* </div> */}
                                    </div>
                                    )
                                
                                })}
                                </React.Fragment>
                                )
                            })
                        }
                        </Grid>
                     </Grid>
                     </React.Fragment>
                     }
                    
                    <footer container justify="left" style={{position: 'fixed', bottom: '0'}}>
                                <Button 
                                    onClick={this.props.type === "user" ? this.props.isEditRole ? this.props.updateSubUser :this.props.saveSubUser : this.props.isEditRole ? this.props.updateRole : this.props.saveRole} 
                                    startIcon={<SaveIcon/>} 
                                    disabled={this.props.isLoading}
                                    className={(this.props.isLoading) ? classes.drawerSaveBtnDisable :classes.drawerSaveBtn}>
                                    SAVE
                                </Button>
                                {(this.props.isLoading) && <CircularProgress size={22} className={classes.drawerbuttonProgress} />}
                               <Button startIcon={<CloseIcon/>} onClick={this.toggleDrawer(false)} className={classes.drawerDeleteButton}>
                                 CANCEL
                              </Button>
                    </footer>
                </Drawer>
                </React.Fragment>
        )
    }   
}
export default UserAndRoleDrawer