import React from 'react'
import SettingCard from './settingCard'
import Styles from '../assets/css/Styles'
import { withStyles, Grid } from '@material-ui/core'

function settingsListGrid(props){
    const {classes} = props
    const menuList = props.menu
    const userType = props.userType

    if (userType === "subuser") {
      let index = menuList.findIndex(list => list.role === userType)
      if (index !== -1) {
        menuList.splice(index, 1)
      }
    }
    
    return(
            <React.Fragment>
               <div className={classes.content}>
                 <Grid container>
                    {menuList.map((item,index) => {
                      return(
                        <Grid key={item.title+index} className={classes.cardGridItem}>
                        <SettingCard 
                          key={index} 
                          title={item.title} 
                          path={item.path} 
                          icon={item.icon} 
                          subTitle={item.subTitle}
                          usetType={props.userType}
                          settingFlag={props.settingFlag}
                      />
                      </Grid>
                      )
                     })}
                 </Grid>
                </div>    
            </React.Fragment>
    )
}

export default withStyles(Styles)(settingsListGrid)