import React from 'react'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { getLabel, getConditonValue } from '../../common/Functions'
import { CONDITION_TYPE, CONDITION_TYPE_OPERATOR } from '../../constants/defaultValues'
import IconButton from '@material-ui/core/IconButton'
import { RemoveIcon, EditCustom } from '../../../common/imageList'
import Divider from '@material-ui/core/Divider'
import {splitRegex} from '../../../static/constants'
const styles = theme => ({
    root: {
        padding: '16px 16px 10px 16px',
        display: 'inline-block'

    },
    card: {
        width: 326,
        "&:hover": {
            background: "#e3eefc"
        },
        cursor: "pointer"
    },
    title: {
        fontWeight: "normal",
        color: "#666666",
        fontSize: "14px",
        lineHeight: "normal",
        letterSpacing: "0.16px",
    },
    value: {
        fontWeight: 500,
        color: "#1a73e8",
        fontSize: "14px",
        lineHeight: "normal",
        letterSpacing: "0.16px",
    },
    valueDiv: {
        padding: '0px 16px 16px 12px',
        overflowWrap: 'anywhere',
    },
    draweCancelButton: {
        float: 'right',
        display: 'none',
        padding: '5px',
        marginTop: '12px'
    },
    draweCancelButtonHover: {
        float: 'right',
        padding: '5px',
        marginTop: '12px'
    },
    draweEditButton: {
        float: 'right',
        display: 'none',
        padding: '1px',
        marginRight: '16px',
        marginTop: '12px'

    },
    draweEditButtonHover: {
        float: 'right',
        padding: '1px',
        marginRight: '16px',
        marginTop: '12px'
    },
    imageEdit: {
        height: '28px',
        width: '28px'
    },
    buttonDiv: {
        display: 'inline'
    },
    divider: {
        margin: "0 auto",
        height: "14px !important"
    },
    conditionJoinText: {
        width: "26px",
        height: "26px",
        margin: "0 149px",
    }
});

class AddedConditionCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            onHover: false
        }
    }

    addedConditionCardClick = () => {
        this.props.addedConditionCardClick(parseInt(this.props.index))
    }
    removeCondition = () => {
        this.props.removeCondition(parseInt(this.props.index))
    }

    onMouseOver = () => {
        this.setState({
            onHover: true
        })
    }

    onMouseOut = () => {
        this.setState({
            onHover: false
        })
    }

    render() {
        const { classes } = this.props
        let lastElement = false
        let conditionOperator = ''
        let conditionCollection = ''
        let index = this.props.index

        if (this.props.mapIndex === this.props.conditionObjLength - 1) {
            lastElement = true
        }
        if (this.props.conditionCollection[index] !== null && this.props.conditionOperator[index] != null && this.props.conditionValueList !== null) {
            conditionOperator = getLabel(CONDITION_TYPE_OPERATOR[this.props.conditionCollection[index].value], this.props.conditionOperator[index])
            conditionCollection = getLabel(CONDITION_TYPE['SHIPMENT'], this.props.conditionCollection[index].value)
        }
        // let requestParams = this.props.actionDetail[this.props.index]

        return (
            <div>
                {this.props.conditionCollection[index] !== null && this.props.conditionOperator[index] != null && this.props.conditionValueList !== null ?
                    <div>
                        <Card className={classes.card} variant="outlined" style={(lastElement) ? {marginBottom:"10px"} : {}} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
                            <CardHeader
                                classes={{
                                    title: classes.title,
                                    avatar: classes.headerAvatar,
                                    root: classes.root
                                }}
                                title={conditionCollection + ' ' + conditionOperator}
                            />
                            <div className={classes.buttonDiv}>
                                <IconButton
                                    color="inherit"
                                    onClick={this.removeCondition}
                                    className={this.state.onHover ? classes.draweCancelButtonHover : classes.draweCancelButton}
                                    edge="start"
                                >
                                    <RemoveIcon />
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    onClick={this.addedConditionCardClick}
                                    className={this.state.onHover ? classes.draweEditButtonHover : classes.draweEditButton}
                                    edge="start"
                                >
                                    <img alt="" src={EditCustom} className={classes.imageEdit} />
                                </IconButton>
                            </div>
                            <div className={classes.valueDiv}>
                                {
                                    getConditonValue(this.props.conditionValueList[index]).split(splitRegex).map((value, index) =>
                                        <React.Fragment key={index}>
                                            {index > 0 ?
                                                <span style={{ fontSize: '0.65rem' }} >{this.props.conditionOperator[index] === 'IS ALL' ? 'AND' : 'OR'}</span> : ''}&nbsp;
                            <span className={classes.value}>{value}</span>&nbsp;
                    </React.Fragment>)
                                }
                            </div>
                        </Card>
                        {lastElement === false ?
                            <React.Fragment>
                                <Divider orientation="vertical" flexItem className={classes.divider} />
                                <span className={classes.conditionJoinText}>{this.props.operator}</span>
                                <Divider orientation="vertical" flexItem className={classes.divider} />
                            </React.Fragment>
                            : null}
                    </div>
                    : ""}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    // actionDetail: state.automations.list.actionRequestParams,
    conditionValueList: state.automations.list.conditionValueList,
    // automationRule: state.automations.list,
    conditionOperator: state.automations.list.conditionOperator,
    conditionCollection: state.automations.list.conditionCollection,
})

export default (connect(mapStateToProps, {})(withStyles(styles)(AddedConditionCard)))