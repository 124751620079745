import React from 'react'

const pageNotFound = () => {
    return (
        <div className='page-not-found'>
            <span className='page-not-found-span'>Page Not Found.</span><br/>
            <span className='page-not-found-desc-span'>A 404 error was encountered. If you followed a link to this page, make sure<br/>that it is correct and that you have access to the page you are trying to view.</span>
        </div>
    )
}

export default pageNotFound