import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import AutomationWorkflowCard from './AutomationWorkflowCard'
import { automationConditionIcon, automationActionIcon, shipmentIcon } from '../../../common/imageList'
import TriggerDrawer from './triggerDrawer'
import ConditionDrawer from './conditionDrawer'
import ActionDrawer from './ActionDrawer'
import { Scrollbars } from 'react-custom-scrollbars';
import { removeAutomationActionByKey, removeAutomationConditionByKey, clearAllAction, setLogicalOperator } from '../../actions/actionMethods'
import omit from 'lodash/omit'
import { CONDITION_OPERATOR } from '../../constants/defaultValues'

const styles = () => ({
    mainDiv: {
        position: "absolute",
        left: "calc(50% - 176px)",
        marginTop: "45px"
    },
    scrollBarDiv: {
        width: "100%",
        height: "100%",
        backgroundColor: "#f2f4f5"
    },
    dividerHorizontal: {
        margin: "0 auto",
        width: "48px !important",
        height: "1px"
    },
});

const initValues = {
    actions: { 1: 1 },
    conditions: { 1: 1 }
}

class AutomationWorkflow extends React.Component {

    constructor(props) {
        super(props)
        this.isActionEdit = false
        this.isConditionEdit = false
        this.state = {
            displayConditionCard: this.props.selectedCustomTrigger && this.props.selectedCustomTrigger.value ? true : false,
            displayActionCard: this.props.selectedCustomTrigger && this.props.selectedCustomTrigger.value ? true : false,
            openTriggerDrawer: false,
            shipmentTriggerValue: this.props.selectedCustomTrigger ? this.props.selectedCustomTrigger : {},
            triggerSubHeaderValue: this.props.selectedCustomTrigger && this.props.selectedCustomTrigger.value ? this.props.selectedCustomTrigger.label : "How do you want to trigger this shipment based automation?",
            openActionDrawer: false,
            actions: this.getSelectedActionObject(),
            currentActionIndex: this.getInitialActionIndex(),
            conditions: null,
            currentConditionIndex: this.getInitialConditionIndex(),
            selectedOptionConditionOperator: this.props.parameterId && this.props.automationDetail.logical_operator === 'OR' ? CONDITION_OPERATOR[1] :CONDITION_OPERATOR[0],
            triggerExist : this.props.selectedCustomTrigger && this.props.selectedCustomTrigger.value ? true : false,
        }
    }

    getSelectedActionObject = () => {
        let actionObj = {}
        if(this.props.parameterId) {
            let automationData = this.props.automationDetail
            for(let index = 1; index <= automationData.action.length; index++){
                actionObj[index] = [index]
            }
        } else if(Object.keys(this.props.actionType).length > 0){
            for(let index = 1; index <= Object.keys(this.props.actionType).length; index++){
                actionObj[index] = [index]
            }
        }
        return actionObj
    }
    getInitialActionIndex = () => {
        let index = 1
        if(this.props.parameterId) {
            index = this.props.automationDetail.action.length + 1
        } else if(Object.keys(this.props.actionType).length > 0){
            index = Object.keys(this.props.actionType).length + 1
        }
        return index
    }
    getInitialConditionIndex = () => {
        let index = 1
        if(this.props.parameterId) {
            index = this.props.automationDetail.condition.length + 1
        } else if(Object.keys(this.props.conditionValueList).length > 0){
            index = Object.keys(this.props.conditionValueList).length + 1
        }
        return index
    }

    addConditionCard = () => {
        if (!this.state.displayConditionCard && this.state.triggerExist) {
            this.setState({ displayConditionCard: true })
        }
    }

    addActionCard = () => {
        if (!this.state.displayActionCard && Object.keys(this.props.conditionCollection).length > 0) {
            this.setState({ displayActionCard: true })
        }
    }

    selectTriggerClick = () => {            
        this.setState({
            openTriggerDrawer: true
        })
    }

    triggerDrawerClose = () => {
        this.setState({
            openTriggerDrawer: false
        })
    }

    triggerSave = (triggerValue) => {
        this.props.handleChangeTrigger(triggerValue)
        this.setState({
            shipmentTriggerValue: triggerValue,
            triggerSubHeaderValue: triggerValue.label,
            triggerExist : true,
            displayConditionCard : true
        })
    }

    selectActionClick = () => {
        this.setState({
            openActionDrawer: true
        })
    }

    actionDrawerClose = () => {
        this.setState({
            openActionDrawer: false
        })
        if (this.isActionEdit) {
            this.changeCurrentActionIndex()
            this.isActionEdit = false
        }
    }

    selectConditionClick = (operator) => {
        this.setState({
            openConditionDrawer: true,
            selectedOptionConditionOperator: operator === 'AND' ? CONDITION_OPERATOR[0] : CONDITION_OPERATOR[1]
        })
        this.props.setLogicalOperator(operator)
    }

    conditionDrawerClose = () => {
        this.setState({
            openConditionDrawer: false
        })
        if (this.isConditionEdit) {
            this.changeCurrentConditionIndex()
            this.isConditionEdit = false
        }
    }

    addMoreCondition = (conditionValue) => {
        if (this.isConditionEdit) {
            this.isConditionEdit = false
            this.changeCurrentConditionIndex()
        } else {
            if (this.state.conditions === null) {
                this.setState({ conditions: initValues.conditions, displayActionCard : true })
                this.setState({ currentConditionIndex: this.state.currentConditionIndex + 1 });
            } else {
                this.changeConditionIndex()
            }
        }
    }
    changeConditionIndex = () => {
        let conditionObj = { ...this.state.conditions }
        let lastIndex = 0
        if (Object.keys(conditionObj).length > 0) {
            lastIndex = parseInt(conditionObj[Object.keys(conditionObj)[Object.keys(conditionObj).length - 1]], 10)
        }
        conditionObj[lastIndex + 1] = lastIndex + 1
        this.setState({ conditions: conditionObj, currentConditionIndex: Object.keys(this.props.conditionCollection).length + 1 });

    }
    changeCurrentConditionIndex = () => {
        this.setState({ currentConditionIndex: Object.keys(this.props.conditionCollection).length + 1 });
    }
    addedConditionCardClick = (indexValue = 1) => {
        this.setState({ currentConditionIndex: indexValue, openConditionDrawer: true });
        this.isConditionEdit = true
    }
    removeCondition = (index) => {
        let conditionObj = omit(this.state.conditions, index);
        this.props.removeAutomationConditionByKey({ 'index': index, 'value': null })
        let conditionObject = this.props.conditionCollection ? Object.keys(this.props.conditionCollection).filter(collectionIndex => (this.props.conditionCollection[collectionIndex] !== null)) : []
        if (conditionObject.length === 1) {
            conditionObj = null
            this.props.clearAllAction()
            this.setState({ actions: null, displayActionCard : false, currentActionIndex : 1 });
        }
        this.setState({ conditions: conditionObj });
    }
    addMoreAction = () => {
        if (this.isActionEdit) {
            this.isActionEdit = false
            this.changeCurrentActionIndex()
        } else {
            if (this.state.actions === null) {
                this.setState({ actions: initValues.actions })
                this.setState({ currentActionIndex: 2 });
            } else {
                this.changeActionIndex()
            }
        }
    }

    changeActionIndex = () => {
        let actionObj = { ...this.state.actions }
        let lastIndex = 0
        if (Object.keys(actionObj).length > 0) {
            lastIndex = parseInt(Object.keys(actionObj)[Object.keys(actionObj).length - 1], 10)
        }
        actionObj[lastIndex + 1] = lastIndex + 1
        this.setState({ actions: actionObj, currentActionIndex: lastIndex + 2 });
    }

    removeAction = (index) => {
        let actionObj = { ...this.state.actions, [index]: null }
        this.props.removeAutomationActionByKey({ 'index': index, 'value': null })
        if (Object.keys(actionObj).length === 0) {
            actionObj = null
        }
        this.setState({ actions: actionObj });
    }

    changeCurrentActionIndex = () => {
        let actionObj = { ...this.state.actions }
        let lastIndex = 0
        if (Object.keys(actionObj).length > 0) {
            lastIndex = parseInt(actionObj[Object.keys(actionObj)[Object.keys(actionObj).length - 1]], 10)
        }
        this.setState({ currentActionIndex: lastIndex + 1 });
    }

    addedActionCardClick = (indexValue = 1) => {
        this.setState({ currentActionIndex: indexValue, openActionDrawer: true });
        this.isActionEdit = true
    }

    render() {
        const { classes } = this.props
        return (
            <Scrollbars autoHide className={classes.scrollBarDiv}>
                <div>
                    <div className={classes.mainDiv}>
                        <AutomationWorkflowCard
                            cardType="trigger"
                            title="Shipment Based"
                            subHeader={this.state.triggerSubHeaderValue}
                            buttonText="SELECT TRIGGER"
                            onAvatarClick={this.addConditionCard}
                            onButtonClick={this.selectTriggerClick}
                            avatar={shipmentIcon}
                            triggerExist={this.state.triggerExist}
                            allowToChangeTrigger = {this.props.allowToChangeTrigger}
                        />
                        {
                            !this.state.displayConditionCard ?
                                <Divider flexItem className={classes.dividerHorizontal} />
                                :
                                <AutomationWorkflowCard
                                    cardType="condition"
                                    totalSavedCondition={Object.keys(this.props.conditionCollection).length > 0 ? this.props.conditionCollection : null}
                                    title="Condition"
                                    subHeader="Which are the must have rules to trigger an automation"
                                    buttonText="SELECT CONDITION"
                                    onAvatarClick={this.addActionCard}
                                    avatar={automationConditionIcon}
                                    onButtonClick={this.selectConditionClick}
                                    addedConditionClick={this.addedConditionCardClick}
                                    index={this.state.currentConditionIndex}
                                    operator={this.state.selectedOptionConditionOperator.value}
                                    removeCondition={this.removeCondition}
                                />
                        }
                        {
                            !this.state.displayActionCard?
                                <Divider flexItem className={classes.dividerHorizontal} />
                                :
                                <React.Fragment>
                                    <AutomationWorkflowCard
                                        cardType="action"
                                        totalSavedActions={this.state.actions}
                                        title="Actions"
                                        subHeader="Which are the actions to be performed"
                                        buttonText="SELECT ACTION"
                                        avatar={automationActionIcon}
                                        onButtonClick={this.selectActionClick}
                                        addedActionClick={this.addedActionCardClick}
                                        removeAction={this.removeAction}
                                        trigger={this.props.trigger}
                                        triggerType ={this.props.triggerCondition}
                                    />
                                    <Divider flexItem className={classes.dividerHorizontal} />
                                </React.Fragment>
                        }
                    </div>
                    <TriggerDrawer
                        openDrawer={this.state.openTriggerDrawer}
                        closeDrawer={this.triggerDrawerClose}
                        triggerOption={this.props.triggerOption}
                        triggerSave={this.triggerSave}
                    />
                    <ConditionDrawer
                        openDrawer={this.state.openConditionDrawer}
                        closeDrawer={this.conditionDrawerClose}
                        index={this.state.currentConditionIndex}
                        conditionSave={this.addMoreCondition}
                        isConditionEdit={this.isConditionEdit}
                        parameterId={this.props.parameterId}
                        removeCondition={this.removeCondition}
                        storeList={this.props.storeList}
                        trigger={this.props.trigger}
                        triggerCondition={this.props.triggerCondition}
                        showConditionCard={this.state.showConditionCard}
                        setDataFlag = {this.props.setDataFlag}
                    />
                    <ActionDrawer
                        openDrawer={this.state.openActionDrawer}
                        closeDrawer={this.actionDrawerClose}
                        trigger={this.props.selectedTriggerType}
                        index={this.state.currentActionIndex}
                        saveAction={this.addMoreAction}
                        isActionEdit={this.isActionEdit}
                        removeAction={this.removeAction}
                        parameterId={this.props.parameterId}
                        setDataFlag = {this.props.setDataFlag}
                    />
                </div>
            </Scrollbars>
        )

    }
}
const mapStateToProps = state => ({
    conditionCollection: state.automations.list.conditionCollection,
    automationDetail: state.automations.list.automationDetail,
    actionType : state.automations.list.actionType,
    conditionValueList: state.automations.list.conditionValueList,
})
export default (connect(mapStateToProps, { removeAutomationActionByKey, removeAutomationConditionByKey, clearAllAction, setLogicalOperator })(withStyles(styles)(AutomationWorkflow)))