import React from 'react';
import Button from '@material-ui/core/Button';
import Styles from '../../../../assets/css/Styles';
import { withRouter } from 'react-router-dom';
import BreadCrumbsCard from '../../BreadCrumbsCard';
import IntegrationLogo from '../../IntegrationLogo';
import { withStyles } from '@material-ui/core/styles';
import {SHOPIFY} from '../../../../../common/imageList'

const onClick = () => {
    let URL = "https://apps.shopify.com/trackhive?surface_detail=trackhive&surface_inter_position=1&surface_intra_position=1&surface_type=search"
    window.open(URL, '_blank');
}

function ShopifyIntegration(props) {
        const { classes } = props
        return (
            <React.Fragment>
              <div className="settingsModule" style={{height: "100%"}}>
                        <BreadCrumbsCard 
                            classes={classes} 
                            storeName="Shopify" 
                         />
                    <div className="dataContainer">
                        <IntegrationLogo 
                            classes={classes}
                            title = "Shopify"
                            icon = {SHOPIFY}
                        />
                        <div className="integrationStepsMainDiv">
                            <div className="instructionText">
                                <div>
                                    Click on the Button to Integrate Your Shopify Store.
                                </div>
                                <Button
                                    onClick={onClick}
                                    variant="contained"
                                    className={classes.integrateBtn}
                                >
                                    Click to Integrate
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
}

export default withRouter(withStyles(Styles)(ShopifyIntegration));
