import React from 'react';
import { withRouter } from 'react-router-dom';
import IntegrateStore from '../IntegrateStore';
import Styles from '../../../../assets/css/Styles';
import { withStyles } from '@material-ui/core/styles';
import NinetyNineMinutosInstructions from './99minutosInstructions';
import BreadCrumbsCard from '../../BreadCrumbsCard';
import IntegrationLogo from '../../IntegrationLogo';
import { NINETYNINELOGO } from '../../../../../common/imageList';
import FlashMessage from '../../../../common/FlashMessage';
import { LinearProgressBar } from '../../../../common/materialComponents';

class NinetyNineMinutos extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess = false
        this.state = {
            error: false,
            showSaveLoader: false,
            updateToggleMessage: false,
            success: false,
            successErrMsg: '',
            ninetyNineMinutosStoreActive: false,
            ninetyNineMinutosForm: this.props.match.params.id === undefined ? false : true,
            ninetyNineMinutosStoreLoader: this.props.match.params.id ? true : false
        }
    }

    ninetyNineMinutosStoreLoader = (isLoading) => {
        this.setState({ninetyNineMinutosStoreLoader: isLoading})
    }
    
    toggleFlashMessage = (data) => {
        this.setState({
            error: false,
            success: false,
            successErrMsg: ''
        })
    }

    updateToggleMessage = (data) => {
        this.isSuccess = data.isSuccess 
        this.setState({error: data.error, success: data.success, successErrMsg: data.successErrMsg, showSaveLoader: data.showSaveLoader ? data.showSaveLoader : false})
    }
    
    isNinetyNineMinutosActive = (isActive) => {
        this.setState({ninetyNineMinutosStoreActive: isActive})
    }

    moveToNinetyNineMinutosForm = (data) => {
        this.setState({
            ninetyNineMinutosForm: data
        })
    }

    backToIntegration = () => {
        this.props.history.push('/integrations')
    }

    backToIntegrationList = () => {
        this.props.history.push('/integrated_list')
    }

    render() {
        const { classes } = this.props
        return(
            <React.Fragment>
                <div style = {{display:this.state.ninetyNineMinutosStoreLoader ? 'block' : 'none'}}>
                    <LinearProgressBar/>
                </div>
                <div style = {{display:!this.state.ninetyNineMinutosStoreLoader ? 'block' : 'none'}}>
                <div className="settingsModule">
                         <BreadCrumbsCard classes={classes} storeName="Gw World" 
                                storeId = {this.props.match.params.id} activeStore={this.isNinetyNineMinutosActive} 
                                active={this.state.ninetyNineMinutosStoreActive} updateToggleMessage={this.updateToggleMessage} />
                        <div className="dataContainer">
                            <IntegrationLogo 
                                icon = {NINETYNINELOGO}
                                title = "Gw World"
                                classes={classes}
                            />
                            <div id="settingDataScrollerWrapper">
                                <div className="settingsContainer">
                                    <div className="settingsRightSidePanel">
                                        {
                                            this.state.ninetyNineMinutosForm ?
                                            <IntegrateStore 
                                                isLoading={this.ninetyNineMinutosStoreLoader} updateSuccessErrMsg={this.updateToggleMessage}
                                                activeStore={this.isNinetyNineMinutosActive} classes={classes} type = "99minutos" 
                                                storeId = {this.props.match.params.id} channelId= "10" backToIntegrationList={this.backToIntegrationList}/> 
                                            : <NinetyNineMinutosInstructions classes={classes} moveToNinetyNineMinutosForm={this.moveToNinetyNineMinutosForm} backToIntegration={this.backToIntegration}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                         {(this.state.error || this.state.success) && !this.state.showSaveLoader ?
                            <FlashMessage isSuccess={this.isSuccess} message={this.state.successErrMsg} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                        : ''}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(Styles)(NinetyNineMinutos));
