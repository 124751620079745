import React from 'react'
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import axios from 'axios';
import { userLogin, clearError } from './auth'
import LinkExpired from '../SubUserLinkExpired'
import { hubSpotForm } from '../Setup/actions/actionMethods'
import moment from 'moment'
import { passwordRegex, rootCss, saveBtnCss, btnProgressCss, 
    btnLinkCss, cardContentCss, textFieldCss, textFieldFocusCss} from '../static/constants'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlashMessage from '../../src/TrackingList/common/flashMessage'
import MediaContent from '../common/userMediaCard'

let textFieldCustomCss = {...textFieldCss, marginTop : "0px"}
let rootCustomCss = {...rootCss, paddingBottom : "54px"}
let btnCustomProgressCss = {...btnProgressCss, left: "53px"}
btnCustomProgressCss["float"] = "right"
btnCustomProgressCss["marginTop"] = "5px"
const styles = () => ({
    root: rootCustomCss,
    saveBtn: saveBtnCss,
    buttonProgress: btnCustomProgressCss,
    forgotBtn: btnLinkCss,
    content: cardContentCss,
    nextButtonRoot: {
        padding: "0px 41px 0px 0px",
        float: "right"
    },
    textField: textFieldCustomCss,
    onFocus: textFieldFocusCss
});    

const accept_invite = '/sub_user/acceptinvite'
const maxPwdLength = 6
let passwordErrorMsg = ''
const url = process.env.REACT_APP_API_URL

const mapStateToProps = state => ({
    isLoggedIn: state.authenticate.isLoggedIn,
    errorMessage: state.authenticate.loginError
})

class SubUserLogin extends React.Component {

    state = {
        nameBorder: '',
        passwordBorder: '',
        showLoader: false,
        passwordError: '',
        apiErrorMsg:'',
        flag:false,
        decodedURL:'',
        name:'',
        email:'',
        password: '',
        ispasswordError: false,
        openSnackBar: true
    }

    componentDidMount() {        
        let currentUrl = this.props.location.search
        let encodeData = currentUrl.split('=')[1]
        let base64Decode = atob(encodeData) 
        let decodeUrl = decodeURIComponent(base64Decode)
        decodeUrl='?'+decodeUrl
       
        let name = this.getRedirectURLParameterByName('name',decodeUrl)
        let email = this.getRedirectURLParameterByName('email',decodeUrl)
        this.setState({name:name,email:email})
        
        let decodeDateTime = this.getRedirectURLParameterByName('expiresIn',decodeUrl)
        decodeDateTime = decodeDateTime.split('Z')[0]       
       
        let current_date_time = moment.utc().format()
        current_date_time = current_date_time.split('Z')[0]
      
        let difference = moment(current_date_time).diff(moment(decodeDateTime),'minutes')
       
        if(difference<=2880){
            this.setState({flag:true})
           }
           else{
            this.setState({flag:false})
           }  
      }
      handleSubmit = values => {         
        this.props.clearError()
        let postObject = {    
            password: this.state.password,
            email: this.state.email
        }
        if(this.checkPassword(postObject.password)) {
            this.setState({showLoader: true,apiError:false, openSnackBar: true})
            axios
              .post(url+accept_invite, postObject)
              .then(response => {                   
                this
                  .props
                  .userLogin(postObject.email, postObject.password, status => {                 
                    if (status){
                        this.setState({
                            showLoader: false
                        })
                        hubSpotForm()
                      this.props.history.push('/')
                    }
                    else {
                    this.setState({
                        showLoader: false
                    })
                }  
                  })    
              })
              .catch(error => {            
                this.setState({showLoader: false,apiError:true,apiErrorMsg:error.response.data.meta.message[0]})
              })
        }       
    
      }

      getRedirectURLParameterByName(name, decodedUrl) {     
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),       
          results = regex.exec(decodedUrl);         
        if (!results)
          return null;
        if (!results[2])
          return '';
        return results[2];
      }

    handlePasswordChange = (event) => {
        let val = event.target.value
        this.setState({
            password: val,
        })
        this.checkPassword(val)
    }

    checkPassword = (val) => {
        if (!passwordRegex.test(val)) {
            this.setState({ispasswordError: true})
            passwordErrorMsg = 'Password must contain alphabets and numbers'
            return false
        } else if(val.length < maxPwdLength) {
            this.setState({ispasswordError: true})
            passwordErrorMsg = 'Password must be minimum 6 characters long'
            return false
        } else {
            this.setState({ispasswordError: false})
            passwordErrorMsg = ''
            return true
        }
    }

    closeFlashMessage = () => {
        this.setState({ openSnackBar: false,apiError:false})
        this.props.clearError()
    }

    render() {
        const { classes } = this.props;
        const { from } = this.props.location.state || {
            from: {
                pathname: '/'
            }
        }

        const { onboarding } = this.props.location.state || {
            onboarding: {
                pathname: '/'
            }
        }

        if (this.props.isLoggedIn) {
            var onboarding_val = JSON
                .parse(localStorage.getItem('trackhiveToken'))
                .onboarding
                ? JSON
                    .parse(localStorage.getItem('trackhiveToken'))
                    .onboarding
                    .status
                : 0;
            if (onboarding_val)
                return <Redirect to={onboarding} />
            else
                return <Redirect to={from} />
        }
        return (
            this.state.flag ? 
            <div className="loginAndRegistrationCommonDiv">
                <Card className={classes.root} variant="outlined">
                    <MediaContent content="Login" />
                    <TextField
                        id="name"
                        label="Name"
                        variant="outlined"
                        className={`${classes.textField} ${classes.onFocus}`}
                        value={this.state.name}
                        style={{marginTop: "32px"}}
                        InputProps={{
                            readOnly: true,
                          }}
                    />
                    <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        className={`${classes.textField} ${classes.onFocus}`}
                        value={this.state.email}
                        InputProps={{
                            readOnly: true,
                          }}
                    />
                    <TextField
                        error={this.state.ispasswordError ? true : false}
                        id="password"
                        label="Create Password"
                        variant="outlined"
                        type="password"
                        className={`${classes.textField} ${classes.onFocus}`}
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        helperText={this.state.ispasswordError && this.state.password !== '' ? passwordErrorMsg : ''}
                    />
                    <CardActions className={classes.nextButtonRoot}>
                            <Button
                                variant="contained"
                                className={classes.saveBtn}
                                disabled={this.state.showLoader}
                                onClick={this.handleSubmit}
                            >
                                Login
                            </Button>
                    </CardActions>
                    {this.state.showLoader && <CircularProgress size={22} className={classes.buttonProgress} />}
                </Card>
                {this.props.errorMessage && this.props.errorMessage !== '' ? 
                <FlashMessage open={this.state.openSnackBar} closeFlash={this.closeFlashMessage} classes={classes.addTrackingSnackbar} messageDetails={{ type: 'ERROR', message: this.props.errorMessage }} /> 
                : ''}
                {this.state.apiError && this.state.apiErrorMsg !== '' ? 
                <FlashMessage open={this.state.openSnackBar} closeFlash={this.closeFlashMessage} classes={classes.addTrackingSnackbar} messageDetails={{ type: 'ERROR', message: this.state.apiErrorMsg }} /> 
                : '' }
        </div>
: <LinkExpired/>
                                
        )
    }
}

export default withRouter(connect(mapStateToProps, { userLogin, clearError })(withStyles(styles)(SubUserLogin)))

