export const Channel = [
    {
        channelName : 'Shopify',
        path : 'add_shopify'
    },
    {
        channelName : 'BigCommerce',
        path : 'add_bigcommerce_step1'
    },
    {
        channelName : 'Australia Post',
        path : 'add_auspost'
    },
    {
        channelName : 'Woocommerce',
        path : 'add_wooCommerce'
    },

    {
        channelName : 'Mangento 2.x',
        path : 'add_magento'
    },
    {
        channelName: 'Bluedart',
        path: 'add_bluedart'
    },
    {
        channelName:'CDL Last Mile',
        path:'add_cdl'
    },
    {
        channelName:'Ninja Van',
        path:'add_ninja_van'
    },
    {
        channelName:'Gw World',
        path:'add_gw_world'
    },
    {
        channelName:'99 Minutos',
        path:'add_99minutos'
    }
    
]
