import React from 'react'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars';
import '../static/dashboard.scss'
import { withRouter } from 'react-router-dom'
import ShipmentSummaryColumn from './shipmentSummaryColumn'
import TopCouriers from './topCouriers'
import TopCountries from './topCountries'
import AllTrackingsData from './allTrackingsData'
import AverageDaysToDeliver from './AverageDaysToDeliver'
import TATDaysCourierWise from './TATDaysCourierWise'
import { getCarrierList, setCourierList } from '../../TrackingList/actions/actionMethods'
import Styles from '../static/Styles'
import { withStyles } from '@material-ui/core/styles';

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
    }
    handleAttrClick = (key, dateType, fromDate, toDate, selectedCourier) => {
        if (selectedCourier && selectedCourier.value !== 'all') {
            switch (key) {
                case 'Pending':
                case 'InfoReceived':
                case 'FailedAttempt':
                case 'Exception':
                case 'InTransit':
                case 'OutForDelivery':
                case 'Expired':
                case 'Delivered':
                    this.props.history.push(`/tracking?trackingStatusArr=${key}&date=${dateType}&fromDate=${fromDate}&toDate=${toDate}&courierListArr=${selectedCourier.value}`)
                    break;
                default:
                    break;     
            }
        }
        else {
            switch (key) {
                case 'Pending':
                case 'InfoReceived':
                case 'FailedAttempt':
                case 'Exception':
                case 'InTransit':
                case 'OutForDelivery':
                case 'Expired':
                case 'Delivered':
                    this.props.history.push(`/tracking?trackingStatusArr=${key}&date=${dateType}&fromDate=${fromDate}&toDate=${toDate}`)
                    break;
                default:
                    break;    
            }
        }
    }
    UNSAFE_componentWillMount() {
        getCarrierList().then(response => {
            if (response && response.data) {
                this.props.setCourierList(response.data)
            }
        })
    }
    prepareCourierData = (courierData) => {
        let courierListArr = []
        for (let index = 0; index < courierData.length; index++) {
            const element = courierData[index];

            courierListArr.push({
                label: element.title,
                value: element.slug
            })
        }
        return courierListArr

    }
    render() {
        const { classes } = this.props
        let addedCourierListValue = []
        if (this.props.addedCourierList && this.props.addedCourierList.length > 0) {
            let courierArr = [{ title: 'All', slug: 'all' }].concat(this.props.addedCourierList)
            addedCourierListValue = this.prepareCourierData(courierArr)
        }
        return (
            <div className="dashboardModule" style={{ backgroundColor: '#f2f4f5' }}>
                <div className="clearfix"></div>
                <Scrollbars id="inboxscroll" style={{ height: window.innerHeight, clear: "both" }} autoHide={true} >
                    <div className="dataContainer" style={{ marginBottom: '4rem' }}>
                        <div className="dataContentWrapper">
                            <ShipmentSummaryColumn classes={classes} addedCourierList={addedCourierListValue} />
                        </div>
                        <div className="dataContentWrapper">
                            <AllTrackingsData classes={classes} addedCourierList={addedCourierListValue} onItemClick={(key, dateType, fromDate, toDate, selectedCourier) => { this.handleAttrClick(key, dateType, fromDate, toDate, selectedCourier) }} />
                        </div>
                        <div className="dataContentWrapper">
                            <TATDaysCourierWise classes={classes} addedCourierList={addedCourierListValue} />
                        </div>
                        <div style={{ width: '100%', display: 'inline-flex' }}>
                            <div className="dataContentWrapper" style={{ width: '50%', paddingRight: '1rem' }}>
                                <TopCouriers classes={classes} />
                            </div>
                            <div className="dataContentWrapper" style={{ width: '50%' }}>
                                <AverageDaysToDeliver classes={classes} addedCourierListValue={addedCourierListValue} />
                            </div>
                        </div>
                        <div style={{ width: '100%', display: 'inline-flex' }}>
                            <div className="dataContentWrapper" style={{ width: '50%', paddingRight: '1rem' }}>
                                <TopCountries classes={classes} addedCourierList={addedCourierListValue} />
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            </div >
        )
    }
}
const mapStateToProps = state => ({
    addedCourierList: state.tracking.list.addedTrackingCourierList
})
export default withRouter(connect(mapStateToProps, { getCarrierList, setCourierList })(withStyles(Styles)(Dashboard)))