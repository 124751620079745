import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, withStyles } from '@material-ui/core'
import Styles from '../static/Styles'

class DashboardCourierAutocomplete extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCourier: null,
        }
    }

    handleChange = (event, selectedOption) => {
        this.setState({
            selectedCourier: selectedOption
        }, () => {
            this.props.handleChangeCourier(selectedOption)
        })
    }

    render() {
        const { classes } = this.props
        return (
            <Autocomplete
                className={classes.autocomplete}
                openOnFocus={true}
                placeholder="Select Courier"
                name="topCountries"
                value={this.state.selectedCourier}
                id="TopCountries"
                onChange={this.handleChange}
                options={this.props.addedCourierList}
                getOptionLabel={option => option.label}
                renderInput={params => (<TextField className={classes.text} {...params} variant="outlined" label="Select Courier" />
                )}
            />
        )
    }
}

export default (withStyles(Styles, { withTheme: true })(DashboardCourierAutocomplete))

