export const UNHANDLED_EXCEPTION = 'Something went wrong !! Please contact support@trackhive.co'
export const REQUIRED_ERROR = 'This field is required'
export const CONDITION_TYPE_ERROR = 'Condition already selected'
export const ACTION_TYPE_ERROR = 'Action already selected'
export const ACTION_VALUE_ERROR = 'Action value is required'
export const INVALID_ACTION = 'Action is invalid'
export const INVALID_CONDITION = 'Condition is invalid'
export const INVALID_TRIGGER = 'Trigger is invalid'
export const INVALID_SHIPMENT_ACTION = 'More than one shipment action exists.'
export const AUTOMATION_NAME_IS_REQUIRED = 'Automation name is required'
export const CONDITION_VALUE_REQUIRED = 'Please select condition values'
export const CONDITION_OPERATOR_REQUIRED = 'Please select condition Operator'
export const EMAIL_ID_REQUIRED='Please enter valid email id'