import React from 'react';
import Footer from '../../../../common/footer';

export default class CdlInstructions extends React.Component {
    moveToCdlForm = () => {
        this.props.moveToCdlForm(true)
    }
    backToIntegration = () => {
        this.props.backToIntegration()
    }
    render() {
        return (
            <React.Fragment>
                <div className="integrationStepsMainDiv">
                    <ul className="instructions-list">
                        <li>
                            <span className="list-seq">1</span>
                            <span className="instructions-text">Enter <strong>UserName</strong> and <strong>Password</strong>.</span>

                        </li>
                        <li>
                            <span className="list-seq">2</span>
                            <span className="instructions-text"> Click on the <strong>Done</strong> button to successfully integrate with CDL Last Mile.</span>
                        </li>
                    </ul>
                    <button
                        id="store-next-btn"
                        type="button"
                        style={{
                            float: 'right',
                            display: 'none'
                        }}
                        onClick={this.moveToCdlForm}
                    >
                        Next
                    </button>
                    <button
                        id="store-cancel-btn"
                        type="button"
                        style={{
                            float: 'right',
                            display: 'none'
                        }}
                        onClick={this.backToIntegration}
                    >
                        Cancel
                    </button>
                </div>
                <div className="commonFooter">
                    <Footer storeAction="true" />
                </div>
            </React.Fragment>
        )
    }
}
