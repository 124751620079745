
export function getInstance(method, path, body = '') {

  let localStorageItem = (JSON.parse(localStorage.getItem('trackhiveToken')))

  const configDetails = localStorageItem.token
  
  const config = generateSignature(configDetails, method, path, body)
  return config
}

function generateSignature(configDetails, method, path, payload, contentType = 'application/json') {

  const host = process.env.REACT_APP_API_URL

  if (!configDetails) return null
  const jwtOptions = {
    method,
    url: `${host}${path}`,
    
  }
  
  if (payload || method === 'POST' || method === 'GET' || method === 'DELETE' || method === 'PUT') {
    jwtOptions.data = payload
    jwtOptions.headers = jwtOptions.headers || {}
    jwtOptions.headers['Content-Type'] = contentType
    jwtOptions.headers['Accept'] = 'application/json'
    jwtOptions.headers['X-Access-Token'] = configDetails
  }
  return jwtOptions
}



