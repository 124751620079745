import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from './alert'

export default class FlashMessage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    handleClose = () => {
        this.props.closeFlash()
    }
render() {
    let message = this.props.messageDetails.message
    let type = ''
    if (this.props.messageDetails.type === 'ERROR') {
        type = false
    } else if (this.props.messageDetails.type === 'SUCCESS') {
        type = true
    }
    return (
        <Snackbar className={this.props.classes != '' ?this.props.classes : '' } open={this.props.open} autoHideDuration={3000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity= {type ? "success" : "error"}>
                        {message}
                    </Alert>
                </Snackbar>
    )
}
}