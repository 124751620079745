import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getTATCourierWise } from '../actions/actionMethods'
import { changeDateFunction } from '../common/Functions'
import { tatIcon } from '../../common/imageList'
import AGGridTATDays from './AGGridTATDays'
import { withStyles } from '@material-ui/core'
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Styles from '../static/Styles'
import DashboardPopover from "../common/DashboardPopover";

class TATDaysCourierWise extends React.Component {
	constructor(props) {
		super(props)
		this.selectedCourier = null
		this.state = {
			toDate: '',
			fromDate: '',
			averageDaysToDeliverData: [],
			courierLoadingFlag: true,
			fromUTCDateValue: '',
			toUTCDateValue: ''
		}
	}

	componentWillMount() {

		let dateObj = changeDateFunction('30Days', false)
		this.getAverageDays(dateObj.fromDate, dateObj.toDate)
		this.setState({ fromDate: dateObj.fromDate, toDate: dateObj.toDate })
	}

	prepareDeliveryTimeData = (data) => {

		let arr = Array.from({ length: 11 }, x => 0)
		data.forEach(val => {
			if (val.delivery_time < 10) {
				arr[val.delivery_time] = val.shipment_count
			} else {
				arr[10] += val.shipment_count
			}
		})
		return arr
	}

	prepareData = (TATData) => {
		let arr = []

		for (let index = 0; index < TATData.length; index++) {
			const element = TATData[index];

			let eachObject = {}

			eachObject.slug = element.slug
			eachObject.total_count = element.total_count

			eachObject.data = this.prepareDeliveryTimeData(element.data)

			arr.push(eachObject)

		}

		return arr
	}
	getAverageDays = (fromDate, toDate) => {
		let data = {}
		data.from_date = fromDate
		data.to_date = toDate
		this.setState({ courierLoadingFlag: true, averageDaysToDeliverData: [] })
		if (this.selectedCourier && this.selectedCourier !== '' && this.selectedCourier.value !== 'all') {
			data.courier = this.selectedCourier.value
		}
		getTATCourierWise(data).then(res => {
			if (res.status >= 200 && res.status <= 299) {
				this.setState({
					averageDaysToDeliverData: this.prepareData(res.data.data),
					courierLoadingFlag: false
				})
			} else {
				this.setState({ courierLoadingFlag: false })
			}
		}).catch(error => {
			console.log(error)
			this.setState({ courierLoadingFlag: false })
		})
	}

	setCustomDateRange = (fromUTCDate, toUTCDate) => {
		this.getAverageDays(fromUTCDate, toUTCDate)
		this.setState({
			fromUTCDateValue: fromUTCDate,
			toUTCDateValue: toUTCDate,
			fromDate: fromUTCDate,
			toDate: toUTCDate
		})
	}
	setDateType = (dateType, fromDate, toDate) => {
		this.setState({dateType: dateType, fromDate: fromDate, toDate:toDate, fromUTCDateValue: '', toUTCDateValue: ''})
	}
	handleChangeCourier = (selectedOption) => {
		this.selectedCourier = selectedOption

		let isCustomRangeDate = ((this.state.fromUTCDateValue && this.state.fromUTCDateValue !== '') && (this.state.toUTCDateValue && this.state.toUTCDateValue !== ''))

		if (isCustomRangeDate) {
			this.getAverageDays(this.state.fromUTCDateValue, this.state.toUTCDateValue)
		}
		else {
			this.getAverageDays(this.state.fromDate, this.state.toDate)
		}
	}

	prepareRowData = (data) => {

		let arr = []

		for (let index = 0; index < data.length; index++) {
			const element = data[index];

			let eachObject = {}

			eachObject.courier_name = element.slug
			eachObject.total = element.total_count
			eachObject.zero_day = element.data[0] + " (" + Number.parseFloat((element.data[0] / element.total_count) * 100).toFixed(2) + "%)"
			eachObject.one_day = element.data[1] + " (" + Number.parseFloat((element.data[1] / element.total_count) * 100).toFixed(2) + "%)"
			eachObject.two_days = element.data[2] + " (" + Number.parseFloat((element.data[2] / element.total_count) * 100).toFixed(2) + "%)"
			eachObject.three_days = element.data[3] + " (" + Number.parseFloat((element.data[3] / element.total_count) * 100).toFixed(2) + "%)"
			eachObject.four_days = element.data[4] + " (" + Number.parseFloat((element.data[4] / element.total_count) * 100).toFixed(2) + "%)"
			eachObject.five_days = element.data[5] + " (" + Number.parseFloat((element.data[5] / element.total_count) * 100).toFixed(2) + "%)"
			eachObject.six_days = element.data[6] + " (" + Number.parseFloat((element.data[6] / element.total_count) * 100).toFixed(2) + "%)"
			eachObject.seven_days = element.data[7] + " (" + Number.parseFloat((element.data[7] / element.total_count) * 100).toFixed(2) + "%)"
			eachObject.eight_days = element.data[8] + " (" + Number.parseFloat((element.data[8] / element.total_count) * 100).toFixed(2) + "%)"
			eachObject.nine_days = element.data[9] + " (" + Number.parseFloat((element.data[9] / element.total_count) * 100).toFixed(2) + "%)"
			eachObject.more_than_nine_days = element.data[10] + " (" + Number.parseFloat((element.data[10] / element.total_count) * 100).toFixed(2) + "%)"

			arr.push(eachObject)

		}

		return arr
	}


	render() {

		const { classes } = this.props
		let preparedValue = []

		if (this.state.averageDaysToDeliverData && this.state.averageDaysToDeliverData.length > 0) {
			preparedValue = this.prepareRowData(this.state.averageDaysToDeliverData)
		}
		return (
			<Card className="cardNormal" style={{ cursor: 'default', float: 'none', width: '99.75%', height: "auto" }}>
				<CardHeader id="shipmentSummary" className="shipmentStatus"
					avatar={
						<Avatar className="icon">
							<img alt="" src={tatIcon} />
						</Avatar>
					}
					action={
						<Grid className="MuiCardHeader-action" style={{ display: "inline-flex", float: 'right' }}>
							<DashboardPopover
								type='TATDays'
								getData={this.getAverageDays}
								setCustomDateRange={this.setCustomDateRange}
								setDateType={this.setDateType}
							/>
						</Grid>
					}
					title={<Typography className="headingText">Day  Wise Turn Around Time Of Courier</Typography>}
				/>
				<CardContent className="topCourierListMainDiv" style={{ padding: '0px' }}>
					{this.state.courierLoadingFlag ? <div style={{ width: '400px', margin: '60px auto', opacity: '0.5' }}> <CircularProgress size={42} className={classes.progress} /> </div>
						: this.state.averageDaysToDeliverData.length > 0 ?
							<div>
								<AGGridTATDays rowData={preparedValue} addedCourierList={this.props.addedCourierList} />
							</div>
							: <div className='noData' style={{ lineHeight: "0rem" }}>No Data to Display.</div>}
				</CardContent>
			</Card>
		)
	}
}
const mapStateToProps = state => ({
	addedCourierList: state.tracking.list.addedTrackingCourierList
})
export default withRouter(connect(mapStateToProps, {})(withStyles(Styles, { withTheme: true })(TATDaysCourierWise)))