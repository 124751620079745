import React from 'react'
import {Link} from 'react-router-dom'
import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/styles';
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import Styles from '../../assets/css/Styles'
import { Typography } from '@material-ui/core';

function TrackingButtonCard(props){
const { classes } = props
return(
    <React.Fragment>
    <Link className="cardAction" to = {props.path}>
    <Card className={classes.trackButtoncard}>
        <CardActionArea className={props.title === "Custom Store" ? classes.customStoreActionArea : ""}>
        <CardMedia
          component="img"
          className = {props.title === "Custom Store" ? classes.customStoreMedia : classes.otherStoreMedia}
          alt={props.title+"_IC"}
          height={props.title === "Custom Store" ? "30" : "90.9"}
          image={props.icon}
        />
        <CardContent>
          <Typography className={classes.trackButtonCardTitle}>
            {props.title}
          </Typography>
        </CardContent>
        </CardActionArea>
    </Card>        
    </Link>
  </React.Fragment>
)
}

export default withStyles(Styles)(TrackingButtonCard)