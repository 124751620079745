import React from 'react'
import Highcharts from 'highcharts'
import ReactHighcharts from 'react-highcharts'
require('highcharts/modules/no-data-to-display')(Highcharts);
class AllTrackingsDataChart extends React.Component {    
	render() {
    let options = {
      chart: {
          type: 'pie',
          height: 300,        
      },
      credits: {
         enabled: false
      },
      tooltip: {
          headerFormat: '<span style="font-size:11px">{data.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
      },
      title: {
         verticalAlign: 'middle',
          text: this.props.count,
          floating: true,
          y: 0,
          style:{            
          fontWeight:'bold',
          fontSize:'26px',
          }
        
      },
      subtitle: {
        verticalAlign: 'middle',
        text: 'Shipments',
        floating: true,
        y: 20,
        style:{            
          fontWeight:'bold',
          fontSize:'15px',
          }
    },     
      plotOptions: {
          pie: {
              innerSize: '55%',
              dataLabels: {
                  enabled: false
              },
              allowPointSelect: true,
              cursor: 'pointer',
          }
      },
      series: [{        
          colorByPoint: true,
          data: this.props.data
      }],
      exporting: { enabled: false }
  }    
        return (
            <div className="App" style={{margin:"0.5rem 0"}}>             
              <ReactHighcharts config={options} />         
            </div>
          );
	}
}
export default AllTrackingsDataChart;