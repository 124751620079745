import React from 'react';
import Button from '@material-ui/core/Button';
import '../../../../assets/css/profileStyle.scss'
import { Scrollbars } from 'react-custom-scrollbars';
import CssBaseline from '@material-ui/core/CssBaseline';
import { scrollbar } from '../../../../../static/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledTextField } from '../../../../common/materialComponents';
class CdlDetailsForm extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                <div className="commonSettingsBox profileRightSideMainDiv">
                    <Scrollbars autoHide style={{ scrollbar }}>
                        <div className="customFormContainer" >
                            <div className="input_wrap">
                                <div className="form-attribute">
                                    <StyledTextField
                                        error={this.props.carrierNameError ? true : false}
                                        id="CarrierName"
                                        label="Store Name"
                                        variant="outlined"
                                        name="CarrierName"
                                        value={this.props.carrierName}
                                        onChange={e => this.props.handleCarrierName(e.target.value)}
                                    />
                                </div>
                                <div className="form-attribute">
                                    <StyledTextField
                                        error={this.props.userNameError ? true : false}
                                        id="UserName"
                                        label="User Name"
                                        variant="outlined"
                                        name="UserName"
                                        value={this.props.userName}
                                        onChange={e => this.props.handleUserName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="input_wrap">
                                <div className="form-attribute">
                                    <StyledTextField
                                        error={this.props.passwordError ? true : false}
                                        id="Password"
                                        label="Password"
                                        variant="outlined"
                                        type="password"
                                        name="Password"
                                        value={this.props.password}
                                        onChange={e => this.props.handlePassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className={classes.saveBtnRoot}>
                                <div className={classes.wrapper} >
                                    <Button
                                        variant="contained"
                                        className={classes.saveBtn}
                                        disabled={this.props.showSaveLoader}
                                        onClick={this.props.handleSubmit}
                                    >
                                        SAVE
                                        </Button>
                                    {this.props.showSaveLoader && <CircularProgress size={22} className={classes.buttonProgress} />}
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </React.Fragment>
        )
    }
}
export default CdlDetailsForm;