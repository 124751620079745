import React from "react"
import Autosuggest from "react-autosuggest"
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../../src/static/css/autosuggest.css'
import { Scrollbars } from 'react-custom-scrollbars'
import ColorPicker from "../../common/ColorPicker";
import { searchTags } from '../../Setup/actions/actionMethods'

const mapStateToProps = state => ({
  isLoading: state.tracking.loader.isLoading,
  createShipmentError: state.tracking.list.createShipmentError
})
class Tags extends React.Component {

  loadSuggestions = (value, tags) => {
    if (value.length > 0) {
      var suggestData = tags.filter(tag =>
        tag.tag_name.toLowerCase().slice(0, value.length) === value.trim().toLowerCase()
      );
      suggestData[suggestData.length] = "customTag"
    }
    this.setState({ suggestions: suggestData })
  }
  getSuggestionValue = suggestion => suggestion.tag_name ? suggestion.tag_name : this.state.value;
  renderSuggestion = (suggestion) => (
    suggestion === 'customTag' ? <div onClick={() => this.handleShowPicker()}>Create New Tag</div> :
      <React.Fragment>
        <span className="ColorPoint" style={{ backgroundColor: suggestion.tag_color }} ></span>
        {suggestion.tag_name}
      </React.Fragment>
  );
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      handleShow: 0,
      backgroundColor: ''
    };
    this.theme = {
      container: {
        position: 'relative',
        marginRight: '0.3125rem'
      },
      input: `list_react-autosuggest__input list_react-autosuggest__input_${this.props.name}`,
      inputOpen: `list_react-autosuggest__input--open list_react-autosuggest__input_${this.props.name}`,
      inputFocused: `list_react-autosuggest__input--focused list_react-autosuggest__input_${this.props.name}`,
      suggestionsContainer: `list_react-autosuggest__suggestions-container list_react-autosuggest__input_${this.props.name}`,
      suggestionsContainerOpen: `list_react-autosuggest__suggestions-container--open list_react-autosuggest__input_${this.props.name}`,
      suggestionsList: `list_react-autosuggest__suggestions-list list_react-autosuggest__input_${this.props.name}`,
      suggestion: `list_react-autosuggest__suggestion list_react-autosuggest__input_${this.props.name}`,
      suggestionHighlighted: `list_react-autosuggest__suggestion--highlighted list_react-autosuggest__input_${this.props.name}`,
    }

  }

  componentDidMount() {
    document.getElementsByClassName("list_react-autosuggest__input")[0].focus()
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onKeyPress = (event) => {
    if (event.keyCode === 13) {
      let tag = event.target.value
      let arr = []
      let tags = this.props.tags
      Object.keys(tags).forEach(function (key) {
        arr.push(tags[key].tag_name)
      })
      if(this.state.backgroundColor == '#FFFFFF'){
        this.setState({backgroundColor: ""})
      }
      if (arr.indexOf(tag) === -1 && this.state.backgroundColor !== ('' || '#EBECED')) {
        this.props.addTag(tag, this.state.backgroundColor)
      } else if (arr.indexOf(tag) === -1) {
        this.props.addTag(tag)
      }
       this.setState({value: ''})
    }
  }
  
  onSuggestionsFetchRequested = ({ value }) => {
    let searchQuery = {"query":  value}
    searchTags(searchQuery).then(res => {
      if(res.status === 200) {
        let tags = res.data.data
        this.loadSuggestions(value, tags/* this.props.tags */)
      }
    })
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleSelection = (event, suggestion) => {
    if (suggestion.suggestion.tag_id && this.props.linkTags) {

      let tag_id = suggestion.suggestion.tag_id
      this.props.linkTags(tag_id)
    }
    if (this.props.linkTag) {
      this.props.linkTag(suggestion.suggestion)
      if(event.keyCode !== 13 && suggestion.suggestion !== 'customTag'){
        this.setState({value: ''})
      }
    }
  }

  handleShowPicker = () => {
    if (this.state.handleShow === 0) {
      this.setState({ handleShow: 1 })
    } else {
      this.setState({ handleShow: 0, backgroundColor: '#FFFFFF' })
      document.getElementsByClassName("list_react-autosuggest__input")[0].focus()
    }
  }

  handleBackgroundColor = (color) => {
    this.setState({ backgroundColor: color })
  }

  handleDonePicker = (query) => {
    if (this.state.backgroundColor === '#EBECED') {
      this.setState({ backgroundColor: '' })
    }
    this.props.addTag(query, this.state.backgroundColor)
    this.setState({value: ''})
  }

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    if (this.state.handleShow === 1)
      return <ColorPicker
        showPicker={() => this.handleShowPicker()}
        handleBackgroundColor={(color) => this.handleBackgroundColor(color)}
        query={query}
        handleDonePicker={this.handleDonePicker}
      />
    else {
      return (
        <div   {...containerProps}>
          <Scrollbars
            autoHeight
            autoHeightMin={15}
            style={{ clear: "both" }}
            autoHeightMax={180}
            autoHide={true}
          >
            {children}
          </Scrollbars>
        </div>
      )
    }
  }

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Add Tag',
      value,
      onChange: this.onChange,
      onKeyUp: this.onKeyPress,
      onClick: this.showPicker
    };
    if (this.state.backgroundColor !== '') {
      Array.from(document.getElementsByClassName(`list_react-autosuggest__input_${this.props.name}`)).forEach((e) => e.style.backgroundColor = this.state.backgroundColor)
    }
    
    return (
      <Autosuggest id={this.props.id} theme={this.theme}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        inputProps={inputProps}
        onSuggestionSelected={(event, suggestion, suggestionValue, suggestionIndex, sectionIndex, method) => this.handleSelection(event, suggestion, suggestionValue, suggestionIndex, sectionIndex, method)}
      />
    );
  }
}

export default withRouter(connect(mapStateToProps, {  })(Tags))

