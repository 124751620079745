import React from 'react'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { getLabel } from '../../common/Functions'
import { ACTION } from '../../constants/defaultValues'
import IconButton from '@material-ui/core/IconButton'
import {RemoveIcon, EditCustom} from  '../../../common/imageList'

const styles = theme => ({
    card: {
        width: 312,
        "&:hover": {
            background: "#e3eefc",
            // display : 'block'
        },
        cursor: "pointer",
        marginBottom: "10px"
    },
    draweCancelButton: {
        float: 'right',
        display: 'none',
        padding: '5px',
        marginTop : '12px'
    },
    draweCancelButtonHover: {
        float: 'right',
        padding: '5px',
        marginTop : '12px'
    },
    draweEditButton: {
        float: 'right',
        display: 'none',
        padding: '1px',
        marginRight : '16px',
        marginTop : '12px'
    },
    draweEditButtonHover: {
        float: 'right',
        padding: '1px',
        marginRight : '16px',
        marginTop : '12px'
    },
    headerRoot: {
        display: 'inline-block'
    },
    iconColor: {
        "& .MuiSvgIcon-root": {
            color: '#1a73e8'
        }
    },
    title: {
        fontWeight: "normal",
        color: "#666666",
        fontSize: "16px",
        lineHeight: "normal",
        letterSpacing: "0.16px",
    },
    imageEdit :{
        height : '28px',
        width : '28px'
    }
});

class AddedActionCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            onHover: false
        }
    }

    addedActionCardClick = () => {
        this.props.addedActionCardClick(parseInt(this.props.index))
    }
    removeAction = () => {
        this.props.removeAction(parseInt(this.props.index))
    }
    onMouseOver = () => {
        this.setState({
            onHover: true
        })
    }

    onMouseOut = () => {
        this.setState({
            onHover: false
        })
    }
    render() {
        const { classes } = this.props
        // TODO Change the trigger type and trigger which is selected by user
        let actionList = ACTION[this.props.trigger].filter(action => {
            return action.trigger.includes(this.props.triggerType)
        })
        let actionCollection = getLabel(actionList, this.props.actionType[this.props.index], true)
        // let requestParams = this.props.actionDetail[this.props.index]

        return (
            <Card className={classes.card} variant="outlined" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
                <CardHeader
                    classes={{
                        title: classes.title,
                        avatar: classes.headerAvatar,
                        root: classes.headerRoot
                    }}
                    title={actionCollection.label}
                // onClick={this.addedActionCardClick}
                // subheader="Action Mode Purpose"
                />
                <IconButton
                    color="inherit"
                    onClick={this.removeAction}
                    className={this.state.onHover ? classes.draweCancelButtonHover : classes.draweCancelButton}
                    edge="start"
                >
                    <RemoveIcon/>
                </IconButton>
                <IconButton
                    color="inherit"
                    onClick={this.addedActionCardClick}
                    className={this.state.onHover ? classes.draweEditButtonHover : classes.draweEditButton}
                    edge="start"
                >
                    <img alt="" src={EditCustom} className={classes.imageEdit}/>
                    {/* // <EditOutlinedIcon className={classes.iconColor} /> */}
                </IconButton>
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    // actionDetail: state.automations.list.actionRequestParams,
    actionType: state.automations.list.actionType
})

export default (connect(mapStateToProps, {})(withStyles(styles)(AddedActionCard)))