import React from 'react'
import Avatar from '@material-ui/core/Avatar';

export default function IntegrationLogo(props){
    const {classes} =  props
    return(
        <React.Fragment>
            <div className="containerHeader">
                  <Avatar alt="organization_logo" className={classes.avatar} src={props.icon} />
                 <div className="containerTitle">{props.title}</div>
            </div>
        </React.Fragment>
    )
}