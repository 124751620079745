import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import {StyledTextField, StyledTypography} from '../common/materialComponents'
import {RemoveIcon, SaveIcon, CloseIcon, cancelIconSettings, ApiCopyIcon, RefreshIcon} from '../../common/imageList'
class DeveloperAppsDrawer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            openDialog: false
        }
    }

    toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.props.closeDrawer()
    };

    hanldeDialog = () => {
        this.setState({openDialog: true})
    }

    handleCloseDialog = () => {
        this.setState({openDialog: false})
    }

    render(){
        const {classes} = this.props
        let indexVal;
        if(this.props.currentIndex){
            indexVal = this.props.tokenData.currentIndex
        }
        return(
               <React.Fragment>
                 <Drawer
                    variant = "persistent"
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    anchor="right"
                    open={this.props.openDrawer}
                    onClose={this.toggleDrawer(false)}
                    BackdropProps={{ invisible: true }}
                    transitionDuration={500}
                 >
                    <Grid container className={classes.drawerHeader}>
                        <StyledTypography className={classes.drawerTitle}>API Keys</StyledTypography>
                        <IconButton
                            color="inherit"
                            onClick={this.toggleDrawer(false)}
                            className={classes.draweCancelButton}
                            edge="start"
                        >
                            <img alt="" src={cancelIconSettings} />
                        </IconButton>
                        {this.props.onChange ? <CircularProgress size={42} className={classes.drawerProgress} /> : ""}
                    </Grid>

                    
                    <Grid container className={classes.drawerContent}>
                           <StyledTypography className={this.props.showViewTokenModal ? classes.drawerContentTextEdit :classes.drawerContentText}> {this.props.showViewTokenModal ? "API Key" : "Create API keys to connect your system with Trackhive."}</StyledTypography>
                    </Grid>  
                    

                    <Grid container  className={this.props.showViewTokenModal ? classes.drawerTextAreaContainer : classes.drawerTextFieldContainer}>
                        {
                            !this.props.showViewTokenModal ?
                            <StyledTextField
                                error={this.props.addTokenInputError ? true : false}
                                id="Add_Token"
                                label="Enter Token Name"
                                variant="outlined"
                                className={classes.drawerFields}
                                value={this.props.tokenName}
                                onChange={(e) => this.props.handleChangeTokenName(e.target.value)}>
                            </StyledTextField> :
                            <div style={{border: '1px solid #d3d3d3', padding: '10px', width: '558px', resize: 'none', overflow: 'hidden', wordBreak: 'break-all' }}>
                                {this.props.viewDeveloperTokenValue}
                            </div>
                        }
                    </Grid> 
                    {
                        this.props.showViewTokenModal ?
                        <Grid container className={classes.drawerTextAreaContainer}>
                            <CopyToClipboard text={this.props.viewDeveloperTokenValue}>
                                <Button startIcon={<ApiCopyIcon/>} onClick={this.props.copyToken} className={classes.drawerCopyButton}>
                                    COPY
                                </Button>
                            </CopyToClipboard>
                        </Grid> : ""
                    }

                    <footer justify="left" style={{position: 'fixed', bottom: '0'}}>
                                <Button
                                    onClick={this.props.showAddTokenModal ? this.props.addDeveloperToken : (developerToken, tokenName , index, action) => this.props.performActions(this.props.tokenData.developerToken,this.props.tokenData.tokenName, indexVal, "refresh")}  
                                    startIcon={this.props.showAddTokenModal ? <SaveIcon/> : <RefreshIcon/>} 
                                    disabled={this.props.loading}
                                    className={this.props.showViewTokenModal  ? this.props.refreshTokenLoader ? classes.drawerRefreshBtnDisable : classes.drawerRefreshBtn : this.props.showAddTokenLoader ? classes.drawerSaveBtnDisable : classes.drawerSaveBtn}>
                                       {this.props.showViewTokenModal ? 'REFRESH' : 'SAVE'}
                                </Button>
                                {(this.props.showAddTokenLoader || this.props.refreshTokenLoader) && <CircularProgress size={22} className={classes.drawerbuttonProgress} />}
                                {
                                    this.props.showViewTokenModal ? 
                                    <Button startIcon={<RemoveIcon/>} style={{marginLeft: '24rem'}} className={classes.drawerDeleteButton} onClick={(developerToken, tokenName , index, action) => this.props.performActions(this.props.tokenData.developerToken,this.props.tokenData.tokenName, indexVal, "delete")}>
                                        DELETE
                                    </Button>: 
                                       <Button startIcon={<CloseIcon/>} onClick={this.toggleDrawer(false)} className={classes.drawerDeleteButton}>
                                            CANCEL
                                       </Button>
                                }
                    </footer>
                </Drawer>

                <Dialog
                    open={this.props.showDeleteTokenModal}
                    onClose={this.props.closeDrawer}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                   <DialogTitle id="alert-dialog-title">{"Delete API Key"}</DialogTitle>
                     <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure that you want to delete this API Key?
                            {this.props.deleteErrorMessage ? this.props.deleteErrorMessage : ""} 
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.dialogCancelBtn}  onClick={this.props.closeDrawer}>
                            Cancel
                        </Button>
                        <Button  className={classes.dialogActionBtn} onClick={this.props.deleteDeveloperToken}  autoFocus>
                            Yes, Delete
                        </Button>
                        {(this.props.showDeleteLoader) && <CircularProgress size={22} className={classes.deleteDialogProgress} />}
                   </DialogActions>
                </Dialog>
                </React.Fragment>
        )
    }   
}
export default (DeveloperAppsDrawer)