
import React from 'react'
import {breadCrumbIcon} from '../../common/imageList'

 const commonRightArrowComponent = () => {
    return (
        <div className="commonBreadcrumbIconStyle">
            <img src={breadCrumbIcon} alt="breadcrumb_IC"/>
        </div>
    )
}
export default commonRightArrowComponent