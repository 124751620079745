import React from 'react';
import { connect } from 'react-redux';
import '../../assets/css/subuser.scss';
import Drawer from './UserAndRoleDrawer'
import Table from '@material-ui/core/Table'
import { withRouter } from 'react-router-dom';
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import FlashMessage from '../../common/FlashMessage';
import TableContainer from "@material-ui/core/TableContainer";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getRolesList, createRole, updateRole, setSettingsFlashMessage, toggleSettingsFlashMessage } from '../../actions/actionMethods';
import { ROLE_ACCESS, WARNING_MESSAGE, ROLE_ADDED_SUCCESSFULLY_MESSAGE, ROLE_UPDATED_SUCCESSFULLY_MESSAGE } from '../../common/constants';

class Roles extends React.Component {
    constructor(props) {
        super(props)
        this.roles = []
        this.mapRoleToSwitch = new Map();
        this.mapRoleToLabel = new Map();
        this.mapRoleToSubModule = new Map();
        this.moduleNames = []
        this.allRoles = []
        this.isSuccess = true
        this.state = {
            roleList: [],
            switchStateArray: [],
            roleName: "",
            department: "",
            roleNameError: false,
            isEdit: false,
            editRoleId: '',
            editRolesArr: [],
            showDrawerLoader: false,
            onChange: false,
            loader: true
        }
    }

    componentDidMount() {
        this.setDefaultSwitchValue()
        this.getRolesList()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.editRolesArr !== prevState.editRolesArr) {
            this.setEditSwitchValue()
        }
    }

    componentWillReceiveProps(prevProps, nextProps) {
        if (prevProps.isEditRole) {
            this.setEditSwitchValue()
        } else {
            this.setDefaultSwitchValue()
        }
    }

    getRolesList = () => {
        getRolesList().then(res => {
            this.setState({
                roleList: res.data.data,
                loader: false
            })
        })
    }

    clearFieldsValue = () => {
        this.setState({
            roleName: '',
            department: '',
            roleNameError: false,
        })
    }

    setDefaultSwitchValue = () => {
        if (this.props.type === "role") {
            var switchStateArray = []
            this.moduleNames = []
            this.roles = []
            Object.keys(ROLE_ACCESS).map(module => (
                ROLE_ACCESS[module].map(data => {
                    if (!switchStateArray.hasOwnProperty(data.switchName)) {
                        switchStateArray[data.switchName] = true
                        this.roles.push(data.roleValue)
                        this.moduleNames.push(data.label)
                        if (data.submodules && data.submodules != null) {
                            let submodules = data.submodules
                            submodules.forEach(element => {
                                switchStateArray[element.switchName] = false
                            });
                        }
                    }
                })
            ))
            this.setState({
                switchStateArray
            })
        }
    }

    setEditSwitchValue = () => {
        var switchStateArray = []
        Object.keys(ROLE_ACCESS).map(module => (
            ROLE_ACCESS[module].map(data => {
                switchStateArray[data.switchName] = false
                this.mapRoleToSwitch.set(data.roleValue, data.switchName)
                this.mapRoleToLabel.set(data.roleValue, data.label)
                this.allRoles.push(data.roleValue)
                if (data.submodules) {
                    this.mapRoleToSubModule.set(data.roleValue, data.submodules)
                }
            })
        ))
        this.setState({
            switchStateArray
        }, () => this.setRoles(this.state.editRolesArr))
    }

    handleChangeRoles = (event, switchName, roleValue, moduleName = null, submodules = null) => {
        const switchStateArray = this.state.switchStateArray
        if (switchStateArray[switchName]) {
            if (this.roles.includes(roleValue)) {
                let index = this.roles.indexOf(roleValue)
                this.roles.splice(index, 1);
                this.moduleNames.splice(index, 1)
                if (submodules !== null) {
                    submodules.forEach(submodule => {
                        if (this.roles.includes(submodule.roleValue)) {
                            this.handleChangeRoles(submodule.switchName, submodule.roleValue)
                        }
                        document.getElementById(submodule.switchName).style.display = "none"
                    })
                }
            }
        } else {
            this.roles.push(roleValue)
            this.moduleNames.push(moduleName)
            if (submodules !== null) {
                submodules.forEach(submodule => {
                    document.getElementById(submodule.switchName).style.display = "block"
                })
            }
        }
        switchStateArray[switchName] = !switchStateArray[switchName]
        this.setState({
            switchStateArray
        })
    }

    handleName = (value) => {
        this.setState({
            roleName: value,
            roleNameError: false
        })
    }

    handleDepartment = (value) => {
        this.setState({
            department: value,
        })
    }

    checkValidation = () => {
        let errorCount = 0
        if (this.state.roleName === '' || this.state.roleName.trim().length === 0) {
            this.setState({
                roleNameError: true,
                showDrawerLoader: false
            })
            errorCount++
        } else {
            this.setState({
                roleNameError: false
            })
        }
        if (errorCount > 0) {
            return false
        }
        return true
    }

    setRoles = rolesArr => {
        let roles = rolesArr
        this.moduleNames = []
        const switchStateArray = this.state.switchStateArray
        this.roles = []
        roles.map(role => {
            this.roles.push(role)
            this.moduleNames.push(this.mapRoleToLabel.get(role))
            let switchName = this.mapRoleToSwitch.get(role)
            switchStateArray[switchName] = true
        })
        this.setState({
            switchStateArray
        })
    }

    checkSubSwitch = () => {
        this.allRoles.forEach(eachRole => {
            if (!this.roles.includes(eachRole) && this.mapRoleToSubModule.get(eachRole) !== undefined) {
                let submodules = this.mapRoleToSubModule.get(eachRole)
                submodules.forEach(submodule => {
                    document.getElementById(submodule.switchName).style.display = "none"
                })
            }
        })
    }


    editDrawer = () => {
        this.props.editDrawer()
    }

    handleEditRole = (roleData) => {
        this.setState({
            onChange: true,
            editRoleId: roleData._id,
            roleName: roleData.name,
            department: roleData.department,
            editRolesArr: roleData.role,
            roleNameError: false
        }, () => {
            setTimeout(() => {
                this.setState({
                    onChange: false
                })
            }, 800)
        })
        this.editDrawer()
    }

    updateRole = () => {
        let rolesData = {}
        let role = []
        if (this.roles.length > 0) {
            role = this.roles
        }
        this.setState({
            showDrawerLoader: true
        })
        let roleId = this.state.editRoleId
        let validation = this.checkValidation()
        if (validation) {
            rolesData.name = this.state.roleName
            rolesData.department = this.state.department
            rolesData.role = role
            updateRole(roleId, rolesData).then(res => {
                if (res && res.data && res.data.meta && res.data.meta === 200) {
                    this.isSuccess = true
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(ROLE_UPDATED_SUCCESSFULLY_MESSAGE)
                    this.setState({
                        showDrawerLoader: false,
                        editRoleId: ''
                    })
                    this.getRolesList()
                    this.closeDrawer()
                }
            }).catch(err => {
                this.isSuccess = false
                this.props.toggleSettingsFlashMessage(1)
                this.props.setSettingsFlashMessage(WARNING_MESSAGE)
            })
        }
    }

    saveRole = () => {
        let rolesData = {}
        let role = []
        if (this.roles.length > 0) {
            role = this.roles
        }
        this.setState({
            showDrawerLoader: true
        })
        let validation = this.checkValidation()
        if (validation) {
            rolesData.name = this.state.roleName
            rolesData.department = this.state.department
            rolesData.role = role
            createRole(rolesData).then(res => {
                if (res && res.data && res.data.meta && res.data.meta.code === 200) {
                    this.isSuccess = true
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(ROLE_ADDED_SUCCESSFULLY_MESSAGE)
                    this.setState({
                        showDrawerLoader: false
                    })
                    this.getRolesList()
                    this.closeDrawer()
                }
                if (res && res.data && res.data.meta && res.data.meta.code === 400) {
                    this.isSuccess = false
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(res.data.meta.message[0])
                    this.setState({
                        showDrawerLoader: false
                    })
                }
            }).catch(err => {
                this.isSuccess = false
                this.props.toggleSettingsFlashMessage(1)
                this.props.setSettingsFlashMessage(WARNING_MESSAGE)
            })
        }
    }

    showRolesData = (rolesList) => {
        const { classes } = this.props
            return (
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={`${classes.headCell} ${classes.firstHeadAndBodyCell}`}>Role Name</TableCell>
                                <TableCell align="left" className={`${classes.headCell} ${classes.lastHeadAndBodyCell}`}>Department</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rolesList.length > 0 ? 
                                rolesList.map((role, index) => {
                                    return (
                                            <TableRow key={index}>
                                                <TableCell align="left" className={`${classes.bodyCell} ${classes.firstHeadAndBodyCell}`}>
                                                    <div style={{ color: "#1a73e8", fontSize: "14px", textDecoration: 'none', cursor: 'pointer' }} onClick={(roleData) => this.handleEditRole(role)} >
                                                        {role.name}
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left" className={`${classes.bodyCell} ${classes.lastHeadAndBodyCell}`}>
                                                        {role.department}
                                                </TableCell>
                                            </TableRow>
                                    )
                                })
                                : <div className="noData" style={{ position: "absolute", padding: "2rem", left: '45%' }}>
                                    No Roles Added.
                                  </div>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            )
    }


    closeDrawer = () => {
        this.props.closeDrawer()
        this.clearFieldsValue()
    }

    toggleFlashMessage = (data) => {
        this.props.toggleSettingsFlashMessage(data)
        this.props.setSettingsFlashMessage('')
    }

    render() {
        const {classes} = this.props
        return (
            <div id="settingDataScrollerWrapper">
                <div className="settingsContainer">
                    <div className="settingsRightSidePanel">
                        <div className="commonSettingsBox subUserStyleMainDiv">
                                <div className="setting-box-content-con">
                                {this.state.loader ? 
                                    <CircularProgress size={62} className={classes.tabProgress} />
                                    : this.showRolesData(this.state.roleList) }  
                                </div>
                        </div>
                        <Drawer 
                            openDrawer={this.props.open} 
                            closeDrawer={this.closeDrawer}
                            classes={classes}
                            type={this.props.type}
                            switchStateArray = {this.state.switchStateArray}
                            handleChangeRoles = {this.handleChangeRoles}
                            saveRole = {this.saveRole}
                            roleName = {this.state.roleName}
                            roleNameError = {this.state.roleNameError}
                            department = {this.state.department}
                            handleName = {this.handleName}
                            handleDepartment = {this.handleDepartment}
                            updateRole = {this.updateRole}
                            isEditRole = {this.props.isEditRole}    
                            onChange = {this.state.onChange}
                            isLoading = {this.state.showDrawerLoader}
                        />
                            {this.props.showSettingFlashMessageFlag === 1 ?
                                <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                        : ''}
                    </div>
                </div>
            </div>
        )   
    }
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, {setSettingsFlashMessage,toggleSettingsFlashMessage })(Roles));
