export const GET_TRACKING_LIST = 'GET_TRACKING_LIST'
export const CREATE_SHIPMENT = 'CREATE_SHIPMENT'
export const CREATE_SHIPMENT_ERROR = 'CREATE_SHIPMENT_ERROR'
export const START_LOADING='START_LOADING'
export const END_LOADING='END_LOADING'
export const CLEAR_SHIPMENT_ERROR='CLEAR_SHIPMENT_ERROR'
export const CLEAR_TRACKING_LIST='CLEAR_TRACKING_LIST'
export const TRACKING_LIST_GLOBAL_FILTERS='TRACKING_LIST_GLOBAL_FILTERS'
export const TRACKING_DETAILS = 'TRACKING_DETAILS'
export const CLEAR_TRACKING_DETAILS = 'CLEAR_TRACKING_DETAILS'
export const SET_TRACKING_DETAIL = 'SET_TRACKING_DETAIL'
export const SET_SEARCH_TRACKING_DETAIL = 'SET_SEARCH_TRACKING_DETAIL'
export const DELETE_TRACKING = 'DELETE_TRACKING'
export const TRACKING_SHOW_FLASH_MESSAGE = 'TRACKING_SHOW_FLASH_MESSAGE'
export const TRACKING_FLASH_MESSAGE = 'TRACKING_FLASH_MESSAGE'
export const SET_TRACKING_FLAG = 'SET_TRACKING_FLAG'
export const START_TRAKING_LIST_LOADER = 'START_TRAKING_LIST_LOADER'
export const END_TRAKING_LIST_LOADER = 'END_TRAKING_LIST_LOADER'
export const SET_TRACKING_STATUS_ARRAY_VALUE_LIST ='SET_TRACKING_STATUS_ARRAY_VALUE_LIST'
export const SET_COURIER_LIST_ARRAY_VALUE_LIST = 'SET_COURIER_LIST_ARRAY_VALUE_LIST'
export const SET_ORIGIN_COUNTRY_ARRAY_VALUE_LIST='SET_ORIGIN_COUNTRY_ARRAY_VALUE_LIST'
export const SET_DESTINATION_COUNTRY_ARRAY_VALUE_LIST='SET_DESTINATION_COUNTRY_ARRAY_VALUE_LIST'
export const SET_TRANSITION_DAYS = 'SET_TRANSITION_DAYS'
export const EDIT_TRACKING_ERROR='EDIT_TRACKING_ERROR'
export const IS_SHIPMENT_ADD='IS_SHIPMENT_ADD'
export const SET_ADDED_COURIER_LIST='SET_ADDED_COURIER_LIST'
export const SET_ADDED_SUBUSER_LIST_FILTER = 'SET_ADDED_SUBUSER_LIST_FILTER'
export const SET_ADDED_TAGS = "SET_ADDED_TAGS"
export const CLEAR_TAG_LIST="CLEAR_TAG_LIST"
export const FILTER_TAG_LIST = "FILTER_TAG_LIST" 

export const CLEAR_SHIPMENT_STATUS_FILTERS = 'CLEAR_SHIPMENT_STATUS_FILTERS'
export const CLEAR_ALL_SHIPMENT_STATUS_FILTERS='CLEAR_ALL_SHIPMENT_STATUS_FILTERS'
export const GET_SELECTED_SHIPMENT_TAB='GET_SELECTED_SHIPMENT_TAB'
export const STATUS_ARRAY = [
    {value:'OutForDelivery', label:'Out for Delivery'},
    {value:'InTransit', label:'In Transit'},
    {value:'Delivered', label:'Delivered'},
    {value:'Exception', label:'Exception'},
    {value:'InfoReceived', label:'Info Received'},
    {value:'FailedAttempt', label:'Failed Attempt'},
    {value:'Pending', label:'Pending'},
    {value:'Expired', label:'Expired'},
]
export const COLUMN_ARRAY = [
    {"label" : 'Courier', "key" : "courier"},
    {"label" : 'Service', "key" : "service"},
    {"label" : 'Tracking No', "key" : "tracking_number"},
    {"label" : 'Origin', "key" : "origin"},
    {"label" : 'Destination', "key" : "destination"},
    {"label" : 'Last Check Point', "key" : "last_status"},
    {"label" : 'Last Check Time', "key" : "time"},
    {"label" : 'Assign To', "key" : "assigned_to"},
    {"label" : 'Tags', "key" : "tag"},
    {"label" : 'Expectad Date', "key" : "date"},
    {"label" : 'Days', "key" : "transit_day"},
    {"label" : 'Status', "key" : "status"},
]
export const DEFAULT_TRACKING_LIMIT = 20