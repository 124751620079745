import * as constants from '../common/constants'
const initialState = {
    trackingList :[],
    totalTrackingRecords:0,
    createShipmentError:'',
    setTrackingListFlag : 0,
    setTrackingListPaginationFlag : 0,
    trackingDetails:null,
    deletTracking:'',
    showTrackingFlashMessage : '',
    trackingFlashMessage:'',
    trackingFilters:{},
    showEditTrackingErrorMessage:'',
    isShipmentAdded:false,
    addedTrackingCourierList:[],
    addedSubUserList: [],
    tags: [],
    filterTags: [],
    selectedShipmentTab:'',
    shipmentTrackingDetail : '',
    detailDrawer : false
    
}
export default (state = initialState , action) => {
    
    switch(action.type) {
        case constants.GET_TRACKING_LIST:
        
        return {
            ...state,
            trackingList:action.data.data,
            totalTrackingRecords:action.data.meta.total_records,
            setTrackingListFlag:1,
            setTrackingListPaginationFlag:1
        }
        
        case constants.TRACKING_DETAILS:
        
        return {
            ...state,
            trackingDetails:action.data,
            shipmentTrackingDetail : action.data,
        }

        case constants.CLEAR_TRACKING_DETAILS:
        
        return {
            ...state,
            trackingDetails:null
        }

        case constants.SET_SEARCH_TRACKING_DETAIL:

         return {
            ...state,
            shipmentTrackingDetail:action.data,
            detailDrawer : true
        }

        case constants.SET_TRACKING_DETAIL:

        return {
            ...state,
            shipmentTrackingDetail:action.data,
            detailDrawer : false
        }

        case constants.DELETE_TRACKING:
        return {
            ...state,
            deletTracking:action.data
        }
        case constants.IS_SHIPMENT_ADD:
            return{
                ...state,
                isShipmentAdded:action.data
            }
        
        
        case constants.CREATE_SHIPMENT_ERROR:
        return{
            ...state,
            createShipmentError:action.data
        }
    case constants.CLEAR_SHIPMENT_ERROR:
        return {
            ...state,
            createShipmentError:''
        }
    case constants.START_TRAKING_LIST_LOADER:
        return{
            ...state,
            setTrackingListPaginationFlag:action.data
        }
    case constants.END_TRAKING_LIST_LOADER:
        return{
            ...state,
            setTrackingListFlag:1
        }
    case constants.CLEAR_TRACKING_LIST:
        
        return {
            ...state,
            trackingList :[],
            totalTrackingRecords:0,
            createShipmentError:'',
            // setTrackingListFlag : 0,
            trackingDetails:null,
            deletTracking:'',
            showTrackingFlashMessage : '',
            trackingFlashMessage:'',
            showEditTrackingErrorMessage:'',
            isShipmentAdded:false,
        }
        
    case constants.TRACKING_SHOW_FLASH_MESSAGE:
        return{
            ...state,
            showTrackingFlashMessage:action.data
        }

        case constants.TRACKING_FLASH_MESSAGE:
        return{
            ...state,
            trackingFlashMessage:action.data
        }
    case constants.TRACKING_LIST_GLOBAL_FILTERS:
    
        return{
            ...state,
            trackingFilters:action.data
        }
    case constants.CLEAR_SHIPMENT_STATUS_FILTERS:
        return{
            ...state,
            trackingFilters:{"trackingStatusArr" : action.data}
        }

    case constants.CLEAR_ALL_SHIPMENT_STATUS_FILTERS:
    return{
        ...state,
        trackingFilters:{"allTrackingStatusArr" : action.data}
    }

    case constants.SET_TRACKING_STATUS_ARRAY_VALUE_LIST :
        return {
            ...state,
            trackingStatusArrayList:action.data
        }
    case constants.SET_COURIER_LIST_ARRAY_VALUE_LIST:
    return{
        ...state,
        trackingCourierArrayList:action.data
    }
    
    case constants.SET_ORIGIN_COUNTRY_ARRAY_VALUE_LIST:
    return{
        ...state,
        originCountryArrayList:action.data
    }

    case constants.SET_DESTINATION_COUNTRY_ARRAY_VALUE_LIST:
    
    return{
        ...state,
        destinationCountryArrayList:action.data
    }

    case constants.SET_TRANSITION_DAYS:
    return{
        ...state,
        transitionDays:action.data
    }
    case constants.EDIT_TRACKING_ERROR:
    return{
        ...state,
        showEditTrackingErrorMessage:action.data
    }

    case constants.SET_ADDED_COURIER_LIST:
        return {
            ...state,
            addedTrackingCourierList:action.data
        }
    
        case constants.SET_ADDED_SUBUSER_LIST_FILTER:
            return {
                ...state,
                addedSubUserList: action.data
            }

        case constants.SET_ADDED_TAGS:
            return {
                ...state,
                tags: [...state.tags].slice().concat(action.data)
            }

        case constants.CLEAR_TAG_LIST:
            return {
                ...state,
                tags: action.data
            }

        case constants.FILTER_TAG_LIST:
        return {
            ...state,
            filterTags: action.data
        }
    
        case constants.GET_SELECTED_SHIPMENT_TAB:
            return{
                ...state,
                selectedShipmentTab:action.data
            }
        
        default:
            return state;
    }
}


function removeDuplicateRecords(myArr) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj['_id']).indexOf(obj['_id']) === pos;
    });
}