import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { TRIGGER_CUSTOM, TRIGGER_CONDITION_SHIPMENT, TRIGGER, TRIGGER_CONDITION, CONTROLLER_CONDITION, CONDITION_OPERATOR } from '../../constants/defaultValues'
import { AUTOMATION_NAME_IS_REQUIRED, INVALID_ACTION, INVALID_CONDITION, INVALID_TRIGGER, INVALID_SHIPMENT_ACTION } from '../../constants/errorMessages'
import { setDeliveryTimeList, setStatusList, setCourierList, removeAutomationConditionByKey, removeAutomationActionByKey, clearAllAction, clearAllCondition, addAutomationRule, updateAutomationRuleListState, getAutomationDetail, updateAutomationRule, getCourierList, setLogicalOperator } from '../../actions/actionMethods'
import { getLabel } from '../../common/Functions'
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Radio from '@material-ui/core/Radio';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { backArrow, updateIcon, shipmentIcon, automationPreview } from '../../../common/imageList'
import InputAdornment from '@material-ui/core/InputAdornment';
import { Card, CardHeader, Avatar } from '@material-ui/core';
import AutomationWorkflow from './AutomationWorkflow'
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import FlashMessage from '../../../common/alertSnackBar'
import LinearProgress from '@material-ui/core/LinearProgress';
const initValues = {
    conditions: { 1: 1 },
    actions: { 1: 1 },
    triggerCondition: { label: 'Select Value', value: '' },
    footerMessage: { type: 'NOTICE', message: 'Add automation rule.' },
    editFooterMessage: { type: 'NOTICE', message: 'Edit automation rule.' },
    controllerCondition: CONTROLLER_CONDITION[0]
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide
        direction="left"
        ref={ref} {...props} />;
});
class AddNewAutomation extends React.Component {
    constructor(props) {
        super(props)
        this.conditionAvailableList = {}
        this.state = {
            isAutomationNameEmpty: false,
            automationName: '',
            selectedOptionTrigger: TRIGGER[0],
            selectedCustomTrigger: TRIGGER_CUSTOM[0],
            selectedControllerOption: initValues.controllerCondition,
            selectedOptionTriggerCondition: initValues.triggerCondition,
            selectedOptionConditionOperator: this.props.parameterId && this.props.automationDetail.logical_operator === 'OR' ? CONDITION_OPERATOR[1] :CONDITION_OPERATOR[0],
            optionTriggerCondition: null,
            conditions: initValues.conditions,
            actions: initValues.actions,
            footerMessage: initValues.footerMessage,
            selectTriggerPage: false,
            triggerOption: TRIGGER_CONDITION_SHIPMENT,
            showMessage : false,
            allowToChangeTrigger : true
        }
    }
    checkAutomationName = () => {
        if (this.state.automationName === '') {
            this.setState({
                isAutomationNameEmpty: true
            })
        }
    }
    handleAutomationName = (event) => {
        this.setState({
            automationName: event.target.value,
            isAutomationNameEmpty: false
        })
    }
    handleChangeController = (selectedControllerOption) => {
        if (selectedControllerOption.value && selectedControllerOption.value == 'TRIGGER_CUSTOM') {
            let customTriggerList = []
            for (let index = 0; index < this.props.customTriggerList.length; index++) {
                const element = this.props.customTriggerList[index];
                customTriggerList.push({
                    label: element.name,
                    value: element.id
                })
            }
            this.setState({
                selectedControllerOption: selectedControllerOption,
                selectedOptionTriggerCondition: initValues.triggerCondition,
                selectedOptionTrigger: TRIGGER_CUSTOM[0],
                triggerOption: customTriggerList
            })
        } else {
            if (selectedControllerOption.value) {
                this.setState({
                    selectedControllerOption: selectedControllerOption,
                    selectedOptionTriggerCondition: initValues.triggerCondition,
                    selectedOptionTrigger: TRIGGER[0],
                    triggerOption: TRIGGER_CONDITION_SHIPMENT
                })
            } else {
                this.setState({ selectedControllerOption: CONTROLLER_CONDITION[0] })
            }
        }
        this.showHideView(false)
    }
    handleChangeTrigger = (selectedOptionTrigger) => {
        if (selectedOptionTrigger.value && selectedOptionTrigger.value != 'SHIPMENT') {
            this.setState({ selectedCustomTrigger: selectedOptionTrigger });
        } else {
            if (selectedOptionTrigger) {
                this.setState({ selectedOptionTrigger, selectedCustomTrigger: '' });
                if (selectedOptionTrigger.value) {
                    this.setState({
                        optionTriggerCondition: TRIGGER_CONDITION[selectedOptionTrigger.value],
                        selectedOptionTriggerCondition: initValues.triggerCondition,
                    })
                } else {
                    this.setState({ optionTriggerCondition: null, selectedOptionTriggerCondition: initValues.triggerCondition })
                }
            }
            else {
                this.setState({ selectedOptionTrigger: TRIGGER[0] });
            }
        }

        // this.showHideView(false)
    }
    handleChangeTriggerCondition = (selectedOptionTriggerCondition) => {
        if (selectedOptionTriggerCondition) {
            this.setState({ selectedOptionTriggerCondition, conditions: initValues.conditions, actions: initValues.actions });
        }
        else {
            this.setState({ selectedOptionTriggerCondition: initValues.triggerCondition });
            this.setState({ conditions: initValues.conditions, actions: initValues.actions })
        }
        this.showHideView(false)
    }
    showHideView = (isVisible = false) => {
        if (!isVisible && Object.keys(this.conditionAvailableList).length > 0) {
            this.props.clearAllCondition()
            this.props.clearAllAction()
        }
    }
    handleChangeConditionOperator = (selectedOptionConditionOperator) => {
        if (selectedOptionConditionOperator) {
            this.setState({ selectedOptionConditionOperator });
        }
        else
            this.setState({ selectedOptionConditionOperator: CONDITION_OPERATOR[0] });
    }
    setDataToEdit = () => {
        let automationData = []
        if (this.props.automationDetail && Object.keys(this.props.automationDetail).length > 0) {
            automationData = this.props.automationDetail
            if (automationData.event_type == 'CUSTOM') {
                automationData.module_name = 'CUSTOM'
                this.setState({
                    selectedCustomTrigger: { label: automationData.custom_event_name, value: automationData.custom_event_id },
                    selectedControllerOption: CONTROLLER_CONDITION[1]
                })

            }else if(automationData.event_type == "CREATED"){
                this.setState({
                    selectedCustomTrigger: TRIGGER_CONDITION_SHIPMENT[0],
                    selectedControllerOption: CONTROLLER_CONDITION[0]
                })
            } else {
                this.setState({
                    selectedCustomTrigger: TRIGGER_CONDITION_SHIPMENT[1],
                    selectedControllerOption: CONTROLLER_CONDITION[0]
                })
            }
            
            let conditionOperator = automationData.logical_operator
            this.props.setLogicalOperator(conditionOperator)
            //set the condition count value
            let conditionCount = automationData.condition.length
            let conditionCountObj = {}
            for (let count = 1; count <= conditionCount; count++) {
                conditionCountObj[count] = count
            }
            //set the action count value
            let actionCount = automationData.action.length
            let actionCountObj = {}
            for (let count = 1; count <= actionCount; count++) {
                actionCountObj[count] = count
            }
            this.setState({
                selectedOptionConditionOperator: getLabel(CONDITION_OPERATOR, conditionOperator, true), conditions: conditionCountObj, actions: actionCountObj,
                automationName: automationData.name, selectedOptionTrigger: getLabel(TRIGGER, automationData.module_name, true),
                selectedOptionTriggerCondition: getLabel(TRIGGER_CONDITION[automationData.module_name], automationData.event_type, true), optionTriggerCondition: TRIGGER_CONDITION[automationData.module_name],
                allowToChangeTrigger : false
            })
        }
    }
    componentDidMount() {
        if (this.props.match.params.id) {
            this.setDataToEdit()
        }
        // this.getCourierList()
        let data = [
            { value: 'OutForDelivery', name: 'Out for Delivery' },
            { value: 'InTransit', name: 'In Transit' },
            { value: 'Delivered', name: 'Delivered' },
            { value: 'Exception', name: 'Exception' },
            { value: 'InfoReceived', name: 'Info Received' },
            { value: 'FailedAttempt', name: 'Failed Attempt' },
            { value: 'Pending', name: 'Pending' },
            { value: 'Expired', name: 'Expired' },
        ]
        this.props.setStatusList(data)

        let deliveryData = [
            { value: 24, name: '24 Hrs' },
            { value: 48, name: '48 Hrs' },
            { value: 72, name: '72 Hrs' },
        ]
        this.props.setDeliveryTimeList(deliveryData)
    }
    prepareAutomationRequestParams = () => {
        let requestParams = {}
        requestParams['name'] = this.state.automationName
        requestParams['module_name'] = (this.state.selectedOptionTrigger.value) ? this.state.selectedOptionTrigger.value : 'SHIPMENT'
        requestParams['event_type'] = (this.state.selectedControllerOption && this.state.selectedControllerOption.value && this.state.selectedControllerOption.value === 'TRIGGER') ? this.state.selectedCustomTrigger.value : 'CUSTOM'
        if (requestParams['event_type'] == 'CUSTOM') {
            requestParams['custom_event_id'] = this.state.selectedCustomTrigger.value
            requestParams['custom_event_name'] = this.state.selectedCustomTrigger.label
        }
        requestParams['logical_operator'] = this.props.logicalOperator
        let conditionObj = Object.keys(this.conditionAvailableList).map(function (key) {
            let automationRule = this.props.automationRule
            let index = this.conditionAvailableList[key]
            return {
                'collection': automationRule['conditionCollection'][index]['value'], 'query_param': automationRule['conditionCollection'][index]['field'],
                'operator': automationRule['conditionOperator'][index], 'query_param_value': this.props.conditionValueList[index]
            }
        }, this);
        requestParams['condition'] = conditionObj
        let actionObj = Object.keys(this.validActionParams).map(function (key) {
            return {
                'type': this.validActionTypeObj[key], 'request_params': this.validActionParams[key]['request_params'],
            }
        }, this);
        requestParams['action'] = actionObj
        requestParams['active'] = 1
        return requestParams
    }
    
    changeFooterMessage = (messageDetails = initValues.footerMessage) => {
        this.setState({ footerMessage: messageDetails, showMessage : true });
    }
    filterActionType = (actionTypeObj = {}) => {
        return Object.keys(actionTypeObj).filter(index => {
            return ((actionTypeObj[index] !== null) ? true : false)
        }).reduce((obj, key) => {
            return {
                ...obj,
                [key]: actionTypeObj[key]
            };
        }, {})
    }
    
    validateInput = () => {
        let element = document.getElementById('addAutomationSubmit')
        if (element.value.trim() === '') {
            element.classList.add('required')
            this.setState({ isAutomationNameEmpty: true })
        } else if (element.classList.contains('required')) {
            element.classList.remove('required')
            this.setState({ isAutomationNameEmpty: false })
        }
    }
    filterShipmentActionType = (actionTypeObj = {}) => {
        let counter = 0
        let res = true
        for (let key in actionTypeObj) {
            if (actionTypeObj[key] === "COURIER" || actionTypeObj[key] === "HANDOVER" || actionTypeObj[key] === 'AUTO_SHIP' || actionTypeObj[key] === 'MCF') {
                counter++
            }
        }
        if (counter > 1) {
            res = false
        }
        return res
    }
    filterActionParameter = (actionParamObj = {}) => {
        return Object.keys(actionParamObj).filter(index => {
            return ((actionParamObj[index] !== null && actionParamObj[index]['is_request_valid']) ?
                (actionParamObj[index]['is_request_valid'] === true) : false)
        }).reduce((obj, key) => {
            return {
                ...obj,
                [key]: actionParamObj[key]
            };
        }, {})
    }
    checkValidation = () => {
        //Check if automation name is set or not
        if (this.state.automationName === '') {
            let errorMessage = { type: 'ERROR', message: AUTOMATION_NAME_IS_REQUIRED }
            this.changeFooterMessage(errorMessage)
            return false
        }
        //Check if the trigger points are set or not
        if (this.state.selectedControllerOption === 'TRIGGER') {
            if (this.state.selectedOptionTrigger.value === '' || this.state.selectedOptionTriggerCondition.value === '') {
                let errorMessage = { type: 'ERROR', message: INVALID_TRIGGER }
                this.changeFooterMessage(errorMessage)
                return false
            }
        }
        //Check if any condition is set or not 
        if (Object.keys(this.conditionAvailableList).length === 0) {
            let errorMessage = { type: 'ERROR', message: INVALID_CONDITION }
            this.changeFooterMessage(errorMessage)
            return false
        }
        //Check if any action is set or not
        if (this.props.automationRule) {
            let automationRule = this.props.automationRule
            if (!automationRule.actionType || !automationRule.actionRequestParams) {
                let errorMessage = { type: 'ERROR', message: INVALID_ACTION }
                this.changeFooterMessage(errorMessage)
                return false
            }
            if (automationRule.actionType) {
                this.validActionTypeObj = this.filterActionType(automationRule.actionType)
                let validFlag = this.filterShipmentActionType(automationRule.actionType)
                if (!validFlag) {
                    let errorMessage = { type: 'ERROR', message: INVALID_SHIPMENT_ACTION }
                    this.changeFooterMessage(errorMessage)
                    return false
                }
                if (Object.keys(this.validActionTypeObj).length === 0) {
                    let errorMessage = { type: 'ERROR', message: INVALID_ACTION }
                    this.changeFooterMessage(errorMessage)
                    return false
                }
            }
            if (automationRule.actionRequestParams) {
                this.validActionParams = this.filterActionParameter(automationRule.actionRequestParams)
                if (Object.keys(this.validActionParams).length === 0) {
                    let errorMessage = { type: 'ERROR', message: INVALID_ACTION }
                    this.changeFooterMessage(errorMessage)
                    return false
                }
            }
        } else {
            return false
        }
        return true;
    }
    onSubmit = () => {
        let isValid = this.checkValidation()
        if (isValid) {
            let automationRuleRequestParams = this.prepareAutomationRequestParams()
            if (this.props.match.params.id) {
                delete automationRuleRequestParams['active']
                this.props.updateAutomationRule(this.props.match.params.id, automationRuleRequestParams).then((res) => {
                    this.handleSubmitResponse(res)
                })
            } else {
                this.props.addAutomationRule(automationRuleRequestParams).then((res) => {
                    this.handleSubmitResponse(res)
                })
            }
        }
    }
    handleSubmitResponse = (res) => {
        if (res.status && !(res.status >= 200 && res.status <= 299)) {
            if (res.data.errors) {
                let errorMessage = { type: 'ERROR', message: res.data.errors[0] }
                this.changeFooterMessage(errorMessage)
            } else {
                let errorMessage = { type: 'ERROR', message: res.data.meta.message[0] }
                this.changeFooterMessage(errorMessage)
            }
        } else {
            if (this.props.match.params.id) {
                this.props.updateAutomationRuleListState(this.props.match.params.id, res.data.data)
            }
            this.props.clearAllCondition()
            this.props.clearAllAction()
            this.setState({
                selectTriggerPage: false,
                automationName : '',
                selectedControllerOption: initValues.controllerCondition
            })
            this.props.closeDialog()
            this.props.history.push(`/automations`)
        }
    }
    componentWillUnmount() {
        this.props.clearAllCondition()
        this.props.clearAllAction()
    }
    handleClose = () => {
        this.props.clearAllCondition()
        this.props.clearAllAction()
        this.setState({
            selectTriggerPage: false,
            automationName : '',
            selectedControllerOption: initValues.controllerCondition
        })
        this.props.closeDialog()
        this.props.history.push(`/automations`)
    };
    selectTrigger = () => {
        this.setState({
            selectTriggerPage: true,
        })
    }
    selectTriggerClose = () => {
        // this.props.clearAllCondition()
        // this.props.clearAllAction()
        this.setState({
            selectTriggerPage: false
        })
    }
    toggleFlashMessage = () => {
        this.setState({
            showMessage : false
        })
    }
    render() {
        const { openDialog, classes } = this.props
        let controllerCondition = CONTROLLER_CONDITION
       
        this.conditionAvailableList = Object.keys(this.props.conditionValueList).filter(index => this.props.conditionValueList[index] !== null)
        return (
            <div>
                <Dialog fullScreen open={openDialog} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar classes={{root:classes.dialogToolbarRoot,gutters: classes.toolBarGutter}}>
                            <IconButton className={classes.dialogIcon} edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                <img src={backArrow} />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Back To Automate
                            </Typography>
                            <FormControl className={classes.formControl}>
                                {/* <InputLabel htmlFor="filled-adornment-name">Name Your Automation</InputLabel> */}
                                <Input
                                    className={classes.automationNameInput}
                                    id="filled-adornment-name"
                                    type='text'
                                    disableUnderline={true}
                                    value={this.state.automationName}
                                    placeholder='Name Your Automation'
                                    onBlur={this.checkAutomationName}
                                    onChange={this.handleAutomationName}
                                    endAdornment={<InputAdornment position="end"><img src={updateIcon} /></InputAdornment>}
                                    aria-describedby="filled-adornment-name-helper-text"
                                />
                            </FormControl>
                            {!this.state.selectTriggerPage && this.state.allowToChangeTrigger ?
                                <Button className={classes.dialogAppbarButton} autoFocus color="inherit" onClick={this.selectTrigger}>
                                    Next
                            </Button> : ""}
                            {this.state.selectTriggerPage || !this.state.allowToChangeTrigger ?
                                <React.Fragment>
                                    <Typography variant="h6" className={classes.title}>
                                        Automation is OFF
                                    </Typography>
                                    {(Object.keys(this.props.conditionValueList).filter(index => this.props.conditionValueList[index] !== null).length > 0
                                    && Object.keys(this.props.actionType).filter(index => this.props.actionType[index] !== null).length > 0
                                    && this.state.automationName !== '' && this.state.automationName !== null && this.state.automationName !== undefined) || !this.state.allowToChangeTrigger ? 
                                    <Button className={classes.dialogAppbarButton} style={{marginLeft: "16px"}} autoFocus color="inherit" onClick={this.onSubmit}>
                                        Turn ON
                                    </Button> 
                                    :
                                    <Button className={classes.dialogAppbarButton} style={{marginLeft: "16px"}} autoFocus color="inherit" onClick={this.selectTriggerClose}>
                                        Review
                                    </Button> 
                                    }
                                   
                                </React.Fragment>
                                : ""
                            }
                        </Toolbar>
                    </AppBar>
                    {
                        this.props.isLoading === true ? 
                        <div className={classes.progressbarDialog}>
                        <LinearProgress color="primary" />
                        </div>
                        :""
                    }
                      
                    {this.state.selectTriggerPage !== true && !this.props.match.params.id ?
                        <div className={classes.shipmentbaseConatainer}>
                            <div className={classes.shipmentBasesGrid}>
                                <div>
                                    <Card className={classes.shipmentCard}>
                                        <CardHeader className={classes.shipmentCardHeader}
                                            classes={{ title: classes.shipmentCardSubTitle }}
                                            // subheader={"Shipment Based"}
                                            title={'Shipment Based'}
                                            avatar={<Avatar aria-label="recipe" src={shipmentIcon} ></Avatar>}
                                        >
                                        </CardHeader>
                                        {/* <CardActions className={classes.shipmentCardActions}>
                            <Link className="cardAction" to={props.path}>EDIT</Link>
                        </CardActions> */}
                                    </Card>
                                </div>
                            </div>
                            <div className={classes.shipmentTriggerDiv}>
                                {/* <Typography>Import Shipment</Typography> */}
                                <div>
                                    <Typography className={classes.shipmentBaseText}>Trigger Type</Typography>
                                    <List>
                                        {controllerCondition.map((option) => {
                                            const labelId = `checkbox-list-label-${option.value}`;

                                            return (
                                                <ListItem classes={{
                                                    root: classes.listItemRoot
                                                }}
                                                disableGutters={true} key={option.value} role={undefined} dense button>
                                                    <ListItemIcon className={classes.exportListIcon}>
                                                        <Radio
                                                            classes={{
                                                                root : classes.radioRoot
                                                            }}
                                                            checked={this.state.selectedControllerOption.value === option.value}
                                                            onChange={(event) => this.handleChangeController(option)}
                                                            name="radio-button-demo"
                                                            color='primary'
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText className={classes.exportDrawerRadioLable} disableTypography={true} id={labelId} primary={option.label} />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </div>
                                <div>
                                    <Typography className={classes.shipmentBaseTextPreview}>Preview</Typography>
                                    <img className="automationImagePreview" src={automationPreview} />
                                </div>
                            </div>
                        </div>
                        : <AutomationWorkflow
                            triggerOption ={this.state.triggerOption}
                            setDataFlag={true} 
                            parameterId={(this.props.match.params.id) ? this.props.match.params.id : null} 
                            setDataFlag={true}
                            // key={''} index={''} 
                            // removeCondition={this.removeCondition} 
                            storeList={this.props.storeList} 
                            trigger={this.state.selectedOptionTrigger.value || this.state.selectedCustomTrigger.value} 
                            triggerCondition={this.state.selectedOptionTriggerCondition.value || 'CREATED'}
                            selectedTriggerType={this.state.selectedOptionTrigger.value}
                            handleChangeTrigger = {this.handleChangeTrigger}
                            selectedCustomTrigger = {this.state.selectedCustomTrigger}
                            allowToChangeTrigger = {this.state.allowToChangeTrigger}
                            
                        />
                    }
                    {(this.state.showMessage) ?
                        <FlashMessage open={this.state.showMessage} messageDetails={this.state.footerMessage} toggleFlashMessage={this.toggleFlashMessage} />
                        : null
                    }
                </Dialog>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    isLoading: state.automations.loader.isLoading,
    conditionValueList: state.automations.list.conditionValueList,
    automationRule: state.automations.list,
    automationDetail: state.automations.list.automationDetail,
    statusList: state.automations.list.statusList,
    courierList: state.automations.list.courierList,
    customTriggerList: state.automations.list.customTriggerList,
    actionType : state.automations.list.actionType,
    logicalOperator : state.automations.list.logicalOperator
})
export default (withRouter(connect(mapStateToProps, { setDeliveryTimeList, setStatusList, setCourierList, removeAutomationConditionByKey, removeAutomationActionByKey, clearAllCondition, clearAllAction, addAutomationRule, updateAutomationRuleListState, getAutomationDetail, updateAutomationRule, getCourierList, setLogicalOperator })(AddNewAutomation)))
