import React from 'react';
import { TrackingBtnIcon } from '../../../common/imageList'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
// import Radio from '@material-ui/core/Radio';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';

// const options = [
//     { label: 'Tracking Number', value: 'tracking_number' },
//     { label: 'Order Number',  value: 'order_number' }
// ];
const styles = () => ({
    textFieldLabel: {
      height: '19px',
      width : '98%',
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.16px',
      color: '#666666',
      transform: 'translate(14px, 16px) scale(1)'
    },
    textField: {
        width: '98%',
        height: '50px',
        backgroundColor: '#fff',
        "& label.Mui-focused": {
            color: '#1a73e8'
        },
        "& .MuiOutlinedInput-root": {
            height: "50px",
            "&.Mui-focused fieldset": {
            borderColor: '#1a73e8'
            }
        }
    },
    button: {
      background: '#1a73e8',
    //   width: '88px',
      minWidth: '132px',
      position: 'relative',
      height: '50px',
      fontFamily: 'Roboto',
      fontSize: '18px',
      fontWeight: '500',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.14px',
      color: '#ffffff',
      float: 'right',
      textTransform: 'none',
      "&:hover": {
        background: '#1a73e8'
      }
    }
    // listItemRoot : {
    //     width : '30%'
    // },
    // listIcon : {
   
    //     // paddingLeft : '0px',
    //     minWidth : '36px',
    //     '& .PrivateSwitchBase-root-596' :{
    //       paddingLeft : '0px',
    //       paddingTop : '5px',
    //       paddingBottom : '5px'
    //     }
    //   },
    //   radioLable : {
    //     fontFamily: 'Roboto',
    //     fontSize: '16px',
    //     fontWeight: 'normal',
    //     fontStretch: 'normal',
    //     fontStyle: 'normal',
    //     lineHeight: 'normal',
    //     letterSpacing: '0.16px',
    //     color: '#5e6367',
    // },
    // radioRoot: {
    //     padding: '9px 9px 9px 0px'
    // },
    // radioDiv : {
    //     display : 'flex'
    // }
  });

class TrackButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {            
            inputValue: '',
            isInputError: false,
            url:'',
            // checkedOption: options[0],
        };
    }
    componentDidMount() {
            var url = window.location.search.substring(1); //get rid of "?" in querystring          
            var qArray = url.split('=')[1]; //get key-value pairs          
            this.setState({url:qArray})
            var li = document.getElementById('root');
            li.className = li.className.replace(/\bbg-Gray\b/g, "");
        
    }        
    handleInputValue = (val) => {
        this.setState({
            inputValue: val,
            isInputError: false
        })
    }
    handleTrackingAction = () => {
        if (this.state.inputValue === '') {
            this.setState({
                isInputError: true
            })
        }
        else {
            // if(this.state.checkedOption.value === 'tracking_number') {
                window.open(`https://${this.state.url}/trackingPage/${this.state.inputValue}?searchBy=tracking_number`, '_blank');
            // } else {
            //     window.open(`https://${this.state.url}/trackingPage/${this.state.inputValue}?searchBy=order_number`, '_blank');
            // }
            
        }
    }
    // onOptionChangeHandler = (option) => {
    //     this.setState({ checkedOption: option, isInputError : false })
    // }
    
    render() {
        const {classes} = this.props
        return (           
                <div className="trackingPageWithSearchMainDiv">
                    <div className="wrapperDiv">                        
                        <div className="searchMainBodyDiv">
                            <div className="searchInnerDiv">                               
                                <div className="textBoxAndButtonMainDiv">
                                    <div className="textBoxAndButtonInnerDiv">
                                        <div className="inputDiv">
                                            {/* <input type="text" value={this.state.inputValue} placeholder="Tracking Number" onChange={(e) => this.handleInputValue(e.target.value)} /> */}
                                            <TextField
                                                InputLabelProps={{
                                                    className: classes.textFieldLabel
                                                }}
                                                error={this.state.isInputError ? true : false}
                                                id="tracking_number"
                                                // label={this.state.checkedOption.label}
                                                label="Tracking Number"
                                                variant="outlined"
                                                className={classes.textField}
                                                value={this.state.inputValue}
                                                onChange={(e) => this.handleInputValue(e.target.value)}
                                            ></TextField>
                                            {
                                                this.state.isInputError ?
                                                    <label className="error-fonts">Please enter tracking number</label> : ''}
                                        </div>
                                        <div className="buttonDiv">
                                            {/* <button onClick={this.handleTrackingAction}>
                                                <img src={TrackingBtnIcon} />
                                                <span>Track</span>
                                            </button> */}
                                            <Button
                                                onClick={this.handleTrackingAction}
                                                startIcon={<img src={TrackingBtnIcon} />}
                                                className={classes.button}>
                                                Track
                                            </Button>
                                        </div>
                                    </div>
                                    {/* <div className={classes.radioDiv}>
                                    {options.map((option) => {
                                         const labelId = `checkbox-list-label-${option.label}`;
                                        return (
                                            <ListItem  className={classes.listItemRoot} disableGutters={true} key={option.label} role={undefined} dense button>
                                            <ListItemIcon className={classes.listIcon}>
                                                <Radio
                                                    classes={{
                                                        root: classes.radioRoot
                                                    }}
                                                    checked={option === this.state.checkedOption}
                                                    onChange={(event) => this.onOptionChangeHandler(option)}
                                                    name="radio-button-demo"
                                                    color='primary'
                                                />
                                            </ListItemIcon>
                                            <ListItemText className={classes.radioLable} disableTypography={true} id={labelId} primary={option.label} />
                                        </ListItem>
                                        )
                                    })}
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                                
        )
    }
}
// export default TrackButton;
export default withStyles(styles)(TrackButton)