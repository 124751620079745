import '@firebase/auth';
import axios from 'axios';
import '@firebase/database';
import { firebase } from '@firebase/app';
import { getInstance } from '../config/token';
import * as constants from './reducers/constants'
import { setLocalStorageData } from './localstorage'
import { addStore,hubSpotForm } from '../Setup/actions/actionMethods'

const prefix = process.env.REACT_APP_API_URL

export function returnData(type, data, status) {
    if (status >= 200 && status <= 299) {
        return {
            type: type,
            data: data
        }
    } else {
        console.log('error');
    }
}

export function userLogin(email, password, callBack) {
    const storageItem = (JSON.parse(localStorage.getItem('userNamesList')) || [])
    const loginUrl = prefix + '/user/login'
    return dispatch => {
        axios.post(loginUrl, {
            email: email,
            password: password
        }).then(response => {
            if (response && response.data) {
                if (response.data.meta.code === 200) {
                    setLocalStorageData(response.data.data)
                    localStorage.setItem("trackhiveToken", JSON.stringify(response.data.data));
                    localStorage.setItem('intercomBoot', true)
                    if(response.data.data.role_id){
                        localStorage.setItem('role_id', response.data.data.role_id)
                    }
                    let userData = response.data.data
                    if (storageItem.filter(item => item.email === email).length === 0) {
                        storageItem.push({
                            name: userData.name,
                            email: email
                        });
                    }
                    storageItem.reverse()
                    localStorage.setItem('userNamesList', JSON.stringify(storageItem));
                    /** 
                     * javascript comment 
                     * @Author: Akshay Tukadiya
                     * @Date: 2020-01-21 10:07:00 
                     * @Desc: Get Firebase token
                     */
                    getFirebaseToken(response.data.data._id)
                    if(response.data.data.role === "subuser"){  
                        if (response.data.data.role_access || response.data.data.roles) {
                            dispatch({
                                type: constants.ROLES_DATA,
                                data: response.data.data.role_access && response.data.data.role_access.role ? response.data.data.role_access.role : response.data.data.roles ? response.data.data.roles : [] 
                            })
                            dispatch({
                                type: constants.LOGIN
                            })
                            localStorage.setItem('role_access', response.data.data.role_access && response.data.data.role_access.role ? JSON.stringify(response.data.data.role_access) : response.data.data.roles ? JSON.stringify({"role":response.data.data.roles}) : '')
                        }
                    }
                   else {
                        dispatch({
                            type: constants.LOGIN
                        })
                    }
                    let bigCommerceStoreDetails = JSON.parse(localStorage.getItem("BigCommerceStoreKey"))
                    if (bigCommerceStoreDetails !== undefined && bigCommerceStoreDetails !== null) {
                        let prepareData = {}
                        prepareData.user_id = response.data.data._id
                        prepareData.request_id = bigCommerceStoreDetails.key
                        prepareData.channel_id = '2'
                        prepareData.active = 1

                        addStore(prepareData).then(res => {
                            if (res.data !== undefined && res.data !== null) {
                                localStorage.removeItem('BigCommerceStoreKey')
                                callBack(true)
                            }
                        })
                    } else {
                        callBack(true)
                    }
                }
            }
        }).catch(error => {
            if (error.response && error.response.data) {
                if (error.response.data.meta) {
                    if (error.response.data.meta.code === 404) {
                        dispatch({
                            type: constants.USER_LOGIN_ERROR,
                            payload: error.response.data.meta.message
                        })
                    }
                    callBack(false)
                }
            }
        })
    }
}

export function storeUserLogin(email, password, callBack) {
    localStorage.setItem('redirect_to_integrated_list', 1);
    const storageItem = (JSON.parse(localStorage.getItem('userNamesList')) || [])
    const loginUrl = prefix + '/user/login'
    return dispatch => {
        axios.post(loginUrl, {
            email: email,
            password: password
        }).then(response => {
            if (response && response.data) {
                if (response.data.meta.code === 200) {
                    setLocalStorageData(response.data.data)
                    localStorage.setItem("trackhiveToken", JSON.stringify(response.data.data));
                    localStorage.setItem('intercomBoot', true)
                    let userData = response.data.data
                    if (storageItem.filter(item => item.email === email).length === 0) {
                        storageItem.push({ name: userData.name, email: email });
                    }
                    storageItem.reverse()
                    localStorage.setItem('userNamesList', JSON.stringify(storageItem));
                    /** 
                     * javascript comment 
                     * @Author: Akshay Tukadiya
                     * @Date: 2020-01-21 10:07:00 
                     * @Desc: Get Firebase token
                    */
                    getFirebaseToken(response.data.data._id)
                    dispatch({
                        type: constants.LOGIN
                    })
                    let shopifyStoreDetails = JSON.parse(localStorage.getItem("ShopifyStoreData"))
                    if (shopifyStoreDetails !== undefined && shopifyStoreDetails !== null) {
                        let prepareData = {}
                        prepareData.user_id = response.data.data._id
                        prepareData.channel_id = shopifyStoreDetails.shop.channel_id
                        prepareData.store_name = shopifyStoreDetails.shop.name
                        prepareData.url = shopifyStoreDetails.shop.url
                        prepareData.active = 1
                        prepareData.key1 = shopifyStoreDetails.shop.access_token
                        
                        addStore(prepareData).then(res => {
                            if (res.data !== undefined && res.data !== null) {
                                localStorage.removeItem('ShopifyStoreData')
                                callBack(true)
                            }
                        })
                    }
                    let bigCommerceStoreDetails = JSON.parse(localStorage.getItem("BigCommerceStoreKey"))
                    if (bigCommerceStoreDetails !== undefined && bigCommerceStoreDetails !== null) {
                        let prepareData = {}
                        prepareData.user_id = response.data.data._id
                        prepareData.request_id = bigCommerceStoreDetails.key
                        prepareData.channel_id = '2'                        
                        prepareData.active = 1                       
                        addStore(prepareData).then(res => {
                            if (res.data !== undefined && res.data !== null) {
                                localStorage.removeItem('BigCommerceStoreKey')
                                callBack(true)
                            }
                        })
                    }                    
                   
                }
            }
        }).catch(error => {
            localStorage.setItem('redirect_to_integrated_list', 0);
            if (error.response && error.response.data) {
                if (error.response.data.meta) {
                    if (error.response.data.meta.code === 404) {
                        dispatch({
                            type: constants.USER_LOGIN_ERROR,
                            payload: error.response.data.meta.message
                        })
                    }
                    callBack(false)
                }
            }
        })
    }
}

export function storeUserLoginWithStoreExist (email, password, callBack){
    localStorage.setItem('redirect_to_integrated_list', 1);
    const storageItem = (JSON.parse(localStorage.getItem('userNamesList')) || [])
    const loginUrl = prefix + '/user/login'
    return dispatch => {
        axios.post(loginUrl, {
            email: email,
            password: password
        }).then(response => {
            if (response && response.data) {
                if (response.data.meta.code === 200) {
                    setLocalStorageData(response.data.data)
                    localStorage.setItem("trackhiveToken", JSON.stringify(response.data.data));
                    localStorage.setItem('intercomBoot', true)
                    let userData = response.data.data
                    if (storageItem.filter(item => item.email === email).length === 0) {
                        storageItem.push({ name: userData.name, email: email });
                    }
                    storageItem.reverse()
                    localStorage.setItem('userNamesList', JSON.stringify(storageItem));
                    /** 
                     * javascript comment 
                     * @Author: Akshay Tukadiya
                     * @Date: 2020-01-21 10:07:00 
                     * @Desc: Get Firebase token
                    */
                    getFirebaseToken(response.data.data._id)
                    dispatch({
                        type: constants.LOGIN
                    })

                    callBack(true)
                   
                    // let shopifyStoreDetails = JSON.parse(localStorage.getItem("ShopifyStoreData"))
                    // if (shopifyStoreDetails !== undefined && shopifyStoreDetails !== null) {
                    //     let prepareData = {}
                    //     prepareData.user_id = response.data.data._id
                    //     prepareData.channel_id = shopifyStoreDetails.shop.channel_id
                    //     prepareData.store_name = shopifyStoreDetails.shop.name
                    //     prepareData.url = shopifyStoreDetails.shop.url
                    //     prepareData.active = 1
                    //     prepareData.key1 = shopifyStoreDetails.shop.access_token
                        
                    //     addStore(prepareData).then(res => {
                    //         if (res.data !== undefined && res.data !== null) {
                    //             localStorage.removeItem('ShopifyStoreData')
                    //           
                    //         }
                    //     })
                    // }
                    // let bigCommerceStoreDetails = JSON.parse(localStorage.getItem("BigCommerceStoreKey"))
                    // if (bigCommerceStoreDetails !== undefined && bigCommerceStoreDetails !== null) {
                    //     let prepareData = {}
                    //     prepareData.user_id = response.data.data._id
                    //     prepareData.request_id = bigCommerceStoreDetails.key
                    //     prepareData.channel_id = '2'                    
                    //     prepareData.active = 1                        
                        
                    //     addStore(prepareData).then(res => {
                    //         if (res.data !== undefined && res.data !== null) {
                    //             localStorage.removeItem('BigCommerceStoreKey')
                    //             callBack(true)
                    //         }
                    //     })
                    // }
                    
                }
            }
        }).catch(error => {
            localStorage.setItem('redirect_to_integrated_list', 0);
            if (error.response && error.response.data) {
                if (error.response.data.meta) {
                    if (error.response.data.meta.code === 404) {
                        dispatch({
                            type: constants.USER_LOGIN_ERROR,
                            payload: error.response.data.meta.message
                        })
                    }
                    callBack(false)
                }
            }
        })
    }
}

function checkURL() {
    let returnValue  = process.env.REACT_APP_API_URL
    return returnValue
}

/** 
 * javascript comment 
 * @Author: Akshay Tukadiya 
 * @Date: 2020-01-21 09:43:00 
 * @Desc: Function to get firebase token and sign in in firebase with that custom token .
 */

export function getFirebaseToken(token) {
    let config = getInstance('GET', '/firebase/token/'+ token);
    axios(config)
    .then(res => {
        localStorage.setItem("firebaseToken", JSON.stringify(res.data.data));
        firebase.auth().signInWithCustomToken(res.data.data)
            .then(function () {
                return true
            }, function (error) {
            return true
            });
    })
    .catch(function (error) {
        console.dir(error);
        return true
    })
}

export function signUp(requestParams, callBack) {
    const storageItem = (JSON.parse(localStorage.getItem('userNamesList')) || [])
    return dispatch => {
        axios.post(checkURL() + '/user/register', requestParams).then(registrationResponse => {
            if (registrationResponse && registrationResponse.data) {
                if (registrationResponse.data.meta.code === 200) {
                    setLocalStorageData(registrationResponse.data.data)
                    localStorage.setItem('trackhiveToken', JSON.stringify(registrationResponse.data.data));
                    let userData = registrationResponse.data.data
                    if (storageItem.filter(item => item.email === requestParams.email).length === 0) {
                        storageItem.push({ name: userData.name, email: requestParams.email })
                    }
                    storageItem.reverse()
                    localStorage.setItem('userNamesList', JSON.stringify(storageItem));
                    /** 
                     * javascript comment 
                     * @Author: Akshay Tukadiya
                     * @Date: 2020-01-21 10:07:00 
                     * @Desc: Get Firebase token
                    */
                    getFirebaseToken(registrationResponse.data.data._id)

                    hubSpotForm()

                    dispatch({
                        type: constants.LOGIN
                    })
                    let bigCommerceStoreDetails = JSON.parse(localStorage.getItem("BigCommerceStoreKey"))
                    if (bigCommerceStoreDetails !== undefined && bigCommerceStoreDetails !== null) {
                        let prepareData = {}
                        prepareData.user_id = registrationResponse.data.data._id
                        prepareData.request_id = bigCommerceStoreDetails.key
                        prepareData.channel_id = '2'                        
                        prepareData.active = 1                      
                        
                        addStore(prepareData).then(res => {
                            if (res.data !== undefined && res.data !== null) {
                                localStorage.removeItem('BigCommerceStoreKey')
                                callBack(true)
                            }
                        })
                    }  
                    else{
                        callBack(true)
                    }                   
                }
            }
        }).catch(
            error => {
                if (error.response && error.response.data) {
                    if (error.response.data.meta) {
                        if (error.response.data.meta.code === 400) {
                            dispatch({
                                type: constants.USER_LOGIN_ERROR,
                                payload: error.response.data.meta.message
                            })
                        }
                        callBack(false)
                    }
                }
            }
        )
    }
}

export function storeSignup(requestParams, callBack) {
    localStorage.setItem('redirect_to_integrated_list', 1);
    const storageItem = (JSON.parse(localStorage.getItem('userNamesList')) || [])
    return dispatch => {
        axios.post(checkURL() + '/user/register', requestParams).then(registrationResponse => {
            if (registrationResponse && registrationResponse.data) {
                if (registrationResponse.data.meta.code === 200) {
                    setLocalStorageData(registrationResponse.data.data)
                    localStorage.setItem('trackhiveToken', JSON.stringify(registrationResponse.data.data));
                    let userData = registrationResponse.data.data
                    if (storageItem.filter(item => item.email === requestParams.email).length === 0) {
                        storageItem.push({ name: userData.name, email: requestParams.email })
                    }
                    storageItem.reverse()
                    localStorage.setItem('userNamesList', JSON.stringify(storageItem));
                    /** 
                     * javascript comment 
                     * @Author: Akshay Tukadiya
                     * @Date: 2020-01-21 10:07:00 
                     * @Desc: Get Firebase token
                    */
                    getFirebaseToken(registrationResponse.data.data._id)
                    dispatch({
                        type: constants.LOGIN
                    })
                    let shopifyStoreDetails = JSON.parse(localStorage.getItem("ShopifyStoreData"))
                    if (shopifyStoreDetails !== undefined && shopifyStoreDetails !== null) {
                        let prepareData = {}
                        prepareData.user_id = registrationResponse.data.data._id
                        prepareData.channel_id = shopifyStoreDetails.shop.channel_id
                        prepareData.store_name = shopifyStoreDetails.shop.name
                        prepareData.url = shopifyStoreDetails.shop.url
                        prepareData.active = 1
                        prepareData.key1 = shopifyStoreDetails.shop.access_token
                        addStore(prepareData).then(res => {
                            if (res.data !== undefined && res.data !== null) {
                                localStorage.removeItem('ShopifyStoreData')
                                callBack(true)
                            }
                        })
                    }
                    let bigCommerceStoreDetails = JSON.parse(localStorage.getItem("BigCommerceStoreKey"))
                    if (bigCommerceStoreDetails !== undefined && bigCommerceStoreDetails !== null) {
                        let prepareData = {}
                        prepareData.user_id = registrationResponse.data.data._id
                        prepareData.request_id = bigCommerceStoreDetails.key
                        prepareData.channel_id = '2'                       
                        prepareData.active = 1                     
                        addStore(prepareData).then(res => {
                            if (res.data !== undefined && res.data !== null) {
                                localStorage.removeItem('BigCommerceStoreKey')
                                callBack(true)
                            }
                        })
                    }             
                }
            }
        }).catch(
            error => {
                localStorage.setItem('redirect_to_integrated_list', 0)
                if (error.response && error.response.data) {
                    if (error.response.data.meta) {
                        if (error.response.data.meta.code === 400) {
                            dispatch({
                                type: constants.USER_LOGIN_ERROR,
                                payload: error.response.data.meta.message
                            })
                        }
                        callBack(false)
                    }
                }
            }
        )
    }
}

export function clearError() {
    let data = ''
    return dispatch => {
        dispatch({
            type: constants.CLEAR_ERROR,
            data: data
        })
    }
}

export function clearRoles(){
    return dispatch => {
        dispatch({
            type: constants.CLEAR_ROLES_DATA,
            data: []
        })
    }
}

export function userLogout(callback) {
    localStorage.removeItem("trackhiveToken");
    localStorage.removeItem("intercomBoot")
    localStorage.removeItem("ProfileName");
    localStorage.removeItem("Reply_To");
    localStorage.removeItem("Company_Name");
    localStorage.removeItem("dashboardData");
    localStorage.removeItem("date_format");
    localStorage.removeItem("TIMEZONE");
    localStorage.removeItem("sub_domain");
    localStorage.removeItem("custom_domain_url");
    localStorage.removeItem("sub_domain_status");
    localStorage.removeItem("redirect_to_integrated_list");
    localStorage.removeItem("daily_digest_email_active");
    localStorage.removeItem("firebaseToken");
    localStorage.removeItem("role_access");
    localStorage.removeItem("role_id");
    localStorage.removeItem('user_type');
    localStorage.removeItem("trackingColumn");
    const localStorageItem = JSON.parse(localStorage.getItem('userNamesList'));
    if (localStorageItem) {
        callback('/users')
    }
    else {
        callback('/login')
    }
    return {
        type: constants.LOGOUT
    }
}

export function verifyUserEmail(email, callBack) {
    const verifyEmailUrl = prefix + '/user/verify_email'
    return dispatch => {
        axios.post(verifyEmailUrl, {
            email: email,
        }).then(response => {
            if (response && response.data) {
                if (response.data.meta.code === 200) {
                    let userData = response.data.data
                    localStorage.setItem('UserName', JSON.stringify({ name: userData.name, email: userData.email }));
                    callBack(true)                
                }
            }
        }).catch(error => {
            if (error.response && error.response.data) {
                if (error.response.data.meta) {
                    if (error.response.data.meta.code === 404) {
                        dispatch({
                            type: constants.VERIFY_EMAIL_ERROR,
                            payload: error.response.data.meta.message
                        })
                    }
                    callBack(false)
                }
            }
        })
    }
}