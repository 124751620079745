import React from 'react';
import Footer from '../../../../common/footer';

export default class NinetyNineMinutosInstructions extends React.Component{
    moveToNinetyNineMinutosForm = () => {
        this.props.moveToNinetyNineMinutosForm(true)
    }
    backToIntegration = () => {
        this.props.backToIntegration()
    }

    render(){
        return(
            <React.Fragment>
                <div className="integrationStepsMainDiv">
                    <ul className="instructions-list">
                        <li>
                            <span className="list-seq">1</span>
                            <span className="instructions-text">
                                You will receive API Key From 99minutos courier team.
                            </span>
                        </li>
                        <li>
                            <span className="list-seq">2</span>
                            <span className="instructions-text">
                                Enter <strong>API Key</strong>.
                            </span>

                        </li>
                        <li>
                            <span className="list-seq">3</span>
                            <span className="instructions-text">
                                Click on the <strong>Done</strong> button to successfully integrate with 99 Minutos.
                            </span>

                        </li>
                    </ul>
                    <button
                        id="store-next-btn"
                        type="button"
                        style={{
                            float: 'right',
                            display: 'none'
                        }}
                        onClick={this.moveToNinetyNineMinutosForm}
                    >
                        Next
                    </button>
                    <button
                        id="store-cancel-btn"
                        type="button"
                        style={{
                            float: 'right',
                            display: 'none'
                        }}
                        onClick={this.backToIntegration}
                    >
                        Cancel
                    </button>
                </div>

            <div className="commonFooter">
                <Footer storeAction="true" />
            </div>
            </React.Fragment>
        )
   }
}