import React from 'react';
import {CirclePicker, HuePicker} from 'react-color';
import { UncontrolledButtonDropdown, DropdownMenu } from 'reactstrap';

class ColorPicker extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
           background: '#ffffff',
           colorCode: '#F5C4CC'
        };
    }
    
    handleChangeComplete = (color, event) => {
        this.setState({ background: color.rgb, colorCode: color.hex });
        this.props.handleBackgroundColor(color.hex)
    };
    
    render(){
        return(
        <UncontrolledButtonDropdown className="floatLeft">
        <DropdownMenu className="tag-position">
            <div className="title">Create New Tag</div>
            <CirclePicker
            circleSize={20}
            colors
            ={[
            "#FFF4BF",
            "#D2FCBD",
            "#DEF1FF",
            "#F2E6FF",
            "#FFD9DF",
            "#FFDE4C",
            "#88F054",
            "#64BBFA",
            "#BD80FF",
            "#FA7087"
          ]}
            className="circle_picker"
            color={this.state.background}
            onChangeComplete={this.handleChangeComplete}/>
          <div
            className="BotBorder"
            style={{
            marginTop: 12
          }}></div>
          <HuePicker
            className="huepicker"
            color={this.state.background}
            onChangeComplete={this.handleChangeComplete}/>
          {/* <AlphaPicker
            className="alphapicker"
            color={this.state.background}
            onChangeComplete={this.handleChangeComplete}/> */}
          <button
            className="dd-cancel-tag"
            type="cancel"
            onClick={() => this.props.showPicker()}>Cancel</button>
          <button
            className="dd-add-tag"
            type="submit"
            onClick={() => {this.props.showPicker(); this.props.handleDonePicker(this.props.query)}}>Done</button>
         </DropdownMenu>   
         </UncontrolledButtonDropdown>
        )
    }
}

export default ColorPicker;