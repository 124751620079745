import React from 'react'
import PropTypes from 'prop-types'
import { isEmptyObject } from './actions/actionMethods'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { ExportButtonIcon, CloseIcon, cancelIconSettings } from '../common/imageList'
import IconButton from '@material-ui/core/IconButton'
import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
var isEmpty = require('lodash.isempty')

const FILTERED = 0, SELECTED = 1, ALL = 2;

const options = [
    { title: 'Filtered Shipments', isEnabled: false, type: FILTERED },
    { title: 'Selected Shipments', isEnabled: false, type: SELECTED },
    { title: 'All Shipments', isEnabled: true, type: ALL }
];

class ExportTracking extends React.Component {
    static propTypes = {
        hasShipments: PropTypes.bool,
        onDismiss: PropTypes.func,
        onExport: PropTypes.func,
        selectedIDs: PropTypes.array,
        filters: PropTypes.object
    }

    state = {
        checkedOption: null,
        requestData: {},
        showLoader: false
    }

    componentDidMount() {
        this.renderDefaultState(this.props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.renderDefaultState(props);
    }

    onOptionChangeHandler = (option) => {
        const checkedOption = this.state.checkedOption;

        const title = option.title;
        const newOption = options.find(option => option.title === title);

        if (newOption.isEnabled && newOption !== checkedOption) {
            this.setState({ checkedOption: newOption })
        } else {
            this.setState({ checkedOption: checkedOption })
        }
    }

    renderDefaultState(props) {
        const { filters, selectedIDs, isSelected } = props;
        // const hasSelection = selectedIDs && selectedIDs.length > 0 ? selectedIDs : [];
        let filterData = { ...filters }
        var hasFilters = false
        let trackingStatusArrVal = []
        let couriersListArrVal = []
        let originListArrVal = []
        let destinationListArrVal = []
        let requestParams = {}
        if (isSelected != true) {
            if (filterData.trackingStatusArr && filterData.trackingStatusArr.length > 0) {
                for (let i = 0; i < filterData.trackingStatusArr.length; i++) {
                    trackingStatusArrVal.push(filterData.trackingStatusArr[i].value)
                }

                if (trackingStatusArrVal[0] !== 'All') {
                    requestParams.status = trackingStatusArrVal
                    hasFilters = true
                }
            }
            if (filterData.courierListArr && filterData.courierListArr.length > 0) {
                for (let i = 0; i < filterData.courierListArr.length; i++) {
                    couriersListArrVal.push(filterData.courierListArr[i].value)
                }
                hasFilters = true
                requestParams.courier = couriersListArrVal
            }
            if (filterData.originListArr && filterData.originListArr.length > 0) {
                for (let i = 0; i < filterData.originListArr.length; i++) {
                    originListArrVal.push({
                        label: filterData.originListArr[i].label,
                        value: filterData.originListArr[i].value
                    })
                }
                hasFilters = true
                requestParams.origin = originListArrVal
            }
            if (filterData.destinationListArr && filterData.destinationListArr.length > 0) {
                for (let i = 0; i < filterData.destinationListArr.length; i++) {
                    destinationListArrVal.push({
                        label: filterData.destinationListArr[i].label,
                        value: filterData.destinationListArr[i].value
                    })
                }
                hasFilters = true
                requestParams.destination = destinationListArrVal
            }
            if (filterData.transitDays != '' && filterData.transitDays != null && filterData.transitDays != undefined) {
                hasFilters = true
                if (filterData.transitDays == '1 Day') {
                    filterData.transitDays = 1
                } else if (filterData.transitDays == '2 Days') {
                    filterData.transitDays = 2
                } else if (filterData.transitDays == '3 Days') {
                    filterData.transitDays = 3
                } else if (filterData.transitDays == '4 Days') {
                    filterData.transitDays = 4
                } else if (filterData.transitDays == '5 Days') {
                    filterData.transitDays = 5
                } else if (filterData.transitDays == 'More than 5 Days') {
                    filterData.transitDays = 6
                }
                requestParams.transitdays = filterData.transitDays
            }
            if (filterData.query != '' && filterData.query != null && filterData.query != undefined) {
                hasFilters = true
                requestParams.query = filterData.query
            }
            if (filterData.fromDate != '' && filterData.fromDate != null && filterData.fromDate != undefined) {
                hasFilters = true
                requestParams.from_date = filterData.fromDate
            }
            if (filterData.toDate != '' && filterData.toDate != null && filterData.toDate != undefined) {
                hasFilters = true
                requestParams.to_date = filterData.toDate
            }
            if (filterData.subUserListArr && filterData.subUserListArr !== undefined && filterData.subUserListArr !== '' && filterData.subUserListArr.length > 0) {
                let assignIdArr = []
                let subUserIds = filterData.subUserListArr
                if (subUserIds[0].value == "false" || subUserIds[0].value == false) {
                    requestParams.is_shipment_assigned = false
                } else {
                    subUserIds.forEach(id => {
                        assignIdArr.push(id.value)
                    });
                    requestParams.assigned_id = assignIdArr
                    requestParams.is_shipment_assigned = true
                }
                hasFilters = true
            }
            if (filterData.tagListArr && filterData.tagListArr !== undefined && filterData.tagListArr.length > 0) {
                let tagNameArr = []
                let tags = filterData.tagListArr
                tags.forEach(tag => {
                    tagNameArr.push(tag.value)
                })
                requestParams.tagsIds = tagNameArr
                hasFilters = true
            }
        } else {
            requestParams.ids = selectedIDs
        }
        let priorityIndex = 2
        if (hasFilters) {
            priorityIndex = 0
        } else if (isSelected) {
            priorityIndex = 1
        }
        options[0].isEnabled = hasFilters
        options[1].isEnabled = isSelected
        this.setState({ checkedOption: options[priorityIndex], requestData: requestParams })

    }

    exportShipments = () => {
        const checkedOption = this.state.checkedOption;

        if (checkedOption) {
            const { folderVisibilityStatus, currentFolder } = this.props;
            // const selectedShipmentIds = selectedIDs ? selectedIDs : [];
            let filterData = this.state.requestData;
            let request = {}
            request['time_zone'] = localStorage.getItem('TIMEZONE')

            if (checkedOption.type !== 0 && folderVisibilityStatus && currentFolder && currentFolder.id) {
                filterData.folderId = currentFolder.id
            }

            switch (checkedOption.type) {
                case FILTERED:
                    if (filterData.query) {
                        request['query'] = filterData.query
                        delete filterData.query
                    }

                    if (!isEmptyObject(filterData)) {
                        request['filters'] = filterData
                    }
                    break;
                case SELECTED:
                    // if (filterData.query) {
                    //     request['query'] = filterData.query
                    //     delete filterData.query
                    // }

                    if (!isEmptyObject(filterData)) {
                        request['filters'] = filterData
                    }
                    break;
                case ALL:
                    if (folderVisibilityStatus && currentFolder && currentFolder.id) {

                        request.filters = {
                            folderId: currentFolder.id
                        }
                    }
                    break;
                default:
                    break;
            }
            this.props.onExport(request);
            this.props.closeDrawer();
        }
    };
    toggleDrawer = (open) => (event) => {
        this.props.closeDrawer()
    };

    render() {
        const { classes } = this.props
        const hasShipments = this.props.hasShipments;
        const { checkedOption } = this.state;
        return (
            <React.Fragment>
                <Drawer
                    classes={{
                        paper: classes.detailTrackingDrawerPaper,
                    }}
                    variant="temporary"
                    anchor="right"
                    open={this.props.openDrawer}
                    onClose={this.toggleDrawer()}
                    BackdropProps={{ invisible: true }}
                    transitionDuration={500}
                >
                    <Grid container className={classes.drawerHeader}>
                        <Typography className={classes.drawerTitle}>Export Shipment</Typography>
                        <IconButton
                            color="inherit"
                            onClick={this.toggleDrawer(false)}
                            className={classes.draweCancelButton}
                            edge="start"
                        >
                            <img alt="" src={cancelIconSettings} />
                        </IconButton>
                    </Grid>
                    <Grid container className={classes.DetaildrawerTrackingTextFieldContainer}>
                        <div className="exprtWrapper">
                            <div className="ModalText">
                                <p>
                                    {hasShipments
                                        ? 'Select an option below to download as an excel spreadsheet (.xls)'
                                        : 'No Shipments found to export.'}
                                </p>
                            </div>
                            {hasShipments &&
                                <div className="ModalList">
                                    <List>
                                        {options.map((option) => {
                                            const labelId = `checkbox-list-label-${option.title}`;

                                            return (
                                                <ListItem  disableGutters={true} disabled={option.isEnabled ? false : true} key={option.title} role={undefined} dense button>
                                                    <ListItemIcon className={classes.exportListIcon}>
                                                        <Radio
                                                            checked={option === checkedOption}
                                                            onChange={(event) => this.onOptionChangeHandler(option)}
                                                            name="radio-button-demo"
                                                            color='primary'
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText className={classes.exportDrawerRadioLable} disableTypography={true} id={labelId} primary={option.title} />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </div>
                            }
                            <div>
                                <p className="smallText">
                                    {hasShipments
                                        ? 'You can export shipmnet upto last 90 days.'
                                        : ''}
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <footer className={classes.trackingDrawerFooter} container="true">
                        <Button
                            onClick={this.exportShipments}
                            startIcon={<ExportButtonIcon />}
                            // disabled={}
                            className={classes.saveBtn}>
                            Export
                        </Button>
                        {/* {(this.state.showLoader) && <CircularProgress size={22} className={classes.buttonProgress} />} */}
                        <Button startIcon={<CloseIcon />} onClick={this.toggleDrawer(false)} className={classes.deleteButtonAction}>
                            CANCEL
                              </Button>
                    </footer>
                </Drawer>
            </React.Fragment>
        )
    }
}

export default ExportTracking;