import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars'
import moment from 'moment-timezone'
import TrackingCheckpoint from './trackingCheckpoints'
import FirstTrackingCheckpoint from './firstTrackingCheckpoints'
import { getTrackingDetails, clearTrackingDetails, editTracking, toggleFlashMessage, setTrackingFlashMessage, setUpdatedTrackingList, setTrackingDetails, setTagList, setFilterTagList, deleteTracking, setDataForTrackingDetail } from '../actions/actionMethods'
import { getCourierLabel } from '../../common/commonFunction'
import { emailErrorMessage, URLErrorMessage } from '../common/errorMessage'
import { getUserDetails } from '../../Setup/actions/actionMethods'
import AddTags from '../common/AddTags'
import { addShipmentsTag } from '../../Setup/actions/actionMethods'
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SaveIcon, RemoveIcon, cancelIconSettings, trackIcon, trackingArrow } from '../../common/imageList'
import FlashMessage from '../common/flashMessage'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {emailRegex,urlRegEx,nameRegEx} from '../../static/constants'
import {DEFAULT_TRACKING_LIMIT} from '../common/constants'
const initValues = {
    footerMessage: { type: 'NOTICE', message: '', action: '' }
}
class TrackingDetail extends React.Component {
    dashValue = ' — '
    timeZone = (localStorage.getItem("TIMEZONE")) ? localStorage.getItem("TIMEZONE") : null
    dateFormat = (localStorage.getItem("date_format")) ? localStorage.getItem("date_format") : null
    customerNameText = 'customerName'
    customerEmailText = 'customerEmail'
    otherEmailText = 'otherEmail'
    customerPhoneNoText = 'customerPhoneNo'
    orderIDText = 'orderID'
    orderURLText = 'orderURL'
    undefinedText = undefined
    nullValue = null
    options = []
    constructor(props) {
        super(props)
        this.state = {
            orderIDValue: '',
            orderURLValue: '',
            customerNameValue: '',
            customerEmailIDValue: '',
            otherEmailIDValue: '',
            customerPhoneNoValue: '',
            showFlag: true,
            trackingSourceValue: '',
            isValidEmail: true,
            isValidOtherEmail: true,
            isValidURL: true,
            showLoader: false,
            showSuccessMessageFlag: false,
            customDomainURL: '',
            subDomainName: '',
            isCopied: false,
            assignedvalue: 'Select',
            tags: [],
            expanded: false,
            openSnackBar: false,
            openDialog: false,
            shoeDeleteLoader : false,
            circularLoader : true,
        }
    }
    componentWillMount() {
    }

    prepareTimeLineData = (trackingCheckpoints) => {
        let preparedArray = []
        if (trackingCheckpoints && trackingCheckpoints.length > 0) {
            for (let checkpointIndex = 0; checkpointIndex < trackingCheckpoints.length; checkpointIndex++) {
                let accessEachTrackingCheckpoint = trackingCheckpoints[checkpointIndex]
                let eachCheckpointObject = {}
                let dateTime = accessEachTrackingCheckpoint.checkpoint_time === null ? ['', ''] : moment(accessEachTrackingCheckpoint.checkpoint_time).format(this.dateFormat + ' HH:mm:ss').split(' ')
                let dataToDisplay = accessEachTrackingCheckpoint.message
                eachCheckpointObject.tag = accessEachTrackingCheckpoint.tag
                eachCheckpointObject.location = accessEachTrackingCheckpoint.location
                eachCheckpointObject.date = dateTime
                eachCheckpointObject.data = dataToDisplay
                preparedArray.push(eachCheckpointObject)
            }
            return preparedArray
        }
    }

    setStateValues = (trackingDetails) => {
        let assignedUser = {}
        let trackingTagList = [...this.props.tagList]
        let assignedTags = []
        if (typeof trackingDetails === "object" && Object.keys(trackingDetails).length > 0) {
            if (trackingDetails.assigned_id && trackingDetails.assigned_id !== null) {
                if (this.props.subUserList.length > 0) {
                    this.props.subUserList.forEach(user => {
                        if (user.value == trackingDetails.assigned_id) {
                            assignedUser = { value: user.value, label: user.label }
                        }
                    })
                }
            }

            if (trackingDetails.tags && trackingDetails.tags.length > 0) {
                let preparedTagsList = trackingTagList.filter(propsTag => trackingDetails.tags.some(resTag => propsTag._id === resTag.tag_id));
                if (preparedTagsList.length > 0) {
                    assignedTags = preparedTagsList
                }
            }

            this.setState({
                trackingSourceValue: trackingDetails.source,
                orderIDValue: trackingDetails.order_id !== this.undefinedText && trackingDetails.order_id !== this.nullValue ? trackingDetails.order_id : '',
                orderURLValue: trackingDetails.order_url !== this.undefinedText && trackingDetails.order_url !== this.nullValue ? trackingDetails.order_url : '',
                customerNameValue: trackingDetails.customer_name !== this.undefinedText && trackingDetails.customer_name !== this.nullValue ? trackingDetails.customer_name : '',
                customerEmailIDValue: trackingDetails.customer_emails !== this.undefinedText && trackingDetails.customer_emails !== this.nullValue ? trackingDetails.customer_emails.join() : '',
                otherEmailIDValue: trackingDetails.other_emails !== this.undefinedText && trackingDetails.other_emails !== this.nullValue ? trackingDetails.other_emails.join() : '',
                customerPhoneNoValue: trackingDetails.customer_phone_numbers !== this.undefinedText && trackingDetails.customer_phone_numbers !== this.nullValue ? trackingDetails.customer_phone_numbers.join() : '',
                assignedvalue: Object.keys(assignedUser).length > 0 ? assignedUser : 'Select',
                showFlag: false,
                isValidURL: true,
                isValidEmail: true,
                isValidOtherEmail: true,
                showLoader: false,
                tags: assignedTags,
                shoeDeleteLoader : false
            })
        }
    }
    handleOnChange = (key, value) => {
        if (key === this.customerNameText) {
            if (!nameRegEx.test(value)) {
                this.setState({
                    customerNameValue: value,
                })
            }
        }
        else if (key === this.customerEmailText) {
            if (emailRegex.test(value) === true) {
                this.setState({
                    isValidEmail: true
                })
            }
            this.setState({
                customerEmailIDValue: String(value).toLowerCase()
            })
        }
        /** 
         * javascript comment 
         * @Author: Akshay Tukadiya
         * @Date: 2020-05-02 16:13:00 
         * @Desc: For other email
        */
        else if (key === this.otherEmailText) {
            if (emailRegex.test(value) === true) {
                this.setState({
                    isValidOtherEmail: true
                })
            }
            this.setState({
                otherEmailIDValue: String(value).toLowerCase()
            })
        }
        /*END*/
        else if (key === this.customerPhoneNoText) {
            this.setState({
                customerPhoneNoValue: value
            })
        }
        else if (key === this.orderIDText) {
            this.setState({
                orderIDValue: value
            })
        }
        else if (key === this.orderURLText) {
            this.setState({
                orderURLValue: value
            })
        }
    }
    editTrackingClick = () => {
        let isValidEmailFlag = true
        let isValidOtherEmailFlag = true
        let isValidURLFlag = true
        let emailIdArrValue = ''
        let otherEmailIdArrValue = ''
        let phoneNoArrValue = ''
        if (this.state.customerEmailIDValue !== '') {
            var emailArr = this.state.customerEmailIDValue.replace(/\s/g, "").split(/,|;/);
            let emailIdValue = ''
            for (let i = 0; i < emailArr.length; i++) {
                if (emailRegex.test(emailArr[i])) {
                    isValidEmailFlag = true
                    this.setState({
                        isValidEmail: true,
                    });
                    emailIdValue = this.state.customerEmailIDValue
                } else {
                    isValidEmailFlag = false
                    this.setState({
                        isValidEmail: false
                    });
                }
            }
            emailIdValue = emailIdValue.replace(/\s/g, '')
            emailIdArrValue = emailIdValue.split(',')
        }
        else {
            this.setState({
                isValidEmail: true
            })
        }
        if (this.state.otherEmailIDValue !== '') {
            var otherEmailArr = this.state.otherEmailIDValue.replace(/\s/g, "").split(/,|;/);
            let emailIdValue = ''
            for (let i = 0; i < otherEmailArr.length; i++) {
                if (emailRegex.test(otherEmailArr[i])) {
                    isValidOtherEmailFlag = true
                    this.setState({
                        isValidOtherEmail: true,
                    });
                    emailIdValue = this.state.otherEmailIDValue
                } else {
                    isValidOtherEmailFlag = false
                    this.setState({
                        isValidOtherEmail: false
                    });
                }
            }
            emailIdValue = emailIdValue.replace(/\s/g, '')
            otherEmailIdArrValue = emailIdValue.split(',')
        }
        else {
            this.setState({
                isValidOtherEmail: true
            })
        }
        if (this.state.orderURLValue !== '') {
            if (urlRegEx.test(this.state.orderURLValue) === false) {
                isValidURLFlag = false
                this.setState({
                    isValidURL: false
                })
            }
            else {
                this.setState({
                    isValidURL: true
                })
            }
        }
        else {
            this.setState({
                isValidURL: true
            })
        }
        if (this.state.customerPhoneNoValue !== '') {
            var phoneNo = this.state.customerPhoneNoValue.replace(/\s/g, "").split(/,|;/);
            let phoneNoValue = ''
            for (var j = 0; j < phoneNo.length; j++) {
                phoneNoValue = this.state.customerPhoneNoValue
            }
            phoneNoValue = phoneNoValue.replace(/\s/g, '')
            phoneNoArrValue = phoneNoValue.split(',')
        }

        if (isValidEmailFlag && isValidURLFlag && isValidOtherEmailFlag) {
            this.setState({
                showLoader: true
            })
            let requestParams = {}
            let trackingID = this.props.match.params.id
            requestParams.customer_name = this.state.customerNameValue
            requestParams.customer_emails = emailIdArrValue && emailIdArrValue.length > 0 ? emailIdArrValue : []
            requestParams.other_emails = otherEmailIdArrValue && otherEmailIdArrValue.length > 0 ? otherEmailIdArrValue : []
            requestParams.customer_phone_numbers = phoneNoArrValue && phoneNoArrValue.length > 0 ? phoneNoArrValue : []
            requestParams.order_id = this.state.orderIDValue
            requestParams.order_url = this.state.orderURLValue
            if (this.state.assignedvalue !== 'Select' && this.state.assignedvalue.value !== 'Select') {
                requestParams.assigned_id = this.state.assignedvalue.value
            }
            if (this.state.tags && this.state.tags.length > 0) {
                let updatedTags = [...this.state.tags]
                let prepareTag = []
                updatedTags.forEach(e => {
                    let data = {
                        "tag_id": e._id,
                        "tag_name": e.tag_name,
                        "tag_color": e.tag_color
                    }
                    prepareTag.push(data)
                })
                requestParams.tags = prepareTag
            } else {
                requestParams.tags = []
            }

            editTracking(requestParams, trackingID).then(res => {
                this.setState({
                    showLoader: false
                })
                if (res && res.status >= 200 && res.status <= 299) {
                    let shipmentData = this.props.trackingList
                    if (shipmentData && shipmentData.length > 0) {
                        let index = shipmentData.findIndex(tracking => tracking._id === res.data._id)
                        if (index > -1) {
                            shipmentData.splice(index, 1, res.data)
                        }
                        this.props.setUpdatedTrackingList(shipmentData, shipmentData.length)
                    }

                    if (this.props.trackingList) {
                        initValues.footerMessage = { type: 'SUCCESS', message: 'Tracking Updated Successfully!', action : 'shipment'}
                        this.setState({
                            showSuccessMessageFlag: true,
                            openSnackBar: true,
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    showSuccessMessageFlag: false
                                })
                            }, 3000);
                        })
                        this.props.getTrackingDetails(this.props.match.params.id)
                        let detail = this.props.shipmentTrackingDetail
                        detail.customer_name = requestParams.customer_name
                        detail.customer_emails = requestParams.customer_emails
                        detail.other_emails = requestParams.other_emails
                        detail.customer_phone_numbers = requestParams.customer_phone_numbers
                        detail.order_id = requestParams.order_id
                        detail.order_url = requestParams.order_url
                        detail.tags = requestParams.tags
                        this.props.setDataForTrackingDetail(detail)
                    }
                }
                else {
                    initValues.footerMessage = { type: 'ERROR', message: res.data.meta.message[0], action : 'shipment'}
                    this.setState({
                        showSuccessMessageFlag: true,
                        openSnackBar: true
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                showSuccessMessageFlag: false
                            })
                        }, 3000);
                    })
                }
            })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({circularLoader : true})
        setTimeout(() => {
            this.setState({
                circularLoader: false
            })
        }, 500);
        if (nextProps.shipmentTrackingDetail !== undefined && nextProps.shipmentTrackingDetail !== null) {
            this.setState({
                customDomainURL: localStorage.getItem('custom_domain_url') && localStorage.getItem('custom_domain_url') !== '' ? localStorage.getItem('custom_domain_url') : '',
                subDomainName: localStorage.getItem('sub_domain') && localStorage.getItem('sub_domain') !== '' ? localStorage.getItem('sub_domain') : ''
            }, () => {
                if (this.state.subDomainName == '') {
                    getUserDetails().then(userDetailsResponse => {
                        this.setState({
                            customDomainURL: userDetailsResponse.data.custom_domain_url,
                            subDomainName: userDetailsResponse.data.sub_domain
                        })
                    })
                }
            })
            this.setStateValues(nextProps.shipmentTrackingDetail)
            this.assignSubUserOptions()
        }
    }
    copyURL = (id, subDomainName) => {
        var copyText = "https://" + subDomainName + ".trackhive.co/trackingPage/"+id
        var input = document.createElement("input");
        input.value = copyText;
        document.body.appendChild(input);
        input.select();
        document.execCommand("Copy");
        input.remove();

        setTimeout(() => {
            this.setState({
                isCopied: true
            })
        }, 500);

        setTimeout(() => {
            this.setState({
                isCopied: false
            })
        }, 2500);
    }

    onSubUserChange = (selectedOption) => {
        if (selectedOption) {
            this.setState({ assignedvalue: selectedOption })
        }
    }

    assignSubUserOptions = () => {
        this.options = [
            { value: 'Select', label: 'unassign user ' },
        ];

        let subUserListArr = this.props.subUserList
        for (let i = 0; i < subUserListArr.length; i++) {
            let eachUserData = subUserListArr[i]
            this.options.push({
                value: eachUserData.value,
                label: eachUserData.label,
            })
        }
    }

    addTag = (data) => {
        let tags = [...this.state.tags]
        let tag = []
        if (data) {
            addShipmentsTag(data).then(response => {
                if (response && response.data && response.data.meta && response.data.meta.code >= 200 && response.data.meta.code <= 299) {
                    tags.push({
                        "_id": response.data.data._id,
                        "tag_name": response.data.data.tag_name,
                        "tag_color": response.data.data.tag_color
                    })
                    tag.push({
                        "_id": response.data.data._id,
                        "tag_name": response.data.data.tag_name,
                        "tag_color": response.data.data.tag_color
                    })
                    this.props.setTagList(tag)
                    let tagData = [...this.props.filterTagsList]
                    if (tagData.length > 0) {
                        let newTag = {
                            "value": response.data.data._id,
                            "label": response.data.data.tag_name,
                        }
                        tagData.push(newTag)
                        this.props.setFilterTagList(tagData)
                    }
                    initValues.footerMessage = { type: 'SUCCESS', message: 'Tag Added Successfully!', action : 'tag' }
                    this.setState({
                        tags: tags,
                        showSuccessMessageFlag: true
                    })

                } else {
                    initValues.footerMessage = { type: 'ERROR', message: response.data.meta.message[0], action : 'tag' }
                    this.setState({
                        showSuccessMessageFlag: true
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                showSuccessMessageFlag: false
                            })
                        }, 3000);
                    })
                }
            }).catch(error => { })
        }
    }

    linkTagSingle = (data) => {
        let tags = [...this.state.tags]
        if (tags.length > 0) {
            let found = tags.some(tag => tag._id === data._id);
            if (!found) {
                tags.push(data)
            }
        } else if (tags.length === 0) {
            tags.push(data)
        }
        this.setState({
            tags: tags
        })
    }

    deleteTag = (id) => {
        let tags = [...this.state.tags]
        let objIndex = tags.findIndex((obj => obj._id === id))
        if (objIndex !== -1) {
            tags.splice(objIndex, 1)
        }
        this.setState({
            tags: tags
        })
    }
    toggleDrawer = (open) => (event) => {

        this.props.closeDrawer()
        // this.props.history.push(`/tracking`)

    };
    handleChangeExpantation = (panel) => {
        this.setState({ expanded: panel })
    }
    closhFlashMessage = () => {
        this.setState({ openSnackBar: false })
    }
    handleClickOpen = () => {
        this.setState({ openDialog: true})
    }
    handleCloseDialog = () => {
        this.setState({ openDialog: false})
    }
    deleteTracking = (id) => {
        this.setState({shoeDeleteLoader : true})
        let trackingId = id
        this.props.deleteTracking(trackingId).then(res => {
            if (res) {
                if (res.data && res.data.meta && (res.data.meta.code >= 200 && res.data.meta.code <= 399)) {
                    this.setState({
                        isTrackingDelete: false,
                        shoeDeleteLoader: false,
                        openDialog : false,
                    })
                    this.props.history.push(`/tracking`)
                    this.props.closeDrawer()
                    let limit = localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : DEFAULT_TRACKING_LIMIT
                    this.props.getListAfterDelete(1, limit)
                }
            }
        })
    }
    render() {
        let customDomainURL= this.props.customDomainURL ? this.props.customDomainURL : this.state.customDomainURL
        let subDomainName= this.props.subDomainName ? this.props.subDomainName : this.state.subDomainName
        const { classes } = this.props
        let trackingDetails = this.props.shipmentTrackingDetail
        let isTrackingDetail = ((trackingDetails !== undefined && trackingDetails !== null) && (customDomainURL !== '' || subDomainName !== ''))
        let footerMessage = initValues.footerMessage
        let trackingCheckpointsTimeLineData = []
        let firstTrackingCheckpointsTimeLineData = []
        if (isTrackingDetail && trackingDetails.trackings && trackingDetails.trackings.checkpoints && trackingDetails.trackings.checkpoints.length > 0) {
            trackingCheckpointsTimeLineData = this.prepareTimeLineData(trackingDetails.trackings.checkpoints)
            firstTrackingCheckpointsTimeLineData = trackingCheckpointsTimeLineData.length > 0 ? trackingCheckpointsTimeLineData.slice(-1)[0] : ''

        }

        if (isTrackingDetail) {
            return (

                <React.Fragment>
                    <Drawer
                        classes={{
                            paper: classes.detailTrackingDrawerPaper,
                        }}
                        variant="persistent"
                        anchor="right"
                        open={this.props.openDrawer}
                        onClose={this.toggleDrawer()}
                        BackdropProps={{ invisible: true }}
                        transitionDuration={500}
                    >
                        <Grid container className={classes.detailDrawerHeader}>
                            <IconButton
                                className={classes.detailDrawerTitleIcon}
                                color="inherit"
                                edge="start"
                            >
                                <img alt="" src={trackIcon} />
                            </IconButton>
                            <Typography className={classes.detailDrawerTitleTrackingNumber}>{trackingDetails.tracking_number}</Typography>
                            <Typography className={classes.detailDrawerTitleCourierName}>{" (" + getCourierLabel(trackingDetails.slug, this.props.addedCourierList) + ")"}</Typography>
                            <IconButton
                                color="inherit"
                                onClick={this.toggleDrawer()}
                                className={classes.draweCancelButton}
                                edge="start"
                            >
                                <img alt="" src={cancelIconSettings} />
                            </IconButton>
                        </Grid>
                        <Scrollbars style={{ height:'100%', clear: 'both', overflowY : 'hidden' }} className="detailScrollbarHeight" autoHide={true}>
                            <Grid container className={classes.DetaildrawerTrackingTextFieldContainer}>
                                <div className="detailDrawerContent">
                                    <Typography className={classes.drawerContentText}>Tracking Status</Typography>
                                </div>
                                <ExpansionPanel
                                    classes={{
                                        root: classes.expandPanelRoot
                                    }}
                                    expanded={this.state.expanded} >
                                    <ExpansionPanelSummary className={classes.expandPanelSummery}>
                                        <div className="expandCheckpoint">
                                            <div className="trackingHistoryTimelineMainDiv">
                                                <FirstTrackingCheckpoint firstTrackingCheckpoints={firstTrackingCheckpointsTimeLineData} />
                                            </div>

                                        </div>
                                        {!this.state.expanded || (trackingCheckpointsTimeLineData.length <= 1 || !trackingCheckpointsTimeLineData.length > 1) ?
                                            <div className="expandPanelButton">
                                                <Typography className={classes.expandPanelLessButton} onClick={(event) => this.handleChangeExpantation(!this.state.expanded)} >{!this.state.expanded ? 'MORE DETAILS' : 'LESS DETAILS'}</Typography>
                                                <Typography className={classes.expandPanelCopyButton} onClick={() => this.copyURL(trackingDetails.tracking_number, subDomainName)}>COPY PUBLIC URL</Typography>
                                                {
                                                    this.state.isCopied ?
                                                        <label className="success-fonts">Tracking URL Copied!</label> : ''

                                                }
                                                <a className="trackingArrow" target="_blank" href={"https://" + subDomainName + ".trackhive.co/trackingPage/"+trackingDetails.tracking_number}>
                                            <img  alt="" src={trackingArrow} />
                                        </a>
                                            </div>
                                            : ""}
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.expandPanelDetail}>
                                        <div>
                                            {/* {this.state.expanded ? */}
                                            <div className="expandCheckpoint">
                                                {trackingCheckpointsTimeLineData.length > 1 ?
                                                    <div className="trackingHistoryTimelineMainDiv">
                                                        <TrackingCheckpoint trackingCheckpoints={trackingCheckpointsTimeLineData.reverse()} />
                                                    </div>
                                                    : ""}
                                            </div>
                                            {/* : ""} */}

                                        </div>
                                        {this.state.expanded && trackingCheckpointsTimeLineData.length > 1 ?
                                            <div className="expandPanelButton">
                                                <Typography className={classes.expandPanelLessButton} onClick={(event) => this.handleChangeExpantation(!this.state.expanded)} >{!this.state.expanded ? 'MORE DETAILS' : 'LESS DETAILS'}</Typography>
                                                <Typography className={classes.expandPanelCopyButton} onClick={() => this.copyURL(trackingDetails.tracking_number, subDomainName)}>COPY PUBLIC URL</Typography>
                                                {
                                                    this.state.isCopied ?
                                                        <label className="success-fonts">Tracking URL Copied!</label> : ''

                                                }
                                                <a className="trackingArrow" target="_blank" href={"https://" + subDomainName + ".trackhive.co/trackingPage/"+trackingDetails.tracking_number}>
                                            <img  alt="" src={trackingArrow} />
                                        </a>
                                            </div> : ""}
                                    </ExpansionPanelDetails>

                                </ExpansionPanel>
                            </Grid>
                            <Grid container className={classes.detailDrawerContent}>
                                <Typography className={classes.drawerContentText}>Other Details</Typography>
                            </Grid>
                            <Grid container className={classes.drawerTextFieldContainer}>
                                <div >
                                    <div className="editformAttributeTop">
                                        <TextField
                                            InputLabelProps={{
                                                className: classes.addTrackingTextField
                                            }}
                                            disabled={true}
                                            id="source"
                                            // helperText={"Tracking Number Required"}
                                            label="Source"
                                            variant="outlined"
                                            className={classes.drawerTrackingField}
                                            value={this.state.trackingSourceValue}
                                        ></TextField>
                                    </div>
                                    <div className="editformAttributeTop">
                                        <TextField
                                            InputLabelProps={{
                                                className: classes.addTrackingTextField
                                            }}
                                            id="order_id"
                                            // helperText={"Tracking Number Required"}
                                            label="Order ID"
                                            variant="outlined"
                                            className={classes.drawerTrackingField}
                                            value={this.state.orderIDValue}
                                            onChange={(e) => this.handleOnChange(this.orderIDText, e.target.value)}
                                        ></TextField>
                                    </div>
                                </div>
                                <div>
                                    <div className="editformAttribute">
                                        <TextField
                                            InputLabelProps={{
                                                className: classes.addTrackingTextField
                                            }}
                                            error={this.state.isValidURL ? false : true}
                                            id="order_url"
                                            // helperText={"Tracking Number Required"}
                                            label="Order URL"
                                            variant="outlined"
                                            // FormHelperTextProps={this.state.postalCodeRequired}
                                            className={classes.drawerTrackingField}
                                            value={this.state.orderURLValue}
                                            onChange={(e) => this.handleOnChange(this.orderURLText, e.target.value)}
                                        ></TextField>
                                        {!this.state.isValidURL ? <label className="error-fonts">{URLErrorMessage}</label> : ''}
                                    </div>
                                    <div className="editformAttribute">
                                        <TextField
                                            InputLabelProps={{
                                                className: classes.addTrackingTextField
                                            }}
                                            error={false}
                                            id="customer_name"
                                            // helperText={"Tracking Number Required"}
                                            label="Customer Name"
                                            variant="outlined"
                                            // FormHelperTextProps={this.state.postalCodeRequired}
                                            className={classes.drawerTrackingField}
                                            value={this.state.customerNameValue}
                                            onChange={(e) => this.handleOnChange(this.customerNameText, e.target.value)}
                                        ></TextField>
                                    </div>
                                </div>
                                <div>
                                    <div className="editformAttribute">
                                        <TextField
                                            InputLabelProps={{
                                                className: classes.addTrackingTextField
                                            }}
                                            error={this.state.isValidEmail ? false : true}
                                            id="customer_email"
                                            // helperText={"Tracking Number Required"}
                                            label="Customer Email"
                                            variant="outlined"
                                            // FormHelperTextProps={this.state.postalCodeRequired}
                                            className={classes.drawerTrackingField}
                                            value={this.state.customerEmailIDValue}
                                            onChange={(e) => this.handleOnChange(this.customerEmailText, e.target.value)}
                                        ></TextField>
                                        {!this.state.isValidEmail ? <label className="error-fonts">{emailErrorMessage}</label> : ''}
                                    </div>
                                    <div className="editformAttribute">
                                        <TextField
                                            InputLabelProps={{
                                                className: classes.addTrackingTextField
                                            }}
                                            error={this.state.isValidOtherEmail ? false : true}
                                            id="other_email"
                                            // helperText={"Tracking Number Required"}
                                            label="Other Email"
                                            variant="outlined"
                                            // FormHelperTextProps={this.state.postalCodeRequired}
                                            className={classes.drawerTrackingField}
                                            value={this.state.otherEmailIDValue}
                                            onChange={(e) => this.handleOnChange(this.otherEmailText, e.target.value)}
                                        ></TextField>
                                        {!this.state.isValidOtherEmail ? <label className="error-fonts">{emailErrorMessage}</label> : ''}
                                    </div>
                                </div>
                                <div className="trackingtagDive">
                                    <div className="editformAttributeBottom">
                                        <AddTags
                                            addTag = {this.addTag}
                                            tags = {this.state.tags}
                                            linkTagSingle={this.linkTagSingle}
                                            deleteTag={this.deleteTag}
                                            name= "edit"
                                        />
                                    </div>
                                    <div className="editformAttributeBottom">
                                        <TextField
                                            InputLabelProps={{
                                                className: classes.addTrackingTextField
                                            }}
                                            id="phone_no"
                                            // helperText={"Tracking Number Required"}
                                            label="Customer Phone No"
                                            variant="outlined"
                                            // FormHelperTextProps={this.state.postalCodeRequired}
                                            className={classes.drawerTrackingField}
                                            value={this.state.customerPhoneNoValue}
                                            onChange={(e) => this.handleOnChange(this.customerPhoneNoText, e.target.value)}
                                        ></TextField>
                                    </div>

                                </div>
                                <div>
                                    <div className="editformAttributeDate">
                                        <div className="editDateText">
                                            Created At:
                                    </div>
                                        <div className="editDateValue">
                                            {moment(trackingDetails.created).tz(localStorage.getItem('TIMEZONE')).format(this.dateFormat + " hh:mm:ss A")}
                                        </div>
                                    </div>
                                    <div className="editformAttributeDate">
                                        <div className="editDateText">
                                            Modified At:
                                    </div>
                                        <div className="editDateValue">
                                            {moment(trackingDetails.modified).tz(localStorage.getItem('TIMEZONE')).format(this.dateFormat + " hh:mm:ss A")}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Scrollbars>
                        <footer className={classes.trackingDrawerFooter} justify="left">
                            <Button
                                onClick={this.editTrackingClick}
                                startIcon={<SaveIcon />}
                                disabled={this.state.showLoader}
                                className={(this.state.showLoader) ? classes.saveBtnDisable : classes.saveBtn}>
                                SAVE
                                    </Button>
                            {(this.state.showLoader) && <CircularProgress size={22} className={classes.buttonProgress} />}
                            <Button
                                startIcon={<RemoveIcon />}
                                onClick={this.handleClickOpen}
                                disabled={this.state.showLoader}
                                className={(this.state.showLoader) ? classes.deleteButtonDisable : classes.deleteButton}>
                                DELETE
                                  </Button>
                            {
                                this.state.showSuccessMessageFlag ? <FlashMessage open={this.state.openSnackBar} closeFlash={this.closhFlashMessage} classes={classes.drawerSnackbar} open={true} messageDetails={footerMessage} /> : ''
                            }
                        </footer>
                        {
                            this.state.circularLoader ? <CircularProgress size={40} className={classes.circularProgress} /> : ""
                        }


                    </Drawer>

                    <Dialog
                        open={this.state.openDialog}
                        onClose={this.closeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete Tracking?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">Are you sure want to delete
Tracking?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className={classes.dialogCancelBtn} onClick={this.handleCloseDialog} disabled={this.state.shoeDeleteLoader} color="primary">

                                Cancel
            </Button>
                            <Button className={classes.dialogActionBtn}
                                disabled={this.state.shoeDeleteLoader}
                                onClick={(event) => this.deleteTracking(trackingDetails._id)} color="primary" autoFocus>
                                Yes,  Delete
            </Button>
                            {(this.state.shoeDeleteLoader) && <CircularProgress size={22} className={classes.deleteTrackingProgress} />}
                        </DialogActions>
                    </Dialog>
                    {/* </div> */}
                    {
                        this.state.expanded === true ?
                            document.getElementById('firstCheckpoint') !== null && document.getElementById('nthCheckpoint') !== null ?
                                document.getElementById('firstCheckpoint').style.backgroundPosition = '0px 0px' : ""
                            :document.getElementById('firstCheckpoint') !== null ?
                                document.getElementById('firstCheckpoint').style.backgroundPosition = '160px 0px' : ""
                    }
                </React.Fragment>

            )
        } else {
            return (
                <React.Fragment>
                    <Drawer
                        classes={{
                            paper: classes.detailTrackingDrawerPaper,
                        }}
                        variant="persistent"
                        anchor="right"
                        open={this.props.openDrawer}
                        onClose={this.toggleDrawer()}
                        BackdropProps={{ invisible: true }}
                        transitionDuration={500}
                    >
                        <CircularProgress size={22} className={classes.buttonProgress} />
                    </Drawer>
                </React.Fragment>

            )
        }
    }
}
const mapStateToProps = state => ({
    trackingDetails: state.tracking.list.trackingDetails,
    addedCourierList: state.tracking.list.addedTrackingCourierList,
    subUserList: state.tracking.list.addedSubUserList,
    tagList: state.tracking.list.tags,
    trackingList: state.tracking.list.trackingList,
    filterTagsList: state.tracking.list.filterTags,
    shipmentTrackingDetail : state.tracking.list.shipmentTrackingDetail
})
export default withRouter(connect(mapStateToProps, { getTrackingDetails, clearTrackingDetails, toggleFlashMessage, setTrackingFlashMessage, getUserDetails, setUpdatedTrackingList, setTrackingDetails, setTagList, setFilterTagList, deleteTracking, setDataForTrackingDetail })(TrackingDetail));