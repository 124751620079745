export const ShipmentConditions = [
    // { label: 'Select', value: '', component: '', field: '', trigger: ['CREATED', 'UPDATED', 'CUSTOM'] },
    { label: 'Shipment Status', value: 'STATUS', component: 'StatusList', field: 'status_id', trigger: ['CREATED', 'UPDATED', 'CUSTOM'] },
    { label: 'Shipping Courier', value: 'COURIER', component: 'CourierList', field: 'courier_id', trigger: ['CREATED', 'UPDATED', 'CUSTOM'] },
    { label: 'Origin Country', value: 'ORIGIN', component: 'CommaSeperatedList', field: 'origin_country', trigger: ['CREATED', 'UPDATED', 'CUSTOM'] },
    { label: 'Destination Country', value: 'DESTINATION', component: 'CommaSeperatedList', field: 'destination_country', trigger: ['CREATED', 'UPDATED', 'CUSTOM'] },
    { label: 'Origin State', value: 'ORIGIN_STATE', component: 'CommaSeperatedList', field: 'origin_state', trigger: ['CREATED', 'UPDATED', 'CUSTOM'] },
    { label: 'Destination State', value: 'DESTINATION_STATE', component: 'CommaSeperatedList', field: 'destination_state', trigger: ['CREATED', 'UPDATED', 'CUSTOM'] },
    { label: 'Origin City', value: 'ORIGIN_CITY', component: 'CommaSeperatedList', field: 'origin_city', trigger: ['CREATED', 'UPDATED', 'CUSTOM'] },
    { label: 'Destination City', value: 'DESTINATION_CITY', component: 'CommaSeperatedList', field: 'destination_city', trigger: ['CREATED', 'UPDATED', 'CUSTOM'] },
    { label: 'Customer Name', value: 'CUSTOMER_NAME', component: 'CommaSeperatedList', field: 'customer_name', trigger: ['CREATED', 'UPDATED', 'CUSTOM'] },
    { label: 'Customer Email ID', value: 'CUSTOMER_EMAIL_ID', component: 'CommaSeperatedList', field: 'customer_email_id', trigger: ['CREATED', 'UPDATED', 'CUSTOM'] },
    // { label: 'Delivery Time', value: 'DELIVERY_TIME', component: 'DeliveryTimeList', field: 'delivery_time', trigger: ['CREATED', 'CUSTOM', 'UPDATED'] },
    { label: 'Shipping Services', value: 'SHIPPING_SERVICES', component: 'CommaSeperatedList', field: 'shipping_services', trigger: ['CREATED', 'UPDATED', 'CUSTOM'] },
]
