import React from 'react'
import axios from 'axios';
import { connect } from 'react-redux'
import '../assets/css/settings.scss'
import '../assets/css/profileStyle.scss'
import Drawer from './changePasswordDrawer'
import Button from '@material-ui/core/Button'
import ChangePassword from './changePassword'
import { getInstance } from '../../config/token';
import { scrollbar } from '../../static/constants'
import Styles from '../assets/css/Styles'
import {withStyles} from '@material-ui/core/styles'
import { Scrollbars } from 'react-custom-scrollbars';
import { Link, withRouter } from 'react-router-dom';
import FlashMessage from '../common/FlashMessage'
import CssBaseline from '@material-ui/core/CssBaseline';
import {Profile as profileIc} from '../../common/imageList'
import CircularProgress from '@material-ui/core/CircularProgress';
import {DATE_FORMAT_OPTIONS, PROFILE_SUCCESSFULLY_SAVED_MESSAGE, PASSWORD_UPDATE_MESSAGE} from '../common/constants'
import { getTimeZoneList, editProfile, getUserDetails,setSettingsFlashMessage,toggleSettingsFlashMessage } from '../actions/actionMethods'
import {StyledTextField, LinearProgressBar, StyledAutoComplete, StyledCard, StyledTypography, BreadCrumbs, Image} from '../common/materialComponents'
import {passwordRegex} from '../../static/constants'
const prefix = ''

class Profile extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess=true
        this.state = {
            user_details: (JSON.parse(localStorage.getItem('trackhiveToken'))),
            userName: (JSON.parse(localStorage.getItem('trackhiveToken'))).name,
            isNameError: false,
            isEmailError: false,
            isCurrentPasswordError: false,
            isNewPasswordError: false,
            isConfirmPasswordError: false,
            customerCorrespondenceEmail: '',
            emailErrorMessage: '',
            showChangePasswordModal: false,
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            showLoader: false,
            newPasswordLengthError: false,
            confirmPasswordLengthError: false,
            alphaNumericPasswordflag: true,
            passwordError: '',
            timezoneData: '',
            selectedTimeZone: '',
            timeZoneOptions: '',
            selectedDateFormat: {value:'', label:''},
            showSaveLoader: false,
            isActive: true 
        }
    }
        handleSubmit = () => {
        if (this.state.userName === '') {
            return false
        }
        else {
            this.setState({
                showSaveLoader:true,
                isActive: false
            })
            let requestParams = {}
            requestParams.name = this.state.userName
            requestParams.date_format = this.state.selectedDateFormat === '' ? 'MM-DD-YYYY' : this.state.selectedDateFormat.value
            requestParams.time_zone_id = this.state.selectedTimeZone === '' ? 38 : this.state.selectedTimeZone.value
            this.isSuccess = true
            editProfile(requestParams).then(res => {
                
                this.setState({
                    showSaveLoader:false, isActive: true
                })
                if (res && res.data) {
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(PROFILE_SUCCESSFULLY_SAVED_MESSAGE)
                    localStorage.setItem('ProfileName', res.data.name)
                    localStorage.setItem('TIMEZONE', res.data.time_zone_data.timezone)
                    localStorage.setItem('date_format', res.data.date_format)
                    localStorage.setItem('Reply_To', res.data.email)
                }
            })
        }
    }
    toggleFlashMessage = (data) => {
        this.props.toggleSettingsFlashMessage(data)
        this.props.setSettingsFlashMessage('')
    }
    onChangeUserName = (value) => {
        this.setState({
            userName: value,
            isNameError: false
        })
    }
    checkUserName = () => {
        if (this.state.userName === '') {
            this.setState({
                isNameError: true
            })
        }
    }
    checkCurrentPassword = () => {
        if (this.state.currentPassword === '') {
            this.setState({
                isCurrentPasswordError: true
            })
        }
    }
    checkNewPassword = () => {
        if (this.state.newPassword === '') {
            this.setState({
                isNewPasswordError: true
            })
        }
        else {
            if (this.state.newPassword.length < 6) {
                this.setState({
                    newPasswordLengthError: true
                })
            }
            else {
                this.setState({
                    newPasswordLengthError: false
                })
            }
        }
    }
    checkConfirmPassword = () => {
        if (this.state.confirmPassword === '') {
            this.setState({
                isConfirmPasswordError: true
            })
        }
    }
    resetForm = () => {
        this.setState({
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        })
    }
    handlechangePasswordModal = () => {
        this.resetForm()
        this.setState({
            showChangePasswordModal: true,
            isCurrentPasswordError: false,
            isConfirmPasswordError: false,
            isNewPasswordError: false,
            confirmPasswordLengthError: false,
            newPasswordLengthError: false,
            alphaNumericPasswordflag: true,
            passwordError:'',
            passwordNotMatched: false
        })
    }
    cancelChangePassword = () => {
        this.setState({
            showChangePasswordModal: false
        })
    }
    changePassword = () => {
        if (this.state.currentPassword === '') {
            this.setState({
                isCurrentPasswordError: true
            })
        }
        if (this.state.newPassword === '') {
            this.setState({
                isNewPasswordError: true
            })
        }
        if (this.state.confirmPassword === '') {
            this.setState({
                isConfirmPasswordError: true
            })
        }
        if(this.state.newPassword !== this.state.confirmPassword){
            this.setState({passwordNotMatched: true})
        }
        if ( this.state.newPassword !== '' && this.state.confirmPassword !== '' && this.state.newPassword === this.state.confirmPassword) {
            this.setState({
                showLoader: true
            })
            let requestParams = {}
            requestParams.current_password = this.state.currentPassword
            requestParams.new_password = this.state.newPassword
            requestParams.confirm_password = this.state.confirmPassword
            
            let config = getInstance('PUT', prefix + '/user/change_password', requestParams)
            this.isSuccess = true
            axios(config).then(response => {
                this.props.toggleSettingsFlashMessage(1)
                this.props.setSettingsFlashMessage(PASSWORD_UPDATE_MESSAGE)
                this.setState({
                    showLoader: false,
                    showChangePasswordModal: false
                })
            }).catch(error => {
                if (error.response.status === 400) {
                    this.setState({
                        showLoader: false
                    })
                    this.props.toggleSettingsFlashMessage(1)
                    this.isSuccess = false
                    this.props.setSettingsFlashMessage(error.response.data.meta.message[0])
                    this.setState({ passwordError: error.response.data.meta.message[0] })
                }
            })
        }
    }
    handleChangePassword = (value,key) => {
        if (key === 'currentPassword') {
            this.setState({
                currentPassword: value,
                isCurrentPasswordError: false
            })
        }
        else if (key === 'newPassword') {
            if (value.length < 6) {
                this.setState({
                    isNewPasswordError: true,
                    newPassword: value
                })
            }
            else {
                if (passwordRegex.test(value) === false) {
                    this.setState({
                        alphaNumericPasswordflag: false,
                        newPassword: value,
                        isNewPasswordError: false,
                        newPasswordLengthError: false
                    })
                }
                else {
                    this.setState({
                        newPassword: value,
                        alphaNumericPasswordflag: true,
                        isNewPasswordError: false,
                        newPasswordLengthError: false
                    })
                }
            }
        }
        else if (key === 'confirmPassword') {
            this.setState({
                confirmPassword: value,
                isConfirmPasswordError: false
            })
        }
    }
    componentWillMount() {
        getTimeZoneList().then(res => {
            if (res) {
                getUserDetails().then(userDetailsResponse => {
                    let userData = userDetailsResponse.data
                    if(userData.date_format !== undefined){
                        
                        this.setState({
                            selectedDateFormat: {
                                value: userData.date_format,
                                label: userData.date_format
                              }
                            })
                        
                    }
                    else{
                        this.setState({
                            selectedDateFormat: {
                                value: 'DD-MM-YYYY',
                                label: 'DD-MM-YYY'
                              }
                            })
                    }
                    let timeZoneArr = []
                    res.data.forEach(element => {
                        if (element.id === userData.time_zone_data.id) {
                            this.setState({
                                selectedTimeZone: {
                                  value: userData.time_zone_data.id,
                                  label: `${userData.time_zone_data.name}--${userData.time_zone_data.timezone}`
                                }
                              })
                        }
                        timeZoneArr.push({
                            value: element.id,
                            label: `${element.name}--${element.timezone}`
                        })
                        this.setState({
                            timezoneData: res.data,
                            timeZoneOptions: timeZoneArr,
                            userName:userData.name 
                        })
                    })
                });
            }
        })
    }

    handleTimeZone = (event, values) => {
        this.setState({
          selectedTimeZone: values
        });
    }

    handleDateFormat = (event,values) => {    
        this.setState({
            selectedDateFormat: values
        })
    }

    render() {
        const { classes } = this.props
        return (
            <React.Fragment>
            <CssBaseline />
           {
              this.state.timezoneData && this.state.timezoneData.length > 0  ?     
             <div className="settingsModule">
                  <StyledCard>
                        <BreadCrumbs aria-label="breadcrumb">
                            <Link className={classes.link} to="/settings">
                                Settings
                            </Link>
                           <StyledTypography className={classes.text}>Profile</StyledTypography>     
                        </BreadCrumbs>
                    </StyledCard>
                <div className="dataContainer">
                    <div className="containerHeader">
                        <Image alt="profile_IC" className={classes.avatar} src={profileIc} />
                        <div className="containerTitle">Profile</div>
                    </div>
                    <div id="settingDataScrollerWrapper">
                        <div className="settingsContainer">
                            <div className="settingsRightSidePanel">
                                <div className="commonSettingsBox profileRightSideMainDiv">
                                    <Scrollbars autoHide style={scrollbar}>
                                            <div className="customFormContainer" >
                                            <div className="input_wrap">    
                                            <div className="form-attribute">
                                                  <StyledTextField
                                                     autoFocus={true} 
                                                     error={this.state.isNameError ? true : false} 
                                                     onBlur={this.checkUserName} 
                                                     value={this.state.userName} 
                                                     onChange={e => this.onChangeUserName(e.target.value)}  
                                                     label="Name" 
                                                     variant="outlined" 
                                                 />
                                            </div>
                                            <div className="form-attribute disbableFieldText">
                                                  <StyledTextField 
                                                     disabled={true}  
                                                     onBlur={this.checkUserName} 
                                                     value={this.state.user_details.email}
                                                     label="Account Email (Private)" 
                                                     variant="outlined" 
                                                   />
                                            </div>
                                            </div>
                                            <div className="input_wrap">
                                            <div className="form-attribute disbableFieldText" >
                                                 <StyledTextField 
                                                          disabled={true}                
                                                          onBlur={this.checkUserName} 
                                                          value="****************"  
                                                          label="Password" 
                                                          variant="outlined"
                                                          InputProps={{ endAdornment: <ChangePassword handlechangePasswordModal={this.handlechangePasswordModal}/> }} 
                                                   />
                                            </div>
                                            <div className="form-attribute">
                                                   <StyledAutoComplete
                                                        id="timezone"
                                                        value={this.state.selectedTimeZone}
                                                        openOnFocus = {true}
                                                        onChange={this.handleTimeZone}
                                                        defaultValue={this.state.selectedTimeZone !== "" ?  this.state.selectedTimeZone : {label: "UTC", value: "UTC"}}
                                                        options={this.state.timeZoneOptions}
                                                        getOptionLabel={option => option.label}
                                                        renderInput={params => <StyledTextField  {...params} label="TimeZone" variant="outlined" />}
                                                />
                                            </div>
                                            </div>
                                            <div className="input_wrap">
                                            <div className="form-attribute">
                                                <StyledAutoComplete
                                                       id="dateformat"
                                                       openOnFocus = {true}
                                                       value={this.state.selectedDateFormat}
                                                       onChange={this.handleDateFormat}
                                                       defaultValue={this.state.selectedTimeZone !== "" ?  this.state.selectedTimeZone : {label: "UTC", value: "UTC"}}
                                                       options={DATE_FORMAT_OPTIONS}
                                                       getOptionLabel={option => option.label}
                                                       renderInput={params => <StyledTextField  {...params} label="Date Format" variant="outlined" />}
                                                  />
                                            </div>
                                            </div>
                                            {
                                                <div className={classes.saveBtnRoot}>
                                                <div className={classes.wrapper} >
                                                  <Button
                                                    variant="contained"
                                                    className={classes.saveBtn}
                                                    disabled={this.state.showSaveLoader}
                                                    onClick={this.handleSubmit}
                                                  >
                                                   SAVE
                                                  </Button>
                                                  {this.state.showSaveLoader && <CircularProgress size={22} className={classes.buttonProgress} />}
                                                </div>
                                              </div>
                                            }
                                            </div>
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <Drawer
                        openDrawer = {this.state.showChangePasswordModal}
                        closeDrawer = {this.cancelChangePassword}
                        classes = {classes}
                        loading = {this.state.showLoader}
                        isCurrentPasswordError = {this.state.isCurrentPasswordError}
                        isConfirmPasswordError =  {this.state.isConfirmPasswordError}
                        isNewPasswordError = {this.state.isNewPasswordError}
                        passwordError = {this.state.passwordError}
                        newPasswordLengthError = {this.state.newPasswordLengthError}
                        alphaNumericPasswordflag = {this.state.alphaNumericPasswordflag}
                        newPassword = {this.state.newPassword}
                        currentPassword = {this.state.currentPassword}
                        confirmPassword = {this.state.confirmPassword}
                        changePassword = {this.changePassword}
                        checkNewPassword = {this.checkNewPassword}
                        checkConfirmPassword = {this.checkConfirmPassword}
                        checkCurrentPassword = {this.checkCurrentPassword}
                        handleChangePassword = {this.handleChangePassword}
                        cancelChangePassword = {this.cancelChangePassword}
                        passwordNotMatched = {this.state.passwordNotMatched}
                    />

                    {this.props.showSettingFlashMessageFlag === 1 && !this.state.showSaveLoader?
                        <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)}/>  
                        : ''}
                </div>
                {/* dataContainer close */}
            </div>
            : <div>
                 <LinearProgressBar />
              </div>
            }
                </React.Fragment>
            // Settings Module Close
        )
    }
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withStyles(Styles)(withRouter(connect(mapStateToProps, { getTimeZoneList, editProfile, getUserDetails,setSettingsFlashMessage,toggleSettingsFlashMessage })(Profile)));