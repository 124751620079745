import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import './static/css/trackingList.scss'
import '../static/css/placeholder.scss'
import SearchTracking from './Search/index'
import { withRouter } from 'react-router-dom'
import TrackingListGrid from './trackingListGrid'
import { withStyles } from '@material-ui/core/styles';
import styles from './static/css/tracking'
import TrackingDrawer from './trackingDrawer'
import ImportDrawer from './importTracking'
import { Scrollbars } from 'react-custom-scrollbars';
import FlashMessagePopup from './common/flashMessage'
import LinearProgress from '@material-ui/core/LinearProgress';
import { getTagList, getSubUsersList } from '../Setup/actions/actionMethods'
import { setSelectedShipmentStatus, getTrackingList, clearShipmentError, clearTrackingList, deleteTracking, toggleFlashMessage, setCourierList, getCarrierList, setTrackingFlashMessage, setTrackingFilters, getFiltersList, setStatusArrayValueList, setCourierArrayValueList, setOriginCountryList, setDestinationCountryList, exportShipments, clearTagList, startPaginationLoader, endPaginationLoader, setTagList, setSubUserList, setFilterTagList } from './actions/actionMethods'
import {DEFAULT_TRACKING_LIMIT} from './common/constants'
var isEmpty = require('lodash.isempty')
const initValues = {
    popupMessage: { type: 'NOTICE', message: '', action: '' }
}
class TrackingList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageId: 1,
            limit: 20,
            trackingListArray: [],
            allFlag: 0,
            searchQueryString: '',
            showPaginationLoader: false,
            carrierList: [],
            globalFilters: {},
            setSearchQueryString: '',
            trackingStatusListValue: [],
            noRecord: false,
            popupMessage: false,
            addNewTrackingTabValue: '',
            openTrackingdrawer: false,
            openImportDrawer: false
        }
    }
    handlePopUpDisplay = (type) => {
        if (type === 'addNewTracking') {
            this.setState({ openTrackingdrawer: true })
            this.props.clearShipmentError()
        }
        if (type === 'importTracking') {
            this.setState({ openImportDrawer: true })
            this.props.clearShipmentError()
        }
    }
    closeDrawer = () => {
        this.setState({ openTrackingdrawer: false, openImportDrawer: false })
    }
    handleCancel = () => {
        this.setState({
            trackingListArray: []
        })
        let filters = ''
        if ((this.state.searchQueryString && this.state.searchQueryString !== undefined && this.state.searchQueryString.length > 0) || !isEmpty(this.props.trackingGlobalFilters)) {
            let urlParams = {}
            let storeObj = this.props.trackingGlobalFilters
            if (storeObj.trackingStatusArr) {
                let trackingStatus = storeObj.trackingStatusArr.map((element, index) => {
                    return (element.value)
                })
                urlParams['trackingStatusArr'] = trackingStatus.join()
            }
            if (storeObj.courierListArr) {
                let couriersList = storeObj.courierListArr.map((element, index) => {
                    return (element.value)
                })
                urlParams['courierListArr'] = couriersList.join()
            }
            if (storeObj.originListArr) {
                let originList = storeObj.originListArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['originListArr'] = originList.join()
            }
            if (storeObj.destinationListArr) {
                let destinationList = storeObj.destinationListArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['destinationListArr'] = destinationList.join()
            }
            if (this.props.trackingGlobalFilters.transitDays !== '') {
                urlParams['transitDays'] = this.props.trackingGlobalFilters.transitDays
            }
            if (this.props.trackingGlobalFilters.date !== '') {
                urlParams['date'] = this.props.trackingGlobalFilters.date
            }
            if (this.props.trackingGlobalFilters.fromDate !== '' && this.props.trackingGlobalFilters.toDate !== '') {
                urlParams['fromDate'] = this.props.trackingGlobalFilters.fromDate
                urlParams['toDate'] = this.props.trackingGlobalFilters.toDate
            }
            if (storeObj.subUserListArr) {
                let subUserList = storeObj.subUserListArr.map((element, index) => {
                    return (element.label)
                })
                let subUserListId = storeObj.subUserListArr.map((element, index) => {
                    return (element.value)
                })
                urlParams['subUserListArr'] = subUserList.join()
                urlParams['subUserListId'] = subUserListId.join()
            }
            if (storeObj.tagListArr && storeObj.tagListArr.length > 0) {
                let tagList = storeObj.tagListArr.map((element, index) => {
                    return (element.label)
                })
                let tagListId = storeObj.tagListArr.map((element, index) => {
                    return (element.value)
                })
                urlParams['tagListArr'] = tagList.join()
                urlParams['tagListId'] = tagListId.join()
            }
            if (this.props.trackingGlobalFilters.query && this.props.trackingGlobalFilters.query !== '') {
                urlParams['query'] = this.props.trackingGlobalFilters.query
            }
            filters = `?${queryString.stringify(urlParams)}`
        }
        this.props.history.push(`/tracking` + filters)
    }
    async componentWillMount() {
        this.props.clearTagList()
        this.props.setTrackingFilters({})
        this.props.clearTrackingList()
        localStorage.removeItem('rowsPerPage')
        if (this.props.location.search !== '') {
            let filters = await this.prepereFilter()
            this.props.setTrackingFilters(filters)
            this.applySearchAndFilter()
        }
        else {
            let requestParams = { "pageId": 1, "limit": 20 }
            let urlParams = {}
            urlParams['trackingStatusArr'] ='All'
            this.props.startPaginationLoader()

            if(!this.props.match.params.id && this.props.location.pathname !== '/tracking/add')
                this.props.history.push(`/tracking` + `?${queryString.stringify(urlParams)}`)
            this.props.getTrackingList(requestParams).then(res => {
                if (res.data && res.data.data && res.data.data.length === 0) {
                    this.setState({
                        noRecord: true
                    })
                }
            })
        }
        if (localStorage.getItem('user_type') !== 'subuser') {
            await getSubUsersList().then(response => {
                if (response && response.data && response.data.data) {
                    // this.props.setSubUserList(response.data.data)
                    let subUsers = []
                    response.data.data.forEach(user => {
                        if (user.invite === 1) {
                            subUsers.push({
                                value: user._id,
                                label: user.name,
                                isChecked: false
                            })
                        }
                    })
                    this.props.setSubUserList(subUsers)
                }
            })
        }
        await getTagList().then(response => {
            if (response && response.data && response.data.meta && response.data.meta.code >= 200 && response.data.meta.code >= 200) {
                let tagArrFilter = []
                let tagArrList = []
                response.data.data.forEach(tag => {
                    tagArrFilter.push({
                        value: tag._id,
                        label: tag.tag_name,
                    })
                    tagArrList.push({
                        "_id": tag._id,
                        "tag_name": tag.tag_name,
                        "tag_color": tag.tag_color
                    })
                })
                this.props.setTagList(tagArrList)
                this.props.setFilterTagList(tagArrFilter)
            }
        })
    }
    prepereFilter = () => {
        let filters = {}
        let urlFilters = queryString.parse(this.props.location.search)
        if (urlFilters.trackingStatusArr) {
            let trackingStatusArr = urlFilters.trackingStatusArr.split(',')
            let newTrackingStatusArr = []
            for (let i = 0; i < trackingStatusArr.length; i++) {
                let eachTrackingStatus = trackingStatusArr[i]
                newTrackingStatusArr.push({
                    label: eachTrackingStatus,
                    value: eachTrackingStatus
                })
            }
            filters['trackingStatusArr'] = newTrackingStatusArr
        }

        if (urlFilters.allTrackingStatusArr) {
            let trackingStatusArr = urlFilters.allTrackingStatusArr.split(',')
            let newTrackingStatusArr = []
            for (let i = 0; i < trackingStatusArr.length; i++) {
                let eachTrackingStatus = trackingStatusArr[i]
                newTrackingStatusArr.push({
                    label: eachTrackingStatus,
                    value: eachTrackingStatus
                })
            }
            this.props.setSelectedShipmentStatus('All')
            filters['allTrackingStatusArr'] = newTrackingStatusArr
        }
        if (urlFilters.courierListArr) {
            let courierListArr = urlFilters.courierListArr.split(',')
            let newcourierListArr = []
            for (let i = 0; i < courierListArr.length; i++) {
                let eachcourierList = courierListArr[i]
                newcourierListArr.push({
                    label: eachcourierList,
                    value: eachcourierList.toLowerCase()
                })
            }
            filters['courierListArr'] = newcourierListArr
        }
        if (urlFilters.originListArr) {
            let originListArr = urlFilters.originListArr.split(',')
            let newOriginListArr = []
            for (let i = 0; i < originListArr.length; i++) {
                let eachoriginList = originListArr[i]
                newOriginListArr.push({
                    label: eachoriginList,
                    value: eachoriginList
                })
            }
            filters['originListArr'] = newOriginListArr
        }
        if (urlFilters.destinationListArr) {
            let destinationListArr = urlFilters.destinationListArr.split(',')
            let newDestinationListArr = []
            for (let i = 0; i < destinationListArr.length; i++) {
                let eachDestinationList = destinationListArr[i]
                newDestinationListArr.push({
                    label: eachDestinationList,
                    value: eachDestinationList
                })
            }
            filters['destinationListArr'] = newDestinationListArr
        }
        if (urlFilters.transitDays) {
            filters['transitDays'] = urlFilters.transitDays
        }
        if (urlFilters.date) {
            filters['date'] = urlFilters.date
        }
        if (urlFilters.fromDate && urlFilters.toDate) {
            filters['fromDate'] = urlFilters.fromDate
            filters['toDate'] = urlFilters.toDate
        }
        if (urlFilters.subUserListArr && urlFilters.subUserListId) {
            let newSubUserList = urlFilters.subUserListArr.split(',')
            let newsubUserId = urlFilters.subUserListId.split(',')
            let newSubUserListArr = []
            for (let i = 0; i < newSubUserList.length; i++) {
                let eachTrackingLabel = newSubUserList[i]
                let eachTrackingValue = newsubUserId[i]
                newSubUserListArr.push({
                    label: eachTrackingLabel,
                    value: eachTrackingValue
                })
            }
            filters['subUserListArr'] = newSubUserListArr
        }

        if (urlFilters.tagListArr && urlFilters.tagListId) {
            let newTagList = urlFilters.tagListArr.split(',')
            let newTagid = urlFilters.tagListId.split(',')
            let newTagArrList = []
            for (let i = 0; i < newTagList.length; i++) {
                let eachTrackingLabel = newTagList[i]
                let eachTrackingValue = newTagid[i]
                newTagArrList.push({
                    label: eachTrackingLabel,
                    value: eachTrackingValue
                })
            }
            filters['tagListArr'] = newTagArrList
        }

        if (urlFilters.query) {
            filters['query'] = urlFilters.query
            this.setState({
                setSearchQueryString: urlFilters.query
            })
        }
        this.setState({
            globalFilters: filters
        })
        return filters
    }
    applySearchAndFilter = () => {
        let urlParams = {}
        let filterFlag = !isEmpty(this.state.globalFilters)
        if (filterFlag) {
            let storeObj = this.state.globalFilters
            if (storeObj.trackingStatusArr) {
                let trackingStatus = storeObj.trackingStatusArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['trackingStatusArr'] = trackingStatus.join()
            }

            if (storeObj.allTrackingStatusArr) {
                let trackingStatus = storeObj.allTrackingStatusArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['allTrackingStatusArr'] = trackingStatus.join()
            }


            if (storeObj.courierListArr) {
                let couriersList = storeObj.courierListArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['courierListArr'] = couriersList.join()
            }
            if (storeObj.originListArr) {
                let originList = storeObj.originListArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['originListArr'] = originList.join()
            }
            if (storeObj.destinationListArr) {
                let destinationList = storeObj.destinationListArr.map((element, index) => {
                    return (element.label)
                })
                urlParams['destinationListArr'] = destinationList.join()
            }
            if (this.props.trackingGlobalFilters.transitDays !== '') {
                urlParams['transitDays'] = this.props.trackingGlobalFilters.transitDays
            }
            if (this.props.trackingGlobalFilters.date !== '') {
                urlParams['date'] = this.props.trackingGlobalFilters.date
            }
            if (this.props.trackingGlobalFilters.fromDate !== '' && this.props.trackingGlobalFilters.toDate !== '') {
                urlParams['fromDate'] = this.props.trackingGlobalFilters.fromDate
                urlParams['toDate'] = this.props.trackingGlobalFilters.toDate
            }
            if (this.state.globalFilters.query && this.state.globalFilters.query !== '') {
                urlParams['query'] = this.state.globalFilters.query
            }
            if (storeObj.subUserListArr) {
                let subUserList = storeObj.subUserListArr.map((element, index) => {
                    return (element.label)
                })
                let subUserListId = storeObj.subUserListArr.map((element, index) => {
                    return (element.value)
                })
                urlParams['subUserListArr'] = subUserList.join()
                urlParams['subUserListId'] = subUserListId.join()
            }
            if (storeObj.tagListArr && storeObj.tagListArr.length > 0) {
                let tagList = storeObj.tagListArr.map((element, index) => {
                    return (element.label)
                })
                let tagListId = storeObj.tagListArr.map((element, index) => {
                    return (element.value)
                })
                urlParams['tagListArr'] = tagList.join()
                urlParams['tagListId'] = tagListId.join()
            }
            let globalFiltersValue = {}
            let requestParams = {}
            globalFiltersValue = this.state.globalFilters
            requestParams.pageId = 1
            requestParams.limit = localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage') : DEFAULT_TRACKING_LIMIT
            let trackingStatusArrVal = []
            let couriersListArrVal = []
            let originListArrVal = []
            let destinationListArrVal = []
            let subUserListArr = []
            let tagListArr = []

            // if All Filters Are Appllied
            if (globalFiltersValue.allTrackingStatusArr && globalFiltersValue.allTrackingStatusArr.length === 1 && globalFiltersValue.allTrackingStatusArr[0].value === 'All') {
                requestParams.status = ['Pending', 'InfoReceived', 'InTransit', 'OutForDelivery', 'Delivered', 'Exception', 'FailedAttempt', 'Expired']
            }

            // if Multiple Filters Are Appllied

            else if (globalFiltersValue.allTrackingStatusArr && globalFiltersValue.allTrackingStatusArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.allTrackingStatusArr.length; i++) {
                    trackingStatusArrVal.push(globalFiltersValue.allTrackingStatusArr[i].value)
                }

                requestParams.status = trackingStatusArrVal
            }

            // if Only 1 Filter is Appllied

            else if (globalFiltersValue.trackingStatusArr && globalFiltersValue.trackingStatusArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.trackingStatusArr.length; i++) {
                    trackingStatusArrVal.push(globalFiltersValue.trackingStatusArr[i].value)
                }
                if (trackingStatusArrVal[0] === 'All') {
                    requestParams.status = ['Pending', 'InfoReceived', 'InTransit', 'OutForDelivery', 'Delivered', 'Exception', 'FailedAttempt', 'Expired']
                }
                else {
                    requestParams.status = trackingStatusArrVal
                }

            }

            if (globalFiltersValue.courierListArr && globalFiltersValue.courierListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.courierListArr.length; i++) {
                    couriersListArrVal.push(globalFiltersValue.courierListArr[i].value)
                }
                requestParams.courier = couriersListArrVal
            }
            if (globalFiltersValue.originListArr && globalFiltersValue.originListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.originListArr.length; i++) {
                    originListArrVal.push({
                        label: globalFiltersValue.originListArr[i].label,
                        value: globalFiltersValue.originListArr[i].value
                    })
                }
                requestParams.origin = originListArrVal
            }
            if (globalFiltersValue.destinationListArr && globalFiltersValue.destinationListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.destinationListArr.length; i++) {
                    destinationListArrVal.push({
                        label: globalFiltersValue.destinationListArr[i].label,
                        value: globalFiltersValue.destinationListArr[i].value
                    })
                }
                requestParams.destination = destinationListArrVal
            }

            if (globalFiltersValue.subUserListArr && globalFiltersValue.subUserListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.subUserListArr.length; i++) {
                    subUserListArr.push(
                        globalFiltersValue.subUserListArr[i]
                    )
                }
                requestParams.assigned_id = subUserListArr
            }

            if (globalFiltersValue.tagListArr && globalFiltersValue.tagListArr.length > 0) {
                for (let i = 0; i < globalFiltersValue.tagListArr.length; i++) {
                    tagListArr.push(
                        globalFiltersValue.tagListArr[i]
                    )
                }
                requestParams.tags = tagListArr
            }

            if (globalFiltersValue.transitDays !== '') {
                requestParams.transitDays = globalFiltersValue.transitDays
            }
            if (globalFiltersValue.date !== '') {
                requestParams.date = globalFiltersValue.date
            }
            if (globalFiltersValue.fromDate !== '' && globalFiltersValue.toDate !== '') {
                requestParams.from_date = globalFiltersValue.fromDate
                requestParams.to_date = globalFiltersValue.toDate
            }
            if (this.state.setSearchQueryString !== undefined && this.state.setSearchQueryString !== '' && this.state.setSearchQueryString !== null) {
                requestParams.query = this.state.setSearchQueryString.trim();
            }
            this.props.getTrackingList(requestParams)
        }


        // let filters = `?${queryString.stringify(urlParams, { strict: false })}`
        // this.props.history.push(`/tracking` + filters)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.trackingList !== nextProps.trackingList) {
            if (this.state.allFlag === 1) {
                let idArr = []
                for (let i = 0; i < nextProps.trackingList.length; i++) {
                    idArr.push(nextProps.trackingList[i]._id)
                }
                this.setState({
                    trackingListArray: idArr,
                    allFlag: 1
                })
            }
        }
    }

    setPageId = () => {

        this.setState({
            pageId: 1
        })
    }

    getTrackingSearchResult = (query) => {
        let urlParams = {}
        let filters = ''
        let queryParams = {}
        if ((query && query.length > 0) || !isEmpty(this.props.trackingGlobalFilters)) {
            let urlFilters = queryString.parse(this.props.location.search)


            if (urlFilters.trackingStatusArr) {
                let trackingStatusArr = urlFilters.trackingStatusArr.split(',')
                let newTrackingStatusArr = []
                for (let i = 0; i < trackingStatusArr.length; i++) {
                    let eachTrackingStatus = trackingStatusArr[i]
                    newTrackingStatusArr.push({
                        label: eachTrackingStatus,
                        value: eachTrackingStatus
                    })
                }
                urlParams['trackingStatusArr'] = newTrackingStatusArr
            }

            if (urlFilters.allTrackingStatusArr) {
                let trackingStatusArr = urlFilters.allTrackingStatusArr.split(',')
                let newTrackingStatusArr = []
                for (let i = 0; i < trackingStatusArr.length; i++) {
                    let eachTrackingStatus = trackingStatusArr[i]
                    newTrackingStatusArr.push({
                        label: eachTrackingStatus,
                        value: eachTrackingStatus
                    })
                }
                urlParams['allTrackingStatusArr'] = newTrackingStatusArr
            }


            if (urlFilters.courierListArr) {
                let courierListArr = urlFilters.courierListArr.split(',')
                let newcourierListArr = []
                for (let i = 0; i < courierListArr.length; i++) {
                    let eachcourierList = courierListArr[i]
                    newcourierListArr.push({
                        label: eachcourierList.toLowerCase(),
                        value: eachcourierList.toLowerCase()
                    })
                }
                urlParams['courierListArr'] = newcourierListArr
            }
            if (urlFilters.originListArr) {
                let originListArr = urlFilters.originListArr.split(',')
                let newOriginListArr = []
                for (let i = 0; i < originListArr.length; i++) {
                    let eachoriginList = originListArr[i]
                    newOriginListArr.push({
                        label: eachoriginList,
                        value: eachoriginList
                    })
                }
                urlParams['originListArr'] = newOriginListArr
            }
            if (urlFilters.destinationListArr) {
                let destinationListArr = urlFilters.destinationListArr.split(',')
                let newDestinationListArr = []
                for (let i = 0; i < destinationListArr.length; i++) {
                    let eachDestinationList = destinationListArr[i]
                    newDestinationListArr.push({
                        label: eachDestinationList,
                        value: eachDestinationList
                    })
                }
                urlParams['destinationListArr'] = newDestinationListArr
            }
            if (urlFilters.transitDays) {
                urlParams['transitDays'] = urlFilters.transitDays
            }
            if (urlFilters.date) {
                urlParams['date'] = urlFilters.date
            }
            if (urlFilters.fromDate && urlParams.toDate) {
                urlParams['fromDate'] = urlFilters.fromDate
                urlParams['toDate'] = urlFilters.toDate
            }
            if (query && query.length > 0) {
                urlParams['query'] = query;
            }

            if (this.props.trackingGlobalFilters.subUserListArr && this.props.trackingGlobalFilters.subUserListArr.length > 0) {
                let subUser = this.props.trackingGlobalFilters.subUserListArr
                let preparedSubUserData = []
                for (let i = 0; i < subUser.length; i++) {
                    let eachUser = subUser[i]
                    preparedSubUserData.push({
                        value: eachUser.value,
                        label: eachUser.label
                    })
                }
                urlParams['subUserListArr'] = preparedSubUserData;
            }
            if (this.props.trackingGlobalFilters.tagListArr && this.props.trackingGlobalFilters.tagListArr.length > 0) {
                let tags = this.props.trackingGlobalFilters.tagListArr
                let preparedTagListData = []
                for (let i = 0; i < tags.length; i++) {
                    let eachTag = tags[i]
                    preparedTagListData.push({
                        value: eachTag.value,
                        label: eachTag.label
                    })
                }
                urlParams['tagListArr'] = preparedTagListData;
            }
            this.props.setTrackingFilters(urlParams)
            let storeObj = urlParams
            if (storeObj.trackingStatusArr) {
                let trackingStatus = storeObj.trackingStatusArr.map((element, index) => {
                    return (element.label)
                })
                queryParams['trackingStatusArr'] = trackingStatus.join()
            }

            if (storeObj.allTrackingStatusArr) {
                let trackingStatus = storeObj.allTrackingStatusArr.map((element, index) => {
                    return (element.label)
                })
                queryParams['allTrackingStatusArr'] = trackingStatus.join()
            }

            if (storeObj.courierListArr) {
                let couriersList = storeObj.courierListArr.map((element, index) => {
                    return (element.label)
                })
                queryParams['courierListArr'] = couriersList.join()
            }
            if (storeObj.originListArr) {
                let originList = storeObj.originListArr.map((element, index) => {
                    return (element.label)
                })
                queryParams['originListArr'] = originList.join()
            }
            if (storeObj.destinationListArr) {
                let destinationList = storeObj.destinationListArr.map((element, index) => {
                    return (element.label)
                })
                queryParams['destinationListArr'] = destinationList.join()
            }
            if (this.props.trackingGlobalFilters.transitDays !== '') {
                queryParams['transitDays'] = this.props.trackingGlobalFilters.transitDays
            }
            if (this.props.trackingGlobalFilters.date !== '') {
                queryParams['date'] = this.props.trackingGlobalFilters.date
            }
            if (this.props.trackingGlobalFilters.fromDate !== '' && this.props.trackingGlobalFilters.toDate !== '') {
                queryParams['fromDate'] = this.props.trackingGlobalFilters.fromDate
                queryParams['toDate'] = this.props.trackingGlobalFilters.toDate
            }

            if (this.props.trackingGlobalFilters.subUserListArr && this.props.trackingGlobalFilters.subUserListArr.length > 0) {
                let subUser = this.props.trackingGlobalFilters.subUserListArr
                let subUserList = subUser.map((element, index) => {
                    return (element.label)
                })
                let subUserListId = subUser.map((element, index) => {
                    return (element.value)
                })
                queryParams['subUserListArr'] = subUserList.join()
                queryParams['subUserListId'] = subUserListId.join()
            }
            if (this.props.trackingGlobalFilters.tagListArr && this.props.trackingGlobalFilters.tagListArr.length > 0) {
                let tags = this.props.trackingGlobalFilters.tagListArr
                let tagList = tags.map((element, index) => {
                    return (element.label)
                })
                let tagListId = tags.map((element, index) => {
                    return (element.value)
                })
                queryParams['tagListArr'] = tagList.join()
                queryParams['tagListId'] = tagListId.join()
            }

            if (query) {
                queryParams['query'] = query;
            }
        }
        filters = `?${queryString.stringify(queryParams, { strict: false })}`
        this.props.history.replace(`/tracking` + filters)
    }
    resetTrackingSearch = () => {
        this.setState({
            setSearchQueryString: ''
        })
        this.getTrackingSearchResult(null)
    }
    setPaginationLoader = () => {
        this.setState({
            showPaginationLoader: false
        })
    }

    setStatusListVal = (statusListValue) => {
        this.setState({
            trackingStatusListValue: statusListValue
        })
    }

    handleExportShipments = (request) => {
        exportShipments(request)
            .then(response => {
                initValues.popupMessage = { type: 'SUCCESS', message: 'Your export will take sometime. You will receive notification & email with download link.', action: 'export' }
                this.setState({
                    popupMessage: true
                })
                setTimeout(() => {
                    this.setState({
                        popupMessage: false
                    })
                }, 5000);
            })
            .catch(error => console.log(error));
    }

    changeTabToAll = () => {
        this.setState({
            addNewTrackingTabValue: 'All'
        }, () => {
            setTimeout(() => {
                this.setState({
                    addNewTrackingTabValue: ''
                })
            }, 1500);
        })
    }
    closhFlashMessage = () => {
        this.setState({ popupMessage: false })
    }

    importMessage = () => {
        initValues.popupMessage = { type: 'SUCCESS', message: 'Your import will take sometime. You will receive notification with download link.', action: 'import' }
        this.setState({
            popupMessage: true
        })
    }
    render() {
        let popupMessage = initValues.popupMessage
        let classes = this.props.classes
        return (
            <div className="trackingModule">
                <div className="commonTopHeader">
                    <SearchTracking
                        popupDisplay={this.handlePopUpDisplay}
                        resetTrackingSearch={() => this.resetTrackingSearch()}
                        onSearchQueryString={(term) => this.getTrackingSearchResult(term)}
                        setPaginationLoader={this.setPaginationLoader}
                        setSearchQueryString={this.state.setSearchQueryString}
                        startPaginationLoader={this.props.startPaginationLoader}
                    />
                </div>

                <div className="dataContainer">
                    {
                        this.state.noRecord && (this.props.trackingList !== undefined && this.props.trackingList.length === 0) && this.props.trackingGlobalFilters !== undefined && Object.keys(this.props.trackingGlobalFilters).length > 0 && this.props.trackingGlobalFilters.trackingStatusArr !== undefined && this.props.trackingGlobalFilters.trackingStatusArr[0].value === 'All' ?

                            <div className="main_view" style={{ margin: '0.5rem' }}>
                                <Scrollbars style={{ height: "calc(100vh - 130px)", clear: "both" }} autoHide={true}>
                                    <div className="PalceHolderWrapper">
                                        <div className="BannerContainer">
                                            <div className="BannerContent">
                                                <div className="BannerTitle">
                                                    Multi-shipment Tracking Software
                                            </div>
                                                <div className="BannerText">
                                                    Check how your shipments are lined up, with Trackhive's shipment tracking software - enhancing the entire journey of your consignment, right from the origin to the destination.
                                                <br /><br />
                                                    Go ahead, list your first shipment, now!
                                            </div>
                                                <div
                                                    onClick={(type) => this.handlePopUpDisplay('addNewTracking')}
                                                    className="Button"
                                                >
                                                    + Add Shipment
                                                </div>
                                                <div style={{ marginLeft: "1rem" }}
                                                    onClick={(type) => this.handlePopUpDisplay('importTracking')}
                                                    className="Button"
                                                >
                                                    + Import Shipment
                                                </div>
                                            </div>
                                            {/* <img src={ListingImg} alt="Place Holder" /> */}
                                            <div className="clearFix" />
                                        </div>
                                        <div className="FeaturesContainer">
                                            <div className="FeaturesBlockContainer">
                                                <div className="FeatureBlock">
                                                    <div className="FeatureTitle">Trackhive advantages</div>
                                                    <div className="FeatureText">
                                                        <ul>
                                                            <li>Necessary notifications</li>
                                                            <li>Price comparisons</li>
                                                            <li>Multi-channel integration</li>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="FeatureBlock">
                                                    <div className="FeatureTitle">Key benefits</div>
                                                    <div className="FeatureText">
                                                        <ul>
                                                            <li>Accurate cargo information</li>
                                                            <li>Feedback on delivery charges</li>
                                                            <li>Reduced costs</li>
                                                            <li>Lesser manual efforts</li>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="FeatureBlock">
                                                    <div className="FeatureTitle">What else can you do?</div>
                                                    <div className="FeatureText">
                                                        <ul>
                                                            <li>Expand your business</li>
                                                            <li>Connect or integrate your favorite shipping tools</li>
                                                            <li>Manage your customer experience</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Scrollbars>
                            </div>
                            :
                            <React.Fragment>
                                {
                                    this.props.setTrackingListFlag === 0 && this.state.noRecord === false ?
                                        <div>
                                            <LinearProgress color="primary" />
                                        </div>
                                        :
                                        <React.Fragment>
                                            <div className="dataScrollerWrapper">
                                                <div className="trackingListTabularStyleMainDiv">

                                                    <TrackingListGrid
                                                        trackingListArray={this.state.trackingListArray}
                                                        trackingList={this.props.trackingList}
                                                        showPaginationLoader={this.state.showPaginationLoader}
                                                        noOfTotalTrackingRecords={this.props.noOfTotalTrackingRecords}
                                                        trackingGlobalFilters={this.props.trackingGlobalFilters}
                                                        sendStatusListValue={this.setStatusListVal}
                                                        setTrackingListFlag={this.props.setTrackingListFlag}
                                                        getTrackingList={this.props.getTrackingList}
                                                        startPaginationLoader={this.props.startPaginationLoader}
                                                        setTrackingListPaginationFlag={this.props.setTrackingListPaginationFlag}
                                                        subUserList={this.props.subUserList}
                                                        carrierList={this.state.carrierList}
                                                        handleCancel={this.handleCancel}
                                                        changeTabToAll={this.changeTabToAll}
                                                        handlePopUpDisplay={this.handlePopUpDisplay}
                                                        setExportMessage={this.importMessage}
                                                        handleExportShipments={this.handleExportShipments}
                                                        prepereFilter={this.prepereFilter}
                                                        applySearchAndFilter={this.applySearchAndFilter}
                                                    />
                                                </div>

                                            </div>
                                        </React.Fragment>
                                }
                            </React.Fragment>
                    }
                </div>
                {
                    this.state.openTrackingdrawer ?
                        <TrackingDrawer
                            openDrawer={this.state.openTrackingdrawer}
                            closeDrawer={this.closeDrawer}
                            classes={classes}
                            handleCancel={this.handleCancel}
                            changeTabToAll={this.changeTabToAll}
                            startPaginationLoader={this.props.startPaginationLoader}
                            setPageId={this.setPageId}
                        />
                        : ""
                }

                <ImportDrawer
                    openDrawer={this.state.openImportDrawer}
                    closeDrawer={this.closeDrawer}
                    classes={classes}
                    setPageId={this.setPageId}
                    setExportMessage={this.importMessage}
                />
                {
                    this.state.popupMessage ?
                        <FlashMessagePopup open={this.state.openSnackBar} closeFlash={this.closhFlashMessage} classes={''} open={true} messageDetails={popupMessage} />
                        : ''
                }
            </div >
        )
    }
}
const mapStateToProps = state => ({
    trackingList: state.tracking.list.trackingList,
    setTrackingListFlag: state.tracking.list.setTrackingListFlag,
    noOfTotalTrackingRecords: state.tracking.list.totalTrackingRecords,
    trackingGlobalFilters: state.tracking.list.trackingFilters,
    setTrackingListPaginationFlag: state.tracking.list.setTrackingListPaginationFlag
})
export default withStyles(styles)(withRouter(connect(mapStateToProps, { setSelectedShipmentStatus, getTrackingList, clearShipmentError, clearTrackingList, deleteTracking, toggleFlashMessage, setTrackingFlashMessage, setTrackingFilters, getCarrierList, setCourierList, getFiltersList, setStatusArrayValueList, setCourierArrayValueList, setOriginCountryList, setDestinationCountryList, exportShipments, clearTagList, startPaginationLoader, endPaginationLoader, setTagList, setSubUserList, setFilterTagList })(TrackingList)))