import React from 'react';
import '../assets/css/webhook.scss';
import { connect } from 'react-redux';
import Drawer from './developerAppsDrawer';
import Table from "@material-ui/core/Table";
import Styles from '../assets/css/Styles'
import { CssBaseline } from '@material-ui/core';
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import {withStyles} from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import FlashMessage from '../common/FlashMessage';
import TableContainer from "@material-ui/core/TableContainer";
import {ApiKeys, addIcon} from '../../common/imageList';
import { LinearProgressBar, StyledCard, StyledTypography, BreadCrumbs, Image } from '../common/materialComponents'
import { getDeveloperTokenList, addDeveloperToken, refreshDeveloperToken, toggleSettingsFlashMessage, setSettingsFlashMessage, deleteToken } from '../actions/actionMethods';
import {TOKEN_ADDED_SUCCESSFULLY_MESSSAGE, TOKEN_REFRESHED_SUCCESSFULLY_MESSAGE, TOKEN_DELETED_SUCCESSFULLY_MESSAGE, TOKEN_COPIED_SUCCESSFULLY} from '../common/constants'
class DeveloperApps extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess = true
        this.state = {
            showLoader: false,
            developerTokenList: [],
            noDataMessage: '',
            refreshTokenLoader: false,
            addDeveloperTokenLoader: true,
            showViewTokenModal: false,
            viewDeveloperTokenValue: '',
            showDeleteTokenModal: false,
            showDeleteLoader: false,
            isNewTokenAdded: false,
            showModal: false,
            deleteIndex: 0,
            currentIndex: 0,
            tokenData: {},
            showAddTokenModal: false,
            tokenName: '',
            showAddTokenLoader: false,
            addTokenInputError: false,
            tokenNameValue: '',
            onChange: false
        }
    }
    handleChangeTokenName = (value) => {
        this.setState({
            tokenName: value,
            addTokenInputError: false
        })
    }

    showAddTokenModalPopup = () => {
        this.setState({
            showAddTokenModal: true,
            showModal: true,
            showViewTokenModal: false,
            addTokenInputError: false,
            tokenName: '',
            tokenNameValue: ''
        })
    }
    addDeveloperToken = () => {
        if (this.state.tokenName === '') {
            this.setState({
                addTokenInputError: true
            })
        } else {
            this.setState({
                showAddTokenLoader: true
            })
            let requestParams = {}
            requestParams.token_name = this.state.tokenName
            addDeveloperToken(requestParams).then(res => {
                if (res && res.meta && res.meta.code >= 200 && res.meta.code <= 299) {
                    this.setState({
                        showAddTokenLoader: false,
                        showAddTokenModal: false,
                        showModal: false
                    })
                    this.getDeveloperToken()
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(TOKEN_ADDED_SUCCESSFULLY_MESSSAGE)
                }
            })
        }
    }

    performActions = (developerToken, tokenName, index, action) => {
        let data = {}
        if (index >= 0) {
            data.currentIndex = index
            this.setState({
                currentIndex: index
            })
        }
        data.developerToken = developerToken
        data.tokenName = tokenName
        this.setState({
            tokenData: data
        })
        if (action === "view") {
            this.setState({
                showViewTokenModal: true,
                showModal: true,
                onChange: true,
                viewDeveloperTokenValue: developerToken
            })
            setTimeout(() => {
                this.setState({onChange: false});
            }, 800)
        } else if (action === "refresh") {
            let eachDeveloperDataArr = [...this.state.developerTokenList]
            eachDeveloperDataArr[this.state.currentIndex].isRefresh = true
            this.setState({
                developerTokenList: eachDeveloperDataArr,
                refreshTokenLoader: true
            })
            let requestParams = {}
            requestParams.developer_token = {}
            requestParams.developer_token.token_name = tokenName
            requestParams.developer_token.token = developerToken

            refreshDeveloperToken(requestParams).then(res => {
                eachDeveloperDataArr[this.state.currentIndex].isRefresh = true
                this.setState({
                    developerTokenList: eachDeveloperDataArr,
                    showModal: false,
                    refreshTokenLoader: false
                }, () => {
                    this.getDeveloperToken()
                    this.props.toggleSettingsFlashMessage(1)
                    this.props.setSettingsFlashMessage(TOKEN_REFRESHED_SUCCESSFULLY_MESSAGE)
                })
            })
        } else if (action === "delete") {
            this.setState({
                showDeleteTokenModal: true,
                showModal: true,
                viewDeveloperTokenValue: developerToken,
                deleteIndex: this.state.currentIndex,
                tokenNameValue: tokenName
            })
        }
    }

    viewDeveloperToken = (developerToken, tokenName, index) => {
        this.setState({
            showViewTokenModal: true,
            showModal: true,
            viewDeveloperTokenValue: developerToken
        })
    }

    deleteDeveloperToken = () => {
        this.setState({
            showDeleteLoader: true,
            highlightFlag: false,
            isNewTokenAdded: false
        })
        let requestParams = {}
        requestParams.developer_token = {}
        requestParams.developer_token.token = this.state.viewDeveloperTokenValue
        requestParams.developer_token.token_name = this.state.tokenNameValue
        deleteToken(requestParams).then(res => {
            if (res && res.meta && res.meta.code >= 200 && res.meta.code <= 299) {
                this.setState({
                    showDeleteLoader: false,
                    showDeleteTokenModal: false,
                    showModal: false,
                    viewDeveloperTokenValue: ''
                })
                let spliceArr = [...this.state.developerTokenList]
                spliceArr.splice(this.state.deleteIndex, 1)
                this.setState({
                    developerTokenList: spliceArr
                })
                this.getDeveloperToken()
                this.props.toggleSettingsFlashMessage(1)
                this.props.setSettingsFlashMessage(TOKEN_DELETED_SUCCESSFULLY_MESSAGE)
            } else {
                this.props.toggleSettingsFlashMessage(1)
                this.props.setSettingsFlashMessage(res.data.meta.message[0])
            }
        })
    }

    cancelModalPopup = () => {
        this.setState({
            showAddTokenModal: false,
            showModal: false,
            showDeleteTokenModal: false,
        })
    }

    componentWillMount() {
        this.setState({
            showLoader: true
        })
        this.getDeveloperToken()
    }

    getDeveloperToken = () => {
        getDeveloperTokenList().then(res => {
            this.setState({
                showLoader: false,
                showViewTokenModal: false,
            })
            if (res.meta && res.meta.code >= 200 && res.meta.code <= 299 && res.data && res.data.developer_token && res.data.developer_token.length > 0) {
                let dataArr = []
                let reverseArr = res.data.developer_token.reverse()
                for (let index = 0; index < reverseArr.length; index++) {
                    let eachDeveloperData = reverseArr[index]

                    dataArr.push({
                        tokenName: eachDeveloperData.token_name,
                        developerToken: eachDeveloperData.token,
                        isRefresh: false
                    })
                }
                this.setState({
                    developerTokenList: dataArr
                })

            } else {
                this.setState({
                    developerTokenList: [],
                    noDataMessage: 'No API key is generated!'
                })
            }
        })
    }

    toggleFlashMessage = (data) => {
        this.props.toggleSettingsFlashMessage(data)
        this.props.setSettingsFlashMessage('')
    }
    
    showDeveloperTokenData = (developerTokenData) => {
      const {classes} = this.props
        return(
           <TableContainer className={classes.tableContainer}>
             <Table className={classes.table} stickyHeader aria-label="sticky table" >
                 <TableHead>
                    <TableRow>
                      <TableCell align="left" className={`${classes.headCell} ${classes.firstHeadAndBodyCell}`}>Key Name</TableCell>
                      <TableCell align="left" className={`${classes.headCell} ${classes.lastHeadAndBodyCell}`}>API Key</TableCell>
                    </TableRow>
                 </TableHead>
                 <TableBody >
                    {developerTokenData && developerTokenData.length > 0 ? 
                        developerTokenData.map((apiKeys,index) => (
                            <TableRow key={index}>
                                <TableCell align="left" className={`${classes.bodyCell} ${classes.firstHeadAndBodyCell}`}>
                                    {apiKeys.tokenName}
                                </TableCell>
                                <TableCell align="left" className={`${classes.bodyCell} ${classes.lastHeadAndBodyCell}`}>
                                    <div 
                                        style={{color: "#1a73e8", fontSize: "14px", textDecoration:'none', cursor: 'pointer'}} 
                                        onClick={(developerToken,tokenName,indexVal,action) => this.performActions(apiKeys.developerToken,apiKeys.tokenName,index, "view")} >{
                                        apiKeys.developerToken}
                                    </div>                           
                                </TableCell>
                            </TableRow>
                          ))
                    :<div className="noData" style={{ position: "absolute", padding: "2rem", left: '45%' }}>{this.state.noDataMessage}</div>
                    }
                 </TableBody>
             </Table>
           </TableContainer>     
            )
    }
    
    copyToken = () => {
        this.setState({
            showModal: false
        })
        this.isSuccess = true
        this.props.toggleSettingsFlashMessage(1)
        this.props.setSettingsFlashMessage(TOKEN_COPIED_SUCCESSFULLY)
    }
    render() {
       const { classes } = this.props;
       return (
            <React.Fragment>
            <CssBaseline/>
            {
                this.state.showLoader ?
                  <div>
                      <LinearProgressBar/>
                  </div> 
               :
            <div className="settingsModule">
                    <StyledCard>
                        <BreadCrumbs aria-label="breadcrumb">
                            <Link className={classes.link} to="/settings">
                                Settings
                            </Link>
                           <StyledTypography className={classes.text}>API Keys</StyledTypography>     
                        </BreadCrumbs>
                    </StyledCard>
                <div className="dataContainer">
                   <div className="containerHeader">
                        <Image alt="ApiKeys_IC" className={classes.avatar} src={ApiKeys} />
                            <div className="containerTitle">API Keys</div>
                            <div style={{float: "right", textAlign:"right", paddingRight: '26px', cursor: 'pointer'}}>
                            <img src={addIcon} alt="Add Icon" onClick={this.showAddTokenModalPopup} />
                        </div>
                    </div>
                    <div id="settingDataScrollerWrapper">
                        <div className="settingsContainer">
                            <div className="settingsRightSidePanel">
                                <div className="commonSettingsBox webhookStyleMainDiv">
                                    <div className="webhookMainContainerDiv">
                                             <div className="webhookContentMainDiv">
                                                 <div className="webhookContentInnerDiv" style={{ border: 'none' }}>
                                                   {this.showDeveloperTokenData(this.state.developerTokenList)}
                                                 </div>
                                             </div>
                                         </div>
                                </div>
                            </div>
                        </div>
                        <Drawer
                            openDrawer = {this.state.showModal}
                            tokenName = {this.state.tokenName}
                            classes={classes}
                            showAddTokenLoader = {this.state.showAddTokenLoader}
                            copyToken = {this.copyToken}
                            refreshTokenLoader = {this.state.refreshTokenLoader}
                            tokenData = {this.state.tokenData}
                            viewDeveloperTokenValue = {this.state.viewDeveloperTokenValue}
                            handleChangeTokenName = {this.handleChangeTokenName}
                            addTokenInputError = {this.state.addTokenInputError}
                            closeDrawer={this.cancelModalPopup}
                            performActions = {this.performActions}
                            showAddTokenModal = {this.state.showAddTokenModal}
                            showViewTokenModal = {this.state.showViewTokenModal}
                            addDeveloperToken = {this.addDeveloperToken}
                            showDeleteTokenModal = {this.state.showDeleteTokenModal}
                            deleteDeveloperToken = {this.deleteDeveloperToken}
                            onChange = {this.state.onChange}
                            showDeleteLoader = {this.state.showDeleteLoader}
                        />
                    </div>
                    {this.props.showSettingFlashMessageFlag === 1 ?
                        <FlashMessage isSuccess={this.isSuccess = true} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                        : ''}
                </div>
                {/* data container close  */}
            </div>
            }
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, { getDeveloperTokenList, addDeveloperToken, refreshDeveloperToken, toggleSettingsFlashMessage, setSettingsFlashMessage, deleteToken })(withStyles(Styles)(DeveloperApps)));