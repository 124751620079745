import React from 'react'
import '../../assets/css/settings.scss'
import '../../assets/css/profileStyle.scss'
import Grid from '@material-ui/core/Grid'
import Styles from '../../assets/css/Styles';
import Typography from '@material-ui/core/Typography'
import {trackingButtonMenu} from './trackingButtonsMenu'
import TrackingButtonCard from './trackingButtonCard'
import { withStyles } from '@material-ui/core/styles';

function TrackingButtonCards(props){
    const {classes} = props
    return(
        <div className="settingsModule" style={{background: "#f2f4f5"}}>
            <div className = "dataContainer">
                <Typography className = {classes.trackButtonMainTitle}>Select store to add track button</Typography>
                <div id="settingDataScrollerWrapper">
                   <div className="settingsContainer">                 
                      <div className={classes.trackButtonCardContainer}>
                        <Grid container>
                          {trackingButtonMenu.map((item,index) => {
                            return(
                              <Grid key={item.title+index} className={classes.trackButtonGridItem}>
                              <TrackingButtonCard 
                                key={index} 
                                title={item.title} 
                                path={item.path} 
                                icon={item.icon}
                            />
                            </Grid>
                            )
                            })}
                        </Grid>
                      </div>    
                   </div>
                </div>
            </div>
        </div>
    )
}

export default withStyles(Styles)(TrackingButtonCards)