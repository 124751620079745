import React from 'react'
const REACT_S3_URL = process.env.REACT_APP_S3_URL


//Trackhive Logo
export const trackhiveLogo = REACT_S3_URL + 'Trackhive-logo.svg'

export const infiniteLoader =  REACT_S3_URL +'infinite_loader.gif'
export const breadCrumbIcon = REACT_S3_URL + 'right_triangle.svg'
export const saveIcon = REACT_S3_URL + 'saveIcon.svg'
export const cancelIcon = REACT_S3_URL + 'cancelIcon.svg'
export const integrationIcon = REACT_S3_URL + 'integrationBlack.svg'
export const deleteIcon = REACT_S3_URL + 'deleteIcon.svg'
// export const editIcon = REACT_S3_URL + 'editIcon.svg'
export const defaultUserPic = REACT_S3_URL + 'defaultUserPic.svg'
export const defaultOrganizationPic = REACT_S3_URL + 'defaultOrgPic.svg'
// export const emailIcon = REACT_S3_URL + 'emailIcon.svg'
export const warningIcon = REACT_S3_URL + 'warningIcon.svg'
export const successIcon = REACT_S3_URL + 'check-circle-16p.svg'
export const circleLoaderImage = REACT_S3_URL+'profile.gif'
export const noTrackingIcon = REACT_S3_URL + 'noTrackingImage.png'
export const SettingIcon = REACT_S3_URL + 'settings.svg'
export const NextIcon = REACT_S3_URL + 'nextIcon.svg'
export const BackIcon = REACT_S3_URL + 'backIcon.svg'
export const DoneIcon = REACT_S3_URL + 'checkGrey.svg'
export const CopyIcon = REACT_S3_URL+'tracking-copy.svg'
export const syncGif = REACT_S3_URL + 'syncGif.gif'
export const TrackingBtnIcon = REACT_S3_URL +'trackingBtn.svg'
export const DateIcon = REACT_S3_URL + 'dateIcon.svg'


export const trackingUpload = REACT_S3_URL + 'trackingImport.svg'
export const accessDenied = REACT_S3_URL +'access-denied.svg'

//Tracking Status Icons

export const pendingStatusIcon = REACT_S3_URL + 'Pending.svg'
export const deliveredStatusIcon  = REACT_S3_URL + 'status-delivered.png'
export const inTransitStatusIcon =  REACT_S3_URL +'status-in-transit.png'
export const infoReceivedStatusIcon = REACT_S3_URL + 'status-info-receive.png'
export const outForDeliveryStatusIcon = REACT_S3_URL + 'status-out-for-delivery.png'
export const exceptionStatusIcon = REACT_S3_URL + 'status-exception.png'
export const expiredStatusIcon = REACT_S3_URL + 'status-expired.png'
export const failedAttemptIcon = REACT_S3_URL + 'status-attemptfail.png'


// Courier Logos

export const dashboardProgressBarLoader = REACT_S3_URL + 'progress-bar.gif'

export const USPSLOGO = REACT_S3_URL + 'courierImages/USPS.png'
export const UPSLOGO = REACT_S3_URL + 'courierImages/UPS.png'
export const FEDEX = REACT_S3_URL + 'courierImages/Fedex.png'
export const DELHIVERY = REACT_S3_URL + 'courierImages/DL.png'
export const DPD = REACT_S3_URL + 'courierImages/DPD.png'
export const ECMS = REACT_S3_URL + 'courierImages/ECMS.png'
export const FASTWAY = REACT_S3_URL + 'courierImages/Fastway.png'
export const LASERSHIP = REACT_S3_URL + 'courierImages/Lasership.png'
export const DHL = REACT_S3_URL + 'courierImages/DHL.svg'
export const GLS = REACT_S3_URL + 'courierImages/gls.png'
export const TNT = REACT_S3_URL + 'courierImages/TNT.png'
export const AUSPOSTLOGO = REACT_S3_URL + 'courierImages/AustraliaPost.svg'
export const BLUEDART = REACT_S3_URL + 'courierImages/BLUEDART.svg'
export const ARAMEX = REACT_S3_URL+'courierImages/Aramex.png'
export const CANADAPOST = REACT_S3_URL+'courierImages/CanadaPost.svg'
export const BRING = REACT_S3_URL+'courierImages/Bring.svg'
export const JAPANPOST = REACT_S3_URL+'courierImages/JapanPost.svg'
export const OMNIVA = REACT_S3_URL+'courierImages/Omniva.svg'
export const CNEEXPRESS = REACT_S3_URL+'courierImages/CNE.svg'
export const SHOPIFY = REACT_S3_URL + "courierImages/SHOPIFY.svg"
export const CDLLOGO = REACT_S3_URL + 'courierImages/Cdl.svg'
export const NINJAVANLOGO = REACT_S3_URL + 'courierImages/ninjavan.png'
export const GWWORLDLOGO = REACT_S3_URL + 'courierImages/GW.png'
export const NINETYNINELOGO = REACT_S3_URL + 'courierImages/NinetyNine.png'

export const DEFAULTCOURIERLOGO = REACT_S3_URL+'courierImages/Courier-Place-holder.png'


// DashBoard Icons

export const flightIcon = REACT_S3_URL + `dashboardIcon/all_shipments_icon.svg`
export const summaryIcon = REACT_S3_URL + `dashboardIcon/summaryIcon.svg`
export const topCouriersIcon = REACT_S3_URL + 'dashboardIcon/topCouriersIcon.svg'
export const worldIcon = REACT_S3_URL + 'dashboardIcon/worldIcon.svg'
export const averageDaysIcon = REACT_S3_URL+ 'dashboardIcon/averageDaysIcon.svg'
export const tatIcon = REACT_S3_URL+ 'dashboardIcon/tatIcon.svg'


// Woocommerce Integration Screenshot

export const storeURL = REACT_S3_URL + 'wocommerceIntegrationImages/storeurl.png'
export const Permalink = REACT_S3_URL + 'wocommerceIntegrationImages/Permalink.png'
export const Legacy = REACT_S3_URL + 'wocommerceIntegrationImages/legacy.png'
export const RestAPI = REACT_S3_URL + 'wocommerceIntegrationImages/Rest-API.png'
export const Permissions = REACT_S3_URL + 'wocommerceIntegrationImages/Permissions.png'
export const CarrierTrackhive = REACT_S3_URL + 'wocommerceIntegrationImages/CarrierTrackhive.png'

//Magento Integration Screenshot

export const IntegrationPage = REACT_S3_URL + 'magentoIntegrationImages/1.png'
export const AddNewIntegration = REACT_S3_URL + 'magentoIntegrationImages/2.png'
export const NewIntegration = REACT_S3_URL + 'magentoIntegrationImages/3.png'
export const MagentoAPIImage = REACT_S3_URL + 'magentoIntegrationImages/4.png'
export const ActivationLink = REACT_S3_URL + 'magentoIntegrationImages/6.png'
export const DialogBox1 = REACT_S3_URL + 'magentoIntegrationImages/7.1.png'
export const DialogBox2 = REACT_S3_URL + 'magentoIntegrationImages/7.png'
export const MagentoTargetStore = REACT_S3_URL + 'magentoIntegrationImages/defaultStoreView.png'
export const storeIDURL = REACT_S3_URL + 'magentoIntegrationImages/store_id.png'


// TrackingPage List Icons
export const defaultStar = REACT_S3_URL + 'starDefault.svg'
export const activeStar = REACT_S3_URL + 'starActive.svg'
export const defaultPreviewCompanyLogo = REACT_S3_URL + 'yourLogo.png'

//TrackButton SS

export const addNewPageToShopify = REACT_S3_URL + 'addNewPageToShopify.PNG'
export const shopifyContentButton = REACT_S3_URL + 'shopifyContentButton.png'
export const shopifyPreviewTrackPage = REACT_S3_URL + 'shopify_preview_page.png'


//Tag Icon
export const tagIcon = REACT_S3_URL + 'tagIcon.svg' 

//Settings Icon
export const Profile = REACT_S3_URL + `settingsIcons/ic_settings_Profile.svg`
export const Organization = REACT_S3_URL + `settingsIcons/ic_settings_Organization.svg`
export const EmailTemplates = REACT_S3_URL + `settingsIcons/ic_settings_Email Templates.svg`
export const EmailDomains = REACT_S3_URL + `settingsIcons/ic_settings_Email Domains.svg`
export const Webhooks = REACT_S3_URL + `settingsIcons/ic_settings_Webhooks.svg`
export const Users = REACT_S3_URL + `settingsIcons/ic_settings_Users.svg`
export const Tags = REACT_S3_URL + `settingsIcons/ic_settings_Shipment_Tags.svg`
export const ApiKeys = REACT_S3_URL + `settingsIcons/ic_settings_Api_Keys.svg`
export const CustomDomain = REACT_S3_URL + `settingsIcons/ic_settings_Custom_Domain.svg`
export const EmailAlerts = REACT_S3_URL + `settingsIcons/ic_settings_Email_Alerts.svg`
export const addIcon = REACT_S3_URL + 'settingsIcons/add_webhook.svg'
export const cancelIconSettings = REACT_S3_URL+'settingsIcons/ic_cancel_btn.svg'
export const previewIcon = REACT_S3_URL+'settingsIcons/preview_IC.svg'
export const checkedOutLineIcon = REACT_S3_URL+'settingsIcons/checked_IC.svg'
export const editOutlineIcon = REACT_S3_URL+'settingsIcons/edit_IC.svg'
export const CancelIcon = () => {
    return (
      <img
        src={REACT_S3_URL+'settingsIcons/ic_cancel_btn.svg'}
        alt="ic_cancel"
      />
    );
  };
export const SaveIcon = () => {
  return (
    <img
      src={REACT_S3_URL+'settingsIcons/ic_save_btn.svg'}
      alt=""
    />
  );
};

export const ExportButtonIcon = () => {
  return (
    <img
      src={REACT_S3_URL +'exportButton.svg'}
      alt=""
    />
  );
};

export const ImportButtonIcon = () => {
  return (
    <img
      src={REACT_S3_URL +'importButton.svg'}
      alt=""
    />
  );
};

export const RemoveIcon = () => {
  return (
    <img
      src={REACT_S3_URL+'settingsIcons/ic_delete_btn.svg'}
      alt="ic_delete"
    />
  );
};
export const CloseIcon = () => {
  return (
    <img
      src={REACT_S3_URL+'settingsIcons/ic_close.svg'}
      alt="ic_close"
    />
  );
};
export const ApiCopyIcon = () => {
  return(
    <img src = {REACT_S3_URL+'settingsIcons/ic_API key_copy.svg'} alt="ic_copy"/>
  )
}
export const ExpandIcon = () => {
  return(
    <img src = {REACT_S3_URL + 'downTriangle.svg'} alt="expand"/>
  )
}
export const RefreshIcon = () => {
  return(
    <img src = {REACT_S3_URL + 'settingsIcons/ic_refresh.svg'} alt="ic_refresh"/>
  )
}
export const PreviewOutlineIcon = () => {
  return(
    <img 
      src={REACT_S3_URL+'settingsIcons/previewOutline_IC.svg'}
      alt="preview_IC"
    />
  )
}
export const EmailIcon = () => {
  return(
    <img src={REACT_S3_URL+'settingsIcons/email_IC.svg'} alt="email_IC"/>
  )
}
export const verifyIcon = REACT_S3_URL+'settingsIcons/ic_verified.svg'
export const notVerifiedIcon = REACT_S3_URL+'settingsIcons/ic_unverified.svg'
//menu Icon
export const menuIcon = REACT_S3_URL + `menuIcons/humber_menu.svg`
export const profileIcon = REACT_S3_URL+`menuIcons/profile.svg`
export const notificationIcon = REACT_S3_URL + `menuIcons/notifications.svg`
export const closeIcon=REACT_S3_URL + `menuIcons/cancelIcon.svg`

// Filter Icon
export const expandIcon = REACT_S3_URL + 'expandIcon.svg'
export const collapseIcon = REACT_S3_URL + 'collapseIcon.svg'
export const columnIcon = REACT_S3_URL + 'columnIcon.svg'
export const importTrackingIcon = REACT_S3_URL + 'importIcon.svg'
export const exportTrackingIcon = REACT_S3_URL + 'exportIcon.svg'
export const addTrackingIcon = REACT_S3_URL + 'addIcon.svg'
export const filterIcon = REACT_S3_URL + 'filterIcon.svg'
export const clearIcon = REACT_S3_URL + 'clearIcon.svg'
export const trackIcon = REACT_S3_URL + 'trackIcon.svg'
export const trackingArrow = REACT_S3_URL + 'trackingArrow.svg'
export const downTriangle = REACT_S3_URL +'downTriangle.svg'

//Tracking Button Page Icon
export const shopifyIcon = REACT_S3_URL + 'settingsIcons/shopify_IC.svg'
export const wooCommerceIcon = REACT_S3_URL + 'settingsIcons/wooCommerce_IC.svg'
export const magentoIcon = REACT_S3_URL + 'settingsIcons/magento_IC.svg'
export const customStoreIcon = REACT_S3_URL + 'settingsIcons/customStore_IC.svg'
export const customTrigger = REACT_S3_URL + 'customTrigger.svg'
export const backArrow = REACT_S3_URL + 'backArrow.svg'

//Automation Icon
export const smallAddIcon = REACT_S3_URL + 'smallAdd.svg'
export const automationConditionIcon = REACT_S3_URL + 'automationConditionIcon.svg'
export const automationActionIcon = REACT_S3_URL + 'automationActionIcon.svg'
export const updateIcon = REACT_S3_URL + 'updateIcon.svg'
export const shipmentIcon = REACT_S3_URL + 'shipmentIcon1.svg'
export const automationPreview = REACT_S3_URL + 'automationPreview.svg'
export const assignShipmentAction = REACT_S3_URL + 'assignShipmentAction.svg'
export const sendEmailAction = REACT_S3_URL + 'sendEmailAction.svg'
export const EditCustom = REACT_S3_URL + 'editCustom.png'
export const IconDelete = REACT_S3_URL + 'settingsIcons/ic_delete_btn.svg'
