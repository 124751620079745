import {rootCss, saveBtnCss, btnProgressCss, btnLinkCss, btnBarCss, textFieldFocusCss, textFieldCss, cardContentCss} from '../static/constants'

let textFieldCustomCss = { ...textFieldCss, marginTop: "0px" }
let rootCustomCss = { ...rootCss, paddingBottom: "54px" }

const Styles = theme => ({ 
    root: rootCss,
    saveBtn: saveBtnCss,
    buttonProgress: btnProgressCss,
    forgotBtn: btnLinkCss,
    nextButtonRoot: btnBarCss,
    onFocus: textFieldFocusCss,
    forgotPasswordContent: {
        padding: "0px 41px",
        paddingTop: "16px",
        "&:last-child": {
            paddingBottom: "0px"
        }
    },
    forgotPasswordEmailtextField: {
        width: '368px',
        height: "56px",
        margin: "16px 41px 32px",
    },
    loginTextField: textFieldCss,
    createAcntBtn: {
        paddingLeft: "0px",
        color: "#1a73e8"
    },
    loginContent: {
        padding: "8px 41px",
        textAlign: "center",
        "&:last-child": {
            paddingBottom: "0px"
        }
    },
    rootCustomCss: rootCustomCss,
    signUpTextField: textFieldCustomCss,
    userLoginContent: cardContentCss,
    chipLabel: {
        color: "#5e6367"
    },
    deleteIcon: {
        "& > img": {
            width: "10px",
            height: "10px",
            marginRight: "5px",
            marginLeft: "-2px",
        }
    },
    title: {
        fontWeight: 500,
        color: "#666666"
    },
    headerRoot : {
        padding : "16px 16px 15px 16px"
    },
    subHeader: {
        fontSize: "12px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "0.12px",
        color: "#666666",

    },
    content: {
        padding: "0px 26px",
        "&:last-child": {
            paddingBottom: "0px"
        },
        cursor: "pointer",
        "&:hover": {
            background: "#e3eefc"
        }
    },
    divider: {
        margin: "0px 41px",
        backgroundColor: "#dadce0",
    },
    removeAction : {
        marginTop: "0px"
    },
    removeIcon : {
        padding: "5px 14px"
    },
    removeIconHide : {
        display: "none"
    },
    deleteButtonRoot : {
        padding: "16px 32px"
    }
});
export default Styles