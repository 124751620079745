import React from 'react';
import { withRouter } from 'react-router-dom';
import IntegrateStore from '../IntegrateStore';
import Styles from '../../../../assets/css/Styles';
import { withStyles } from '@material-ui/core/styles';
import CdlInstructions from './CdlInstruction';
import { CDLLOGO } from '../../../../../common/imageList';
import BreadCrumbsCard from '../../BreadCrumbsCard';
import IntegrationLogo from '../../IntegrationLogo';
import FlashMessage from '../../../../common/FlashMessage';
import { LinearProgressBar } from '../../../../common/materialComponents';

class Cdl extends React.Component {
    constructor(props) {
        super(props)
        this.isSuccess = false
        this.state = {
            error: false,
            showSaveLoader: false,
            updateToggleMessage: false,
            success: false,
            successErrMsg: '',
            cdlStoreActive: false,
            cdlStoreLoader: this.props.match.params.id ? true : false,
            cdlForm: this.props.match.params.id === undefined ? false : true,
        }
    }

    cdlStoreLoader = (isLoading) => {
        this.setState({ cdlStoreLoader: isLoading })
    }

    toggleFlashMessage = (data) => {
        this.setState({
            error: false,
            success: false,
            successErrMsg: ''
        })
    }

    updateToggleMessage = (data) => {
        this.isSuccess = data.isSuccess
        this.setState({ error: data.error, success: data.success, successErrMsg: data.successErrMsg, showSaveLoader: data.showSaveLoader ? data.showSaveLoader : false })
    }

    isCdlActive = (isActive) => {
        this.setState({ cdlStoreActive: isActive })
    }


    moveToCdlForm = (data) => {
        this.setState({ cdlForm: data })
    }

    backToIntegration = () => {
        this.props.history.push('/integrations')
    }

    backToIntegrationList = () => {
        this.props.history.push('/integrated_list')
    }

    render() {
        const { classes } = this.props
        return (
            <React.Fragment>
                <div style={{ display: this.state.cdlStoreLoader ? 'block' : 'none' }}>
                    <LinearProgressBar />
                </div>
                <div style={{ display: !this.state.cdlStoreLoader ? 'block' : 'none' }}>
                    <div className="settingsModule">
                        <BreadCrumbsCard classes={classes} storeName="CDL Last Mile"
                            storeId={this.props.match.params.id} activeStore={this.isCdlActive}
                            active={this.state.cdlStoreActive} updateToggleMessage={this.updateToggleMessage} />
                        <div className="dataContainer">
                            <IntegrationLogo classes={classes} icon={CDLLOGO} title="CDL Last Mile" />
                            <div id="settingDataScrollerWrapper">
                                <div className="settingsContainer">
                                    <div className="settingsRightSidePanel">
                                        {
                                            this.state.cdlForm ?
                                                <IntegrateStore
                                                    isLoading={this.cdlStoreLoader} updateSuccessErrMsg={this.updateToggleMessage}
                                                    activeStore={this.isCdlActive} classes={classes} type="Cdl"
                                                    storeId={this.props.match.params.id} channelId="7" backToIntegrationList={this.backToIntegrationList} />
                                                : <CdlInstructions classes={classes} moveToCdlForm={this.moveToCdlForm} backToIntegration={this.backToIntegration} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.error || this.state.success) && !this.state.showSaveLoader ?
                            <FlashMessage isSuccess={this.isSuccess} message={this.state.successErrMsg} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                            : ''}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(Styles)(Cdl));
