import React from 'react';
import { withRouter } from 'react-router-dom';
import '../../assets/css/onboarding.scss';
import { checkSubDomain, editSubDomain } from '../../actions/actionMethods'
import { DoneIcon, cancelIcon } from '../../../common/imageList'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { SaveIcon } from '../../../common/imageList'
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = () => ({
  textLabelField: {
    height: '19px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.16px',
    color: '#666666',
    transform: 'translate(14px, 20px) scale(1)'
  },
  disableButton: {
    background: '#d3d3d3',
    width: '88px',
    height: '36px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    float: 'right',
    color: '#ffffff',
    "&:hover": {
      background: '#d3d3d3'
    }
  },
  button: {
    background: '#1a73e8',
    width: '88px',
    height: '36px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.14px',
    color: '#ffffff',
    float: 'right',
    "&:hover": {
      background: '#1a73e8'
    }
  },
  progressbar: {
    width: '100%',
    zIndex: 2,
    position: 'absolute',
  }
});

class OnBoarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subDomain: '',
      isDomainError: false,
      errMsg: false,
      loading: false
    }
    this.timeout = false
  }

  onChangeSubDomain = (value) => {
    if (value !== '') {
      let requestParams = {}
      requestParams.sub_domain = value.replace(/[^a-zA-Z0-9_-]/g, "").toLowerCase()
      this.setState({
        subDomain: requestParams.sub_domain,
        isDomainError: false
      })
      if (this.timeout)
        clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        checkSubDomain(requestParams).then(res => {
          if (!res.data.is_domain_available) {
            this.setState({
              errMsg: false,
              isDomainError: false,
            })
          } else {
            this.setState({
              errMsg: true
            })
          }
        })
      }, 1000);

    } else {
      this.setState({
        isDomainError: true,
        subDomain: value
      })
    }
  }
  handleSubmit = () => {
    if (this.state.subDomain !== '') {
      let requestParams = {}
      requestParams.sub_domain = this.state.subDomain
      this.setState({ loading: true })
      editSubDomain(requestParams).then(res => {
        this.setState({ loading: false })
        if (res && res.data) {
          if (res.data.meta && res.data.meta.code === 400) {
            this.setState({ errMsg: false })
          } else {
            localStorage.setItem('sub_domain', res.data.sub_domain)
            localStorage.setItem('sub_domain_status', 1)
            if (localStorage.getItem('redirect_to_integrated_list') == 1) {
              localStorage.setItem('redirect_to_integrated_list', 0)
              this.props.history.push('/integrated_list')
            } else {
              this.props.history.push('/')
            }
          }
        }
      })
    }
  }

  componentWillMount() {
    if (localStorage.getItem('sub_domain')) {
      this.setState({
        subDomain: localStorage.getItem('sub_domain'),
      })
      let requestParams = {}
      requestParams.sub_domain = localStorage.getItem('sub_domain').replace(/[^a-zA-Z0-9_-]/g, "").toLowerCase()
      checkSubDomain(requestParams).then(res => {
        if (!res.data.is_domain_available) {
          this.setState({
            errMsg: false,
            isDomainError: false,
          })
        } else {
          this.setState({
            errMsg: true
          })
        }
      })
    }
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        {
          this.state.loading ?
            <div className={classes.progressbar}>
              <LinearProgress color="primary" />
            </div>
            : ''
        }
        <div id="onboarding_container">
          <div class="data_container main_view">
            <div className="commonTopHeader">
              <h1 className="headerText">Welcome to Trackhive, {localStorage.getItem('ProfileName')}!</h1>
            </div>
            <div id="onboarding_index">
              <div className="onboarding-container">
                <div className="TitleBar">
                  <div className="onboarding-title">Please provide subdomain name for your tracking page.</div>
                  <Button
                    onClick={this.handleSubmit}
                    startIcon={<SaveIcon />}
                    disabled={this.state.isDomainError}
                    className={this.state.isDomainError ? classes.disableButton : classes.button}>
                    DONE
                  </Button>
                  <div className="clearFix"></div>
                </div>
                <div className="onboarding-content">
                  <TextField
                    InputLabelProps={{
                      className: classes.textLabelField
                    }}
                    error={this.state.isDomainError ? true : false}
                    id="tracking_number"
                    label="Sub domian"
                    variant="outlined"
                    value={this.state.subDomain}
                    onChange={e => this.onChangeSubDomain(e.target.value)}
                  ></TextField><span>.trackhive.co</span>
                  {
                    this.state.subDomain !== '' ?
                      !this.state.errMsg ? <label className="domainNameAvailableStyle" style={{ color: '#F73E5D' }} ><img alt="cancel_IC" src={cancelIcon} /> Is Unavailable</label> : <label className="domainNameAvailableStyleYes" style={{ color: '#54BB83' }}><img src={DoneIcon} alt="done_IC" /> Is Available</label>
                      : ''
                  }
                  <label>Note : Space and special characters are not allowed.</label>
                  {this.state.isDomainError ? <label className="error-fonts">Subdomain Required</label> : ''}
                </div>
              </div>

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

}

export default withRouter(withStyles(styles)(OnBoarding))
