import {
    STATUS_LIST, COURIER_LIST, COURIER_LIST_VALUE, SUPPLIER_LIST, CONDITION_VALUE, CLEAR_CONDITION_VALUE, CONDITION_TYPE_COLLECTION, CLEAR_CONDITION_BY_KEY, CONDITION_OPERATOR,
    ACTION_TYPE, CLEAR_ACTION_BY_KEY, WAREHOUSE_LIST, ACTION_REQUEST_PARAMS, CLEAR_ALL_ACTION, AUTOMATION_LIST, AUTOMATION_DETAIL,
    DELETE_AUTOMATION_LIST, ADD_AUTOMATION_LIST, PRICING_TIER_LIST, AUTOMATION_GLOBAL_FILTERS, CLEAR_AUTOMATION_LIST, SET_AUTOMATION_TRIGGER_LIST,
    SET_AUTOMATION_CONDITION_LIST, SET_AUTOMATION_ACTION_LIST, SET_AUTOMATION_CUSTOM_EVENT_LIST, PRODUCT_CUSTOM_FIELD, DELIVERY_TIME_LIST, CUSTOM_TRIGGER_LIST, SET_AUTOMATION_ACTION_ARRAY_VALUE_LIST,
    SET_AUTOMATION_TRIGGER_ARRAY_VALUE_LIST, AUTOMATION_LIST_GLOBAL_FILTERS, SET_AUTOMATION_LOADER, SET_LOGICAL_OPERATOR
} from '../constants/actionTypes';

// import { UNHANDLED_EXCEPTION } from '../constants/errorMessages'

const initialState = {
    statusList: [],
    deliveryTimeList: [],
    courierList: [],
    supplierList: [],
    warehouseList: [],
    conditionValueList: {},
    conditionCollection: {},
    conditionOperator: {},
    actionType: {},
    actionRequestParams: {},
    automationList: [],
    automationDetail: {},
    pricingTierList: [],
    searchAutomationList: [],
    automationGlobalFilters: [],
    automationTriggerList: [],
    automationConditionList: [],
    automationActionList: [],
    automationCustomEventList: [],
    productCustomField: [],
    courierListValue: [],
    customTriggerList: [],
    automationFilters:{},
    automatioLoader : 0,
    logicalOperator : ''
}

export default function (state = initialState, action = null) {


    switch (action.type) {
        case STATUS_LIST:
            return { ...state, statusList: action.data };
        case SET_LOGICAL_OPERATOR:
            return { ...state, logicalOperator: action.data };
        case DELIVERY_TIME_LIST:
            return { ...state, deliveryTimeList: action.data };
        case CUSTOM_TRIGGER_LIST:
            return { ...state, customTriggerList: action.data };
        case COURIER_LIST:
            return { ...state, courierList: action.data };
        case SUPPLIER_LIST:
            return { ...state, supplierList: action.data.data };
        case WAREHOUSE_LIST:
            return { ...state, warehouseList: action.data };
        case CONDITION_VALUE:
            let key = action.data.key
            let value = action.data.value
            return { ...state, conditionValueList: { ...state.conditionValueList, [key]: value } };
        case CLEAR_CONDITION_VALUE:
            return { ...state, conditionValueList: action.data, conditionCollection: action.data, conditionOperator: action.data };
        case CONDITION_TYPE_COLLECTION:
            return { ...state, conditionCollection: { ...state.conditionCollection, [action.data.key]: action.data.value } };
        case CONDITION_OPERATOR:
            return { ...state, conditionOperator: { ...state.conditionOperator, [action.data.key]: action.data.value } };
        case CLEAR_CONDITION_BY_KEY:
            return {
                ...state,
                conditionCollection: {
                    ...state.conditionCollection, [action.data.key]: action.data.value
                },
                conditionValueList: {
                    ...state.conditionValueList, [action.data.key]: action.data.value
                },
                conditionOperator: {
                    ...state.conditionOperator, [action.data.key]: action.data.value
                }
            };
        case ACTION_TYPE:
            return { ...state, actionType: { ...state.actionType, [action.data.key]: action.data.value } };
        case ACTION_REQUEST_PARAMS:
            return { ...state, actionRequestParams: { ...state.actionRequestParams, [action.data.key]: action.data.value } };
        case CLEAR_ACTION_BY_KEY:
            return {
                ...state,
                actionType: {
                    ...state.actionType, [action.data.key]: action.data.value
                },
                actionRequestParams: {
                    ...state.actionRequestParams, [action.data.key]: action.data.value
                }
            };
        case CLEAR_ALL_ACTION:
            return { ...state, actionType: action.data, actionRequestParams: action.data };
        case AUTOMATION_LIST:

            return { ...state, automationList: action.data, automatioLoader : 1 };
        case CLEAR_AUTOMATION_LIST:
            return { ...state, automationList: action.data };
        case ADD_AUTOMATION_LIST:



            let addNewAutomationList = [
                ...state.automationList.slice(0, 0),
                action.data.data,
                ...state.automationList.slice(0)
            ]

            return { ...state, automationList: addNewAutomationList };

        case DELETE_AUTOMATION_LIST:

            
            let prevAutomationList = [...state.automationList]
            let newAutomationList = prevAutomationList.filter((automationData) => automationData.id !== action.data.id)
            return { ...state, automationList: newAutomationList };

        case AUTOMATION_DETAIL:
            return { ...state, automationDetail: action.data };
        case COURIER_LIST_VALUE:
            return { ...state, courierListValue: action.data };
        case PRICING_TIER_LIST:
            return { ...state, pricingTierList: action.data };
        case AUTOMATION_GLOBAL_FILTERS:
            return { ...state, automationGlobalFilters: action.data };

        case SET_AUTOMATION_TRIGGER_LIST:
            return { ...state, automationTriggerList: action.data };
        case SET_AUTOMATION_CONDITION_LIST:
            return { ...state, automationConditionList: action.data };
        case SET_AUTOMATION_ACTION_LIST:
            return { ...state, automationActionList: action.data };

        case SET_AUTOMATION_CUSTOM_EVENT_LIST:
            return { ...state, automationCustomEventList: action.data };
        case PRODUCT_CUSTOM_FIELD:
            return { ...state, productCustomField: action.data || [] };
        case AUTOMATION_LIST_GLOBAL_FILTERS:
            return{
                ...state,
                automationFilters:action.data
            }
        case SET_AUTOMATION_ACTION_ARRAY_VALUE_LIST:
            return {
                ...state,
                automationActionArrayList:action.data
            }
        case SET_AUTOMATION_TRIGGER_ARRAY_VALUE_LIST:
            return {
                ...state,
                automationTriggerArrayList : action.data
            }
        case SET_AUTOMATION_LOADER:
            return {
                ...state,
                automatioLoader : action.data
            }
        default:
            return state;
    }
}
