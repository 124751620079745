import React from 'react'
import { withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { topCouriers } from '../actions/actionMethods'
import { changeDateFunction } from '../common/Functions'
import { getCourierLabel, showCourierLogo } from '../../common/commonFunction'
import { topCouriersIcon } from '../../common/imageList'
import { getCarrierList, setCourierList } from '../../TrackingList/actions/actionMethods'
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import DashboardPopover from "../common/DashboardPopover";
import Styles from '../static/Styles'

class TopCouriers extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			toDate: '',
			fromDate: '',
			courierData: [],
			courierLoadingFlag: true
		}
	}
	componentWillMount() {
		getCarrierList().then(response => {
			if (response && response.data) {
				this.props.setCourierList(response.data)
			}
		})
		let dateObj = changeDateFunction('30Days', false)
		this.getTopCouriers(dateObj.fromDate, dateObj.toDate)
	}
	getTopCouriers = (fromDate, toDate) => {
		let data = {}
		data.from_date = fromDate
		data.to_date = toDate
		this.setState({ courierLoadingFlag: true })
		topCouriers(data).then(res => {
			if (res.status >= 200 && res.status <= 299) {
				this.setState({
					courierData: res.data.data,
					courierLoadingFlag: false
				})

			} else {
				this.setState({ courierLoadingFlag: false })
			}
		}).catch(error => {
			console.log(error)
			this.setState({ courierLoadingFlag: false })
		})
	}
	setCustomDateRange = (fromUTCDate, toUTCDate) => {
		this.getTopCouriers(fromUTCDate, toUTCDate)
		this.setState({
			fromUTCDateValue: fromUTCDate,
			toUTCDateValue: toUTCDate,
			fromDate: fromUTCDate,
			toDate: toUTCDate
		})
	}
	setDateType = (dateType, fromDate, toDate) => {
		this.setState({dateType: dateType, fromDate: fromDate, toDate:toDate, fromUTCDateValue: '', toUTCDateValue: ''})
	}
	render() {
		const { classes } = this.props
		let topCourierData = []
		for (let i = 0; i < this.state.courierData.length; i++) {
			let courier = this.state.courierData[i]
			topCourierData.push(
				<div className="topCourierInlineDiv" key={i}>
					<div className="courierNameLogo">
						<div className="courierLogo">
							<img src={showCourierLogo(courier.status)} alt={courier.status}></img>
						</div>
						<div className="courierName">
							<span>{getCourierLabel(courier.status, this.props.addedCourierList)}</span>
						</div>
					</div>
					<div className="noOfCourier">{courier.count}</div>
				</div>
			)
		}
		return (
			<Card id="shipments" className="cardNormal" style={{ cursor: 'default', float: 'none', width: '99.75%', height: "auto", position: 'relative', bottom: '8px' }}>
				<CardHeader id="shipmentSummary" className="shipmentStatus"
					avatar={
						<Avatar>
							<img alt="" src={topCouriersIcon} />
						</Avatar>
					}
					action={
						<Grid className="MuiCardHeader-action" style={{ display: "inline-flex", float: 'right' }}>
							<DashboardPopover
								type='TopCouriers'
								getData={this.getTopCouriers}
								setCustomDateRange={this.setCustomDateRange}
								setDateType={this.setDateType}
							/>
						</Grid>
					}
					title={<Typography className="headingText">Top 10 Couriers</Typography>}
				/>
				<CardContent className="topCourierListMainDiv" style={{ padding: '0px' }}>
					{this.state.courierLoadingFlag ? <div style={{ width: '400px', margin: '60px auto', opacity: '0.5' }}><CircularProgress size={42} className={classes.progress} /></div>
						: this.state.courierData.length > 0 ? topCourierData : <div className='noData' style={{ lineHeight: "0rem" }}>No Data to Display.</div>}
				</CardContent>
			</Card>
		)
	}
}

const mapStateToProps = state => ({
	addedCourierList: state.tracking.list.addedTrackingCourierList
})

export default withRouter(connect(mapStateToProps, { getCarrierList, setCourierList })(withStyles(Styles, { withTheme: true })(TopCouriers)))