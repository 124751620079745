import React from 'react';
import {withRouter} from 'react-router-dom';

import axios from 'axios';

import {getRedirectURLParameterByName, devShopifyToOhURI, liveShopifyVerifyHmac, liveShopifyToOhURI,devShopifyVerifyHmac,} from '../common/constants'
import {searchPattern} from '../../static/constants'
const prefix = process.env.REACT_APP_API_PREFIX

class ShopifyToOH extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader:true
    }
  }

  async componentDidMount() {
    let shopifyVerifyHmac = ''
    let redirect_uri = ''
    if(prefix === '/development'){
      shopifyVerifyHmac = devShopifyVerifyHmac
      redirect_uri=devShopifyToOhURI
    }else{
      shopifyVerifyHmac = liveShopifyVerifyHmac
       redirect_uri = liveShopifyToOhURI
    }

    var urlParams;
    (window.onpopstate = function () { // To get all query params in an Obj form
        var match,
            pl     = /\+/g,  // Regex for replacing addition symbol with a space
            decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
            query  = window.location.search.substring(1);

        urlParams = {};
        while (match = searchPattern.exec(query))
           urlParams[decode(match[1])] = decode(match[2]);
    })();

    let queryString = ''
    Object.keys(urlParams).forEach(function(key,index) {
      if(key !== 'hmac'){
        queryString += key + '='+urlParams[key]+'&'
      }
    });

    queryString = queryString.substr(0,queryString.length-1)

    let postObject = {
      channel_name : 'Shopify',
      store_param : getRedirectURLParameterByName('shop'),
      redirect_uri : redirect_uri,
      shopify_query_string :queryString,
      shopify_hmac : urlParams.hmac
    }
    axios.post(shopifyVerifyHmac, postObject).then(response=>{
      
      if(response.data.data){
        window.open(decodeURIComponent(response.data.data.url),"_self")
      }else{
        this.setState({loader:false})
      }
    })
    .catch(error=>{
    })
  }

  render() {

    return (
      <div id="data_container" className="main_view setup_view">
      {this.state.loader ? <div>Redirecting to Shopify...</div> : <div>Unauthorized Store...</div>}
      </div>

    );
  }
}
export default withRouter(ShopifyToOH);
