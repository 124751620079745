import React from 'react'
import onClickOutside from 'react-onclickoutside'
import DataTable from './EnhancedTable'
class TrackingListGrid extends React.Component {
    handleClickOutside = evt => {
        if(this.props.trackingListArray.length > 0){
            this.props.resetSelect()
        }
    }
    
    render(){
        return(
            <div className="trackingCardMainDiv">            
                <DataTable 
                trackingList={this.props.trackingList}
                subUserList={this.props.subUserList}
                trackingListArray={this.props.trackingListArray}
                noOfTotalTrackingRecords = {this.props.noOfTotalTrackingRecords}
                trackingGlobalFilters = {this.props.trackingGlobalFilters}
                sendStatusListValue={this.props.sendStatusListValue}
                setTrackingListFlag = {this.props.setTrackingListFlag}
                showLoader = {this.props.showLoader}
                getTrackingList = {this.props.getTrackingList}
                startPaginationLoader = {this.props.startPaginationLoader}
                setTrackingListPaginationFlag = {this.props.setTrackingListPaginationFlag}
                addNewTrackingTabValue={this.props.addNewTrackingTabValue}
                trackingStatusArrayList={this.props.trackingStatusListValue}
                carrierList={this.props.carrierList}
                handleCancel={this.props.handleCancel} 
                changeTabToAll={this.props.changeTabToAll}
                handlePopUpDisplay = {this.props.handlePopUpDisplay}
                setExportMessage = {this.props.setExportMessage}
                handleExportShipments = {this.props.handleExportShipments}
                prepereFilter = {this.props.prepereFilter}
                applySearchAndFilter = {this.props.applySearchAndFilter}
                />
            </div>
        );
    }
}
TrackingListGrid = onClickOutside(TrackingListGrid)
export default TrackingListGrid