import React from 'react';
import '../../assets/css/emailSettings.scss';
import { withRouter, Link } from 'react-router-dom';
import { getEmailTemplateList, deleteTemplate, toggleSettingsFlashMessage, setSettingsFlashMessage} from '../../actions/actionMethods';
import { connect } from 'react-redux';
import { LinearProgressBar, StyledCard, StyledTypography, BreadCrumbs, Image } from '../../common/materialComponents'
import {DELETE_TEMPLATE_ERROR, DELETE_TEMPLATE_SUCCESS} from '../../common/constants';
import {addIcon,EmailTemplates,RemoveIcon,ApiCopyIcon} from '../../../../src/common/imageList'
import { CssBaseline } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Styles from '../../assets/css/Styles';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FlashMessage from '../../common/FlashMessage';
import CircularProgress from '@material-ui/core/CircularProgress';
class EmailSetting extends React.Component{
  constructor(props) {
      super(props)
      this.isSuccess = true
      this.state = {
          templateList: [],
          deletePopupFlag: false,
          deleteTemplateId: '',
          showLoader: false,
          loading: false,
          loaderFlag: true,
          showEmailDomainModal: false,
          emailIdValue: '',
          newFromEmail: '',
          errorBorderEmail: false,
          EmailError: '',
          domainList: [],
          isVerifyFlag: false,
          isDeleteFlag: false,
          deleteEmailId: '',
          deleteDomainId: '',
          deleteDomainFlag: false,
          verifyDomainId: '',
          cname: {},
          selectedFilterOptions: null,
          emailFieldError: false
      }
  }

  componentDidMount() {
      this.getEmailTemplates()
  }

  getEmailTemplates = () => {
      getEmailTemplateList().then(res => {
          if (res.status >= 200 && res.status <= 500) {
              let templateList = []
              templateList = res.data
              let customTemplates = []
              if (templateList !== undefined) {
                  for (let i = 0; i < templateList.data.length; i++) {
                      customTemplates.push(templateList.data[i])
                  }
              }
              this.setState({
                  templateList: customTemplates,
                  loaderFlag: false
              })
          }
      }).catch(function (error) {
          console.log(error)
      })
  }

  createCustomTemplate = () => {
      this.props.history.push('/custom_email_template')
  }
 
   templatesListing = (templateList) => {
      const {classes} = this.props
      if(templateList !== undefined){
         return(
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                 <TableHead>
                     <TableRow>
                        <TableCell align="left" className={`${classes.headCell} ${classes.firstHeadAndBodyCell}`}>Template Name</TableCell>
                        <TableCell align="left" className={classes.headCell}>Copy</TableCell>
                        <TableCell align="left" className={`${classes.headCell} ${classes.lastHeadAndBodyCell}`}>Delete</TableCell>
                      </TableRow>
                 </TableHead>
                 <TableBody>
                    {
                      templateList.map((template,index) => {
                       return(
                         
                           <TableRow key={index}>
                              <TableCell align="left" className={`${classes.bodyCell} ${classes.firstHeadAndBodyCell}`}>
                                <div style={{color: "#2f80ed", fontSize: "14px", textDecoration:'none', cursor: 'pointer'}}   onClick={(id, action) => this.handleActionPerform(template._id, 'edit')}>
                                     {template.template_name}
                                 </div>
                              </TableCell>
                              <TableCell align="left" className={classes.bodyCell} style={{paddingLeft:'7px'}}>
                                 <div style={{cursor: 'pointer' }} onClick={(id, action) => this.handleActionPerform(template._id, 'copy')} >
                                      <ApiCopyIcon/>
                                  </div>
                              </TableCell>
                              <TableCell className={`${classes.bodyCell} ${classes.lastHeadAndBodyCell}`} style={{paddingLeft:'10px'}}>
                                  <div style={{cursor: 'pointer'}} onClick = {(id) => this.setState({deletePopupFlag:true,deleteTemplateId:template._id,loading: false})}>
                                       <RemoveIcon/>
                                   </div>
                              </TableCell>
                           </TableRow>
                         
                        )
                        })
                    }
                 </TableBody>
              </Table>
            </TableContainer>
         )
      }
   }


  checkValidation = () => {
      let errorCount = 0
      if (this.state.newFromEmail === '' || this.state.newFromEmail.trim().length === 0) {
          this.setState({
              emailFieldError: true
          })
          errorCount++
      } else {
          this.setState({
              emailFieldError: false
          })
      }
      if (this.state.emailError === true) {
          errorCount++
      } else {
          this.setState({
              emailError: false
          })
      }
      if (errorCount > 0) {
          return false
      }
      return true
  }
  
 removeTemplate = () => {
    this.setState({loading: true})
    let templateId = this.state.deleteTemplateId
    deleteTemplate(templateId).then(res => {
        if(res && res.status >= 200 && res.status <= 299){
            this.getEmailTemplates()
            this.isSuccess = true
            this.props.toggleSettingsFlashMessage(1)
            this.props.setSettingsFlashMessage(DELETE_TEMPLATE_SUCCESS)
            this.setState({ loading: false, deleteTemplateId: '', deleteTemplateType: '', deletePopupFlag: false })
        }else{
            this.isSuccess = false
            this.props.toggleSettingsFlashMessage(1)
            this.props.setSettingsFlashMessage(DELETE_TEMPLATE_ERROR)
            this.setState({ loading: false,deleteTemplateType: '', deleteTemplateId: '', deletePopupFlag: false })
        }
    }).catch(error => {
        console.log(error)
        this.setState({loading: false})
    })
 }

 cancelDeletePopUP = () => {
     this.setState({
         deletePopupFlag: false,
         deleteTemplateId: '',
         deleteTemplateType: ''
     })
 }

 handleActionPerform = (id, action) => {
     if (action === 'copy') {
         this.props.history.push(`/copy_email_template/${id}`)
     } else if (action === 'edit') {
         this.props.history.push(`/edit_email_template/${id}`)
     }
 }

 toggleFlashMessage = (data) => {
    this.props.toggleSettingsFlashMessage(data)
    this.props.setSettingsFlashMessage('')
  }
render(){
    if(this.state.loaderFlag){
        return(
           <div>
               <LinearProgressBar/>
           </div>
        )
    }else{
        const {classes} = this.props
        return(
            <React.Fragment>
            <CssBaseline/>
            <div className="settingsModule">
                   <StyledCard>
                        <BreadCrumbs aria-label="breadcrumb">
                            <Link className={classes.link} to="/settings">
                                Settings
                            </Link>
                           <StyledTypography className={classes.text}>Email Templates</StyledTypography>     
                        </BreadCrumbs>
                    </StyledCard>
            <div className="dataContainer">
                   <div className="containerHeader">
                        <Image alt="emailTemplates_IC" className={classes.avatar} src={EmailTemplates} />
                            <div className="containerTitle" style={{width: '149px'}}>Email Templates</div>
                            <div style={{float: "right", textAlign:"right", paddingRight: '26px', cursor: 'pointer'}}>
                            <Image src={addIcon} alt="Add Icon" onClick={this.createCustomTemplate} />
                        </div>
                   </div>
                <div id="settingDataScrollerWrapper">
                    <div className="settingsContainer">                  
                        <div className="settingsRightSidePanel">
                            <div className="commonSettingsBox emailStyleMainDiv">
                                    <div style = {{paddingBottom: "1.5rem"}}>
                                            {this.templatesListing(this.state.templateList)}
                                    </div>
                            </div>
                            {this.props.showSettingFlashMessageFlag === 1 ?
                                         <FlashMessage isSuccess={this.isSuccess} message={this.props.settingFlashMessageValue} toggleFlashMessage={(data) => this.toggleFlashMessage(data)} />
                            : ''}
                        </div>
                    </div>
                </div>
            </div>
            {/* data container close  */}
        
         <Dialog
            open={this.state.deletePopupFlag}
            onClose={this.cancelDeletePopUP}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Delete Email Template"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure that you want to delete this Email Template?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.dialogCancelBtn}  onClick={this.cancelDeletePopUP}>
                    Cancel
                </Button>
                <Button  className={classes.dialogActionBtn}  onClick={this.removeTemplate} autoFocus>
                    Yes, Delete
                </Button>
                {(this.state.loading) && <CircularProgress size={22} className={classes.deleteDialogProgress} />}
            </DialogActions>
        </Dialog>
    </div>
    </React.Fragment>
    )
    }
  }   
}
const mapStateToProps = state => ({
    showSettingFlashMessageFlag: state.settings.list.showSettingFlashMessage,
    settingFlashMessageValue: state.settings.list.SettingFlashMessage
})
export default withRouter(connect(mapStateToProps, { toggleSettingsFlashMessage, setSettingsFlashMessage })(withStyles(Styles)(EmailSetting)));