import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch'
import axios from 'axios';
import {syncGif} from '../../../common/imageList';
import { getInstance } from '../../../../src/config/token';
import withRouter from 'react-router-dom/withRouter';
import {STORE_ACTIVATED_MESSAGE, STORE_DEACTIVATED_MESSAGE} from '../../common/constants'

const styles = theme => ({
    media: {
        objectFit: 'none',
        height: 90,
        width: 90,
        margin: "16px"
    },
    addIntegrationMedia: {
        height: 48,
        width: 48,
    },
    root: {
        display: 'flex',
        width: 351,
        height: 144,
        cursor: "pointer"
    },
    addIntegrationRoot: {
        display: 'flex',
        width: 351,
        height: 144,
        cursor: "pointer",
        padding: "48px 70px"
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: "229px"
    },
    content: {
        flex: '1 0 auto',
        paddingRight: "0px"
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    textOverflow: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    cardAction: {
        "&:hover": {
            "& > .MuiPaper-root": {
                background: "#e3eefc"
            }
        }
    }
});

class IntegrationCard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            active: this.props.active
        }
    }

    toggleSwitch = (event, toggleState) => {
        event.stopPropagation()
        let id = this.props.id
        let newToggleState = ''

        if (toggleState) {
            newToggleState = 0
        }
        else {
            newToggleState = 1
        }
        let requestParams = { active: newToggleState }
        this.setState({
            active: newToggleState,
        })
        let config = getInstance('PUT', `/stores/activation/${id}`, requestParams)
        let isSuccess = null
        let successErrMsg = ''
        axios(config).then(res => {
            isSuccess = true
            successErrMsg = (newToggleState) ? STORE_ACTIVATED_MESSAGE : STORE_DEACTIVATED_MESSAGE
            this.props.flashMessage(isSuccess, successErrMsg)
        }).catch(err => {
            this.setState({
                active: toggleState,
            })
            isSuccess = false
            if (err.response && err.response.status) {
                if (err.response.data && err.response.data.meta && err.response.data.meta.code === 400)
                    successErrMsg = err.response.data.meta.message[0]
            }
            this.props.flashMessage(isSuccess, successErrMsg)
        })
    }

    onClick = (event) => {
        event.stopPropagation()
        let id = this.props.id
        let channelId = this.props.channelId
        if (channelId === "3") {
            this.props.history.push('/edit_auspost/' + id)
        }

        if (channelId === "4") {
            this.props.history.push('/edit_wooCommerce/' + id)
        }

        if (channelId === "5") {
            this.props.history.push('/edit_magento/' + id)
        }
        if (channelId === "6") {
            this.props.history.push('/edit_bluedart/' + id)
        }
        if (channelId === "7") {
            this.props.history.push('/edit_cdl/' + id)
        }
        if (channelId === "8") {
            this.props.history.push('/edit_ninja_van/' + id)
        }
        if (channelId === "9") {
            this.props.history.push('/edit_gw_world/' + id)
        }
        if (channelId === "10") {
            this.props.history.push('/edit_99minutos/' + id)
        }
    }

    addIntegration = (event) => {
        this.props.tabChange(event, 1)
        this.props.history.push('/integrations')
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                {this.props.id ?
                    <CardActionArea className={classes.cardAction} onClick={this.onClick}>
                        <Card className={classes.root}>
                            <div className={classes.details}>
                                <CardContent className={classes.content}>
                                    <Typography title={this.props.title} className={classes.textOverflow} component="h5" variant="h5">
                                        {this.props.title}
                                    </Typography>
                                    <Typography title={this.props.title} className={classes.textOverflow} variant="subtitle1" color="textSecondary">
                                        {this.props.title}
                                    </Typography>
                                    {this.props.channelId === '1' && this.props.isSync?
                                          <React.Fragment>
                                              <img src={syncGif} alt= "" className="sync-img"/><span className="sync-progress">Sync in progress...</span>
                                          </React.Fragment>
                                          : ''
                                    }
                                </CardContent>
                                <div className={classes.controls}>
                                    <Switch
                                        size="medium"
                                        checked={(this.state.active === 1) ? true : false}
                                        onClick={(e) => this.toggleSwitch(e, this.state.active)}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </div>
                            </div>
                            <CardMedia
                                component="img"
                                className={classes.media}
                                image={this.props.icon}
                                title={this.props.title}
                            />
                        </Card>
                    </CardActionArea>
                    :
                    <CardActionArea className={classes.cardAction} onClick={this.addIntegration}>
                        <Card className={classes.addIntegrationRoot}>
                            <CardMedia
                                className={classes.addIntegrationMedia}
                                image={this.props.icon}
                                title="Add Integration"
                            />
                            <div style={{ padding: "8px 0px 0px 16px" }}>
                                <Typography className={classes.textOverflow} variant="subtitle1" color="textSecondary">
                                    Add Integration
                                </Typography>
                            </div>
                        </Card>
                    </CardActionArea>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(IntegrationCard))