
import * as types from '../reducers/constants'



export function setPasswordLinkMessage() {
    return dispatch => {
      dispatch({
        type: types.SET_PASSWORD_EMAIL_LINK_MESSAGE,
        data: {}
      })
    }
  }

export function resetPasswordLinkMessage() {
    return dispatch => {
        dispatch({
          type: types.RESET_PASSWORD_LINK_MESSAGE,
          data: {}
        })
      }
}

